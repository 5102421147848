<template>
    <node-view-wrapper class="draw p-2">

        <!-- <div v-if="widgetInfo.locked" class="position-absolute px-2 w-100">

            <BRow class="p-2 bg-light w-100 rounded-pill">
                <BCol md="6">
                    <div class="d-flex align-items-center">
                        <input class="p-0 mx-2" type="color" v-model="color">
                        <template v-for="x in colors" :key="x">
                            <span class="ms-2 rounded-circle-36 hand" :style="'background-color:' + x" @click="color = x"></span>
                        </template>
<input class="p-0 ms-4 text-center" type="number" min="1" max="10" v-model="size">
</div>
</BCol>
<BCol md="6">
    <div class="d-flex align-items-center justify-content-end pe-3">
        <template v-for="x in 5" :key="x">
                            <span class="ps-3 hand" @click="size = x">
                                <i v-if="size === x" :class="'text-danger fw-bold fs-30 ri-number-' + x"></i>
                                <i v-else :class="'ri-number-' + x"></i>
                            </span>
                        </template>
        <span class="flex-fill"></span>
        <button class="p-2 ms-2 bg-dark text-light rounded-pill" @click="clear">clear</button>
    </div>
</BCol>
</BRow>

</div> -->

        <svg viewBox="0 0 100 100" ref="canvas" style="width:100%; height:100%">
            <template v-for="item in node.attrs.lines">
                <path v-if="item.id !== id" :key="item.id" :d="item.path" :id="`id-${item.id}`" :stroke="item.color" :stroke-width="item.size" />
            </template>
        </svg>
    </node-view-wrapper>
</template>

<script>
import { inject } from "vue";
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import * as d3 from 'd3'
import { v4 as uuid } from 'uuid'

// const getRandomElement = list => {
//     return list[Math.floor(Math.random() * list.length)]
// }

export default {
    name: 'Paper',
    components: {
        NodeViewWrapper,
    },
    props: nodeViewProps,
    setup() {
        const widgetInfo = inject("$widgetInfo");

        return {
            widgetInfo
        }
    },
    data() {
        return {
            // color: getRandomElement([
            //     '#A975FF',
            //     '#FB5151',
            //     '#FD9170',
            //     '#FFCB6B',
            //     '#68CEF8',
            //     '#80CBC4',
            //     '#9DEF8F',
            //     '#666666',
            // ]),
            // colors: [
            //     '#A975FF',
            //     '#FB5151',
            //     '#FD9170',
            //     '#FFCB6B',
            //     '#68CEF8',
            //     '#80CBC4',
            //     '#9DEF8F',
            //     '#666666',
            // ],
            // size: Math.ceil(Math.random() * Math.floor(5)),
            svg: null,
            path: null,
            points: [],
            drawing: false,
            id: uuid(),
        }
    },
    methods: {
        onStartDrawing(event) {
            this.drawing = true
            this.points = []
            this.path = this.svg
                .append('path')
                .data([this.points])
                .attr('id', `id-${this.id}`)
                .attr('stroke', this.widgetInfo.penColor)
                .attr('stroke-width', this.widgetInfo.penSize)

            const moveEvent = event.type === 'mousedown'
                ? 'mousemove'
                : 'touchmove'

            if (this.widgetInfo.locked) {
                this.svg.on(moveEvent, this.onMove)
            }
            // this.svg.on(moveEvent, this.onMove)
        },

        onMove(event) {
            event.preventDefault()
            this.points.push(d3.pointers(event)[0])
            this.tick()
        },

        onEndDrawing() {
            this.svg.on('mousemove', null)
            this.svg.on('touchmove', null)

            if (!this.drawing) {
                return
            }

            this.drawing = false
            this.svg.select(`#id-${this.id}`).remove()
            this.id = uuid()

            // console.log('path : '+ JSON.stringify(this.path))
        },

        tick() {
            requestAnimationFrame(() => {
                this.path.attr('d', points => {
                    const path = d3.line().curve(d3.curveBasis)(points)
                    const lines = this.node.attrs.lines.filter(item => item.id !== this.id)

                    this.updateAttributes({
                        lines: [
                            ...lines,
                            {
                                id: this.id,
                                color: this.widgetInfo.penColor,
                                size: this.widgetInfo.penSize,
                                path,
                            },
                        ],
                    })

                    return path
                })
            })
        },

        clear() {
            this.updateAttributes({
                lines: [],
            })
        },
    },
    mounted() {
        this.svg = d3.select(this.$refs.canvas);

        this.svg
            .on('mousedown', this.onStartDrawing)
            .on('mouseup', this.onEndDrawing)
            .on('mouseleave', this.onEndDrawing)
            .on('touchstart', this.onStartDrawing)
            .on('touchend', this.onEndDrawing)
            .on('touchleave', this.onEndDrawing)
    },
}
</script>

<style lang="scss">
.draw {
    svg {
        // background: #f1f3f5;
        cursor: crosshair;
    }

    path {
        fill: none;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
}
</style>