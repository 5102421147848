<template>
    <BCard no-body>
        <BCardHeader class="align-items-center d-flex py-3">
            <BCardTitle class="mb-0 flex-grow-1">{{ selectedPage.Title }}</BCardTitle>
        </BCardHeader>

        <BCardBody>
            <BRow class="p-2">
                <template v-for="(x, i) in tags" :key="x">
                    <BCol v-if="i > 0 && i < 5" cols="6" class="text-center">
                        <div
                            class="d-flex mb-2 align-items-center"
                            :class="i === 1 ? 'border-bottom border-end' : i === 2 ? 'border-bottom' : i === 3 ? 'border-end' : ''"
                            @click="updateTag(i)"
                        >
                            <span class="p-4" :class="selectedPage.Tag === i ? 'text-danger' : ''">
                                <i class="fs-50 hand" :class="x.icon"></i>
                            </span>
                            <span>
                                <div class="mb-2">
                                    <input class="form-check-input" type="radio" :id="'formCheck' + i" v-model="selectedPage.Tag" />
                                </div>
                                <label class="form-check-label" :for="'formCheck' + i">{{ x.name }}</label>
                            </span>
                        </div>
                    </BCol>
                </template>
            </BRow>
        </BCardBody>
    </BCard>
</template>

<script lang="js">
import { apiEnums, enumTypes, sysEnums } from "@/common/sysEnums";
import { inject } from "vue";

export default {
    components: {
    },
    props: {
        Content: String,
        FileType: Number,
        IsBtn: Boolean,
    },
    setup(props) {
        const tags = Object.values(enumTypes.PageTags);

        const axios = inject("$axios");

        const userInfo = inject('$userInfo');

        const { filteredFolder, selectedPage, getUserPage, sendMessage } = inject("$provideList");

        const updateTag = (x) => {
            if (!selectedPage.value) {
                // showAlert('Alert!', '페이지를 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            const tag = selectedPage.value.Tag === x ? 0 : x;

            const formData = new FormData();
            formData.append('EncPage', selectedPage.value.EncPage);
            formData.append('Tag', tag);
            axios.post(apiEnums.CloudTicket.updatePage, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        getUserPage();
                        sendMessage(null, sysEnums.SignalTypes.Folder);
                    }
                })
                .catch(() => { });
        }


        return {
            props, sysEnums, enumTypes, userInfo, tags,
            filteredFolder, selectedPage,
            updateTag
        };
    },
};
</script>

<style scoped lang="scss"></style>
