<template>
    <!-- {{ JSON.stringify(selectedPage) }} -->

    <div v-if="selectedPage.Tag && selectedPage.Tag === sysEnums.PageTags.Decision" class="mb-4">
        <div class="p-2 mt-2 border-bottom">의사결정 진행 중</div>
        <!-- <BBadge v-if="selectedPage.PageRoles" variant="danger" pill>{{ selectedPage.PageRoles.length }}</BBadge> -->

        <template v-for="(x, i) in selectedPage.PageRoles" :key="x">
            <div v-if="x.EncFolder == sysEnums.zero" @dragover.prevent @drop="handleDropRoleMove(x.EncRole, $event)" class="mt-4">
                <div class="d-flex align-items-center">
                    <span class="pe-3 fs-30" :class="confirmStep === i ? 'text-danger fst-italic' : 'text-muted'">{{ i + 1 }}</span>
                    <div class="flex-shrink-0 me-3" style="cursor: move" draggable="true" @dragstart="dragStart(x, $event)">
                        <img
                            :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'"
                            class="avatar-xs rounded-circle"
                            :class="getSignalStatus(x.EncUser) > 0 ? '' : 'opacity-25'"
                            @click="gotoModal(x, 'UserInfo', '사용자정보')"
                        />
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="fs-13 mb-0 hand">
                            <span class="text-body d-block" @click="widgetInfo.selectedUser = x">{{
                                x.UserName + ' / ' + getUserDepartment(x.Position, true) + ' / ' + getUserDepartment(x.Department, false)
                            }}</span>
                        </h5>
                    </div>

                    <div class="flex-shrink-0">
                        <div v-if="selectedPage.Tag && selectedPage.Tag === sysEnums.PageTags.Decision" class="d-flex align-items-center gap-1">
                            <BButton type="button" variant="light" size="sm">{{ userInfo.EncUser === x.EncUser ? '오너' : enumTypes.RoleTypes[x.Type].name }}</BButton>

                            <BDropdown
                                variant="link"
                                toggle-class="btn btn-icon btn-sm fs-16 text-muted arrow-none"
                                menu-class="dropdown-menu-end"
                                :offset="{ alignmentAxis: -130, crossAxis: 0, mainAxis: 10 }"
                            >
                                <template #button-content>
                                    <i class="ri-more-fill"></i>
                                </template>
                                <BDropdownItem><i class="ri-eye-fill align-bottom me-2 text-muted"></i>View</BDropdownItem>
                                <BDropdownItem><i class="ri-pencil-fill align-bottom me-2 text-muted"></i>Favorite </BDropdownItem>
                                <BDropdownItem><i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i>Delete</BDropdownItem>
                            </BDropdown>
                        </div>
                        <!-- <div v-else @click="updateRole(x.EncRole, x.EncUser, true)">
                        {{ confirmStep + 1 + ' / ' + selectedPage.PageRoles.length }}
                    </div> -->
                    </div>
                </div>

                <div class="d-flex position-relative" v-if="confirmStep === i">
                    <!-- <BButton
                    class="w-100"
                    type="button"
                    :variant="$moment(x.Confirmed).format('YYYY') === '0000' ? 'light' : 'danger'"
                    size="sm"
                    @click="updateRole(x.EncRole, x.EncUser, false)"
                    >{{ selectedPage.EncUser === x.EncUser ? '오너' : $moment(x.Confirmed).format('YYYY') === '0000' ? '전결' : '전결됨' }}
                </BButton> -->

                    <BButton class="w-100 mx-2" type="button" variant="light" size="md" @click="updateRole(x.EncRole, x.EncUser, true)"
                        ><i class="ri-arrow-go-back-line pe-4 text-muted"></i>{{ selectedPage.EncUser === x.EncUser ? '오너' : '반려' }}
                    </BButton>

                    <BButton
                        class="w-100"
                        type="button"
                        :variant="$moment(x.Confirmed).format('YYYY') === '0000' ? 'light' : 'danger'"
                        size="md"
                        @click="updateRole(x.EncRole, x.EncUser, false)"
                    >
                        {{ selectedPage.EncUser === x.EncUser ? '오너' : $moment(x.Confirmed).format('YYYY') === '0000' ? '승인' : '승인됨'
                        }}<i class="ps-4" :class="i > 0 ? 'mdi mdi-apple-keyboard-caps text-muted' : 'ri-auction-line'"></i>
                    </BButton>
                    <!-- <span class="position-absolute" style="top: -30px; right: 30px"><i class="ri-auction-line text-muted"></i></span> -->
                    <!-- <span class="position-absolute" style="top: -30px; right: 30px"><i :class="i > 0 ? 'mdi mdi-apple-keyboard-caps text-muted' : 'ri-auction-line'"></i></span> -->
                </div>
                <div class="text-danger" v-else-if="$moment(x.Rejected).format('YYYY') !== '0000'">{{ $moment(x.Rejected).format('YYYY-MM-DD hh:mm:ss') }} 반려됨</div>
                <div class="text-primary" v-else>{{ $moment(x.Confirmed).format('YYYY') !== '0000' ? $moment(x.Confirmed).format('YYYY-MM-DD hh:mm:ss') + ' 승인됨' : '' }}</div>
            </div>
        </template>
    </div>

    <div v-else class="pb-5">
        <div v-if="selectedPage.FolderRoles && selectedPage.FolderRoles.length > 0" class="p-2 border-bottom">Post Shared</div>
        <div v-else class="p-2 border-bottom">포스트에 공유된 사용자가 없습니다.</div>

        <template v-for="(x, i) in selectedPage.FolderRoles" :key="x">
            <div v-if="x.EncPage === sysEnums.zero" class="d-flex align-items-center mt-2">
                <span class="pe-3 fs-30 text-muted">{{ i + 1 }}</span>
                <div class="flex-shrink-0 me-3">
                    <img
                        :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'"
                        class="avatar-xs rounded-circle"
                        :class="getSignalStatus(x.EncUser) > 0 ? '' : 'opacity-25'"
                        @click="gotoModal(x, 'UserInfo', '사용자정보')"
                    />
                </div>
                <div class="flex-grow-1">
                    <h5 class="fs-13 mb-0 hand">
                        <span class="text-body d-block" @click="widgetInfo.selectedUser = x">{{
                            x.UserName + ' / ' + getUserDepartment(x.Position, true) + ' / ' + getUserDepartment(x.Department, false)
                        }}</span>
                    </h5>
                </div>
                <div class="flex-shrink-0">
                    <div class="d-flex align-items-center gap-1">
                        <BButton type="button" variant="light" size="sm">{{ userInfo.EncUser === x.EncUser ? '오너' : enumTypes.RoleTypes[x.Type].name }}</BButton>

                        <BDropdown
                            variant="link"
                            toggle-class="btn btn-icon btn-sm fs-16 text-muted arrow-none"
                            menu-class="dropdown-menu-end"
                            :offset="{ alignmentAxis: -130, crossAxis: 0, mainAxis: 10 }"
                        >
                            <template #button-content>
                                <i class="ri-more-fill"></i>
                            </template>
                            <BDropdownItem><i class="ri-eye-fill align-bottom me-2 text-muted"></i>View</BDropdownItem>
                            <BDropdownItem><i class="ri-pencil-fill align-bottom me-2 text-muted"></i>Favorite </BDropdownItem>
                            <BDropdownItem><i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i>Delete</BDropdownItem>
                        </BDropdown>
                    </div>
                </div>
            </div>
        </template>

        <div v-if="selectedPage.PageRoles && selectedPage.PageRoles.length > 0" class="mt-4 p-2 border-bottom">Page Shared</div>
        <div v-else class="mt-5 p-2 border-bottom">페이지에 공유된 사용자가 없습니다.</div>

        <template v-for="(x, i) in selectedPage.PageRoles" :key="x">
            <div v-if="x.Grouped === 1" class="d-flex align-items-center mt-2">
                <span class="pe-3 fs-30 text-muted">{{ i + 1 }}</span>
                <div class="flex-shrink-0 me-3">
                    <img
                        :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'"
                        class="avatar-xs rounded-circle"
                        :class="getSignalStatus(x.EncUser) > 0 ? '' : 'opacity-25'"
                        @click="gotoModal(x, 'UserInfo', '사용자정보')"
                    />
                </div>
                <div class="flex-grow-1">
                    <h5 class="fs-13 mb-0 hand">
                        <span class="text-body d-block" @click="widgetInfo.selectedUser = x">{{
                            x.UserName + ' / ' + getUserDepartment(x.Position, true) + ' / ' + getUserDepartment(x.Department, false)
                        }}</span>
                    </h5>
                </div>
                <div class="flex-shrink-0">
                    <div class="d-flex align-items-center gap-1">
                        <BButton type="button" variant="light" size="sm">{{ userInfo.EncUser === x.EncUser ? '오너' : enumTypes.RoleTypes[x.Type].name }}</BButton>

                        <BDropdown
                            variant="link"
                            toggle-class="btn btn-icon btn-sm fs-16 text-muted arrow-none"
                            menu-class="dropdown-menu-end"
                            :offset="{ alignmentAxis: -130, crossAxis: 0, mainAxis: 10 }"
                        >
                            <template #button-content>
                                <i class="ri-more-fill"></i>
                            </template>
                            <BDropdownItem><i class="ri-eye-fill align-bottom me-2 text-muted"></i>View</BDropdownItem>
                            <BDropdownItem><i class="ri-pencil-fill align-bottom me-2 text-muted"></i>Favorite </BDropdownItem>
                            <BDropdownItem><i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i>Delete</BDropdownItem>
                        </BDropdown>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="js">
import { getSignalStatus, getUserDepartment, showAlert, showConfirm } from "@/common/comFuncs";
import { apiEnums, enumTypes, sysEnums } from "@/common/sysEnums";
import { computed, inject, ref } from "vue";

export default {
    props: {
        Content: String,
        FileType: Number,
        IsBtn: Boolean,
    },
    setup(props) {
        const selectedDept = ref([]);

        const roleTypes = Object.values(enumTypes.RoleTypes);

        const axios = inject("$axios");
        const userInfo = inject('$userInfo');

        const moment = inject('$moment');

        const widgetInfo = inject("$widgetInfo");

        const { filteredFolder, selectedPage, sendMessage, getUserPage, gotoModal } = inject("$provideList");

        // watch(() => selectedFolder.value.Dept, () => {
        //     getSelectedDept()
        // });

        // const getSelectedDept = () => {
        //     selectedDept.value = [];
        //     if (selectedFolder.value.Dept) {
        //         console.log(selectedFolder.value.Dept);
        //         selectedDept.value = selectedFolder.value.Dept.split(',').map(Number);
        //     }
        // };

        const createRole = (encUser, loginId, type) => {
            // console.log('createRole : ' + selectedPage.value.EncPage + ' / ' + encUser + ' / ' + type)
            const formData = new FormData();
            formData.append('EncFolder', sysEnums.zero);
            formData.append('EncPage', selectedPage.value.EncPage);
            formData.append('EncUser', encUser);
            formData.append('EncOwner', userInfo.value.EncUser);
            formData.append('Type', type);
            formData.append('Status', 1);
            axios.post(apiEnums.CloudTicket.createRole, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        getUserPage();
                        sendMessage(loginId, sysEnums.SignalTypes.Default);
                        // console.log('createRole response : ' + JSON.stringify(response.data));
                    }
                })
                .catch(() => { });
        };

        const updateRole = (encRole, encUser, flag) => {
            // console.log('updateRole selectedPage : ' + JSON.stringify(selectedPage.value))
            if (selectedPage.value.EncUser !== userInfo.value.EncUser && encUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '권한이 없습니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            showConfirm('', flag ? '요청사항을 반려 합니다?' : '요청사항을 승인 합니다?', flag ? '반려하기' : '승인하기').then((result) => {
                if (result) {
                    if (encRole) {
                        const formData = new FormData();
                        // formData.append('EncPage', selectedPage.value.EncPage || sysEnums.zero);
                        formData.append('EncRole', encRole);
                        formData.append('EncUser', encUser);
                        flag ? formData.append('Reject', 1) : formData.append('Confirm', 1);
                        axios.post(apiEnums.CloudTicket.updateRole, formData)
                            .then((response) => {
                                if (response.data !== '0000') {
                                    getUserPage();
                                    sendMessage(null, sysEnums.SignalTypes.Folder);
                                }
                            })
                            .catch(() => { });
                    }
                }
            });
        }

        const getStatus = (encUser) => {
            if (selectedPage?.value?.PageRoles) {
                return selectedPage.value.PageRoles.some(x => x.EncUser === encUser);
            }

            return false;
        }

        const dragStart = (data, event) => {
            // 드래그 시작 시 데이터 전달
            const dataString = JSON.stringify(data);
            event.dataTransfer.setData('application/json', dataString);
            // event.dataTransfer.setData('text/plain', data);
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.dropEffect = 'move';
            event.dataTransfer.setDragImage(event.target, 0, 0);
        };

        // const handleDropUserAdd = (event) => {
        //     // 요소를 드롭할 때 실행되는 함수
        //     event.preventDefault(); // 기본 동작 막음

        //     // 드롭시 데이터 받음
        //     // const draggedData = JSON.parse(event.dataTransfer.getData('text/plain'));
        //     const dataString = event.dataTransfer.getData('application/json');
        //     const droppedData = JSON.parse(dataString);
        //     // console.log('drop drop : ' + JSON.stringify(droppedData))

        //     const formData = new FormData();
        //     formData.append('EncFolder', sysEnums.zero);
        //     formData.append('EncUser', droppedData.EncUser);
        //     formData.append('EncPage', selectedPage.value.EncPage);
        //     axios.post(apiEnums.CloudTicket.createRole, formData)
        //         .then((response) => {
        //             if (response.data !== '0000') {
        //                 getUserPage();
        //             }
        //         })
        //         .catch(() => { });
        // };

        const handleDropRoleMove = (encRole, event) => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('application/json');
            const droppedData = JSON.parse(dataString);

            const formData = new FormData();
            formData.append('EncRole', droppedData.EncRole);
            formData.append('EncTarget', encRole);
            axios.post(apiEnums.CloudTicket.updateRole, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        getUserPage();
                        sendMessage(null, sysEnums.SignalTypes.Folder);
                    }
                })
                .catch(() => { });

            console.log(encRole + ' / ' + droppedData.EncRole)

            // if (droppedData.EncWorkspace) {
            //     updateWorkspace(droppedData.EncWorkspace, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncWorkspace)
            // } else {
            //     updateFolder(droppedData.EncFolder, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncFolder)
            // }
        }

        const confirmStep = computed(() => {
            if (selectedPage?.value?.PageRoles) {
                const index = selectedPage.value.PageRoles.findLastIndex(x => moment(x.Confirmed).format('YYYY') === "0000");
                return index; // 조건을 만족하는 요소가 없을 경우 0 반환
            }

            return 0;
        });

        // const filterList = computed(() => {
        //     let datas = [];
        //     if (searchEvent.value && searchEvent.value.target.value !== '') {
        //         datas = userList.value.filter(user => user.UserName.includes(searchEvent.value.target.value) || user.LoginId.includes(searchEvent.value.target.value));
        //     } else {
        //         datas = userList.value;
        //     }

        //     return datas;
        // });

        // const updateSearchEvent = (event) => {
        //     searchEvent.value = event;
        // };

        // const addGroup = (deptNum) => {
        //     if (!selectedPage.value) {
        //         // showAlert('Alert!', '페이지를 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
        //         return;
        //     }

        //     console.log(deptNum + ' / ' + selectedPage.value.EncFolder + ' / ' + JSON.stringify(selectedPage.value));

        //     const formData = new FormData();
        //     formData.append('Group', deptNum);
        //     formData.append('EncFolder', selectedPage.value.EncFolder);
        //     formData.append('EncPage', selectedPage.value.EncPage);
        //     axios.post(apiEnums.CloudTicket.createRoleFolderGroup, formData)
        //         .then((response) => {
        //             if (response.data !== '0000') {
        //                 getUserPage();
        //             }
        //         })
        //         .catch(() => { });
        // }

        return {
            props, sysEnums, enumTypes,
            userInfo, selectedDept,
            filteredFolder,
            selectedPage, roleTypes,
            getSignalStatus, widgetInfo,
            gotoModal, confirmStep,
            handleDropRoleMove,
            createRole, updateRole,
            getStatus, dragStart,
            getUserDepartment
        };
    },
};
</script>

<style scoped lang="scss"></style>
