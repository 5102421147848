//import store from '@/state/store';

export default [
    //{
    //    path: '/register',
    //    name: 'Register',
    //    component: () => import('../views/account/register'),
    //    meta: {
    //        title: 'Register',
    //        beforeResolve(routeTo, routeFrom, next) {
    //            // If the user is already logged in
    //            if (store.getters['auth/loggedIn']) {
    //                // Redirect to the home page instead
    //                next({ name: 'default' });
    //            } else {
    //                // Continue to the login page
    //                next();
    //            }
    //        },
    //    },
    //},
    //{
    //    path: '/forgot-password',
    //    name: 'Forgot password',
    //    component: () => import('../views/account/forgot-password'),
    //    meta: {
    //        title: 'Forgot Password',
    //        beforeResolve(routeTo, routeFrom, next) {
    //            // If the user is already logged in
    //            if (store.getters['auth/loggedIn']) {
    //                // Redirect to the home page instead
    //                next({ name: 'default' });
    //            } else {
    //                // Continue to the login page
    //                next();
    //            }
    //        },
    //    },
    //},
    {
        path: '/',
        name: 'home',
        meta: { title: 'PageClip', authRequired: true },
        component: () => import('../views/WebView'),
    },
    {
        path: '/app',
        name: 'app',
        meta: { title: 'PageClip', authRequired: true },
        component: () => import('../views/AppView'),
    },
    {
        path: '/login',
        name: 'login',
        meta: { index: 1 },
        component: () => import('../components/login/WebLogin'),
    },
    {
        path: '/applogin',
        name: 'appLogin',
        meta: { title: 'PageClip', authRequired: true },
        component: () => import('../components/login/AppLogin'),
    },
    {
        path: '/page',
        name: 'page',
        meta: { title: 'PageClip', authRequired: true },
        component: () => import('../views/WebView'),
    },
    {
        path: '/company',
        name: 'company',
        meta: { title: 'Company', authRequired: false },
        component: () => import('../components/companys/CompanyList'),
    },
    {
        path: '/notification',
        name: 'notification',
        meta: { title: 'Notification', authRequired: true },
        component: () => import('../components/notification/SendNoti'),
    },
    {
        path: '/user',
        name: 'user',
        meta: { title: 'User', authRequired: true },
        component: () => import('../components/users/UserList'),
    },
    {
        path: '/usercard',
        name: 'usercard',
        meta: { title: 'User', authRequired: true },
        component: () => import('../components/users/UserCard'),
    },
    {
        path: '/file',
        name: 'file',
        meta: { title: 'File', authRequired: true },
        component: () => import('../components/files/FileList'),
    },
    {
        path: '/schedule',
        name: 'schedule',
        meta: { title: 'Schedule', authRequired: true },
        component: () => import('../components/CompSchedule'),
    },
    {
        path: '/payment',
        name: 'payment',
        meta: { title: 'Payment', authRequired: true },
        component: () => import('../components/payment/PaymentList'),
    },
    {
        path: '/paystory',
        name: 'paystory',
        meta: { title: 'Paystory', authRequired: true },
        component: () => import('../components/payment/PayStoryList'),
    },
    {
        path: '/settlement',
        name: 'settlement',
        meta: { title: 'Settlement', authRequired: true },
        component: () => import('../components/payment/SettlementList'),
    },
    {
        path: '/biztalk',
        name: 'biztalk',
        meta: { title: 'biztalk', authRequired: true },
        component: () => import('../components/notification/BizTalk'),
    },
    {
        path: '/folder',
        name: 'folder',
        meta: { title: 'folder', authRequired: true },
        component: () => import('../components/folders/FolderList'),
    },

    {
        path: '/dashboard/crypto',
        name: 'dashboard-crypto',
        meta: {
            title: 'Crypto',
            authRequired: true,
        },
        component: () => import('../views/dashboard/crypto/index'),
    },
    {
        path: '/dashboard/projects',
        name: 'dashboard-projects',
        meta: {
            title: 'Projects',
            authRequired: true,
        },
        component: () => import('../views/dashboard/projects/index'),
    },
    {
        path: '/dashboard/crm',
        name: 'dashboard-crm',
        meta: {
            title: 'CRM',
            authRequired: true,
        },
        component: () => import('../views/dashboard/crm/index'),
    },
    {
        path: '/dashboard/analytics',
        name: 'dashboard-analytics',
        meta: {
            title: 'Analytics',
            authRequired: true,
        },
        component: () => import('../views/dashboard/analytics/index'),
    },
    {
        path: '/dashboard/nft',
        name: 'dashboard-nft',
        meta: {
            title: 'NFT Dashboard',
            authRequired: true,
        },
        component: () => import('../views/dashboard/nft/index'),
    },
    {
        path: '/dashboard/job',
        name: 'dashboard-job',
        meta: {
            title: 'Job Dashboard',
            authRequired: true,
        },
        component: () => import('../views/dashboard/job/index'),
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            title: 'Logout',
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear();
                sessionStorage.clear();
                next();
            },
        },
        component: () => import('../views/auth/logout/basic'),
    },

    {
        path: '/main-calendar',
        name: 'main-calendar',
        meta: { title: 'Calendar', authRequired: true },
        component: () => import('../views/calendar/main'),
    },
    {
        path: '/month-grid',
        name: 'month-grid',
        meta: { title: 'Month Grid', authRequired: true },
        component: () => import('../views/calendar/month'),
    },
];
