import { createApp, computed } from 'vue';
import App from './App.vue';
import router from './router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from './state/store';
import axios from 'axios';
import moment from 'moment';

import './registerServiceWorker';

import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from 'click-outside-vue3';
import VueApexCharts from 'vue3-apexcharts';
import Maska from 'maska';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/minimal/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap-icons/font/bootstrap-icons.css';

import widgetView from './layouts/WidgetView';
import widgetDetail from './layouts/WidgetDetail';
import modalView from './layouts/ModalView';

import Vue3DraggableResizable from 'vue3-draggable-resizable';
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css';

import directiveLoadImg from './common/directiveLoadImg';

moment.locale('ko');

const app = createApp(App);

app.component('WidgetView', widgetView);
app.component('WidgetDetail', widgetDetail);
app.component('ModalView', modalView);

const isDev = location.host.indexOf('www3') !== -1 || location.host.indexOf('app3') !== -1 || location.host.indexOf('localhost') !== -1 || process.env.NODE_ENV === 'development' ? true : false;

app.config.globalProperties.$cdnUrl = 'https://cdn' + (isDev ? '3' : '') + '.pageclip.ai/images/';
app.config.globalProperties.$fileUrl = 'https://cdn' + (isDev ? '3' : '') + '.pageclip.ai/files/';

app.config.globalProperties.$store = store;
app.config.globalProperties.$router = router;
app.config.globalProperties.$moment = moment;

app.config.productionTip = false;

axios.defaults.headers.common['ApiKey'] = 'nvzQ0EM8fdTxUqrQpsnCOdKwGm5Zjs5ucF-EHVu83uE';
axios.defaults.headers.post['Content-Type'] = 'application/json';

app.directive('load-img', directiveLoadImg);

// FakeBackend authentication
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();

// Firebase authentication
// import { initFirebaseBackend } from './authUtils';

// const firebaseConfig = {
//     apiKey: process.env.VUE_APP_APIKEY,
//     authDomain: process.env.VUE_APP_AUTHDOMAIN,
//     databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//     projectId: process.env.VUE_APP_PROJECTId,
//     storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.VUE_APP_MEASUREMENTID
// };

// initFirebaseBackend(firebaseConfig);

AOS.init({
    easing: 'ease-out-back',
    duration: 1200,
});

const pages = computed(() => store.getters['GE_PAGE']);
app.provide('$pages', pages);

const folders = computed(() => store.getters['GE_FOLDER']);
app.provide('$folders', folders);

const companys = computed(() => store.getters['GE_COMPANY']);
app.provide('$companys', companys);

// 채팅 가능한 전체 리스트
const chats = computed(() => store.getters['chat/GE_CHAT']);
app.provide('$chats', chats);

// // 채팅 접속중인 리스트
const signals = computed(() => store.getters['chat/GE_SIGNAL']);
app.provide('$signals', signals);

const users = computed(() => store.getters['GE_USERS']);
app.provide('$users', users);

const notis = computed(() => store.getters['GE_NOTI']);
app.provide('$notis', notis);

const files = computed(() => store.getters['GE_FILE']);
app.provide('$files', files);

const routeInfo = computed(() => store.getters['GE_ROUTE']);
app.provide('$routeInfo', routeInfo);

// const paydos = computed(() => store.getters['GE_PAYDO']);
// app.provide('$paydos', paydos);

// const bills = computed(() => store.getters['GE_BILL']);
// app.provide('$bills', bills);

const alarms = computed(() => store.getters['GE_ALARM']);
app.provide('$alarms', alarms);

const userInfo = computed(() => store.getters['GE_USER']);
app.provide('$userInfo', userInfo);

const widgetInfo = computed(() => store.getters['GE_WIDGET']);
app.provide('$widgetInfo', widgetInfo);

app.provide('$store', store);
app.provide('$axios', axios);
app.provide('$router', router);
app.provide('$moment', moment);

app.use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .use(Vue3DraggableResizable)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside)
    .mount('#app');
