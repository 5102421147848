<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <BRow>
            <BCol cols="12">
                <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                    <h5 class="mb-0 pb-1 text-decoration-underline">정산관리</h5>
                </div>
                <BRow>
                    <BCol xxl="3" lg="6">
                        <BCard no-body class="card-animate">
                            <BCardBody>
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="fw-medium text-muted mb-0">PG 수수료</p>
                                        <h2 class="mt-4 ff-secondary fw-semibold">
                                            {{ totalServiceFee.toLocaleString() }}원
                                        </h2>
                                        <p class="mb-0 text-muted">
                                        </p>
                                    </div>
                                    <div>
                                        <div class="avatar-sm flex-shrink-0">
                                            <span class="avatar-title bg-primary-subtle rounded-circle fs-2">
                                                <span class="text-primary bi-person"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </BCardBody>
                        </BCard>
                    </BCol>
                    <BCol xxl="3" lg="6">
                        <BCard no-body class="card-animate">
                            <BCardBody>
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="fw-medium text-muted mb-0">페이스토리 수수료</p>
                                        <h2 class="mt-4 ff-secondary fw-semibold">
                                            {{ totalPayStoryFee.toLocaleString() }}원
                                        </h2>
                                        <p class="mb-0 text-muted">
                                        </p>
                                    </div>
                                    <div>
                                        <div class="avatar-sm flex-shrink-0">
                                            <span class="avatar-title bg-primary-subtle rounded-circle fs-2">
                                                <span class="text-primary bi-person"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </BCardBody>
                        </BCard>
                    </BCol>
                    <BCol xxl="3" lg="6">
                        <BCard no-body class="card-animate">
                            <BCardBody>
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="fw-medium text-muted mb-0">파트너 수수료</p>
                                        <h2 class="mt-4 ff-secondary fw-semibold">
                                            {{ totalPartnerFee.toLocaleString() }}원
                                        </h2>
                                        <p class="mb-0 text-muted">
                                        </p>
                                    </div>
                                    <div>
                                        <div class="avatar-sm flex-shrink-0">
                                            <span class="avatar-title bg-primary-subtle rounded-circle fs-2">
                                                <span class="text-primary bi-person"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </BCardBody>
                        </BCard>
                    </BCol>
                    <BCol xxl="3" lg="6">
                        <BCard no-body class="card-animate">
                            <BCardBody>
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="fw-medium text-muted mb-0">HM 수수료</p>
                                        <h2 class="mt-4 ff-secondary fw-semibold">
                                            {{ totalPartnerFee.toLocaleString() }}원
                                        </h2>
                                        <p class="mb-0 text-muted">
                                        </p>
                                    </div>
                                    <div>
                                        <div class="avatar-sm flex-shrink-0">
                                            <span class="avatar-title bg-primary-subtle rounded-circle fs-2">
                                                <span class="text-primary bi-person"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </BCardBody>
                        </BCard>
                    </BCol>
                </BRow>
            </BCol>
        </BRow>

        <BRow>
            <BCol lg="12">
                <BCard no-body>
                    <BCardHeader class="justify-content-between d-flex align-items-center">
                        <BCardTitle>Settlement</BCardTitle>
                    </BCardHeader>
                    <BCardBody>
                        <ChartArea :Target="enumTypes.ChartTypes.Settlement"></ChartArea>
                    </BCardBody>
                </BCard>
            </BCol>
        </BRow>

        <BCard no-body class="card-animate">
            <BCardHeader class="border-0 align-items-center d-flex">
                <BCardTitle class="mb-0 flex-grow-1">{{ contractList.length }}건
                </BCardTitle>
            </BCardHeader>
            <BCardBody>
                <BRow>
                    <BCol cols="12">
                        <div class="px-2 bg-white">
                            <table class="table table-striped table-hover text-center">
                                <thead>
                                    <tr class="h-50h bg-light align-middle fw-bold">
                                        <th scope="col">번호</th>
                                        <th v-for="x in titles" :key="x">{{ x }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(x, i) in contractList" :key="x">
                                        <tr class="h-50h align-middle hand">
                                            <th class="w-5" scope="row">{{ i + 1 }}</th>
                                            <td class="text-start" v-text="x.Address1"></td>
                                            <td class="w-5" v-text="x.LessorName"></td>
                                            <td class="w-5" v-text="x.PartnerName"></td>
                                            <td class="w-5" v-text="x.LesseeName"></td>
                                            <td class="w-5" v-text="x.PaymentDay"></td>
                                            <td class="w-5" v-text="x.PayDos.length + ' / ' + getCotractPeriod(x.StartDate, x.EndDate)"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="x.PayDos ? $moment(x.PayDos.Created).format('YY.MM.DD') : '-'"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="(sysEnums.DiscoutTypes.Tax * (x.Rent + x.ManagementFee)).toLocaleString()"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="(x.FeeDiscountRate).toFixed(1) + '%'"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="(1 - (x.FeeDiscountRate)).toFixed(1) + '%'"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="getServiceFee(x.Rent, x.ManagementFee, x.FeeDiscountRate).toLocaleString()"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="getPayStoryFee(x.Rent, x.ManagementFee, x.FeeDiscountRate).toLocaleString()"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="getPartnerFee(x.Rent, x.ManagementFee, x.FeeDiscountRate).toLocaleString()">
                                            </td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="getPartnerFee(x.Rent, x.ManagementFee, x.FeeDiscountRate).toLocaleString()">
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </BCol>
                </BRow>
            </BCardBody>
        </BCard>

    </Layout>
</template>

<script lang="js">
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { computed, ref, inject } from 'vue';
import { apiEnums, sysEnums, enumTypes } from "@/common/sysEnums";
import { getCotractPeriod, getServiceFee, getPayStoryFee, getPartnerFee } from "@/common/comFuncs";
import ChartArea from "@/components/charts/ChartArea";

export default {
    components: {
        Layout,
        PageHeader,
        ChartArea,
    },
    props: {
        Data: Object,
        Title: Array,
    },
    setup(props) {
        const titles = ref(['시설', '임대인', '파트너', '임차인', '결제일', '회차', '결제일시', '수납금액', '할인률', '적용률', 'PG수수료', 'PS수수료', '파트너수수료', 'HM수수료']);

        const store = inject("$store");
        const axios = inject("$axios");

        const { contractList } = inject("$provideList");

        const totalServiceFee = computed(() => {
            return contractList.value.reduce((acc, cur) => {
                return acc + getServiceFee(cur.Rent, cur.ManagementFee, cur.FeeDiscountRate);
            }, 0);
        });

        const totalPayStoryFee = computed(() => {
            return contractList.value.reduce((acc, cur) => {
                return acc + getPayStoryFee(cur.Rent, cur.ManagementFee, cur.FeeDiscountRate);
            }, 0);
        });

        const totalPartnerFee = computed(() => {
            return contractList.value.reduce((acc, cur) => {
                const cardFee = cur.PayMid ? (cur.PayMid.CardFee || sysEnums.cardFee) : sysEnums.cardFee;
                return acc + getPartnerFee(cur.Rent, cur.ManagementFee, cur.FeeDiscountRate, cardFee);
            }, 0);
        });

        const setContractStatus = (encContract) => {
            const formData = new FormData();
            formData.append('EncContract', encContract);
            axios.post(apiEnums.CloudTicket.setContractStatus, formData)
                .then((response) => {
                    if (response.data === '1000') {
                        getContract();
                    }
                })
                .catch(() => { });
        };

        const getContract = () => {
            axios.post(apiEnums.CloudTicket.getContractConsole)
                .then((response) => {
                    const datas = response.data;
                    store.dispatch("AC_CONTRACT", datas);
                    contractList.value = datas;
                    // console.log(JSON.stringify(response.data))
                })
                .catch(() => { });
        };

        return {
            title: "Settlement",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "SettlementList",
                    active: true,
                },
            ],
            props, totalServiceFee, totalPayStoryFee, totalPartnerFee,
            titles, contractList, sysEnums, enumTypes,
            getCotractPeriod, getServiceFee, getPayStoryFee, getPartnerFee,
            setContractStatus,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable="true"] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
