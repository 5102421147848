<template>
    <BCard v-if="widgetInfo.modal" no-body>
        <BCardHeader class="align-items-center d-flex py-3">
            <BCardTitle class="mb-0 flex-grow-1">{{ selectedPage.Title }}</BCardTitle>
        </BCardHeader>

        <BCardBody class="px-3 pt-4 mx-n3 mb-2">
            <BRow class="g-3 px-3">
                <BCol cols="12">
                    <label for="exampleFormControlTextarea1" class="form-label text-body">Leave a Comments</label>
                    <span v-if="widgetInfo.dataModal.length !== 0" class="ps-2 fw-bold fst-italic">
                        {{ widgetInfo.dataModal.UserName }}
                    </span>
                    <textarea
                        class="form-control bg-light border-light"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="댓글을 작성해 주세요."
                        v-model="widgetInfo.dataModal.Content"
                    ></textarea>
                </BCol>
                <BCol cols="12" class="text-end">
                    <span v-if="widgetInfo.encComment !== ''">
                        <span v-if="widgetInfo.dataModal.EncUser === userInfo.EncUser">
                            <BButton variant="success" class="me-2" @click="widgetInfo.dataModal = []"><i class="ri-loader-3-line"></i> 초기화</BButton>
                            <BButton variant="danger" class="me-2" @click="removeComment"><i class="ri-edit-line"></i> 댓글삭제</BButton>
                            <BButton variant="danger" class="me-2" @click="updateComment(true)"
                                ><i :class="widgetInfo.dataModal.Locked ? 'ri-lock-unlock-line' : 'ri-lock-line'"></i> 나만보기</BButton
                            >
                            <BButton variant="primary" @click="updateComment(false)"><i class="ri-edit-line"></i> 댓글수정</BButton>
                        </span>
                        <span v-else>
                            <BButton variant="primary" @click="createReply"><i class="mdi mdi-reply"></i> Reply</BButton>
                        </span>
                    </span>

                    <span v-else-if="widgetInfo.dataModal.length === 0">
                        <BButton variant="primary" @click="createComment"><i class="ri-arrow-up-line"></i> 댓글작성</BButton>
                    </span>

                    <!-- <span v-else>
                        <i class="ri-loader-3-line me-3 text-success fs-26" @click="widgetInfo.dataModal = []"></i>
                        <i class="ri-delete-bin-line text-danger fs-26 me-3" @click="removeComment"></i>
                        <i class="fs-26 me-3" :class="widgetInfo.dataModal.Locked ? 'ri-lock-unlock-line text-primary' : 'ri-lock-line text-warning'"
                            @click="updateComment(true)"></i>
                        <i class="ri-edit-line text-dark fs-26 me-2" @click="updateComment(false)"></i>

                        <BButton variant="success" class="me-2" @click="widgetInfo.dataModal = []"><i class="ri-loader-3-line"></i> 초기화</BButton>
                        <BButton variant="danger" class="me-2" @click="removeComment"><i class="ri-edit-line"></i> 댓글삭제</BButton>
                        <BButton variant="danger" class="me-2" @click="updateComment(true)"><i :class="widgetInfo.dataModal.Locked ? 'ri-lock-unlock-line' : 'ri-lock-line'"></i>
                            나만보기</BButton>
                        <BButton variant="primary" @click="updateComment(false)"><i class="ri-edit-line"></i> 댓글수정</BButton>
                    </span> -->
                </BCol>
            </BRow>
        </BCardBody>
    </BCard>

    <BCard v-else no-body>
        <BCardHeader class="align-items-center d-flex py-3">
            <BCardTitle class="mb-0 flex-grow-1"
                >Comments <BBadge v-if="selectedPage.Comments && selectedPage.Comments.length > 0" variant="danger" pill>{{ selectedPage.Comments.length }}</BBadge>
            </BCardTitle>
            <div class="flex-shrink-0">
                <BButton variant="success" @click="gotoModal(null, 'PageComment', '댓글작성')">Page Comments</BButton>
                <!-- <BDropdown variant="link" class="card-header-dropdown" toggle-class="text-reset dropdown-btn arrow-none" menu-class="dropdown-menu-end" aria-haspopup="true">
                    <template #button-content> <span class="text-muted">Recent<i class="mdi mdi-chevron-down ms-1"></i></span>
                    </template>
<BDropdownItem>Recent</BDropdownItem>
<BDropdownItem>Top Rated</BDropdownItem>
<BDropdownItem>Last 7 DaysPrevious</BDropdownItem>
</BDropdown> -->
            </div>
        </BCardHeader>

        <BCardBody class="px-3 pt-4 mx-n3 mb-2">
            <TreeComment :model="treeData"></TreeComment>
        </BCardBody>
    </BCard>
</template>

<script lang="js">
import { showConfirm } from "@/common/comFuncs";
import { apiEnums, sysEnums } from "@/common/sysEnums";
import { inject, onMounted, ref, watch } from "vue";

import TreeComment from '@/components/tree-comment';

export default {
    props: {
        Content: String,
        Datas: Array,
    },
    components: {
        TreeComment
    },
    setup(props) {
        const treeData = ref({ Name: 'COMMENT', children: [] });

        const axios = inject("$axios");

        const pages = inject('$pages');
        const userInfo = inject('$userInfo');
        const widgetInfo = inject("$widgetInfo");

        const { selectedFolder, selectedPage, getUserPage, gotoModal } = inject("$provideList");

        watch(
            [() => selectedFolder.value, () => selectedPage.value],
            ([newFolderValue, newPageValue]) => {
                if ((newFolderValue !== undefined && newFolderValue !== null) || (newPageValue !== undefined && newPageValue !== null)) {
                    buildTree();
                }
            }
        );

        onMounted(() => {
            setTimeout(() => {
                buildTree();
            }, 900);
        });

        // const commentList = computed(() => {
        //     let filteredLists = [];

        //     if (typeof selectedPage.value.EncPage === 'string' && selectedPage.value.EncPage.length > 10) {
        //         // 선택한 폴더의 하위 폴더 리스트 반환
        //         filteredLists = pages.value.filter(x => x.EncPage === selectedPage.value.EncPage);
        //     } else {
        //         // 모든 최상위 폴더의 리스트 반환
        //         filteredLists = pages.value;
        //     }

        //     return filteredLists;
        // });

        const createReply = () => {
            if (!widgetInfo.value.encComment) {
                // showAlert('Alert!', '페이지를 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            const formData = new FormData();
            formData.append('EncPage', selectedPage.value.EncPage);
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncComment', sysEnums.zero);
            formData.append('EncReply', widgetInfo.value.encComment);
            formData.append('Content', widgetInfo.value.dataModal.Content);
            axios.post(apiEnums.CloudTicket.createComment, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        getUserPage();
                        widgetInfo.value.dataModal = [];
                        widgetInfo.value.modal = false;
                    }
                })
                .catch(() => { });
        }

        const createComment = () => {
            if (!selectedPage.value || !widgetInfo.value.dataModal.Content) {
                // showAlert('Alert!', '페이지를 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            const formData = new FormData();
            formData.append('EncPage', selectedPage.value.EncPage);
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncComment', sysEnums.zero);
            formData.append('EncReply', sysEnums.zero);
            formData.append('Content', widgetInfo.value.dataModal.Content);
            axios.post(apiEnums.CloudTicket.createComment, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        getUserPage();
                        widgetInfo.value.dataModal = [];
                    }
                })
                .catch(() => { });
        }

        const removeComment = () => {
            if (!widgetInfo.value.dataModal.Content) return;

            showConfirm('', '댓글을 삭제 합니다.', '삭제하기').then((result) => {
                if (result) {
                    const formData = new FormData();
                    formData.append('EncUser', userInfo.value.EncUser);
                    formData.append('EncComment', widgetInfo.value.dataModal.EncComment);
                    formData.append('EncReply', sysEnums.zero);
                    formData.append('Status', 1);
                    axios.post(apiEnums.CloudTicket.createComment, formData)
                        .then((response) => {
                            if (response.data !== '0000') {
                                getUserPage();
                                widgetInfo.value.dataModal = [];
                            }
                        })
                        .catch(() => { });
                }
            });
        }

        const updateComment = (flag) => {
            if (!widgetInfo.value.dataModal.Content) return;

            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncComment', widgetInfo.value.dataModal.EncComment);
            formData.append('EncReply', sysEnums.zero);
            formData.append('Content', widgetInfo.value.dataModal.Content);
            if (flag) formData.append('Locked', 1);

            axios.post(apiEnums.CloudTicket.createComment, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        getUserPage();
                        widgetInfo.value.dataModal = [];
                    }
                })
                .catch(() => { });
        }

        const buildTree = () => {
            // console.log(JSON.stringify(datas, null, 4));

            const lists = [];
            const items = {};

            // Ensure selectedPage and its Comments are properly checked
            if (selectedFolder.value && selectedFolder.value.EncFolder) {
                // console.log('selectedFolder selected')

                const filteredPage = pages.value.filter(x => x.EncFolder === selectedFolder.value.EncFolder)
                if (filteredPage && filteredPage.length > 0) {
                    filteredPage.forEach(item => {
                        if (item.Comments && item.Comments.length > 0) {
                            let titleAdded = false;
                            const modifiedComments = item.Comments.map(comment => {
                                if (!titleAdded && comment.EncReply === sysEnums.zero) {
                                    titleAdded = true;
                                    return { ...comment, Title: item.Title };
                                }
                                return comment;
                            });
                            lists.push(...modifiedComments);
                        }
                    });
                }
            }

            else if (selectedPage.value && !selectedPage.value.Comments) {
                // console.log('selectedPage selected')

                if (pages.value && pages.value.length > 0) {
                    pages.value.forEach(item => {
                        if (item.Comments && item.Comments.length > 0) {
                            let titleAdded = false;
                            const modifiedComments = item.Comments.map(comment => {
                                if (!titleAdded && comment.EncReply === sysEnums.zero) {
                                    titleAdded = true;
                                    return { ...comment, Title: item.Title };
                                }
                                return comment;
                            });
                            lists.push(...modifiedComments);
                        }
                    });
                }
            }

            // Correctly assign datas based on lists content
            let datas = (selectedPage.value.Comments && selectedPage.value.Comments.length > 0) ? selectedPage.value.Comments : lists;

            // Create items object for easy lookup by EncFolder
            datas.forEach(item => {
                items[item.EncComment] = { ...item, children: [] };
            });

            // Build tree structure
            datas.forEach(item => {
                if (item.EncReply) {
                    const parent = items[item.EncReply];
                    if (parent) {
                        parent.children.push(items[item.EncComment]);
                    }
                }
            });

            // Remove non-root items from the items object
            Object.values(items).forEach(item => {
                if (item.EncComment && item.EncReply !== sysEnums.zero) {
                    delete items[item.EncComment];
                }
            });

            // Convert tree object to array format
            treeData.value.children = Object.values(items);
        };

        return {
            props, sysEnums, userInfo, widgetInfo,
            selectedPage, treeData, gotoModal,
            createReply, createComment,
            updateComment, removeComment
        }
    },
};
</script>

<style scoped lang="scss"></style>
