// 로딩 스피너 HTML 엘리먼트 추가
const spinnerHtml = `
  <div id="loading-spinner" class="spinner-grow text-primary" style="display: none;">
    <!-- 로딩 스피너의 내용 및 스타일을 여기에 추가하세요 -->
  </div>
`;

document.body.insertAdjacentHTML('beforeend', spinnerHtml);

const loadImageDirective = {
    mounted(el) {
        const imageLoad = (targetElement) => {
            const imgElement = targetElement;
            imgElement.setAttribute('src', imgElement.getAttribute('img-src'));
            imgElement.onload = function () {
                imgElement.removeAttribute('img-src');
                hideLoader(); // 로딩 스피너 숨기기
            };
        };

        const hideLoader = () => {
            const loader = document.getElementById('loading-spinner');
            if (loader) {
                loader.style.display = 'none';
            }
        };

        const showLoader = () => {
            const loader = document.getElementById('loading-spinner');
            if (loader) {
                loader.style.display = 'block';
            }
        };

        const callIntersectionApi = () => {
            const options = {
                root: null,
                threshold: 0.5,
                rootMargin: '0px'
            };

            const lazyLoadCallback = (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        showLoader(); // 로딩 스피너 표시
                        imageLoad(entry.target);
                        observer.unobserve(entry.target);
                    }
                });
            };

            const lazyLoadingIO = new IntersectionObserver(lazyLoadCallback, options);
            lazyLoadingIO.observe(el);
        };

        // 지원되는 브라우저가 있기 때문에, 이런 식으로 처리
        window.IntersectionObserver ? callIntersectionApi() : imageLoad(el);
    }
};

export default loadImageDirective; // export 추가

