<script>
import { showConfirm } from '@/common/comFuncs';
import { apiEnums, sysEnums } from '@/common/sysEnums';
import { inject } from 'vue';

export default {
    setup() {
        const axios = inject('$axios');

        const { selectedClips, setClipUpdate, getUserClip } = inject('$provideList');

        const removeClip = file => {
            showConfirm('', '클립 이미지를 삭제 합니다?', '삭제하기').then(result => {
                if (result) {
                    const formData = new FormData();
                    formData.append('EncClip', file.EncClip);
                    axios
                        .post(apiEnums.CloudTicket.removeImageClip, formData)
                        .then(response => {
                            if (response.data !== '0000') {
                                getUserClip();
                            }
                        })
                        .catch(() => {});
                }
            });
        };

        return {
            sysEnums,
            selectedClips,
            setClipUpdate,
            removeClip,
        };
    },
    components: {},
};
</script>

<template>
    <BRow>
        <BCol lg="12">
            <BCard no-body>
                <BCardBody>
                    <!-- <div class="mt-2">
                        <label class="form-label" for="product-title-input">Page Title</label>
                    </div> -->

                    <template v-for="data in selectedClips" :key="data">
                        <div v-if="!data.Status" class="d-flex align-items-center pb-2 mb-2 border-bottom">
                            <span v-if="data.Type === sysEnums.ClipTypes.Image"><i class="ri-image-line"></i></span>
                            <span v-else-if="data.Type === sysEnums.ClipTypes.Draw"><i class="ri-brush-line"></i></span>
                            <span v-else-if="data.Type === sysEnums.ClipTypes.Table"><i class="ri-table-2"></i></span>
                            <span v-else><i class="ri-edit-box"></i></span>
                            <span class="px-2">{{ data.ClipNum }}</span>
                            <span class="flex-fill ps-2">{{
                                data.Content && data.Content.length > 50 ? data.Content.substring(0, 50) : data.Content.length > 40 ? data.Content.substring(0, 40) : data.Content
                            }}</span>
                            <i v-if="data.Type === sysEnums.ClipTypes.Image" class="ri-eraser-line pe-4 text-danger hand" @click="removeClip(data)"></i>
                            <i class="ri-eye-line pe-4 hand" @click="setClipUpdate(data, sysEnums.ClipHandles.Status)"></i>
                            <span v-text="$moment(data.Created).format('YY-MM-DD hh:mm')"></span>
                        </div>
                    </template>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>
</template>
