<template>
    <img
        class="img-clip"
        :src="$fileUrl + props.modelValue.Content"
        :style="{ transform: 'rotate(' + rotate + 'deg)' }"
        :data-aos="props.idx % 2 === 0 ? 'fade-up' : 'fade-down'"
    />

    <!-- <span v-if="props.modelValue.isActive" class="d-flex position-absolute top-0 left-0 w-100 p-2 bg-dark text-light opacity-50">
        <i :class="props.modelValue.Fixed ? 'ri-pushpin-line' : 'ri-pushpin-2-line'" @click="setClip(sysEnums.ClipHandles.Fix)"></i>
        <i class="ri-sort-asc ms-3" @click="setClip(sysEnums.ClipHandles.SortUp)"></i>
        <i class="ri-sort-desc ms-3" @click="setClip(sysEnums.ClipHandles.SortDown)"></i>
        <i class="ri-bring-forward ms-3" @click="setClip(sysEnums.ClipHandles.SortUp)"></i>
        <i class="ri-delete-bin-line ms-3" @click="setClip(sysEnums.ClipHandles.Del)"></i>
        <i class="ri-anticlockwise-line ms-3" @click="rotateClip(true)"></i>
        <i class="ri-clockwise-line ms-3" @click="rotateClip(false)"></i>
        <span class="flex-fill"></span>
        <span v-text="$moment(props.modelValue.Created).format('YY-MM-DD hh:mm')"></span>
    </span> -->

    <span v-show="props.modelValue.isActive" class="position-absolute top-0 end-0 p-2">
        <span class="p-2 bg-dark text-light rounded-circle">
            <i class="ri-anticlockwise-line" @click="rotateClip(true)"></i>
        </span>

        <span class="p-2 ms-2 bg-dark text-light rounded-circle">
            <i class="ri-clockwise-line" @click="rotateClip(false)"></i>
        </span>

        <span class="p-2 ms-2 bg-dark text-light rounded-circle">
            <i class="ri-eye-off-line" @click="setClip(sysEnums.ClipHandles.Hided)"></i>
        </span>

        <span class="p-2 ms-2 bg-danger text-light rounded-circle">
            <i class="ri-delete-bin-line" @click="setClip(sysEnums.ClipHandles.Del)"></i>
        </span>
    </span>

    <span v-show="props.modelValue.isActive" class="position-absolute bottom-0 end-0 p-2">
        <BBadge variant="primary">{{ props.modelValue.ClipNum + ' / ' + $moment(props.modelValue.Created).format('YY-MM-DD hh:mm') }}</BBadge>
    </span>

    <!-- <span v-if="props.modelValue.isActive" class="d-flex w-100 position-absolute bottom-0 left-0 p-2 bg-dark text-light opacity-50 fs-10">
        {{ $fileUrl + props.modelValue.Content }}
    </span> -->
</template>

<script>
import { apiEnums, sysEnums } from '@/common/sysEnums';
import { inject, onMounted, ref } from 'vue';

export default {
    // emits: ['update:imgHided'],
    props: {
        modelValue: Object,
        idx: Number,
    },
    setup(props) {
        const rotate = ref(0);

        const axios = inject('$axios');

        // const widgetInfo = inject("$widgetInfo");
        // const { emit } = getCurrentInstance();

        const { clipList, setClipSort, setClipUpdate } = inject('$provideList');

        onMounted(() => {
            rotate.value = props.modelValue.Rotate;
            // console.log('ClipImage onMounted : ' + JSON.stringify(props.modelValue))
        });

        // const adjustImageSize = () => {
        //     const imageRef = resizeDiv.value.querySelector('.img-fit');
        //     if (!imageRef || !imageRef.complete) return; // Return if image not loaded

        //     const aspectRatio = imageRef.naturalWidth / imageRef.naturalHeight;
        //     const resizeDivHeight = resizeDiv.value.clientHeight;
        //     const newHeight = (resizeDivHeight * sliderValue.value) / 100;
        //     const newWidth = newHeight * aspectRatio;
        // };

        const rotateClip = flag => {
            if (flag) {
                rotate.value -= 90;
                if (rotate.value <= -360) rotate.value = 0;
            } else {
                rotate.value += 90;
                if (rotate.value >= 360) rotate.value = 0;
            }

            if (props.modelValue.EncUser) {
                const formData = new FormData();
                formData.append('EncUser', props.modelValue.EncUser);
                formData.append('EncClip', props.modelValue.EncClip);
                formData.append('Rotate', rotate.value);
                axios
                    .post(apiEnums.CloudTicket.updateClip, formData)
                    .then(response => {
                        if (response.data !== '0000') {
                            // console.log('rotateClip : ' + JSON.stringify(response.data, null, 4));
                        }
                    })
                    .catch(() => {});
            }
        };

        const setClip = flag => {
            if (flag === sysEnums.ClipHandles.SortUp || flag === sysEnums.ClipHandles.SortDown) {
                setClipSort(props.modelValue, flag);
            } else {
                setClipUpdate(props.modelValue, flag);
            }
        };

        return {
            props,
            sysEnums,
            clipList,
            rotate,
            setClip,
            rotateClip,
        };
    },
};
</script>

<style scoped>
i {
    font-size: 20px;
    cursor: pointer;
}

.img-clip {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    /* transition: transform 0.2s ease, max-width 0.3s ease, max-height 0.3s ease; */
    transition: transform 0.1s ease;
}
</style>
