<template>
    <div v-if="editor && props.modelValue.isActive">
        <bubble-menu class="floating-menu px-2" :tippy-options="{ duration: 100 }" :editor="editor">
            <!-- <button @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()">insertTable</button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().addColumnBefore().run()" :disabled="!editor.can().addColumnBefore()">addColumnBefore</button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().addColumnAfter().run()" :disabled="!editor.can().addColumnAfter()">addColumnAfter</button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().deleteColumn().run()" :disabled="!editor.can().deleteColumn()">deleteColumn</button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().addRowBefore().run()" :disabled="!editor.can().addRowBefore()">addRowBefore</button>
            <button @click="editor.chain().focus().addRowAfter().run()" :disabled="!editor.can().addRowAfter()">addRowAfter</button>
            <button @click="editor.chain().focus().deleteRow().run()" :disabled="!editor.can().deleteRow()">deleteRow</button>
            <button @click="editor.chain().focus().deleteTable().run()" :disabled="!editor.can().deleteTable()">deleteTable</button>
            <button @click="editor.chain().focus().mergeCells().run()" :disabled="!editor.can().mergeCells()">mergeCells</button>
            <button @click="editor.chain().focus().splitCell().run()" :disabled="!editor.can().splitCell()">splitCell</button>
            <button @click="editor.chain().focus().toggleHeaderColumn().run()" :disabled="!editor.can().toggleHeaderColumn()">toggleHeaderColumn</button>
            <button @click="editor.chain().focus().toggleHeaderRow().run()" :disabled="!editor.can().toggleHeaderRow()">toggleHeaderRow</button>
            <button @click="editor.chain().focus().toggleHeaderCell().run()" :disabled="!editor.can().toggleHeaderCell()">toggleHeaderCell</button>
            <button @click="editor.chain().focus().mergeOrSplit().run()" :disabled="!editor.can().mergeOrSplit()">mergeOrSplit</button>
            <button @click="editor.chain().focus().setCellAttribute('backgroundColor', '#FAF594').run()"
                :disabled="!editor.can().setCellAttribute('backgroundColor', '#FAF594')">setCellAttribute</button>
            <button @click="editor.chain().focus().fixTables().run()" :disabled="!editor.can().fixTables()">fixTables</button>
            <button @click="editor.chain().focus().goToNextCell().run()" :disabled="!editor.can().goToNextCell()">goToNextCell</button>
            <button @click="editor.chain().focus().goToPreviousCell().run()" :disabled="!editor.can().goToPreviousCell()">goToPreviousCell</button> -->

            <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }"><i class="ri-bold"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }"><i class="ri-italic"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }"><i class="ri-strikethrough"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                <i class="ri-heading"></i><i class="ri-number-1"></i>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                <i class="ri-heading"></i><i class="ri-number-2"></i>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
                <i class="ri-heading"></i><i class="ri-number-3"></i>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
                <i class="ri-heading"></i><i class="ri-number-4"></i>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
                <i class="ri-heading"></i><i class="ri-number-5"></i>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
                <i class="ri-heading"></i><i class="ri-number-6"></i>
            </button>
        </bubble-menu>
    </div>

    <div class="position-relative" @mouseleave="handleUpdate">
        <!-- {{ parsedData }} -->

        <editor-content :editor="editor" />

        <span v-show="props.modelValue.isActive" class="position-absolute top-0 end-0 pe-2">
            <!-- <span class="p-2 bg-dark text-light rounded-circle">
                <i :class="props.modelValue.Fixed ? 'ri-pushpin-line' : 'ri-pushpin-2-line'" @click="setClip(sysEnums.ClipHandles.Fix)"></i>
            </span> -->

            <!-- <i class="ri-sort-asc ms-3" @click="setClip(sysEnums.ClipHandles.SortUp)"></i>
            <i class="ri-sort-desc ms-3" @click="setClip(sysEnums.ClipHandles.SortDown)"></i> -->

            <span class="p-2 ms-2 bg-dark text-light rounded-circle">
                <i class="ri-eye-off-line" @click="setClip(sysEnums.ClipHandles.Hided)"></i>
            </span>
        </span>

        <span v-show="props.modelValue.isActive" class="position-absolute bottom-0 end-0 p-2">
            <BBadge variant="primary">{{ $moment(props.modelValue.Created).format('YY-MM-DD hh:mm') }}</BBadge>
        </span>
    </div>
</template>

<script>
import { apiEnums, sysEnums } from '@/common/sysEnums';
import { getCurrentInstance, inject, onBeforeUnmount, onMounted, ref } from 'vue';

import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import StarterKit from '@tiptap/starter-kit';
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/vue-3';

export default {
    emits: ['update:modelValue'],
    components: {
        EditorContent,
        BubbleMenu,
    },
    props: {
        modelValue: Object,
        idx: Number,
    },
    setup(props) {
        const clipContent = ref('');

        const axios = inject('$axios');

        const { setClipFix, setClipSort, setClipUpdate } = inject('$provideList');

        const { emit } = getCurrentInstance();

        // const CustomTableCell = TableCell.extend({
        //     addAttributes() {
        //         return {
        //             // extend the existing attributes …
        //             ...this.parent?.(),

        //             // and add a new one …
        //             backgroundColor: {
        //                 default: null,
        //                 parseHTML: element => element.getAttribute('data-background-color'),
        //                 renderHTML: attributes => {
        //                     return {
        //                         'data-background-color': attributes.backgroundColor,
        //                         style: `background-color: ${attributes.backgroundColor}`,
        //                     }
        //                 },
        //             },
        //         }
        //     },
        // })

        // const parsedData = computed(() => {
        //     return editor.value && editor.value.getJSON();
        // });

        const editor = useEditor({
            extensions: [
                StarterKit,
                Table.configure({
                    resizable: true,
                    // allowTableNodeSelection: true,
                }),
                TableRow,
                TableHeader,
                TableCell,
                // Custom TableCell with backgroundColor attribute
                // CustomTableCell,
            ],
            content: props.modelValue.Content,
            onUpdate: () => {
                emit('update:modelValue', editor.value.getHTML());
            },
        });

        onMounted(() => {
            clipContent.value = editor.value.getHTML();
            // editor.value.commands.setContent(props.modelValue.Content, false);
        });

        onBeforeUnmount(() => {
            editor.value.destroy();
        });

        const handleUpdate = () => {
            if (props.modelValue.isActive && clipContent.value !== editor.value.getHTML()) {
                // console.log('handleMouseLeave : ' + editor.value.getHTML() + ' / ' + props.modelValue.Content);
                const formData = new FormData();
                formData.append('EncUser', props.modelValue.EncUser);
                formData.append('EncClip', props.modelValue.EncClip);
                formData.append('Content', editor.value.getHTML());
                axios
                    .post(apiEnums.CloudTicket.updateClip, formData)
                    .then(response => {
                        if (response.data !== '0000') {
                            clipContent.value = response.data.Content;
                            editor.value.commands.setContent(clipContent.value, false);
                            // emit('update:modelValue', response.data);
                            console.log('handleUpdate ClipTable responsed : ' + props.modelValue.Content);
                        }
                    })
                    .catch(() => {});
            }
        };

        const setClip = flag => {
            if (flag === sysEnums.ClipHandles.Fix) {
                setClipFix(props.modelValue);
            }

            if (flag === sysEnums.ClipHandles.SortUp || flag === sysEnums.ClipHandles.SortDown) {
                setClipSort(props.modelValue, flag);
            }

            if (flag === sysEnums.ClipHandles.Hided) {
                setClipUpdate(props.modelValue, flag);
            }
        };

        return {
            props,
            editor,
            sysEnums,
            setClip,
            handleUpdate,
        };
    },
};
</script>

<style lang="scss"></style>
