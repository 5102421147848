<template>
    <label class="w-100 mb-0 hand" for="uploadFiles">
        <img v-if="fileData.EncUser" :src="fileData.Avatar ? $fileUrl + fileData.Avatar : $cdnUrl + 'no-profile.jpg'" class="avatar-sm rounded-circle" />
        <img v-else :src="userInfo.Avatar ? $fileUrl + userInfo.Avatar : $cdnUrl + 'no-profile.jpg'" class="avatar-xl rounded-circle border border-5 border-light" />
        <input ref="selectBtn" id="uploadFiles" type="file" hidden @change="fileList" />
        <!-- <button ref="uploadBtn" @click="fileUploadSingle"></button> -->
    </label>
</template>

<script lang="js">
import { ref, inject, getCurrentInstance } from "vue";
import { apiEnums, sysEnums } from "@/common/sysEnums";
import Compressor from 'compressorjs';

export default {
    emits: ['SingleFileUploaded'],
    props: {
        FileType: Number,
    },
    setup(props) {
        let uploadFiles = ref([]);
        const fileData = ref({});
        const selectBtn = ref(null);

        const axios = inject("$axios");
        // const store = inject("$store");

        const userInfo = inject("$userInfo");

        const { emit } = getCurrentInstance();

        const { getCompany, getUserFolder } = inject("$provideList");

        const fileList = async (event) => {
            const files = Array.from(event.target.files);

            if (files.length > 0) {
                uploadFiles.value = [];

                for (const file of files) {
                    await new Promise((resolve) => {
                        new Compressor(file, {
                            quality: 0.6,
                            maxWidth: 600,
                            maxHeight: 600,
                            success(result) {
                                // 기존 파일의 이름과 확장자를 그대로 사용
                                const resizedFile = new File([result], file.name, { type: result.type });
                                uploadFiles.value.push(resizedFile);
                                resolve(); // 압축이 완료되면 Promise를 해결하여 다음 파일로 진행
                            },
                            error(err) {
                                console.log(err.message);
                                resolve(); // 에러가 발생하더라도 Promise를 해결하여 다음 파일로 진행
                            },
                        });
                    });
                }

                fileUploadSingle(uploadFiles.value);

                // setTimeout(() => {
                //     uploadBtn.value.click();
                // }, 100)
            }
        };


        const fileUploadSingle = (files) => {
            const formData = new FormData();

            formData.append('EncUser', props.FileType === sysEnums.FileTypes.Folder ? fileData.value.EncFolder :
                props.FileType === sysEnums.FileTypes.Company ? fileData.value.EncCompany :
                    fileData.value.EncUser ? fileData.value.EncUser : userInfo.value.EncUser);

            formData.append('EncContent', sysEnums.zero);
            formData.append('Category', props.FileType);
            Array.from(files).forEach((item) => {
                formData.append('Files', item);
            });

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            };

            try {
                axios.post(apiEnums.CloudTicket.fileUpload, formData, config)
                    .then(() => {
                        fileUploadedSingle();
                    }).catch(() => { })
            } catch (e) { console.log(e) }
        };

        // 상위 컴포넌트에 입력 데이터 전달 에서 vue로 변경
        const fileUploadedSingle = () => {
            uploadFiles.value = [];

            if (props.FileType === sysEnums.FileTypes.Folder) {
                getUserFolder();
            } else if (props.FileType === sysEnums.FileTypes.Company) {
                getCompany();
            } else if (props.FileType === sysEnums.FileTypes.Avatar) {
                emit('SingleFileUploaded', null);
                // userAvatarUploaded();
            } else if (fileData.value.EncUser) {
                emit('SingleFileUploaded', null);
            }

            // if (result.status === 200) {
            //     showAlert('', '이미지가 변경 되었습니다', 'HouseMeta', sysEnums.AlertTypes.Info);
            // }
        };

        // const userAvatarUploaded = () => {
        //     const formData = new FormData();
        //     formData.append('EncUser', userInfo.value.EncUser);
        //     axios.post(apiEnums.CloudTicket.getUserInfo, formData)
        //         .then((response) => {
        //             if (response.data.Avatar) {
        //                 userInfo.value.Avatar = response.data.Avatar;
        //             }
        //         })
        //         .catch(() => { });
        // }

        const setFileData = (data) => {
            fileData.value = data;

            setTimeout(() => {
                selectBtn.value.click();
            }, 100)
            // console.log(JSON.stringify(fileData.value));
        }

        return {
            props, fileData,
            userInfo, uploadFiles, selectBtn, getCompany,
            fileList, setFileData
        };
    },
};
</script>

<style scoped lang="scss"></style>


<!-- Avatar = 10,
Facility = 11,
Contract = 12,
Partner = 13,
Chat = 15,
Documents = 20, -->