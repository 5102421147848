<template>
    <div class="container-fluid">
        <div v-if="props.FileType !== sysEnums.FileTypes.File" v-show="selectedFiles.length > 0" class="pt-4 mb-4">
            <div class="progress animated-progress custom-progress progress-label">
                <div class="progress-bar bg-danger" role="progressbar" :style="'width:' + progress + '%'" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
                    <div class="label">{{ progress }}</div>
                </div>
            </div>
        </div>

        <div v-if="props.FileType === sysEnums.FileTypes.File">
            <!-- 이미지 섬네일 없는 타입 -->
            <div class="d-flex align-items-center p-2 mb-2" v-for="(file, i) in selectedFiles" :key="i">
                <span class="flex-grow-1">{{ file.name }}</span>
                <span class="me-2" v-html="(uploadFiles[i].size / 1000000).toFixed(1) + 'Mb'"></span>
                <div class="profile-user position-relative d-inline-block mx-auto mb-2">
                    <i class="bi-x-lg rounded-circle avatar-xs img-thumbnail" @click="removeFile(i)"></i>
                </div>
            </div>
        </div>
        <div class="row" v-else-if="props.FileType === sysEnums.FileTypes.Clip">
            <!-- 이미지 섬네일 1/4 고정-->
            <div class="col-3 p-1" v-for="(file, i) in selectedFiles" :key="i">
                <img class="img-fit rounded" :src="file" />
                <span class="position-absolute end-0 top-0 text-end" @click="removeFile(i)">
                    <i class="bi-x-lg status-descript p-1 rounded"></i>
                </span>
            </div>
        </div>
        <div class="row" v-else>
            <!-- 이미지 섬네일 자동지정 -->
            <div :class="'col-' + 12 / selectedFiles.length + ' p-1'" v-for="(file, i) in selectedFiles" :key="i">
                <img class="img-fit rounded" :src="file" />
                <span class="position-absolute end-0 top-0 text-end" @click="removeFile(i)">
                    <i class="bi-x-lg status-descript p-1 rounded"></i>
                    <!-- <p class="mt-3 fs-12 status-descript p-2 rounded-pill" v-html="(uploadFiles[i].size / 1000000).toFixed(1) + 'Mb'"></p> -->
                </span>
            </div>
        </div>

        <div class="text-center mb-2">
            <input ref="uploadFilesInput" id="uploadFiles" v-on:change="fileList" type="file" hidden multiple />
            <label for="uploadFiles" class="hand" @click="handleSelectImage"></label><br />
            <button v-show="selectedFiles.length > 0 && IsBtn" class="w-100 mb-4 h-50h btn btn-primary" @click="fileUpload" v-html="'파일 올리기'"></button>
        </div>

        <div v-if="selectedFiles.length === 0 && IsSelect" class="text-center mb-4">
            <div class="py-4 fs-20 fst-italic">최대 {{ maxFiles }}개의 이미지 등록이 가능 합니다</div>
            <button class="w-100 h-50h btn btn-primary" @click="handleSelectImage" v-html="'파일선택'"></button>
        </div>
    </div>

    <span v-show="isUpload" class="spinner-border p-4 position-absolute top-50 start-50" role="status">
        <span>Uploading...</span>
    </span>
</template>

<script lang="js">
import { getExifOrientation, showAlert } from "@/common/comFuncs";
import { apiEnums, sysEnums } from "@/common/sysEnums";
import imageCompression from 'browser-image-compression';
import { getCurrentInstance, inject, ref } from "vue";

export default {
    emits: ['FileUploaded', 'FileSelected'],
    props: {
        Content: String,
        FileType: Number,
        IsBtn: Boolean,
        IsSelect: {Boolean, default: false}
    },
    setup(props) {
        let uploadFiles = ref([]);
        let selectedFiles = ref([]);
        let progress = ref(0);

        const isUpload = ref(false);
        const maxFiles = ref(4);

        const uploadFilesInput = ref(null);

        const { emit } = getCurrentInstance();

        const axios = inject("$axios");

        const userInfo = inject('$userInfo');
        const widgetInfo = inject('$widgetInfo');

        const { getUserPage, getUserClip } = inject("$provideList");

        // 압축하지않고 올리기 사진촬영시 이미지 세로형이 가로로 전송됨
        // const fileList = (event) => {
        //     const files = Array.from(event.target.files);
        //     files.forEach((file) => {
        //         selectedFiles.value.push(URL.createObjectURL(file));
        //         uploadFiles.value.push(file);
        //         // console.log('FileUpload selectedFiles : ', selectedFiles);
        //         // console.log('FileUpload Uploaded file : ', file);
        //     });
        // };

        const fileList = async (event) => {
            const files = Array.from(event.target.files);
            progress.value = 0;

            if (files.length > maxFiles.value) {
                showAlert('', '파일은 최대 ' + maxFiles.value + '개 까지 등록 가능합니다', 'HouseMeta', sysEnums.AlertTypes.Info);
            } else {

                if (files.length > 0) {
                    uploadFiles.value = [];
                    selectedFiles.value = [];

                    for (const file of files) {
                        if (props.FileType === sysEnums.FileTypes.File) {
                            uploadFiles.value.push(file);
                            selectedFiles.value.push(file);
                        } else {
                            await new Promise((resolve) => {
                                const options = {
                                    maxSizeMB: 2,
                                    maxWidthOrHeight: 2560,
                                    useWebWorker: true,
                                    exifOrientation: getExifOrientation(file),
                                    onProgress: (value) => {
                                        progress.value = value;
                                    },
                                };

                                imageCompression(file, options)
                                    .then((compressedFile) => {
                                        // 파일명을 그대로 유지하면서 Blob으로부터 File 생성
                                        const resizedFile = new File([compressedFile], file.name, { type: compressedFile.type });
                                        uploadFiles.value.push(resizedFile);
                                    })
                                    .catch((error) => {
                                        console.log(error.message); // output: I just want to stop
                                    })
                                    .finally(() => {
                                        resolve(); // 압축 성공 또는 실패와 관계없이 resolve 호출
                                    });
                            });
                            selectedFiles.value.push(URL.createObjectURL(file));
                        }
                    }
                    emit('FileSelected', true);
                }
                else {
                    emit('FileSelected', false);
                }

            }
        };

        const fileUpload = async () => {
            // console.log(userInfo.value.EncUser +' / '+ props.Content +' / '+ props.FileType)
            if (uploadFiles.value.length > 0) {
                isUpload.value = true;

                const formData = new FormData();
                formData.append('EncUser', userInfo.value.EncUser);
                formData.append('EncContent', props.Content);
                formData.append('Category', props.FileType);
                Array.from(uploadFiles.value).forEach((item) => {
                    formData.append('Files', item);
                });

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                };

                try {
                    // const response = await axios.post(apiEnums.CloudTicket.fileUpload, formData, config);
                    await axios.post(apiEnums.CloudTicket.fileUpload, formData, config);
                    fileUploaded();
                } catch (error) {
                    console.log(error);
                }
            }
        };

        const fileUploadWithParam = async (encContent) => {
            if (uploadFiles.value.length > 0) {
                const formData = new FormData();
                formData.append('EncUser', userInfo.value.EncUser);
                formData.append('EncContent', encContent);
                formData.append('Category', props.FileType);
                Array.from(uploadFiles.value).forEach((item) => {
                    formData.append('Files', item);
                });

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                };

                try {
                    await axios.post(apiEnums.CloudTicket.fileUpload, formData, config);
                    // console.log(response.data)

                    fileUploaded();
                } catch (error) {
                    console.log(error);
                }
            }
        };

        // 상위 컴포넌트에 입력 데이터 전달
        const fileUploaded = () => {
            // console.log(props.FileType)
            isUpload.value = false;

            if (props.Content !== '' || props.FileType === sysEnums.FileTypes.Facility) {
                emit('FileUploaded', null);
                // getFacility();
            }

            if (props.FileType === sysEnums.FileTypes.Contract) {
                // store.dispatch("AC_USER", JSON.parse(data));
                // store.dispatch("AC_WIDGET_DETAIL", { title: '', target: '', data: [] });
                emit('FileUploaded', null);
            }

            if (props.FileType === sysEnums.FileTypes.Partner) {
                emit('FileUploaded', null);
                // showAlert('', '사업자등록증이 등록 되었습니다!', 'HouseMeta', sysEnums.AlertTypes.Info);
            }

            if (props.FileType === sysEnums.FileTypes.File || props.FileType === sysEnums.FileTypes.Clip) {
                getUserPage();
                getUserClip();
            }

            // 처리순서 바꾸면 데이터 갱신 안됨
            uploadFiles.value = [];
            selectedFiles.value = [];

            widgetInfo.value.modal = false;
        };

        const handleSelectImage = () => {
            uploadFilesInput.value.click();
        };

        const uploadClipImage = (file) => {
            if (getFileExtension(file.name)) {
                uploadFiles.value.push(file);
                fileUpload();
                // console.log('uploadClipImage FileUpload Vue : ' + file.name + ' / ' + getFileExtension(file.name));
            }
        };

        const removeFile = (index) => {
            selectedFiles.value.splice(index, 1);
            uploadFiles.value.splice(index, 1);

            if (selectedFiles.value.length === 0) emit('FileSelected', false);
        };

        const getFileExtension = (fileName) => {
            const dotIndex = fileName.lastIndexOf('.');
            const extension = dotIndex !== -1 ? fileName.slice(dotIndex + 1).toLowerCase() : '';
            const disallowedExtensions = ['webp', 'web1', 'svg1'];

            return !disallowedExtensions.includes(extension);
        };


        return {
            props, progress, sysEnums,
            maxFiles, isUpload,
            selectedFiles, uploadFiles, uploadFilesInput,
            fileList, removeFile, uploadClipImage,
            fileUpload, fileUploadWithParam, handleSelectImage
        };
    },
};
</script>

<style scoped lang="scss"></style>
