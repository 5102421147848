<template>
    <BCard no-body>
        <BCardHeader>
            <!-- <button type="button" class="btn-close float-end fs-11" aria-label="Close"></button> -->
            <h6 class="card-title mb-0">
                <span class="fs-20 fw-semibold">{{ widgetInfo.selectedUser.UserName }}</span>
                <span class="text-muted fs-16 px-3">{{
                    getUserDepartment(widgetInfo.selectedUser.Position, true) + ' / ' + getUserDepartment(widgetInfo.selectedUser.Department, false)
                }}</span>
            </h6>
        </BCardHeader>
        <BCardBody class="p-4 text-center">
            <div class="mb-3 hand">
                <img
                    :src="widgetInfo.selectedUser.Avatar ? $fileUrl + widgetInfo.selectedUser.Avatar : 'assets/images/users/avatar-5.jpg'"
                    alt=""
                    class="mx-auto img-fit avatar-md rounded-circle"
                    :class="widgetInfo.selectedUser.Status ? '' : 'opacity-25'"
                />
            </div>

            <h5 class="card-title mb-1">
                <UserUpdate :Data="widgetInfo.selectedUser"></UserUpdate>
            </h5>

            <p class="text-muted mb-0">{{ widgetInfo.selectedUser.Email || '-' }}</p>
            <p class="text-muted mb-0">{{ $moment(widgetInfo.selectedUser.Created).format('YYYY.MM.DD') }} 가입</p>

            <span class="position-absolute start-50 top-50 text-muted ps-5 ms-5">
                <span class="pe-3" @click="viewChatUser">
                    <i class="ri-message-2-line fs-30"></i>
                </span>
                <span class="ps-3">
                    <i class="ri-mail-send-line fs-30"></i>
                </span>
            </span>

            <!-- <div class="text-center p-2 text-muted">
                <span class="pe-5">
                    <i class="ri-message-2-line fs-30"></i>
                </span>
                <span class="ps-5">
                    <i class="ri-mail-send-line fs-30"></i>
                </span>
            </div> -->
        </BCardBody>
        <BCardFooter class="text-center">
            <ul class="list-inline mb-0">
                <li class="list-inline-item text-muted">
                    <i class="ri-space"></i> POST <span class="text-primary">{{ widgetInfo.selectedUser.Folders.length || 0 }}</span>
                </li>
                <li class="list-inline-item text-muted px-4">
                    <i class="ri-article-line"></i> PAGE <span class="text-primary">{{ widgetInfo.selectedUser.Pages.length || 0 }}</span>
                </li>
                <li class="list-inline-item text-muted">
                    <i class="ri-attachment-2"></i> 첨부파일 <span class="text-primary">{{ widgetInfo.selectedUser.Files.length || 0 }}</span>
                </li>
            </ul>
        </BCardFooter>
    </BCard>
</template>

<script lang="js">
import { inject } from 'vue';
import { getUserDepartment } from "@/common/comFuncs";

import UserUpdate from "@/components/users/UserUpdate";

export default {
    components: {
        UserUpdate,
    },
    props: {
        Data: Object,
    },
    setup(props) {
        const widgetInfo = inject("$widgetInfo");

        const { getChatData } = inject("$provideList");

        const viewChatUser = () => {
            if (widgetInfo.value.canvasChat) {
                console.log(widgetInfo.value.selectedUser.UserName);
            } else {
                getChatData();

                widgetInfo.value.title = '1:1 채팅';
                widgetInfo.value.target = 'ChatList';
                widgetInfo.value.place = 'end';

                widgetInfo.value.canvas = true;
            }
            widgetInfo.value.modal = false;
        }

        return {
            props,
            widgetInfo,
            getUserDepartment,
            viewChatUser,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable='true'] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
