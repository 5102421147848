<template>
    <BCard no-body>
        <BCardHeader class="align-items-center d-flex border-bottom-dashed">
            <BCardTitle class="mb-0 flex-grow-1"
                >Attachments <BBadge v-if="selectedPage.PageFiles" variant="danger" pill>{{ selectedPage.PageFiles.length }}</BBadge>
            </BCardTitle>
            <div class="flex-shrink-0">
                <BButton type="button" variant="soft-primary" size="sm" @click="selectImage"><i class="ri-upload-2-fill me-1 align-bottom"></i> Upload</BButton>
            </div>
        </BCardHeader>

        <BCardBody>
            <div class="vstack gap-2">
                <!-- <div class="text-center">
                    <div class="profile-user position-relative d-inline-block mx-auto mb-2 hand" @click="selectImage">
                        <i class="ri-file-line fs-70 rounded-circle img-thumbnail p-3"></i>
                    </div>
                    <h5 class="fw-semibold text-uppercase">Attachments <BBadge v-if="selectedPage.PageFiles" variant="danger" pill>{{ selectedPage.PageFiles.length }}</BBadge>
                    </h5>
                </div> -->

                <FileUpload ref="fileRef" :FileType="sysEnums.FileTypes.File" :Content="selectedPage.EncPage" :IsBtn="true"></FileUpload>

                <template
                    class="h-50h align-middle hand"
                    v-for="x in getPageFile()"
                    :key="x"
                    style="cursor: move"
                    draggable="true"
                    @dragstart="dragStart(x, $event)"
                    @dragover.prevent
                    @drop="handleDropFolderMove(x.EncFolder, $event)"
                >
                    <div class="border rounded border-dashed p-2">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 me-3" @click="gotoModal(x, 'FileViewer', '파일 미리보기')">
                                <div class="avatar-sm">
                                    <div class="avatar-title bg-light text-primary rounded fs-24">
                                        <i class="ri-folder-zip-line"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                                <h5 class="fs-13 mb-1">
                                    <span class="text-body text-truncate d-block">{{ x.FileName }}</span>
                                </h5>
                                <div v-if="x.FileSize < 100000" v-text="(x.FileSize / 1000).toFixed(1) + 'Kb'"></div>
                                <div v-else v-text="(x.FileSize / 1000000).toFixed(1) + 'Mb'"></div>
                            </div>
                            <div class="flex-shrink-0 ms-2">
                                <div class="d-flex gap-1">
                                    <button type="button" class="btn btn-icon text-muted btn-sm fs-18" @click="downloadFile($fileUrl + x.FilePath, x.FileName)">
                                        <i class="ri-download-2-line"></i>
                                    </button>
                                    <BDropdown
                                        variant="link"
                                        toggle-class="btn btn-icon btn-sm fs-18 text-muted arrow-none"
                                        menu-class="dropdown-menu-end"
                                        :offset="{ alignmentAxis: -130, crossAxis: 0, mainAxis: 10 }"
                                    >
                                        <template #button-content>
                                            <i class="ri-more-fill"></i>
                                        </template>
                                        <BDropdownItem><i class="ri-pencil-fill align-bottom me-2 text-muted"></i>{{ $moment(x.Created).format('YY.MM.DD') }}</BDropdownItem>
                                        <BDropdownItem @click="removeFile(x)"> <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </BDropdownItem>
                                    </BDropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </BCardBody>
    </BCard>
</template>

<script lang="js">
import { showConfirm } from "@/common/comFuncs";
import { apiEnums, sysEnums } from "@/common/sysEnums";
import { inject, ref } from "vue";

import FileUpload from "@/components/files/FileUpload";

export default {
    props: {
        Content: String,
        FileType: Number,
        IsBtn: Boolean,
    },
    components: {
        FileUpload,
    },
    setup(props) {
        const fileRef = ref(null);

        const axios = inject("$axios");

        const userInfo = inject('$userInfo');

        const { filteredFolder, selectedPage, gotoModal } = inject("$provideList");

        const handleDropFolderMove = (encFolder, event) => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            console.log(encFolder + ' / ' + droppedData.EncFolder)

            // if (droppedData.EncWorkspace) {
            //     updateWorkspace(droppedData.EncWorkspace, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncWorkspace)
            // } else {
            //     updateFolder(droppedData.EncFolder, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncFolder)
            // }
        }

        const selectImage = () => {
            fileRef.value.handleSelectImage();
        }

        const getPageFile = () => {
            const datas = selectedPage.value.PageFiles

            // console.log('getPageFile : ' + JSON.stringify(datas));

            if (datas && datas.length > 0) {
                const datas1 = datas.filter(x => x.Category === sysEnums.FileTypes.File)
                return datas1
            } else {
                return null
            }
        }

        const downloadFile = async (path, name) => {
            try {
                const response = await axios.get(path, {
                    responseType: 'blob'
                });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = name;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading the file', error);
            }
        }

        const removeFile = (file) => {
            showConfirm('', '등록된 파일을 삭제 합니다?', '삭제하기').then((result) => {
                if (result) {
                    const params = new URLSearchParams();
                    params.append('FileNum', file.FileNum);
                    axios.post(apiEnums.CloudTicket.removeFile, null, { params })
                        .then((response) => {
                            if (response.data.result !== "0000") {

                                const files = selectedPage.value.PageFiles
                                const index = files.findIndex(x => x.FileNum === x.FileNum);
                                files.splice(index, 1);

                            }
                        })
                        .catch(() => { });
                }
            });
        }


        return {
            props, sysEnums, userInfo, fileRef,
            filteredFolder, selectedPage, gotoModal,
            handleDropFolderMove, selectImage, getPageFile,
            removeFile, downloadFile,
        };
    },
};
</script>

<style scoped lang="scss"></style>
