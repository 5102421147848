<template>
    <div class="card p-2">
        <div class="card-body">
            <!-- 입력 부분 시작 -->
            <div class="mb-1">
                <p class="text-muted fw-bold">휴대폰번호, 초대자명을 입력하여 주세요</p>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-floating">
                        <input type="text" class="form-control" id="floatingInput1" placeholder="휴대폰번호를 입력하여 주세요" v-model="recipient" />
                        <label for="floatingInput1">휴대폰번호를 -를 제외하고 입력하여 주세요</label>
                    </div>
                </div>
            </div>
            <div class="row mt-4 mb-4">
                <div class="col">
                    <div class="form-floating">
                        <input type="text" class="form-control" id="floatingInput2" placeholder="고객명을 입력하여 주세요" v-model="receiverName" />
                        <label for="floatingInput2">초대하고자하는 고객명을 입력하여 주세요</label>
                    </div>
                </div>
            </div>

            <div class="mb-5 d-flex justify-content-around hand">
                <span @click="userType = 1" class="text-center"><i class="fs-50" :class="userType === 1 ? 'las la-user text-danger' : 'las la-user'"></i><br />직원</span>
                <span @click="userType = 0" class="text-center"
                    ><i class="fs-50" :class="userType === 0 ? 'las la-handshake text-danger' : 'las la-handshake'"></i><br />파트너</span
                >
            </div>

            <div>
                <div class="mb-4 bg-dark text-light p-4 br-14">
                    <div class="mb-2 fw-bold" v-html="title"></div>
                    <div>[Web발신]</div>
                    <textarea class="w-100 h-180 border-0 bg-dark text-light" v-html="msg" readonly></textarea>
                    <div>https://app.pageclip.ai/6084JRax9S7xQcR55G2ZZw==</div>
                </div>
                <div class="w-100 mt-2 fw-bold" v-html="result"></div>
            </div>
        </div>
    </div>

    <!-- {{ JSON.stringify(userInfo) }} -->

    <div class="col-lg-12 bg-white p-2">
        <div class="row">
            <div class="col-4" data-bs-dismiss="offcanvas">
                <button type="button" class="btn btn-light h-50h w-100">취소</button>
            </div>
            <div class="col-8 ps-0">
                <button class="btn btn-primary h-50h w-100" @click="sendAlimTalk">초대하기</button>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import { showAlert } from "@/common/comFuncs";
import { apiEnums, enumTypes, sysEnums } from "@/common/sysEnums";
import { computed, inject, ref } from "vue";

export default {
    setup() {
        const userType = ref(0);

        let result = ref('');
        let recipient = ref('');
        let receiverName = ref('');

        // 주의 제목길이 40바이트 제한
        const title = ref('페이지클립 초대안내');

        const filteredUsers = ref([]);

        const userInfo = inject("$userInfo");

        const axios = inject("$axios");

        let msg = computed(() => {
            return `${enumTypes.UserTypes[userInfo.value.UserType]}  ${userInfo.value.UserName} 님이\n\n${receiverName.value} 님에게\n${enumTypes.UserTypes[userType.value]} 으로 초대 요청을 하였습니다.\n\n링크된 주소를 클릭하신후 회원가입을 진행해 주시기 바랍니다.\n\n`;
        });

        const requestInvite = () => {
            const formData = new FormData();
            formData.append("EncSender", userInfo.value.EncUser);
            formData.append("InviteType", userType.value);
            formData.append("Recipient", recipient.value);
            formData.append("ReceiverName", receiverName.value);
            formData.append("Title", title.value);
            formData.append("Msg", msg.value);
            axios.post(apiEnums.BizTalk.sendLms, formData)
                .then((response) => {
                    if (response.data !== "0000") {
                        result.value = '초대안내 문자가 전송 되었습니다';
                    }
                    showAlert('', result.value, '', sysEnums.AlertTypes.Info);
                })
                .catch(() => { });
        }

        const sendMMS = () => {
            const params = new URLSearchParams();
            params.append('recipient', recipient.value);
            axios.post(apiEnums.BizTalk.sendMms, null, { params })
                .then((response) => {
                    if (response.data !== "0000") {
                        result.value = response.data
                    }
                })
                .catch(() => { });
        }

        const sendAlimTalk = () => {
            const formData = new FormData();
            formData.append('TmpltCode', 'C0101');
            formData.append('CompanyName', userInfo.value.CompanyName || '어반클립');
            formData.append('Title', '관리자');
            formData.append('Recipient', recipient.value);
            formData.append('UserName', receiverName.value);

            // const Addr2 = props.Data.Address2.trim() === '' ? ' ' : props.Data.Address2;
            // formData.append('Address', props.Data.Address1 + ' ' + Addr2);

            axios.post(apiEnums.BizTalk.sendAlimTalk, formData)
                .then((response) => {
                    // console.log(JSON.stringify(response))
                    result.value = response.msg;
                })
                .catch(() => { });
        };


        return {
            result, userType,
            recipient, receiverName, title, msg,
            filteredUsers,
            requestInvite, sendMMS, sendAlimTalk,
        };
    },
};
</script>

<style scoped lang="scss"></style>
