<template>
    <apexchart v-if="props.Target === enumTypes.ChartTypes.Noti" class="apex-charts" height="350" dir="ltr" :series="basicAreaChart.series" :options="basicAreaChart.chartOptions">
    </apexchart>
    <apexchart v-if="props.Target === enumTypes.ChartTypes.Company" class="apex-charts" height="350" dir="ltr" :series="splineAreaChart.series"
        :options="splineAreaChart.chartOptions">
    </apexchart>
    <apexchart v-if="props.Target === enumTypes.ChartTypes.Settlement" class="apex-charts" height="350" dir="ltr" :series="areaChart.series" :options="areaChart.chartOptions">
    </apexchart>
</template>

<script lang="js">
import getChartColorsArray from "@/common/getChartColorsArray";

import { onMounted, inject, ref } from "vue";
import { enumTypes, sysEnums } from "@/common/sysEnums";
import { getServiceFee, getPayStoryFee, getPartnerFee } from "@/common/comFuncs";

export default {
    props: {
        Target: { type: Number, required: true },
    },
    setup(props) {
        const basicAreaChart = ref({
            series: [
                {
                    name: "Send Notification",
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: "area",
                    height: 350,
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },
                // title: {
                //     text: "Fundamental Analysis of Stocks",
                //     align: "left",
                //     style: {
                //         fontWeight: 500,
                //     },
                // },
                // subtitle: {
                //     text: "Price Movements",
                //     align: "left",
                // },
                labels: [],
                xaxis: {
                    type: "datetime",
                },
                yaxis: {
                    opposite: true,
                },
                legend: {
                    horizontalAlign: "left",
                },
                colors: getChartColorsArray('["--vz-primary"]'),
            },
        });

        //  Spline Area Charts
        const splineAreaChart = ref({
            series: [
                {
                    name: "company",
                    data: [],
                },
                {
                    name: "contract",
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: "area",
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "smooth",
                },
                labels: [],
                colors: getChartColorsArray('["--vz-primary", "--vz-info"]'),
                xaxis: {
                    type: "datetime",
                    categories: [],
                },
                tooltip: {
                    x: {
                        format: "dd/MM/yy HH:mm",
                    },
                },
            },
        });


        const areaChart = ref({
            series: [{
                name: "PG수수료",
                type: "bar",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
                name: "페이스토리",
                type: "area",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
                name: "파트너",
                type: "bar",
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            ],
            chartOptions: {
                chart: {
                    height: 374,
                    type: "line",
                    toolbar: {
                        show: false,
                    },
                },
                stroke: {
                    curve: "smooth",
                    dashArray: [0, 3, 0],
                    width: [0, 1, 0],
                },
                fill: {
                    opacity: [1, 0.1, 1],
                },
                markers: {
                    size: [0, 4, 0],
                    strokeWidth: 2,
                    hover: {
                        size: 4,
                    },
                },
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ],
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: false,
                    },
                },
                grid: {
                    show: true,
                    xaxis: {
                        lines: {
                            show: true,
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false,
                        },
                    },
                    padding: {
                        top: 0,
                        right: -2,
                        bottom: 15,
                        left: 10,
                    },
                },
                legend: {
                    show: true,
                    horizontalAlign: "center",
                    offsetX: 0,
                    offsetY: -5,
                    markers: {
                        width: 9,
                        height: 9,
                        radius: 6,
                    },
                    itemMargin: {
                        horizontal: 10,
                        vertical: 0,
                    },
                },
                plotOptions: {
                    bar: {
                        columnWidth: "30%",
                        barHeight: "70%",
                    },
                },
                colors: getChartColorsArray('["--vz-primary", "--vz-primary-rgb, 0.1", "--vz-primary-rgb, 0.50"]'),
                tooltip: {
                    shared: true,
                    y: [{
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(0).toLocaleString();
                            }
                            return y;
                        },
                    },
                    {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(0).toLocaleString();
                            }
                            return y;
                        },
                    },
                    {
                        formatter: function (y) {
                            if (typeof y !== "undefined") {
                                return y.toFixed(0);
                            }
                            return y;
                        },
                    },
                    ],
                },
            },
        });

        const moment = inject("$moment");

        const companys = inject("$companys");
        const notis = inject("$notis");

        const { contractList } = inject("$provideList");

        onMounted(() => {
            try {
                props.Target === enumTypes.ChartTypes.Noti ? drawChartCompany() :
                    props.Target === enumTypes.ChartTypes.Company ? drawChartCompany() :
                        props.Target === enumTypes.ChartTypes.Settlement ? drawChartSettlement() : drawChartNoti();
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        });

        const fetchDataNoti = async () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const data = notis.value;
                    resolve(data);
                }, 700);
            });
        };

        const fetchDataCompany = async () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const data = companys.value;
                    resolve(data);
                }, 800);
            });
        };

        const fetchDataContract = async () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const data = contractList.value;
                    resolve(data);
                }, 900);
            });
        };

        const drawChartNoti = async () => {
            try {
                const datas = await fetchDataNoti();
                // console.log(JSON.stringify(datas));

                basicAreaChart.value.series[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                basicAreaChart.value.chartOptions.labels = [];

                let obj = {};
                if (Array.isArray(datas)) {
                    datas.forEach(item => {
                        const formattedDate = moment(item.Created).format('YYYY-MM-DD');
                        if (!obj[formattedDate]) {
                            obj[formattedDate] = [];
                        }
                        obj[formattedDate].push(item);
                    })

                    Object.keys(obj).forEach((key) => {
                        // const userType = enumTypes.UserTypes[obj[key][0].UserType];
                        basicAreaChart.value.series[0].data[8](obj[key].length);
                        basicAreaChart.value.chartOptions.labels.push(key);
                    });
                }
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        };

        const drawChartCompany = async () => {
            try {
                const datas = await fetchDataCompany();

                splineAreaChart.value.series[0].data = [];
                splineAreaChart.value.chartOptions.labels = [];

                let obj = {};
                if (Array.isArray(datas)) {
                    datas.forEach(item => {
                        const formattedDate = moment(item.Created).format('YYYY-MM-DD');
                        if (!obj[formattedDate]) {
                            obj[formattedDate] = [];
                        }
                        obj[formattedDate].push(item);
                    })

                    Object.keys(obj).forEach((key) => {
                        splineAreaChart.value.series[0].data.push(obj[key].length);
                        splineAreaChart.value.chartOptions.labels.push(key);
                    });
                }
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        };

        const drawChartSettlement = async () => {
            try {
                const datas = await fetchDataContract();

                areaChart.value.series[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                areaChart.value.series[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                areaChart.value.series[2].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                areaChart.value.chartOptions.labels = [];

                let obj = {};
                datas.forEach(item => {
                    const formattedDate = moment(item.StartDate).format('YYYY-MM');
                    if (!obj[formattedDate]) {
                        obj[formattedDate] = [];
                    }
                    obj[formattedDate].push(item);
                })

                Object.keys(obj).forEach((key) => {
                    const index = parseInt(moment(key).format('M')) - 1;

                    const dt1 = obj[key].reduce((acc, cur) => {
                        return acc + getServiceFee(cur.Rent, cur.ManagementFee, cur.FeeDiscountRate);
                    }, 0);

                    const dt2 = obj[key].reduce((acc, cur) => {
                        return acc + getPayStoryFee(cur.Rent, cur.ManagementFee, cur.FeeDiscountRate);
                    }, 0);

                    const dt3 = obj[key].reduce((acc, cur) => {
                        const cardFee = cur.PayMid ? (cur.PayMid.CardFee || sysEnums.cardFee) : sysEnums.cardFee;
                        return acc + getPartnerFee(cur.Rent, cur.ManagementFee, cur.FeeDiscountRate, cardFee);
                    }, 0);

                    areaChart.value.series[0].data[index] = dt1;
                    areaChart.value.series[1].data[index] = dt2;
                    areaChart.value.series[2].data[index] = dt3;

                    areaChart.value.chartOptions.labels.push(key);
                    // console.log(dt1 + ' / ' + key)
                });
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        };

        // const drawChartCompany = () => {
        //     const datas = companys.value;

        //     gradientDonutChart.value.series = [];
        //     gradientDonutChart.value.chartOptions.labels = [];

        //     let obj = {};
        //     datas.forEach(item => {
        //         if (!obj[item.CompanyType]) {
        //             obj[item.CompanyType] = [];
        //         }
        //         obj[item.CompanyType].push(item);
        //     })

        //     Object.keys(obj).forEach((key) => {
        //         const companyType = enumTypes.CompanyTypes[obj[key][0].CompanyType].name;
        //         gradientDonutChart.value.series.push(obj[key].length);
        //         gradientDonutChart.value.chartOptions.labels.push(companyType || '-');
        //     });
        // };

        return {
            props, enumTypes,
            basicAreaChart, splineAreaChart, areaChart,
        };
    },
};
</script>

<style scoped lang="scss"></style>
