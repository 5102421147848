<script>
import { showConfirm } from '@/common/comFuncs';
import { apiEnums } from '@/common/sysEnums';
import { inject, ref } from 'vue';

export default {
    components: {},
    setup() {
        const title = ref('');
        const edit1 = ref(false);
        const edit2 = ref(false);
        const edit3 = ref(false);

        // const users = inject('$users');

        const widgetInfo = inject('$widgetInfo');

        const axios = inject('$axios');

        const { userList, getCompany } = inject('$provideList');

        const cntDepartmentUser = department => {
            if (userList.value && userList.value.length > 0) {
                return userList.value.filter(x => x.EncCompany === widgetInfo.value.companyInfo.EncCompany && x.Department === department).length || 0;
            }
            return 0;
        };

        const cntPositionUser = position => {
            if (userList.value && userList.value.length > 0) {
                return userList.value.filter(x => x.EncCompany === widgetInfo.value.companyInfo.EncCompany && x.Position === position).length || 0;
            }
            return 0;
        };

        // const createPosition = (deptNum) => {
        //     const formData = new FormData();
        //     formData.append('DeptNum', deptNum);
        //     formData.append('Title', title.value);
        //     axios.post(apiEnums.CloudTicket.updateDepartment, formData)
        //         .then((response) => {
        //             // console.log(JSON.stringify(response))
        //             if (response.data !== '0000') {
        //                 getCompany();
        //             }
        //         })
        //         .catch(() => { });
        // }

        const createDepartment = (flag, group) => {
            showConfirm('', flag ? '직급을 등록 합니다.' : '부서를 등록 합니다.', flag ? '직급등록' : '부서등록').then(result => {
                if (result) {
                    const formData = new FormData();
                    formData.append('EncCompany', widgetInfo.value.companyInfo.EncCompany);
                    formData.append('Title', title.value);
                    formData.append('Group', group);
                    formData.append('IsPosition', flag ? 'true' : 'false');
                    axios
                        .post(apiEnums.CloudTicket.createDepartment, formData)
                        .then(response => {
                            if (response.data !== '0000') {
                                edit1.value = false;
                                getCompany();
                            }
                        })
                        .catch(() => {});
                }
            });
        };

        const updateDepartment = data => {
            const formData = new FormData();
            formData.append('DeptNum', data.DeptNum);
            formData.append('Title', data.Title);
            // if (flag === 1) formData.append('Updated', 'true');

            axios
                .post(apiEnums.CloudTicket.updateDepartment, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        edit2.value = edit3.value = false;
                        getCompany();
                    }
                })
                .catch(() => {});
        };

        const moveDepartment = (deptNum, group) => {
            const formData = new FormData();
            formData.append('DeptNum', deptNum);
            formData.append('Group', group);
            axios
                .post(apiEnums.CloudTicket.updateDepartment, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        edit2.value = edit3.value = false;
                        getCompany();
                    }
                })
                .catch(() => {});
        };

        const dragStart = (data, event) => {
            event.dataTransfer.setData('text/plain', JSON.stringify(data));
        };

        const handleDropDept = (data, event) => {
            event.preventDefault();

            try {
                const dataString = event.dataTransfer.getData('text/plain');
                const droppedData = JSON.parse(dataString);

                if (droppedData.DeptNum !== data.DeptNum) moveDepartment(droppedData.DeptNum, data.DeptNum);

                // console.log('CompanyMap handleDropDept : ' + JSON.stringify(droppedData));
            } catch (error) {
                console.error('Error handleDropDept : ', error);
            }
        };

        return {
            edit1,
            edit2,
            edit3,
            title,
            widgetInfo,
            userList,
            dragStart,
            handleDropDept,
            cntDepartmentUser,
            cntPositionUser,
            createDepartment,
            updateDepartment,
        };
    },
};
</script>

<template>
    <BRow class="mb-5">
        <BCol lg="12">
            <BCard no-body>
                <BCardHeader>
                    <BCardTitle class="mb-0">조직도</BCardTitle>
                </BCardHeader>
                <BCardBody>
                    <BButton v-if="edit2 || edit3" class="float-end" @click="edit2 = edit3 = false"><i class="ri-close-line fs-20"></i></BButton>

                    <div v-if="widgetInfo && widgetInfo.companyInfo" class="sitemap-content">
                        <figure class="sitemap-horizontal">
                            <ul class="administration">
                                <li>
                                    <ul class="director">
                                        <li>
                                            <BLink v-if="edit1" class="p-2">
                                                <input type="text" class="form-control" v-model="title" placeholder="부서명" />
                                                <BButton class="px-3 py-1 mx-2" variant="primary" @click="createDepartment(false, 0)"><i class="ri-add-line fs-20"></i></BButton>
                                                <BButton class="px-3 py-1" @click="edit1 = edit2 = false"><i class="ri-close-line fs-20"></i></BButton>
                                            </BLink>
                                            <BLink v-else class="fw-semibold" @click="edit1 = true"
                                                ><span>{{ widgetInfo.companyInfo.Name || '' }}</span></BLink
                                            >
                                            <ul class="subdirector">
                                                <li>
                                                    <BLink class="fw-semibold"><span>임시</span></BLink>
                                                </li>
                                            </ul>
                                            <ul class="departments">
                                                <li>
                                                    <BLink class="fw-semibold"><span>비서실</span></BLink>
                                                </li>

                                                <template v-for="x in widgetInfo.companyInfo.Depts" :key="x">
                                                    <li class="department">
                                                        <span v-if="edit2" class="d-flex align-items-center mt-2">
                                                            <!-- <i class="ri-arrow-left-fill" @click="updateDepartment(x)"></i> -->
                                                            <input type="text" class="form-control me-2" v-model="x.Title" placeholder="부서명" />
                                                            <BButton class="px-2 py-1" variant="primary" @click="updateDepartment(x)"><i class="ri-edit-line"></i></BButton>
                                                        </span>
                                                        <BLink
                                                            v-else
                                                            class="px-4 d-flex fw-semibold"
                                                            draggable="true"
                                                            @dragstart="dragStart(x, $event)"
                                                            style="cursor: move"
                                                            @dragover.prevent
                                                            @drop="handleDropDept(x, $event)"
                                                        >
                                                            <span class="flex-fill" @click="edit2 = true">{{ x.Title }} ({{ cntDepartmentUser(x.DeptNum) }})</span>
                                                            <i class="ri-add-line fs-20" @click="createDepartment(false, x.DeptNum)"></i>
                                                        </BLink>

                                                        <ul>
                                                            <div v-if="edit3" class="mt-5"></div>

                                                            <template v-for="y in widgetInfo.companyInfo.Groups" :key="y">
                                                                <li v-if="x.DeptNum === y.Group">
                                                                    <span v-if="edit3" class="d-flex align-items-center mt-2">
                                                                        <!-- <i class="ri-arrow-up-line" @click="updateDepartment(y)"></i> -->
                                                                        <input type="text" class="form-control me-2" v-model="y.Title" placeholder="부서명" />
                                                                        <BButton class="px-2 py-1" variant="primary" @click="updateDepartment(y)"
                                                                            ><i class="ri-edit-line"></i>
                                                                        </BButton>
                                                                    </span>
                                                                    <BLink
                                                                        v-else
                                                                        class="border shadow-none"
                                                                        @click="edit3 = true"
                                                                        draggable="true"
                                                                        @dragstart="dragStart(y, $event)"
                                                                        @dragover.prevent
                                                                        @drop="handleDropDept(y, $event)"
                                                                        ><span>{{ y.Title }}</span></BLink
                                                                    >
                                                                </li>
                                                            </template>
                                                        </ul>
                                                    </li>
                                                </template>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </figure>
                    </div>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>

    <BRow class="mb-5">
        <BCol lg="12">
            <BCard no-body>
                <BCardHeader>
                    <BCardTitle class="mb-0">직급목록</BCardTitle>
                </BCardHeader>
                <BCardBody>
                    <BRow>
                        <BCol sm="6">
                            <div class="verti-sitemap">
                                <ul class="list-unstyled mb-0">
                                    <li class="p-0 parent-title">
                                        <BLink v-if="edit1" class="p-2 d-flex">
                                            <input type="text" class="form-control" v-model="title" placeholder="직급명" />
                                            <BButton class="px-3 py-1 mx-2" variant="primary" @click="createDepartment(true, 0)"><i class="ri-add-line fs-20"></i></BButton>
                                            <BButton class="px-3 py-1" @click="edit1 = edit2 = false"><i class="ri-close-line fs-20"></i></BButton>
                                        </BLink>
                                        <span v-else class="d-flex align-items-center">
                                            <BLink class="fw-medium fs-14" @click="edit1 = true">{{ widgetInfo.companyInfo.Name || '' }}</BLink>
                                            <i class="ri-add-line fs-20 text-primary ps-3" @click="createDepartment(true, 0)"></i>
                                        </span>
                                    </li>
                                    <li>
                                        <template v-for="x in widgetInfo.companyInfo.Positions" :key="x">
                                            <div class="first-list">
                                                <div class="list-wrap">
                                                    <span v-if="edit2" class="d-flex align-items-center mt-2">
                                                        <!-- <i class="ri-arrow-up-fill" @click="updateDepartment(x)"></i> -->
                                                        <input type="text" class="form-control me-2" v-model="x.Title" placeholder="직급명" />
                                                        <BButton class="px-2 py-1" variant="primary" @click="updateDepartment(x)"><i class="ri-edit-line"></i></BButton>
                                                    </span>
                                                    <BLink
                                                        v-else
                                                        @click="edit2 = true"
                                                        class="fw-medium text-primary"
                                                        draggable="true"
                                                        @dragstart="dragStart(x, $event)"
                                                        style="cursor: move"
                                                        @dragover.prevent
                                                        @drop="handleDropDept(x, $event)"
                                                        >{{ x.Title }} ({{ cntPositionUser(x.DeptNum) }})</BLink
                                                    >
                                                </div>
                                            </div>
                                        </template>
                                    </li>
                                </ul>
                            </div>
                        </BCol>

                        <BCol sm="6">
                            <div class="verti-sitemap">
                                <ul class="list-unstyled mb-0">
                                    <li class="p-0 parent-title">
                                        <BLink class="fw-medium fs-14">{{ widgetInfo.companyInfo.Name || '' }}</BLink>
                                    </li>
                                    <li>
                                        <template v-for="x in widgetInfo.companyInfo.Depts" :key="x">
                                            <div class="first-list">
                                                <div class="list-wrap">
                                                    <BLink class="fw-medium text-primary"
                                                        ><i class="ri-airplay-line me-1 align-bottom"></i>{{ x.Title }} ({{ cntDepartmentUser(x.DeptNum) }})
                                                    </BLink>
                                                </div>
                                                <ul class="second-list list-unstyled">
                                                    <template v-for="y in widgetInfo.companyInfo.Groups" :key="y">
                                                        <li v-if="x.DeptNum === y.Group">
                                                            <BLink
                                                                ><span class="border-bottom">{{ y.Title }}</span></BLink
                                                            >
                                                        </li>
                                                    </template>
                                                </ul>
                                            </div>
                                        </template>
                                    </li>
                                </ul>
                            </div>
                        </BCol>
                    </BRow>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>
</template>

<style></style>
