<script>
import { showAlert, setUserToStorage } from '@/common/comFuncs';
import { sysEnums, apiEnums } from '@/common/sysEnums';
import Layout from '../../layouts/main';
import { userPolicy } from '@/common/userPolicy';

import { ref, reactive, inject, onMounted, computed } from 'vue';

export default {
    components: {
        Layout,
    },
    setup() {
        const pages = 3;
        let curPage = ref(1);

        const userName = ref('');
        const email = ref('');
        const userType = ref(0);
        const phoneNum = ref('');
        const confirmNum = ref('');

        let isToken = ref(false);
        //let showPwaGuide = ref(false);
        let digits = ref([]);
        let validNumber = ref('');
        let recipient = ref('');
        let confirmMsg = ref('');

        const policyTitle = ref([
            '서비스이용약관 동의하기',
            '회원 이용약관 (임대인·임차인) 동의하기',
            '협업 계약서 동의하기',
            '개인정보 처리방침 동의하기',
            '개인정보 수집·이용 동의서',
            '개인정보 제3자 제공 동의서',
        ]);

        let selectedPolicy = ref([false, false, false, false, false, false]);

        const checkedPolicy = reactive([
            [true, false, false, true, false, false],
            [false, false, true, true, true, true],
            [true, true, false, true, true, true],
            [true, true, false, true, true, true],
        ]);

        const browserInfo = reactive({
            name: '',
            code: '',
            platform: '',
            userAgent: '',
            version: '',
        });

        const store = inject('$store');
        const axios = inject('$axios');
        const router = inject('$router');

        const widgetInfo = inject('$widgetInfo');

        const { gotoModal } = inject('$provideList');

        const houseMetaUser = localStorage.getItem(sysEnums.UserKey);

        const searchParams = new URLSearchParams(location.search);
        const extJoinParam = searchParams.get('extJoin');

        onMounted(() => {
            if (houseMetaUser) {
                // 이미 기입한 회원 홈화면으로 이동
                router.push('/pagelist');
            } else {
                //checkBrowser();
            }

            if (extJoinParam) {
                userType.value = parseInt(extJoinParam);
            }
        });

        const checkUserInfo = async () => {
            //if (!email.value) {
            //    showAlert("", "메일 주소를 입력하세요.", "", sysEnums.AlertTypes.Error);
            //    return;
            //}

            //if (!isValidEmail(email.value)) {
            //    showAlert("", "유효한 이메일 주소를 입력하세요.", "", sysEnums.AlertTypes.Info);
            //    return;
            //}

            //if (userType.value == 0) {
            //    showAlert("", "회원타입을 선택 하세요.", "", sysEnums.AlertTypes.Info);
            //    return;
            //}

            if (recipient.value === '01043129876') {
                userName.value = 'tester';
            }

            const isDev = location.host.indexOf('app3') !== -1 || process.env.NODE_ENV === 'development' ? true : false;
            if (recipient.value === '01043129876') {
                const datas = await checkExistUser();

                setUserToStorage(datas, true);
                store.dispatch('AC_USER', datas);
                router.push('/');
                window.location.reload();

                curPage.value = 3;
            } else if (isDev) {
                confirmNum.value = '1234';
                curPage.value = 2;
            } else {
                sendMMS();
            }
        };

        const checkToken = async () => {
            // 약관 확인 했는지 체크
            if (!isAllSelected.value) {
                showAlert('', '약관 동의가 필요 합니다.', '', sysEnums.AlertTypes.Info);
                return;
            }

            // if (!fcmToken.value) {
            //     showAlert('', '푸시요청 수락이 필요 합니다.', '', sysEnums.AlertTypes.Info);
            //     return;
            // }

            //showPwaGuide.value = true;

            try {
                createUser();
            } catch (error) {
                showAlert('', error, 'HouseMeta', sysEnums.AlertTypes.Info);
            }
        };

        const sendMMS = () => {
            const params = new URLSearchParams();
            params.append('recipient', recipient.value);
            axios
                .post(apiEnums.BizTalk.sendMms, null, { params })
                .then(response => {
                    // bizToken.value = JSON.stringify(response);
                    if (response.data === '0000') {
                        // 메세지 전송오류 로직 추가
                    } else {
                        confirmNum.value = response.data;
                        curPage.value = 2;
                    }
                })
                .catch(() => {});
        };

        const checkExistUser = async () => {
            try {
                const formData = new FormData();
                formData.append('Recipient', recipient.value);
                formData.append('UserName', userName.value);
                formData.append('Uid', widgetInfo.value.fcmToken);

                const response = await axios.post(apiEnums.CloudTicket.checkExistUser, formData);

                return response.data;
            } catch (error) {
                console.error(error);
                return null;
            }
        };

        const createUser = () => {
            const formData = new FormData();
            formData.append('Recipient', recipient.value);
            formData.append('UserName', userName.value);
            formData.append('Email', email.value);
            formData.append('UserType', userType.value);
            formData.append('Platform', browserInfo.platform);
            formData.append('UserAgent', browserInfo.userAgent);
            formData.append('Uid', widgetInfo.value.fcmToken);
            axios
                .post(apiEnums.CloudTicket.createUser, formData)
                .then(response => {
                    const datas = response.data;
                    if (datas.result === '1000' || datas.result === '3000') {
                        setUser(datas, true);
                    } else if (datas.result === '2000') {
                        showAlert('', '재가입시에는 최초 등록된<br>연락처와 성명이 일치 하여야 합니다', 'HouseMeta', sysEnums.AlertTypes.Error);
                    } else {
                        showAlert('', '회원가입 중 오류 가 발생 하였습니다!<br>관리자에 문의 바랍니다', datas, sysEnums.AlertTypes.Error);
                    }
                })
                .catch(() => {});
        };

        const handlePhoneNumber = event => {
            let replacedInput = event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,4})(\d{0,4})/);
            phoneNum.value = !replacedInput[2] ? replacedInput[1] : '(' + replacedInput[1] + ') ' + replacedInput[2] + (replacedInput[3] ? '-' + replacedInput[3] : '');
            recipient.value = !replacedInput[2] ? replacedInput[1] : replacedInput[1] + replacedInput[2] + replacedInput[3];
        };

        const handleValidNumber = event => {
            const inputValue = event.target.value;
            const index = inputValue.length;
            digits.value = validNumber.value.split('');
            // digits.value = [...validNumber.value];

            if (index === 4) {
                confirmValidNumber();
            }
        };

        const confirmValidNumber = async () => {
            const validNum = digits.value.join('');

            if (confirmMsg.value === '' && confirmNum.value === validNumber.value) {
                const datas = await checkExistUser();
                if (datas === '0000') {
                    curPage.value = 3;
                } else {
                    setUserToStorage(datas, true);
                    store.dispatch('AC_USER', datas);
                    router.push('/');
                    window.location.reload();

                    curPage.value = 3;
                }
            } else if (validNum.length == 4 && confirmNum.value !== validNumber.value) {
                confirmMsg.value = '인증번호를 확인 하세요.';
                showAlert('', '4자리 인증번호를 확인 하신 후<br><br>정확히 입력해 주세요.', '', sysEnums.AlertTypes.Info);
            } else {
                confirmMsg.value = '';
            }
        };

        const setUser = (datas, flag) => {
            const user = setUserToStorage(datas, flag);

            //if (showPwaGuide.value) {
            //    store.dispatch("AC_USER", datas);
            //    return;
            //}

            if (user.EncUser) {
                store.dispatch('AC_USER', datas);
                window.location.reload();
            }
        };

        const viewPolicy = index => {
            switch (index) {
                case 1:
                    widgetInfo.value.dataModal = userPolicy.policy1;
                    break;
                case 2:
                    widgetInfo.value.dataModal = userPolicy.policy2;
                    break;
                case 3:
                    widgetInfo.value.dataModal = userPolicy.policy3;
                    break;
                case 4:
                    widgetInfo.value.dataModal = userPolicy.policy4;
                    break;
                case 5:
                    widgetInfo.value.dataModal = userPolicy.policy5;
                    break;
                case 6:
                    widgetInfo.value.dataModal = userPolicy.policy6;
                    break;
                case 7:
                    widgetInfo.value.dataModal = userPolicy.policy7;
                    break;
                default:
                    widgetInfo.value.dataModal = '';
            }

            gotoModal(null, 'PolicyModal', '약관상세보기');
        };

        const isAllSelected = computed(() => {
            return selectedPolicy.value.every((item, index) => item === checkedPolicy[userType.value][index]);
        });

        const checkPolicy = index => {
            selectedPolicy.value[index] = !selectedPolicy.value[index];
            //console.log(selectedPolicy.value + " / " + index);
        };

        const checkPolicyAll = () => {
            // selectedPolicy.value = selectedPolicy.value.map(item => !item);
            isAllSelected.value ? selectedPolicy.value.fill(false) : (selectedPolicy.value = [...checkedPolicy[userType.value]]); // 새로운 배열을 할당하여 복사
        };

        return {
            sysEnums,
            widgetInfo,
            pages,
            curPage,
            digits,
            userName,
            phoneNum,
            recipient,
            validNumber,
            //showPwaGuide,
            confirmNum,
            confirmMsg,
            email,
            browserInfo,
            userType,
            isToken,
            policyTitle,
            userPolicy,
            selectedPolicy,
            handlePhoneNumber,
            handleValidNumber,
            checkUserInfo,
            checkToken,
            checkedPolicy,
            checkExistUser,
            confirmValidNumber,
            isAllSelected,
            checkPolicy,
            checkPolicyAll,
            gotoModal,
            viewPolicy,
        };
    },
};
</script>

<template>
    <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div class="bg-overlay"></div>

        <div class="auth-page-content overflow-hidden pt-lg-5">
            <BContainer>
                <BRow>
                    <BCol lg="12">
                        <BCard no-body class="overflow-hidden border-0 m-0">
                            <BRow class="justify-content-center g-0">
                                <BCol lg="6">
                                    <div class="p-lg-5 p-4 auth-one-bg h-100">
                                        <div class="bg-overlay"></div>
                                        <div class="position-relative h-100 d-flex flex-column">
                                            <div v-if="!widgetInfo.isApp" class="mb-4">
                                                <router-link to="/" class="d-block">
                                                    <!-- <img src="@/assets/images/logo-light.png" alt="" height="18"> -->
                                                    <img src="assets/images/pageclip/symbol_100.png" alt="" height="36" />
                                                    <img src="assets/images/pageclip/pageclip_400.png" alt="" height="28" />
                                                </router-link>
                                            </div>
                                            <div class="mt-auto">
                                                <div class="mb-3">
                                                    <i class="ri-double-quotes-l display-4 text-success"></i>
                                                </div>

                                                <div class="text-center text-white-50">
                                                    <div>
                                                        <img src="assets/images/pageclip/symbol_100.png" alt="" height="46" />
                                                        <h5 class="mt-2 text-light">
                                                            {{ curPage === 1 ? '로그인 정보 입력하기!' : curPage === 2 ? '문자 확인하기!' : '이용약관 동의하기!' }}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </BCol>

                                <BCol lg="6">
                                    <div class="p-lg-5 p-4">
                                        <h5 class="text-primary">PageClip 서비스 가입하기</h5>

                                        <div class="mt-4">
                                            <div v-if="curPage === 1" :data-aos="widgetInfo.isApp ? '' : 'flip-left'">
                                                <div class="form-floating mb-3">
                                                    <input
                                                        type="text"
                                                        class="form-control text-center fs-20"
                                                        id="floatingInput1"
                                                        placeholder="휴대폰번호를 입력하여 주시기 바랍니다."
                                                        v-model="recipient"
                                                        @input="handlePhoneNumber"
                                                    />
                                                    <label for="floatingInput1" class="fw-bold ms-2 mt-1">휴대폰번호<span class="ps-2 fs-10 fw-normal">Phone</span></label>
                                                </div>

                                                <div class="form-floating mb-3">
                                                    <input
                                                        type="text"
                                                        class="form-control text-center fs-20"
                                                        id="floatingInput2"
                                                        placeholder="고객님의 이름을 입력하여 주시기 바랍니다."
                                                        v-model="userName"
                                                    />
                                                    <label for="floatingInput2" class="fw-bold ms-2 mt-1">고객명<span class="ps-2 fs-10 fw-normal">Name</span></label>
                                                </div>

                                                <div class="mt-5">
                                                    <div v-show="recipient && recipient.length < 12" class="p-2 h-50h text-center fw-bold fs-20 text-dark" v-text="phoneNum"></div>
                                                    <button class="btn btn-success h-50h w-100" type="button" @click="checkUserInfo" :disabled="recipient && recipient.length < 11">
                                                        다음
                                                    </button>
                                                </div>

                                                <div v-if="!widgetInfo.isApp" class="mt-4 text-center">
                                                    <div class="signin-other-title">
                                                        <h5 class="fs-13 mb-4 title text-muted">Create account with</h5>
                                                    </div>

                                                    <div>
                                                        <BButton type="button" variant="primary" class="btn-icon"><i class="ri-facebook-fill fs-16"></i></BButton>
                                                        <BButton type="button" variant="danger" class="btn-icon ms-1"><i class="ri-google-fill fs-16"></i></BButton>
                                                        <BButton type="button" variant="dark" class="btn-icon ms-1"><i class="ri-github-fill fs-16"></i></BButton>
                                                        <!-- <BButton type="button" variant="info" class="btn-icon ms-1"><i class="ri-twitter-fill fs-16"></i></BButton> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="curPage === 2" :data-aos="widgetInfo.isApp ? '' : 'flip-right'">
                                                <div class="mb-4">
                                                    <div class="avatar-lg mx-auto">
                                                        <div class="avatar-title bg-light text-primary display-5 rounded-circle">
                                                            <i class="ri-mail-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-muted text-center mx-lg-3">
                                                    <h4 class="">인증번호 확인</h4>
                                                    <p>
                                                        4자리 인증번호를 확인하여 주세요.<br />
                                                        <span class="fw-semibold" v-text="phoneNum"></span>
                                                        <span class="mx-2" v-text="' '"></span>
                                                        <span class="fw-semibold" v-text="userName"></span>
                                                    </p>
                                                </div>

                                                <div class="mt-4">
                                                    <div class="row position-relative">
                                                        <div v-for="(i, n) in 4" :key="i" class="col-3">
                                                            <div class="mb-3 form-control form-control-lg bg-light border-light text-center" v-text="digits[n]"></div>
                                                        </div>

                                                        <input
                                                            class="px-4 h-50h w-100 position-absolute top-0 left-0 opacity-0"
                                                            type="text"
                                                            maxlength="4"
                                                            v-model="validNumber"
                                                            @input="handleValidNumber($event)"
                                                        />
                                                    </div>

                                                    <div class="mt-3">
                                                        <button type="button" class="btn btn-success h-50h w-100" @click="confirmValidNumber">인증하기</button>
                                                    </div>
                                                </div>

                                                <div class="mt-5 text-center">
                                                    <p class="mb-0">인증번호를 받지못하셨습니까?</p>
                                                    <p href="#" class="fw-semibold text-primary text-decoration-underline hand" @click="curPage = 1">인증번호 다시받기</p>
                                                </div>
                                            </div>

                                            <div v-if="curPage === 3" :data-aos="widgetInfo.isApp ? '' : 'flip-left'">
                                                <div class="avatar-lg mx-auto mt-2">
                                                    <div class="avatar-title bg-light text-success display-3 rounded-circle">
                                                        <i class="ri-checkbox-circle-fill"></i>
                                                    </div>
                                                </div>
                                                <div class="mt-4 pt-2 text-center">
                                                    <h4>약관동의!</h4>
                                                    <p class="text-muted mx-4">PageClip 서비스를 이용하기 위해서는<br />아래의 약관에 동의하셔야 합니다</p>
                                                </div>

                                                <div class="mt-4 mb-4 text-start">
                                                    <p
                                                        class="hand"
                                                        :class="isAllSelected() ? 'fst-italic border-bottom' : 'fs-12 bg-danger badge rounded-pill'"
                                                        @click="checkPolicyAll"
                                                    >
                                                        전체동의
                                                    </p>
                                                    <template v-for="(i, n) in 7" :key="i">
                                                        <div v-if="checkedPolicy[userType][n]" class="mb-2 fs-12 text-muted d-flex">
                                                            <div class="form-check-inline flex-fill">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="checkbox"
                                                                    :id="'formCheck' + n"
                                                                    @click="checkPolicy(n)"
                                                                    :checked="selectedPolicy[n]"
                                                                />
                                                                <label class="form-check-label ps-2" :for="'formCheck' + n">{{ policyTitle[n] }}</label>
                                                            </div>
                                                            <span class="text-primary text-decoration-underline fst-normal fw-medium hand" @click="viewPolicy(i)">약관보기</span>
                                                        </div>
                                                    </template>
                                                </div>

                                                <div class="mt-5">
                                                    <button type="button" class="btn btn-success h-50h w-100" @click="checkToken">PageClip 가입하기</button>
                                                    <button type="button" class="btn h-50h w-100 mt-2" @click="curPage = 2">back</button>
                                                </div>
                                            </div>

                                            <div class="my-5 fw-bold fs-20 text-center text-danger" v-text="confirmMsg"></div>
                                        </div>

                                        <span v-if="!widgetInfo.isApp">
                                            <div class="mt-5 text-center">
                                                <p class="mb-0">
                                                    이미 PageClip을 이용하고 있습니다
                                                    <router-link to="/auth/signin-cover" class="fw-semibold text-primary text-decoration-underline"> 로그인</router-link>
                                                </p>
                                            </div>
                                        </span>
                                    </div>
                                </BCol>
                            </BRow>
                        </BCard>
                    </BCol>
                </BRow>
            </BContainer>
        </div>
    </div>

    <Layout></Layout>
</template>
