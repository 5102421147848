<template>

    <BCard no-body>
        <BCardHeader class="align-items-center d-flex py-3">
            <BCardTitle class="mb-0 flex-grow-1">{{ selectedPage.Title }}</BCardTitle>
        </BCardHeader>

        <BCardBody>

            <BRow>
                <BCol cols="12">

                    <div>
                        <flat-pickr v-model="selectedDate" :config="inlineConfig" class="form-control"></flat-pickr>
                    </div>

                    <div v-if="selectedPage && selectedPage.Expired" class="mt-3 text-center fs-20 fw-bold text-muted">
                        <span class="me-3">{{ $moment(selectedPage.Expired).format('YYYY-MM-DD hh:mm') }}</span>
                    </div>

                    <div class="mt-5 text-end">
                        <BButton variant="primary" @click="updatePage"><i class="ri-arrow-up-line"></i> 종료일 지정하기</BButton>
                    </div>

                </BCol>
            </BRow>

        </BCardBody>
    </BCard>

</template>

<script lang="js">
import { ref, inject } from "vue";
import { apiEnums, sysEnums } from "@/common/sysEnums";
import { showAlert } from "@/common/comFuncs";

import flatPickr from "vue-flatpickr-component";

export default {
    components: {
        flatPickr
    },
    props: {
        Page: String,
    },
    setup(props) {
        const titles = ref(['', '제목', '설명', '등록일', '상태']);

        const selectedDate = ref('');

        const inlineConfig = ref({
            // dateFormat: "Y-m-d", // Date format (e.g., "Y-m-d" for year-month-day)
            // minDate: "today", // Minimum selectable date (can be a date string or Date object)
            // maxDate: new Date().fp_incr(30), // Maximum selectable date (e.g., 30 days from today)
            // defaultDate: "today", // Default date to display when the picker opens
            // disableMobile: true, // Disable mobile-friendly behavior (e.g., touch gestures)
            // enableTime: false, // Enable time selection (set to true for date and time picker)
            // altInput: true, // Display an alternative input field (e.g., for user input)
            // altFormat: "F j, Y", // Date format for the alternative input field
            // inline: false, // Display the picker inline (embedded in the page)
            // locale: { // Localization settings
            //     firstDayOfWeek: 1, // First day of the week (0 for Sunday, 1 for Monday)
            //     weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"], // Short weekday names
            // },
            // mode: "range",
            position: 'above',
            inline: true,
            dateFormat: "Y-m-d",
            defaultDate: "today",
        });

        const axios = inject("$axios");

        const userInfo = inject('$userInfo');

        const { filteredFolder, selectedPage, getUserPage } = inject("$provideList");

        const handleDropFolderMove = (encFolder, event) => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            // updateFolder(droppedData.EncFolder, encFolder)
            console.log(encFolder + ' / ' + droppedData.EncFolder)

            // if (droppedData.EncWorkspace) {
            //     updateWorkspace(droppedData.EncWorkspace, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncWorkspace)
            // } else {
            //     updateFolder(droppedData.EncFolder, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncFolder)
            // }
        }

        const updatePage = () => {
            if (!selectedPage.value) {
                showAlert('Alert!', '페이지를 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            if (!selectedDate.value) {
                showAlert('Alert!', '종료일을 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            const formData = new FormData();
            // formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncPage', selectedPage.value.EncPage);
            formData.append('Expired', selectedDate.value);
            axios.post(apiEnums.CloudTicket.updatePage, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        getUserPage();
                    }
                })
                .catch(() => { });
        }


        return {
            props, userInfo,
            titles, filteredFolder,
            inlineConfig, selectedDate, selectedPage,
            handleDropFolderMove, updatePage
        };
    },
};
</script>

<style scoped>
/* Add custom styles for the circular appearance and selected date color */
.flatpickr-day.selected span {
    border-radius: 50%;
    /* Makes the selected date appear circular */
    background-color: #ffcc00;
    /* Set the background color for the selected date */
}
</style>