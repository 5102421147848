<template>
    <Layout>
        <div class="container-trans position-relative mt-4">
            <div :class="routeInfo.to === 0 ? 'slide-in' : routeInfo.to > 0 ? 'slide-left-out' : 'slide-left-out'">
                <component v-if="routeInfo.to === 0 || routeInfo.savedPosition === 99" :is="'AppIndex'">
                    <AppIndex></AppIndex>
                </component>
            </div>
            <div :class="routeInfo.to === 1 ? 'slide-in' : routeInfo.to > 1 ? 'slide-left-out' : 'slide-left-out'">
                <component v-if="routeInfo.to === 1 || routeInfo.savedPosition === 99" :is="'AppCompany'">
                    <AppCompany></AppCompany>
                </component>
            </div>
            <div :class="routeInfo.to === 2 ? 'slide-in' : routeInfo.to > 2 ? 'slide-left-out' : 'slide-right-out'">
                <component v-if="routeInfo.to === 2 || routeInfo.savedPosition === 99" :is="'AppFolderList'">
                    <AppFolderList></AppFolderList>
                </component>
            </div>
            <div :class="routeInfo.to === 3 ? 'slide-in' : routeInfo.to > 3 ? 'slide-left-out' : 'slide-right-out'">
                <component v-if="routeInfo.to === 3 || routeInfo.savedPosition === 99" :is="'ChatList'">
                    <ChatList></ChatList>
                </component>
            </div>
            <div :class="routeInfo.to === 4 ? 'slide-in' : routeInfo.to > 4 ? 'slide-right-out' : 'slide-right-out'">
                <component v-if="routeInfo.to === 4 || routeInfo.savedPosition === 99" :is="'UserCard'">
                    <UserCard></UserCard>
                </component>
            </div>
            <div :class="routeInfo.to === 6 ? 'slide-in' : routeInfo.to > 6 ? 'slide-right-out' : 'slide-right-out'">
                <component v-if="routeInfo.to === 6" :is="'FileList'">
                    <FileList></FileList>
                </component>
            </div>
            <div :class="routeInfo.to === 7 ? 'slide-in' : routeInfo.to > 7 ? 'slide-left-out' : 'slide-right-out'">
                <component v-if="routeInfo.to === 7" :is="'FileList'">
                    <FileList></FileList>
                </component>
            </div>
        </div>
    </Layout>
</template>

<script lang="js">
//import { showCanvas } from "@/common/comFuncs";
import { sysEnums } from "@/common/sysEnums";
import { onMounted, onBeforeUnmount, inject, ref, watch } from "vue";

import AppIndex from "@/components/apps/AppIndex";
import AppCompany from "@/components/apps/AppCompany";
import AppFolderList from "@/components/apps/AppFolderList";

import ChatList from "@/components/chats/ChatList";
import FileList from "@/components/files/FileList";

import UserCard from "@/components/users/UserCard";

import Layout from "../layouts/main";

export default ({
    props: ['to'],
    components: {
        Layout,
        AppIndex, AppCompany, AppFolderList,
        FileList,
        ChatList,
        UserCard,
    },
    setup(props) {
        //const startX = ref(0);
        //const isDragging = ref(false);
        //const totalX = ref(0);

        const compChat = ref(null);

        const store = inject("$store");
        const routeInfo = inject('$routeInfo');
        const widgetInfo = inject("$widgetInfo");

        //const { selectedFolder } = inject("$provideList");

        //const handleTouchStart = event => {
        //    if (routeInfo.value.to === 2 || routeInfo.value.to === 5 || routeInfo.value.to === 6) {
        //        isDragging.value = true;
        //        startX.value = event.touches[0].pageX;
        //    }
        //};

        //const handleTouchEnd = event => {
        //    if (!isDragging.value) return;

        //    if (routeInfo.value.to === 2 || routeInfo.value.to === 5 || routeInfo.value.to === 6) {
        //        const endX = event.changedTouches[0].pageX;
        //        const diffX = endX - startX.value;

        //        if (Math.abs(diffX) > 100) {
        //            // 스와이프 거리가 일정 거리 이상일 때
        //            //const direction = diffX > 0 ? 'right' : 'left';

        //            diffX > 0 ? (store.state.routeTo.to = 2) : (store.state.routeTo.to = 5);

        //            //if (store.state.routeTo.to === 2) {
        //            //    selectedFolder.value = [];
        //            //}

        //            isDragging.value = false;

        //            // Flutter로 방향 전달
        //            //toApp('loginInfo', JSON.stringify(datas));
        //        }
        //    }
        //};

        //const onTouchMove = event => {
        //    if (!isDragging.value) return;

        //    const currentX = event.touches[0].clientX;
        //    totalX.value = currentX - startX.value;

        //    if (Math.abs(totalX.value) > Math.abs(event.touches[0].clientY - event.touches[0].clientY)) {
        //        // 좌우 드래그 감지
        //        console.log('좌우 드래그', totalX.value > 0 ? '오른쪽' : showAlert('Alert!', '좌우 드래그 left', 'PageClip', sysEnums.AlertTypes.Info));
        //    }
        //};


        onMounted(() => {
            //document.addEventListener('touchstart', handleTouchStart);
            //document.addEventListener('touchend', handleTouchEnd);
        });

        onBeforeUnmount(() => {
            //document.removeEventListener('touchstart', handleTouchStart);
            //document.removeEventListener('touchend', handleTouchEnd);
        });


        watch(routeInfo, () => {
            const container = document.getElementById('app');
            if (container) {
                if (container.scrollTop > 0) {
                    container.scrollTop = 0;
                    // console.log(' routeInfo ' + JSON.stringify(routeInfo.value))
                }
            }

            store.commit('UP_ROUTE', 0);
        });

        return {
            props, sysEnums, widgetInfo,
            routeInfo, compChat,
        }
    },
});
</script>

<style scoped></style>
