<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <BRow>

            <BCol cols="6">
                <BCard no-body>
                    <!-- <BCardHeader class="justify-content-between d-flex align-items-center">
                        <BCardTitle>공지 보내기</BCardTitle>
                    </BCardHeader> -->
                    <BCardBody>
                        <table class="table table-striped table-hover text-center">
                            <thead>
                                <tr class="h-50h bg-light align-middle fw-bold">
                                    <th scope="col">번호</th>
                                    <th v-for="x in titles" :key="x">{{ x }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(x, i, n) in dataList" :key="x">
                                    <tr class="h-50h align-middle hand" @click="checkedList(x.find(item => item.Status === true), false)">
                                        <th class="w-10" scope="row">{{ n + 1 }}</th>
                                        <td>
                                            <span v-for="y in x" :key="y">
                                                <img :src="y.AvatarPath ? $fileUrl + y.AvatarPath : 'assets/images/no-profile.jpg'" class="avatar-xs rounded-circle-36"
                                                    :class="y.Status ? '' : 'opacity-25'" />
                                            </span>
                                        </td>
                                        <td class="w-10 text-start" v-text="x[0].UserName"></td>
                                        <td class="w-10" v-text="x.find(item => item.Status === true) ? enumTypes.UserTypes[x.find(item => item.Status === true).UserType] : '-'">
                                        </td>
                                        <td class="w-20" v-text="x[0].LoginId"></td>
                                        <!-- <td class="w-10" v-if="!$store.state.isMobile" v-text="$moment(x.find(item => item.Status === true).Updated).format('YY.MM.DD')"></td>
                                    <td class="w-10" v-if="!$store.state.isMobile" v-text="$moment(x.find(item => item.Status === true).Created).format('YY.MM.DD')"></td> -->
                                        <td class="w-10" v-if="!$store.state.isMobile">
                                            <i :class="x[0].Uid.length < 10 ? 'ri-wifi-off-line text-secondary' : 'ri-wifi-line text-danger'"></i>
                                        </td>
                                        <!-- <td class="w-10">
                                        <div class="form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" :checked="x.Selected" @click="checkedList(n)" />
                                        </div>
                                    </td> -->
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </BCardBody>
                </BCard>
            </BCol>

            <BCol cols="6">
                <BCard no-body>
                    <BCardBody>
                        <div class="form-floating w-100 mb-2">
                            <input type="text" class="form-control" id="floatingInput" placeholder="제목" v-model="alarmTitle" />
                            <label for="floatingInput" class="fw-bold">제목</label>
                        </div>

                        <textarea class="w-100 bg-dark text-light p-4 br-14" v-model="content" style="height:220px"></textarea>

                        <div class="my-4 d-flex justify-content-around hand">
                            <span @click="checkedGroup(sysEnums.UserTypes.Lessor)" class="text-center"><i class="fs-50"
                                    :class="userType === 2 ? 'las la-building text-danger' : 'las la-building'"></i><br>임대인</span>
                            <span @click="checkedGroup(sysEnums.UserTypes.Partner)" class="text-center"><i class="fs-50"
                                    :class="userType === 1 ? 'las la-handshake text-danger' : 'las la-handshake'"></i><br>파트너</span>
                            <span @click="checkedGroup(sysEnums.UserTypes.Lessee)" class="text-center"><i class="fs-50"
                                    :class="userType === 3 ? 'las la-user text-danger' : 'las la-user'"></i><br>임차인</span>
                            <span @click="checkedGroup(4)" class="text-center"><i class="fs-50"
                                    :class="userType === 4 ? 'las la-user-friends text-danger' : 'las la-user-friends'"></i><br>전체</span>
                        </div>

                        <div class="d-flex align-items-center flex-wrap">
                            <span class="mx-2 hand" v-for="(x, i) in selectedList" :key="x" @click="checkedList(i, true)">{{ x.UserName }}</span>
                        </div>

                        <div class="d-flex align-items-center mt-5 mb-3">
                            <select class="form-select h-50h me-2 w-100" aria-label="Default select example" v-model="notiType">
                                <option value="null" selected>메세지선택</option>
                                <option v-for="x in notiTypes" :key="x" :value="x" :text="enumTypes.NotiTypes[x]"></option>
                            </select><br>
                            <span class="w-100 mx-2">
                                <button class="btn btn-primary h-50h w-100 rounded" @click="crudAlarm(false)">등록</button>
                            </span>
                            <span class="w-100 mx-2">
                                <button class="btn btn-primary h-50h w-100 rounded" @click="crudAlarm(true)">수정</button>
                            </span>
                            <span class="w-100">
                                <button class="btn btn-danger h-50h w-100 rounded" @click="sendNotification">
                                    {{ selectedList.length }} 명 보내기
                                </button>
                            </span>
                        </div>

                    </BCardBody>
                </BCard>

                <BCard no-body>
                    <BCardBody>

                        <table class="table table-striped table-hover text-center">
                            <thead>
                                <tr class="h-50h bg-light align-middle fw-bold">
                                    <th scope="col">번호</th>
                                    <th v-for="x in titles1" :key="x">{{ x }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(x, n) in alarmList" :key="x">
                                    <tr class="h-50h align-middle hand" @click="setAlarmData(x)">
                                        <th class="w-10" scope="row">{{ n + 1 }}</th>
                                        <td class="w-15" v-text="enumTypes.NotiTypes[x.Type]"></td>
                                        <td class="text-start" v-text="x.Title"></td>
                                        <td class="w-25" v-if="!$store.state.isMobile" v-text="$moment(x.Created).format('YY-MM-DD hh:mm')"></td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>

                    </BCardBody>
                </BCard>
            </BCol>

        </BRow>

    </Layout>
</template>

<script lang="js">
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { onMounted, watch, inject, ref, reactive } from "vue";
import { apiEnums, sysEnums, enumTypes } from "@/common/sysEnums";
import { showAlert } from "@/common/comFuncs";

export default {
    components: {
        Layout,
        PageHeader,
    },
    props: {
        UserData: Object
    },
    setup(props) {
        const titles = ref(['Avatar', '회원명', '타입', '아이디', '상태']);
        const titles1 = ref(['구분', '제목', '등록일']);

        const notiTypes = reactive(sysEnums.NotiTypes);

        const dataList = ref({});

        let encAlarm = ref('');
        let notiType = ref(null);
        let alarmTitle = ref('');
        let content = ref('');
        let selectedList = ref([]);
        let userType = ref(0)

        const axios = inject("$axios");
        const store = inject("$store");

        const { userList, alarmList } = inject("$provideList");

        watch(() => userList.value, () => {
            dataReset();
        })

        onMounted(() => {
            // const datas = JSON.parse(JSON.stringify(userList.value));
            // selectedList.value = datas.filter(user => user.Status === true);
            dataReset();
            // console.log(' onMounted : ' + JSON.stringify(selectedList.value[0]));
        });

        const dataReset = () => {
            let obj = {};
            userList.value.forEach(item => {
                if (!obj[item.LoginId]) {
                    obj[item.LoginId] = [];
                }
                obj[item.LoginId].push(item);
            });
            dataList.value = obj;
        }

        const sendNotification = () => {
            let datas = selectedList.value;
            // console.log(notiType.value + ' / ' + JSON.stringify(datas));

            if (datas.length > 0 && content.value.length > 0) {
                let formData = new FormData();
                formData.append('SendData', JSON.stringify(datas));
                formData.append('UserName', '');
                formData.append('NotiType', notiType.value);
                formData.append('Content', content.value);
                formData.append('Title', enumTypes.NotiTypes[notiType.value]);
                axios.post(apiEnums.CloudTicket.sendNoti, formData)
                    .then(() => {
                        content.value = '';
                        showAlert('', datas.length + '건이 발송 되었습니다.', '', sysEnums.AlertTypes.Info);

                        sendNotiToClient(JSON.stringify(datas), content.value);
                    })
                    .catch(() => { });
            } else if (!content.value) {
                showAlert('', '발송 내용을 선택하세요.', 'HouseMeta', sysEnums.AlertTypes.Info);
            } else {
                showAlert('', '발송 대상을 선택하세요.', 'HouseMeta', sysEnums.AlertTypes.Info);
            }
        }

        // const setMsg = () => {
        //     notiType.value === sysEnums.NotiTypes.Contract ? content.value = '시스템 점검중 입니다.\n\n점검시간은 01:00 ~ 07:00입니다\n더 나은 서비스를 위한 협조 감사드립니다' :
        //         sysEnums.NotiTypes.ContractChked ? content.value = '앱 설치 방법을 공지 드립니다.\n\n설치시 제공되는 장점은 아래와 같습니다\n1. \n2. \n3.' :
        //             sysEnums.NotiTypes.Facility ? content.value = '새로운 버전이 업로드 되었습니다.\n\n업데이트중 문제발생시 하우스메타에 문의 바랍니다' :
        //                 sysEnums.NotiTypes.FacilityEdit ? content.value = '새로운 시설이 등록 되었습니다.\n\n상세보기' :
        //                     sysEnums.NotiTypes.Chat ? content.value = '시스템 점검중 입니다.' :
        //                         sysEnums.NotiTypes.User ? content.value = '시스템 점검중 입니다.' :
        //                             sysEnums.NotiTypes.UserEdit ? content.value = '시스템 점검중 입니다.' :
        //                                 sysEnums.NotiTypes.PayDo ? content.value = '시스템 점검중 입니다.' :
        //                                     sysEnums.NotiTypes.PayCancel ? content.value = '시스템 점검중 입니다.' :
        //                                         content.value = '시스템 점검중 입니다.\n\n점검시간은 01:00 ~ 07:00입니다\n\n더 나은 서비스를 위한 협조 감사드립니다';
        // }

        const sendNotiToClient = async (datas, msg) => {
            if (store.state.connection) {
                try {
                    if (datas) {
                        await store.state.connection.invoke('SendNotiToClient', datas, msg, enumTypes.SignalTypes.Noti);
                    }
                } catch (error) {
                    console.error('Error invoking SendMessageToClient : ', error);
                }
            } else {
                console.error('Error invoking SendMessageToClient : ' + store.state.connection);
            }
        }

        const checkedList = (x, flag) => {
            // selectedList의 복사본을 수정
            // selectedList.value[index].Selected = !selectedList.value[index].Selected;
            if (flag) {
                selectedList.value.splice(x, 1);
            } else {
                const exist = selectedList.value.find(item => item.EncUser === x.EncUser);
                if (!exist) {
                    selectedList.value.push(x);
                }
            }
        }

        const checkedGroup = (type) => {
            userType.value = type;
            selectedList.value = userList.value.filter(item => item.UserType === userType.value && item.Status === true);
            if (userType.value === 4) {
                selectedList.value = userList.value.filter(item => item.Status === true);
            }
        }

        const crudAlarm = (flag) => {
            if (flag && encAlarm.value === '') {
                showAlert('', '수정하실 알림을 선택 하세요!', 'HouseMeta', sysEnums.AlertTypes.Warning);
                return;
            }

            if (notiType.value === '') {
                showAlert('', '알림타입을 선택 하세요!', 'HouseMeta', sysEnums.AlertTypes.Warning);
                return;
            }

            if (alarmTitle.value === '') {
                showAlert('', '제목을 입력 하세요!', 'HouseMeta', sysEnums.AlertTypes.Warning);
                return;
            }

            if (content.value === '') {
                showAlert('', '알림 내역을 입력 하세요!', 'HouseMeta', sysEnums.AlertTypes.Warning);
                return;
            }

            const formData = new FormData();
            formData.append('EncAlarm', flag ? encAlarm.value : '');
            formData.append('Type', notiType.value);
            formData.append('Title', alarmTitle.value);
            formData.append('Content', content.value);
            axios.post(apiEnums.CloudTicket.crudAlarm, formData)
                .then((response) => {
                    store.dispatch("AC_ALARM", response.data);
                    alarmList.value = response.data;
                })
                .catch(() => { });
        };

        const setAlarmData = (x) => {
            encAlarm.value = x.EncAlarm;
            notiType.value = x.Type;
            alarmTitle.value = x.Title;
            content.value = x.Content;
        }

        return {
            title: "Notifiction",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "SendNoti",
                    active: true,
                },
            ],
            props, enumTypes, sysEnums,
            titles, titles1, userType,
            notiTypes, notiType, alarmTitle, content,
            userList, dataList, alarmList, selectedList,
            sendNotification, checkedList, checkedGroup,
            crudAlarm, setAlarmData
        };
    },
};
</script>

<style scoped lang="scss"></style>
