import { mergeAttributes, Node, VueNodeViewRenderer } from '@tiptap/vue-3'

import Component from '@/components/clips/TipTapComp'

export default Node.create({
    name: 'paper',
    group: 'block',
    atom: true,
    addAttributes() {
        return {
            lines: {
                default: [],
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: 'div[data-type="paper"]',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(HTMLAttributes, { 'data-type': 'paper' })]
    },

    addNodeView() {
        return VueNodeViewRenderer(Component)
    },
})