<template>
    <!-- {{ JSON.stringify(selectedFolder) }} -->

    <BRow v-if="selectedFolder.EncFolder && selectedFolder.FolderRoles.length > 0">
        <template v-for="x in selectedFolder.FolderRoles" :key="x">
            <BCol xl="3">
                <BCard no-body>
                    <BCardHeader>
                        <!-- <button type="button" class="btn-close float-end fs-11" aria-label="Close"></button> -->
                        <h6 class="card-title mb-0">
                            <span class="fw-semibold">{{ x.UserName }}</span>
                            <span class="text-muted fs-16 px-3">{{ getUserDepartment(x.Position, true) + ' / ' + getUserDepartment(x.Department, false) }}</span>
                        </h6>
                    </BCardHeader>
                    <BCardBody class="p-4 text-center">
                        <div class="mb-3 hand">
                            <img
                                :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/users/avatar-5.jpg'"
                                alt=""
                                class="mx-auto img-fit avatar-md rounded-circle"
                                :class="getSignalStatus(x.EncUser) ? '' : 'opacity-25'"
                                @click="fileRef.setFileData(x)"
                            />
                        </div>

                        <h5 class="card-title mb-1">
                            <UserUpdate :Data="x"></UserUpdate>
                        </h5>

                        <p class="text-muted mb-0">{{ x.Email || '-' }}</p>
                        <p class="text-muted mb-0">{{ $moment(x.Updated).format('YYYY.MM.DD') }} 수정</p>

                        <span class="position-absolute start-50 top-50 text-muted ps-5 ms-4">
                            <span class="pe-2">
                                <i class="ri-message-2-line fs-20"></i>
                            </span>
                            <span class="ps-2">
                                <i class="ri-mail-send-line fs-20"></i>
                            </span>
                        </span>
                    </BCardBody>
                    <!-- <BCardFooter class="text-center">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item text-muted">
                                    <i class="ri-space"></i> POST <span class="text-primary">{{ x.Folders.length || 0 }}</span>
                                </li>
                                <li class="list-inline-item text-muted px-3">
                                    <i class="ri-article-line"></i> PAGE <span class="text-primary">{{ x.Pages.length || 0 }}</span>
                                </li>
                                <li class="list-inline-item text-muted">
                                    <i class="ri-attachment-2"></i> 첨부파일 <span class="text-primary">{{ x.Files.length || 0 }}</span>
                                </li>
                            </ul>
                        </BCardFooter> -->
                </BCard>
            </BCol>
        </template>
    </BRow>

    <BRow v-else-if="!selectedFolder.EncFolder && userList.length > 0">
        <template v-for="x in userList" :key="x">
            <BCol xl="3">
                <BCard no-body>
                    <BCardHeader>
                        <!-- <button type="button" class="btn-close float-end fs-11" aria-label="Close"></button> -->
                        <h6 class="card-title mb-0">
                            <span class="fw-semibold">{{ x.UserName }}</span>
                            <span class="text-muted fs-16 px-3">{{ getUserDepartment(x.Position, true) + ' / ' + getUserDepartment(x.Department, false) }}</span>
                        </h6>
                    </BCardHeader>
                    <BCardBody class="p-4 text-center">
                        <div class="mb-3 hand">
                            <img
                                :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/users/avatar-5.jpg'"
                                alt=""
                                class="mx-auto img-fit avatar-md rounded-circle"
                                :class="x.Status ? '' : 'opacity-25'"
                                @click="fileRef.setFileData(x)"
                            />
                        </div>

                        <h5 class="card-title mb-1">
                            <UserUpdate :Data="x"></UserUpdate>
                        </h5>

                        <p class="text-muted mb-0">{{ x.Email || '-' }}</p>
                        <p class="text-muted mb-0">{{ $moment(x.Created).format('YYYY.MM.DD') }} 가입</p>

                        <span class="position-absolute start-50 top-50 text-muted ps-5 ms-4">
                            <span class="pe-2">
                                <i class="ri-message-2-line fs-20"></i>
                            </span>
                            <span class="ps-2">
                                <i class="ri-mail-send-line fs-20"></i>
                            </span>
                        </span>
                    </BCardBody>
                    <BCardFooter class="text-center">
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item text-muted">
                                <i class="ri-space"></i> POST <span class="text-primary">{{ x.Folders.length || 0 }}</span>
                            </li>
                            <li class="list-inline-item text-muted px-3">
                                <i class="ri-article-line"></i> PAGE <span class="text-primary">{{ x.Pages }}</span>
                            </li>
                            <li class="list-inline-item text-muted">
                                <i class="ri-attachment-2"></i> 첨부파일 <span class="text-primary">{{ x.Files }}</span>
                            </li>
                        </ul>
                    </BCardFooter>
                </BCard>
            </BCol>
        </template>
    </BRow>

    <BRow v-else>
        <BCol class="project-card">
            <BCard no-body class="border-0">
                <BCardBody class="pt-5 bg-app">
                    <div class="mt-5 text-center">
                        <h4 class="pt-5 mt-5">등록된 사용자가 없습니다</h4>
                        <p>
                            <Lottie colors="primary:#25a0e2,secondary:#00bd9d" trigger="loop" :options="aniOption1" :height="240" :width="240"></Lottie>
                        </p>
                    </div>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>

    <span class="d-none">
        <FileUploadSingle ref="fileRef" :FileType="sysEnums.FileTypes.Avatar" @SingleFileUploaded="getUser"></FileUploadSingle>
    </span>
</template>

<script lang="js">
import { getSignalStatus, getUserDepartment } from "@/common/comFuncs";
import { enumTypes, sysEnums } from "@/common/sysEnums";
import { inject, reactive, ref } from 'vue';

import FileUploadSingle from "@/components/files/FileUploadSingle";
import UserUpdate from "@/components/users/UserUpdate";

import Lottie from '@/components/widgets/lottie.vue';
import aniData1 from '@/components/widgets/member.json';

export default {
    components: {
        UserUpdate,
        FileUploadSingle,
        Lottie,
    },
    props: {
    },
    setup(props) {
        const fileRef = ref(null);

        const aniOption1 = reactive({ animationData: aniData1 });

        const { userList, getUser, selectedFolder } = inject("$provideList");

        return {
            sysEnums, enumTypes, selectedFolder,
            userList, getUser,
            fileRef, props, aniOption1,
            getUserDepartment, getSignalStatus,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable='true'] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
