<script>
import { layoutMethods } from '@/state/helpers';

import simplebar from 'simplebar-vue';

import i18n from '../i18n';

import { enumTypes, sysEnums } from '@/common/sysEnums';
import { inject } from 'vue';
import { useRoute } from 'vue-router';

/**
 * Nav-bar Component
 */
export default {
    setup() {
        const route = useRoute();

        const widgetInfo = inject('$widgetInfo');
        const companys = inject('$companys');
        const users = inject('$users');
        const folders = inject('$folders');
        const pages = inject('$pages');
        const moment = inject('$moment');

        const userInfo = inject('$userInfo');

        const store = inject('$store');

        const { companyList, userList, notiList, chatData, filteredFolder, filteredPage, gotoModal, routeTo, selectedFolder } = inject('$provideList');

        let fData = [];
        let datas = [];

        const hasSpecialCharacters = /[~!@#$*]/;

        const filterList = event => {
            const inputValue = event.target.value;
            widgetInfo.value.tag = sysEnums.PageTags.Search;

            if (route.path === '/page') {
                selectedFolder.value = [];
            }

            if (inputValue === '') {
                widgetInfo.value.tag = sysEnums.PageTags.Default;

                route.path === '/' && widgetInfo.value.activeTab[0] === 1
                    ? (datas = users.value)
                    : route.path === '/' && widgetInfo.value.activeTab[0] === 2
                    ? (datas = folders.value)
                    : route.path === '/' && widgetInfo.value.activeTab[0] === 3
                    ? ((fData = folders.value), (datas = pages.value))
                    : route.path === '/company'
                    ? (datas = companys.value)
                    : route.path === '/user' || route.path === '/notification'
                    ? (datas = users.value)
                    : null;
            } else if (route.path === '/' && hasSpecialCharacters.test(inputValue)) {
                let dateCondition = '';
                let addressCondition = '';
                let hashtagCondition = '';
                let filenameCondition = '';
                // let usernameCondition = '';

                // 공백 사용시
                // const dateMatch = inputValue.match(/~(\d+)/);
                // const addressMatch = inputValue.match(/!(\S+)/);
                // const usernameMatch = inputValue.match(/@(\S+)/);
                // const hashtagMatch = inputValue.match(/#(\S+)/);
                // const filenameMatch = inputValue.match(/\*(\S+)/);
                // const currencyMatch = inputValue.match(/\$(\d+(\.\d+)?)/);

                // 공백 또는 공백없이 모두
                const dateMatch = inputValue.match(/~(\d+)(?=\s|!|@|#|\*|$|$)/);
                const addressMatch = inputValue.match(/!(\S+?)(?=\s|~|@|#|\*|$|$)/);
                const hashtagMatch = inputValue.match(/#(\S+?)(?=\s|~|!|@|\*|$|$)/);
                const filenameMatch = inputValue.match(/\*(\S+?)(?=\s|~|!|@|#|$|$)/);
                // const usernameMatch = inputValue.match(/@(\S+?)(?=\s|~|!|#|\*|$|$)/);

                if (dateMatch) dateCondition = dateMatch[1].trim();
                if (addressMatch) addressCondition = addressMatch[1].trim();
                if (hashtagMatch) hashtagCondition = hashtagMatch[1].trim();
                if (filenameMatch) filenameCondition = filenameMatch[1].trim();
                // if (usernameMatch) usernameCondition = usernameMatch[1].trim();

                // console.log(filenameMatch);

                datas = pages.value.filter(page => {
                    let dateMatch = [];
                    let addressMatch = [];
                    let usernameMatch = [];
                    let hashtagMatch = [];
                    let filenameMatch = [];

                    if (dateCondition) dateMatch = moment(page.Created).format('YYYYMMDD').includes(dateCondition);
                    if (addressCondition) addressMatch = page.Address1 && page.Address1.includes(addressCondition);
                    if (hashtagCondition)
                        hashtagMatch = page.PageClips && page.PageClips.filter(x => x.Type === sysEnums.ClipTypes.Default).some(x => x.Content.includes(hashtagCondition));
                    if (filenameCondition) filenameMatch = page.PageFiles && page.PageFiles.some(x => x.FileName.includes(filenameCondition));
                    // if (usernameCondition) usernameMatch = page.UserName && page.UserName.includes(usernameCondition);

                    return dateMatch && addressMatch && usernameMatch && hashtagMatch && filenameMatch;
                });
            } else if ((route.path === '/' || route.path === '/page') && widgetInfo.value.activeTab[0] === 1) {
                datas = users.value.filter(user => {
                    return user.UserName.includes(inputValue) || user.LoginId.includes(inputValue);
                });
            } else if (route.path === '/' && widgetInfo.value.activeTab[0] === 2) {
                datas = folders.value.filter(folder => {
                    return folder.UserName.includes(inputValue) || folder.Name.includes(inputValue);
                });
            } else if ((route.path === '/' || route.path === '/page') && widgetInfo.value.activeTab[0] === 3) {
                fData = folders.value.filter(folder => {
                    return folder.UserName.includes(inputValue) || folder.Name.includes(inputValue);
                });

                datas = pages.value.filter(page => {
                    return page.UserName.includes(inputValue) || page.Title.includes(inputValue);
                });
            } else if (route.path === '/company') {
                datas = companys.value.filter(company => {
                    return company.Name.includes(inputValue) || company.Address1.includes(inputValue);
                });
            } else if (route.path === '/user' || route.path === '/notification') {
                datas = users.value.filter(user => {
                    return user.UserName.includes(inputValue) || user.LoginId.includes(inputValue);
                });
            }

            widgetInfo.value.search = inputValue;

            switch (route.path) {
                case '/user':
                    break;
                case '/company':
                    companyList.value = datas;
                    break;
                case '/':
                case '/page':
                    if (widgetInfo.value.activeTab[0] === 1) userList.value = datas;
                    if (widgetInfo.value.activeTab[0] === 2) {
                        // widgetInfo.value.search = inputValue;
                        filteredFolder.value = datas;
                    }
                    if (widgetInfo.value.activeTab[0] === 3) {
                        filteredFolder.value = fData;
                        filteredPage.value = datas;
                    }
                    break;
                case '/notification':
                    userList.value = datas;
                    break;
                default:
                    // Handle the default case here if necessary
                    break;
            }

            // $store.dispatch('AC_FILTERED_CONTRACT', datas);
            // console.log('searchContract : ' + inputValue + ' / ' + route.path + '/' + JSON.stringify(users.value));
        };

        const setSwiperIndex = flag => {
            const totalSlides = 3;

            if (flag) {
                // 다음 슬라이드로 이동 (마지막 슬라이드에서 첫 번째로 순환)
                widgetInfo.value.swiperIndex = (widgetInfo.value.swiperIndex + 1) % totalSlides;
            } else {
                // 이전 슬라이드로 이동 (첫 번째 슬라이드에서 마지막으로 순환)
                widgetInfo.value.swiperIndex = (widgetInfo.value.swiperIndex - 1 + totalSlides) % totalSlides;
            }
        };

        return {
            store,
            sysEnums,
            widgetInfo,
            userInfo,
            enumTypes,
            notiList,
            chatData,
            gotoModal,
            routeTo,
            filterList,
            setSwiperIndex,
        };
    },
    data() {
        return {
            cartItems: [],
            lan: i18n.locale,
            text: null,
            flag: null,
            value: null,
            myVar: 1,
        };
    },
    components: {
        simplebar,
    },

    methods: {
        ...layoutMethods,
        isCustomDropdown() {
            var searchOptions = document.getElementById('search-close-options');
            // var dropdown = document.getElementById('search-dropdown');
            var searchInput = document.getElementById('search-options');

            // searchInput.addEventListener('focus', () => {
            //     var inputLength = searchInput.value.length;
            //     if (inputLength > 0) {
            //         dropdown.classList.add('show');
            //         searchOptions.classList.remove('d-none');
            //     } else {
            //         dropdown.classList.remove('show');
            //         searchOptions.classList.add('d-none');
            //     }
            // });

            // searchInput.addEventListener('keyup', () => {
            //     var inputLength = searchInput.value.length;
            //     if (inputLength > 0) {
            //         dropdown.classList.add('show');
            //         searchOptions.classList.remove('d-none');
            //     } else {
            //         dropdown.classList.remove('show');
            //         searchOptions.classList.add('d-none');
            //     }
            // });

            searchOptions.addEventListener('click', () => {
                searchInput.value = '';
                // dropdown.classList.remove('show');
                searchOptions.classList.add('d-none');
            });

            document.body.addEventListener('click', e => {
                if (e.target.getAttribute('id') !== 'search-options') {
                    // dropdown.classList.remove('show');
                    searchOptions.classList.add('d-none');
                }
            });
        },

        toggleHamburgerMenu() {
            var windowSize = document.documentElement.clientWidth;
            let layoutType = document.documentElement.getAttribute('data-layout');

            document.documentElement.setAttribute('data-sidebar-visibility', 'show');
            let visiblilityType = document.documentElement.getAttribute('data-sidebar-visibility');

            if (windowSize > 767) document.querySelector('.hamburger-icon').classList.toggle('open');

            //For collapse horizontal menu
            if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
                document.body.classList.contains('menu') ? document.body.classList.remove('menu') : document.body.classList.add('menu');
            }

            //For collapse vertical menu
            if (visiblilityType === 'show' && (layoutType === 'vertical' || layoutType === 'semibox')) {
                if (windowSize < 1025 && windowSize > 767) {
                    document.body.classList.remove('vertical-sidebar-enable');
                    document.documentElement.getAttribute('data-sidebar-size') == 'sm'
                        ? document.documentElement.setAttribute('data-sidebar-size', '')
                        : document.documentElement.setAttribute('data-sidebar-size', 'sm');
                } else if (windowSize > 1025) {
                    document.body.classList.remove('vertical-sidebar-enable');
                    document.documentElement.getAttribute('data-sidebar-size') == 'lg'
                        ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
                        : document.documentElement.setAttribute('data-sidebar-size', 'lg');
                } else if (windowSize <= 767) {
                    document.body.classList.add('vertical-sidebar-enable');
                    document.documentElement.setAttribute('data-sidebar-size', 'lg');
                }
            }

            // console.log(layoutType + ' / ' + visiblilityType);

            //Two column menu
            if (document.documentElement.getAttribute('data-layout') == 'twocolumn') {
                document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
            }

            const secondNav = document.getElementById('navbar-nav-secondary');
            secondNav.classList.toggle('d-none');
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        initFullScreen() {
            document.body.classList.toggle('fullscreen-enable');
            if (
                !document.fullscreenElement &&
                /* alternative standard method */
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        setLanguage(locale, country, flag) {
            this.lan = locale;
            this.text = country;
            this.flag = flag;
            document.getElementById('header-lang-img').setAttribute('src', flag);
            i18n.global.locale = locale;
        },
        toggleDarkMode() {
            if (document.documentElement.getAttribute('data-bs-theme') == 'dark') {
                document.documentElement.setAttribute('data-bs-theme', 'light');
            } else {
                document.documentElement.setAttribute('data-bs-theme', 'dark');
            }

            const mode = document.documentElement.getAttribute('data-bs-theme');
            this.changeMode({
                mode: mode,
            });
        },
        removeItem(cartItem) {
            this.cartItems = this.cartItems.filter(item => item.id !== cartItem.id);
            this.$emit('cart-item-price', this.cartItems.length);
        },
    },

    computed: {
        calculateTotalPrice() {
            return this.cartItems.reduce((total, item) => total + parseFloat(item.itemPrice), 0).toFixed(2);
        },
    },
    mounted() {
        document.addEventListener('scroll', function () {
            var pageTopbar = document.getElementById('page-topbar');
            if (pageTopbar) {
                document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50
                    ? pageTopbar.classList.add('topbar-shadow')
                    : pageTopbar.classList.remove('topbar-shadow');
            }
        });
        if (document.getElementById('topnav-hamburger-icon')) document.getElementById('topnav-hamburger-icon').addEventListener('click', this.toggleHamburgerMenu);

        this.isCustomDropdown();
    },
};
</script>

<template>
    <header id="page-topbar" :class="widgetInfo.isApp ? 'pt-40 border-0' : ''">
        <div class="navbar-header">
            <!--워크스페이스 탭 선택시 일경우 네비게이션 표시-->
            <template v-if="$store.getters['GE_ROUTE'].to === 2">
                <div class="w-100 d-flex align-items-center justify-content-around">
                    <!--<BButton @click="setSwiperIndex(false)">back</BButton>-->
                    <i class="ri-arrow-left-s-line fs-50" @click="setSwiperIndex(false)"></i>
                    <h5>{{ widgetInfo.swiperIndex === 0 ? 'WORKSPACE' : widgetInfo.swiperIndex === 1 ? 'POST' : 'PAGE' }}</h5>
                    <!--<BButton @click="setSwiperIndex(true)">next</BButton>-->
                    <i class="ri-arrow-right-s-line fs-50" @click="setSwiperIndex(true)"></i>
                </div>
            </template>

            <template v-else>
                <div class="d-flex">
                    <!-- LOGO -->
                    <div class="navbar-brand-box horizontal-logo">
                        <router-link to="/" class="logo logo-dark">
                            <span class="logo-sm">
                                <img src="assets/images/pageclip/symbol_100.png" alt="" height="48" />
                            </span>
                            <span class="logo-lg">
                                <img src="assets/images/pageclip/symbol_100.png" alt="" height="36" />
                            </span>
                        </router-link>
                        <router-link to="/" class="logo logo-light">
                            <span class="logo-sm">
                                <img src="assets/images/pageclip/symbol_100.png" alt="" height="48" />
                            </span>
                            <span class="logo-lg">
                                <img src="assets/images/pageclip/symbol_100.png" alt="" height="36" />
                            </span>
                        </router-link>
                    </div>

                    <button v-if="!widgetInfo.isApp" type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
                        <span class="hamburger-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </button>

                    <!--{{ store.state.routeTo.to }}-->

                    <!-- App Search-->
                    <form class="app-search d-none d-md-block">
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Search..." autocomplete="off" id="search-options" v-on:input="filterList" />
                            <span class="mdi mdi-magnify search-widget-icon"></span>
                            <span class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none" id="search-close-options"></span>
                        </div>

                        <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                            <simplebar data-simplebar style="max-height: 320px"> </simplebar>

                            <div class="text-center pt-3 pb-1">
                                <!-- <router-link to="/pages/search-results" class="btn btn-primary btn-sm">View All Results <i class="ri-arrow-right-line ms-1"></i></router-link> -->
                                <router-link to="/" class="btn btn-primary btn-sm">View All Results <i class="ri-arrow-right-line ms-1"></i></router-link>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="d-flex align-items-center">
                    <BDropdown
                        class="dropdown d-md-none topbar-head-dropdown header-item"
                        variant="ghost-secondary"
                        dropstart
                        :offset="{ alignmentAxis: 55, crossAxis: 15, mainAxis: 0 }"
                        toggle-class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle show  arrow-none"
                        menu-class="dropdown-menu-lg dropdown-menu-end p-0"
                    >
                        <template #button-content>
                            <i class="bx bx-search fs-22"></i>
                        </template>
                        <BDropdownItem aria-labelledby="page-header-search-dropdown">
                            <form class="p-3">
                                <div class="form-group m-0">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                        <BButton variant="primary">
                                            <i class="mdi mdi-magnify"></i>
                                        </BButton>
                                    </div>
                                </div>
                            </form>
                        </BDropdownItem>
                    </BDropdown>

                    <!--바로가기 메뉴-->
                    <BDropdown
                        class="ms-2 dropdown"
                        variant="ghost-secondary"
                        dropstart
                        :offset="{ alignmentAxis: 57, crossAxis: 0, mainAxis: -42 }"
                        toggle-class="btn-icon btn-topbar rounded-circle mode-layout ms-1 arrow-none"
                        menu-class="p-0 dropdown-menu-end"
                    >
                        <template #button-content>
                            <i class="bx bx-category-alt fs-22"></i>
                        </template>
                        <div class="p-3 border-top-0 dropdown-head border-start-0 border-end-0 border-dashed border dropdown-menu-lg">
                            <BRow class="align-items-center">
                                <BCol>
                                    <h6 class="m-0 fw-semibold fs-15">Web Apps</h6>
                                </BCol>
                                <BCol cols="auto">
                                    <BLink class="btn btn-sm btn-soft-info">
                                        View All Apps
                                        <i class="ri-arrow-right-s-line align-middle"></i>
                                    </BLink>
                                </BCol>
                            </BRow>
                        </div>

                        <div class="p-2">
                            <BRow class="g-0">
                                <BCol>
                                    <BLink class="dropdown-icon-item" @click="gotoModal(null, 'UserInvite', '회원가입 요청하기')">
                                        <i class="ri-user-add-line fs-30"></i>
                                        <span>초대하기</span>
                                    </BLink>
                                </BCol>
                                <BCol>
                                    <BLink class="dropdown-icon-item">
                                        <i class="ri-safe-2-line fs-30"></i>
                                        <span>계좌정보</span>
                                    </BLink>
                                </BCol>
                                <BCol>
                                    <BLink class="dropdown-icon-item">
                                        <i class="ri-map-pin-line fs-30"></i>
                                        <span>찾아오는길</span>
                                    </BLink>
                                </BCol>
                            </BRow>

                            <BRow class="g-0">
                                <BCol>
                                    <BLink class="dropdown-icon-item" @click="gotoModal(null, 'SendFile', '회사파일 보내기')">
                                        <i class="ri-attachment-2 fs-30"></i>
                                        <span>파일보내기</span>
                                    </BLink>
                                </BCol>
                                <BCol>
                                    <BLink class="dropdown-icon-item" @click="gotoModal(null, 'PageCreate', '새로운 페이지 간편 등록하기')">
                                        <i class="ri-quill-pen-line fs-30"></i>
                                        <span>페이지작성</span>
                                    </BLink>
                                </BCol>
                                <BCol>
                                    <BLink class="dropdown-icon-item" @click="gotoModal(null, 'PageCreate', '새로운 일정 간편 등록하기')">
                                        <i class="ri-calendar-check-line fs-30"></i>
                                        <span>일정등록</span>
                                    </BLink>
                                </BCol>
                            </BRow>
                        </div>
                    </BDropdown>

                    <div class="ms-1 header-item d-none d-sm-flex">
                        <BButton type="button" variant="ghost-secondary" class="btn-icon btn-topbar rounded-circle" data-toggle="fullscreen" @click="initFullScreen">
                            <i class="bx bx-fullscreen fs-22"></i>
                        </BButton>
                    </div>

                    <div class="ms-1 header-item d-none d-sm-flex">
                        <BButton type="button" variant="ghost-secondary" class="btn-icon btn-topbar rounded-circle light-dark-mode" @click="toggleDarkMode">
                            <i class="bx bx-moon fs-22"></i>
                        </BButton>
                    </div>

                    <!--마이페이지 아바타 영역-->
                    <BDropdown
                        variant="ghost-dark"
                        dropstart
                        class="dropdown"
                        :offset="{ alignmentAxis: 57, crossAxis: 0, mainAxis: -42 }"
                        toggle-class="btn-icon btn-topbar rounded-circle arrow-none"
                        id="page-header-notifications-dropdown"
                        menu-class="dropdown-menu-lg dropdown-menu-end p-0"
                        auto-close="outside"
                    >
                        <template #button-content>
                            <i class="bx bx-bell fs-22"></i>
                            <span class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                                <span class="notification-badge">0</span>
                                <span class="visually-hidden">unread messages</span>
                            </span>
                        </template>
                        <div class="dropdown-head bg-primary bg-pattern rounded-top dropdown-menu-lg">
                            <div class="p-3">
                                <BRow class="align-items-center">
                                    <BCol>
                                        <h6 class="m-0 fs-16 fw-semibold text-white">Notifications</h6>
                                    </BCol>
                                    <BCol cols="auto" class="dropdown-tabs">
                                        <BBadge variant="light-subtle" class="bg-light-subtle text-body fs-13"> 0 New</BBadge>
                                    </BCol>
                                </BRow>
                            </div>
                        </div>

                        <BTabs nav-class="dropdown-tabs nav-tab-custom bg-primary px-2 pt-2">
                            <BTab title="All (0)" class="tab-pane fade py-2 ps-2 show" id="all-noti-tab" role="tabpanel">
                                <simplebar data-simplebar style="max-height: 300px" class="pe-2">
                                    <template v-for="x in chatData" :key="x">
                                        <div class="text-reset notification-item d-block dropdown-item position-relative">
                                            <div class="d-flex">
                                                <div class="avatar-xs me-3 flex-shrink-0">
                                                    <span class="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                                        <i class="bx bx-badge-check"></i>
                                                    </span>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <BLink class="stretched-link">
                                                        <h6 class="mt-0 mb-2 lh-base">
                                                            {{ JSON.stringify(x) }}
                                                        </h6>
                                                    </BLink>
                                                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                        <span><i class="mdi mdi-clock-outline"></i> {{ $moment(x.Created).format('YYYY-MM-DD') }}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="my-3 text-center">
                                        <BButton type="button" variant="soft-success"> View All Notifications <i class="ri-arrow-right-line align-middle"></i> </BButton>
                                    </div>
                                </simplebar>
                            </BTab>

                            <BTab title="알림" class="tab-pane fade py-2 ps-2" id="messages-tab" role="tabpanel" aria-labelledby="messages-tab">
                                <simplebar data-simplebar style="max-height: 300px" class="pe-2">
                                    <div class="my-3 text-center">
                                        <BButton type="button" variant="soft-success">
                                            View All Messages
                                            <i class="ri-arrow-right-line align-middle"></i>
                                        </BButton>
                                    </div>
                                </simplebar>
                            </BTab>

                            <BTab title="공지" class="p-4">
                                <simplebar data-simplebar style="max-height: 300px" class="pe-2">
                                    <div class="w-25 w-sm-50 pt-3 mx-auto">
                                        <img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
                                    </div>
                                    <div class="text-center pb-5 mt-2">
                                        <h6 class="fs-18 fw-semibold lh-base">Hey! You have no any notifications</h6>
                                    </div>
                                </simplebar>
                            </BTab>

                            <BTab title="채팅" class="p-4">
                                <simplebar data-simplebar style="max-height: 300px" class="pe-2">
                                    <div class="w-25 w-sm-50 pt-3 mx-auto">
                                        <img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
                                    </div>
                                    <div class="text-center pb-5 mt-2">
                                        <h6 class="fs-18 fw-semibold lh-base">Hey! You have no any notifications</h6>
                                    </div>
                                </simplebar>
                            </BTab>
                        </BTabs>
                    </BDropdown>

                    <BDropdown
                        variant="link"
                        class="ms-sm-3 header-item topbar-user"
                        toggle-class="rounded-circle arrow-none"
                        menu-class="dropdown-menu-end"
                        :offset="{ alignmentAxis: -14, crossAxis: 0, mainAxis: 0 }"
                    >
                        <template #button-content>
                            <span class="d-flex align-items-center">
                                <img :src="userInfo.Avatar ? $fileUrl + userInfo.Avatar : 'assets/images/no-profile.jpg'" class="avatar-xs rounded-circle img-fit" />
                                <span class="text-start ms-xl-2">
                                    <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ userInfo.UserName }}</span>
                                    <span class="d-none d-xl-block ms-1 fs-12 user-name-sub-text">{{ enumTypes.UserTypes[userInfo.UserType] }}</span>
                                </span>
                            </span>
                        </template>
                        <h6 class="dropdown-header">Welcome {{ userInfo.UserName }}</h6>

                        <router-link class="dropdown-item" to="/company">
                            <i class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle"> Profile</span>
                        </router-link>
                        <div class="dropdown-item hand" @click="routeTo('/page', sysEnums.PageTags.History)">
                            <i class="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle"> Activities</span>
                        </div>
                        <div class="dropdown-item hand" @click="routeTo('/page', sysEnums.PageTags.Post)">
                            <i class="ri-space text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle"> Post</span>
                        </div>
                        <div class="dropdown-item hand" @click="routeTo('/page', sysEnums.PageTags.Page)">
                            <i class="ri-file-line text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle"> Pages</span>
                        </div>
                        <div class="dropdown-item hand" @click="routeTo('/page', sysEnums.PageTags.Noti)">
                            <i class="ri-chat-smile-3-line text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle"> Comments</span>
                        </div>
                        <div class="dropdown-item hand" @click="routeTo('/page', sysEnums.PageTags.File)">
                            <i class="ri-attachment-2 text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle"> Files</span>
                        </div>
                        <div class="dropdown-divider"></div>
                        <!-- <div class="dropdown-item">
                            <i class="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle"> Balance : <b>$5971.67</b></span>
                        </div> -->
                        <div class="dropdown-item hand">
                            <BBadge variant="success-subtle" class="bg-success-subtle text-success mt-1 float-end">New</BBadge
                            ><i class="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle"> Settings</span>
                        </div>
                        <div class="dropdown-item hand">
                            <i class="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle"> Lock screen</span>
                        </div>

                        <router-link class="dropdown-item" to="/logout"
                            ><i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                            <span class="align-middle" data-key="t-logout"> Logout</span>
                        </router-link>
                    </BDropdown>
                </div>
            </template>
        </div>
    </header>
</template>
