<script>
import { showAlert } from '@/common/comFuncs';
import { apiEnums, sysEnums } from '@/common/sysEnums';
import { inject, ref } from 'vue';

export default {
    setup() {
        const encFolder = ref('');

        const axios = inject('$axios');

        const userInfo = inject('$userInfo');
        const widgetInfo = inject('$widgetInfo');

        const { folderList, getUserFolder, selectedFolder } = inject('$provideList');

        const folderType = ref(true);

        const createFolder = () => {
            if (!selectedFolder.value.Name) {
                showAlert('', '폴더명을 입력 하세요!', 'PageClip', sysEnums.AlertTypes.Warning);
                return;
            }

            if (!selectedFolder.value.Description) {
                showAlert('', '폴더 설명을 입력 하세요!', 'PageClip', sysEnums.AlertTypes.Warning);
                return;
            }

            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('Name', selectedFolder.value.Name);
            formData.append('Description', selectedFolder.value.Description);
            formData.append('Type', folderType.value ? sysEnums.PageCategorys.Folder : sysEnums.PageCategorys.Post);
            axios
                .post(apiEnums.CloudTicket.createFolder, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        // console.log(response.data);

                        widgetInfo.value.modal = false;
                        widgetInfo.value.titleModal = '';
                        widgetInfo.value.targetModal = null;
                    }
                })
                .catch(() => {});
        };

        const updateFolder = () => {
            if (!selectedFolder.value.Name) {
                showAlert('', '폴더명을 입력 하세요!', 'PageClip', sysEnums.AlertTypes.Warning);
                return;
            }

            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncFolder', encFolder.value ? encFolder.value : selectedFolder.value.EncFolder);
            formData.append('Name', selectedFolder.value.Name);
            formData.append('Description', selectedFolder.value.Description);
            formData.append('Type', folderType.value ? sysEnums.PageCategorys.Folder : sysEnums.PageCategorys.Post);
            axios
                .post(apiEnums.CloudTicket.updateFolder, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserFolder();

                        widgetInfo.value.modal = false;
                        widgetInfo.value.titleModal = '';
                        widgetInfo.value.targetModal = null;
                    }
                })
                .catch(() => {});
        };

        return {
            sysEnums,
            encFolder,
            folderType,
            selectedFolder,
            folderList,
            createFolder,
            updateFolder,
        };
    },
    components: {},
};
</script>

<template>
    <BRow>
        <BCol col="6">
            <BCard no-body>
                <BCardBody :class="folderType ? 'bg-primary text-light' : 'hand'" @click="folderType = true"> <i class="ri-folder-3-line pe-2"></i> 폴더 </BCardBody>
            </BCard>
        </BCol>

        <BCol col="6">
            <BCard no-body>
                <BCardBody :class="!folderType ? 'bg-primary text-light' : 'hand'" @click="folderType = false"> <i class="ri-space pe-2"></i> 포스트 </BCardBody>
            </BCard>
        </BCol>
    </BRow>

    <BRow>
        <div class="card border-0">
            <div class="card-body text-center">
                <div class="profile-user position-relative d-inline-block mx-auto my-3">
                    <label class="w-100 mb-0 hand" for="uploadFiles">
                        <i class="fs-60 p-3 rounded-circle avatar-xl img-thumbnail user-profile-image" :class="folderType ? 'ri-folder-3-line' : 'ri-space'"></i>
                        <!-- <img v-avatar-img="userInfo.EncUser" class="rounded-circle avatar-xl img-thumbnail user-profile-image" /> -->
                    </label>

                    <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <label for="profile-img-file-input" class="profile-photo-edit avatar-xs">
                            <span class="avatar-title rounded-circle bg-light text-body">
                                <i class="ri-image-line"></i>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <!-- {{ JSON.stringify(selectedFolder) }} -->

        <BCol lg="12">
            <div v-if="!folderType" class="form-floating text-center mb-2">
                <select class="form-select" id="floatingSelect1" aria-label="POST선택" v-model="encFolder">
                    <option class="text-center" selected>Folder선택</option>
                    <option class="text-center" v-for="x in folderList.filter(x => x.Type === sysEnums.PageCategorys.Folder)" :key="x" :value="x.EncFolder">
                        {{ x.Name }}
                    </option>
                </select>
                <label for="floatingSelect1">Folder선택</label>
            </div>
            <div class="form-floating">
                <input type="text" class="form-control" id="floatingInput2" :placeholder="folderType ? '폴더명' : '포스트명'" v-model="selectedFolder.Name" />
                <label for="floatingInput2">{{ folderType ? '폴더' : '포스트' }} 명</label>
            </div>
        </BCol>
        <BCol lg="12 mt-2">
            <textarea class="form-control" rows="5" :placeholder="folderType ? '폴더설명' : '포스트설명'" v-model="selectedFolder.Description"></textarea>
        </BCol>
    </BRow>

    <div class="d-flex align-items-center mt-3">
        <BButton class="w-100 me-2" variant="warning" @click="updateFolder">{{ folderType ? '폴더' : '포스트' }}변경</BButton>
        <BButton class="w-100 ms-2" variant="primary" @click="createFolder">{{ folderType ? '폴더' : '포스트' }}만들기</BButton>
    </div>
</template>
