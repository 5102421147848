export const sysEnums = {
    UserKey: '__pageClipConsole__',
    UserServiceTypes: {},
    UserTypes: {},
    NotiTypes: {},
    AccountRoles: {},
    CompanyTypes: {},
    AlertTypes: {},
    ChartTypes: {},
    ClipTypes: {},
    ClipHandles: {},
    FileCategory: {},
    FileTypes: {},
    LogType: {},
    SignalTypes: {},
    DiscoutTypes: {},
    PageTags: {},
    RoleTypes: {},
    PageCategorys: {},
    PageOptions: {},
    Positions: {},
    SortTypes: {},
};

export const enumTypes = {
    UserServiceTypes: {},
    UserServiceText: {},
    UserServiceIcons: {},
    UserTypes: {},
    NotiTypes: {},
    AccountRoles: {},
    CompanyTypes: {},
    AlertTypes: {},
    ChartTypes: {},
    ClipTypes: {},
    ClipHandles: {},
    FileCategory: {},
    FileTypes: {},
    LogType: {},
    SignalTypes: {},
    PageTags: {},
    RoleTypes: {},
    PageCategorys: {},
    PageOptions: {},
    Positions: {},
    SortTypes: {},
};

sysEnums.UserServiceTypes.None = 0;
enumTypes.UserServiceTypes[0] = '페이지클립';
enumTypes.UserServiceText[0] = '연동된 SNS계정이 없습니다';
enumTypes.UserServiceIcons[0] = 'assets/images/pageclip/logo.svg"';

sysEnums.UserServiceTypes.KakaoTalk = 1;
enumTypes.UserServiceTypes[1] = 'Kakao';
enumTypes.UserServiceText[1] = '카카오톡에 연동 되었습니다.';
enumTypes.UserServiceIcons[1] = 'assets/images/pageclip/KakaoTalk.svg';

sysEnums.UserServiceTypes.Naver = 2;
enumTypes.UserServiceTypes[2] = 'NAVER';
enumTypes.UserServiceText[2] = '네이버에 연동 되었습니다.';
enumTypes.UserServiceIcons[2] = 'assets/images/pageclip/naver.svg';

sysEnums.UserServiceTypes.Google = 3;
enumTypes.UserServiceTypes[3] = 'Google';
enumTypes.UserServiceText[3] = '구글에 연동 되었습니다.';
enumTypes.UserServiceIcons[3] = 'assets/images/pageclip/google.svg';

sysEnums.AlertTypes.Primary = 11;
sysEnums.AlertTypes.Info = 12;
sysEnums.AlertTypes.Warning = 13;
sysEnums.AlertTypes.Error = 14;
sysEnums.AlertTypes.Success = 15;
sysEnums.AlertTypes.Question = 16;

enumTypes.AlertTypes[11] = 'primary';
enumTypes.AlertTypes[12] = 'info';
enumTypes.AlertTypes[13] = 'warning';
enumTypes.AlertTypes[14] = 'error';
enumTypes.AlertTypes[15] = 'success';
enumTypes.AlertTypes[16] = 'question';

sysEnums.UserTypes.None = 0;
enumTypes.UserTypes[0] = '비회원';

sysEnums.UserTypes.Default = 1;
enumTypes.UserTypes[1] = '직원';

sysEnums.UserTypes.Manager = 9;
enumTypes.UserTypes[9] = '매니저';

sysEnums.UserTypes.Super = 99;
enumTypes.UserTypes[99] = '관리자';

sysEnums.NotiTypes.None = 0;
enumTypes.NotiTypes[0] = '공지';

sysEnums.NotiTypes.Group = 1;
enumTypes.NotiTypes[1] = '그룹';

sysEnums.NotiTypes.Contract = 13;
enumTypes.NotiTypes[13] = '계약요청';

sysEnums.NotiTypes.ContractChked = 14;
enumTypes.NotiTypes[14] = '계약승인';

sysEnums.NotiTypes.Company = 23;
enumTypes.NotiTypes[23] = '시설등록';

sysEnums.NotiTypes.CompanyEdit = 24;
enumTypes.NotiTypes[24] = '시설변경';

sysEnums.NotiTypes.Chat = 33;
enumTypes.NotiTypes[33] = '채팅';

sysEnums.NotiTypes.User = 73;
enumTypes.NotiTypes[73] = '신규가입';

sysEnums.NotiTypes.UserEdit = 74;
enumTypes.NotiTypes[74] = '회원수정';

sysEnums.NotiTypes.PayDo = 93;
enumTypes.NotiTypes[93] = '결제';

sysEnums.NotiTypes.PayCancel = 94;
enumTypes.NotiTypes[94] = '결제취소';

enumTypes.FileTypes[0] = '0';
enumTypes.FileTypes[1] = '1';
enumTypes.FileTypes[2] = '2';
enumTypes.FileTypes[3] = '3';
enumTypes.FileTypes[4] = '4';
enumTypes.FileTypes[9] = '사업자등록증';
enumTypes.FileTypes[10] = 'Avatar';
enumTypes.FileTypes[11] = '계약서';
enumTypes.FileTypes[21] = '시설';
enumTypes.FileTypes[31] = 'Chat';
enumTypes.FileTypes[41] = '일반파일';
enumTypes.FileTypes[61] = '문서';
enumTypes.FileTypes[62] = '계약서';

sysEnums.FileCategory.Partner = 9;
sysEnums.FileCategory.Avatar = 10;
sysEnums.FileCategory.Contract = 11;
sysEnums.FileCategory.Company = 21;
sysEnums.FileCategory.Chat = 31;
sysEnums.FileCategory.File = 41;
sysEnums.FileCategory.Document = 61;

enumTypes.FileCategory[9] = '사업자등록증';
enumTypes.FileCategory[10] = 'Avatar';
enumTypes.FileCategory[11] = '계약서';
enumTypes.FileCategory[21] = '시설들';
enumTypes.FileCategory[31] = '채팅';
enumTypes.FileCategory[61] = '문서';
enumTypes.FileCategory[62] = '문서2';

sysEnums.PageCategorys.Default = 0;
sysEnums.PageCategorys.Folder = 3;
sysEnums.PageCategorys.Post = 5;
sysEnums.PageCategorys.Page = 7;
sysEnums.PageCategorys.Safety = 11;
sysEnums.PageCategorys.MyData = 21;
sysEnums.PageCategorys.DataBox = 31;
sysEnums.PageCategorys.Trash = 41;
sysEnums.PageCategorys.Decision = 51;
sysEnums.PageCategorys.Vacation = 61;

enumTypes.PageCategorys[0] = '일반';
enumTypes.PageCategorys[3] = '폴더';
enumTypes.PageCategorys[5] = '포스트';
enumTypes.PageCategorys[7] = '페이지';
enumTypes.PageCategorys[11] = '안전관리';
enumTypes.PageCategorys[21] = '내자료함';
enumTypes.PageCategorys[31] = '자료보관함';
enumTypes.PageCategorys[41] = '휴지통';
enumTypes.PageCategorys[51] = '의사결정';
enumTypes.PageCategorys[61] = '휴가신청서';

sysEnums.PageOptions.Default = 0;
sysEnums.PageOptions.Role3 = 3;
sysEnums.PageOptions.Role5 = 5;
sysEnums.PageOptions.Role7 = 7;
sysEnums.PageOptions.Role9 = 9;
sysEnums.PageOptions.Tag = 99;

enumTypes.PageOptions[0] = '일반';
enumTypes.PageOptions[3] = 'Role3';
enumTypes.PageOptions[5] = 'Role5';
enumTypes.PageOptions[7] = 'Role7';
enumTypes.PageOptions[9] = 'Role9';

sysEnums.SortTypes.Default = 0;
sysEnums.SortTypes.Created = 1;
sysEnums.SortTypes.Title = 2;
sysEnums.SortTypes.NoRead = 3;

enumTypes.SortTypes[0] = '최근수정순';
enumTypes.SortTypes[1] = '최근등록순';
enumTypes.SortTypes[2] = '가나다순';
enumTypes.SortTypes[3] = '읽지않은목록';

sysEnums.ClipTypes.Default = 0;
sysEnums.ClipTypes.Table = 3;
sysEnums.ClipTypes.Draw = 5;
sysEnums.ClipTypes.DrawReset = 55;
sysEnums.ClipTypes.Image = 13;
sysEnums.ClipTypes.Company = 21;
sysEnums.ClipTypes.Chat = 31;
sysEnums.ClipTypes.File = 41;
sysEnums.ClipTypes.Document = 61;

enumTypes.ClipTypes[0] = '기본';
enumTypes.ClipTypes[3] = '테이블';
enumTypes.ClipTypes[5] = '드로우';
enumTypes.ClipTypes[55] = '드로우초기화';
enumTypes.ClipTypes[13] = '이미지';
enumTypes.ClipTypes[21] = '클립21';
enumTypes.ClipTypes[31] = '클립31';
enumTypes.ClipTypes[41] = '클립41';
enumTypes.ClipTypes[61] = '클립61';

sysEnums.ClipHandles.Default = 0;
sysEnums.ClipHandles.Fix = 3;
sysEnums.ClipHandles.SortUp = 5;
sysEnums.ClipHandles.SortDown = 7;
sysEnums.ClipHandles.Status = 11;
sysEnums.ClipHandles.Hide = 12;
sysEnums.ClipHandles.Del = 13; // 삭제전용
sysEnums.ClipHandles.Company = 21;
sysEnums.ClipHandles.Chat = 31;
sysEnums.ClipHandles.File = 41;
sysEnums.ClipHandles.Document = 61;

sysEnums.FileTypes.Super0 = 0;
sysEnums.FileTypes.Super1 = 1;
sysEnums.FileTypes.Super2 = 2;
sysEnums.FileTypes.Folder = 3;
sysEnums.FileTypes.Super4 = 4;
sysEnums.FileTypes.Partner = 9;
sysEnums.FileTypes.Avatar = 10;
sysEnums.FileTypes.Page = 11;
sysEnums.FileTypes.Clip = 13;
sysEnums.FileTypes.Company = 21;
sysEnums.FileTypes.Chat = 31;
sysEnums.FileTypes.File = 41;
sysEnums.FileTypes.Document = 61;
sysEnums.FileTypes.Paper = 62;

enumTypes.FileTypes[3] = 'Folder';
enumTypes.FileTypes[9] = 'Partner';
enumTypes.FileTypes[10] = 'Avatar';
enumTypes.FileTypes[11] = 'Page';
enumTypes.FileTypes[13] = 'Clip';
enumTypes.FileTypes[21] = 'Company';
enumTypes.FileTypes[31] = 'Chat';
enumTypes.FileTypes[41] = 'File';
enumTypes.FileTypes[61] = 'Document';

sysEnums.ChartTypes.Sns = 9;
sysEnums.ChartTypes.User = 10;
sysEnums.ChartTypes.Contract = 11;
sysEnums.ChartTypes.Company = 21;
sysEnums.ChartTypes.Chat = 31;
sysEnums.ChartTypes.File = 41;
sysEnums.ChartTypes.Noti = 51;
sysEnums.ChartTypes.Paper = 62;
sysEnums.ChartTypes.PayStory = 91;
sysEnums.ChartTypes.Paydo = 93;
sysEnums.ChartTypes.Paysum = 94;
sysEnums.ChartTypes.Payment = 95;
sysEnums.ChartTypes.Settlement = 96;

sysEnums.CompanyTypes.Apartment = 0;
enumTypes.CompanyTypes[0] = { name: '건설', icon: 'assets/images/pageclip/apartment.svg' };

sysEnums.CompanyTypes.Officetel = 1;
enumTypes.CompanyTypes[1] = { name: '유통', icon: 'assets/images/pageclip/officetel.svg' };

sysEnums.CompanyTypes.House = 2;
enumTypes.CompanyTypes[2] = { name: '홍보', icon: 'assets/images/pageclip/house.svg' };

sysEnums.CompanyTypes.Office = 3;
enumTypes.CompanyTypes[3] = { name: '병원', icon: 'assets/images/pageclip/office.svg' };

sysEnums.CompanyTypes.Store = 4;
enumTypes.CompanyTypes[4] = { name: '운송', icon: 'assets/images/pageclip/store.svg' };

sysEnums.CompanyTypes.Factory = 5;
enumTypes.CompanyTypes[5] = { name: '서비스', icon: 'assets/images/pageclip/factory.svg' };

sysEnums.PageTags.Default = 0;
enumTypes.PageTags[0] = { encTag: '6084JRax9S7xQcR55G2ZZw==', name: '일반', icon: 'ri-error-warning-line', variant: 'primary' };

sysEnums.PageTags.Noti = 1;
enumTypes.PageTags[1] = { encTag: 'D9RhYu5Vp3UuFglDZUCyeA==', name: '공지쪽지', icon: 'ri-mail-send-line', variant: 'warning' };

sysEnums.PageTags.Decision = 2;
enumTypes.PageTags[2] = { encTag: 'h8Fp1IwONBMBAw/EP0iyKg==', name: '의사결정', icon: 'ri-quill-pen-line', variant: 'success' };

sysEnums.PageTags.Cooperation = 3;
enumTypes.PageTags[3] = { encTag: 'NGtCNVwrLJjvvLB6zgeTnQ==', name: '업무협조', icon: 'ri-task-line', variant: 'info' };

sysEnums.PageTags.Safety = 4;
enumTypes.PageTags[4] = { encTag: 'nuwjGj2sIS/D0+c6dQJ0Bw==', name: '안전관리', icon: 'ri-spam-line', variant: 'secondary' };
// enumTypes.PageTags[4] = { name: '안전관리', icon: 'ri-traffic-light-line' };

sysEnums.PageTags.Trash = 5;
enumTypes.PageTags[5] = { encTag: 'Kc7BtflzLjNUEzzERWOK5g==', name: '휴지통', icon: 'ri-delete-2-line', variant: 'danger' };

sysEnums.PageTags.Search = 7;
enumTypes.PageTags[7] = { encTag: 'Kc7BtflzLjNUEzzERWOK5g==', name: '검색', icon: 'ri-delete-2-line', variant: 'danger' };

sysEnums.PageTags.User = 9;
enumTypes.PageTags[9] = { encTag: 'Kc7BtflzLjNUEzzERWOK5g==', name: 'Users', icon: 'ri-delete-2-line', variant: 'danger' };

sysEnums.PageTags.Post = 11;
enumTypes.PageTags[11] = { encTag: 'Kc7BtflzLjNUEzzERWOK5g==', name: '휴지통', icon: 'ri-delete-2-line', variant: 'danger' };

sysEnums.PageTags.Page = 31;
enumTypes.PageTags[31] = { encTag: 'Kc7BtflzLjNUEzzERWOK5g==', name: '휴지통', icon: 'ri-delete-2-line', variant: 'danger' };

sysEnums.PageTags.File = 41;
enumTypes.PageTags[41] = { encTag: 'Kc7BtflzLjNUEzzERWOK5g==', name: 'Files', icon: 'ri-delete-2-line', variant: 'danger' };

sysEnums.PageTags.History = 61;
enumTypes.PageTags[61] = { encTag: 'Kc7BtflzLjNUEzzERWOK5g==', name: '휴지통', icon: 'ri-delete-2-line', variant: 'danger' };

sysEnums.RoleTypes.Default = 0;
enumTypes.RoleTypes[0] = { name: '일반', icon: 'ri-user-4-line', bg: 'bg-primary' };

sysEnums.RoleTypes.Viewer = 1;
enumTypes.RoleTypes[1] = { name: '뷰어', icon: 'ri-contacts-line', bg: 'bg-warning' };

sysEnums.RoleTypes.Director = 2;
enumTypes.RoleTypes[2] = { name: '편집자', icon: 'ri-quill-pen-line', bg: 'bg-success' };

sysEnums.RoleTypes.Manager = 3;
enumTypes.RoleTypes[3] = { name: '관리자', icon: 'ri-list-settings-line', bg: 'bg-info' };

export const apiEnums = {
    CloudTicket: {},
    BizTalk: {},
    PayStory: {},
};

const isDev = location.host.indexOf('www3') !== -1 || location.host.indexOf('app3') !== -1 || location.host.indexOf('localhost') !== -1 || process.env.NODE_ENV === 'development' ? true : false;

const baseDevURL = 'https://api-3.cloudticket.co.kr/';
const baseProductionURL = 'https://api3.cloudticket.co.kr/';

const baseAPIURL = isDev ? baseDevURL : baseProductionURL;

const baseDevURL3 = 'https://signal3.pageclip.ai/';
const baseProductionURL3 = 'https://signal.pageclip.ai/';

const baseSIGNALURL = isDev ? baseDevURL3 : baseProductionURL3;

// SignalR
apiEnums.CloudTicket['initSignalR'] = baseSIGNALURL + 'signalHub?encUser=';

// 알람
apiEnums.CloudTicket['getNotiConsole'] = baseAPIURL + 'Noti/getNotiConsole';

apiEnums.CloudTicket['getUserNoti'] = baseAPIURL + 'Noti/getUserNoti';

apiEnums.CloudTicket['chkUserNoti'] = baseAPIURL + 'Noti/chkUserNoti';

apiEnums.CloudTicket['sendNoti'] = baseAPIURL + 'Noti/sendNoti';

// 페이지
apiEnums.CloudTicket['getPageConsole'] = baseAPIURL + 'Page/getPageConsole';

apiEnums.CloudTicket['getUserPage'] = baseAPIURL + 'Page/getUserPage';

apiEnums.CloudTicket['chkUserPage'] = baseAPIURL + 'Page/chkUserPage';

apiEnums.CloudTicket['createPage'] = baseAPIURL + 'Page/createPage';

apiEnums.CloudTicket['createRole'] = baseAPIURL + 'Page/createRole';

apiEnums.CloudTicket['createPageTag'] = baseAPIURL + 'Page/createPageTag';

apiEnums.CloudTicket['createComment'] = baseAPIURL + 'Page/createComment';

apiEnums.CloudTicket['copyPage'] = baseAPIURL + 'Page/copyPage';

apiEnums.CloudTicket['updatePage'] = baseAPIURL + 'Page/updatePage';

apiEnums.CloudTicket['updateRole'] = baseAPIURL + 'Page/updateRole';

// 클립
apiEnums.CloudTicket['getUserClip'] = baseAPIURL + 'Clip/getUserClip';

apiEnums.CloudTicket['getClipConsole'] = baseAPIURL + 'Clip/getClipConsole';

apiEnums.CloudTicket['getClipDraw'] = baseAPIURL + 'Clip/getClipDraw';

apiEnums.CloudTicket['chkUserClip'] = baseAPIURL + 'Clip/chkUserClip';

apiEnums.CloudTicket['createClip'] = baseAPIURL + 'Clip/createClip';

apiEnums.CloudTicket['createImageClip'] = baseAPIURL + 'Clip/createImageClip';

apiEnums.CloudTicket['removeImageClip'] = baseAPIURL + 'Clip/removeImageClip';

apiEnums.CloudTicket['updateClip'] = baseAPIURL + 'Clip/updateClip';

// 계약
apiEnums.CloudTicket['getContractConsole'] = baseAPIURL + 'Contract/getContractConsole';

apiEnums.CloudTicket['getContract'] = baseAPIURL + 'Contract/getContract';

apiEnums.CloudTicket['getUserContract'] = baseAPIURL + 'Contract/getUserContract';

apiEnums.CloudTicket['createContractProc'] = baseAPIURL + 'Contract/createContractProc';

apiEnums.CloudTicket['updateContract'] = baseAPIURL + 'Contract/updateContract';

apiEnums.CloudTicket['updateEndDate'] = baseAPIURL + 'Contract/updateEndDate';

apiEnums.CloudTicket['setContractConfirm'] = baseAPIURL + 'Contract/setContractConfirm';

apiEnums.CloudTicket['setContractStatus'] = baseAPIURL + 'Contract/setContractStatus';

apiEnums.CloudTicket['updateContractUser'] = baseAPIURL + 'Contract/updateContractUser';

// 회사
apiEnums.CloudTicket['getCompanyConsole'] = baseAPIURL + 'Company/getCompanyConsole';

apiEnums.CloudTicket['getCompany'] = baseAPIURL + 'Company/getCompany';

apiEnums.CloudTicket['getCompanyNew'] = baseAPIURL + 'Company/getCompanyNew';

apiEnums.CloudTicket['createCompany'] = baseAPIURL + 'Company/createCompany';

apiEnums.CloudTicket['updateCompany'] = baseAPIURL + 'Company/updateCompany';

apiEnums.CloudTicket['setCompanyStatus'] = baseAPIURL + 'Company/setCompanyStatus';

apiEnums.CloudTicket['createDepartment'] = baseAPIURL + 'Company/createDepartment';

apiEnums.CloudTicket['updateDepartment'] = baseAPIURL + 'Company/updateDepartment';

// 파일
apiEnums.CloudTicket['getFileConsole'] = baseAPIURL + 'File/getFileConsole';

apiEnums.CloudTicket['getFile'] = baseAPIURL + 'File/getFile';

apiEnums.CloudTicket['getUserFile'] = baseAPIURL + 'File/getUserFile';

apiEnums.CloudTicket['fileUpload'] = baseAPIURL + 'File/fileUpload';

apiEnums.CloudTicket['removeFile'] = baseAPIURL + 'File/removeFile';

apiEnums.CloudTicket['removeChatFile'] = baseAPIURL + 'File/removeChatFile';

// 폴더
apiEnums.CloudTicket['getUserFolder'] = baseAPIURL + 'Folder/getUserFolder';

apiEnums.CloudTicket['createFolder'] = baseAPIURL + 'Folder/createFolder';

apiEnums.CloudTicket['updateFolder'] = baseAPIURL + 'Folder/updateFolder';

apiEnums.CloudTicket['createRoleFolder'] = baseAPIURL + 'Folder/createRoleFolder';

// apiEnums.CloudTicket['createRoleFolderGroup'] = baseAPIURL + 'Folder/createRoleFolderGroup';

// 사용자
apiEnums.CloudTicket['getUserConsole'] = baseAPIURL + 'User/getUserConsole';

apiEnums.CloudTicket['getUserInfo'] = baseAPIURL + 'User/getUserInfo';

apiEnums.CloudTicket['getPartnerMidData'] = baseAPIURL + 'User/getPartnerMidData';

apiEnums.CloudTicket['cudUserData'] = baseAPIURL + 'User/cudUserData';

apiEnums.CloudTicket['setUserTypeConsole'] = baseAPIURL + 'User/setUserTypeConsole';

apiEnums.CloudTicket['setUserStatus'] = baseAPIURL + 'User/setUserStatus';

apiEnums.CloudTicket['createMid'] = baseAPIURL + 'User/createMid';

apiEnums.CloudTicket['updateMid'] = baseAPIURL + 'User/updateMid';

apiEnums.CloudTicket['createBankAccount'] = baseAPIURL + 'User/createBankAccount';

apiEnums.CloudTicket['createBankAccountConsole'] = baseAPIURL + 'User/createBankAccountConsole';

apiEnums.CloudTicket['checkExistUser'] = baseAPIURL + 'User/checkExistUser';

apiEnums.CloudTicket['createUser'] = baseAPIURL + 'User/createUser';

apiEnums.CloudTicket['createUserConsole'] = baseAPIURL + 'User/createUserConsole';

apiEnums.CloudTicket['setSnsAuth'] = baseAPIURL + 'User/setSnsAuth';

apiEnums.CloudTicket['setUid'] = baseAPIURL + 'User/setUid';

apiEnums.CloudTicket['setUserType'] = baseAPIURL + 'User/setUserType';

apiEnums.CloudTicket['setCardPolicy'] = baseAPIURL + 'User/setCardPolicy';

apiEnums.CloudTicket['updateUserData'] = baseAPIURL + 'User/updateUserData';

apiEnums.CloudTicket['updateUser'] = baseAPIURL + 'User/updateUser';

// FCM
apiEnums.CloudTicket['sendFcm'] = baseAPIURL + 'Common/sendFcm';

apiEnums.CloudTicket['getMsg'] = baseAPIURL + 'Common/getMsg';

apiEnums.CloudTicket['getChatGroup'] = baseAPIURL + 'Common/getChatGroup';

apiEnums.CloudTicket['getJsonData'] = baseAPIURL + 'Common/getJsonData';

apiEnums.CloudTicket['getSchedule'] = baseAPIURL + 'Common/getSchedule';

apiEnums.CloudTicket['setSchedule'] = baseAPIURL + 'Common/setSchedule';

apiEnums.CloudTicket['setHoliday'] = baseAPIURL + 'Common/setHoliday';

apiEnums.CloudTicket['getJwtToken'] = baseAPIURL + 'Common/getJwtToken';

apiEnums.CloudTicket['getAlarmList'] = baseAPIURL + 'Common/getAlarmList';

apiEnums.CloudTicket['crudAlarm'] = baseAPIURL + 'Common/crudAlarm';

// 문자발송
apiEnums.BizTalk['sendMms'] = baseAPIURL + 'Biztalk/sendMms';

apiEnums.BizTalk['sendLms'] = baseAPIURL + 'Biztalk/sendLms';

apiEnums.BizTalk['sendAlimTalk'] = baseAPIURL + 'Biztalk/sendAlimTalk';

apiEnums.BizTalk['getResultAll'] = baseAPIURL + 'Biztalk/getResultAll';

apiEnums.BizTalk['getCofirmNumber'] = baseAPIURL + 'Biztalk/getCofirmNumber';

// 결제
apiEnums.PayStory['getMid'] = baseAPIURL + 'PayStory/getMid';

apiEnums.PayStory['getBid'] = baseAPIURL + 'PayStory/getBid';

apiEnums.PayStory['getBill'] = baseAPIURL + 'PayStory/getBill';

apiEnums.PayStory['getPayDo'] = baseAPIURL + 'PayStory/getPayDo';

apiEnums.PayStory['getPayCancel'] = baseAPIURL + 'PayStory/getPayCancel';

apiEnums.PayStory['registBill'] = baseAPIURL + 'PayStory/registBill';

apiEnums.PayStory['deleteBill'] = baseAPIURL + 'PayStory/deleteBill';

apiEnums.PayStory['doBill'] = baseAPIURL + 'PayStory/doBill';

apiEnums.PayStory['cancelBill'] = baseAPIURL + 'PayStory/cancelBill';

apiEnums.PayStory['cancelBillWithoutPayNum'] = baseAPIURL + 'PayStory/cancelBillWithoutPayNum';

apiEnums.PayStory['inquiryBill'] = baseAPIURL + 'PayStory/inquiryBill';

export const payEnums = {
    cardCodes: {},
    bankCodes: {},
};

payEnums.cardCodes['01'] = '비씨';
payEnums.cardCodes['02'] = '국민';
payEnums.cardCodes['03'] = '하나';
payEnums.cardCodes['04'] = '삼성';
payEnums.cardCodes['06'] = '신한';
payEnums.cardCodes['07'] = '현대';
payEnums.cardCodes['08'] = '롯데';

payEnums.cardCodes['11'] = '시티';
payEnums.cardCodes['12'] = 'NH농협';
payEnums.cardCodes['13'] = '수협';
payEnums.cardCodes['15'] = '우리';

payEnums.cardCodes['21'] = '광주';
payEnums.cardCodes['22'] = '전북';
payEnums.cardCodes['23'] = '제주';
payEnums.cardCodes['25'] = '해외비자';
payEnums.cardCodes['26'] = '해오마스터';
payEnums.cardCodes['27'] = '해외다이너스';
payEnums.cardCodes['28'] = '해외AMAX';
payEnums.cardCodes['29'] = '해외JCB';

payEnums.cardCodes['30'] = '해외';
payEnums.cardCodes['32'] = '우체국';
payEnums.cardCodes['33'] = 'MG새마을체크';
payEnums.cardCodes['34'] = '중국은행체크';
payEnums.cardCodes['38'] = '은련';

payEnums.cardCodes['41'] = '신협';
payEnums.cardCodes['42'] = '저축은행';
payEnums.cardCodes['43'] = 'KDB산업';
payEnums.cardCodes['44'] = '카카오뱅크';
payEnums.cardCodes['45'] = '케이뱅크';
payEnums.cardCodes['46'] = '카카오머니';
payEnums.cardCodes['47'] = 'SSG머니';

payEnums.bankCodes['01'] = '비씨';
payEnums.bankCodes['02'] = '국민';
payEnums.bankCodes['03'] = '하나';
payEnums.bankCodes['04'] = '삼성';
payEnums.bankCodes['06'] = '신한';
payEnums.bankCodes['07'] = '현대';
payEnums.bankCodes['08'] = '롯데';

payEnums.bankCodes['11'] = '시티';
payEnums.bankCodes['12'] = 'NH농협';
payEnums.bankCodes['13'] = '수협';
payEnums.bankCodes['15'] = '우리';

payEnums.bankCodes['21'] = '광주';
payEnums.bankCodes['22'] = '전북';
payEnums.bankCodes['23'] = '제주';
payEnums.bankCodes['25'] = '해외비자';
payEnums.bankCodes['26'] = '해오마스터';
payEnums.bankCodes['27'] = '해외다이너스';
payEnums.bankCodes['28'] = '해외AMAX';
payEnums.bankCodes['29'] = '해외JCB';

payEnums.bankCodes['30'] = '해외';
payEnums.bankCodes['32'] = '우체국';
payEnums.bankCodes['33'] = 'MG새마을체크';
payEnums.bankCodes['34'] = '중국은행체크';
payEnums.bankCodes['38'] = '은련';

payEnums.bankCodes['41'] = '신협';
payEnums.bankCodes['42'] = '저축은행';
payEnums.bankCodes['43'] = 'KDB산업';
payEnums.bankCodes['44'] = '카카오뱅크';
payEnums.bankCodes['45'] = '케이뱅크';
payEnums.bankCodes['46'] = '카카오머니';
payEnums.bankCodes['47'] = 'SSG머니';

const sortOrder = {
    '01': 1,
    '02': 2,
    '03': 3,
    '04': 5,
    '06': 5,
    '07': 6,
    '08': 7,

    11: 8,
    12: 9,
    13: 10,
    15: 11,

    21: 12,
    22: 13,
    23: 14,
    25: 15,
    26: 16,
    27: 17,
    28: 18,
    29: 19,

    30: 20,
    32: 21,
    33: 22,
    34: 23,
    38: 24,

    41: 25,
    42: 26,
    43: 27,
    44: 28,
    45: 29,
    46: 30,
    47: 31,
};

// Convert cardCodes object to an array of key-value pairs
const cardCodesArray = Object.entries(payEnums.cardCodes);

// Sort the array based on the sortOrder values
cardCodesArray.sort((a, b) => sortOrder[a[0]] - sortOrder[b[0]]);

// Convert the sorted array back to an object
payEnums.cardCodes = Object.fromEntries(cardCodesArray);

sysEnums.cardFee = 2;
sysEnums.zero = '6084JRax9S7xQcR55G2ZZw==';
enumTypes.zero = '6084JRax9S7xQcR55G2ZZw==';

sysEnums.hm0 = 'v/hTmMsrf6ZxWN65PjGEOQ=='; // 7100119
sysEnums.hm1 = 'GdLcvLIAPDKI9WoLliInhw=='; // 7100120
sysEnums.hm2 = 'GG16ZyfTLkDWqi2xM8v2Rw=='; // 7100121
sysEnums.hm3 = 'lFGnLKCcTq4Ego1aMImNlA=='; // 7100122
sysEnums.hm9 = '0iwT7cEycMCZdinWkrYYww=='; // 7100123

// sysEnums.hm0 = { "EncUser": "v/hTmMsrf6ZxWN65PjGEOQ==", "UserName": "하우스메타", "LoginId": "0212345678", "AvatarPath": null }

// sysEnums.firebaseVapidKey = 'BL8Lz4wra4GKwT8VPLUMi58dsJdrDVBUHWgVWMz8Yf76rq1dkwkW6BlBIej-08UPeSbml7zo1_EzU45eaWWDy3A';

sysEnums.AccountRoles.Master = 2147483647;
enumTypes.AccountRoles['2147483647'] = '시스템관리자';
sysEnums.AccountRoles.Partner = 1048576;
enumTypes.AccountRoles['1048576'] = '파트너';
sysEnums.AccountRoles.Lessee = 1024;
enumTypes.AccountRoles['2048'] = '임대인';
sysEnums.AccountRoles.Lessor = 1024;
enumTypes.AccountRoles['1024'] = '임차인';
sysEnums.AccountRoles.User = 0;
enumTypes.AccountRoles['0'] = '비회원';

sysEnums.SignalTypes.Folder = 3;
sysEnums.SignalTypes.Post = 5;
sysEnums.SignalTypes.Page = 7;
sysEnums.SignalTypes.Clip = 8;
sysEnums.SignalTypes.Invite = 9;
sysEnums.SignalTypes.Avatar = 10;
sysEnums.SignalTypes.Contract = 11;
sysEnums.SignalTypes.ContractCreated = 13;
sysEnums.SignalTypes.ContractConfirm = 14;
sysEnums.SignalTypes.Company = 21;
sysEnums.SignalTypes.CompanyCreated = 23;
sysEnums.SignalTypes.CompanyUpdated = 24;
sysEnums.SignalTypes.Chat = 31;
sysEnums.SignalTypes.ChatCreated = 32;
sysEnums.SignalTypes.File = 41;
sysEnums.SignalTypes.User = 71;
sysEnums.SignalTypes.UserCreated = 73;
sysEnums.SignalTypes.UserUpdated = 74;
sysEnums.SignalTypes.Pay = 91;
sysEnums.SignalTypes.PayCreated = 93;
sysEnums.SignalTypes.PayUpdated = 94;
sysEnums.SignalTypes.Noti = 51;
sysEnums.SignalTypes.Document = 61;

enumTypes.SignalTypes[3] = 'Folder';
enumTypes.SignalTypes[5] = 'Post';
enumTypes.SignalTypes[7] = 'Page';
enumTypes.SignalTypes[8] = 'Clip';
enumTypes.SignalTypes[9] = 'Invite';
enumTypes.SignalTypes[10] = 'Avatar';
enumTypes.SignalTypes[11] = 'Contract';
enumTypes.SignalTypes[13] = 'ContractCreated';
enumTypes.SignalTypes[14] = 'ContractConfirm';
enumTypes.SignalTypes[21] = 'Company';
enumTypes.SignalTypes[23] = 'CompanyCreated';
enumTypes.SignalTypes[24] = 'CompanyUpdated';
enumTypes.SignalTypes[31] = 'Chat';
enumTypes.SignalTypes[32] = 'ChatCreated';
enumTypes.SignalTypes[41] = 'File';
enumTypes.SignalTypes[71] = 'User';
enumTypes.SignalTypes[73] = 'UserCreated';
enumTypes.SignalTypes[74] = 'UserUpdated';
enumTypes.SignalTypes[91] = 'Pay';
enumTypes.SignalTypes[93] = 'PayCreated';
enumTypes.SignalTypes[94] = 'PayUpdated';
enumTypes.SignalTypes[51] = 'Noti';
enumTypes.SignalTypes[61] = 'Document';

sysEnums.LogType.App__Join = 1100;
sysEnums.LogType.App__Login = 1200;
sysEnums.LogType.App__Pay = 1300;
sysEnums.LogType.App__Pay__Fail = 1302;
sysEnums.LogType.App__User = 1700;
sysEnums.LogType.App__Mid__CREATE = 1701;
sysEnums.LogType.App__Mid__UPDATE = 1701;
sysEnums.LogType.App__Uid__CREATE = 1703;
sysEnums.LogType.App__Uid__UPDATE = 1711;
sysEnums.LogType.Worker__Alarm = 9100;
sysEnums.LogType.Worker__Alarm__Fail = 9102;
sysEnums.LogType.Worker__Pay = 9302;
sysEnums.LogType.Worker__Pay__Fail = 9302;

enumTypes.LogType['1100'] = '앱 가입';
enumTypes.LogType['1200'] = '앱 로그인';
enumTypes.LogType['1300'] = '앱 결제';
enumTypes.LogType['1302'] = '앱 결제 실패';
enumTypes.LogType['1700'] = '앱 멤버';
enumTypes.LogType['1701'] = '앱 입금키 생성';
enumTypes.LogType['1701'] = '앱 입금키 생성';
enumTypes.LogType['1703'] = '앱 입금키 수정';
enumTypes.LogType['1711'] = '앱 결제키 생성';
enumTypes.LogType['9100'] = '데몬 알람 생성';
enumTypes.LogType['9102'] = '데몬 알람 실패';
enumTypes.LogType['9302'] = '데몬 결제 성공';
enumTypes.LogType['9302'] = '데몬 결제 실패';

sysEnums.DiscoutTypes.Tax = 1.1;
sysEnums.DiscoutTypes.Rate11 = 1.1;
sysEnums.DiscoutTypes.Rate01 = 0.01;
sysEnums.DiscoutTypes.Rate02 = 0.02;
sysEnums.DiscoutTypes.Rate03 = 0.03;
sysEnums.DiscoutTypes.Rate04 = 0.04;
sysEnums.DiscoutTypes.Rate05 = 0.05;
sysEnums.DiscoutTypes.Rate06 = 0.06;
sysEnums.DiscoutTypes.Rate07 = 0.07;
sysEnums.DiscoutTypes.Rate08 = 0.08;
sysEnums.DiscoutTypes.Rate09 = 0.09;

sysEnums.AlertTypes.Ver = 2024071902;
