<script>
import { inject } from 'vue';

import router from '@/router';
import { layoutComputed } from '@/state/helpers';
import simplebar from 'simplebar-vue';

import Footer from '@/components/footer';
import Menu from '@/components/menu.vue';
import NavBar from '@/components/nav-bar';
import RightBar from '@/components/right-bar';
localStorage.setItem('hoverd', false);

/**
 * Vertical layout
 */
export default {
    components: { NavBar, RightBar, Footer, Menu, simplebar },
    setup() {
        const router = inject('$router');
        const widgetInfo = inject('$widgetInfo');

        const { selectedFolder, selectedPage, getUserPage } = inject('$provideList');

        const routeTo = target => {
            getUserPage();
            widgetInfo.value.tag = 0;
            widgetInfo.value.title = '';
            selectedFolder.value = [];
            selectedPage.value = [];
            widgetInfo.value.activeTab[0] = 2;
            router.push(target);
        };

        return {
            widgetInfo,
            routeTo,
        };
    },
    data() {
        return {
            isMenuCondensed: false,
        };
    },
    computed: {
        ...layoutComputed,
    },
    created: () => {
        document.body.removeAttribute('data-layout', 'horizontal');
        document.body.removeAttribute('data-topbar', 'dark');
        document.body.removeAttribute('data-layout-size', 'boxed');
        // this.updateSidebarSize();
    },
    methods: {
        updateSidebarSize() {
            let sidebarSize = '';
            // Check window.screen.width and update the data-sidebar-size attribute
            if (window.innerWidth < 1025) {
                this.sidebarSize = 'sm';
                sidebarSize = 'sm';
            } else {
                this.sidebarSize = 'lg'; // Reset sidebarSize if screen width is >= 1025
                sidebarSize = 'lg';
            }
            // Update the data-sidebar-size attribute of document.documentElement
            document.documentElement.setAttribute('data-sidebar-size', sidebarSize);
        },

        initActiveMenu() {
            if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
                localStorage.setItem('hoverd', true);
                document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
            } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
                localStorage.setItem('hoverd', false);
                document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
            } else {
                document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
            }
        },
        toggleMenu() {
            document.body.classList.toggle('sidebar-enable');
            if (window.screen.width >= 992) {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove('sidebar-enable');
                    document.body.classList.remove('vertical-collpsed');
                });
                document.body.classList.toggle('vertical-collpsed');
            } else {
                // eslint-disable-next-line no-unused-vars
                router.afterEach((routeTo, routeFrom) => {
                    document.body.classList.remove('sidebar-enable');
                });
                document.body.classList.remove('vertical-collpsed');
            }
            this.isMenuCondensed = !this.isMenuCondensed;
        },
        toggleRightSidebar() {
            document.body.classList.toggle('right-bar-enabled');
        },
        hideRightSidebar() {
            document.body.classList.remove('right-bar-enabled');
        },
    },
    mounted() {
        if (localStorage.getItem('hoverd') == 'true') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
        }

        document.getElementById('overlay').addEventListener('click', () => {
            document.body.classList.remove('vertical-sidebar-enable');
        });
        if (window.screen.width < 1025) {
            document.documentElement.setAttribute('data-sidebar-size', 'sm');
        }

        window.addEventListener('resize', () => {
            document.body.classList.remove('vertical-sidebar-enable');
            document.querySelector('.hamburger-icon').classList.add('open');
            this.updateSidebarSize();
        });
    },
    unmounted() {
        window.removeEventListener('resize', this.updateSidebarSize);
    },
};
</script>

<!-- 좌측 메 상단 로고 부분  -->
<template>
    <div id="layout-wrapper">
        <span v-if="$route.path != '/applogin'">
            <NavBar />
        </span>

        <div>
            <!-- ========== Left Sidebar Start ========== -->
            <!-- ========== App Menu ========== -->
            <div class="app-menu navbar-menu">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <!-- Dark Logo-->
                    <span @click="routeTo('/')" class="logo logo-dark hand">
                        <span class="logo-sm">
                            <img src="assets/images/pageclip/symbol_100.png" alt="" height="36" />
                        </span>
                        <span class="logo-lg img-contain">
                            <img src="assets/images/pageclip/pageclip_400.png" alt="" height="48" />
                        </span>
                    </span>
                    <!-- Light Logo-->
                    <span @click="routeTo('/')" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/pageclip/symbol_100.png" alt="" height="48" />
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/pageclip/pageclip_400.png" alt="" height="36" />
                        </span>
                    </span>
                    <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover" @click="initActiveMenu">
                        <i class="ri-record-circle-line"></i>
                    </button>
                </div>

                <simplebar id="scrollbar" class="h-100 pt-2" ref="scrollbar">
                    <Menu></Menu>
                </simplebar>
                <div class="sidebar-background"></div>
            </div>
            <!-- Left Sidebar End -->
            <!-- Vertical Overlay-->
            <div class="vertical-overlay" id="overlay"></div>
        </div>
        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="main-content">
            <div class="page-content">
                <!-- Start Content-->
                <BContainer fluid>
                    <slot />
                </BContainer>
            </div>
            <span v-if="!widgetInfo.isApp">
                <Footer />
            </span>
        </div>
        <RightBar />
    </div>
</template>
