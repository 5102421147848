<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <BRow>
            <BCol lg="12">
                <BCard no-body>
                    <BCardBody>

                        <div v-show="bizAll.response">No Data...</div>

                        <table class="table table-striped table-hover text-center">
                            <thead>
                                <tr class="h-50h bg-light align-middle fw-bold">
                                    <th scope="col">No.</th>
                                    <th v-for="x in titles" :key="x">{{ x }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="h-50h align-middle hand" v-for="(x, i) in bizAll.response" :key="x">
                                    <th class="w-10" scope="row">{{ i + 1 }}</th>
                                    <td class="w-15 text-start" v-text="x.resultCode"></td>
                                    <td class="text-center" v-text="x.msgIdx"></td>
                                    <td class="w-15" v-text="x.bsid"></td>
                                    <td class="w-15" v-if="!$store.state.isMobile" v-text="x.sendType"></td>
                                    <td class="w-15" v-text="$moment(x.requestAt).format('YYYY-MM-DD hh:mm:ss')"></td>
                                    <td class="w-15" v-text="$moment(x.responseAt).format('YYYY-MM-DD hh:mm:ss')"></td>
                                </tr>
                            </tbody>
                        </table>

                    </BCardBody>
                </BCard>
            </BCol>
        </BRow>

        <div>
            <button class="btn btn-primary h-50h w-100 rounded" @click="getBizResultAll">조회하기</button>
        </div>

        <div class="text-end">
            발송 메시지 타입 구분 (K : 카카오, N : 네이버, S : SMS, M : LMS/MMS, R : RCS, E : 문자를 발송 하였으나 결과를 수신하지 못함)
        </div>

    </Layout>
</template>

<script lang="js">
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { inject, ref } from 'vue';
import { apiEnums, enumTypes } from "@/common/sysEnums";

export default {
    components: {
        Layout,
        PageHeader,
    },
    setup(props) {
        const titles = ref(['responseCode', 'mgxIdx', 'bsid', 'sendType', 'requestAt', 'responseAt']);

        const bizAll = ref({});

        const axios = inject("$axios");

        const getBizResultAll = () => {
            axios.post(apiEnums.BizTalk.getResultAll)
                .then((response) => {
                    bizAll.value = response.data;
                })
                .catch(() => { });
        }

        return {
            title: "BizTalk",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "getBizResultAll",
                    active: true,
                },
            ],
            props, enumTypes,
            titles, bizAll,
            getBizResultAll
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable="true"] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
