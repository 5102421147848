<script>
import { ref, computed, inject } from 'vue'
import { sysEnums, apiEnums } from "@/common/sysEnums";

export default {
    props: {
        model: Object,
    },
    setup(props) {
        const isOpen = ref(false);

        const router = inject("$router");
        const axios = inject("$axios");

        const userInfo = inject('$userInfo');
        const widgetInfo = inject("$widgetInfo");

        const { getUserFolder, getUserPage, selectedFolder, selectedPage, filteredPage } = inject("$provideList");

        const isFolder = computed(() => {
            return props.model.children && props.model.children.length
        })

        const toggle = () => {
            isOpen.value = !isOpen.value
        }

        const changeType = () => {
            if (!isFolder.value) {
                // props.model.children = []
                isOpen.value = true

                console.log('props.model : ' + JSON.stringify(props.model))
            }
        }

        const navigateTo = (target, data) => {
            // isOpen.value = !isOpen.value
            filteredPage.value = [];
            widgetInfo.value.tag = sysEnums.PageTags.Default;
            selectedFolder.value = data;
            widgetInfo.value.title = data.Name;
            router.push(target);
        }

        const dragStart = (data, event) => {
            event.dataTransfer.setData('text/plain', JSON.stringify(data));
        };

        const handleDropFolderMove = (data, event) => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            const role = droppedData.PageRoles.find(x => x.EncPage === droppedData.EncPage && x.EncUser === droppedData.EncUser);

            droppedData.EncPage ? createRole(data.EncFolder, role ? role.EncRole : sysEnums.zero, droppedData.EncPage) : updateFolder(droppedData.EncFolder, data.EncFolder);
            // console.log(encFolder + ' / ' + droppedData.EncFolder + ' / ' + JSON.stringify(droppedData));
        }

        const updateFolder = (encFolder, encTopFolder) => {
            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncFolder', encFolder);
            formData.append('EncTopFolder', encTopFolder);
            axios.post(apiEnums.CloudTicket.updateFolder, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        // console.log('handleKeyDown : ' + response.data)
                    }
                })
                .catch(() => { });
        }

        // 페이지를 작업공간 메뉴에 드롭
        const createRole = (encFolder, encRole, encPage) => {
            const formData = new FormData();
            formData.append('EncFolder', encFolder);
            formData.append('EncPage', encPage);
            formData.append('EncUser', userInfo.value.EncUser);
            if (encRole) {
                formData.append('EncRole', encRole);
            }
            axios.post(apiEnums.CloudTicket.createRole, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        getUserPage();
                    }
                })
                .catch(() => { });
        };

        return {
            props, sysEnums, widgetInfo,
            isOpen, isFolder,
            selectedFolder, selectedPage,
            toggle, changeType, navigateTo,
            dragStart, handleDropFolderMove
        }
    }
}
</script>

<template>

    <li class="nav-item no_dot text-muted">

        <div v-if="model.Name !== 'WORKSPACE'" class="d-flex flex-items-center pe-5" :class="{ bold: isFolder }" @click="navigateTo('/page', model)" @dragover.prevent
            @drop="handleDropFolderMove(model, $event)" @dblclick="changeType">

            <span class="flex-grow-1">
                <span v-if="isFolder" style="cursor:move" draggable="true" @dragstart="dragStart(model, $event)">
                    <!-- <i class="ri-folder-5-line"></i> <span class="fw-bold ps-2" v-text="model.Name"></span> -->
                    <i :class="model.Type === sysEnums.PageCategorys.Folder ? 'ri-folder-5-line' : 'ri-space'"></i> <span class="fw-bold pe-2" v-text="model.Name"></span>
                    <BBadge v-if="model.FolderRoles.length > 0" variant="dark">{{ model.FolderRoles.length }}</BBadge>
                </span>
                <span v-else class="" style="cursor:move" draggable="true" @dragstart="dragStart(model, $event)">
                    <i :class="model.Type === sysEnums.PageCategorys.Folder ? 'ri-folder-5-line' : 'ri-space'"></i> <span v-text="model.Name"></span>
                    <BBadge v-if="model.FolderRoles.length > 0" class="ms-2" variant="primary">{{ model.FolderRoles.length }}</BBadge>
                </span>
            </span>

            <span class="flex-grow-0 text-muted">{{ $moment(model.Created).format('YYYY-MM-DD hh:mm') }}</span>

        </div>
        <div class="text-muted pb-2 mb-4 border-bottom123">{{ model.Description }}</div>
        <ul v-if="isFolder" class="navbar-nav ps-4 mb-4">
            <TreeWorkspace v-for="x in model.children" :key="x" :model="x"></TreeWorkspace>
        </ul>

    </li>

</template>

<style scoped lang="scss">
.no_dot {
    list-style-type: none !important;
    list-style: none !important;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-left: 0;
}

ul li {
    padding-left: 0;
    margin-left: 20px;
    line-height: 1.9;
}

ul:nth-child(1) {
    margin-left: -20px;
}
</style>