<script>
import { getCommentCount } from '@/common/comFuncs';
import { enumTypes, sysEnums } from '@/common/sysEnums';
import { reactive, inject } from 'vue';

//import PageTop from '@/components/pages/PageTop';
import AppIndexFolder from '@/components/apps/AppIndexFolder';
import AppIndexPage from '@/components/apps/AppIndexPage';

import NotiList from '@/components/notification/NotiList';

import LottieAni from '@/components/widgets/lottie.vue';
import aniData1 from '@/components/widgets/forest.json';

export default {
    components: {
        NotiList,
        AppIndexFolder,
        AppIndexPage,
        LottieAni,
    },
    setup() {
        const widgetInfo = inject('$widgetInfo');

        const { folderList, selectedFolder } = inject('$provideList');

        const aniOption1 = reactive({ animationData: aniData1 });

        return {
            sysEnums,
            enumTypes,
            widgetInfo,
            aniOption1,
            selectedFolder,
            folderList,
            getCommentCount,
        };
    },
};
</script>

<template>
    <slot v-if="widgetInfo.tag === sysEnums.PageTags.Noti">
        <NotiList></NotiList>
    </slot>

    <span v-for="(x, n) of folderList" :key="x">
        <BCard no-body>
            <BCardBody>
                <AppIndexFolder :Data="x" :Index="n"></AppIndexFolder>
            </BCardBody>
        </BCard>
    </span>

    <span v-if="folderList.length === 0">
        <div class="mt-5 text-center">
            <h4 class="pt-5">등록된 폴더가 없습니다</h4>
            <div class="fs-10">There are no registed folders</div>
            <p>
                <LottieAni colors="primary:#25a0e2,secondary:#00bd9d" trigger="loop" :options="aniOption1" :height="240" :width="240"></LottieAni>
            </p>
        </div>
    </span>

    <AppIndexPage></AppIndexPage>
</template>

<style></style>
