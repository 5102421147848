// Apps > Crypto

// Crypto > Transactions
const transactions = [{
    tr_id: 1,
    icon: "ri-arrow-right-up-fill",
    date: "24 Dec, 2021",
    time: "08:58AM",
    image: require("@/assets/images/svg/crypto-icons/btc.svg"),
    currency: "BTC",
    from: "Wallet",
    to: "Thomas Taylor",
    details: "Membership Fees",
    id: "16b1d9234b61e8778d9e3588f20",
    type: "Withdraw",
    amount: "-142.35 BTC",
    amount1: "$697.88k",
    status: "Processing",
},
{
    tr_idid: 2,
    icon: "ri-arrow-left-down-fill",
    date: "16 Dec, 2021",
    time: "10:58AM",
    image: require("@/assets/images/svg/crypto-icons/eth.svg"),
    currency: "ETH",
    from: "Tonya Noble",
    to: "Wallet",
    details: "Spring Telephone Network",
    id: "0a4b5e0e15d70ce79809eabbe",
    type: "Deposit",
    amount: "+342.35 ETH",
    amount1: "$14565.35",
    status: "Success",
},
{
    tr_id: 3,
    icon: "ri-arrow-left-down-fill",
    date: "04 Jan, 2021",
    time: "10:58AM",
    image: require("@/assets/images/svg/crypto-icons/eur.svg"),
    currency: "EUR",
    from: "Nancy Martino",
    to: "Wallet",
    details: "Funding Purse with Payment Check",
    id: "cca3da2b7711985361825f615e9",
    type: "Deposit",
    amount: "+174.23 EUR",
    amount1: "$354.14",
    status: "Failed",
},
{
    tr_id: 4,
    icon: "ri-arrow-right-up-fill",
    date: "28 Oct, 2021",
    time: "11:42AM",
    image: require("@/assets/images/svg/crypto-icons/gbp.svg"),
    currency: "GBP",
    from: "Wallet",
    to: "Michael Morris",
    details: "British Pound Sterling Block",
    id: "062e0e0123f2b1e9862f659c28",
    type: "Withdraw",
    amount: "-365.00 GBP",
    amount1: "$7532.21",
    status: "Success",
},
{
    tr_id: 5,
    icon: "ri-arrow-left-down-fill",
    date: "14 Nov, 2021",
    time: "12:38PM",
    image: require("@/assets/images/svg/crypto-icons/jpy.svg"),
    currency: "JPY",
    from: "Alexis Clarke",
    to: "Wallet",
    details: "Platinum Business",
    id: "1deffa9713917ee0af26bbb5f272",
    type: "Deposit",
    amount: "+341.74 JPY",
    amount1: "$748.10",
    status: "Processing",
},
{
    tr_id: 6,
    icon: "ri-arrow-right-up-fill",
    date: "02 Jan, 2021",
    time: "08:58AM",
    image: require("@/assets/images/svg/crypto-icons/xrp.svg"),
    currency: "XRP",
    from: "Wallet",
    to: "Kevin Dawson",
    details: "Business Advantage Fundaments - Banking",
    id: "186aa96d8014061d994f025ac4",
    type: "Withdraw",
    amount: "-240.74 XRP",
    amount1: "$3254.20",
    status: "Failed",
},
{
    tr_id: 7,
    icon: "ri-arrow-left-down-fill",
    date: "17 Oct, 2021",
    time: "07:08PM",
    image: require("@/assets/images/svg/crypto-icons/ltc.svg"),
    currency: "LTC",
    from: "Tonya Noble",
    to: "Wallet",
    details: "Litecoin Sale",
    id: "c94b5581418c41c2c74448a5ec",
    type: "Deposit",
    amount: "+298.72 LTC",
    amount1: "$149.32",
    status: "Success",
},
{
    tr_id: 8,
    icon: "ri-arrow-right-up-fill",
    date: "27 Dec, 2021",
    time: "01:24PM",
    image: require("@/assets/images/svg/crypto-icons/xmr.svg"),
    currency: "XMR",
    from: "Wallet",
    to: "Mary Cousar",
    details: "Monero Purchase",
    id: "9a592451d1b0e0e5af6d4908f7",
    type: "Withdraw",
    amount: "-365.13 XMR",
    amount1: "$754.91",
    status: "Failed",
},
{
    tr_id: 9,
    icon: "ri-arrow-left-down-fill",
    date: "23 Dec, 2021",
    time: "01:47AM",
    image: require("@/assets/images/svg/crypto-icons/xpm.svg"),
    currency: "XPM",
    from: "Glen Matney",
    to: "Wallet",
    details: "British Pound Sterling Block",
    id: "c1bf44fd100fff59a5c64c28dfe0",
    type: "Deposit",
    amount: "+174.09 XPM",
    amount1: "$657.28",
    status: "Processing",
},
{
    tr_id: 10,
    icon: "ri-arrow-left-down-fill",
    date: "15 Dec, 2021",
    time: "08:58AM",
    image: require("@/assets/images/svg/crypto-icons/ppt.svg"),
    currency: "PPT",
    from: "Timothy Smith",
    to: "Wallet",
    details: "British Pound Sterling Block",
    id: "v98p141d5f4j145614sdsa78gh48t98",
    type: "Withdraw",
    amount: "-142.35 PPT",
    amount1: "$398.35",
    status: "Success",
}];

// Crypto > Buy & Sell
const buySellList = [{
    id: 1,
    image: require("@/assets/images/svg/crypto-icons/btc.svg"),
    currency_name: "Bitcoin (BTC)",
    price: "47071",
    pairs: "BTC/USD",
    high: "28,722.76",
    low: "68,789.63",
    market: "888,411,910",
    volume: 1.5,
},
{
    id: 2,
    image: require("@/assets/images/svg/crypto-icons/eth.svg"),
    currency_name: "Ethereum (ETH)",
    price: "3,813.14",
    pairs: "ETH/USDT",
    high: "4,036.24",
    low: "3,588.14",
    market: "314,520,675",
    volume: 0.42,
},
{
    id: 3,
    image: require("@/assets/images/svg/crypto-icons/ltc.svg"),
    currency_name: "Litecoin (LTC)",
    price: "149.65",
    pairs: "LTC/USDT",
    high: "412.96",
    low: "104.33",
    market: "314,520,675",
    volume: 0.89,
},
{
    id: 4,
    image: require("@/assets/images/svg/crypto-icons/fil.svg"),
    currency_name: "Filecoin (FIL)",
    price: "35.21",
    pairs: "FIL/USD",
    high: "36.41",
    low: "35.03",
    market: "374,618,945.51",
    volume: 0.84,
},
{
    id: 5,
    image: require("@/assets/images/svg/crypto-icons/eth.svg"),
    currency_name: "Ethereum (ETH)",
    price: "3,813.14",
    pairs: "ETH/USDT",
    high: "4,036.24",
    low: "3,588.14",
    market: "314,520,675",
    volume: 0.42,
},
{
    id: 6,
    image: require("@/assets/images/svg/crypto-icons/btc.svg"),
    currency_name: "Bitcoin (BTC)",
    price: "47,071.60",
    pairs: "BTC/USD",
    high: "28,722.76",
    low: "68,789.63",
    market: "888,411,910",
    volume: 1.5,
},
{
    id: 7,
    image: require("@/assets/images/svg/crypto-icons/aave.svg"),
    currency_name: "Aave (AAVE)",
    price: "275.47",
    pairs: "AAVE/USDT",
    high: "277.11",
    low: "255.01",
    market: "156,209,195.18",
    volume: 8.2,
},
{
    id: 8,
    image: require("@/assets/images/svg/crypto-icons/dot.svg"),
    currency_name: "Polkadot (DOT)",
    price: "28.88",
    pairs: "DOT/USD",
    high: "30.56",
    low: "28.66",
    market: "880,387,980.14",
    volume: 1.03,
},
{
    id: 9,
    image: require("@/assets/images/svg/crypto-icons/ant.svg"),
    currency_name: "Aragon (ANT)",
    price: "13.31",
    pairs: "ANT/USD",
    high: "13.85",
    low: "12.53",
    market: "156,209,195.18",
    volume: 3.96,
},
{
    id: 10,
    image: require("@/assets/images/svg/crypto-icons/ada.svg"),
    currency_name: "Cardano (ADA)",
    price: "1.35",
    pairs: "ADA/USD",
    high: "1.39",
    low: "1.32",
    market: "880,387,980.14",
    volume: 0.42,
}];

// Crypto > Orders
const cryptoOrders = [{
    id: 1,
    date: ["02 Jan, 2022", "03:45PM"],
    img: require("@/assets/images/svg/crypto-icons/btc.svg"),
    coinName: "Bitcoin (BTC)",
    type: "Buy",
    typeClass: "success",
    quantity: "08",
    orderValue: "$3,70,683.2",
    avgPrice: "$46,154.30",
    price: "$46,335.40",
    status: "Successful",
    statusClass: "success",
},
{
    id: 2,
    date: ["27 Dec, 2021", "02:47PM"],
    img: require("@/assets/images/svg/crypto-icons/eth.svg"),
    coinName: "Ethereum (ETH)",
    type: "Sell",
    typeClass: "danger",
    quantity: "50",
    orderValue: "$1,87,433",
    avgPrice: "$3,744.48",
    price: "$3,748.66",
    status: "Cancelled",
    statusClass: "danger",
},
{
    id: 3,
    date: ["19 Dec, 2021", "10:24AM"],
    img: require("@/assets/images/svg/crypto-icons/xmr.svg"),
    coinName: "Monero (XMR)",
    type: "Sell",
    typeClass: "danger",
    quantity: "150",
    orderValue: "$33,982.5",
    avgPrice: "$227.30",
    price: "$226.55",
    status: "Successful",
    statusClass: "success",
},
{
    id: 4,
    date: ["19 Dec, 2021", "11:20AM"],
    img: require("@/assets/images/svg/crypto-icons/mkr.svg"),
    coinName: "Maker (MKR)",
    type: "Buy",
    typeClass: "success",
    quantity: "60",
    orderValue: "$1,43,445",
    avgPrice: "$2,470.30",
    price: "$2,390.75",
    status: "Pending",
    statusClass: "warning",
},
{
    id: 5,
    date: ["11 Dec, 2021", "02:47PM"],
    img: require("@/assets/images/svg/crypto-icons/yfi.svg"),
    coinName: "Yearn.finance (YFI)",
    type: "Buy",
    typeClass: "success",
    quantity: "25",
    orderValue: "$9,81,906",
    avgPrice: "$37,632.17",
    price: "$39,276.24",
    status: "Cancelled",
    statusClass: "danger",
},
{
    id: 6,
    date: ["05 Dec, 2021", "09:20AM"],
    img: require("@/assets/images/svg/crypto-icons/mkr.svg"),
    coinName: "Maker (MKR)",
    type: "Sell",
    typeClass: "danger",
    quantity: "20",
    orderValue: "$50,243",
    avgPrice: "$2,324.65",
    price: "$2,512.15",
    status: "Successful",
    statusClass: "success",
},
{
    id: 7,
    date: ["28 Nov, 2021", "11:42AM"],
    img: require("@/assets/images/svg/crypto-icons/ltc.svg"),
    coinName: "Litecoin (LTC)",
    type: "Buy",
    typeClass: "success",
    quantity: "200",
    orderValue: "$29,500",
    avgPrice: "$144.00",
    price: "$147.50",
    status: "Pending",
    statusClass: "warning",
},
{
    id: 8,
    date: ["19 Nov, 2021", "03:36PM"],
    img: require("@/assets/images/svg/crypto-icons/xmr.svg"),
    coinName: "Monero (XMR)",
    type: "Sell",
    typeClass: "danger",
    quantity: "75",
    orderValue: "$17,874",
    avgPrice: "$221.61",
    price: "$238.32",
    status: "Successful",
    statusClass: "success",
},
{
    id: 9,
    date: ["27 Oct, 2021", "03:36PM"],
    img: require("@/assets/images/svg/crypto-icons/dash.svg"),
    coinName: "Dash (DASH)",
    type: "Buy",
    typeClass: "success",
    quantity: "45",
    orderValue: "$14,962.5",
    avgPrice: "$147.39",
    price: "$142.5",
    status: "Cancelled",
    statusClass: "danger",
},
{
    id: 10,
    date: ["27 Oct, 2021", "03:36PM"],
    img: require("@/assets/images/svg/crypto-icons/neo.svg"),
    coinName: "Neo (NEO)",
    type: "Sell",
    typeClass: "danger",
    quantity: "36",
    orderValue: "$77,232.24",
    avgPrice: "$2,274.21",
    price: "$2,145.34",
    status: "Pending",
    statusClass: "warning",
},
{
    id: 11,
    date: ["21 Oct, 2021", "11:45AM"],
    img: require("@/assets/images/svg/crypto-icons/mln.svg"),
    coinName: "Enzyme (MLN)",
    type: "Sell",
    typeClass: "danger",
    quantity: "68",
    orderValue: "$6,296.8",
    avgPrice: "$94.21",
    price: "$92.60",
    status: "Successful",
    statusClass: "success",
},
{
    id: 12,
    date: ["24 Sep, 2021", "02:32AM"],
    img: require("@/assets/images/svg/crypto-icons/eth.svg"),
    coinName: "Ethereum (ETH)",
    type: "Buy",
    typeClass: "success",
    quantity: "72",
    orderValue: "$2,69,602.56",
    avgPrice: "$3,744.48",
    price: "$3,748.66",
    status: "Cancelled",
    statusClass: "danger",
}];

// Crypto > My Wallet
const market = [{
    id: 1,
    image: require("@/assets/images/svg/crypto-icons/btc.svg"),
    currency_name: "Bitcoin (BTC)",
    quantity: "08",
    price: "47071",
    value: "28722",
    returns: "6878963",
    volume: 1.5,
},
{
    id: 2,
    image: require("@/assets/images/svg/crypto-icons/eth.svg"),
    currency_name: "Ethereum (ETH)",
    quantity: "50",
    price: "3813",
    value: "4036",
    returns: "3588",
    volume: 0.42,
},
{
    id: 3,
    image: require("@/assets/images/svg/crypto-icons/xmr.svg"),
    currency_name: "Monero (XMR)",
    quantity: "150",
    price: "227",
    value: "226",
    returns: "33982",
    volume: 1.92,
},
{
    id: 4,
    image: require("@/assets/images/svg/crypto-icons/aave.svg"),
    currency_name: "Aave (AAVE)",
    quantity: "180",
    price: "250",
    value: "254",
    returns: "45774",
    volume: 3.96,
},
{
    id: 5,
    image: require("@/assets/images/svg/crypto-icons/mkr.svg"),
    currency_name: "Maker (MKR)",
    quantity: "60",
    price: "2470",
    value: "2390",
    returns: "143445",
    volume: 0.36,
},
{
    id: 6,
    image: require("@/assets/images/svg/crypto-icons/mkr.svg"),
    currency_name: "Yearn.finance (YFI)",
    quantity: "25",
    price: "37632",
    value: "39276",
    returns: "981906",
    volume: 3.96,
},
{
    id: 7,
    image: require("@/assets/images/svg/crypto-icons/mkr.svg"),
    currency_name: "Maker (MKR)",
    quantity: "60",
    price: "37632",
    value: "39276",
    returns: "981906",
    volume: 3.96,
},
{
    id: 8,
    image: require("@/assets/images/svg/crypto-icons/mkr.svg"),
    currency_name: "Yearn.finance (YFI)",
    quantity: "25",
    price: "37632",
    value: "39276",
    returns: "981906",
    volume: 3.96,
}];

export { transactions, buySellList, cryptoOrders, market };