<script>
import { layoutComputed } from '@/state/helpers';

//import Horizontal from './horizontal';
//import TwoColumns from './twocolumn';
import Vertical from './vertical';

export default {
    components: {
        Vertical,
        //Horizontal,
        //TwoColumns,
    },
    //setup() {
    //    const widgetInfo = inject('$widgetInfo');

    //    const { widgetClose, modalClose } = inject('$provideList');

    //    return {
    //        widgetInfo,
    //        widgetClose,
    //        modalClose,
    //    };
    //},
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
};
</script>

<template>
    <div>
        <Vertical v-if="layoutType === 'vertical' || layoutType === 'semibox'" :layout="layoutType">
            <slot />
        </Vertical>

        <!--<Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
            <slot />
        </Horizontal>-->

        <!--<TwoColumns v-if="layoutType === 'twocolumn'" :layout="layoutType">
            <slot />
        </TwoColumns>-->
    </div>
</template>
