<template>
    <div class="chat-list chat-user-list p-2">
        <div class="mb-4 hand" v-for="x in chatList" :key="x" @click="getChat(x)">
            <span v-if="x.EncUser != userInfo.EncUser">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 chat-user-img online align-self-center me-3">
                        <img :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'" class="rounded-circle avatar-sm img-fit" />
                        <!-- 상대방이 접속 상태이면 아이콘 표시 -->
                        <span v-if="signals.find((y) => y.EncUser === x.EncUser && y.Status === true)" class="user-status"></span>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                        <p class="text-truncate mb-0 fw-bold fs-14 mb-1" v-text="x.UserName"></p>
                        <!-- <p v-if="x.LastMsg" class="text-truncate mb-0" v-text="x.LastMsg && x.LastMsg.Content ? x.LastMsg.Content : '-'"></p> -->
                    </div>
                    <div class="ms-auto">
                        <span class="badge bg-dark-subtle text-body rounded" v-text="x.LastMsg ? $moment(x.LastMsg.Created).format('MM월 DD일 hh:mm') : ''"></span>
                        <span v-if="x.UnRead > 0" class="fs-12 badge rounded-pill bg-danger ms-2">
                            {{ x.UnRead }}
                        </span>
                    </div>
                </div>
            </span>
        </div>
    </div>
</template>

<script lang="js">
import { inject } from "vue";


export default {
    setup() {
        const store = inject("$store");

        const userInfo = inject("$userInfo");
        const signals = inject('$signals');

        const widgetInfo = inject('$widgetInfo');

        const { chatList, gotoWidget, toApp } = inject("$provideList");

        const getChat = async (selected) => {
            selected.UnRead = 0;
            //store.dispatch("AC_WIDGET", { target: 'CompChat', data: selected });
            // console.log(JSON.stringify(chatList.value));

            if (store.state.connection) {
                try {
                    await store.state.connection.invoke('SendEntranceChat', userInfo.value.EncUser);
                    if (widgetInfo.value.isApp) {
                        //const datas = await getChatData(selected);
                        toApp('selectChat', JSON.stringify(selected));
                        //alert(JSON.stringify(datas))
                    } else {
                        gotoWidget(selected, 'CompChat', selected.UserName, '')
                    }
                } catch (error) {
                    console.error('Error invoking SendMessageToClient:', error);
                }
            }
        };

        return {
            userInfo, chatList,
            signals,
            getChat,
        };
    },
};
</script>

<style scoped lang="scss"></style>
