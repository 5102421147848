<template>

    <BRow>
        <BCol xxl="12">
            <!-- <h5 class="mb-3">이동하고자 하는 워크스페이스 또는 자료보관함을 선택하여 주시기 바랍니다.</h5> -->
            <BCard no-body>
                <BCardBody>

                    <p class="text-muted">이동하고자 하는 워크스페이스 또는 자료보관함을 선택하여 주시기 바랍니다.</p>

                    <BTabs nav-class="nav-tabs-custom nav-success mb-3" justified>

                        <BTab title="워크스페이스" active>
                            <div class="text-muted">
                                <div class="d-flex">
                                    <div class="flex-shrink-0">
                                        <i class="ri-checkbox-multiple-blank-fill text-success"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-2">

                                        <TreeMenu :model="treeData"></TreeMenu>

                                    </div>
                                </div>
                            </div>
                        </BTab>
                        <BTab title="자료보관함">
                            <div class="text-muted">
                                <div class="d-flex">
                                    <div class="flex-shrink-0">
                                        <i class="ri-checkbox-multiple-blank-fill text-success"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-2">

                                    </div>
                                </div>
                            </div>
                        </BTab>
                        <BTab title="내자료함">
                            <div class="text-muted">
                                <div class="d-flex">
                                    <div class="flex-shrink-0">
                                        <i class="ri-checkbox-multiple-blank-fill text-success"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-2">

                                    </div>
                                </div>
                            </div>
                        </BTab>

                    </BTabs>

                </BCardBody>
            </BCard>

        </BCol>
    </BRow>

    <BRow>
        <BCol xxl="6">
            <BButton variant="success" class="waves-effect waves-light w-100 h-50h" @click="pageCopy">복사</BButton>
        </BCol>
        <BCol xxl="6">
            <BButton variant="primary" class="waves-effect waves-light w-100 h-50h" @click="pageMove">이동</BButton>
        </BCol>
    </BRow>

</template>


<script lang="js">
import { inject } from "vue";
import { sysEnums, apiEnums } from "@/common/sysEnums";
import { showAlert } from "@/common/comFuncs";
import TreeMenu from '@/components/tree-menu.vue'

export default {
    props: {
        Content: String,
        FileType: Number,
        IsBtn: Boolean,
    },
    components: {
        TreeMenu,
    },
    setup(props) {
        const axios = inject("$axios");
        const userInfo = inject('$userInfo');

        // const widgetInfo = inject('$widgetInfo');

        const { getUserFolder, getUserPage, selectedFolder, selectedPage, treeData } = inject("$provideList");

        const pageMove = () => {
            if (!selectedFolder.value.EncFolder) {
                showAlert('', '폴더를 선택 하세요.', '', sysEnums.AlertTypes.Error);
                return;
            }

            const role = selectedPage.value.PageRoles.find(x => x.EncUser === selectedPage.value.EncUser);
            // console.log(JSON.stringify(selectedPage.value))

            if (!role) {
                showAlert('', '삭제된 페이지 입니다.', '', sysEnums.AlertTypes.Error);
                return;
            }

            if (selectedFolder.value) {
                const formData = new FormData();
                formData.append('EncFolder', selectedFolder.value.EncFolder);
                formData.append('EncPage', selectedPage.value.EncPage);
                formData.append('EncUser', selectedPage.value.EncUser);
                if (role.EncRole) {
                    formData.append('EncRole', role.EncRole);
                }
                axios.post(apiEnums.CloudTicket.createRole, formData)
                    .then((response) => {
                        if (response.data !== '0000') {
                            getUserFolder();
                            getUserPage();
                        }
                    })
                    .catch(() => { });
            }
        }

        const pageCopy = () => {
            if (!selectedFolder.value.EncFolder) {
                showAlert('', '폴더를 선택 하세요.', '', sysEnums.AlertTypes.Error);
                return;
            }

            if (selectedFolder.value) {
                const formData = new FormData();
                formData.append('EncFolder', selectedFolder.value.EncFolder);
                formData.append('EncPage', selectedPage.value.EncPage);
                formData.append('EncUser', userInfo.value.EncUser);
                axios.post(apiEnums.CloudTicket.copyPage, formData)
                    .then((response) => {
                        if (response.data !== '0000') {
                            getUserFolder();
                            getUserPage();
                        }
                    })
                    .catch(() => { });
            }
        }


        return {
            props,
            treeData,
            pageMove, pageCopy,
        };
    },
};
</script>

<style scoped lang="scss"></style>