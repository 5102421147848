<template>
    <div v-if="!isKakaoPost">
        <div class="card-header">
            <button type="button" class="btn-close float-end fs-11" @click="emit('CloseRegist')"></button>
            <h6 class="card-title mb-0 d-inline-block">회사등록하기</h6>
        </div>

        <div class="card-body form-steps p-3">
            <div id="custom-progress-bar" class="progress-nav mt-4 mb-5">
                <div class="progress" style="height: 1px">
                    <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <ul class="nav nav-pills progress-bar-tab custom-nav" role="tablist">
                    <li class="nav-item" role="presentation" @click="setPage(1)">
                        <button
                            class="nav-link rounded-pill active"
                            data-progressbar="custom-progress-bar"
                            id="pills-gen-info-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-gen-info"
                            type="button"
                            role="tab"
                            aria-controls="pills-gen-info"
                            aria-selected="true"
                        >
                            1
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" @click="setPage(2)">
                        <button
                            class="nav-link rounded-pill"
                            data-progressbar="custom-progress-bar"
                            id="pills-info-desc-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-info-desc"
                            type="button"
                            role="tab"
                            aria-controls="pills-info-desc"
                            aria-selected="false"
                        >
                            2
                        </button>
                    </li>
                    <li class="nav-item" role="presentation" @click="setPage(3)">
                        <button
                            class="nav-link rounded-pill"
                            data-progressbar="custom-progress-bar"
                            id="pills-success-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-success"
                            type="button"
                            role="tab"
                            aria-controls="pills-success"
                            aria-selected="false"
                        >
                            3
                        </button>
                    </li>
                </ul>
            </div>

            <!-- 입력 부분 시작 -->
            <div class="container-slide" style="height: 720px">
                <div :class="curPage === 1 ? 'slide-in' : curPage > 1 ? 'slide-left-out' : 'slide-right-out'">
                    <div>
                        <div class="text-center pb-4 mb-2 tab-top-tit">
                            <h5>회사명, 회사 주소 입력하기</h5>
                        </div>
                        <div class="mb-4">
                            <div>
                                <h5 class="mb-1">기본정보 입력</h5>
                                <p class="text-muted">관리파트너 선택, 업종을 선택하여 주시기 바랍니다.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <div class="form-floating">
                                        <input type="text" class="form-control" id="floatingInput1" placeholder="회사명" v-on:input="searchPartner" v-model="companyName" />
                                        <label for="floatingInput1">회사명</label>
                                    </div>
                                </div>
                                <ul class="list-unstyled chat-list chat-user-list">
                                    <li id="contact-id-1" data-name="direct-message" class="hand" v-for="x in filteredUsers" :key="x" @click="setPartner(x)">
                                        <div class="d-flex align-items-center p-2">
                                            <div class="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                <div class="avatar-xs">
                                                    <img
                                                        :src="x.FilePath ? $fileUrl + x.FilePath : 'assets/images/no-profile.jpg'"
                                                        class="rounded-circle img-fluid userprofile img-fit"
                                                        alt=""
                                                    />
                                                    <span class="user-status"></span>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1 overflow-hidden">
                                                <p class="text-truncate mb-0">
                                                    {{ x.UserName + ' ' + modifiedUserPhone(x.LoginId) }}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-6 d-flex align-items-center">
                                <div class="mb-3 w-100">
                                    <!-- <div class="w-100 btn-group pt-1">
                                            <button type="button" class="btn border h-50h border-1 dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static"
                                                aria-expanded="true">
                                                {{ selectedCompanyType ? selectedCompanyType.name : "업종 선택" }}
                                            </button>
                                            <ul class="dropdown-menu">
                                                <li v-for="x in companyTypes" :key="x">
                                                    <button class="dropdown-item" type="button">
                                                        {{ x.name }}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div> -->

                                    <div class="form-floating text-center">
                                        <select class="form-select" id="floatingSelect1a" aria-label="납입(입금)일" v-model="selectedCompanyType">
                                            <option class="text-center" selected>업종 선택</option>
                                            <option class="text-center" v-for="x in companyTypes" :key="x">
                                                {{ x.name }}
                                            </option>
                                        </select>
                                        <label for="floatingSelect1a">업종 선택</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="my-4">
                            <!-- <div class="fw-bold" v-text="'주소 *'"></div> -->
                            <!-- <div class="mb-2 text-danger" v-text="postalCode ? postalCode : ''"></div>
                                <div class="me-2 text-dark" v-text="addr1 ? addr1 : ''"></div>
                                <span class="text-dark" v-text="addr2 ? addr2 : ''"></span> -->

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="d-flex align-items-center mb-2">
                                        <div class="form-floating w-50 pe-3">
                                            <input type="number" class="form-control" id="floatingInput-1" placeholder="우편번호" v-model="postalCode" readonly />
                                            <label for="floatingInput-1">우편번호</label>
                                        </div>
                                        <button type="button" class="w-50 h-50h btn btn-primary rounded-pill" @click="searchPostalCode">우편번호검색</button>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2">
                                <div class="form-floating w-100">
                                    <input type="text" class="form-control" id="floatingInput-2" placeholder="주소" v-model="addr1" readonly />
                                    <label for="floatingInput-2">주소</label>
                                </div>
                            </div>
                            <div class="mb-2">
                                <div class="form-floating w-100">
                                    <input type="text" class="form-control" id="floatingInput-3" placeholder="상세주소" v-on:input="handleInput" v-model="addr2" />
                                    <label for="floatingInput-3">상세주소</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div :class="curPage === 2 ? 'slide-in' : curPage > 2 ? 'slide-left-out' : 'slide-right-out'">
                    <div>
                        <div class="text-center pb-4 mb-2 tab-top-tit">
                            <h5>회사정보 입력하기</h5>
                        </div>
                        <div class="mb-4">
                            <div>
                                <h5 class="mb-1">일반사항 입력</h5>
                                <p class="text-muted">시설종류 및 면적, 옵션을 선택하여 주시기 바랍니다.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="d-flex align-items-center mb-2">
                                    <div class="w-50 pe-1">
                                        <div class="form-floating">
                                            <input type="number" class="form-control" id="floatingInput2" placeholder="공급면적 m2" v-model="supply" />
                                            <label for="floatingInput2">공급면적 m2</label>
                                        </div>
                                    </div>
                                    <div class="w-50 ps-1">
                                        <div class="form-floating">
                                            <input type="number" class="form-control" id="floatingInput3" placeholder="전용면적 m2" v-model="dedicated" />
                                            <label for="floatingInput3">전용면적 m2</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 mb-2 d-flex align-items-center position-relative">
                                <div class="form-floating w-100">
                                    <input
                                        type="text"
                                        class="form-control fs-22 text-center"
                                        id="floatingInput5"
                                        placeholder="보증금 (단위 원)"
                                        @input="formatCurrency($event, 1)"
                                        v-model="deposit"
                                    />
                                    <label for="floatingInput5">보증금 (단위 원)</label>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-2 d-flex align-items-center position-relative">
                                <div class="form-floating w-100">
                                    <input
                                        type="text"
                                        class="form-control fs-22 text-center"
                                        id="floatingInput6"
                                        placeholder="임대료 (단위 원)"
                                        @input="formatCurrency($event, 2)"
                                        v-model="rent"
                                    />
                                    <label for="floatingInput6">임대료 (단위 원)</label>
                                </div>
                            </div>
                            <div class="mb-3">부가가치세 10%는 자동으로 적용되며<br />합계란에 합산하여 표시됩니다</div>
                            <div class="col-lg-6 mb-2 d-flex align-items-center position-relative">
                                <div class="form-floating w-100 form-icon right">
                                    <input
                                        type="text"
                                        class="form-control fs-22 text-center"
                                        id="floatingInput7"
                                        placeholder="관리비 (단위 원)"
                                        @input="formatCurrency($event, 3)"
                                        v-model="managementFee"
                                    />
                                    <i class="ri-question-line fs-24 hand p-2" @click="showMaintnanceInfo" style="z-index: 1000"></i>
                                    <label for="floatingInput7">관리비 (단위 원)</label>
                                </div>
                            </div>
                            <div class="mb-5">관리비는 양자간 협의하여 정한 금액을 등록하며<br />일괄적으로 부가가치세 10% 적용됩니다</div>
                            <div class="col-lg-6">
                                <div class="mb-2">매월 납부할 금액 (VAT 포함) = 임대료+관리비</div>
                                <div class="fw-bold fs-20" v-if="rent">
                                    {{ addCommas(((parseInt(removeCommas(rent)) + parseInt(removeCommas(managementFee))) * 1.1).toFixed(0)) }}원
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div :class="curPage === 3 ? 'slide-in' : curPage > 3 ? 'slide-left-out' : 'slide-right-out'">
                    <div class="text-center pb-4 mb-2 tab-top-tit">
                        <h5>시설사진 첨부하기</h5>
                    </div>
                    <div class="mb-4">
                        <div class="d-flex align-items-center">
                            <h5 class="flex-fill">자료첨부</h5>
                            <button type="button" class="btn btn-primary rounded-pill" @click="selectImage">사진선택</button>
                        </div>
                        <p class="text-muted">회사의 내, 외부 사진을 올려주시기 바랍니다.</p>
                    </div>
                    <!-- <FileUpload ref="fileRef" :FileType="sysEnums.FileTypes.Company" :Content="''" :IsBtn="false"></FileUpload> -->
                    <!-- <div class="mt-5 check-area">
                            <CompanyOption v-on:OptionSelected="setCompanyOption"></CompanyOption>
                        </div> -->
                </div>
            </div>
        </div>
    </div>

    <div v-show="isKakaoPost" class="card">
        <div class="card-body p-3">
            <KakaoPost ref="postRef" v-on:SetAddress="setAddress"></KakaoPost>
        </div>
        <div class="w-100 text-end">
            <button type="button" class="btn btn-secondary" @click="searchPostalCode">닫기</button>
        </div>
    </div>

    <!-- 하단 네비게이션바 -->
    <div class="fixed-bottom p-3">
        <div class="d-flex align-items-center justify-content-between bg-white p-3">
            <button @click="prev" v-show="curPage > 1" type="button" class="btn btn-label previestab h-50h" data-previous="pills-gen-info-tab">
                <i class="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>이전
            </button>
            <!-- <span class="mx-5 fw-bold" v-text="curPage + ' / ' + pages"></span> -->
            <button v-show="curPage === 1" type="button" class="btn btn-success btn-label right ms-auto nexttab" @click="next">
                <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>회사정보입력
            </button>
            <button v-show="curPage === 2" type="button" class="btn btn-success btn-label right ms-auto nexttab" @click="next">
                <i class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>자료첨부하기
            </button>
            <button v-show="curPage === 3" class="btn btn-secondary rounded-pill px-4 ms-5" v-text="'회사등록하기'" :disabled="!isChecked" @click="createCompany"></button>
        </div>
    </div>

    <!-- 우편번호용 Modal -->
    <!-- <div class="modal fade" id="staticBackdrop" data-bs-backdrop="false" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
                <div class="modal-header pb-3 border-bottom">
                    <h1 class="modal-title fs-5" id="staticBackdropLabel">우편번호검색</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="searchPostalCode"></button>
                </div>
                <div class="modal-body mt-2">
                    <KakaoPost ref="postRef" v-on:SetAddress="setAddress"></KakaoPost>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="searchPostalCode">닫기</button>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import { ref, inject, getCurrentInstance } from 'vue';
import { apiEnums, enumTypes, sysEnums } from '@/common/sysEnums';
import { closeCanvas, showAlert, modifiedUserPhone, addCommas, removeCommas } from '@/common/comFuncs';
// import CompanyOption from "@/components/companys/CompanyOption";
import KakaoPost from '@/components/kakao/KakaoPost';
// import FileUpload from "@/components/file/FileUpload";

export default {
    emits: ['CloseRegist'],
    props: {
        Data: Object,
        UserType: Number,
    },
    components: {
        // CompanyOption,
        KakaoPost,
        // FileUpload
    },
    setup() {
        const pages = 4;
        let curPage = ref(1);

        let isChecked = ref(false);
        let isKakaoPost = ref(false);

        let companyName = ref('');

        let selectedPartner = ref('');
        let selectedPartnerName = ref('');

        let addr1 = ref('');
        let addr2 = ref('');
        let postalCode = ref('');
        let supply = ref(null);
        let dedicated = ref(null);
        let selectedOptions = ref([]);
        let selectedCompanyType = ref(null);
        let selectedCompanyIndex = ref(null);
        let lat = ref(null);
        let lng = ref(null);
        let deposit = ref('');
        let rent = ref('');
        let managementFee = ref('');

        const fileRef = ref(null);
        const postRef = ref(null);

        const filteredUsers = ref([]);

        const axios = inject('$axios');
        const store = inject('$store');

        const { emit } = getCurrentInstance();

        const companyTypes = Object.values(enumTypes.CompanyTypes);
        // const options = Object.values(enumTypes.CompanyOptions);

        const userInfo = inject('$userInfo');
        const users = inject('$users');

        const { companyList } = inject('$provideList');

        const searchPartner = event => {
            // console.log('searchPartner : ' + JSON.stringify(users.value));
            if (event.target.value.trim() === '') {
                filteredUsers.value = [];
            } else {
                filteredUsers.value = users.value.filter(user => {
                    return (user.UserName.includes(event.target.value) || user.LoginId.includes(event.target.value)) && user.EncUser !== userInfo.value.EncUser;
                });

                if (filteredUsers.value.length === 0) {
                    filteredUsers.value = [{ EncUser: sysEnums.hm1, UserType: 1, UserName: '하우스메타', LoginId: sysEnums.hm1, Uid: '', AvatarPath: null }];
                }
            }
        };

        const setPartner = data => {
            filteredUsers.value = [];
            selectedPartner.value = data.EncUser;
            selectedPartnerName.value = data.UserName;
            // console.log(JSON.stringify(data));
        };

        // const handleCompanyType = (x) => {
        //     console.log(x);

        //     selectedCompanyType.value = x;
        //     selectedCompanyIndex.value = companyTypes.findIndex(item => item.name === x.name);
        // }

        const setCompanyOption = x => {
            selectedOptions.value = x;
        };

        const setAddress = x => {
            postalCode.value = x.code;
            addr1.value = x.addr1;
            addr2.value = x.addr2;
            lat.value = x.coords.lat;
            lng.value = x.coords.lng;
            // console.log(lat.value , lng.value)
        };

        const createCompany = () => {
            // let encLessor = null;
            // let encPartner = null;

            // if (userInfo.value.UserType === sysEnums.UserTypes.Partner) {
            //     encLessor = selectedPartner.value;
            //     encPartner = userInfo.value.EncUser;
            // }

            // if (userInfo.value.UserType === sysEnums.UserTypes.Lessor) {
            //     encLessor = userInfo.value.EncUser;
            //     encPartner = selectedPartner.value;
            // }

            if (!companyName.value) {
                showAlert('', '회사명을 입력 하세요!', 'PageClip', sysEnums.AlertTypes.Warning);
                curPage.value = 1;
                return;
            }

            // if (userInfo.value.UserType === sysEnums.UserTypes.Lessor && encPartner === null) {
            //     showAlert('', '임대인을 선택 하세요!', 'HouseMeta', sysEnums.AlertTypes.Warning);
            //     curPage.value = 1;
            //     return;
            // }

            if (selectedCompanyType.value === null) {
                showAlert('', '회사타입을 선택 하세요!', 'PageClip', sysEnums.AlertTypes.Warning);
                curPage.value = 1;
                return;
            }

            if (postalCode.value === '' || addr1.value === '' || addr1.value === '') {
                showAlert('', '주소를 입력 하세요!', 'PageClip', sysEnums.AlertTypes.Warning);
                curPage.value = 1;
                return;
            }

            // if (supply.value === null || dedicated.value === null) {
            //     showAlert('', '공급/전용 면적을 입력 하세요!', 'HouseMeta', sysEnums.AlertTypes.Warning);
            //     curPage.value = 2;
            //     return;
            // }

            // if (deposit.value === '') {
            //     showAlert('', '보증금을 입력 하세요!', 'HouseMeta', sysEnums.AlertTypes.Warning);
            //     curPage.value = 2;
            //     return;
            // }

            // if (rent.value === '') {
            //     showAlert('', '임대료를 입력 하세요!', 'HouseMeta', sysEnums.AlertTypes.Warning);
            //     curPage.value = 2;
            //     return;
            // }

            // if (managementFee.value === '') {
            //     managementFee.value = '0';
            //     // showAlert('', '관리비를 입력 하세요!', 'HouseMeta', sysEnums.AlertTypes.Warning);
            //     // curPage.value = 2;
            //     // return;
            // }

            console.log(companyName.value, selectedCompanyType.value, postalCode.value, addr1.value, addr2.value, lat.value, lng.value);

            const formData = new FormData();
            formData.append('Name', companyName.value);
            formData.append('Type', 2);
            formData.append('PostalCode', postalCode.value);
            formData.append('Address1', addr1.value);
            formData.append('Address2', addr2.value);
            // formData.append('Supply', supply.value);
            // formData.append('Dedicated', dedicated.value);
            // formData.append('Options', selectedOptions.value);
            formData.append('Lat', lat.value);
            formData.append('Lng', lng.value);
            axios
                .post(apiEnums.CloudTicket.createCompany, formData)
                .then(response => {
                    // console.log(JSON.stringify(response.data))
                    if (response.data === '9999') {
                        showAlert('PageClip', '이미 등록된 회사가 있습니다.', '', sysEnums.AlertTypes.Danger);
                    } else if (response.data !== '0000') {
                        const promise1 = fileRef.value.fileUploadWithParam(response.data);
                        Promise.all([promise1])
                            .then(async () => {
                                showAlert('PageClip', '회사가 등록 되었습니다.', '', sysEnums.AlertTypes.Success);

                                getCompany();

                                store.dispatch('AC_WIDGET_DETAIL', { title: '', target: '', data: [] });

                                try {
                                    await store.state.connection.invoke('SendMsgToConsole', enumTypes.SignalTypes.CompanyCreated);
                                } catch (error) {
                                    alert(error);
                                }
                            })
                            .catch(() => {});
                    }
                    closeCanvas('offcanvasWidgetDetail');
                })
                .catch(() => {});
        };

        const getCompany = () => {
            axios
                .post(apiEnums.CloudTicket.getCompany)
                .then(response => {
                    store.dispatch('AC_COMPANY', response.data);
                    companyList.value = response.data;
                    // console.log(' handleCompanyUploaded ' + JSON.stringify(response.data))
                })
                .catch(() => {});
        };

        const next = () => {
            curPage.value < pages ? curPage.value++ : (curPage.value = pages);
            updateProgressBar();
        };

        const prev = () => {
            curPage.value > 1 ? curPage.value-- : (curPage.value = 1);
            updateProgressBar();
        };

        const setPage = page => {
            curPage.value = page;
            updateProgressBar();
        };

        const updateProgressBar = () => {
            const progressBar = document.querySelector('#custom-progress-bar .progress-bar');
            const width = ((curPage.value - 1) / (3 - 1)) * 100;
            progressBar.style.width = width + '%';
            progressBar.setAttribute('aria-valuenow', width);

            const stepMarkers = document.querySelectorAll('.progress-bar-tab .nav-link');
            stepMarkers.forEach((marker, index) => {
                if (index + 1 === curPage.value) {
                    marker.classList.add('active');
                } else {
                    marker.classList.remove('active');
                }
            });

            curPage.value === 3 ? (isChecked.value = true) : (isChecked.value = false);

            const container = document.getElementById('appWidgetDetail');
            if (container) {
                if (container.scrollTop > 0) {
                    // console.log('appWidgetDetail scrollTop : ' + container.scrollTop);
                    container.scrollTop = 0;
                }
            }
            // console.log('progressBar' + width);
        };

        const formatCurrency = (event, flag) => {
            console.log(event.target.value);
            flag === 1
                ? (deposit.value = addCommas(event.target.value))
                : flag === 2
                ? (rent.value = addCommas(event.target.value))
                : flag === 3 && event.target.value === '0'
                ? (managementFee.value = event.target.value)
                : flag === 3
                ? (managementFee.value = addCommas(event.target.value))
                : null;
        };

        const selectImage = () => {
            fileRef.value.handleSelectImage();
        };

        const searchPostalCode = () => {
            isKakaoPost.value = !isKakaoPost.value;
            postRef.value.viewDaumPostcode();
        };

        const handleInput = event => {
            addr2.value = event.target.value;
            // console.log(addr1.value, addr2.value)
        };

        const showMaintnanceInfo = () => {
            let info = '1. 일반관리비, 청소비, 경비비, 소독비<br>';
            info += '승강기유지비 난방비, 급탕비, 수선유지비<br> 지능형 홈네트워크설비 유지비, 기타 등<br><br>';
            info += '2. 전기료, 수도료, 가스료, 정화조<br> 생활폐기, 물수수료, 기타 등<br>';
            showAlert('관리비항목', info, 'HouseMeta', sysEnums.AlertTypes.Info);
        };

        return {
            pages,
            curPage,
            isChecked,
            isKakaoPost,
            emit,
            companyTypes,
            addr1,
            addr2,
            postalCode,
            lat,
            lng,
            supply,
            dedicated,
            userInfo,
            companyName,
            selectedOptions,
            selectedPartner,
            selectedPartnerName,
            selectedCompanyType,
            selectedCompanyIndex,
            deposit,
            rent,
            managementFee,
            postRef,
            fileRef,
            filteredUsers,
            sysEnums,
            enumTypes,
            setPage,
            next,
            prev,
            formatCurrency,
            modifiedUserPhone,
            searchPostalCode,
            searchPartner,
            addCommas,
            removeCommas,
            setCompanyOption,
            setAddress,
            setPartner,
            createCompany,
            selectImage,
            showMaintnanceInfo,
            handleInput,
        };
    },
};
</script>

<style scoped lang="scss"></style>
