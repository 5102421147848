<script>
import { getUserDepartment, showAlert, showConfirm } from '@/common/comFuncs';
import { apiEnums, sysEnums } from '@/common/sysEnums';
import { computed, inject, ref, onMounted } from 'vue';

import '@vueform/multiselect/themes/default.css';
import { MoreHorizontalIcon } from '@zhuowenli/vue-feather-icons';

import AppTreeFolder from '@/components/apps/app-tree-folder';

import FileUploadSingle from '@/components/files/FileUploadSingle';

import simplebar from 'simplebar-vue';

export default {
    props: {
        Index: Number,
        Data: Object,
    },
    components: {
        MoreHorizontalIcon,
        FileUploadSingle,
        AppTreeFolder,
        simplebar,
    },
    setup(props) {
        const isShow = ref(false);
        const trees = ref({ Name: 'FOLDER', children: [] });
        const subTrees = ref({ Name: 'FOLDER', children: [] });

        const axios = inject('$axios');

        const userInfo = inject('$userInfo');
        const widgetInfo = inject('$widgetInfo');

        const fileRef = ref(null);

        const { treeData, folderList, pageList, selectedFolder, getUserFolder, sendMessage, gotoModal, gotoWidget, pageCount } = inject('$provideList');

        //watch(
        //    () => selectedFolder.value.EncFolder,
        //    () => {
        //        filterData();
        //        console.log('---');
        //    },
        //);

        onMounted(() => {
            filterData();
        });

        const filterData = () => {
            let filterCondition;

            if (props.Data.Shared) {
                // 공유시 전체 보이기
                filterCondition = item => item.EncFolder === (selectedFolder.value.EncFolder || props.Data.EncFolder);
            } else {
                // 공유 해제시 내 폴더가 보여야 편집 가능
                filterCondition = item => item.EncUser === userInfo.value.EncUser && item.EncFolder === (selectedFolder.value.EncFolder || props.Data.EncFolder);
            }

            const filteredChildren = treeData.value.children.filter(filterCondition);
            trees.value.children = filteredChildren;

            if (filteredChildren.length > 0) {
                if (filteredChildren[0].children && filteredChildren[0].children.length > 0) {
                    // 하위 트리가 있을때
                    subTrees.value.children = filteredChildren[0].children;
                } else {
                    // 하위 트리가 없이 최상위 위치
                    //subTrees.value.children = filteredChildren;
                }
            } else {
                // 하위 트리가 없는경우
                subTrees.value.children = [];
            }

            // console.log('FolderItem filterData No selectedFolder : ' + props.Data.EncFolder + ' / ' + JSON.stringify(trees.value.children, null, 8))
        };

        const countPages = nodes => {
            const counts = {
                cntFolders: [],
                cntPosts: [],
                cntPages: [],
            };

            let cntFolder = 0;
            let cntPost = 0;
            let cntPage = 0;

            const countRoles = node => {
                if (node.FolderRoles && node.FolderRoles.length > 0) {
                    cntPage += pageCount(node.EncFolder);
                    // cntPage += node.FolderRoles.filter(x => x.Grouped === 3).length;
                    // console.log(props.Data.EncFolder + ' / ' + JSON.stringify(node, null, 4));
                }

                if (node.Type === sysEnums.PageCategorys.Folder) {
                    cntFolder += 1;
                }

                if (node.Type === sysEnums.PageCategorys.Post) {
                    cntPost += 1;
                }

                // 페이지기준 권한시 하위 노드 전체페이지 카운트 / 6.25일 포스트단위 권한적용 변경요청
                if (node.children && node.children.length > 0) {
                    for (let child of node.children) {
                        countRoles(child);
                    }
                }
            };

            const processNodes = nodes => {
                for (let node of nodes) {
                    countRoles(node);

                    if (node.Type === sysEnums.PageCategorys.Folder && node.EncUser === userInfo.value.EncUser) {
                        cntFolder -= 1;
                    }

                    if (node.Type === sysEnums.PageCategorys.Post && node.EncUser === userInfo.value.EncUser) {
                        cntPost -= 1;
                    }

                    // console.log(JSON.stringify(node));

                    counts.cntFolders.push(cntFolder);
                    counts.cntPosts.push(cntPost);
                    counts.cntPages.push(cntPage);

                    cntFolder = 0;
                    cntPost = 0;
                    cntPage = 0;
                }
            };

            if (selectedFolder.value.EncFolder) {
                for (let node of nodes) {
                    processNodes(node.children);
                }
            } else {
                processNodes(nodes);
            }

            return counts;
        };

        const counts = computed(() => {
            // console.log(JSON.stringify(trees.value.children));
            return countPages(trees.value.children);
        });

        const cntFolder = computed(() => {
            if (selectedFolder.value.EncFolder) {
                return counts.value.cntFolders[props.Index] || 0;
            } else {
                return counts.value.cntFolders.length ? counts.value.cntFolders.reduce((acc, cnt) => acc + cnt, 0) : 0;
            }
        });

        const cntPost = computed(() => {
            if (selectedFolder.value.EncFolder) {
                return counts.value.cntPosts[props.Index] || 0;
            } else {
                return counts.value.cntPosts.length ? counts.value.cntPosts.reduce((acc, cnt) => acc + cnt, 0) : 0;
            }
        });

        const cntPage = computed(() => {
            if (selectedFolder.value.EncFolder) {
                return counts.value.cntPages[props.Index] || 0;
            } else {
                return counts.value.cntPages.length ? counts.value.cntPages.reduce((acc, cnt) => acc + cnt, 0) : 0;
            }
        });

        const gotoFolder = (data, to, title) => {
            // let encTopFolder = sysEnums.zero;

            // if (selectedFolder.value && selectedFolder.value.EncFolder) {
            //     encTopFolder = selectedFolder.value.EncFolder;
            // }

            selectedFolder.value = data;

            // console.log(JSON.stringify(props.Data));

            if (!selectedFolder.value) {
                return;
            }

            if (props.Data.EncUser === userInfo.value.EncUser) {
                widgetInfo.value.titleModal = title;
                widgetInfo.value.modal = true;
                widgetInfo.value.targetModal = to;
            }
        };

        const updateFolderFix = () => {
            if (props.Data.EncUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '폴더 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            if (props.Data) {
                const formData = new FormData();
                formData.append('EncUser', userInfo.value.EncUser);
                formData.append('EncFolder', props.Data.EncFolder);
                formData.append('Fixed', 1);
                axios
                    .post(apiEnums.CloudTicket.updateFolder, formData)
                    .then(response => {
                        if (response.data !== '0000') {
                            getUserFolder();
                            sendMessage(null, sysEnums.SignalTypes.Folder);
                        }
                    })
                    .catch(() => {});
            } else {
                console.log('updateFolderFix error : ' + JSON.stringify(props.Data));
            }
        };

        const updateFolderShare = () => {
            if (props.Data.EncUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '폴더 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncFolder', props.Data.EncFolder);
            formData.append('Shared', 1);
            axios
                .post(apiEnums.CloudTicket.updateFolder, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        sendMessage(null, sysEnums.SignalTypes.Folder);
                    }
                })
                .catch(() => {});
        };

        const filteredFolderRoles = computed(() => {
            const subFolders = folderList.value.filter(x => x.EncFolder === props.Data.EncFolder);

            const folderRoles = subFolders
                .filter(x => x.FolderRoles && x.FolderRoles.length > 0)
                .map(x => {
                    // console.log(props.Data.EncFolder + ' / ' + JSON.stringify(x.FolderRoles, null, 4));
                    const matchingRoles = x.FolderRoles.filter(y => y.EncFolder === props.Data.EncFolder && y.EncPage === sysEnums.zero);
                    return matchingRoles.length > 0 ? matchingRoles : null;
                })
                .filter(x => x !== null)
                .flat();

            // console.log(props.Data.Name + ' / ' + JSON.stringify(folderRoles, null, 4));

            return folderRoles;
        });

        const setFolderDel = () => {
            if (props.Data.EncUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '폴더 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            showConfirm('', '폴더를 삭제 합니다.', '삭제하기').then(result => {
                if (result) {
                    const formData = new FormData();
                    formData.append('EncUser', userInfo.value.EncUser);
                    formData.append('EncFolder', props.Data.EncFolder);
                    formData.append('Status', 1);
                    axios
                        .post(apiEnums.CloudTicket.updateFolder, formData)
                        .then(response => {
                            if (response.data === '9999') {
                                showAlert('Alert!', '하위 폴더가 있습니다.', 'PageClip', sysEnums.AlertTypes.Info);
                            } else if (response.data !== '0000') {
                                getUserFolder();
                                sendMessage(null, sysEnums.SignalTypes.Folder);
                            }
                        })
                        .catch(() => {});
                }
            });
        };

        return {
            props,
            sysEnums,
            widgetInfo,
            selectedFolder,
            treeData,
            folderList,
            pageList,
            fileRef,
            trees,
            subTrees,
            filteredFolderRoles,
            isShow,
            cntFolder,
            cntPost,
            cntPage,
            pageCount,
            gotoModal,
            gotoWidget,
            getUserDepartment,
            gotoFolder,
            updateFolderFix,
            updateFolderShare,
            setFolderDel,
        };
    },
};
</script>

<template>
    <div class="p-3 rounded-top placeholder-glow" :class="props.Data.Type === sysEnums.PageCategorys.Folder ? 'bg-secondary' : 'bg-primary'">
        <div class="d-flex gap-1 align-items-center justify-content-end my-n2">
            <i :class="props.Data.Fixed ? 'ri-pushpin-2-fill text-primary' : 'ri-pushpin-line'" class="text-light me-3" @click="updateFolderFix"></i>
            <i
                :class="['text-light me-3', props.Data.Shared ? 'ri-eye-line' : 'ri-eye-off-line']"
                @click="updateFolderShare"
                v-if="props.Data.Type === sysEnums.PageCategorys.Post"
            ></i>

            <i
                class="ri-share-line text-light me-3"
                @click="gotoWidget(props.Data, 'FolderMove', '포스트 폴더로 이동', 'end')"
                v-if="props.Data.Type === sysEnums.PageCategorys.Post"
            ></i>

            <!--<BDropdown variant="link" toggle-class="p-1 py-0 fs-15 arrow-none" menu-class="dropdown-menu-end" :offset="{ alignmentAxis: -130, crossAxis: 0, mainAxis: 10 }">
                <template #button-content>
                    <MoreHorizontalIcon class="icon-sm text-light"></MoreHorizontalIcon>
                </template>
                <BDropdownItem @click="fileRef.setFileData(props.Data)"><i class="ri-image-2-line align-bottom me-2 text-muted"></i>대표이미지</BDropdownItem>
                <BDropdownItem @click="gotoModal(props.Data, 'FolderCreate', '폴더수정')"><i class="ri-edit-2-line align-bottom me-2 text-muted"></i>수정하기</BDropdownItem>
                <BDropdownItem @click="gotoModal(props.Data, 'FolderCreate', '폴더수정')"><i class="ri-file-copy-line align-bottom me-2 text-muted"></i>이동하기</BDropdownItem>
                <BDropdownItem @click="gotoModal(props.Data, 'SetUserRole', '폴더 열람대상 지정하기')"
                    ><i class="ri-share-forward-line align-bottom me-2 text-muted"></i>공유하기
                </BDropdownItem>
                <div class="dropdown-divider"></div>
                <BDropdownItem><i class="ri-bookmark-line align-bottom text-muted me-2"></i>북마크</BDropdownItem>
                <BDropdownItem @click="setFolderDel"><i class="ri-delete-bin-4-line align-bottom text-muted me-2"></i>삭제하기</BDropdownItem>
            </BDropdown>-->
        </div>

        <div class="d-flex align-items-center mt-2">
            <img
                :src="props.Data.Avatar ? $fileUrl + props.Data.Avatar : 'assets/images/no-profile.jpg'"
                class="avatar-xs rounded-circle img-fit border border-3 hand"
                @click="gotoModal(props.Data, 'UserInfo', '사용자정보')"
            />

            <span class="d-flex flex-column text-light ps-2">
                <span class="fs-16">{{ props.Data.UserName }}</span>
                <span>{{ getUserDepartment(props.Data.Position, true) + ' / ' + getUserDepartment(props.Data.Department, false) }}</span>
            </span>
        </div>
    </div>

    <!--{{ JSON.stringify(props.Data) }}-->

    <div class="p-2" @click="selectedFolder = props.Data">
        <div v-if="props.Data.Name" class="mt-2 hand">
            <i class="text-muted me-2" :class="props.Data.Type === sysEnums.PageCategorys.Folder ? 'ri-folder-3-line' : 'ri-space'"></i
            ><span class="fs-16">{{ props.Data.Name }}</span>
        </div>

        <div v-if="props.Data.Description">
            <simplebar data-simplebar style="height: 60px">
                <span class="fs-12 text-muted">{{ props.Data.Description }}</span>
            </simplebar>
        </div>
    </div>

    <BRow class="mt-4 text-center" @click="isShow = !isShow">
        <slot v-if="props.Data.Type === sysEnums.PageCategorys.Folder">
            <BCol cols="4">
                <div class="fs-12 text-muted">FOLDERS</div>
                <span class="fs-30 fw-bold">{{ cntFolder }}</span>
            </BCol>
            <BCol cols="4">
                <div class="fs-12 text-muted">POSTS</div>
                <p class="fs-30 fw-bold">{{ cntPost }}</p>
            </BCol>
            <BCol cols="4">
                <div class="fs-12 text-muted">PAGES</div>
                <p class="fs-30 fw-bold">{{ cntPage }}</p>
            </BCol>
        </slot>

        <slot v-else>
            <BCol cols="6" @click="gotoModal(props.Data, 'SetUserRole', '폴더 열람대상 지정하기')">
                <div class="fs-12 text-muted">USERS</div>
                <p class="fs-30 fw-bold">{{ props.Data.FolderRoles.filter(x => x.Grouped === 2).length }}</p>
            </BCol>
            <BCol cols="6">
                <div class="fs-12 text-muted">PAGES</div>
                <p class="fs-30 fw-bold">{{ pageCount(props.Data.EncFolder) }}</p>
            </BCol>
        </slot>
    </BRow>

    <BCard no-body v-show="!isShow">
        <BCardBody v-if="props.Data.Type === sysEnums.PageCategorys.Folder">
            <simplebar class="p-0" data-simplebar style="height: 220px">
                <AppTreeFolder :model="subTrees"></AppTreeFolder>
            </simplebar>
        </BCardBody>
        <BCardBody v-else>
            <simplebar class="p-0" data-simplebar style="height: 220px">
                <template v-for="x in props.Data.FolderRoles.filter(x => x.Grouped === 2)" :key="x">
                    <div class="d-flex align-items-center pb-2 mb-2 border-bottom">
                        <span class="pe-3">
                            <img :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'" class="avatar-xxs rounded-circle border border-3" />
                        </span>
                        <span class="flex-fill text-muted">{{ x.UserName }}</span>
                        <span class="text-muted">{{ $moment(x.Updated).format('YY.MM.DD') }}</span>
                    </div>
                </template>
            </simplebar>
        </BCardBody>
    </BCard>

    <p class="text-muted mb-1">{{ $moment(props.Data.Created).format('YYYY.MM.DD hh:mm') }} 에 {{ props.Data.Type === sysEnums.PageCategorys.Folder ? '폴더' : '포스트' }} 만듦</p>

    <span class="d-none">
        <FileUploadSingle ref="fileRef" :FileType="sysEnums.FileTypes.Folder"></FileUploadSingle>
    </span>
</template>
