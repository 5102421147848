<script>
import { inject } from 'vue';
import { sysEnums } from '../common/sysEnums';

export default {
    components: {},
    props: {
        title: {
            type: String,
            default: '',
        },
        pageTitle: {
            type: String,
            default: '',
        },
    },
    setup() {
        const widgetInfo = inject('$widgetInfo');

        const { selectedFolder, selectedPage, selectedClips, parentFolderName } = inject('$provideList');

        return {
            sysEnums,
            widgetInfo,
            selectedFolder,
            selectedPage,
            selectedClips,
            parentFolderName,
        };
    },
};
</script>

<template>
    <BRow>
        <BCol cols="12">
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0">
                    {{ !selectedFolder.EncFolder ? 'Home' : '' }}
                    <span class="pe- hand" v-for="folder in parentFolderName(selectedFolder.EncFolder)" :key="folder.EncFolder" @click="folder.clickEvent">
                        {{ folder.name }} >
                    </span>
                </h4>
                <div class="page-title-right">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item">
                            <span>{{ selectedFolder.EncFolder ? selectedFolder.Name : '' }}</span>
                        </li>
                        <!-- <li class="breadcrumb-item active">{{ selectedPage.EncPage ? selectedPage.Title : '' }}</li> -->
                    </ol>
                </div>
            </div>
        </BCol>
    </BRow>
</template>
