<template>

    <div class="mt-4 hand">

        <div class="d-flex mb-4 br-8 align-items-center" :class="pageOption === sysEnums.PageOptions.Default ? 'bg-warning' : 'bg-secondary text-light'"
            @click="setPageOption(sysEnums.PageOptions.Default)">
            <span class="p-4">
                <i class="fs-50" :class="pageOption === sysEnums.PageOptions.Default ? 'ri-pushpin-2-fill' : 'ri-pushpin-line'"></i>
            </span>
            <span>
                <h5 class="mb-1">Page목록에서 기본정렬 사용</h5>
                <div>Page목록에서 맨위에 위치</div>
            </span>
        </div>
        <div class="d-flex mb-4 br-8 align-items-center" :class="pageOption === sysEnums.PageOptions.Role3 ? 'bg-success' : 'bg-secondary text-light'"
            @click="setPageOption(sysEnums.PageOptions.Role3)">
            <span class="p-4">
                <i class="fs-50" :class="pageOption === sysEnums.PageOptions.Role3 ? 'ri-lock-2-fill' : 'ri-lock-2-line'"></i>
            </span>
            <span>
                <h5 class="mb-1">워크스페이스 멤버에 전체 공유</h5>
                <div>나만보기</div>
            </span>
        </div>
        <div class="d-flex mb-4 br-8 align-items-center" :class="pageOption === sysEnums.PageOptions.Role5 ? 'bg-info' : 'bg-secondary text-light'"
            @click="setPageOption(sysEnums.PageOptions.Role5)">
            <span class="p-4">
                <i class="fs-50" :class="pageOption === sysEnums.PageOptions.Role5 ? 'ri-edit-fill' : 'ri-edit-line'"></i>
            </span>
            <span>
                <h5 class="mb-1">워크스페이스 멤버 누구나 수정가능</h5>
                <div>나만 수정가능</div>
            </span>
        </div>
        <div class="d-flex mb-4 br-8 align-items-center" :class="pageOption === sysEnums.PageOptions.Role7 ? 'bg-danger' : 'bg-secondary text-light'"
            @click="setPageOption(sysEnums.PageOptions.Role7)">
            <span class="p-4">
                <i class="fs-50" :class="pageOption === sysEnums.PageOptions.Role7 ? 'ri-chat-private-fill' : 'ri-chat-private-line'"></i>
            </span>
            <span>
                <h5 class="mb-1">기본댓글 사용</h5>
                <div>1:1댓글 (본인 댓글만 보기)</div>
            </span>
        </div>

    </div>

</template>


<script lang="js">
import { ref, inject } from "vue";
import { sysEnums } from "@/common/sysEnums";

export default {
    components: {
    },
    props: {
        Content: String,
        FileType: Number,
        IsBtn: Boolean,
    },
    setup(props) {
        const titles = ref(['', '제목', '설명', '등록일', '상태']);

        const selectedDate = ref('');

        let pageOption = ref(0);

        // const axios = inject("$axios");

        const userInfo = inject('$userInfo');

        const { filteredFolder } = inject("$provideList");

        const setPageOption = (option) => {
            pageOption.value = option;
        }

        const handleDropFolderMove = (encFolder, event) => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            // updateFolder(droppedData.EncFolder, encFolder)
            console.log(encFolder + ' / ' + droppedData.EncFolder)

            // if (droppedData.EncWorkspace) {
            //     updateWorkspace(droppedData.EncWorkspace, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncWorkspace)
            // } else {
            //     updateFolder(droppedData.EncFolder, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncFolder)
            // }
        }


        return {
            props, sysEnums, userInfo,
            titles, filteredFolder,
            selectedDate, pageOption,
            handleDropFolderMove, setPageOption,
        };
    },
};
</script>

<style scoped lang="scss"></style>