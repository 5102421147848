<template>
    <div>
        <component v-if="widgetInfo.targetModal === 'PageOption'" :is="'PageOption'"></component>
        <component v-else-if="widgetInfo.targetModal === 'PageTag'" :is="'PageTag'"></component>
        <component v-else-if="widgetInfo.targetModal === 'PageFile'" :is="'PageFile'"></component>
        <component v-else-if="widgetInfo.targetModal === 'PageSetDate'" :is="'PageSetDate'"></component>
        <component v-else-if="widgetInfo.targetModal === 'PageSetMap'" :is="'PageSetMap'"></component>
        <component v-else-if="widgetInfo.targetModal === 'PageMove'" :is="'PageMove'"></component>
        <component v-else-if="widgetInfo.targetModal === 'PageCreate'" :is="'PageCreate'"></component>
        <component v-else-if="widgetInfo.targetModal === 'PageTrash'" :is="'PageTrash'"></component>
        <component v-else-if="widgetInfo.targetModal === 'PageComment'" :is="'PageComment'"></component>
        <component v-else-if="widgetInfo.targetModal === 'SendFile'" :is="'SendFile'"></component>
        <component v-else-if="widgetInfo.targetModal === 'FolderCreate'" :is="'FolderCreate'"></component>
        <component v-else-if="widgetInfo.targetModal === 'FolderMove'" :is="'FolderMove'"></component>
        <component v-else-if="widgetInfo.targetModal === 'UserInfo'" :is="'UserInfo'"></component>
        <component v-else-if="widgetInfo.targetModal === 'UserInvite'" :is="'UserInvite'"></component>
        <component v-else-if="widgetInfo.targetModal === 'SetUserRole'" :is="'SetUserRole'"></component>
        <component v-else-if="widgetInfo.targetModal === 'FileViewer'" :is="'FileViewer'"></component>
        <component v-else-if="widgetInfo.targetModal === 'ClipViewer'" :is="'ClipViewer'" :url="widgetInfo.dataModal"></component>
        <component
            v-else-if="widgetInfo.targetModal === 'FileUpload'"
            :is="'FileUpload'"
            :FileType="sysEnums.FileTypes.Clip"
            :Content="selectedPage.EncPage"
            :IsBtn="true"
            :IsSelect="true"
        ></component>
        <!-- <component v-else> 이 코드는 사용 금지 무한루프 맥스스택 오류 발생 </component> -->
    </div>
</template>

<script>
import { sysEnums } from '@/common/sysEnums';
import { inject } from 'vue';

import ClipViewer from '@/components/clips/ClipViewer';
import FileUpload from '@/components/files/FileUpload';
import FileViewer from '@/components/files/FileViewer';
import SendFile from '@/components/files/SendFile';
import FolderCreate from '@/components/folders/FolderCreate';
import FolderMove from '../components/folders/FolderMove';
import PageComment from '../components/pages/PageComment';
import PageCreate from '../components/pages/PageCreate';
import PageFile from '../components/pages/PageFile';
import PageMove from '../components/pages/PageMove';
import PageOption from '../components/pages/PageOption';
import PageSetDate from '../components/pages/PageSetDate';
import PageSetMap from '../components/pages/PageSetMap';
import PageTag from '../components/pages/PageTag';
import PageTrash from '../components/pages/PageTrash';
import SetUserRole from '../components/users/SetUserRole';
import UserInfo from '../components/users/UserInfo';
import UserInvite from '../components/users/UserInvite';

export default {
    components: {
        PageOption,
        PageTag,
        PageFile,
        PageSetDate,
        PageSetMap,
        PageMove,
        PageCreate,
        PageTrash,
        PageComment,
        UserInvite,
        SendFile,
        FolderCreate,
        FolderMove,
        SetUserRole,
        UserInfo,
        FileViewer,
        FileUpload,
        ClipViewer,
    },
    setup() {
        const widgetInfo = inject('$widgetInfo');

        const { selectedPage } = inject('$provideList');

        return {
            widgetInfo,
            sysEnums,
            selectedPage,
        };
    },
};
</script>
