<template>
    <apexchart v-if="props.Target === enumTypes.ChartTypes.Company" class="apex-charts" height="350" dir="ltr" :series="customDatalabelsChart.series"
        :options="customDatalabelsChart.chartOptions"></apexchart>
    <apexchart v-if="props.Target === enumTypes.ChartTypes.Paydo || props.Target === enumTypes.ChartTypes.Paysum" class="apex-charts" height="350" dir="ltr"
        :series="basicBarChart.series" :options="basicBarChart.chartOptions">
    </apexchart>
    <apexchart v-if="props.Target === enumTypes.ChartTypes.Payment" class="apex-charts" height="350" dir="ltr" :series="paymentChart.series" :options="paymentChart.chartOptions">
    </apexchart>
</template>

<script lang="js">
import getChartColorsArray from "@/common/getChartColorsArray";

import { onMounted, inject, ref } from "vue";
import { enumTypes } from "@/common/sysEnums";

export default {
    props: {
        Target: { type: Number, required: true },
    },
    setup(props) {
        const basicBarChart = ref({
            series: [{
                data: [],
            },],
            chartOptions: {
                chart: {
                    height: 350,
                    type: "bar",
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                colors: getChartColorsArray('["--vz-primary"]'),
                grid: {
                    borderColor: "#f1f1f1",
                },
                xaxis: {
                    categories: [],
                },
            },
        });

        const customDatalabelsChart = ref({
            series: [{
                data: [],
            },],
            chartOptions: {
                chart: {
                    type: "bar",
                    height: 350,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        barHeight: "100%",
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: "bottom",
                        },
                    },
                },
                colors: getChartColorsArray('["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info", "--vz-primary-rgb, 0.5", "--vz-gray-300", "--vz-danger-rgb, 0.7", "--vz-warning-rgb, 0.5", "--vz-primary", "--vz-info"]'),
                dataLabels: {
                    enabled: true,
                    textAnchor: "start",
                    style: {
                        colors: ["#fff"],
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: false,
                    },
                },
                stroke: {
                    width: 1,
                    colors: ["#fff"],
                },
                xaxis: {
                    categories: [
                        "South Korea",
                        "Canada",
                        "United Kingdom",
                        "Netherlands",
                        "Italy",
                        "France",
                        "Japan",
                        "United States",
                        "China",
                        "India",
                    ],
                },
                yaxis: {
                    labels: {
                        show: false,
                    },
                },
                title: {
                    text: "Custom DataLabels",
                    align: "center",
                    floating: true,
                    style: {
                        fontWeight: 600,
                    },
                },
                subtitle: {
                    text: "Category Names as DataLabels inside bars",
                    align: "center",
                },
                tooltip: {
                    theme: "dark",
                    x: {
                        show: false,
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return "";
                            },
                        },
                    },
                },
            },
        });

        const paymentChart = ref({
            series: [
                {
                    name: "결제금액",
                    type: 'column',
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
                {
                    name: "페이스토리",
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
                {
                    name: "임대인",
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                }
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: "bar",
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                markers: {
                    size: 4,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "smooth",
                },
                labels: [],
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ],
                },
                colors: getChartColorsArray('["--vz-primary", "--vz-primary-rgb, 0.1", "--vz-primary-rgb, 0.50"]'),
                tooltip: {
                    theme: "dark",
                    x: {
                        show: false,
                    },
                    shared: false,
                    y: {
                        formatter: function (val) {
                            return (val).toFixed(0);
                        },
                    },
                },
            },
        });

        const paydos = inject("$paydos");
        const companys = inject("$companys");

        const moment = inject("$moment");

        onMounted(() => {
            try {
                props.Target === enumTypes.ChartTypes.Paydo ? drawChartPaydo() :
                    props.Target === enumTypes.ChartTypes.Paysum ? drawChartPaysum() :
                        props.Target === enumTypes.ChartTypes.Company ? drawChartCompany() :
                            props.Target === enumTypes.ChartTypes.Payment ? drawChartPayment() : drawChartPaysum();
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        });

        const fetchDataPayDo = async () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const data = paydos.value;
                    resolve(data);
                }, 600);
            });
        };

        const fetchDataCompany = async () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const data = companys.value;
                    resolve(data);
                }, 700);
            });
        };

        const drawChartPaydo = async () => {
            try {
                const datas = await fetchDataPayDo();

                basicBarChart.value.series[0].data = [];
                basicBarChart.value.chartOptions.labels = [];

                let obj = {};
                if (Array.isArray(datas)) {
                    datas.forEach(item => {
                        const formattedDate = moment(item.Created).format('YYYY-MM-DD');
                        if (!obj[formattedDate]) {
                            obj[formattedDate] = [];
                        }
                        obj[formattedDate].push(item);
                    })

                    Object.keys(obj).forEach((key) => {
                        basicBarChart.value.series[0].data.push(obj[key].length);
                        basicBarChart.value.chartOptions.labels.push(key);
                    });
                }
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        };

        const drawChartPaysum = async () => {
            try {
                const datas = await fetchDataPayDo();

                basicBarChart.value.series[0].data = [];
                basicBarChart.value.chartOptions.labels = [];
                basicBarChart.value.chartOptions.plotOptions.bar.horizontal = false;

                let obj = {};
                if (Array.isArray(datas)) {
                    datas.forEach(item => {
                        const formattedDate = moment(item.Created).format('YYYY-MM-DD');
                        if (!obj[formattedDate]) {
                            obj[formattedDate] = [];
                        }
                        obj[formattedDate].push(item);
                    })

                    Object.keys(obj).forEach((key) => {
                        const sum = obj[key].reduce((acc, cur) => acc + parseInt(cur.Amt), 0);
                        basicBarChart.value.series[0].data.push(sum);
                        basicBarChart.value.chartOptions.labels.push(key);
                        // console.log(JSON.stringify(sum));
                    });
                }
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        };

        const drawChartCompany = async () => {
            try {
                const datas = await fetchDataCompany();

                customDatalabelsChart.value.series[0].data = [];
                customDatalabelsChart.value.chartOptions.labels = [];

                let obj = {};
                if (Array.isArray(datas)) {
                    datas.forEach(item => {
                        const formattedDate = moment(item.Created).format('YYYY-MM-DD');
                        if (!obj[formattedDate]) {
                            obj[formattedDate] = [];
                        }
                        obj[formattedDate].push(item);
                    })

                    Object.keys(obj).forEach((key) => {
                        customDatalabelsChart.value.series[0].data.push(obj[key].length);
                        customDatalabelsChart.value.chartOptions.labels.push(key);
                    });
                }
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        };

        const drawChartPayment = async () => {
            try {
                const datas = await fetchDataPayDo();

                paymentChart.value.series[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                paymentChart.value.series[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                paymentChart.value.series[2].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                paymentChart.value.chartOptions.labels = [];

                let obj = {};
                datas.forEach(item => {
                    const formattedDate = moment(item.Created).format('YYYY-MM');
                    if (!obj[formattedDate]) {
                        obj[formattedDate] = [];
                    }
                    obj[formattedDate].push(item);
                })

                Object.keys(obj).forEach((key) => {
                    const index = parseInt(moment(key).format('M')) - 1;

                    const dt1 = obj[key].reduce((acc, cur) => {
                        return acc + parseInt(cur.Amt);
                    }, 0);

                    paymentChart.value.series[0].data[index] = dt1;
                    paymentChart.value.chartOptions.labels.push(key);
                });
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        };

        return {
            props, enumTypes,
            basicBarChart, customDatalabelsChart, paymentChart,
        };
    },
};
</script>

<style scoped lang="scss"></style>

