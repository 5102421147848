<template>
    <BRow>
        <BCol xxl="12">
            <h5 class="mb-3">직원 및 파트너 선택하여 페이지 열람대상을 변경하여 주시기 바랍니다.</h5>
            <BCard no-body>
                <!-- <BCardHeader class="align-items-center d-flex">
                    <div class="flex-grow-1 oveflow-hidden">
                        <p class="text-muted text-truncates mb-0">Use <code>card-header-tabs</code> class to create card header tabs.</p>
                    </div>
                </BCardHeader> -->
                <BCardBody>
                    <p>
                        <input type="text" class="form-control" placeholder="열람대상명 또는 연락처 검색" @input="updateSearchEvent" />
                    </p>

                    <table class="table table-striped table-hover text-center">
                        <!-- <thead>
                            <tr class="h-50h bg-light align-middle fw-bold">
                                <th scope="col">번호</th>
                                <th v-for="x in titles" :key="x">{{ x }}</th>
                            </tr>
                        </thead> -->
                        <tbody>
                            <template v-for="(x, i) in filterList" :key="x" @dragover.prevent @drop="handleDropFolderMove(x.EncFolder, $event)">
                                <tr class="h-50h align-middle hand" style="cursor: move" draggable="true" @dragstart="dragStart(x, $event)">
                                    <th class="w-10" scope="row">{{ i + 1 }}</th>
                                    <td class="w-10">
                                        <img
                                            :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'"
                                            class="avatar-sm rounded-circle img-fit"
                                            :class="x.Type > 0 ? '' : 'opacity-25'"
                                        />
                                    </td>
                                    <td>{{ x.UserName + ' / ' + x.LoginId }}</td>
                                    <td class="w-20 hand">
                                        <span v-if="getStatus(x.EncUser)">
                                            <i class="text-danger fs-20 ri-close-line" @click="updateRole(x.EncUser, 0)"></i>
                                        </span>
                                        <span v-else v-for="(y, n) in roleTypes" :key="y" class="pe-4 fs-20">
                                            <i :class="'opacity-50 ' + y.icon" @click="createRole(x.EncUser, n)"></i>
                                        </span>
                                    </td>
                                    <!-- <td class="w-10">
                                        <span class="form-switch">
                                            <input class="form-check-input hand" type="checkbox" role="switch" id="flexSwitchCheckDefault" :checked="x.Status" />
                                        </span>
                                    </td> -->
                                </tr>
                            </template>
                        </tbody>
                    </table>

                    <h6 class="mt-4" @dragover.prevent @drop="handleDropUserAdd($event)">지정된 대상 목록</h6>

                    <span v-if="selectedPage.FolderRoles">
                        <table class="table table-striped table-hover text-center">
                            <tbody>
                                <template v-for="(x, i) in selectedPage.FolderRoles" :key="x" @dragover.prevent @drop="handleDropFolderMove(x.EncFolder, $event)">
                                    <tr class="h-50h align-middle hand" style="cursor: move" draggable="true" @dragstart="dragStart(x, $event)">
                                        <th class="w-10 fs-30 fw-bold text-muted" scope="row">{{ i + 1 }}</th>
                                        <td class="w-10">
                                            <img
                                                :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'"
                                                class="avatar-sm rounded-circle img-fit"
                                                :class="x.Type > 0 ? '' : 'opacity-25'"
                                            />
                                        </td>
                                        <td>
                                            {{ x.UserName + ' / ' + getUserDepartment(x.Position, true) + ' / ' + getUserDepartment(x.Department, false) }}<br />
                                            {{ x.LoginId + ', ' + x.Email }}
                                        </td>
                                        <td class="w-20 hand">
                                            <BDropdown split :text="userInfo.EncUser === x.EncUser ? '오너' : enumTypes.RoleTypes[x.Type].name" variant="primary">
                                                <BDropdownItem v-for="(y, n) in roleTypes" :key="y" @click="updateRole(x.EncUser, n)">{{ y.name }}</BDropdownItem>
                                            </BDropdown>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </span>

                    <!-- {{ JSON.stringify(selectedPage.FolderRoles) }}
                    <hr />
                    {{ JSON.stringify(selectedPage.PageRoles) }} -->

                    <span v-if="selectedPage.PageRoles">
                        <table class="table table-striped table-hover text-center">
                            <tbody>
                                <template v-for="(x, i) in selectedPage.PageRoles" :key="x" @dragover.prevent @drop="handleDropFolderMove(x.EncPage, $event)">
                                    <tr class="h-50h align-middle hand" style="cursor: move" draggable="true" @dragstart="dragStart(x, $event)">
                                        <th class="w-10 fs-30 fw-bold text-muted" scope="row">{{ i + 1 }}</th>
                                        <td class="w-10">
                                            <img
                                                :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'"
                                                class="avatar-sm rounded-circle img-fit"
                                                :class="x.Type > 0 ? '' : 'opacity-25'"
                                            />
                                        </td>
                                        <td>
                                            {{ x.UserName + ' / ' + getUserDepartment(x.Position, true) + ' / ' + getUserDepartment(x.Department, false) }}<br />
                                            {{ x.LoginId + ', ' + x.Email }}
                                        </td>
                                        <td class="w-20 hand">
                                            <BDropdown split :text="userInfo.EncUser === x.EncUser ? '오너' : enumTypes.RoleTypes[x.Type].name" variant="danger">
                                                <BDropdownItem v-for="(y, n) in roleTypes" :key="y" @click="updateRole(x.EncUser, n)">{{ y.name }}</BDropdownItem>
                                            </BDropdown>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </span>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>
</template>

<script lang="js">
import { getUserDepartment } from "@/common/comFuncs";
import { apiEnums, enumTypes, sysEnums } from "@/common/sysEnums";
import { computed, inject, ref } from "vue";

export default {
    props: {
        Content: String,
        FileType: Number,
        IsBtn: Boolean,
    },
    setup(props) {
        // const titles = ref(['', '회원명', '구분']);

        const selectedDept = ref([]);

        const searchEvent = ref(null);

        const roleTypes = Object.values(enumTypes.RoleTypes);

        const axios = inject("$axios");
        const userInfo = inject('$userInfo');

        // const widgetInfo = inject("$widgetInfo");

        const { filteredFolder, selectedFolder, selectedPage, sendMessage, userList, getUserFolder, getUserPage } = inject("$provideList");

        // watch(() => selectedFolder.value.Dept, () => {
        //     getSelectedDept()
        // });

        // onMounted(() => {
        //     getSelectedDept()
        // });

        // const getSelectedDept = () => {
        //     selectedDept.value = [];
        //     if (selectedFolder.value.Dept) {
        //         console.log(selectedFolder.value.Dept);
        //         selectedDept.value = selectedFolder.value.Dept.split(',').map(Number);
        //     }
        // };

        const createRole = (encUser, type) => {
            // console.log('createRole : ' + JSON.stringify(selectedFolder.value) + ' / ' + selectedFolder.value.EncPage + ' / ' + encUser + ' / ' + type)
            const formData = new FormData();
            formData.append('EncFolder', selectedFolder.value.EncFolder || sysEnums.zero);
            formData.append('EncPage', selectedPage.value.EncPage || sysEnums.zero);
            formData.append('EncTopFolder', sysEnums.zero);
            formData.append('EncUser', encUser);
            formData.append('EncOwner', userInfo.value.EncUser);
            formData.append('Type', type);
            formData.append('Group', selectedFolder.value.EncFolder ? 2 : 1);
            axios.post(apiEnums.CloudTicket.createRole, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        selectedFolder.value.EncFolder ? getUserFolder() : getUserPage();
                        sendMessage(null, sysEnums.SignalTypes.Folder);
                        // console.log('createRole response : ' + JSON.stringify(response.data));
                    }
                })
                .catch(() => { });
        };

        const updateRole = (encUser, type) => {
            // console.log('updateRole selectedFolder : ' + JSON.stringify(selectedFolder.value) + ' / ' + encUser)

            let role = [];
            if (selectedFolder?.value?.FolderRoles) {
                role = selectedFolder.value.FolderRoles.find(x => x.EncUser === encUser);
            }

            if (selectedPage?.value?.PageRoles) {
                role = selectedPage.value.PageRoles.find(x => x.EncUser === encUser);
            }

            // console.log(JSON.stringify(role));

            if (role) {
                const formData = new FormData();
                // formData.append('EncFolder', selectedFolder.value.EncFolder || sysEnums.zero);
                formData.append('EncRole', role.EncRole);
                formData.append('EncUser', encUser);
                formData.append('Type', type);

                // if (type === 0) {
                //     formData.append('Status', 1);
                // }

                axios.post(apiEnums.CloudTicket.updateRole, formData)
                    .then((response) => {
                        if (response.data !== '0000') {
                            selectedFolder.value.EncFolder ? getUserFolder() : getUserPage();
                            sendMessage(null, sysEnums.SignalTypes.Folder);
                        }
                    })
                    .catch(() => { });
            }
        }

        const getStatus = (encUser) => {
            if (selectedFolder?.value?.FolderRoles) {
                // console.log(JSON.stringify(selectedFolder.value) + ' / ' + encUser)
                return selectedFolder.value.FolderRoles.some(x => x.EncUser === encUser);
            }

            else if (selectedPage?.value?.PageRoles) {
                // console.log(JSON.stringify(selectedPage.value) + ' / ' + encUser)
                return selectedPage.value.PageRoles.some(x => x.EncUser === encUser);
            }

            return false;
        }

        const dragStart = (data, event) => {
            // 드래그 시작 시 데이터 전달
            const dataString = JSON.stringify(data);
            event.dataTransfer.setData('application/json', dataString);
            // event.dataTransfer.setData('text/plain', data);
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.dropEffect = 'move';
            event.dataTransfer.setDragImage(event.target, 0, 0);
        };

        const handleDropUserAdd = (event) => {
            // 요소를 드롭할 때 실행되는 함수
            event.preventDefault(); // 기본 동작 막음

            // 드롭시 데이터 받음
            // const draggedData = JSON.parse(event.dataTransfer.getData('text/plain'));
            const dataString = event.dataTransfer.getData('application/json');
            const droppedData = JSON.parse(dataString);
            // console.log('drop drop : ' + JSON.stringify(droppedData))

            const formData = new FormData();
            formData.append('EncUser', droppedData.EncUser);
            // formData.append('EncPage', selectedPage.value.EncPage);
            axios.post(apiEnums.CloudTicket.createRoleFolder, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        getUserFolder();
                    }
                })
                .catch(() => { });
        };

        const handleDropFolderMove = (encFolder, event) => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            // updateFolder(droppedData.EncFolder, encFolder)
            console.log(encFolder + ' / ' + droppedData.EncFolder)

            // if (droppedData.EncWorkspace) {
            //     updateWorkspace(droppedData.EncWorkspace, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncWorkspace)
            // } else {
            //     updateFolder(droppedData.EncFolder, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncFolder)
            // }
        }

        const filterList = computed(() => {
            let datas = [];
            if (searchEvent.value && searchEvent.value.target.value !== '') {
                datas = userList.value.filter(user => user.UserName.includes(searchEvent.value.target.value) || user.LoginId.includes(searchEvent.value.target.value));
            }

            // else {
            //     datas = userList.value;
            // }

            return datas;
        });

        const updateSearchEvent = (event) => {
            searchEvent.value = event;
        };

        // const addGroup = (deptNum) => {
        //     if (!selectedFolder.value) {
        //         // showAlert('Alert!', '페이지를 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
        //         return;
        //     }

        //     const formData = new FormData();
        //     formData.append('Group', deptNum);
        //     formData.append('EncFolder', selectedFolder.value.EncFolder);
        //     axios.post(apiEnums.CloudTicket.createRoleFolderGroup, formData)
        //         .then((response) => {
        //             if (response.data !== '0000') {
        //                 getUserFolder();
        //             }
        //         })
        //         .catch(() => { });
        // }

        return {
            props, sysEnums, enumTypes,
            userInfo, filterList, selectedDept,
            filteredFolder,
            selectedFolder, selectedPage, roleTypes,
            handleDropFolderMove, updateSearchEvent,
            createRole, updateRole,
            getStatus, dragStart, handleDropUserAdd,
            getUserDepartment,
        };
    },
};
</script>

<style scoped lang="scss"></style>
