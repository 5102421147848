<template>
    <component v-if="widgetInfo.target === 'UserCreate'" :is="'UserCreate'"></component>
    <component v-else-if="widgetInfo.target === 'AppPageDetail'" :is="'AppPageDetail'"></component>
    <component v-else-if="widgetInfo.target === 'PageDetail'" :is="'PageDetail'"></component>
    <component v-else-if="widgetInfo.target === 'PageOption'" :is="'PageOption'"></component>
    <component v-else-if="widgetInfo.target === 'PageFile'" :is="'PageFile'"></component>
    <component v-else-if="widgetInfo.target === 'PageSetDate'" :is="'PageSetDate'"></component>
    <component v-else-if="widgetInfo.target === 'PageSetMap'" :is="'PageSetMap'"></component>
    <component v-else-if="widgetInfo.target === 'FolderCreate'" :is="'FolderCreate'"></component>
    <component v-else-if="widgetInfo.target === 'FolderMove'" :is="'FolderMove'"></component>
    <!-- <component v-else> 이 코드는 사용 금지 무한루프 맥스스택 오류 발생 </component> -->
</template>

<script>
import { inject } from 'vue';
import AppPageDetail from '../components/apps/AppPageDetail';
import PageDetail from '../components/pages/PageDetail';
import PageFile from '../components/pages/PageFile';
import PageOption from '../components/pages/PageOption';
import PageSetDate from '../components/pages/PageSetDate';
import PageSetMap from '../components/pages/PageSetMap';
import FolderCreate from '@/components/folders/FolderCreate';
import FolderMove from '@/components/folders/FolderMove';
import UserCreate from '../components/users/UserCreate';

export default {
    components: {
        AppPageDetail,
        PageDetail,
        PageOption,
        PageFile,
        PageSetDate,
        PageSetMap,
        FolderCreate,
        FolderMove,
        UserCreate,
    },
    setup() {
        const widgetInfo = inject('$widgetInfo');

        return {
            widgetInfo,
        };
    },
};
</script>

<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.content-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
</style>
