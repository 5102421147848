<script>
import { showAlert } from '@/common/comFuncs';
import { apiEnums, sysEnums } from '@/common/sysEnums';
import { computed, inject, ref } from 'vue';

export default {
    props: {
        model: Object,
    },
    setup(props) {
        const cnt = ref(0);

        const axios = inject('$axios');

        const userInfo = inject('$userInfo');
        const widgetInfo = inject('$widgetInfo');

        const { getUserPage, selectedFolder, selectedPage } = inject('$provideList');

        const isFolder = computed(() => {
            return props.model.children && props.model.children.length;
        });

        const dragStart = (data, event) => {
            event.dataTransfer.setData('text/plain', JSON.stringify(data));
            // console.log('drop started...')
        };

        const handleDropComment = (data, event) => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            updateComment(data, droppedData);
            // console.log(JSON.stringify(data.EncComment) + ' / ' + JSON.stringify(droppedData.EncComment));
        };

        const updateComment = (data, droppedData) => {
            const formData = new FormData();
            formData.append('EncPage', data.EncPage);
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncComment', droppedData.EncComment);
            formData.append('EncReply', data.EncComment);
            axios
                .post(apiEnums.CloudTicket.createComment, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserPage();
                        widgetInfo.value.dataModal = [];
                    }
                })
                .catch(() => {});
        };

        const setTopReply = data => {
            const formData = new FormData();
            formData.append('EncPage', data.EncPage);
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncComment', data.EncComment);
            formData.append('EncReply', data.EncComment);
            axios
                .post(apiEnums.CloudTicket.createComment, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserPage();
                        widgetInfo.value.dataModal = [];
                    }
                })
                .catch(() => {});
        };

        const setComment = () => {
            // widgetInfo.value.content = props.model.Content;
            if (props.model.EncUser === userInfo.value.EncUser) {
                widgetInfo.value.dataModal = props.model;
                gotoModal('PageComment', '댓글수정');
            } else {
                showAlert('Alert!', '본인글만 수정 하실수 있어요!', 'PageClip', sysEnums.AlertTypes.Info);
            }
        };

        const gotoModal = (to, title) => {
            widgetInfo.value.encComment = props.model.EncComment;
            widgetInfo.value.titleModal = title;
            widgetInfo.value.modal = true;
            widgetInfo.value.canvasEdit = false;
            widgetInfo.value.targetModal = to;
        };

        return {
            props,
            sysEnums,
            widgetInfo,
            isFolder,
            cnt,
            selectedFolder,
            selectedPage,
            gotoModal,
            setTopReply,
            setComment,
            dragStart,
            updateComment,
            handleDropComment,
        };
    },
};
</script>

<template>
    <li class="nav-item no_dot text-muted">
        <span v-if="model.Name !== 'COMMENT' && !model.Readed" @dragover.prevent @drop="handleDropComment(model, $event)">
            <div v-if="model.Title" class="hand pt-5 pb-2 pe-4 mb-4 text-danger fw-bold border-bottom" @click="setTopReply(model)">{{ model.Title }}</div>

            <!-- <i v-show="model.EncReply !== sysEnums.zero" class="bx bx-first-page hand" @click="setTopReply(model)"></i> -->

            <div
                v-if="isFolder"
                class="d-flex align-items-top"
                :class="model.EncReply === sysEnums.zero ? 'my-4' : 'mb-4'"
                style="cursor: move"
                draggable="true"
                @dragstart="dragStart(model, $event)"
            >
                <div class="flex-shrink-0">
                    <i v-show="model.EncReply !== sysEnums.zero" class="bx bx-first-page hand me-2" @click="setTopReply(model)"></i>
                    <img :src="model.Avatar ? $fileUrl + model.Avatar : 'assets/images/no-profile.jpg'" class="avatar-xs rounded-circle" />
                </div>
                <div class="flex-grow-1 ms-3">
                    <h5 class="fs-13">
                        {{ model.UserName }}<small class="text-muted ms-2">{{ $moment(model.Updated).format('YYYY-MM-DD hh:mm') }}</small>
                    </h5>
                    <p class="text-muted" @click="setComment">
                        <i v-if="model.Locked" class="ri-lock-line text-dark fs-26"></i>
                        <span v-else v-html="model.Content.replace(/\n/g, '<br>')"></span>
                    </p>
                    <BLink class="badge text-muted bg-light" @click="gotoModal('PageComment', '댓글수정')"><i class="mdi mdi-reply"></i>Reply</BLink>
                </div>
            </div>

            <div
                v-else
                class="d-flex align-items-top"
                :class="model.EncReply === sysEnums.zero ? 'my-4' : 'mb-4'"
                style="cursor: move"
                draggable="true"
                @dragstart="dragStart(model, $event)"
            >
                <div class="flex-shrink-0">
                    <i v-show="model.EncReply !== sysEnums.zero" class="bx bx-first-page hand me-2" @click="setTopReply(model)"></i>
                    <img :src="model.Avatar ? $fileUrl + model.Avatar : 'assets/images/no-profile.jpg'" class="avatar-xs rounded-circle" />
                </div>
                <div class="flex-grow-1 ms-3">
                    <h5 class="fs-13">
                        {{ model.UserName }}<small class="text-muted ms-2">{{ $moment(model.Updated).format('YYYY-MM-DD hh:mm') }}</small>
                    </h5>
                    <p class="text-muted" @click="setComment">
                        <i v-if="model.Locked" class="ri-lock-line text-dark fs-26"></i>
                        <span v-else v-html="model.Content.replace(/\n/g, '<br>')"></span>
                    </p>
                    <BLink class="badge text-muted bg-light" @click="gotoModal('PageComment', '댓글작성')"><i class="mdi mdi-reply"></i> Reply</BLink>
                </div>
            </div>
        </span>

        <ul v-if="isFolder && model.children">
            <TreeComment v-for="x in model.children" :key="x" :model="x"></TreeComment>
        </ul>
    </li>
</template>

<style scoped lang="scss">
.no_dot {
    list-style-type: none !important;
    list-style: none !important;
}

.comment-display {
    white-space: pre-wrap;
    /* CSS property to preserve whitespace and line breaks */
}

ul {
    list-style-type: disc;
    padding-left: 30px;
    margin-left: 0;
}

ul li {
    padding-left: 0;
    // text-indent: -20px;
    margin-left: 30px;
    line-height: 1.7;
}

ul:nth-child(1) {
    margin-top: -30px;
    margin-left: -30px;
}
</style>
