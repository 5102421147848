<script>
import {
    dayLeftPercent,
    daysLeft,
    getConfirmStep,
    getConfirmStepPercent,
    getConfirmUser,
    getReadStatus,
    getUserDepartment,
    showAlert,
    showConfirm,
    timesElapsed,
} from '@/common/comFuncs';
import { apiEnums, enumTypes, sysEnums } from '@/common/sysEnums';
import PageCardImg from '@/components/pages/PageCardImg';
import { inject, reactive, ref } from 'vue';

import aniData1 from '@/components/widgets/forest.json';
// import aniData1 from "@/components/widgets/wired-flat-54-photo.json";
// import aniData1 from "@/components/widgets/house.json";
// import aniData1 from "@/components/widgets/mountain.json";

import Lottie from '@/components/widgets/lottie.vue';

export default {
    components: {
        Lottie,
        PageCardImg,
    },
    setup() {
        const targetPage = ref('');

        const axios = inject('$axios');

        const userInfo = inject('$userInfo');
        const widgetInfo = inject('$widgetInfo');

        const aniOption1 = reactive({ animationData: aniData1 });

        const { selectedFolder, selectedPage, pageList, getUserFolder, getUserPage, getUserClip, gotoModal, sendMessage } = inject('$provideList');

        const gotoPage = async data => {
            selectedPage.value = data;

            if (!selectedPage.value) {
                return;
            }

            if (data && data.Comments) {
                const idx = data.Comments.findIndex(x => x.Checked && x.EncUser === userInfo.value.EncUser);
                if (idx === -1) {
                    const formData = new FormData();
                    formData.append('EncPage', selectedPage.value.EncPage);
                    formData.append('EncUser', userInfo.value.EncUser);
                    formData.append('EncComment', sysEnums.zero);
                    formData.append('EncReply', sysEnums.zero);
                    formData.append('Content', '');
                    formData.append('Readed', 1);

                    try {
                        await axios.post(apiEnums.CloudTicket.createComment, formData);
                    } catch (err) {
                        console.error(err);
                    }
                }
            }

            getUserClip();

            widgetInfo.value.titleDetail = data.Title;
            widgetInfo.value.target = 'PageDetail';
            widgetInfo.value.placeDetail = 'start';
            widgetInfo.value.canvasDetail = true;

            //console.log('gotoPage : '+ JSON.stringify(selectedPage.value))
            // console.log('gotoPage : ' + JSON.stringify(data.Comments));
        };

        const getFirstClip = data => {
            const idx = data.findIndex(x => x.Type === 0);

            if (idx !== -1) {
                let htmlString = data[idx].Content;
                htmlString.length > 90
                    ? (htmlString = htmlString.substring(0, 90) + ' ...')
                    : htmlString.length > 80
                    ? (htmlString = htmlString.substring(0, 80) + ' ...')
                    : htmlString;
                return htmlString.replace(/(<\/?[a-z0-9]+(?:\s+[a-z0-9]+(?:=(?:"[^"]*"|'[^']*'))*|\s*\/?)>)/gi, ' ');
                // return htmlString;
            }
            return '';
        };

        const dragStart = (data, event) => {
            data.Trash
                ? showAlert('Alert!', '휴지통에서는 드래그가 제한 됩니다', 'PageClip', sysEnums.AlertTypes.Info)
                : event.dataTransfer.setData('text/plain', JSON.stringify(data));

            const dragText = document.createElement('div');
            dragText.textContent = data.Title; // Use the specific phrase from data

            // // Style the drag text
            // dragText.style.position = 'absolute';
            // dragText.style.top = '0'; // Position the drag text off-screen initially
            // dragText.style.left = '0';
            dragText.style.width = '200px';
            dragText.style.padding = '10px';
            dragText.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
            dragText.style.border = '1px solid #000';
            dragText.style.borderRadius = '12px';
            dragText.style.color = '#fff';
            dragText.style.pointerEvents = 'none'; // Prevent the drag text from capturing mouse events

            // // Append the drag text to the document body off-screen
            document.body.appendChild(dragText);

            // // Calculate the dimensions of the drag text after it is rendered
            const rect = dragText.getBoundingClientRect();

            // // Set the drag image using the calculated dimensions
            event.dataTransfer.setDragImage(dragText, rect.width / 2, rect.height / 2);

            // Remove the drag text from the document body after a short delay
            setTimeout(() => {
                document.body.removeChild(dragText);
            }, 0);
        };

        const handleDropPageMove = event => {
            console.log(JSON.stringify(event));
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            droppedData.EncPage ? updatePage(targetPage.value, droppedData.EncPage) : null;
            // console.log(JSON.stringify(droppedData.EncPage));
        };

        const updatePage = (encTarget, encPage) => {
            const formData = new FormData();
            formData.append('EncTarget', encTarget);
            formData.append('EncPage', encPage);
            axios
                .post(apiEnums.CloudTicket.updatePage, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserPage();
                    }
                })
                .catch(() => {});
        };

        const updatePageLock = (page, flag) => {
            if (page.EncUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '페이지 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            const formData = new FormData();
            formData.append('EncPage', page.EncPage);
            flag ? formData.append('Locked', 1) : formData.append('Shared', 1);
            axios
                .post(apiEnums.CloudTicket.updatePage, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserPage();
                        sendMessage(null, sysEnums.SignalTypes.Folder);
                    }
                })
                .catch(() => {});
        };

        const updatePageFix = page => {
            if (page.EncUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '페이지 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            if (page) {
                const formData = new FormData();
                formData.append('EncPage', page.EncPage);
                formData.append('Fixed', 1);
                axios
                    .post(apiEnums.CloudTicket.updatePage, formData)
                    .then(response => {
                        if (response.data !== '0000') {
                            getUserFolder();
                            getUserPage();
                        }
                    })
                    .catch(() => {});
            } else {
                console.log('updatePageFix error : ' + JSON.stringify(page));
            }
        };

        const updatePageTrash = data => {
            if (data.EncUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '폴더 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            showConfirm('', data.Trash ? '페이지를 복원 합니다.' : '페이지를 삭제 합니다.', data.Trash ? '복원하기' : '삭제하기').then(result => {
                if (result) {
                    const formData = new FormData();
                    formData.append('EncUser', userInfo.value.EncUser);
                    formData.append('EncPage', data.EncPage);
                    formData.append('Trash', 1);
                    axios
                        .post(apiEnums.CloudTicket.updatePage, formData)
                        .then(response => {
                            if (response.data !== '0000') {
                                getUserPage();
                                widgetInfo.value.dataModal = [];
                            }
                        })
                        .catch(() => {});
                }
            });
        };

        const removeRole = page => {
            showConfirm('', '페이지를 삭제 합니다.', '삭제하기').then(result => {
                if (result) {
                    if (selectedFolder.value) {
                        const role = page.PageRoles.find(x => x.EncUser === page.EncUser);

                        if (!role) {
                            showAlert('', '삭제된 페이지 입니다.', '', sysEnums.AlertTypes.Error);
                            return;
                        }

                        const formData = new FormData();
                        formData.append('EncPage', page.EncPage);
                        formData.append('EncUser', userInfo.value.EncUser);
                        formData.append('EncRole', role.EncRole);
                        axios
                            .post(apiEnums.CloudTicket.updateRole, formData)
                            .then(response => {
                                if (response.data !== '0000') {
                                    getUserFolder();
                                    getUserPage();
                                }
                            })
                            .catch(() => {});
                    }
                }
            });
        };

        return {
            userInfo,
            aniOption1,
            selectedFolder,
            sysEnums,
            enumTypes,
            targetPage,
            widgetInfo,
            pageList,
            dayLeftPercent,
            daysLeft,
            gotoModal,
            getUserDepartment,
            getReadStatus,
            getConfirmStep,
            getConfirmUser,
            getConfirmStepPercent,
            getFirstClip,
            timesElapsed,
            dragStart,
            handleDropPageMove,
            updatePage,
            updatePageLock,
            gotoPage,
            updatePageFix,
            updatePageTrash,
            removeRole,
        };
    },
};
</script>

<template>
    <BRow v-if="(pageList.length > 0 && selectedFolder.EncFolder) || (widgetInfo.search && widgetInfo.search.length > 0)" @dragover.prevent @drop="handleDropPageMove($event)">
        <BCol xl="3" lg="4" sm="6" class="project-card px-3 py-2" v-for="(x, i) of pageList" :key="i">
            <BCard no-body>
                <BCardBody class="p-0" @dragover="targetPage = x.EncPage">
                    <!-- {{ $moment(x.Created).format('YYYY-MM-DD hh:mm') }} -->

                    <div class="hand rounded-4 bg-white placeholder-glow">
                        <div class="d-flex align-items-center p-3">
                            <img
                                :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'"
                                class="avatar-xs rounded-circle img-fit me-2"
                                @click="gotoModal(x, 'UserInfo', '사용자정보')"
                            />
                            <span class="flex-grow-1 d-flex" v-b-tooltip.hover :title="getUserDepartment(x.Position, true) + ' / ' + getUserDepartment(x.Department, false)">
                                <h5 class="fs-14">{{ x.UserName }}</h5>
                                <span class="ms-2">{{ timesElapsed(x.Updated) }}</span>
                            </span>
                            <span v-show="!x.Trash" class="flex-grow-0 text-muted">
                                <i :class="x.Fixed ? 'ri-pushpin-2-fill text-primary' : 'ri-pushpin-line'" @click="updatePageFix(x)"></i>
                                <i :class="x.Locked ? 'ri-lock-line text-danger' : 'ri-lock-unlock-line'" class="ms-3" @click="updatePageLock(x, true)"></i>
                                <i :class="x.Shared ? 'ri-eye-fill text-primary' : 'ri-eye-off-line'" class="mx-3" @click="updatePageLock(x, false)"></i>
                                <!-- <i class="ri-drag-move-2-line me-2" draggable="true" @dragstart="dragStart(x, $event)" style="cursor:move"></i> -->

                                <BDropdown
                                    variant="link"
                                    toggle-class="p-1 py-0 fs-15 arrow-none"
                                    menu-class="dropdown-menu-end"
                                    :offset="{ alignmentAxis: -130, crossAxis: 0, mainAxis: 10 }"
                                >
                                    <template #button-content>
                                        <i class="ri-more-line"></i>
                                    </template>
                                    <BDropdownItem><i class="ri-window-line align-bottom me-2"></i>새탭열기</BDropdownItem>
                                    <BDropdownItem @click="gotoModal(x, 'PageMove', '페이지 복사이동하기')"
                                        ><i class="ri-pencil-line align-bottom me-2 text-muted"></i>복사이동</BDropdownItem
                                    >
                                    <BDropdownItem @click="gotoModal(x, 'PageMove', '페이지 복사이동하기')"
                                        ><i class="ri-share-forward-line align-bottom me-2 text-muted"></i>공유하기
                                    </BDropdownItem>
                                    <div class="dropdown-divider"></div>
                                    <BDropdownItem @click="gotoModal(x, 'PageCreate', '제목수정')"><i class="ri-text align-bottom me-2 text-muted"></i>제목수정</BDropdownItem>
                                    <BDropdownItem @click="updatePageTrash(x)"><i class="ri-delete-bin-4-line align-bottom me-2 text-muted"></i>삭제하기</BDropdownItem>
                                </BDropdown>
                            </span>
                        </div>

                        <div class="position-relative fade-in">
                            <span
                                v-if="x.PageImgs"
                                @click="gotoPage(x)"
                                draggable="true"
                                @dragstart="dragStart(x, $event)"
                                style="height: 320px; cursor: move"
                                class="d-flex align-items-center justify-content-center"
                                :class="{ 'bg-light-subtle': !x.PageImgs[0] }"
                            >
                                <slot v-if="x.PageImgs[0]">
                                    <PageCardImg :imgData="x.PageImgs[0]"></PageCardImg>
                                </slot>
                                <slot v-else>
                                    <div class="p-4 text-center">{{ x.Title }}</div>
                                </slot>
                            </span>
                            <span v-if="x.Tag > 0" class="d-flex align-items-center justify-content-center flex-wrap p-1 position-absolute top-0 start-0 p-2">
                                <BBadge :variant="enumTypes.PageTags[x.Tag].variant" pill>{{ enumTypes.PageTags[x.Tag].name }}</BBadge>
                                <!-- <div class="card ribbon-box border shadow-none left">
                                            <div class="qr-area">
                                                <img :src="$fileUrl + 'QRcode/Page/' + x.EncPage + '.png'
                ? $fileUrl + 'QRcode/Page/' + x.EncPage + '.png' : ''" />
                                            </div>
                                        </div> -->
                            </span>
                        </div>

                        <span v-if="x.Trash" class="text-center" @click="updatePageTrash(x)">
                            <div class="fs-20 mt-4"><i class="ri-loader-3-line me-1 fs-50"></i></div>
                            <div class="fs-20 my-2">복원하기</div>
                        </span>
                        <span v-else>
                            <div class="d-flex align-items-center p-2 text-muted" :class="widgetInfo.loaded ? '' : 'placeholder bg-primary'">
                                <span class="flex-grow-1">
                                    <!-- <span class="me-3">
                                        <i class="ri-heart-2-line me-1"></i><span class="fs-14">{{ x.PageClips.length }}</span>
                                    </span> -->
                                    <span class="me-3" @click="gotoModal(x, 'PageComment', '페이지 댓글보기')">
                                        <i class="ri-chat-smile-3-line me-1"></i><span class="fs-14">{{ x.Comments.length }}</span>
                                    </span>
                                    <span class="me-3" @click="gotoModal(x, 'PageFile', '페이지 파일보기')">
                                        <i class="ri-attachment-2 me-1"></i><span class="fs-14">{{ x.PageFiles.length }}</span>
                                    </span>
                                </span>
                                <span class="flex-grow-0">
                                    <i class="ri-price-tag-3-line me-3" @click="gotoModal(x, 'PageTag', '페이지 업무Tag 및 해시태그 변경하기')"></i>
                                    <i class="ri-bookmark-line me-3" @click="gotoModal(x, 'PageTag', '페이지 업무Tag 및 해시태그 변경하기')"></i>
                                    <i class="ri-share-forward-line" @click="gotoModal(x, 'SetUserRole', '페이지 열람대상 지정하기')"></i>
                                </span>
                            </div>

                            <div class="p-2 my-2">
                                <div class="d-flex">
                                    <h5
                                        class="fs-14 text-body"
                                        :class="widgetInfo.loaded ? '' : 'placeholder'"
                                        @click="gotoPage(x)"
                                        draggable="true"
                                        @dragstart="dragStart(x, $event)"
                                    >
                                        {{ x.Title }}
                                    </h5>

                                    <!-- <span v-if="!getReadStatus(userInfo.EncUser, x.PageRoles)" class="ms-2 red-dot"></span> -->
                                    <span v-if="!getReadStatus(userInfo.EncUser, x.Comments) && userInfo.EncUser !== x.EncUser" class="position-relative ms-2">
                                        <span class="badge border border-light rounded-circle bg-danger p-1">
                                            <span class="visually-hidden">unread messages</span>
                                        </span>
                                    </span>
                                </div>
                                <div
                                    class="text-muted"
                                    :class="widgetInfo.loaded ? '' : 'placeholder'"
                                    v-if="x.PageClips && x.PageClips.length > 0"
                                    v-html="getFirstClip(x.PageClips)"
                                    @click="gotoPage(x)"
                                    style="height: 36px"
                                ></div>

                                <!-- <BRow class="align-items-center mt-2 g-2" @click="gotoModal(x, 'PageSetUser', '페이지 열람대상 지정하기')">
                                    <BCol sm="auto">
                                        <div class="avatar-group">
                                            <template v-for="(y, n) of x.PageRoles" :key="n">
                                                <span v-if="n < 4" class="avatar-group-item">
                                                    <span v-b-tooltip.hover :title="y.UserName + ' / ' + y.RoleNum + ' / ' + y.Status">
                                                        <img :src="y.Avatar ? $fileUrl + y.Avatar : 'assets/images/no-profile.jpg'" class="rounded-circle avatar-xxs"
                                                            :class="y.Status ? '' : 'opacity-25'" />
                                                    </span>
                                                </span>
                                            </template>
                                            <span class="avatar-group-item">
                                                <div class="avatar-xxs">
                                                    <span class="avatar-title rounded-circle bg-info text-white">{{ x.PageRoles.length }}</span>
                                                </div>
                                            </span>
                                        </div>
                                    </BCol>
                                </BRow> -->
                            </div>
                        </span>

                        <div class="px-2 mb-2 text-muted" @click="gotoModal(x, 'PageSetMap', '위치정보')">
                            <i class="ri-map-pin-5-line me-2"></i><span class="me-2" :class="widgetInfo.loaded ? '' : 'placeholder bg-primary'">{{ x.Address1 || '' }}</span>
                            <!-- <span class="me-2">{{ x.Address2 || '' }}</span> -->
                        </div>

                        <!-- 의사결정 템플릿 -->
                        <span v-if="x.Tag && x.Tag === sysEnums.PageTags.Decision">
                            <div class="d-flex p-2 text-muted">
                                <span class="flex-grow-1" :class="widgetInfo.loaded ? '' : 'placeholder bg-primary'">
                                    {{ $moment(x.Created).format('YYYY.MM.DD hh:mm') }} 에 요청
                                </span>
                                <!-- <span class="flex-grow-1" :class="widgetInfo.loaded ? '' : 'placeholder bg-primary'">{{
                                    x.PageRoles ? x.PageRoles[getConfirmStep(x.PageRoles)].UserName : ''
                                }}</span> -->
                                <span v-if="x.PageRoles && x.PageRoles.length > 0" class="flex-shrink-0 text-dark" :class="widgetInfo.loaded ? '' : 'placeholder bg-primary'">
                                    {{ getConfirmUser(x.PageRoles) }}님 진행
                                </span>
                            </div>

                            <div class="p-2 mt-2">
                                <BProgress class="animated-progress custom-progress progress-label" aria-valuemin="0" aria-valuemax="100">
                                    <BProgress-bar v-if="x.PageRoles && getConfirmStepPercent(x.PageRoles) < 90" :value="getConfirmStepPercent(x.PageRoles)" variant="primary">
                                        <div class="label bg-primary">{{ getConfirmStepPercent(x.PageRoles) }}%</div>
                                    </BProgress-bar>
                                    <BProgress-bar v-else value="99" variant="danger">
                                        <div class="label">완료됨</div>
                                    </BProgress-bar>
                                </BProgress>
                            </div>
                        </span>
                        <span v-else>
                            <div class="d-flex p-2 text-muted" @click="gotoModal(x, 'PageSetDate', '종료일 및 시간변경하기')">
                                <span class="flex-grow-1" :class="widgetInfo.loaded ? '' : 'placeholder bg-primary'">
                                    {{ $moment(x.Created).format('YYYY.MM.DD') }}
                                </span>
                                <span class="flex-shrink-0" :class="widgetInfo.loaded ? '' : 'placeholder bg-primary'">
                                    {{ $moment(x.Expired).format('YYYY') === '0000' ? 'open run' : $moment(x.Expired).format('YYYY.MM.DD') }}
                                </span>
                            </div>

                            <div class="p-2 mt-2">
                                <BProgress class="animated-progress custom-progress progress-label" aria-valuemin="0" aria-valuemax="100">
                                    <BProgress-bar v-if="$moment(x.Expired).format('YYYY') === '0000'" value="50" variant="primary">
                                        <div class="label bg-primary">진행중</div>
                                    </BProgress-bar>
                                    <BProgress-bar
                                        v-else-if="daysLeft($moment(x.Expired).format('YYYY-MM-DD')) > 0"
                                        :value="dayLeftPercent($moment(x.Created).format('YYYY-MM-DD'), $moment(x.Expired).format('YYYY-MM-DD'))"
                                        variant="primary"
                                    >
                                        <div class="label">{{ daysLeft($moment(x.Expired).format('YYYY-MM-DD')) + '일 전' }}</div>
                                    </BProgress-bar>
                                    <BProgress-bar v-else value="99" variant="danger">
                                        <div class="label">만료됨</div>
                                    </BProgress-bar>
                                </BProgress>
                            </div>
                        </span>
                    </div>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>

    <BRow v-else>
        <BCol class="project-card">
            <BCard no-body class="border-0">
                <BCardBody class="pt-5 bg-app">
                    <div class="mt-5 text-center">
                        <h4 class="pt-5">등록된 페이지가 없습니다</h4>
                        <h4 class="fs-10">There are no registered pages</h4>
                        <p>
                            <Lottie colors="primary:#25a0e2,secondary:#00bd9d" trigger="loop" :options="aniOption1" :height="240" :width="240"></Lottie>
                        </p>
                    </div>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>
</template>

<style>
.draggable-item {
    cursor: pointer;
    display: inline-block;
    padding: 5px 10px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
}

.drop-area {
    cursor: pointer;
    display: inline-block;
    padding: 5px 10px;
    margin: 5px;
    border: 1px dashed #ccc;
    border-radius: 5px;
}

.red-dot {
    width: 12px;
    height: 12px;
    object-fit: contain;
    border-radius: 50%;
    background-color: #ff0000;
    box-shadow: #666 2px 2px 5px;
}
</style>
