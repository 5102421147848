<template>
    <div v-show="widgetInfo.selectedFile">
        <div class="d-flex align-items-center hand">
            <h5
                class="flex-fill"
                @click="
                    widgetInfo.selectedFile = null;
                    widgetInfo.modalSize = 'lg';
                "
            >
                {{ fileName }}
            </h5>
            <span
                @click="
                    widgetInfo.selectedFile = null;
                    widgetInfo.modalSize = 'lg';
                "
            >
                <i class="ri-arrow-up-s-line"></i> 미리보기 닫기
                <!-- <i class="ri-subtract-line"></i> -->
            </span>
        </div>

        <!-- <input type="file" @change="onFileChange" :accept="acceptedFileTypes" /> -->
        <div v-if="isPdf && widgetInfo.selectedFile">
            <iframe :src="$fileUrl + widgetInfo.selectedFile" width="100%" height="620px"></iframe>
        </div>

        <div v-if="isExcel && excelData.length">
            <table>
                <thead>
                    <tr>
                        <th v-for="header in excelHeaders" :key="header">{{ header }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in excelData" :key="index">
                        <td v-for="(cell, i) in row" :key="i">{{ cell }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- DOCX 처리 파트 추가 -->
        <div v-if="isDocx && widgetInfo.selectedFile">
            <simplebar class="p-0" data-simplebar style="height: 620px">
                <div v-html="widgetInfo.selectedFile"></div>
            </simplebar>
        </div>
    </div>

    <div class="mt-4">
        <div class="text-center">
            <div class="profile-user position-relative d-inline-block mx-auto mb-2 hand" @click="selectImage">
                <!-- <img src="@/assets/images/users/user-dummy-img.jpg" class="rounded-circle avatar-lg img-thumbnail user-profile-image" alt="user-profile-image"> -->
                <i class="ri-file-line fs-70 rounded-circle img-thumbnail p-3"></i>
            </div>
            <h5 class="fs-md">Add File</h5>
        </div>

        <FileUpload ref="fileRef" :FileType="sysEnums.FileTypes.File" :Content="selectedPage.EncPage" :IsBtn="true"></FileUpload>

        <!-- <div class="overflow-y-scroll" style="height:480px;"> -->

        <table class="table table-striped table-hover text-center">
            <thead>
                <tr class="h-50h bg-light align-middle fw-bold">
                    <th scope="col">번호</th>
                    <th v-for="x in titles" :key="x">{{ x }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="h-50h align-middle hand"
                    v-for="(x, i) in getPageFile()"
                    :key="x"
                    style="cursor: move"
                    draggable="true"
                    @dragstart="dragStart(x, $event)"
                    @dragover.prevent
                    @drop="handleDropFolderMove(x.EncFolder, $event)"
                >
                    <th class="w-10" scope="row">{{ i + 1 }}</th>
                    <td>
                        <!-- <img :src="x.AvatarPath ? $fileUrl + x.AvatarPath : 'assets/images/no-profile.jpg'"
class="rounded-circle-36"
:class="x.Status ? '' : 'opacity-25'" /> -->
                        <!-- {{ getMenuFolder(x.EncFolder).length }} -->
                    </td>
                    <td class="text-start" v-text="x.FileName" @click="acceptedFileTypes(x)"></td>
                    <td class="w-10" v-text="(x.FileSize / 1000000).toFixed(1) + 'Mb'"></td>
                    <td class="w-10" v-if="!$store.state.isMobile" v-text="$moment(x.Created).format('YY.MM.DD')"></td>
                    <!-- <td class="w-10">
                            <span class="form-switch">
                                <input class="form-check-input hand" type="checkbox" role="switch" id="flexSwitchCheckDefault" :checked="x.Status" />
                            </span>
                        </td> -->
                </tr>
            </tbody>
        </table>

        <!-- </div> -->
    </div>
</template>

<script lang="js">
import { sysEnums } from "@/common/sysEnums";
import { getCurrentInstance, inject, ref } from "vue";

import FileUpload from "@/components/files/FileUpload";
import simplebar from 'simplebar-vue';

import mammoth from 'mammoth';
import * as XLSX from 'xlsx';

export default {
    props: {
        Content: String,
        FileType: Number,
        IsBtn: Boolean,
    },
    components: {
        FileUpload,
        simplebar
    },
    setup(props) {
        const titles = ref(['', '파일명', '사이즈', '등록일']);

        const fileRef = ref(null);

        const fileName = ref(null);
        const excelHeaders = ref([]);
        const excelData = ref([]);

        const isPdf = ref(false);
        const isExcel = ref(false);
        const isDocx = ref(false); // DOCX 파일인지 여부

        const { proxy } = getCurrentInstance(); // Get the component instance
        const fileUrl = proxy.$fileUrl;

        const widgetInfo = inject("$widgetInfo");

        const userInfo = inject('$userInfo');

        const { filteredFolder, selectedPage } = inject("$provideList");

        const handleDropFolderMove = (encFolder, event) => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            // updateFolder(droppedData.EncFolder, encFolder)
            console.log(encFolder + ' / ' + droppedData.EncFolder)

            // if (droppedData.EncWorkspace) {
            //     updateWorkspace(droppedData.EncWorkspace, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncWorkspace)
            // } else {
            //     updateFolder(droppedData.EncFolder, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncFolder)
            // }
        }

        const selectImage = () => {
            fileRef.value.handleSelectImage();
        }

        const getPageFile = () => {
            const datas = selectedPage.value.PageFiles

            // console.log('getPageFile : ' + JSON.stringify(datas));

            if (datas && datas.length > 0) {
                const datas1 = datas.filter(x => x.Category === sysEnums.FileTypes.File)
                return datas1
            } else {
                return null
            }
        }

        const acceptedFileTypes = (file) => {
            fileName.value = file.FileName;
            const fileExtension = fileName.value.split('.').pop().toLowerCase();

            isPdf.value = fileExtension === 'pdf';
            isExcel.value = ['xlsx', 'xls'].includes(fileExtension);
            isDocx.value = fileExtension === 'docx';

            onFileChange(file);
        }

        const onFileChange = async (file) => {
            widgetInfo.value.modalSize = 'xl';

            try {
                if (isPdf.value) {
                    // console.log('PDF File:', file);
                    widgetInfo.value.selectedFile = file.FilePath;
                } else if (isExcel.value) {
                    const response = await fetch(fileUrl + file.FilePath);

                    if (!response.ok) throw new Error('Failed to fetch Excel file');

                    const data = new Uint8Array(await response.arrayBuffer());
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    excelHeaders.value = jsonData[0];
                    excelData.value = jsonData.slice(1);
                } else if (isDocx.value) {
                    const response = await fetch(fileUrl + file.FilePath);

                    if (!response.ok) throw new Error('Failed to fetch DOCX file');

                    const arrayBuffer = await response.arrayBuffer();
                    const result = await mammoth.convertToHtml({ arrayBuffer });
                    widgetInfo.value.selectedFile = result.value;
                } else {
                    alert(`Unsupported file type: ${fileName.value}`);
                }
            } catch (error) {
                console.error('Error processing file:', error);
            }
        }


        return {
            props, sysEnums, userInfo, fileRef, fileName,
            titles, filteredFolder, selectedPage,
            widgetInfo,
            isPdf, isExcel, isDocx, excelHeaders, excelData,
            acceptedFileTypes,
            handleDropFolderMove, selectImage, getPageFile,
        };
    },
};
</script>

<style scoped lang="scss"></style>
