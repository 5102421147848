<template>
    <!-- <div class="d-flex justify-content-end">
        <div class="w-50 p-4 pt-0">
            <div class="align-items-center mb-2">
                <select class="form-select h-50h me-2 w-100" aria-label="Default select example" v-model="fileType" @change="filterFileList">
                    <option value="null" selected>전체보기</option>
                    <option v-for="x in fileTypes" :key="x" :value="x" :text="enumTypes.FileTypes[x]"></option>
                </select>
            </div>
        </div>
    </div> -->

    <BRow>
        <BCol lg="12">
            <BTabs nav-class="mb-3" pills justified>
                <BTab class="nav-item pt-4">
                    <template #title>
                        <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                        <span class="d-none d-md-inline-block me-3">Selected</span>
                        <BBadge variant="primary" pill>{{ selectedFolder && selectedFileList.length > 0 ? selectedFileList.length : fileList.length }}</BBadge>
                    </template>

                    <BRow v-if="selectedFolder && selectedFolder.EncFolder && selectedFileList.length > 0">
                        <BCol lg="6" v-for="x in selectedFileList" :key="x">
                            <BCard no-body>
                                <BCardBody>
                                    <h5 class="position-absolute p-2 m-2 bg-light opacity-50 rounded-pill" v-text="x.ClipNum"></h5>

                                    <img
                                        :src="x.Content ? $fileUrl + x.Content : 'assets/images/no_image.png'"
                                        class="rounded-2 img-fit hand"
                                        @click="removeClip(x)"
                                        style="height: 260px"
                                    />

                                    <div class="d-flex">
                                        <span v-text="enumTypes.ClipTypes[x.Type]" class="flex-fill"></span>
                                        <span v-text="$moment(x.Created).format('YY.MM.DD')"></span>
                                        <span class="ps-3">
                                            <div class="form-switch">
                                                <input class="form-check-input hand" type="checkbox" role="switch" :checked="x.Status" disabled />
                                            </div>
                                        </span>
                                    </div>
                                </BCardBody>
                            </BCard>
                        </BCol>
                    </BRow>

                    <BRow v-else>
                        <BCol lg="3" v-for="(x, i) in fileList" :key="x">
                            <BCard no-body>
                                <BCardBody>
                                    <img
                                        :src="x.Category > 9 ? $fileUrl + x.FilePath : 'assets/images/no_image.png'"
                                        class="rounded-2 img-fit"
                                        @click="removeFile(x, i)"
                                        style="height: 260px"
                                    />

                                    <div class="d-flex">
                                        <span v-text="enumTypes.FileTypes[x.Category]" class="flex-fill"></span>
                                        <span v-text="$moment(x.Created).format('YY.MM.DD')"></span>
                                        <span class="ps-3">
                                            <div class="form-switch">
                                                <input class="form-check-input hand" type="checkbox" role="switch" :checked="x.Status" disabled />
                                            </div>
                                        </span>
                                    </div>
                                </BCardBody>
                            </BCard>
                        </BCol>
                    </BRow>
                </BTab>

                <BTab class="nav-item pt-4">
                    <template #title>
                        <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                        <span class="d-none d-md-inline-block me-3">Clip Images</span>
                        <BBadge variant="primary" pill>{{ clipList.length }}</BBadge>
                    </template>

                    <BRow>
                        <BCol xl="3" lg="4" sm="6" v-for="x in clipList" :key="x">
                            <BCard v-if="x.Type === sysEnums.ClipTypes.Image" no-body>
                                <BCardBody>
                                    <h5 class="position-absolute p-2 m-2 bg-light opacity-50 rounded-pill" v-text="x.ClipNum"></h5>

                                    <img
                                        :src="x.Content ? $fileUrl + x.Content : 'assets/images/no_image.png'"
                                        class="rounded-2 img-fit hand"
                                        @click="removeClip(x)"
                                        style="height: 260px"
                                    />

                                    <div class="d-flex">
                                        <span v-text="enumTypes.ClipTypes[x.Type]" class="flex-fill"></span>
                                        <span v-text="$moment(x.Created).format('YY.MM.DD')"></span>
                                        <span class="ps-3">
                                            <div class="form-switch">
                                                <input class="form-check-input hand" type="checkbox" role="switch" :checked="x.Status" disabled />
                                            </div>
                                        </span>
                                    </div>
                                </BCardBody>
                            </BCard>

                            <BCard v-else no-body>
                                <BCardBody>
                                    <h5 class="text-center">{{ x.ClipNum }}</h5>

                                    <div class="d-flex">
                                        <span v-text="enumTypes.ClipTypes[x.Type]" class="flex-fill"></span>
                                        <span v-text="$moment(x.Created).format('YY.MM.DD')"></span>
                                        <span class="ps-3">
                                            <div class="form-switch">
                                                <input class="form-check-input hand" type="checkbox" role="switch" :checked="x.Status" disabled />
                                            </div>
                                        </span>
                                    </div>
                                </BCardBody>
                            </BCard>
                        </BCol>
                    </BRow>
                </BTab>
            </BTabs>
        </BCol>
    </BRow>
</template>

<script lang="js">
import { showConfirm } from "@/common/comFuncs";
import { apiEnums, enumTypes, sysEnums } from "@/common/sysEnums";
import { computed, inject, reactive, ref, watch } from 'vue';

export default {
    components: {
    },
    props: {
        Data: Object,
        Title: Array,
    },
    setup() {
        const fileType = ref(null);

        // const trees = ref({ Name: 'FOLDER', children: [] });

        const fileTypes = reactive(sysEnums.FileCategory);

        const files = inject("$files");

        const axios = inject("$axios");

        const userInfo = inject("$userInfo");
        const widgetInfo = inject("$widgetInfo");

        const { treeData, clipList, fileList, selectedFolder } = inject("$provideList");

        watch(userInfo, (newVal) => {
            if (newVal) {
                getClipConsole();
            }
        });


        // 선택된 포스트만 필터링
        const selectedFileList = computed(() => {
            let fileList = [];

            if (!Array.isArray(clipList.value)) {
                return fileList;
            }

            // clipsByPage 생성
            const clipsByPage = clipList.value
                .filter(z => z.Type === sysEnums.ClipTypes.Image)
                .reduce((acc, z) => {
                    if (!acc[z.EncPage]) {
                        acc[z.EncPage] = [];
                    }
                    acc[z.EncPage].push(z);
                    return acc;
                }, {});

            // 필터 조건 정의
            const filterCondition = (item) => {
                if (selectedFolder.value.Shared) {
                    // 공유시 전체 보이기
                    return item.EncFolder === selectedFolder.value.EncFolder || item.children.some(filterCondition);
                } else {
                    // 공유 해제시 내 폴더가 보여야 편집 가능
                    return item.EncUser === userInfo.value.EncUser && (item.EncFolder === selectedFolder.value.EncFolder || item.children.some(filterCondition));
                }
            };

            // 필터링된 children 수집
            const collectFilteredChildren = (folder) => {
                let result = [];

                if (filterCondition(folder)) {
                    result.push(folder);
                }

                if (folder.children && folder.children.length > 0) {
                    for (const child of folder.children) {
                        result = result.concat(collectFilteredChildren(child));
                    }
                }

                return result;
            };

            // selectedFolder.value.EncFolder가 존재하면
            if (selectedFolder.value.EncFolder) {
                const filteredChildren = collectFilteredChildren(treeData.value);
                for (const folder of filteredChildren) {
                    if (folder.FolderRoles && folder.FolderRoles.length > 0) {
                        for (const role of folder.FolderRoles) {
                            if (clipsByPage[role.EncPage]) {
                                fileList.push(...clipsByPage[role.EncPage]);
                            }
                        }
                    }
                }
            }

            // console.log(JSON.stringify(fileList, null, 4));

            return fileList;
        });

        // 재귀함수 적용
        // const selectedFileList123 = computed(() => {
        //     let fileList = [];

        //     if (!Array.isArray(clipList.value)) {
        //         return fileList;
        //     }

        //     // clipsByPage 생성
        //     const clipsByPage = clipList.value
        //         .filter(z => z.Type === sysEnums.ClipTypes.Image)
        //         .reduce((acc, z) => {
        //             if (!acc[z.EncPage]) {
        //                 acc[z.EncPage] = [];
        //             }
        //             acc[z.EncPage].push(z);
        //             return acc;
        //         }, {});

        //     // 폴더 및 하위 폴더의 EncFolder를 재귀적으로 수집하는 함수
        //     const collectFoldersAndChildren = (folder) => {
        //         let folders = [folder.EncFolder];

        //         if (folder.children && folder.children.length > 0) {
        //             for (const child of folder.children) {
        //                 folders = folders.concat(collectFoldersAndChildren(child));
        //             }
        //         }

        //         return folders;
        //     };

        //     // 필터 조건 정의
        //     const filterCondition = (folder) => {
        //         if (selectedFolder.value.Shared) {
        //             // 공유시 전체 보이기
        //             const allFolders = collectFoldersAndChildren(selectedFolder.value);
        //             return allFolders.includes(folder.EncFolder);
        //         } else {
        //             // 공유 해제시 내 폴더가 보여야 편집 가능
        //             if (folder.EncUser !== userInfo.value.EncUser) {
        //                 return false;
        //             }
        //             const allFolders = collectFoldersAndChildren(selectedFolder.value);
        //             return allFolders.includes(folder.EncFolder);
        //         }
        //     };

        //     // 선택된 폴더와 하위 폴더들 수집
        //     const collectFolders = (folder) => {
        //         let folders = [];

        //         if (filterCondition(folder)) {
        //             folders.push(folder);
        //         }

        //         if (folder.children && folder.children.length > 0) {
        //             for (const child of folder.children) {
        //                 folders = folders.concat(collectFolders(child));
        //             }
        //         }

        //         return folders;
        //     };

        //     // selectedFolder.value.EncFolder가 존재하면
        //     if (selectedFolder.value.EncFolder) {
        //         const foldersToProcess = collectFolders(treeData.value);
        //         for (const folder of foldersToProcess) {
        //             if (folder.FolderRoles && folder.FolderRoles.length > 0) {
        //                 for (const role of folder.FolderRoles) {
        //                     if (clipsByPage[role.EncPage]) {
        //                         fileList.push(...clipsByPage[role.EncPage]);
        //                     }
        //                 }
        //             }
        //         }
        //     }

        //     return fileList;
        // });


        // const selectedFileListMap = computed(() => {
        //     let fileList = [];

        //     if (!Array.isArray(clipList.value)) {
        //         return fileList;
        //     }

        //     // clipsByPage 생성
        //     const clipsByPage = clipList.value
        //         .filter(z => z.Type === sysEnums.ClipTypes.Image)
        //         .reduce((acc, z) => {
        //             if (!acc[z.EncPage]) {
        //                 acc[z.EncPage] = [];
        //             }
        //             acc[z.EncPage].push(z);
        //             return acc;
        //         }, {});

        //     // 캐시 초기화 (selectedFolder 값이 변경될 때)
        //     const folderMemo = new Map();

        //     // 폴더 및 하위 폴더의 EncFolder를 재귀적으로 수집하는 함수 (Memoization 적용)
        //     const collectFoldersAndChildren = (folder) => {
        //         if (folderMemo.has(folder.EncFolder)) {
        //             return folderMemo.get(folder.EncFolder);
        //         }

        //         let folders = [folder.EncFolder];

        //         if (folder.children && folder.children.length > 0) {
        //             for (const child of folder.children) {
        //                 folders = folders.concat(collectFoldersAndChildren(child));
        //             }
        //         }

        //         folderMemo.set(folder.EncFolder, folders);
        //         return folders;
        //     };

        //     // 필터 조건 정의
        //     const filterCondition = (folder) => {
        //         if (selectedFolder.value.Shared) {
        //             // 공유시 전체 보이기
        //             const allFolders = collectFoldersAndChildren(selectedFolder.value);
        //             return allFolders.includes(folder.EncFolder);
        //         } else {
        //             // 공유 해제시 내 폴더가 보여야 편집 가능
        //             if (folder.EncUser !== userInfo.value.EncUser) {
        //                 return false;
        //             }
        //             const allFolders = collectFoldersAndChildren(selectedFolder.value);
        //             return allFolders.includes(folder.EncFolder);
        //         }
        //     };

        //     // 선택된 폴더와 하위 폴더들 수집
        //     const collectFolders = (folder) => {
        //         let folders = [];

        //         if (filterCondition(folder)) {
        //             folders.push(folder);
        //         }

        //         if (folder.children && folder.children.length > 0) {
        //             for (const child of folder.children) {
        //                 folders = folders.concat(collectFolders(child));
        //             }
        //         }

        //         return folders;
        //     };

        //     // selectedFolder.value.EncFolder가 존재하면
        //     if (selectedFolder.value.EncFolder) {
        //         const foldersToProcess = collectFolders(treeData.value);
        //         for (const folder of foldersToProcess) {
        //             if (folder.FolderRoles && folder.FolderRoles.length > 0) {
        //                 for (const role of folder.FolderRoles) {
        //                     if (clipsByPage[role.EncPage]) {
        //                         fileList.push(...clipsByPage[role.EncPage]);
        //                     }
        //                 }
        //             }
        //         }
        //     }

        //     return fileList;
        // });


        // folderList로 필터링시 재귀함수로 적용
        // const selectedFileList345 = computed(() => {
        //     let fileList = [];

        //     if (!Array.isArray(clipList.value)) {
        //         return fileList;
        //     }

        //     // clipsByPage 생성
        //     const clipsByPage = clipList.value
        //         .filter(z => z.Type === sysEnums.ClipTypes.Image)
        //         .reduce((acc, z) => {
        //             if (!acc[z.EncPage]) {
        //                 acc[z.EncPage] = [];
        //             }
        //             acc[z.EncPage].push(z);
        //             return acc;
        //         }, {});

        //     // 재귀 함수 정의
        //     const collectFolderRoles = (folder) => {
        //         if (folder.FolderRoles && folder.FolderRoles.length > 0) {
        //             for (const role of folder.FolderRoles) {
        //                 if (clipsByPage[role.EncPage]) {
        //                     fileList.push(...clipsByPage[role.EncPage]);
        //                 }
        //             }
        //         }

        //         if (folder.children && folder.children.length > 0) {
        //             for (const child of folder.children) {
        //                 collectFolderRoles(child);
        //             }
        //         }
        //     };

        //     // 재귀적으로 필터링된 children 수집
        //     const collectFilteredChildren = (folder, filterCondition) => {
        //         let result = [];

        //         if (filterCondition(folder)) {
        //             result.push(folder);
        //         }

        //         if (folder.children && folder.children.length > 0) {
        //             for (const child of folder.children) {
        //                 result = result.concat(collectFilteredChildren(child, filterCondition));
        //             }
        //         }

        //         return result;
        //     };

        //     // 필터 조건 정의
        //     const filterCondition = (item) => {
        //         if (selectedFolder.value.Shared) {
        //             // 공유시 전체 보이기
        //             return item.EncFolder === selectedFolder.value.EncFolder || item.children.some(child => filterCondition(child));
        //         } else {
        //             // 공유 해제시 내 폴더가 보여야 편집 가능
        //             return item.EncUser === userInfo.value.EncUser && (item.EncFolder === selectedFolder.value.EncFolder || item.children.some(child => filterCondition(child)));
        //         }
        //     };

        //     // selectedFolder.value.EncFolder가 존재하면
        //     if (selectedFolder.value.EncFolder) {
        //         const filteredChildren = collectFilteredChildren(treeData.value, filterCondition);
        //         for (const folder of filteredChildren) {
        //             collectFolderRoles(folder);
        //         }
        //     }

        //     return fileList;
        // });

        // const getFilteredData = () => {
        //     const lists = [];
        //     const items = {};

        //     // Ensure selectedPage and its Comments are properly checked
        //     if (selectedFolder.value && selectedFolder.value.EncFolder) {
        //         // console.log('selectedFolder selected')

        //         const filteredPage = pages.value.filter(x => x.EncFolder === selectedFolder.value.EncFolder)
        //         if (filteredPage && filteredPage.length > 0) {
        //             filteredPage.forEach(item => {
        //                 if (item.Comments && item.Comments.length > 0) {
        //                     let titleAdded = false;
        //                     const modifiedComments = item.Comments.map(comment => {
        //                         if (!titleAdded && comment.EncReply === sysEnums.zero) {
        //                             titleAdded = true;
        //                             return { ...comment, Title: item.Title };
        //                         }
        //                         return comment;
        //                     });
        //                     lists.push(...modifiedComments);
        //                 }
        //             });
        //         }
        //     }

        //     else if (selectedPage.value && !selectedPage.value.Comments) {
        //         // console.log('selectedPage selected')

        //         if (pages.value && pages.value.length > 0) {
        //             pages.value.forEach(item => {
        //                 if (item.Comments && item.Comments.length > 0) {
        //                     let titleAdded = false;
        //                     const modifiedComments = item.Comments.map(comment => {
        //                         if (!titleAdded && comment.EncReply === sysEnums.zero) {
        //                             titleAdded = true;
        //                             return { ...comment, Title: item.Title };
        //                         }
        //                         return comment;
        //                     });
        //                     lists.push(...modifiedComments);
        //                 }
        //             });
        //         }
        //     }
        // }

        const removeClip = (file) => {
            showConfirm('', '클립 이미지를 삭제 합니다?', '삭제하기').then((result) => {
                if (result) {
                    const formData = new FormData();
                    formData.append('EncClip', file.EncClip);
                    axios.post(apiEnums.CloudTicket.removeImageClip, formData)
                        .then((response) => {
                            if (response.data !== '0000') {
                                const idx = clipList.value.findIndex(clip => clip.EncClip === file.EncClip)
                                if (idx > -1) clipList.value.splice(idx, 1);
                            }
                        })
                        .catch(() => { });
                }
            });
        }

        const removeFile = (file, index) => {
            showConfirm('', '등록된 이미지를 삭제 합니다?', '삭제하기').then((result) => {
                if (result) {
                    const params = new URLSearchParams();
                    params.append('FileNum', file.FileNum);
                    axios.post(apiEnums.CloudTicket.removeFile, null, { params })
                        .then((response) => {
                            if (response.data.result === "1000") {
                                fileList.value.splice(index, 1);
                            }
                        })
                        .catch(() => { });
                }
            });
        }

        const getClipConsole = () => {
            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            axios.post(apiEnums.CloudTicket.getClipConsole, formData)
                .then((response) => {
                    if (response.data !== "0000") {
                        // console.log(JSON.stringify(response.data));
                        clipList.value = response.data;
                    }
                })
                .catch(() => { });
        }

        return {
            title: "Files",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "FileList",
                    active: true,
                },
            ],
            clipList, fileList,
            sysEnums, enumTypes, fileTypes,
            files, fileType, widgetInfo,
            selectedFolder, selectedFileList,
            removeFile, removeClip, getClipConsole,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable='true'] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
