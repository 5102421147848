<script>
import { ref } from 'vue';

export default {
    props: {
        imgData: Object,
    },
    setup(props) {
        const imageHeight = ref(0);

        const checkImageHeight = event => {
            const imgElement = event.target;
            imageHeight.value = imgElement.naturalHeight;
        };

        return {
            props,
            imageHeight,
            checkImageHeight,
        };
    },
    components: {},
};
</script>

<template>
    <!-- --- {{ imageHeight }} -- -->

    <img
        :class="props.imgData && imageHeight <= 300 ? 'img-contain' : imageHeight > 300 ? 'img-fit' : 'p-4'"
        :src="$fileUrl + props.imgData.Content"
        style="height: 320px"
        @load="checkImageHeight"
        draggable="false"
    />

    <!-- <img
        :class="x.PageImgs[0] && imageHeight <= 320 ? 'img-contain' : x.PageImgs[0] && imageHeight > 320 ? 'img-fit' : 'p-4'"
        :src="x.PageImgs[0] ? $fileUrl + x.PageImgs[0].Content : 'assets/images/imageclip.svg'"
        :style="x.PageImgs[0] ? 'height: 320px' : 'max-height: 42%'"
        @load="checkImageHeight"
        draggable="false"
    /> -->
</template>

<style></style>
