<template>
    <div class="position-relative" @mouseleave="handleUpdate">
        <editor-content :editor="editor" />

        <!-- <span v-show="props.modelValue.isActive" class="position-absolute top-0 end-0">
            <span class="p-2 text-light rounded-circle" :class="widgetInfo.locked ? 'bg-danger' : 'bg-dark'">
                <i :class="widgetInfo.locked ? 'ri-lock-line' : 'ri-lock-unlock-line'" @click="widgetInfo.locked = !widgetInfo.locked"></i>
            </span>
            <span class="p-2 bg-danger text-light rounded-circle">
                <i class="ri-eraser-line" @click="drawReset"></i>
            </span><br><br>

            <span class="p-2 bg-dark text-light rounded-circle">
                <i :class="props.modelValue.Fixed ? 'ri-pushpin-line' : 'ri-pushpin-2-line'" @click="setClip(sysEnums.ClipHandles.Fix)"></i>
            </span>

            <i class="ri-sort-asc ms-3" @click="setClip(sysEnums.ClipHandles.SortUp)"></i>
            <i class="ri-sort-desc ms-3" @click="setClip(sysEnums.ClipHandles.SortDown)"></i>

            <span class="p-2 bg-dark text-light rounded-circle">
                <i class="ri-eye-off-line" @click="setClip(sysEnums.ClipHandles.Hided)"></i>
            </span>
        </span> -->

        <!-- <span v-show="props.modelValue.isActive" class="position-absolute bottom-0 end-0 p-2">
            <BBadge variant="primary">{{ $moment(props.modelValue.Created).format('YY-MM-DD hh:mm') }}</BBadge>
        </span> -->
    </div>
</template>

<script>
import { apiEnums, sysEnums } from '@/common/sysEnums';
import { getCurrentInstance, inject, onBeforeUnmount, onMounted, ref } from 'vue';

import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/vue-3';

import Paper from '@/components/clips/Paper.js';

export default {
    emits: ['update:modelValue'],
    components: {
        EditorContent,
    },
    props: {
        modelValue: Object,
        idx: Number,
    },
    setup(props) {
        const clipContent = ref('');

        const axios = inject('$axios');
        const widgetInfo = inject('$widgetInfo');

        const { setClipFix, setClipSort, setClipUpdate } = inject('$provideList');

        const { emit } = getCurrentInstance();

        const editor = useEditor({
            extensions: [
                Document.extend({
                    content: 'paper',
                }),
                Text,
                Paper,
            ],
            onUpdate: () => {
                emit('update:modelValue', editor.value.getHTML());
                // if (editor.value.getJSON()) clipContent.value = JSON.stringify(editor.value.getJSON());
                // clipContent.value = editor.value.getHTML();
                // console.log('ClipDraw onUpdate : ' + clipContent.value + ' / ' + JSON.stringify(editor.value.getHTML()));
            },
        });

        onMounted(() => {
            getData();
            // console.log('ClipDraw onMounted : ' + JSON.stringify(props.modelValue));
        });

        onBeforeUnmount(() => {
            editor.value.destroy();
        });

        const handleUpdate = () => {
            if (props.modelValue.isActive && clipContent.value !== editor.value.getHTML()) {
                // console.log('ClipDraw onUpdate started : ' + clipContent.value + ' / ' + JSON.stringify(editor.value.getHTML()));
                const formData = new FormData();
                formData.append('EncUser', props.modelValue.EncUser);
                formData.append('EncClip', props.modelValue.EncClip);
                formData.append('Content', JSON.stringify(editor.value.getJSON()));
                formData.append('Type', sysEnums.ClipTypes.Draw);
                axios
                    .post(apiEnums.CloudTicket.updateClip, formData)
                    .then(response => {
                        if (response.data !== '0000') {
                            clipContent.value = editor.value.getHTML();
                            emit('update:modelValue', editor.value.getHTML());
                            console.log('handleUpdate ClipDraw responsed : ' + JSON.stringify(response.data));
                        }
                    })
                    .catch(() => {});
            }
        };

        const drawReset = () => {
            console.log('drawReset started : ' + clipContent.value + ' / ' + JSON.stringify(editor.value.getHTML()));
            const formData = new FormData();
            formData.append('EncUser', props.modelValue.EncUser);
            formData.append('EncClip', props.modelValue.EncClip);
            formData.append('Content', '{"type":"doc","content":[]}');
            formData.append('Type', sysEnums.ClipTypes.Draw);
            axios
                .post(apiEnums.CloudTicket.updateClip, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getData();
                        emit('update:modelValue', editor.value.getHTML());
                        console.log('drawReset responsed : ' + JSON.stringify(response.data));
                    }
                })
                .catch(() => {});
        };

        const getData = async () => {
            if (editor.value && props.modelValue.EncClip) {
                const fileUrl = 'PageClipStorage/files/' + props.modelValue.Content;

                try {
                    if (props.modelValue.Hided) {
                        editor.value.commands.setContent(null, false);
                        clipContent.value = editor.value.getHTML();
                    } else {
                        const params = new URLSearchParams();
                        params.append('FileUrl', fileUrl);
                        axios
                            .post(apiEnums.CloudTicket.getJsonData, null, { params })
                            .then(response => {
                                const data = response.data;
                                const content = JSON.parse(data);
                                editor.value.commands.setContent(content, false);
                                clipContent.value = editor.value.getHTML();
                            })
                            .catch(err => {
                                console.error('Error getData API : ', err);
                            });
                    }
                } catch (error) {
                    console.error('Error getData JSON file : ', error);
                }
            }
        };

        // const getData = async () => {
        //     if (editor.value && props.modelValue.EncClip) {
        //         const isDev = location.host.indexOf('pageclip3') !== -1 || process.env.NODE_ENV === 'development' ? true : false;
        //         const fileUrl = 'https://cdn' + (isDev ? '3' : '3') + '.pageclip.ai/files/'

        //         try {
        //             // console.log('getData : ' + JSON.stringify(fileUrl + 'Json/' + props.modelValue.EncClip + '.json'));
        //             const response = await fetch(fileUrl + props.modelValue.Content);

        //             if (response.ok) {
        //                 const content = await response.json();
        //                 editor.value.commands.setContent(JSON.parse(content), false);
        //                 clipContent.value = editor.value.getHTML();
        //                 // console.log('getData success : ' + JSON.stringify(content));
        //             }
        //         } catch (error) {
        //             console.error('Error getData JSON file : ', error);
        //         }
        //     }
        // }

        // const setData = (data) => {
        //     if (editor.value) {
        //         try {
        //             const content = data.Content;
        //             editor.value.commands.updateAttributes(JSON.parse(content));
        //             editor.value.commands.setContent(JSON.parse(content), false);
        //             // console.log('setData success : ' + JSON.stringify(content));
        //         } catch (error) {
        //             console.error('Error setData JSON file : ', error);
        //         }
        //     }
        // }

        const setClip = flag => {
            if (flag === sysEnums.ClipHandles.Fix) {
                setClipFix(props.modelValue);
            }

            if (flag === sysEnums.ClipHandles.SortUp || flag === sysEnums.ClipHandles.SortDown) {
                setClipSort(props.modelValue, flag);
            }

            if (flag === sysEnums.ClipHandles.Hided) {
                setClipUpdate(props.modelValue, flag);
            }
        };

        return {
            props,
            editor,
            sysEnums,
            clipContent,
            widgetInfo,
            setClip,
            handleUpdate,
            drawReset,
            getData,
        };
    },
};
</script>

<style lang="scss"></style>
