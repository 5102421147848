<script>
import { getCommentCount } from '@/common/comFuncs';
import { enumTypes, sysEnums } from '@/common/sysEnums';
import { ref, reactive, inject, watch, computed } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';

import AppFolderItem from '@/components/apps/AppFolderItem';
//import AppPostItem from '@/components/apps/AppPostItem';

// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
//import 'swiper/css/pagination';

import { register } from 'swiper/element/bundle';
register();

import LottieAni from '@/components/widgets/lottie.vue';
import aniData1 from '@/components/widgets/forest.json';
import aniData2 from '@/components/widgets/auvicynv.json';
import aniData3 from '@/components/widgets/auvicynv.json';

//import aniData1 from '@/components/widgets/cnyeuzxc.json';
//import aniData1 from '@/components/widgets/dklbhvrt.json';
//import aniData1 from '@/components/widgets/fhtaantg.json';
//import aniData1 from '@/components/widgets/forest.json';
//import aniData1 from '@/components/widgets/gsqxdxog.json';
//import aniData1 from '@/components/widgets/hfmdczge.json';
//import aniData1 from '@/components/widgets/house.json';
//import aniData1 from '@/components/widgets/hrqwmuhr.json';
//import aniData1 from '@/components/widgets/adwosptt.json';
//import aniData1 from '@/components/widgets/auvicynv.json';

export default {
    components: {
        Swiper,
        SwiperSlide,
        AppFolderItem,
        //AppPostItem,
        LottieAni,
    },
    setup() {
        const swiperRef = ref(null);
        const activeIndex = ref(0);

        const widgetInfo = inject('$widgetInfo');

        const { folderList, selectedFolder } = inject('$provideList');

        const aniOption1 = reactive({ animationData: aniData1 });
        const aniOption2 = reactive({ animationData: aniData2 });
        const aniOption3 = reactive({ animationData: aniData3 });

        watch(
            () => selectedFolder.value.EncFolder,
            newData => {
                if (swiperRef.value && newData && selectedFolder.value.Type === sysEnums.PageCategorys.Folder) {
                    swiperRef.value.slideTo(1);
                } else if (swiperRef.value && newData && selectedFolder.value.Type === sysEnums.PageCategorys.Post) {
                    swiperRef.value.slideTo(2);
                } else {
                    swiperRef.value.slideTo(0);
                }

                //console.log(JSON.stringify(newData));
            },
        );

        watch(
            () => widgetInfo.value.swiperIndex,
            newData => {
                if (swiperRef.value) {
                    swiperRef.value.slideTo(newData);
                }
                //console.log(newData);
            },
        );

        const cntFolder = computed(() => {
            if (selectedFolder.value) {
                return folderList.value.findIndex(x => x.Type === sysEnums.PageCategorys.Post);
            }
            return 0;
        });

        const onSwiper = swiper => {
            swiperRef.value = swiper; // Swiper 인스턴스를 저장합니다.
        };

        //const onProgress = e => {
        //    const [swiper, progress] = e.detail;
        //    swiperRef.value = swiper;
        //    console.log(progress);
        //};

        const onSlideChange = swiper => {
            if (swiperRef.value) {
                activeIndex.value = swiper.activeIndex;
                widgetInfo.value.swiperIndex = activeIndex.value;
            }

            if (activeIndex.value === 0) selectedFolder.value = [];

            //console.log(swiperRef.value.activeIndex);
        };

        const goToSlide = flag => {
            if (swiperRef.value) {
                const totalSlides = 3;

                if (flag) {
                    // 다음 슬라이드로 이동 (마지막 슬라이드에서 첫 번째로 순환)
                    activeIndex.value = (activeIndex.value + 1) % totalSlides;
                } else {
                    // 이전 슬라이드로 이동 (첫 번째 슬라이드에서 마지막으로 순환)
                    activeIndex.value = (activeIndex.value - 1 + totalSlides) % totalSlides;
                }

                swiperRef.value.slideTo(activeIndex.value);
            }
        };

        return {
            sysEnums,
            enumTypes,
            swiperRef,
            activeIndex,
            widgetInfo,
            aniOption1,
            aniOption2,
            aniOption3,
            folderList,
            cntFolder,
            getCommentCount,
            selectedFolder,
            onSwiper,
            onSlideChange,
            goToSlide,
        };
    },
};
</script>

<template>
    <!--<div class="sticky-top pb-5 mb-5">
        <div class="px-5">
            <BButton @click="goToSlide(false)">back</BButton>
            <h5 class="card-title">{{ activeIndex === 0 ? 'WORKSPACE' : activeIndex === 1 ? 'POST' : 'PAGE' }}</h5>
            <BButton @click="goToSlide(true)">next</BButton>
        </div>
    </div>-->

    <div class="swiper project-swiper p-0">
        <swiper
            :slides-per-view="1"
            :space-between="30"
            :centered-slides="true"
            :pagination="{
                hideOnClick: true,
            }"
            :breakpoints="{
                768: {
                    slidesPerView: 1,
                },
            }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }"
        >
            <swiper-slide>
                <template v-for="(x, n) of folderList" :key="x">
                    <span v-if="x.Type === sysEnums.PageCategorys.Folder">
                        <BCard no-body>
                            <BCardBody>
                                <AppFolderItem :Data="x" :Index="n"></AppFolderItem>
                            </BCardBody>
                        </BCard>
                    </span>
                </template>

                <div class="mt-5 text-center" v-if="folderList.length === 0">
                    <h4 class="pt-5">등록된 폴더가 없습니다</h4>
                    <div class="fs-10">There are no registed folders</div>
                    <p>
                        <LottieAni colors="primary:#25a0e2,secondary:#00bd9d" trigger="loop" :options="aniOption1" :height="260" :width="260"></LottieAni>
                    </p>
                </div>
            </swiper-slide>
            <swiper-slide>
                <template v-for="(x, n) in folderList" :key="x">
                    <span v-if="x.Type === sysEnums.PageCategorys.Post">
                        <BCard no-body>
                            <BCardBody>
                                <AppFolderItem :Data="x" :Index="n"></AppFolderItem>
                            </BCardBody>
                        </BCard>
                    </span>
                </template>

                <!--{{ cntFolder + ' / ' + selectedFolder.length }}-->

                <div class="mt-5 text-center" v-if="cntFolder < 0">
                    <h4 class="pt-5">등록된 포스트가 없습니다</h4>
                    <div class="fs-10">There are no registed posts</div>
                    <p>
                        <LottieAni colors="primary:#25a0e2,secondary:#00bd9d" trigger="loop" :options="aniOption2" :height="170" :width="170"></LottieAni>
                    </p>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="mt-5 text-center h-100 bg-warning" v-if="!widgetInfo.selectedPost">
                    <h4 class="pt-5">등록된 페이지가 없습니다</h4>
                    <div class="fs-10">There are no registed pages</div>
                    <p>
                        <LottieAni colors="primary:#25a0e2,secondary:#00bd9d" trigger="loop" :options="aniOption3" :height="190" :width="190"></LottieAni>
                    </p>
                </div>
            </swiper-slide>

            <!-- 커스텀 네비게이션 버튼 -->
            <template #container-start>
                <div class="swiper-button-prev">
                    <i class="ri-arrow-left-s-line"></i>
                </div>
                <div class="swiper-button-next">
                    <i class="ri-arrow-right-s-line"></i>
                </div>
            </template>
        </swiper>
    </div>
</template>

<style>
.swiper-button-next,
.swiper-button-prev {
    background-color: transparent;
    color: #666; /* 아이콘 색상 */
    width: auto;
    height: auto;
    display: none;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    display: none;
}

.swiper-button-next i,
.swiper-button-prev i {
    font-size: 50px; /* fs-50 클래스와 동일한 효과 */
}
</style>
