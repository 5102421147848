<template>
    <BRow>
        <template v-for="x in companyList" :key="x">
            <BCol xl="3">
                <BCard no-body>
                    <BCardHeader>
                        <h6 class="card-title mb-0">
                            <span class="fs-20 fw-semibold">{{ x.Name }}</span>
                        </h6>
                    </BCardHeader>
                    <BCardBody class="p-4 text-center">
                        <div class="mb-3 hand">
                            <img
                                :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/users/avatar-5.jpg'"
                                alt=""
                                class="mx-auto img-fit avatar-md rounded-3"
                                :class="x.Status ? '' : 'opacity-25'"
                                @click="fileRef.setFileData(x)"
                            />
                        </div>

                        <h5 class="card-title mb-1">
                            <CompanyUpdate :Data="x"></CompanyUpdate>
                        </h5>

                        <p class="text-muted mb-0">{{ x.Email || '-' }}</p>
                        <p class="text-muted mb-0">{{ $moment(x.Created).format('YYYY.MM.DD') }} 등록</p>

                        <span class="position-absolute start-50 top-50 text-muted ps-5 ms-4">
                            <span class="ps-2">
                                <i class="ri-mail-send-line fs-20"></i>
                            </span>
                        </span>
                    </BCardBody>
                    <BCardFooter class="text-center">
                        <div class="text-muted" v-text="x.PostalCode + ' ' + x.Address1 + ' ' + x.Address2 || ''"></div>
                    </BCardFooter>
                </BCard>
            </BCol>
        </template>
    </BRow>

    <span class="d-none">
        <FileUploadSingle ref="fileRef" :FileType="sysEnums.FileTypes.Company"></FileUploadSingle>
    </span>
</template>

<script lang="js">
import CompanyUpdate from "@/components/companys/CompanyUpdate";
import FileUploadSingle from "@/components/files/FileUploadSingle";

import { ref, inject } from 'vue';
import { apiEnums, sysEnums, enumTypes } from "@/common/sysEnums";

export default {
    components: {
        CompanyUpdate,
        FileUploadSingle,
    },
    props: {
        Data: Object,
        Title: Array,
    },
    setup(props) {
        const isCompanyRegist = ref(false);
        const fileRef = ref(false);

        const canvasUserType = ref(0);

        const selectedCompany = ref({});
        const selectedData = ref({});
        const selectedList = ref([]);

        const widgetInfo = inject("$widgetInfo");

        const axios = inject("$axios");

        const { companyList, getCompany } = inject("$provideList");

        const updateCompany = (encCompany) => {
            const formData = new FormData();
            formData.append('EncCompany', encCompany);
            formData.append('Seqno', 99);
            axios.post(apiEnums.CloudTicket.updateCompany, formData)
                .then((response) => {
                    if (response.data === '1000') {
                        getCompany();
                    }
                })
                .catch(() => { });
        };

        const setCompany = (data) => {
            selectedCompany.value = data;
        }

        const setData = (data, user) => {
            canvasUserType.value = user;
            isCompanyRegist.value = !isCompanyRegist.value;
            isCompanyRegist.value ? selectedData.value = data : canvasUserType.value = 0;
        }

        return {
            props, sysEnums, enumTypes, isCompanyRegist,
            selectedData, selectedList, selectedCompany,
            companyList, canvasUserType,
            fileRef, widgetInfo,
            updateCompany,
            setCompany, setData,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable='true'] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
