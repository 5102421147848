<script>
import { dayLeftPercent, daysLeft, getCommentCount, isApp } from '@/common/comFuncs';
import { enumTypes, sysEnums } from '@/common/sysEnums';
import { inject, reactive, ref } from 'vue';

import PageHeader from '@/components/page-header';
import Layout from '@/layouts/main.vue';

import FileList from '@/components/files/FileList';
import CommentList from '@/components/notification/CommentList';
import NotiList from '@/components/notification/NotiList';
import PageList from '@/components/pages/PageList';
import PageTop from '@/components/pages/PageTop';
import UserCard from '@/components/users/UserCard';

import CompanyMap from '@/components/companys/CompanyMap';
// import UserCreate from "@/components/users/UserCreate";

import FolderList from '@/components/folders/FolderList';
import TreeWorkspace from '@/components/tree-workspace';

export default {
    setup() {
        const targetPage = ref('');

        const fileTypes = reactive(sysEnums.FileCategory);

        const userInfo = inject('$userInfo');

        const widgetInfo = inject('$widgetInfo');
        const folders = inject('$folders');
        const pages = inject('$pages');

        const { userList, pageList, folderList, fileList, treeData, selectedTree, selectedFolder, gotoModal } = inject('$provideList');

        return {
            sysEnums,
            enumTypes,
            targetPage,
            widgetInfo,
            userInfo,
            userList,
            pageList,
            treeData,
            selectedTree,
            selectedFolder,
            fileTypes,
            folderList,
            fileList,
            folders,
            pages,
            getCommentCount,
            dayLeftPercent,
            daysLeft,
            gotoModal,
            isApp,
        };
    },
    components: {
        Layout,
        FolderList,
        TreeWorkspace,
        PageHeader,
        PageTop,
        PageList,
        CommentList,
        UserCard,
        CompanyMap,
        FileList,
        NotiList,
    },
};
</script>

<template>
    <Layout v-if="!isApp()">
        <PageHeader title="Page List" pageTitle="Pages" />

        <PageTop></PageTop>

        <BRow>
            <BCol lg="12">
                <div v-if="!widgetInfo.isApp" class="d-flex profile-wrapper position-absolute" style="right: 13px; top: 26px">
                    <BButtonGroup v-show="widgetInfo.activeTab[0] === sysEnums.PageCategorys.Post" class="me-3">
                        <BDropdown variant="primary">
                            <template v-slot:button-content>{{ enumTypes.FileTypes[widgetInfo.fileType] }}</template>
                            <template v-for="x in fileTypes" :key="x">
                                <BDropdownItem @click="widgetInfo.fileType = x">{{ enumTypes.FileTypes[x] }}</BDropdownItem>
                            </template>
                        </BDropdown>
                    </BButtonGroup>

                    <BButtonGroup v-show="widgetInfo.activeTab[0] === sysEnums.PageCategorys.Folder" class="me-3">
                        <BDropdown variant="primary">
                            <template v-slot:button-content>{{ enumTypes.SortTypes[widgetInfo.sort] }}</template>
                            <BDropdownItem @click="widgetInfo.sort = sysEnums.SortTypes.Default">최근수정순</BDropdownItem>
                            <BDropdownItem @click="widgetInfo.sort = sysEnums.SortTypes.Created">최근등록순</BDropdownItem>
                            <BDropdownItem @click="widgetInfo.sort = sysEnums.SortTypes.Title">가나다순</BDropdownItem>
                            <b-dropdown-divider></b-dropdown-divider>
                            <BDropdownItem @click="widgetInfo.sort = sysEnums.SortTypes.NoRead">읽지않은목록</BDropdownItem>
                        </BDropdown>
                    </BButtonGroup>
                    <span class="btn btn-success" @click="gotoModal(null, 'FolderCreate', '폴더 만들기')"><i class="ri-add-line align-bottom"></i>폴더</span>
                </div>
                <BTabs :nav-class="['animation-nav profile-nav text-muted', { 'gap-2 gap-lg-3 pt-4': !widgetInfo.isApp }]" pills v-model="widgetInfo.activeTab[0]">
                    <!-- <BTab class="nav-item pt-4 d-none">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-md-inline-block"></span>
                        </template>

                        <BCard no-body>
                            <BCardBody> </BCardBody>
                        </BCard>
                    </BTab> -->

                    <BTab :class="['nav-item', { 'pt-4': !widgetInfo.isApp }]" :title-item-class="widgetInfo.isApp ? 'd-none' : ''">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-md-inline-block">멤버({{ userList.length }})</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <UserCard></UserCard>
                            </BCardBody>
                        </BCard>
                    </BTab>

                    <BTab :class="['nav-item', { 'pt-4': !widgetInfo.isApp }]" :title-item-class="widgetInfo.isApp ? 'd-none' : ''">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-md-inline-block">워크스페이스({{ folders.length + '/' + selectedTree.children.length }})</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <BCard no-body>
                                    <BCardHeader class="align-items-center d-flex py-3">
                                        <BCardTitle class="mb-0 flex-grow-1">Folder & </BCardTitle>
                                        <div class="flex-shrink-0"></div>
                                    </BCardHeader>

                                    <BCardBody class="px-3 pt-4 mx-n3 mb-2">
                                        <TreeWorkspace :model="selectedFolder && selectedFolder.EncFolder ? selectedTree : treeData"></TreeWorkspace>
                                    </BCardBody>
                                </BCard>
                            </BCardBody>
                        </BCard>
                    </BTab>

                    <BTab :class="['nav-item', { 'pt-4': !widgetInfo.isApp }]" :title-item-class="widgetInfo.isApp ? 'd-none' : ''">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-md-inline-block">페이지({{ pages.length + '/' + pageList.length }})</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <slot v-if="widgetInfo.tag === sysEnums.PageTags.Noti">
                                    <NotiList></NotiList>
                                </slot>
                                <slot v-else>
                                    <BRow>
                                        <slot v-for="(x, n) of folderList" :key="x">
                                            <BCol xxl="3" sm="6" class="project-card px-3 py-2">
                                                <BCard no-body>
                                                    <BCardBody>
                                                        <FolderList :Data="x" :Index="n"></FolderList>
                                                    </BCardBody>
                                                </BCard>
                                            </BCol>
                                        </slot>
                                    </BRow>

                                    <hr v-if="folderList.length > 0" class="mt-4 mb-5 text-muted" />

                                    <PageList></PageList>
                                </slot>
                            </BCardBody>
                        </BCard>
                    </BTab>

                    <BTab :class="['nav-item', { 'pt-4': !widgetInfo.isApp }]" :title-item-class="widgetInfo.isApp ? 'd-none' : ''">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-md-inline-block">댓글({{ getCommentCount(pages) }})</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <!-- <p v-if="pages && pages.length > 0">{{ JSON.stringify(pages[0].Comments) }}</p> -->
                                <CommentList></CommentList>
                            </BCardBody>
                        </BCard>
                    </BTab>

                    <BTab :class="['nav-item', { 'pt-4': !widgetInfo.isApp }]" :title-item-class="widgetInfo.isApp ? 'd-none' : ''">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-md-inline-block">파일({{ fileList.length }})</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <FileList></FileList>
                            </BCardBody>
                        </BCard>
                    </BTab>

                    <BTab :class="['nav-item', { 'pt-4': !widgetInfo.isApp }]" :title-item-class="widgetInfo.isApp ? 'd-none' : ''">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-md-inline-block">조직도</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <CompanyMap></CompanyMap>
                            </BCardBody>
                        </BCard>
                    </BTab>

                    <!-- <BTab class="nav-item pt-4">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-md-inline-block">히스토리</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <CompanyMap></CompanyMap>
                            </BCardBody>
                        </BCard>
                    </BTab>                     -->
                </BTabs>
            </BCol>
        </BRow>
    </Layout>
</template>

<style></style>
