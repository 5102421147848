<template>
    <BRow>
        <BCol class="text-muted">
            <BCard no-body>
                <BCardHeader>
                    <BCardTitle class="mb-0">
                        {{
                            activeTab === 1
                                ? 'Page 종류'
                                : activeTab === 2
                                ? 'Page 제목'
                                : activeTab === 3
                                ? '파일첨부하기'
                                : activeTab === 4
                                ? 'Page 열람 대상 지정하기'
                                : activeTab === 5
                                ? 'D-Day 지정하기'
                                : activeTab === 6
                                ? '장소 지정하기'
                                : 'Page 옵션 선택하기'
                        }}
                    </BCardTitle>
                </BCardHeader>

                <BCardBody>
                    <div class="text-center pt-3 pb-4">
                        <h5 class="text-muted">
                            {{
                                activeTab === 1
                                    ? '작성하실 Page의 종류를 선택하여 주시기 바랍니다'
                                    : activeTab === 2
                                    ? 'Page 제목 및 내용을 입력하여 주시기 바랍니다'
                                    : activeTab === 3
                                    ? '파일을 첨부하여 주시기 바랍니다'
                                    : activeTab === 4
                                    ? '열람대상 지정하지 않으면\n\n워크스페이스 멤버 전체가 조회가능 합니다'
                                    : activeTab === 5
                                    ? '끝내야 하는 일정을 선택하여 주시기 바랍니다'
                                    : activeTab === 6
                                    ? '관련된 장소를 선택하여 주시기 바랍니다'
                                    : '이 Page의 속성 옵션을 지정해 주시기 바랍니다'
                            }}
                        </h5>
                    </div>

                    <div id="custom-progress-bar" class="progress-nav mb-4">
                        <div class="progress" style="height: 1px">
                            <div class="progress-bar" role="progressbar" :style="`width: ${progressbarvalue}%;`" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>

                        <ul class="nav nav-pills progress-bar-tab custom-nav position-relative" role="tablist">
                            <li v-for="step in steps" :key="step" class="nav-item" role="presentation">
                                <button
                                    @click="toggleTab(step, ((step - 1) * 100) / stepsCount)"
                                    :class="{ 'nav-link': true, 'rounded-pill': true, active: activeTab === step, done: activeTab > step }"
                                    type="button"
                                    role="tab"
                                >
                                    {{ step }}
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div class="tab-content mt-5">
                        <div class="tab-pane fade" :class="activeTab == 1 && 'show active'" id="pills-gen-info" role="tabpanel" aria-labelledby="pills-gen-info-tab">
                            <div class="mt-5 hand">
                                <div
                                    class="d-flex mb-4 br-8 align-items-center"
                                    :class="pageType === sysEnums.PageCategorys.Default ? 'bg-warning' : 'bg-secondary text-light'"
                                    @click="setPageType(sysEnums.PageCategorys.Default)"
                                >
                                    <span class="p-4"><i class="ri-file-line fs-50"></i></span>
                                    <span>
                                        <h5 class="mb-1">일반</h5>
                                        <div>기본 양식</div>
                                    </span>
                                </div>

                                <div
                                    class="d-flex mb-4 br-8 align-items-center"
                                    :class="pageType === sysEnums.PageCategorys.Decision ? 'bg-success' : 'bg-secondary text-light'"
                                    @click="setPageType(sysEnums.PageCategorys.Decision)"
                                >
                                    <span class="p-4"><i class="ri-quill-pen-line fs-50"></i></span>
                                    <span>
                                        <h5 class="mb-1">의사결정</h5>
                                        <div>문서결재 양식</div>
                                    </span>
                                </div>

                                <div
                                    class="d-flex mb-4 br-8 align-items-center"
                                    :class="pageType === sysEnums.PageCategorys.Vacation ? 'bg-danger' : 'bg-secondary text-light'"
                                    @click="setPageType(sysEnums.PageCategorys.Vacation)"
                                >
                                    <span class="p-4"><i class="ri-flight-takeoff-line fs-50"></i></span>
                                    <span>
                                        <h5 class="mb-1">휴가신청서 외</h5>
                                        <div>휴가신청서 및 지출결의서 등 공통양식으로 작성</div>
                                    </span>
                                </div>

                                <!-- <div class="d-flex mb-4 br-8 align-items-center bg-secondary text-light" @click="gotoPageCreate('PageCreate')">
                                    <span class="p-4"><i class="ri-edit-2-line fs-50"></i></span>
                                    <span>
                                        <h5 class="mb-1">에디터로 작성하기</h5>
                                        <div>위자드(Wizard)가 아닌 기본 에디터로 작성하기</div>
                                    </span>
                                </div> -->

                                <div class="d-flex mb-4 br-8 align-items-center bg-secondary text-light" @click="gotoModal('PageCreate', '페이지 등록')">
                                    <span class="p-4"><i class="ri-edit-2-line fs-50"></i></span>
                                    <span>
                                        <h5 class="mb-1">에디터로 작성하기</h5>
                                        <div>위자드(Wizard)가 아닌 기본 에디터로 작성하기</div>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" :class="activeTab == 2 && 'show active'" id="pills-info-desc" role="tabpanel" aria-labelledby="pills-info-desc-tab">
                            <div><span class="text-danger">*</span> 항목은 필수 입력할 항목입니다</div>
                            <div class="mt-5">
                                <label class="form-label" for="gen-info-description-input">제목 <span class="text-danger">*</span></label>
                                <div class="form-floating mb-4">
                                    <input type="text" class="form-control" id="floatingInput2" placeholder="Page 제목을 입력하여 주세요." v-model="workspaceName" />
                                    <label for="floatingInput2">Page 제목을 입력하여 주세요.</label>
                                </div>

                                <label class="form-label" for="gen-info-description-input">내용</label>
                                <div class="mb-5">
                                    <textarea
                                        class="form-control"
                                        rows="6"
                                        id="floatingInput3"
                                        placeholder="Page 내용을 입력하여 주세요."
                                        v-model="workspaceDescription"
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" :class="activeTab == 3 && 'show active'" id="pills-info-desc" role="tabpanel" aria-labelledby="pills-info-desc-tab">
                            <PageFile></PageFile>
                        </div>

                        <div class="tab-pane fade" :class="activeTab == 4 && 'show active'" id="pills-info-desc" role="tabpanel" aria-labelledby="pills-info-desc-tab">
                            <SetUserRole></SetUserRole>
                        </div>

                        <div class="tab-pane fade" :class="activeTab == 5 && 'show active'" id="pills-info-desc" role="tabpanel" aria-labelledby="pills-info-desc-tab">
                            <PageSetDate></PageSetDate>
                        </div>

                        <div class="tab-pane fade" :class="activeTab == 6 && 'show active'" id="pills-info-desc" role="tabpanel" aria-labelledby="pills-info-desc-tab">
                            <!-- <PageSetMap></PageSetMap> -->
                        </div>

                        <div class="tab-pane fade" :class="activeTab == 7 && 'show active'" id="pills-success" role="tabpanel" aria-labelledby="pills-success-tab">
                            <PageOption></PageOption>

                            <div class="text-center pt-4 pb-2">
                                <i class="ri-checkbox-circle-fill bg-light text-success display-4 rounded-circle"></i>
                                <h5>Well Done !</h5>
                                <p class="text-muted">You have Successfully Signed Up</p>

                                <div class="mt-5">
                                    <div class="avatar-md mx-auto">
                                        <div class="mb-4">
                                            <lottie colors="primary:#0ab39c,secondary:#405189" :options="defaultOptions" :height="120" :width="120" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex align-items-start gap-3 mt-5 mb-4">
                        <button v-if="activeTab > 1" type="button" class="btn btn-link text-decoration-none btn-label previestab" @click="toggleTab(activeTab - 1, 0)">
                            <i class="ri-arrow-left-line label-icon align-middle fs-lg me-2"></i>이전
                        </button>
                        <button v-if="activeTab < stepsCount" type="button" class="btn btn-success btn-label right ms-auto nexttab nexttab" @click="toggleTab(activeTab + 1, 100)">
                            <i class="ri-arrow-right-line label-icon align-middle fs-lg ms-2"></i>
                            {{
                                activeTab === 1
                                    ? '페이지명등록'
                                    : activeTab === 2
                                    ? '파일첨부하기'
                                    : activeTab === 3
                                    ? '열람대상지정'
                                    : activeTab === 4
                                    ? '날자선택하기'
                                    : activeTab === 5
                                    ? '위치지정하기'
                                    : activeTab === 6
                                    ? '옵션선택하기'
                                    : ''
                            }}
                        </button>
                    </div>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>
</template>

<script lang="js">
import { sysEnums } from "@/common/sysEnums";
import { computed, inject, ref } from "vue";

import PageFile from "@/components/pages/PageFile";
import PageSetDate from "@/components/pages/PageSetDate";
import SetUserRole from "@/components/users/SetUserRole";
// import PageSetMap from "@/components/pages/PageSetMap";
import PageOption from "@/components/pages/PageOption";

import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/lupuorrc.json";

export default {
    emits: ['FileUploaded', 'FileSelected'],
    components: {
        PageFile,
        SetUserRole,
        PageSetDate, PageOption,
        lottie: Lottie,
    },
    props: {
        Content: String,
        FileType: Number,
        IsBtn: Boolean,
    },
    setup(props) {
        const titles = ref(['', '제목', '설명', '등록일', '상태']);

        let progress = ref(0);
        let progressbarvalue = ref(0);
        let pageType = ref(0);

        let stepsCount = ref(7);
        let activeTab = ref(1);

        let workspaceName = ref('');
        let workspaceDescription = ref('');

        // const store = inject("$store");

        const userInfo = inject('$userInfo');
        const widgetInfo = inject("$widgetInfo");

        const { filteredFolder, selectedPage } = inject("$provideList");

        const steps = computed(() => {
            return Array.from({ length: stepsCount.value }, (_, index) => index + 1);
        })

        const toggleTab = (step, progress) => {
            activeTab.value = step;
            console.log('Step:', step, 'Progress:', progress);
        }

        const setPageType = (type) => {
            pageType.value = type;
        }

        const gotoPageCreate = (to) => {
            if (!selectedPage.value) {
                // showAlert('Alert!', '페이지를 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            widgetInfo.value.target = to;
            widgetInfo.value.canvasEdit = true;
            widgetInfo.value.canvasEnd = true;

            // widgetInfo.value.canvasEnd = !widgetInfo.value.canvasEnd;

            // if (widgetInfo.value.canvasEnd) {
            //     // store.dispatch("AC_WIDGET", { title: '', target: to, data: null, canvasEdit: true, canvasEnd: true });
            //     widgetInfo.value.target = to;
            //     widgetInfo.value.canvasEdit = true;
            //     widgetInfo.value.canvasEnd = true;
            // } else {
            //     widgetInfo.value.target = null;
            //     widgetInfo.value.canvasEdit = false;
            //     widgetInfo.value.canvasEnd = false;
            //     // store.dispatch("AC_WIDGET", { title: '', target: null, data: null, canvasEdit: false, canvasEnd: false });
            // }
        };

        const gotoModal = (to, title) => {
            widgetInfo.value.titleModal = title;
            widgetInfo.value.canvas = false;
            widgetInfo.value.modal = true;
            widgetInfo.value.targetModal = to;

            selectedPage.value.Title = '';
        }

        const handleDropFolderMove = (encFolder, event) => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            // updateFolder(droppedData.EncFolder, encFolder)
            console.log(encFolder + ' / ' + droppedData.EncFolder)

            // if (droppedData.EncWorkspace) {
            //     updateWorkspace(droppedData.EncWorkspace, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncWorkspace)
            // } else {
            //     updateFolder(droppedData.EncFolder, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncFolder)
            // }
        }

        // 압축하지않고 올리기 사진촬영시 이미지 세로형이 가로로 전송됨
        // const fileList = (event) => {
        //     const files = Array.from(event.target.files);
        //     files.forEach((file) => {
        //         selectedFiles.value.push(URL.createObjectURL(file));
        //         uploadFiles.value.push(file);
        //         // console.log('FileUpload selectedFiles : ', selectedFiles);
        //         // console.log('FileUpload Uploaded file : ', file);
        //     });
        // };


        return {
            props, sysEnums, userInfo, progress,
            stepsCount, activeTab, steps, pageType,
            workspaceName, workspaceDescription,
            titles, filteredFolder, progressbarvalue,
            defaultOptions: { animationData: animationData },
            toggleTab, handleDropFolderMove, setPageType,
            gotoPageCreate, gotoModal,
        };
    },
};
</script>

<style scoped lang="scss"></style>
