<template>
    <!-- <input class="w-100 p-3 my-2 rounded shadow border-1" type="text" id="extraAddress" placeholder="참고항목" /> -->

    <div class="d-none" data-bs-toggle="collapse" data-bs-target="#postContainer" aria-expanded="false" aria-controls="postContainer" ref="daumPostRef" @click="execDaumPostcode"></div>

    <div class="collapse border border-1 b-90" id="postContainer" style="height: 600px; overflow: hidden">
        <div ref="daumPostwrap" class="position-relative" style="height: 600px; max-height: 630px; /* margin: 5px 0; */">
            <!-- <img src="//t1.daumcdn.net/postcode/resource/images/close.png" class="float-end hand" @click="foldDaumPostcode" alt="fold button" /> -->
        </div>
        <div ref="map" style="height: 600px; overflow-y: scroll"></div>
    </div>
</template>

<script>
import { onMounted, ref, getCurrentInstance, inject } from "vue";

export default {
    emits: ["SetAddress"],
    setup() {
        const postcode = ref('');
        const address = ref('');
        const extraAddress = ref('');
        const daumPostwrap = ref(null);
        const daumPostRef = ref(null);
        const map = ref(null);

        let coords = ref(null);

        const store = inject("$store");

        const { emit } = getCurrentInstance();

        onMounted(() => {
            if (window.kakao && window.kakao.maps) {
                // console.log("window.kakao.maps already loaded");
            } else {
                const script = document.createElement("script");
                script.onload = () => window.kakao.maps.load();
                script.src =
                    "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey="+ store.state.login.kakaoAppKey +"&libraries=services";
                document.head.appendChild(script);
            }

            // container.value = document.getElementById('postContainer');
            // daumPostwrap.value.style.height = container.value.style.height + "px";
        });

        const foldDaumPostcode = () => {
            daumPostwrap.value.style.display = 'none';
        };

        const viewDaumPostcode = () => {
            daumPostRef.value.click();

            if (daumPostwrap.value.style.display === 'none') {
                daumPostRef.value.click();
            }
        };

        const execDaumPostcode = () => {
            // console.log('execDaumPostcode')
            // const currentScroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop);

            // container.value = document.getElementById('postContainer');
            // daumPostwrap.value.style.height = container.value.style.height + "px";

            new window.daum.Postcode({
                oncomplete: (data) => {
                    let addr = "";
                    let extraAddr = "";

                    if (data.userSelectedType === "R") {
                        addr = data.roadAddress;
                    } else {
                        addr = data.jibunAddress;
                    }

                    if (data.userSelectedType === "R") {
                        if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
                            extraAddr += data.bname;
                        }

                        if (data.buildingName !== "" && data.apartment === "Y") {
                            extraAddr += extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
                        }

                        if (extraAddr !== "") {
                            extraAddr = " (" + extraAddr + ")";
                        }

                        extraAddress.value = extraAddr;
                    } else {
                        extraAddress.value = "";
                    }

                    postcode.value = data.zonecode;
                    address.value = addr;

                    daumPostwrap.value.style.display = "none";
                    // document.body.scrollTop = currentScroll;

                    Promise.resolve(data)
                        .then(() => {
                            const { address } = data;
                            return new Promise((resolve, reject) => {
                                const geocoder = new window.kakao.maps.services.Geocoder();
                                geocoder.addressSearch(address, (result, status) => {
                                    if (status === window.kakao.maps.services.Status.OK) {
                                        const { x, y } = result[0];
                                        resolve({ lat: y, lng: x });
                                    } else {
                                        reject();
                                    }
                                });
                            });
                        })
                        .then((result) => {
                            coords.value = result;
                            postcode.value = data.zonecode;
                            setAddress();
                            viewRegionMap();
                        });
                },
                onresize: (size) => {
                    daumPostwrap.value.style.height = size.height + "px";

                    setTimeout(() => {
                        const container = document.getElementById('offcanvasWidgetBody');
                        if (container) {
                            container.scrollTop = container.scrollHeight;
                        }
                    }, 300)
                },
                width: "100%",
                height: "100%",
            }).embed(daumPostwrap.value);

            daumPostwrap.value.style.display = "block";
        };

        const viewRegionMap = () => {
            const container = map.value;
            container.style.width = "100%";
            container.style.height = window.innerHeight + 20 + "px";
            const options = {
                center: new window.kakao.maps.LatLng(coords.value.lat, coords.value.lng),
                // center: new daum.maps.LatLng(37.537187, 127.005476),
                level: 5,
            };

            const mapInstance = new window.kakao.maps.Map(container, options);
            const marker = new window.kakao.maps.Marker({
                position: new window.kakao.maps.LatLng(coords.value.lat, coords.value.lng),
            });
            marker.setMap(mapInstance);

            window.kakao.maps.event.addListener(mapInstance, "click", (mouseEvent) => {
                const latlng = mouseEvent.latLng;
                marker.setPosition(latlng);

                coords.value = { lat: latlng.getLat(), lng: latlng.getLng() };
                setAddress();

                // let geocoder = new kakao.maps.services.Geocoder();
                // const callback = (result, status) => {
                //     if (status == kakao.maps.services.Status.OK) {
                //         let msg =
                //             "주소 : " +
                //             result[0].address.address_name +
                //             "<br><br>" +
                //             "위도: " +
                //             latlng.getLat() +
                //             "<br>경도: " +
                //             latlng.getLng();
                //         console.log(msg);

                //         coords.value = { lat: latlng.getLat(), lng: latlng.getLng() };
                //         setAddress();
                //     }
                // };
                // geocoder.coord2Address(coords.getLng(), coords.getLat(), callback);
            });
        };

        const setAddress = () => {
            emit("SetAddress", {
                code: postcode.value,
                addr1: address.value,
                coords: coords.value,
            });
        };

        return {
            postcode, address,
            extraAddress, coords,
            daumPostwrap, map, daumPostRef,
            foldDaumPostcode, viewDaumPostcode, execDaumPostcode,
            setAddress, viewRegionMap,
        };
    },
};
</script>
