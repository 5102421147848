<script>
import { sysEnums } from '@/common/sysEnums';
import { BButton, BCol, BRow } from 'bootstrap-vue-next';
import { inject } from 'vue';

export default {
    setup() {
        const widgetInfo = inject('$widgetInfo');

        const folders = inject('$folders');

        const { updateFolder } = inject('$provideList');

        return {
            sysEnums,
            folders,
            widgetInfo,
            updateFolder,
        };
    },
    components: {},
};
</script>

<template>
    <!--{{ JSON.stringify(widgetInfo.dataDetail) }}-->
    <div class="p-4 text-center">
        <BRow>
            <BCol cols="12" class="mb-2" v-for="x in folders.filter(x => x.Type === sysEnums.PageCategorys.Folder)" :key="x" :value="x.EncFolder">
                <i v-if="x.EncFolder === widgetInfo.dataDetail.EncTopFolder" class="ri-check-line me-3"></i>
                <BButton class="rounded-pill" variant="primary" size="lg" @click="updateFolder(widgetInfo.dataDetail.EncFolder, x.EncFolder)">{{ x.Name }}</BButton>
            </BCol>
        </BRow>

        <!--<div class="text-center pb-2 mb-2 border-bottom hand" v-for="x in folders.filter(x => x.Type === sysEnums.PageCategorys.Folder)" :key="x" :value="x.EncFolder">
            <span @click="updateFolder(widgetInfo.dataDetail.EncFolder, x.EncFolder)">{{ x.Name }}</span>
            <i v-if="x.EncFolder === widgetInfo.dataDetail.EncTopFolder" class="ri-check-line ms-3"></i>
        </div>        -->
    </div>

    <!--<div class="form-floating text-center mb-2">
        <select class="form-select" id="floatingSelect1" aria-label="POST선택" v-model="encFolder">
            <option class="text-center" selected>Folder선택</option>
            <option class="text-center" v-for="x in folders.filter(x => x.Type === sysEnums.PageCategorys.Folder)" :key="x" :value="x.EncFolder">
                {{ x.Name }}
            </option>
        </select>
        <label for="floatingSelect1">Folder선택</label>
    </div>-->

    <!--<div class="d-flex align-items-center mt-3">
        <BButton class="w-100 h-50h" variant="primary" @click="updateFolder">이동하기</BButton>
    </div>-->
</template>
