import { createStore } from 'vuex';

import modules from './modules';

const store = createStore({
    state: {
        isDev: true,
        isMobile: false,
        validToken: 'jddwpmakxiendlxjjat3m0qxu#.nnjnjxss!by$mksda',
        signalToken: 'nvzQ0EM8fdTxUqrQpsnCOdKwGm5Zjs5ucF-EHVu83uE',
        // showOverlay: false,
        routeTo: { to: 0, from: 0 },
        widgetInfo: {
            title: '',
            titleDetail: '',
            titleModal: '',
            encUser: '',
            encPage: '',
            encClip: '',
            encComment: '',
            place: '',
            placeDetail: '',
            search: '',
            penColor: '#666666',
            modalSize: 'lg',
            target: null,
            targetDetail: null,
            targetModal: null,
            selectedFile: null,
            canvas: false,
            canvasDetail: false,
            canvasChat: false,
            clipMenu: false,
            modal: false,
            spiner: false,
            isApp: false,
            isAndroid: false,
            isMobile: false,
            data: [],
            dataDetail: [],
            dataCanvas: [],
            dataModal: [],
            companyInfo: [],
            selectedPost: [],
            selectedClip: [],
            selectedUser: [],
            children: [],
            activeTab: [2, 0, 0],
            pageWid: 0,
            tag: 0,
            sort: 0,
            fileType: 0,
            penSize: 2,
            swiperIndex: 0,
            loaded: false,
            locked: false,
        },
        userInfo: {},
        notiData: {}, //공사현장
        usersData: {}, //작성자
        companyData: {}, //COMPANY
        pageData: {}, //PAGE
        folderData: {}, //폴더
        fileData: {},
        paydoData: {},
        billData: {},
        alarmData: {},
        questionData: {}, //체크리스트질문
        answerData: {}, //체크리스트확인
        connection: null,
        masterKey: '0iwT7cEycMCZdinWkrYYww==',
    },
    getters: {
        GE_VALIDTOKEN: state => state.validToken,
        GE_ROUTE: state => state.routeTo,
        GE_WIDGET: state => state.widgetInfo,
        GE_WIDGET_DATA: state => state.widgetInfo.data,
        GE_USER: state => state.userInfo,
        GE_NOTI: state => state.notiData,
        GE_USERS: state => state.usersData,
        GE_COMPANY: state => state.companyData,
        GE_PAGE: state => state.pageData,
        GE_FOLDER: state => state.folderData,
        GE_FILE: state => state.fileData,
        GE_PAYDO: state => state.paydoData,
        GE_BILL: state => state.billData,
        GE_ALARM: state => state.alarmData,
        GE_QUESTION: state => state.questionData,
        GE_ANSWER: state => state.answerData,
    },
    mutations: {
        MU_VALIDTOKEN: (state, payload) => {
            state.validToken = payload;
        },
        MU_ROUTE: (state, payload) => {
            state.routeTo = payload;
        },
        MU_WIDGET: (state, payload) => {
            state.widgetInfo = payload;
        },
        UP_WIDGET: (state, payload) => {
            state.widgetInfo.data = payload;
        },
        MU_USER: (state, payload) => {
            state.userInfo = payload;
        },
        MU_NOTI: (state, payload) => {
            state.notiData = payload;
        },
        MU_USERS: (state, payload) => {
            state.usersData = payload;
        },
        MU_COMPANY: (state, payload) => {
            state.companyData = payload;
        },
        MU_PAGE: (state, payload) => {
            state.pageData = payload;
        },
        MU_FOLDER: (state, payload) => {
            state.folderData = payload;
        },
        MU_FILE: (state, payload) => {
            state.fileData = payload;
        },
        MU_PAYDO: (state, payload) => {
            state.paydoData = payload;
        },
        MU_BILL: (state, payload) => {
            state.billData = payload;
        },
        MU_ALARM: (state, payload) => {
            state.alarmData = payload;
        },
        MU_QUESTION: (state, payload) => {
            state.questionData = payload;
        },
        MU_ANSWER: (state, payload) => {
            state.answerData = payload;
        },
    },
    actions: {
        AC_VALIDTOKEN: ({ commit }, payload) => {
            commit('MU_VALIDTOKEN', payload);
        },
        AC_ROUTE: ({ commit }, payload) => {
            commit('MU_ROUTE', payload);
        },
        AC_WIDGET: ({ commit }, payload) => {
            commit('MU_WIDGET', payload);
        },
        AC_USER: ({ commit }, payload) => {
            commit('MU_USER', payload);
        },
        AC_NOTI: ({ commit }, payload) => {
            commit('MU_NOTI', payload);
        },
        AC_USERS: ({ commit }, payload) => {
            commit('MU_USERS', payload);
        },
        AC_COMPANY: ({ commit }, payload) => {
            commit('MU_COMPANY', payload);
        },
        AC_PAGE: ({ commit }, payload) => {
            commit('MU_PAGE', payload);
        },
        AC_FOLDER: ({ commit }, payload) => {
            commit('MU_FOLDER', payload);
        },
        AC_FILE: ({ commit }, payload) => {
            commit('MU_FILE', payload);
        },
        AC_PAYDO: ({ commit }, payload) => {
            commit('MU_PAYDO', payload);
        },
        AC_BILL: ({ commit }, payload) => {
            commit('MU_BILL', payload);
        },
        AC_ALARM: ({ commit }, payload) => {
            commit('MU_ALARM', payload);
        },
        AC_QUESTION: ({ commit }, payload) => {
            commit('MU_QUESTION', payload);
        },
        AC_ANSWER: ({ commit }, payload) => {
            commit('MU_ANSWER', payload);
        },
    },
    modules,
    // Enable strict mode in development to get a warning
    // when mutating state outside of a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    // 뮤테이션을 통하지 않고 상태를 변경하는 것에 대한 경고 설정
    // strict: process.env.NODE_ENV !== 'production',
});

export default store;
