<template>
    <div v-show="props.url">
        <div class="d-flex align-items-center p-4 mb-2 fs-18">
            {{ props.data }}
        </div>

        <div class="w-100 vh-100" v-if="props.url">
            <iframe :src="props.data" width="100%" height="100%"></iframe>
        </div>
    </div>
</template>

<script lang="js">
// import simplebar from 'simplebar-vue';

export default {
    props: {
        data: String
    },
    components: {
        // simplebar
    },
    setup(props) {

        return {
            props,
        };
    },
};
</script>

<style scoped lang="scss"></style>
