<template>

    <div v-if="edit" class="flex-grow-1 text-muted overflow-hidden">
        <input type="text" class="form-control mb-2 text-center" v-model="props.Data.UserName" placeholder="성명">
        <input type="text" class="form-control mb-2 text-center" v-model="props.Data.LoginId" placeholder="ID">
        <input type="text" class="form-control mb-2 text-center" v-model="props.Data.Email" placeholder="Email">

        <div class="row">
            <div class="col-6">
                <div class="form-floating">
                    <select class="form-select" id="floatingSelect1" aria-label="부서" v-model="props.Data.Department">
                        <option selected>부서</option>
                        <option v-for="x in widgetInfo.companyInfo.Depts" :key="x" :text="x.Title" :value="x.DeptNum"></option>
                        <option v-for="x in widgetInfo.companyInfo.Groups" :key="x" :text="x.Title" :value="x.DeptNum"></option>
                    </select>
                    <label for="floatingSelect1">부서</label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-floating">
                    <select class="form-select" id="floatingSelect2" aria-label="직급" v-model="props.Data.Position">
                        <option selected>직급</option>
                        <option v-for="x in widgetInfo.companyInfo.Positions" :key="x" :text="x.Title" :value="x.DeptNum"></option>
                    </select>
                    <label for="floatingSelect2">직급</label>
                </div>
            </div>
        </div>

        <span class="d-flex flex-wrap my-3 hand">
            <span v-for="x in userTypes" :key="x">
                <BBadge :variant="x === props.Data.UserType ? 'danger' : 'secondary'" class="m-1" @click="updateUserType(x)">{{ enumTypes.UserTypes[x] }}</BBadge>
            </span>
            <span class="form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" :checked="props.Data.Status" @click="updateUser(true)" />
            </span>
        </span>

        <div class="row mb-2">
            <div class="col-6">
                <BButton class="w-100 p-0" variant="primary" @click="updateUser(false)"><i class="ri-edit-line fs-20"></i></BButton>
            </div>
            <div class="col-6">
                <BButton class="w-100 p-0" @click="edit = false"><i class="ri-close-line fs-20"></i></BButton>
            </div>
        </div>

    </div>
    <div v-else class="overflow-hidden hand" @click="setData">
        {{ props.Data.LoginId }}<br>
        <BBadge :variant="props.Data.UserType > 0 ? 'danger' : 'primary'" class="mb-2">{{ enumTypes.UserTypes[props.Data.UserType] }}</BBadge>
    </div>

</template>

<script lang="js">
import { ref, inject, onMounted } from 'vue';
import { apiEnums, sysEnums, enumTypes } from "@/common/sysEnums";
// import { getUserTypeList } from "@/common/comFuncs";

export default {
    components: {
    },
    props: {
        Data: Object
    },
    setup(props) {
        // const name = ref('');
        // const tel = ref('');
        // const email = ref('');
        // const department = ref(0);
        // const position = ref(0);

        const edit = ref(false);

        const companys = inject("$companys");

        const axios = inject("$axios");

        const widgetInfo = inject("$widgetInfo");

        const { getUser } = inject("$provideList");

        const departmentList = ref([]);
        const positionList = ref([]);
        const userTypes = sysEnums.UserTypes;

        onMounted(() => {
            setTimeout(() => {
                if (companys.value && companys.value.length > 0) {
                    const comp = companys.value.find(x => x.EncCompany === props.Data.EncCompany) || null;
                    if (comp) {
                        departmentList.value = comp.Department.split('|').map(String);
                        positionList.value = comp.Position.split('|').map(String);
                    }
                }
            }, 300);
        });

        const setData = () => {
            edit.value = true;

            // name.value = props.Data.UserName;
            // tel.value = props.Data.LoginId;
            // email.value = props.Data.Email;
            // department.value = props.Data.Department;
            // position.value = props.Data.Position;

            // console.log(props.Data.EncUser + ' / ' + props.Data.LoginId + ' / ' + JSON.stringify(companyInfo.value));
        }

        const updateUser = (flag) => {
            edit.value = false;

            // console.log(department.value + ' / ' + position.value)

            const formData = new FormData();
            formData.append('EncUser', props.Data.EncUser);
            if (flag) {
                formData.append('Status', 1);
            } else {
                formData.append('UserName', props.Data.UserName);
                formData.append('Email', props.Data.Email);
                formData.append('Department', props.Data.Department);
                formData.append('Position', props.Data.Position);
            }

            axios.post(apiEnums.CloudTicket.updateUser, formData)
                .then(async (response) => {
                    if (response.data !== '0000') {
                        getUser();

                        // try {
                        //     await store.state.connection.invoke('SendUserReset', props.Data.LoginId, enumTypes.SignalTypes.UserUpdated);
                        // } catch (error) {
                        //     console.error('Error invoking SendUserReset : ', error);
                        // }
                    }
                })
                .catch(() => { });
        };

        const updateUserType = (userType) => {
            const formData = new FormData();
            formData.append('EncUser', props.Data.EncUser);
            formData.append('UserType', userType);
            axios.post(apiEnums.CloudTicket.updateUser, formData)
                .then(async (response) => {
                    if (response.data !== '0000') {
                        getUser();

                        // try {
                        //     await store.state.connection.invoke('SendUserReset', props.Data.LoginId, enumTypes.SignalTypes.UserUpdated);
                        // } catch (error) {
                        //     console.error('Error invoking SendUserReset : ', error);
                        // }
                    }
                })
                .catch(() => { });
        };

        return {
            sysEnums, enumTypes,
            edit, widgetInfo,
            // name, tel, email, department, position,
            getUser, props,
            departmentList, positionList,
            userTypes,
            setData, updateUser, updateUserType,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable="true"] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
