import { sysEnums, enumTypes } from '@/common/sysEnums';
import { Modal, Offcanvas } from 'bootstrap';
import { initializeApp } from 'firebase/app';
import { getToken, getMessaging } from 'firebase/messaging';
import store from '@/state/store';
import moment from 'moment';
import Swal from 'sweetalert2';

export const getFcmToken = async () => {
    const { firebaseConfig, firebaseVapidKey } = store.state.login;
    try {
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        const currentToken = await getToken(messaging, { vapidKey: firebaseVapidKey });

        if (currentToken) {
            return currentToken;
        } else {
            alert('토큰발행 오류...');
        }
    } catch (error) {
        console.log('토큰발행 실패 : ' + error);
        return '0000';
    }
};

export const calculateDayRemaining = targetDate => {
    const dt1 = moment();
    const dt2 = moment().add(1, 'months').date(targetDate).hour(12).minute(0).second(0);

    const duration = moment.duration(dt2.diff(dt1));

    return Math.floor(duration.asDays()); // 남은 일수
};

export const calculateDaysElapsed = (day, month) => {
    const currentYear = moment().year();
    const targetDate = moment()
        .year(currentYear)
        .month(month - 1) // Months are 0-based in moment.js, so we subtract 1
        .date(day)
        .hour(12)
        .minute(0)
        .second(0);

    const dt1 = moment();
    const dt2 = targetDate;

    const duration = moment.duration(dt1.diff(dt2));

    return Math.abs(Math.floor(duration.asDays())); // Elapsed days, absolute value
};

export const calculateDaysElapsedFromLastMonth = (day, monthsAgo) => {
    const currentDate = moment();
    const targetDate = moment().subtract(monthsAgo, 'months').date(day).hour(12).minute(0).second(0);

    const duration = moment.duration(currentDate.diff(targetDate));

    return Math.floor(duration.asDays());
};

export const calculateTimeRemaining = targetDate => {
    const dt1 = moment();
    const dt2 = moment().add(1, 'months').date(targetDate).hour(12).minute(0).second(0);

    const duration = moment.duration(dt2.diff(dt1));

    const daysDiff = Math.floor(duration.asDays()); // 남은 일수
    duration.subtract(daysDiff, 'days'); // 일 수를 뺀 나머지 시간 계산
    const hoursDiff = Math.floor(duration.asHours()); // 남은 시간
    duration.subtract(hoursDiff, 'hours'); // 시간을 뺀 나머지 분 계산
    const minutesDiff = Math.floor(duration.asMinutes()); // 남은 분

    // Use Math.abs to ensure positive values, indicating time remaining
    return `${Math.abs(daysDiff)} 일 ${Math.abs(hoursDiff)} 시간 ${Math.abs(minutesDiff)} 분 남음`;
};

export const getCotractPeriod = (startDate, endDate) => {
    const dt1 = new Date(startDate);
    const dt2 = new Date(endDate);

    const monthsDiff = (dt2.getFullYear() - dt1.getFullYear()) * 12 + (dt2.getMonth() - dt1.getMonth());

    return Math.max(0, monthsDiff);
};

export const setUserToStorage = (datas, flag) => {
    const user = {
        EncUser: datas.EncUser,
        UserType: datas.UserType,
        Department: datas.Department,
        Position: datas.Position,
        ServiceType: datas.ServiceType,
        Platform: datas.Platform,
        LoginId: datas.LoginId,
        UserName: datas.UserName,
        NickName: datas.NickName,
        Email: datas.Email,
        Uid: datas.Uid,
        Status: datas.Status,
        Checked: datas.Checked,
        Updated: datas.Updated,
        Created: datas.Created,
        Avatar: datas.Avatar,
    };

    if (flag) {
        localStorage.setItem(sysEnums.UserKey, JSON.stringify(user));
    }

    return user;
    // console.log('getUserInfo : '+ $store.getters['GE_USER'].EncUser);
};

export const modifiedUserName = userName => {
    if (userName) {
        const firstTwoLetters = userName.substring(0, 1);
        const asterisks = '*'.repeat(Math.max(userName.length - 2, 1));
        const lastTwoLetters = userName.substring(userName.length - 1, userName.length);
        return firstTwoLetters + asterisks + lastTwoLetters;
    }
};

export const modifiedUserPhone = pNumber => {
    if (pNumber) {
        if (pNumber.length >= 10) {
            const firstThreeLetters = pNumber.substring(0, 3);
            const asterisks = '*'.repeat(Math.max(pNumber.length - 7, 1));
            const lastFourLetters = pNumber.substring(pNumber.length - 4, pNumber.length);
            return firstThreeLetters + '' + asterisks + '' + lastFourLetters;
        }
    }
    return pNumber; // Return the original input if it doesn't match the expected format
};

export const closeCanvas = id => {
    const offcanvasElement = document.getElementById(id);
    if (offcanvasElement) {
        const bsOffcanvas = Offcanvas.getInstance(offcanvasElement);
        bsOffcanvas.hide();
    }
};

export const showCanvas = id => {
    const bsOffcanvas = new Offcanvas(id);
    if (bsOffcanvas) {
        bsOffcanvas.show();
    }
};

export const hideModal = id => {
    const modalElement = document.getElementById(id);
    if (modalElement) {
        const bsModal = Modal.getInstance(modalElement);
        bsModal.hide();
    }
};

export const showModal = id => {
    const bsModal = new Modal(id);
    if (bsModal) {
        bsModal.show();
    }
};

export const showAlert = (top, msg, bottom, type) => {
    type === sysEnums.AlertTypes.Primary
        ? Swal.fire(msg)
        : Swal.fire({
              title: top,
              html: msg,
              icon: enumTypes.AlertTypes[type],
              customClass: {
                  confirmButton: 'btn btn-primary w-100 rounded-pill P-4 mt-2',
              },
              footer: bottom,
              buttonsStyling: false,
              showCloseButton: false,
          });
};

export const showConfirm = async (top, msg, btnText) => {
    const result = await Swal.fire({
        title: top,
        html: msg,
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#34c38f',
        // cancelButtonColor: "#f46a6a",
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#cccccc',
        confirmButtonText: btnText,
    });

    if (result.value) {
        return result.value;
    } else {
        return false;
    }
};

export const playSound = flag => {
    try {
        let sound = './assets/sounds/effect2.mp3';
        if (flag === enumTypes.SignalTypes.Chat) {
            sound = './assets/sounds/effect3.mp3';
        }

        if (sound) {
            let audio = new Audio(sound);
            audio.play();
        }
    } catch (error) {
        console.error(error);
        alert('Error: ' + error);
    }
};

export const getLastMonth = () => {
    const currentDate = new Date();
    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    return lastMonth;
};

const getFee = (rent, managementFee, feeDiscountRate) => {
    const totalAmount = rent + managementFee;
    const fee = totalAmount * ((1 - feeDiscountRate) / 100);

    return fee;
};

export const getServiceFee = (rent, managementFee, feeDiscountRate) => {
    const fee = getFee(rent, managementFee, feeDiscountRate);
    const serviceFee = parseInt(fee * sysEnums.DiscoutTypes.Tax);

    return serviceFee;
};

export const getPartnerFee = (rent, managementFee, feeDiscountRate) => {
    const fee = getFee(rent, managementFee, feeDiscountRate);
    const serviceFee = fee * (40 / 100);
    const partnerFee = parseInt(serviceFee * sysEnums.DiscoutTypes.Tax);

    return partnerFee;
};

export const getPayStoryFee = (rent, managementFee, feeDiscountRate) => {
    const fee = getFee(rent, managementFee, feeDiscountRate);
    const serviceFee = fee * (20 / 100);
    const partnerFee = parseInt(serviceFee * sysEnums.DiscoutTypes.Tax);

    return partnerFee;
};

export const getLessorSent = (rent, managementFee, feeDiscountRate, cardFee) => {
    const totalAmount = rent + managementFee;
    const serviceFee = cardFee + (1 - feeDiscountRate);
    const payStoryFee = totalAmount * (serviceFee / 100);

    const lessorFee = (totalAmount - payStoryFee) * sysEnums.DiscoutTypes.Tax;

    return lessorFee;
};

export const getCardFee = (rent, managementFee, cardFee) => {
    // 1. 월별 수납 계산
    const totalAmount = rent + managementFee;

    // 2. 카드 결제 수수료 계산
    const paystoryCardFee = ((totalAmount * cardFee) / 100) * sysEnums.DiscoutTypes.Tax;

    return paystoryCardFee;
};

export const getHouseMetaFee = (rent, managementFee, feeDiscountRate, cardFee) => {
    // 1. 월별 수납 계산
    const totalAmount = rent + managementFee;

    // 2. 서비스 이용료 계산
    const serviceFee = cardFee + (1 - feeDiscountRate);
    const payStoryFee = totalAmount * (serviceFee / 100) * sysEnums.DiscoutTypes.Tax;

    return payStoryFee;
};

export const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
            const img = new Image();
            img.src = event.target.result;

            img.onload = () => {
                const aspectRatio = img.width / img.height;
                let newWidth, newHeight;

                if (img.width > img.height) {
                    newWidth = Math.min(img.width, maxWidth);
                    newHeight = newWidth / aspectRatio;
                } else {
                    newHeight = Math.min(img.height, maxHeight);
                    newWidth = newHeight * aspectRatio;
                }

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = newWidth;
                canvas.height = newHeight;
                ctx.drawImage(img, 0, 0, newWidth, newHeight);

                canvas.toBlob(blob => {
                    const resizedFile = new File([blob], file.name, { type: file.type });
                    resolve(resizedFile);
                });

                // jpeg 포맷으로
                // canvas.toBlob((blob) => {
                //     const resizedFile = new File([blob], file.name, { type: 'image/jpeg' });
                //     resolve(resizedFile);
                // }, 'image/jpeg');
            };
        };

        reader.onerror = error => {
            console.log(error);
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};

export const getNotiCount = list => {
    const count = list.reduce((count, item) => {
        if (item.Status === false) {
            return count + 1;
        }
        return count;
    }, 0);

    return count;
};

export const getCommentCount = list => {
    if (list && list.length > 0) {
        const count = list.reduce((count, item) => {
            if (item && Array.isArray(item.Comments)) {
                // console.log(item.Title + ' / ' + item.Comments.length)
                return count + item.Comments.length;
            }
            return count;
        }, 0);

        return count;
    }

    return 0;
};

// Exif 방향을 얻는 함수
export const getExifOrientation = async file => {
    return new Promise(resolve => {
        const reader = new FileReader();

        reader.onload = e => {
            const view = new DataView(e.target.result);

            if (view.getUint16(0, false) !== 0xffd8) {
                resolve(1); // 유효한 JPEG이 아닌 경우, 기본 방향
                return;
            }

            const length = view.byteLength;
            let offset = 2;

            while (offset < length) {
                const marker = view.getUint16(offset, false);
                offset += 2;

                if (marker === 0xffe1) {
                    if (view.getUint32((offset += 2), false) !== 0x45786966) {
                        resolve(1); // 유효한 Exif 데이터가 아닌 경우, 기본 방향
                        return;
                    }

                    const little = view.getUint16((offset += 6), false) === 0x4949;
                    offset += view.getUint32(offset + 4, little);

                    const tags = view.getUint16(offset, little);
                    offset += 2;

                    for (let i = 0; i < tags; i++) {
                        if (view.getUint16(offset + i * 12, little) === 0x0112) {
                            resolve(view.getUint16(offset + i * 12 + 8, little));
                            return;
                        }
                    }
                } else if ((marker & 0xff00) !== 0xff00) {
                    break;
                } else {
                    offset += view.getUint16(offset, false);
                }
            }

            resolve(1); // 기본 방향
        };

        reader.readAsArrayBuffer(file);
    });
};

export const getUserType = user => {
    let type = enumTypes.UserTypes[user.UserType];

    if (user.UserType === sysEnums.UserTypes.Partner && moment(user.Checked).format('YY') !== '00') {
        type += '(부동산중개인)';
    }

    return type;
};

export const getUserTypeList = data => {
    const user = data.find(item => item.Status === true);
    if (!user) {
        return 0;
    }

    let type = enumTypes.UserTypes[user.UserType];

    if (user.UserType === sysEnums.UserTypes.Partner && moment(user.Checked).format('YY') !== '00') {
        type += '(부동산중개인)';
    }

    return type;
};

// 콤마를 추가하는 함수
export const addCommas = val => {
    // return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return String(val)
        .replace(/^0+|\D+/g, '')
        .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
};

// 콤마를 제거하는 함수
export const removeCommas = val => {
    return String(val).replace(/,/g, '');
};

export const nextMonthPaymentDay = day => {
    const nextMonthDate = moment().date(day).add(5, 'days');
    return nextMonthDate.format('D');
};

export const prevMonthPaymentDay = day => {
    const nextMonthDate = moment().date(day).add(-5, 'days');
    return nextMonthDate.format('D');
};

export const goWidget = (to, dt) => {
    store.dispatch('AC_WIDGET', { target: to, data: dt });
};

export const dayLeftPercent = (dtStart, dtEnd) => {
    const startDate = new Date(dtStart);
    const endDate = new Date(dtEnd);
    const today = new Date();

    // Step 1: Calculate total duration
    const totalDuration = endDate - startDate;

    // Step 2: Calculate elapsed duration
    const elapsedDuration = today - startDate;

    // Step 3: Calculate percentage elapsed
    const percentageElapsed = (elapsedDuration / totalDuration) * 100;

    // console.log('Percentage Elapsed:', percentageElapsed.toFixed(0) + '%');

    return percentageElapsed.toFixed(0);
};

export const daysLeft = dtEnd => {
    const endDate = new Date(dtEnd);
    const today = new Date();

    const daysLeft = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24));
    // console.log('Days Left:', daysLeft);

    return daysLeft.toFixed(0);
};

export const timesElapsed = targetDate => {
    if (targetDate) {
        const startDate = moment(targetDate);
        const now = moment();

        const duration = moment.duration(now.diff(startDate));

        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();

        if (years > 0) {
            // If more than a year has passed, return in years, months, and days
            // return `${years}y ${months}m ${days}d`;
            return `${years}y ${months}m`;
        } else if (months > 0) {
            // If within a year but more than a month, return in months and days
            return `${months}m ${days}d`;
        } else if (days > 0) {
            // If within a month but more than a day, return in days, hours, and minutes
            // return `${days}d ${hours}h ${minutes}m`;
            return `${days}d ${hours}h`;
        } else if (hours > 0) {
            // If within a day but more than an hour, return in hours and minutes
            return `${hours}h ${minutes}m`;
        } else {
            // If within an hour, return in minutes and seconds
            const seconds = duration.seconds();
            return `${minutes}m ${seconds}s`;
        }
    } else {
        return '';
    }
};

export const timesElapsedTotal = targetDate => {
    if (targetDate) {
        const startDate = moment(targetDate);
        const now = moment();

        const duration = moment.duration(now.diff(startDate));

        const days = Math.floor(duration.asDays());
        const hours = duration.hours();
        const minutes = duration.minutes();

        return `${days}d ${hours}h ${minutes}m 전`;
    } else return '';
};

export const getUserDepartment = (dept, flag) => {
    const widget = store.getters['GE_WIDGET'];
    // console.log(widget.companyInfo.CompanyDepts);
    if (widget.companyInfo.CompanyDepts) {
        const data = widget.companyInfo.CompanyDepts.find(x => x.DeptNum === dept && x.IsPosition === flag);
        if (data && data.Title) {
            return data.Title;
        } else {
            return '미정';
        }
    }
};

export const getSignalStatus = encUser => {
    const signal = store.getters['chat/GE_SIGNAL'];
    if (signal) {
        return signal.some(x => x.EncUser === encUser && x.Status === true);
    }
    return false;
};

export const getReadStatusByRole = (encUser, roles) => {
    if (roles) {
        const result = roles.some(role => {
            const isUserMatch = role.EncUser === encUser;
            const isUpdatedValid = moment(role.Updated).year() > 0;
            // console.log(`Role: ${JSON.stringify(role, null, 4)}, isUserMatch: ${isUserMatch}, isUpdatedValid: ${isUpdatedValid}, Updated: ${moment(role.Updated).year()}`);
            return isUserMatch && isUpdatedValid;
        });
        return result;
    }
    return false;
};

export const getReadStatus = (encUser, comments) => {
    if (comments) {
        const result = comments.some(comment => comment.EncUser === encUser && comment.Readed);
        return result;
    }
    return false;
};

export const getConfirmUser = data => {
    if (data) {
        const result = data.findLast(x => moment(x.Confirmed).format('YYYY') === '0000');
        if (result) return result.UserName;
    }

    return 0; // 조건을 만족하는 요소가 없을 경우 0 반환
};

export const getConfirmStep = data => {
    if (data) {
        const index = data.findLastIndex(x => moment(x.Confirmed).format('YYYY') === '0000');
        return (data.length - (index + 1)) / data.length;
    }

    return 0;
};

export const getConfirmStepPercent = data => {
    if (data) {
        const index = data.findLastIndex(x => moment(x.Confirmed).format('YYYY') === '0000');
        const percent = ((data.length - (index + 1)) / data.length) * 100;
        return percent;
    }

    return 0;
};

export const intDecrypt = encNum => {
    let num = encNum;
    num = num.replace('6084JRax9S7xQcR55G2ZZw==', 0);
    num = num.replace('D9RhYu5Vp3UuFglDZUCyeA==', 1);
    num = num.replace('h8Fp1IwONBMBAw/EP0iyKg==', 2);
    num = num.replace('NGtCNVwrLJjvvLB6zgeTnQ==', 3);
    num = num.replace('nuwjGj2sIS/D0+c6dQJ0Bw==', 4);

    return parseInt(num, 10);
};


export const isAppCompact = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /wv|Android.*Version\/.*Chrome\/.*Mobile Safari\/|MyApp-iOS-WebView/.test(userAgent);
};

export const isAppDetail = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroidApp = /wv|Android.*Version\/.*Chrome\/.*Mobile Safari\//.test(userAgent);
    const isIOSApp = document.querySelector('meta[name="apple-mobile-web-app-capable"]') !== null || window.location.search.includes('isApp=true');
    return isAndroidApp || isIOSApp;
};

// Function to detect if the app is running in a mobile or web view
export const isApp = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Detect WebView for Android and iOS
    //return /wv|Android.*Version\/.*Chrome\/.*Mobile Safari\/|iPhone.*Safari/.test(userAgent);
    return /wv|Android.*Version\/.*Chrome\/.*Mobile Safari\/|iPhone.*AppleWebKit(?!.*Version)|iPad.*AppleWebKit(?!.*Version)/.test(userAgent);
};

export const isChromeBrowser = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const isStandalone = window.navigator.standalone || (window.matchMedia('(display-mode: standalone)').matches);