<script>
import { showAlert, showConfirm } from '@/common/comFuncs';
import { apiEnums, enumTypes, sysEnums } from '@/common/sysEnums';
import { inject, reactive, ref } from 'vue';

import { Korean } from 'flatpickr/dist/l10n/ko.js';
import flatPickr from 'vue-flatpickr-component';

import '@vueform/multiselect/themes/default.css';

export default {
    components: {
        flatPickr,
    },
    setup() {
        const isShare = ref(false);
        const encFolder = ref('');
        const selectedAlarms = ref([]);
        const endDate = ref(null);

        const items = ref([
            { label: '하루전', value: '1' },
            { label: '3일전', value: '2' },
            { label: '한달전', value: '3' },
            { label: '한달전', value: '4' },
        ]);

        const pageCategorys = reactive(sysEnums.PageCategorys);

        const axios = inject('$axios');

        const widgetInfo = inject('$widgetInfo');

        const userInfo = inject('$userInfo');

        const { selectedFolder, selectedPage, getUserPage, sendMessage, folderList, widgetClose } = inject('$provideList');

        const config = ref({
            wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'Y-m-d',
            altInput: true,
            dateFormat: 'Y-m-d',
            locale: Korean,
        });

        const createPage = () => {
            if (!selectedPage.value.Title) {
                showAlert('Alert!', '페이지 제목을 입력하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            showConfirm('', '페이지를 등록 합니다.', '등록하기').then(result => {
                if (result) {
                    const formData = new FormData();
                    formData.append('EncUser', userInfo.value.EncUser);
                    formData.append('EncFolder', encFolder.value || sysEnums.zero);
                    formData.append('Title', selectedPage.value.Title);
                    formData.append('Description', selectedPage.value.Description);
                    formData.append('Tag', widgetInfo.value.tag > 0 ? widgetInfo.value.tag : 0);
                    formData.append('Alarms', selectedAlarms.value.length > 0 ? selectedAlarms.value : '');
                    formData.append('Expired', endDate.value || '0001-01-01');
                    formData.append('Shared', isShare.value ? 1 : 0);
                    formData.append('Category', 0);
                    formData.append('Company', 5700191);
                    axios
                        .post(apiEnums.CloudTicket.createPage, formData)
                        .then(response => {
                            if (response.data !== '0000') {
                                // console.log('createRole : ' + selectedFolder.value.EncFolder + ' / ' + response.data);
                                if (encFolder.value) {
                                    createRole(response.data);
                                } else {
                                    getUserPage();
                                    widgetClose();
                                    widgetInfo.value.modal = false;
                                }
                            }
                        })
                        .catch(() => {});
                }
            });
        };

        const createRole = encPage => {
            // console.log('createRole : ' + encPage + ' / ' + userInfo.value.EncUser);
            const formData = new FormData();
            formData.append('EncFolder', encFolder.value);
            formData.append('EncPage', encPage);
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncOwner', userInfo.value.EncUser);
            axios
                .post(apiEnums.CloudTicket.createRole, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserPage();
                        widgetClose();
                        widgetInfo.value.modal = false;

                        sendMessage(null, sysEnums.SignalTypes.Folder);
                    }
                })
                .catch(() => {});
        };

        const updatePage = () => {
            if (!selectedPage.value) {
                showAlert('Alert!', '페이지를 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            if (!selectedPage.value.Title) {
                showAlert('Alert!', '페이지 제목을 입력하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncPage', selectedPage.value.EncPage);
            formData.append('Title', selectedPage.value.Title);
            formData.append('Description', selectedPage.value.Description);
            axios
                .post(apiEnums.CloudTicket.updatePage, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        widgetInfo.value.titleDetail = selectedPage.value.Title;
                        getUserPage();
                        widgetClose();
                        widgetInfo.value.modal = false;
                    }
                })
                .catch(() => {});
        };

        const submitAlarms = () => {
            const data = selectedAlarms.value.join(',');
            console.log(data);
            // axios.post('/api/save-items', { items: data });
            // 실제 API 요청을 사용할 때 주석을 제거하세요.
        };

        return {
            sysEnums,
            enumTypes,
            widgetInfo,
            encFolder,
            selectedFolder,
            selectedPage,
            pageCategorys,
            isShare,
            endDate,
            config,
            items,
            selectedAlarms,
            folderList,
            createPage,
            updatePage,
            submitAlarms,
        };
    },
};
</script>

<template>
    <BRow>
        <BCol lg="12">
            <BCard no-body>
                <BCardBody>
                    <div class="mㅛ-2">
                        <label class="form-label" for="product-title-input"
                            >{{ widgetInfo.titleModal === '새로운 일정 간편 등록하기' ? '새로운 일정을 등록합니다.' : '새로운 페이지를 작성합니다' }}
                            {{
                                widgetInfo.tag > 0
                                    ? ' (' + enumTypes.PageTags[widgetInfo.tag].name + ' 태그 선택 중)'
                                    : selectedFolder.EncFolder
                                    ? ' (' + selectedFolder.Name + ')'
                                    : ''
                            }}</label
                        >
                    </div>

                    <!-- {{ JSON.stringify(selectedPage) }} -->

                    <div v-if="!encFolder" class="form-floating text-center">
                        <select class="form-select" id="floatingSelect1" aria-label="POST선택" v-model="encFolder">
                            <option class="text-center" selected>POST선택</option>
                            <option class="text-center" v-for="x in folderList" :key="x" :value="x.EncFolder">{{ x.Name }}</option>
                        </select>
                        <label for="floatingSelect1">POST선택</label>
                    </div>

                    <div class="form-floating mt-4">
                        <input type="text" class="form-control" id="floatingInput1" placeholder="페이지 타이틀" v-model="selectedPage.Title" />
                        <label for="floatingInput1">페이지 타이틀</label>
                    </div>

                    <div class="mt-2">
                        <textarea class="form-control" rows="5" placeholder="페이지 설명" v-model="selectedPage.Description"></textarea>
                    </div>

                    <span v-if="widgetInfo.titleModal === '새로운 일정 간편 등록하기'">
                        <div class="form-floating mt-4">
                            <flat-pickr :config="config" :weekNumber="true" placeholder="계약 시작일" class="form-control" id="floatingInput9" v-model="endDate"></flat-pickr>
                            <label for="floatingInput9">종료일</label>
                        </div>

                        <div class="mt-4 d-flex align-items-center justify-content-between flex-wrap">
                            <label v-for="item in items" :key="item.value" class="d-flex align-items-center me-3">
                                <input type="checkbox" :value="item.value" v-model="selectedAlarms" class="me-2" />
                                {{ item.label }}
                            </label>
                        </div>
                    </span>

                    <div class="my-4 d-flex align-items-center">
                        <button :class="['btn waves-light', isShare ? 'btn-outline-primary' : 'btn-outline-secondary']" @click="isShare = !isShare">
                            <i :class="isShare ? 'ri-eye-fill' : 'ri-eye-off-line'"></i>
                        </button>
                        <span :class="['ps-2 flex-fill', { 'text-danger': isShare }]" v-text="isShare ? '등록과 동시에 페이지를 공유 합니다' : '등록 시 페이지를 숨김니다'"></span>
                    </div>

                    <div class="text-end">
                        <BButton class="p-2" @click="createPage">{{ widgetInfo.titleModal === '새로운 일정 간편 등록하기' ? '일정 등록하기' : '페이지 작성하기' }}</BButton>
                        <BButton class="ms-2 p-2" @click="updatePage">수정</BButton>
                    </div>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>
</template>
