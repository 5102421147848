<template>
    <div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 py-4 px-5">
        <div class="user-chat w-100 overflow-hidden border">
            <div class="chat-content d-lg-flex">
                <div class="w-100 overflow-hidden position-relative">
                    <div class="position-relative">
                        <!-- 상단 -->
                        <div class="p-3 user-chat-topbar">
                            <BRow class="align-items-center">
                                <!-- 상단 선택된 대상 아이콘 표시 좌측영역 -->
                                <BCol sm="4" cols="8">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 d-block d-lg-none me-3">
                                            <BLink class="user-chat-remove fs-18 p-1"><i class="ri-arrow-left-s-line align-bottom"></i></BLink>
                                        </div>
                                        <div class="flex-grow-1 overflow-hidden">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                                    <img
                                                        v-if="widgetInfo.selectedUser && widgetInfo.selectedUser.EncUser"
                                                        :src="widgetInfo.selectedUser.Avatar ? $fileUrl + widgetInfo.selectedUser.Avatar : 'assets/images/users/user-dummy-img.jpg'"
                                                        class="avatar-xs rounded-circle"
                                                        :class="getSignalStatus(widgetInfo.selectedUser.EncUser) > 0 ? '' : 'opacity-25'"
                                                    />
                                                    <img
                                                        v-else
                                                        :src="userInfo.Avatar ? $fileUrl + userInfo.Avatar : 'assets/images/users/user-dummy-img.jpg'"
                                                        class="avatar-xs rounded-circle"
                                                        :class="getSignalStatus(userInfo.EncUser) > 0 ? '' : 'opacity-25'"
                                                    />
                                                </div>
                                                <div class="flex-grow-1 overflow-hidden">
                                                    <h5 class="text-truncate mb-0 fs-16">
                                                        <BLink class="text-reset username"
                                                            >{{
                                                                widgetInfo.selectedUser && widgetInfo.selectedUser.UserName ? widgetInfo.selectedUser.UserName : userInfo.UserName
                                                            }}
                                                        </BLink>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </BCol>

                                <!-- 상단 우측 -->
                                <BCol sm="8" cols="4">
                                    <ul class="list-inline user-chat-nav text-end mb-0">
                                        <li class="list-inline-item m-0">
                                            <BDropdown
                                                variant="link"
                                                class="btn btn-ghost-secondary btn-icon"
                                                toggle-class="arrow-none"
                                                menu-class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg"
                                                auto-close="outside"
                                            >
                                                <BDropdownItemButton>
                                                    <div class="p-2 dropdown-menu-lg px-0 py-0">
                                                        <div class="search-box">
                                                            <input type="text" class="form-control bg-light border-light" placeholder="Search here..." id="searchMessage" />
                                                            <i class="ri-search-2-line"></i>
                                                        </div>
                                                    </div>
                                                </BDropdownItemButton>
                                            </BDropdown>
                                        </li>

                                        <li class="list-inline-item m-0">
                                            <BDropdown
                                                variant="link"
                                                class="btn btn-ghost-secondary btn-icon"
                                                toggle-class=" arrow-none"
                                                menu-class="dropdown-menu"
                                                aria-haspopup="true"
                                            >
                                                <BDropdownItem><i class="ri-inbox-archive-line align-bottom text-muted me-2"></i>Archive</BDropdownItem>
                                                <BDropdownItem><i class="ri-mic-off-line align-bottom text-muted me-2"></i>Muted</BDropdownItem>
                                                <BDropdownItem><i class="ri-delete-bin-5-line align-bottom text-muted me-2"></i>Delete</BDropdownItem>
                                            </BDropdown>
                                        </li>
                                    </ul>
                                </BCol>
                            </BRow>
                        </div>

                        <!-- {{ JSON.stringify(chatDataGroup) }} -->

                        <!-- 채팅영역 -->
                        <div class="position-relative" id="users-chat">
                            <simplebar class="chat-conversation p-3 p-lg-4" id="chat-conversation" data-simplebar ref="current">
                                <ul class="list-unstyled chat-conversation-list">
                                    <template v-for="x of chatDataGroup" :key="x">
                                        <li
                                            v-if="x.ChatType === sysEnums.NotiTypes.Group || x.EncUser === userInfo.EncUser || x.EncReceiver === userInfo.EncUser"
                                            :class="x.EncUser === userInfo.EncUser ? 'right' : 'left'"
                                            class="chat-list"
                                        >
                                            <div class="conversation-list">
                                                <div class="chat-avatar" v-if="x.EncUser !== userInfo.EncUser">
                                                    <img
                                                        :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/users/user-dummy-img.jpg'"
                                                        class="avatar-xs rounded-circle"
                                                        :class="getSignalStatus(userInfo.EncUser) > 0 ? '' : 'opacity-25'"
                                                    />
                                                </div>
                                                <div class="user-chat-content">
                                                    <div class="ctext-wrap">
                                                        <div class="ctext-wrap-content">
                                                            <p class="mb-0 ctext-content">
                                                                {{ x.Content }}
                                                            </p>
                                                        </div>

                                                        <div class="conversation-name" :class="x.EncUser === userInfo.EncUser ? 'pe-2' : 'ps-2'">
                                                            <small class="text-muted time">{{ $moment(x.Created).format('YYYY-MM-DD hh:mm:ss') }}</small>
                                                            <span class="text-success check-message-icon"><i class="ri-check-double-line align-bottom"></i></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </template>
                                </ul>
                            </simplebar>
                        </div>

                        <div class="chat-input-section p-3 p-lg-4">
                            <BRow class="g-0 align-items-center">
                                <BCol cols="auto">
                                    <div class="chat-input-links me-2">
                                        <div class="links-list-item">
                                            <BButton type="button" variant="link" class="text-decoration-none emoji-btn" id="emoji-btn">
                                                <i class="bx bx-smile align-middle"></i>
                                            </BButton>
                                        </div>
                                    </div>
                                </BCol>
                                <BCol>
                                    <input type="text" v-model="content" class="form-control chat-input bg-light border-light fs-13" placeholder="Enter Message..." />
                                </BCol>
                                <BCol cols="auto">
                                    <div class="chat-input-links ms-2">
                                        <div class="links-list-item">
                                            <BButton variant="success" class="chat-send fs-13" @click="sendMessage">
                                                <i class="ri-send-plane-2-fill align-bottom"></i>
                                            </BButton>
                                        </div>
                                    </div>
                                </BCol>
                            </BRow>
                        </div>

                        <div class="replyCard">
                            <BCard no-body class="mb-0">
                                <BCardBody class="py-3">
                                    <div class="replymessage-block mb-0 d-flex align-items-start">
                                        <div class="flex-grow-1">
                                            <h5 class="conversation-name"></h5>
                                            <p class="mb-0"></p>
                                        </div>
                                        <div class="flex-shrink-0">
                                            <BButton type="button" variant="link" size="sm" id="close_toggle" class="mt-n2 me-n3 fs-18">
                                                <i class="bx bx-x align-middle"></i>
                                            </BButton>
                                        </div>
                                    </div>
                                </BCardBody>
                            </BCard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, watch } from 'vue';
import { sysEnums, enumTypes } from '@/common/sysEnums';
import { showAlert, getSignalStatus } from '@/common/comFuncs';

import simplebar from 'simplebar-vue';
// import UserComp from "@/components/users/UserComp";
// import CompanyMap from "@/components/companys/CompanyMap";

export default {
    setup() {
        let content = ref('');
        const targetPage = ref('');
        const editorRef = ref(null);

        const store = inject('$store');
        const moment = inject('$moment');

        const widgetInfo = inject('$widgetInfo');
        const userInfo = inject('$userInfo');

        const { userList, treeData, gotoModal, selectedPage, selectedClips, chatDataGroup } = inject('$provideList');

        watch(
            () => chatDataGroup.value,
            () => {
                scrollToBottom('users-chat');
            },
            { deep: true },
        );

        // onMounted(() => {
        //     // filteredList.value = chats.value;
        //     getChatGroup();
        //     // checkMsg();

        //     // store.state.connection.on("ReceiveMsgChecked", (list) => {
        //     //     try {
        //     //         const datas = JSON.parse(list);
        //     //         messages.value.forEach(msg => {
        //     //             if (msg.ChartNum === datas.ChartNum) {
        //     //                 msg.Checked = datas.Checked; // 객체 업데이트
        //     //             }
        //     //         });

        //     //         // chatList.value.forEach(msg => {
        //     //         //     if (msg.ChartNum === datas.ChartNum) {
        //     //         //         msg.UnRead = 0; // 객체 업데이트
        //     //         //     }
        //     //         // });
        //     //         // console.log(JSON.stringify(datas));
        //     //     } catch (error) {
        //     //         console.error("Error parsing JSON:", error);
        //     //     }
        //     //     // playSound(enumTypes.SignalTypes.Chat);
        //     // });

        //     // 안드로이드 키보드 입력시 인풋 감춰짐 방지
        //     // window.visualViewport.onresize = changeHeight;
        // });

        const sendMessage = async () => {
            if (!content.value) {
                showAlert('Alert!', '내용을 입력하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            let encReceiver = userInfo.value.EncUser;
            let avatar = userInfo.value.Avatar;

            if (widgetInfo.value.selectedUser.EncUser) {
                encReceiver = widgetInfo.value.selectedUser.EncUser;
            }

            if (store.state.connection) {
                try {
                    if (content.value) {
                        if (widgetInfo.value.selectedUser.EncUser && widgetInfo.value.selectedUser.EncUser !== userInfo.value.EncUser) {
                            await store.state.connection.invoke(
                                'SendMessageToClient',
                                userInfo.value.EncUser,
                                userInfo.value.UserName,
                                encReceiver,
                                selectedPage.value.EncPage,
                                content.value,
                                avatar,
                                0,
                            );
                        } else {
                            await store.state.connection.invoke(
                                'SendMessageToGroup',
                                userInfo.value.EncUser,
                                userInfo.value.UserName,
                                selectedPage.value.EncPage,
                                content.value,
                                avatar,
                                sysEnums.NotiTypes.Group,
                            );
                        }

                        const data = {
                            ChatNum: 0,
                            ChatType: 0,
                            UserName: userInfo.value.UserName,
                            Content: content.value,
                            EncUser: userInfo.value.EncUser,
                            EncReceiver: encReceiver,
                            Checked: '0000',
                            Created: moment(),
                            Avatar: avatar,
                        };
                        chatDataGroup.value.push(data);
                        content.value = '';
                    }
                } catch (error) {
                    console.error('Error invoking SendMessageToClient:', error);
                }
            }
        };

        const scrollToBottom = id => {
            setTimeout(() => {
                var simpleBar = document.getElementById(id).querySelector('#chat-conversation .simplebar-content-wrapper')
                    ? document.getElementById(id).querySelector('#chat-conversation .simplebar-content-wrapper')
                    : '';

                var offsetHeight = document.getElementsByClassName('chat-conversation-list')[0]
                    ? document.getElementById(id).getElementsByClassName('chat-conversation-list')[0].scrollHeight - window.innerHeight + 600
                    : 0;

                if (offsetHeight)
                    simpleBar.scrollTo({
                        top: offsetHeight,
                        behavior: 'smooth',
                    });
            }, 500);
        };

        return {
            sysEnums,
            enumTypes,
            selectedClips,
            content,
            chatDataGroup,
            targetPage,
            widgetInfo,
            editorRef,
            userInfo,
            userList,
            treeData,
            getSignalStatus,
            gotoModal,
            sendMessage,
            scrollToBottom,
        };
    },
    components: {
        simplebar,
    },
};
</script>
