<template>
    <!-- <div class="card-header hand fixed-top position-relative pb-2" data-bs-dismiss="offcanvas">
        <i class="las la-angle-left fs-16 me-4"></i>
        <h6 class="card-title mb-0 d-inline-block">이전</h6>
    </div> -->

    <component v-if="widgetInfo.target === 'UserCreate'" :is="'UserCreate'" :UserData="widgetInfo.data"></component>
    <component v-else-if="widgetInfo.target === 'PageCreate'" :is="'PageCreate'"></component>
    <component v-else-if="widgetInfo.target === 'PageWizard'" :is="'PageWizard'"></component>
    <component v-else-if="widgetInfo.target === 'PageOption'" :is="'PageOption'"></component>
    <component v-else-if="widgetInfo.target === 'PageFile'" :is="'PageFile'"></component>
    <component v-else-if="widgetInfo.target === 'PageSetDate'" :is="'PageSetDate'"></component>
    <component v-else-if="widgetInfo.target === 'PageSetMap'" :is="'PageSetMap'"></component>
    <component v-else-if="widgetInfo.target === 'ChatInfo'" :is="'ChatInfo'"></component>
    <component v-else-if="widgetInfo.target === 'ChatList'" :is="'ChatList'"></component>
    <component v-else-if="widgetInfo.target === 'ClipViewer'" :is="'ClipViewer'" :url="widgetInfo.selectedUrl"></component>
    <!-- <component v-else> 이 코드는 사용 금지 무한루프 맥스스택 오류 발생 </component> -->
</template>

<script>
import ClipViewer from '@/components/clips/ClipViewer';
import ChatInfo from '@/components/notification/ChatInfo';
import ChatList from '@/components/notification/ChatList';
import { inject } from 'vue';
import PageCreate from '../components/pages/PageCreate';
import PageFile from '../components/pages/PageFile';
import PageOption from '../components/pages/PageOption';
import PageSetDate from '../components/pages/PageSetDate';
import PageSetMap from '../components/pages/PageSetMap';
import PageWizard from '../components/pages/PageWizard';
import UserCreate from '../components/users/UserCreate';

export default {
    components: {
        UserCreate,
        PageCreate,
        PageWizard,
        PageOption,
        PageFile,
        PageSetDate,
        PageSetMap,
        ChatInfo,
        ChatList,
        ClipViewer,
    },
    setup() {
        const widgetInfo = inject('$widgetInfo');

        return {
            widgetInfo,
        };
    },
};
</script>
