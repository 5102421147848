<template>
    <router-view v-slot="{ Component }">
        <component :is="Component" />
    </router-view>

    <!-- right offcanvas -->
    <BOffcanvas
        body-class="border-0"
        header-class="pb-0 mb-0"
        translate="no"
        :backdrop="false"
        no-header-close
        v-model="widgetInfo.canvas"
        placement="start"
        :title="widgetInfo.title || 'WidgetView'"
        :class="widgetInfo.isApp ? 'w-100' : 'w-75'"
        id="offcanvasWidget"
        @close="widgetClose"
    >
        <template #title>
            <div class="pt-4 mt-4" @click="widgetClose"><i class="ri-arrow-left-s-line me-2 align-middle"></i> {{ widgetInfo.title }}</div>
        </template>
        <WidgetView></WidgetView>
    </BOffcanvas>

    <!-- start offcanvas -->
    <BOffcanvas
        body-class="border-0"
        header-class="pb-0 mb-0"
        translate="no"
        :backdrop="false"
        no-header-close
        v-model="widgetInfo.canvasDetail"
        placement="end"
        class="w-100"
        :title="widgetInfo.titleDetail || 'WidgetDetail'"
        id="offcanvasWidgetDetail"
        @close="widgetClose"
    >
        <template #title>
            <div v-if="widgetInfo.target === 'AppPageDetail'" class="d-flex align-items-center mt-4 mb-2">
                <span class="flex-fill" @click="widgetClose"><i class="ri-arrow-left-s-line me-2 align-middle"></i>{{ widgetInfo.titleDetail }}</span>
                <span>
                    <i class="ri-image-line fw-normal" @click="gotoModal(selectedPage, 'FileUpload', '선택 파일 업로드')"></i>

                    <!--<BCard no-body class="p-2">
                        <i class="ri-quill-pen-line fs-30" @click="editorRef.createClip(sysEnums.ClipTypes.Draw)"></i>
                    </BCard>-->
                </span>
            </div>
            <div v-else :class="['pt-4', widgetInfo.isApp ? 'mt-4 mb-3' : 'mb-4']" @click="widgetClose">
                <i class="ri-arrow-left-s-line me-2 align-middle"></i> {{ widgetInfo.titleDetail }}
            </div>
        </template>
        <WidgetDetail></WidgetDetail>
    </BOffcanvas>

    <!-- start offcanvas -->
    <!--<BOffcanvas
        body-class="border-0"
        header-class="pt-5 pb-1 mt-2"
        class="w-100"
        translate="no"
        backdrop="false"
        no-header-close
        v-model="widgetInfo.canvasDetail"
        placement="end"
        :title="widgetInfo.titleDetail || 'WidgetDetail'"
        id="offcanvasWidgetDetail"
        @close="widgetClose"
    >
        <template #title>
            <div @click="widgetClose"><i class="ri-arrow-left-s-line me-2 align-middle"></i> {{ widgetInfo.titleDetail }}</div>
        </template>
        <WidgetDetail></WidgetDetail>
    </BOffcanvas>    -->

    <BModal
        v-model="widgetInfo.modal"
        class="v-modal-custom"
        :modal-class="{ flip: !widgetInfo.isApp }"
        :title="widgetInfo.titleModal"
        :size="widgetInfo.isApp ? 'xl' : widgetInfo.modalSize"
        centered
        scrollable
        hide-footer
        :backdrop="false"
        @close="modalClose"
    >
        <ModalView></ModalView>
    </BModal>
</template>

<script lang="js">
import { setUserToStorage, showAlert, isApp } from '@/common/comFuncs';
import { apiEnums, enumTypes, sysEnums } from '@/common/sysEnums';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { BCol, BRow } from 'bootstrap-vue-next';
import { computed, inject, onBeforeUnmount, onMounted, provide, ref } from 'vue';
// import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";

export default {
    name: 'App',
    setup() {
        const userList = ref({});
        const notiList = ref({});
        const serviceList = ref({});
        const companyList = ref({});
        const workspaceList = ref({});
        const clipList = ref({});
        const fileList = ref({});

        const chatData = ref([]);
        const chatDataGroup = ref([]);
        // const paydoList = ref({});
        // const billList = ref({});
        const alarmList = ref({});

        const totalPeriods = ref([]);
        const curPeriods = ref([]);

        const filteredFolder = ref([]);
        const filteredPage = ref([]);
        const filteredNoti = ref({});
        const filteredSignal = ref({});

        const signalReceived = ref([]);

        const treeData = ref({ Name: 'WORKSPACE', children: [] });

        const selectedFolder = ref([]);
        const selectedPage = ref([]);
        const selectedClips = ref([]);

        const router = inject('$router');
        const axios = inject('$axios');
        const store = inject('$store');
        const moment = inject('$moment');

        const userInfo = inject('$userInfo');
        const widgetInfo = inject('$widgetInfo');

        const pages = inject('$pages');
        const folders = inject('$folders');
        const signals = inject('$signals');

        const pageClipUser = localStorage.getItem(sysEnums.UserKey);

        onMounted(() => {
            loginProc();

            getUser();
            getNoti();
            getCompany();
            getFiles();
            getAlarmList();

            initAppChannel();
        });

        onBeforeUnmount(() => {
            store.state.connection.stop();
        });

        // 앱 진입 시점 검증 메서드
        const loginProc = async () => {
            if (pageClipUser) {
                const userData = JSON.parse(pageClipUser);
                // console.log(JSON.stringify(userData.value, null, 4))

                if (userData.EncUser === null || userData.EncUser === '') {
                    localStorage.removeItem(sysEnums.UserKey);
                }

                if (userData.EncUser) {
                    await getUserInfo();
                } else {
                    localStorage.removeItem(sysEnums.UserKey);
                }

                // if (window.navigator.standalone) {
                //     // PWA가 이미 설치되어 있는 경우, ios 에서만 감지
                //     alert(`PWA is already installed : Opening safari standalone ${JSON.stringify(deferredPrompt.value)}`);
                //     console.log('PWA is already installed. Opening Safari...');
                // }

                // if (window.matchMedia('(display-mode: standalone)').matches) {
                //     // PWA가 이미 설치되어 있는 경우, ios 안드로이드 모두 동작
                //     alert('PWA is already installed. Opening Chrome standalone...');
                //     console.log('PWA is already installed...');
                // }

                // if (deferredPrompt.value) {
                //     setTimeout(() => {
                //         showCanvas('#offcanvasBottom')
                //     }, 1200);
                // }

                // if (userData.value?.Uid === undefined) {
                //     showAlert('', '알림설정 차단중 입니다!<br><br>웹서비스 사용시<br>알림권한을 수락해 주세요.', '', sysEnums.AlertTypes.Info);
                // }
            } else {
                // 가입 화면으로 이동
                if (isApp()) {
                    widgetInfo.value.isApp = true;
                    router.push('/applogin');
                } else {
                    router.push('/login');
                }
            }
        };

        const getUserInfo = async () => {
            if (pageClipUser) {
                const userData = JSON.parse(pageClipUser);
                const formData = new FormData();
                formData.append('EncUser', userData.EncUser);
                axios
                    .post(apiEnums.CloudTicket.getUserInfo, formData)
                    .then(async response => {
                        const datas = response.data;
                        //console.log(JSON.stringify(response.data))
                        if (datas === '0000') {
                            localStorage.removeItem(sysEnums.UserKey);
                            showAlert('', '회원정보가 초기화 되었습니다!<br><br>회원가입을 다시 진행 하세요.', userData.EncUser, sysEnums.AlertTypes.Info);

                            if (isApp()) {
                                widgetInfo.value.isApp = true;
                                router.push('/applogin');
                            } else {
                                router.push('/login');
                            }
                        } else {
                            store.dispatch('AC_USER', datas);

                            if (datas.Uid === '' || datas.Uid !== userData.Uid || datas.EncUser !== userData.EncUser) {
                                // checkNotification(datas.EncUser);
                                setUserToStorage(datas, true);
                            }

                            await initSignalR(datas.EncUser);
                            // getUserNoti(datas.EncUser);
                            // checkNotification(datas.EncUser);
                            await getUserFolder();
                            await getUserPage();

                            if (isApp()) {
                                widgetInfo.value.isApp = true;

                                if (!datas.Avatar) {
                                    datas.Avatar = '';
                                }

                                //if (datas.LoginId == '01093416539' || datas.LoginId == '01036586539') {
                                //    alert(JSON.stringify(datas));
                                //}
                                router.push('/app');

                                toApp('loginInfo', JSON.stringify(datas));
                            } else {
                                widgetInfo.value.isApp = false;
                                //router.push('/app');
                                // console.log(' App Onmounted ' + JSON.stringify(datas))
                            }
                        }
                    })
                    .catch(() => {});
            }
        };

        const getUser = async () => {
            axios
                .post(apiEnums.CloudTicket.getUserConsole)
                .then(async response => {
                    store.dispatch('AC_USERS', response.data);
                    userList.value = response.data;
                    //console.log(JSON.stringify(response.data))
                })
                .catch(() => {});
        };

        const getNoti = () => {
            axios
                .post(apiEnums.CloudTicket.getNotiConsole)
                .then(async response => {
                    store.dispatch('AC_NOTI', response.data);
                    notiList.value = response.data;
                    await dataFilterNoti(response.data);
                })
                .catch(() => {});
        };

        const getCompany = () => {
            axios
                .post(apiEnums.CloudTicket.getCompanyConsole)
                .then(async response => {
                    store.dispatch('AC_COMPANY', response.data);
                    companyList.value = response.data;

                    widgetInfo.value.companyInfo = response.data.find(x => x.EncCompany === userInfo.value.EncCompany) || [];
                    // console.log(JSON.stringify(widgetInfo.value.companyInfo))
                })
                .catch(() => {});
        };

        const getFiles = () => {
            axios
                .post(apiEnums.CloudTicket.getFileConsole)
                .then(response => {
                    store.dispatch('AC_FILE', response.data);
                    fileList.value = response.data;
                })
                .catch(() => {});
        };

        const getAlarmList = () => {
            axios
                .post(apiEnums.CloudTicket.getAlarmList)
                .then(response => {
                    store.dispatch('AC_ALARM', response.data);
                    alarmList.value = response.data;
                })
                .catch(() => {});
        };

        const setUid = (uid, encUser) => {
            const formData = new FormData();
            formData.append('EncUser', encUser);
            formData.append('Uid', uid);
            axios
                .post(apiEnums.CloudTicket.setUid, formData)
                .then(response => {
                    if (response.data == '1000') {
                        // console.log("getUserInfo fcmToken : " + userData.value.EncUser + ' / ' + uid);
                        // pageClipUser = JSON.parse(localStorage.getItem(sysEnums.UserKey));
                        pageClipUser.Uid = uid;
                        localStorage.setItem(sysEnums.UserKey, JSON.stringify(pageClipUser));

                        //showAlert('', '새로운 토큰이 발행 되었습니다.', '', sysEnums.AlertTypes.Success);
                    }
                })
                .catch(() => {});
        };

        const dataFilterNoti = async datas => {
            let obj = {};
            datas.forEach(item => {
                if (!obj[item.NotiType]) {
                    obj[item.NotiType] = [];
                }
                obj[item.NotiType].push(item);
            });
            filteredNoti.value = obj;
            // console.log(JSON.stringify(datas));
        };

        const initSignalR = async () => {
            if (userInfo.value.EncUser === null || userInfo.value.EncUser === undefined || userInfo.value.EncUser === '') {
                return;
                // console.log('initSignalR '+ JSON.stringify(userInfo.value))
                // router.push('/login');
            }

            store.state.connection = new HubConnectionBuilder()
                .withUrl(apiEnums.CloudTicket.initSignalR + encodeURIComponent(userInfo.value.EncUser), {
                    withCredentials: false,
                    skipNegotiation: false,
                    transport: HttpTransportType.None,
                    accessTokenFactory: () => store.state.signalToken,
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Error)
                .build();

            store.state.connection
                .start()
                .then(() => {
                    console.log('SignalR Connected...');
                })
                .catch(() => {});

            store.state.connection.on('ReceiveUserList', response => {
                // console.log("SignalR ReceiveUserList : " + response[0].ConnectionId);
                store.dispatch('chat/AC_SIGNAL', JSON.parse(response));
                filteredSignal.value = JSON.parse(response);
            });

            store.state.connection.on('ReceiveUserData', response => {
                // 여기서는 SignalR encUser로 리턴 대소문자 주의
                const userIndex = signals.value.findIndex(item => item.EncUser === response.encUser);
                // console.log(JSON.stringify(response) + " / " + userIndex);

                if (userIndex !== -1) {
                    store.commit('chat/MU_SIGNAL_UP', { index: userIndex, data: response });
                }
            });

            store.state.connection.on('ReceiveWorkspace', response => {
                clipList.value = JSON.parse(response);
                // console.log('ReceiveWorkspace : '+ JSON.stringify(response));
            });

            store.state.connection.on('ReceiveReset', response => {
                widgetInfo.value.loaded = false;
                const result = JSON.parse(response);

                if (result.SignalType === enumTypes.SignalTypes.UserUpdated) {
                    getUser();
                } else {
                    // showAlert('', result.Msg, '', sysEnums.AlertTypes.Success);
                    getUserFolder();
                    getUserPage();
                }

                if (result.SignalType === enumTypes.SignalTypes.Invite) {
                    showAlert('', result.Msg, '', sysEnums.AlertTypes.Success);
                }

                signalReceived.value.push({ title: result.Msg, received: moment().format('YYYY-MM-DD HH:mm:ss') });
            });

            // 텍스트클립에서 텍스트변경시 즉시반영 (주의)서버 환경 고려 할것
            store.state.connection.on('ReceiveResetClip', response => {
                const result = JSON.parse(response);

                if (result.EncPage === selectedPage.value.EncPage) {
                    const idx = selectedClips.value.findIndex(x => x.EncClip === result.EncClip);

                    if (idx > -1) {
                        selectedClips.value[idx].Content = result.Data;
                        // selectedClips.value[idx] = { ...selectedClips.value[idx], Content: JSON.stringify(result.Data) };
                        // console.log('ReceiveResetClip : ' + JSON.stringify(selectedClips.value[idx].Content));
                    }
                }
            });

            store.state.connection.on('ReceiveMessageFromSender', response => {
                const obj = JSON.parse(response);
                const data = {
                    ChatNum: 0,
                    ChatType: parseInt(obj.Type),
                    UserName: obj.UserName,
                    Content: obj.Content,
                    EncUser: obj.EncUser,
                    EncReceiver: obj.EncReceiver,
                    Checked: '0000',
                    Created: moment(),
                    Avatar: obj.Avatar,
                };

                // console.log(' CompIndex getContract... ' + JSON.stringify(data))

                // unReadNoti.value++;

                if (obj.EncUser !== userInfo.value.EncUser) chatDataGroup.value.push(data);

                // chatGroupData.value.forEach((chat) => {
                //     if (data.EncUser === chat.EncUser) {
                //         if (chat.LastMsg === null) {
                //             chat.LastMsg = { Content: data.Content };
                //         } else {
                //             chat.LastMsg.Content = data.Content;
                //         }
                //         chat.UnRead += 1;
                //     }
                // });

                // playSound(enumTypes.SignalTypes.Chat);
                //store.dispatch("AC_WIDGET", { target: 'CompChat', data: selected });
            });
        };

        const sendMessage = async (data, proc) => {
            // console.log('getConnectionId : ' + JSON.stringify(store.state.connection) + ' / ' + data + ' / ' + proc);
            if (store.state.connection) {
                try {
                    if (data) {
                        if (proc === sysEnums.SignalTypes.Default) await store.state.connection.invoke('SendUserReset', userInfo.value.EncUser, proc);
                        if (proc === sysEnums.SignalTypes.Invite) await store.state.connection.invoke('SendUserReset', data, proc);
                        if (proc === sysEnums.SignalTypes.Page) await store.state.connection.invoke('SendUserReset', data, proc);
                        if (proc === sysEnums.SignalTypes.Clip) {
                            await store.state.connection.invoke('SendClip', selectedPage.value.EncPage, widgetInfo.value.selectedClip.EncClip, userInfo.value.EncUser, data, proc);
                        }
                        // console.log('sendMessage with data : '+ selectedPage.value.EncPage + ' / ' + widgetInfo.value.selectedClip.EncClip);
                    } else {
                        if (proc === sysEnums.SignalTypes.Folder) await store.state.connection.invoke('SendFolder', userInfo.value.EncUser, proc);
                        if (proc === sysEnums.SignalTypes.Page) await store.state.connection.invoke('SendFolder', userInfo.value.EncUser, proc);
                    }
                } catch (error) {
                    console.error('Error invoking sendMessage : ', error);
                }
            }
        };

        const getUserFolder = async () => {
            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            axios
                .post(apiEnums.CloudTicket.getUserFolder, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        store.dispatch('AC_FOLDER', response.data);

                        // 열람대상 지정하기 상태 업데이트
                        if (selectedFolder.value.EncFolder) {
                            const idx = response.data.findIndex(x => x.EncFolder === selectedFolder.value.EncFolder);

                            if (idx > -1) {
                                selectedFolder.value = response.data[idx];
                            }
                            // console.log(idx + ' / ' + JSON.stringify(response.data[idx], null, 4));
                        }

                        // folderList.value = response.data.filter(x => x.EncTopFolder === sysEnums.zero);
                        buildTree();
                    }
                })
                .catch(() => {});
        };

        // 본인글 아닌경우 테스트시 selectedPage 인덱스 없슴 테스트시 참고
        const getUserPage = async () => {
            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            axios
                .post(apiEnums.CloudTicket.getUserPage, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        store.dispatch('AC_PAGE', response.data);

                        // 열람대상 지정하기 상태 업데이트
                        if (selectedPage.value.EncPage) {
                            const idx = response.data.findIndex(x => x.EncPage === selectedPage.value.EncPage);

                            if (idx > -1) {
                                selectedPage.value = response.data[idx];
                            }
                            // console.log(idx + ' / ' + JSON.stringify(response.data[idx], null, 4));
                        }

                        setTimeout(() => {
                            widgetInfo.value.loaded = true;
                        }, 900);
                    }
                })
                .catch(() => {});
        };

        const getUserClip = () => {
            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncPage', selectedPage.value.EncPage);
            axios
                .post(apiEnums.CloudTicket.getUserClip, formData)
                .then(response => {
                    // console.log(widgetInfo.value.pageWid)
                    if (response.data !== '0000') {
                        response.data.forEach(data => (data.Xpos = calculatePositionInteger(data.Xpos)));

                        setTimeout(() => {
                            selectedClips.value = response.data;
                            widgetInfo.value.selectedClip = [];
                        }, 300);

                        // if (response.data && typeof response.data === 'object' && Object.keys(response.data).length > 0) {
                        //   Object.assign(selectedClips.value, response.data);
                        // }
                        // console.log('AppVue getUserClip : ' + JSON.stringify(response.data));
                    }
                })
                .catch(() => {});
        };

        const getChatData = () => {
            if (userInfo.value && !userInfo.value.EncUser) {
                return null;
            }

            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            axios
                .post(apiEnums.CloudTicket.getChatData, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        chatData.value = response.data;
                        // console.log('AppVue getChatGroup : ' + JSON.stringify(response.data));
                    }
                })
                .catch(() => {});
        };

        const getChatGroup = () => {
            if (selectedPage.value && !selectedPage.value.EncPage) {
                return null;
            }

            // console.log(selectedPage.value.EncPage)

            const formData = new FormData();
            formData.append('EncPage', selectedPage.value.EncPage);
            axios
                .post(apiEnums.CloudTicket.getChatGroup, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        chatDataGroup.value = response.data;
                        // console.log('AppVue getChatGroup : ' + JSON.stringify(response.data));
                    }
                })
                .catch(() => {});
        };

        // 함수: 퍼센트 값을 뷰포트 상대 좌표의 정수 값으로 계산
        const calculatePositionInteger = percentage => {
            const integerValue = (percentage * widgetInfo.value.pageWid) / 100;
            // console.log('calculatePositionInteger :' + percentage, widgetInfo.value.pageWid, integerValue);
            return integerValue;
        };

        const setClipSort = (data, sortFlag) => {
            // console.log('setClipSort AppVue :' + data.EncUser, data.EncClip);
            if (data.EncUser === '' || data.EncClip === '') {
                return null;
            }

            const formData = new FormData();
            formData.append('EncUser', data.EncUser);
            formData.append('EncClip', data.EncClip);
            formData.append('Sort', sortFlag);
            axios
                .post(apiEnums.CloudTicket.updateClip, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        selectedClipsReset(response.data);
                        sendMessage(JSON.stringify(response.data), sysEnums.SignalTypes.Default);
                        // console.log('setClipPos response :' + JSON.stringify(response.data));
                    }
                })
                .catch(() => {});
        };

        // 페이지 업데이트시 갱신
        const setClipSortTarget = (data, encTarget) => {
            // console.log('setClipSort AppVue :' + data.EncUser, data.EncClip);
            if (data.EncUser === '' || data.EncClip === '') {
                return null;
            }

            const formData = new FormData();
            formData.append('EncUser', data.EncUser);
            formData.append('EncClip', data.EncClip);
            formData.append('EncTarget', encTarget);
            axios
                .post(apiEnums.CloudTicket.updateClip, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserClip();
                        sendMessage(JSON.stringify(response.data), sysEnums.SignalTypes.Page);
                        // console.log('setClipSortTarget :' + JSON.stringify(response.data));
                    }
                })
                .catch(() => {});
        };

        const setFolderUpdate = (data, handle) => {
            if (data.EncUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '폴더 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            if (!data.EncFolder) return;

            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncFolder', data.EncFolder);

            switch (handle) {
                case sysEnums.ClipHandles.Shared:
                    formData.append('Shared', 1);
                    break;
                case sysEnums.ClipHandles.Fixed:
                    formData.append('Fixed', 1);
                    break;
                default:
                    return;
            }

            axios
                .post(apiEnums.CloudTicket.updateFolder, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        sendMessage(null, sysEnums.SignalTypes.Folder);
                    }
                })
                .catch(() => {});
        };

        const updateFolder = (encFolder, encTopFolder) => {
            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncFolder', encFolder);
            formData.append('EncTopFolder', encTopFolder);
            axios
                .post(apiEnums.CloudTicket.updateFolder, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        // console.log('updateFolder : ' + JSON.stringify(response.data))

                        if (widgetInfo.value.target === 'FolderMove') {
                            widgetClose();
                        }
                    }
                })
                .catch(() => {});
        };

        const updateFolderFix = (encUser, encFolder) => {
            if (encUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '폴더 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            if (encFolder) {
                const formData = new FormData();
                formData.append('EncUser', userInfo.value.EncUser);
                formData.append('EncFolder', encFolder);
                formData.append('Fixed', 1);
                axios
                    .post(apiEnums.CloudTicket.updateFolder, formData)
                    .then(response => {
                        if (response.data !== '0000') {
                            getUserFolder();
                            sendMessage(null, sysEnums.SignalTypes.Folder);
                        }
                    })
                    .catch(() => {});
            } else {
                console.log('updateFolderFix error : ' + JSON.stringify(props.Data));
            }
        };

        const updateFolderShare = (encUser, encFolder) => {
            if (encUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '폴더 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncFolder', encFolder);
            formData.append('Shared', 1);
            axios
                .post(apiEnums.CloudTicket.updateFolder, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        sendMessage(null, sysEnums.SignalTypes.Folder);
                    }
                })
                .catch(() => {});
        };

        // 페이지를 작업공간 메뉴에 드롭
        const createRole = (encFolder, encRole, encPage) => {
            const formData = new FormData();
            formData.append('EncFolder', encFolder);
            formData.append('EncPage', encPage);
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncOwner', userInfo.value.EncUser);
            if (encRole) {
                formData.append('EncRole', encRole);
            }
            axios
                .post(apiEnums.CloudTicket.createRole, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        getUserPage();

                        sendMessage(null, sysEnums.SignalTypes.Folder);
                    }
                })
                .catch(() => {});
        };

        const setClipUpdate = (data, handle) => {
            if (!data.EncUser) return;

            // if (data.EncUser === '' || data.EncClip === '') {
            //     return null;
            // }

            const formData = new FormData();
            formData.append('EncUser', data.EncUser);
            formData.append('EncClip', data.EncClip);

            switch (handle) {
                case sysEnums.ClipHandles.Hided:
                    formData.append('Hided', data.Hided ? 0 : 1);
                    break;
                case sysEnums.ClipHandles.Status:
                    formData.append('Status', data.Status ? 0 : 1);
                    break;
                case sysEnums.ClipHandles.Fixed:
                    formData.append('Fixed', data.Fixed ? 0 : 1);
                    break;
                case sysEnums.ClipHandles.Del:
                    formData.append('Status', 0);
                    console.log('Status Del Only');
                    break;
                default:
                    return; // Handle unknown cases
            }

            axios
                .post(apiEnums.CloudTicket.updateClip, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        if (handle === sysEnums.ClipHandles.Fixed) {
                            selectedClipsReset(response.data);
                        } else {
                            getUserClip();
                        }
                        sendMessage(JSON.stringify(response.data), sysEnums.SignalTypes.Clip);
                        // console.log('setClipUpdate : ' + JSON.stringify(response.data))
                    }
                })
                .catch(error => {
                    console.error('Error updating clip:', error);
                });
        };

        const selectedClipsReset = data => {
            const idx = selectedClips.value.findIndex(x => x.EncClip === data.EncClip);
            if (idx !== -1) {
                selectedClips.value[idx] = data;
                console.log('AppVue selectedClipsReset : ' + JSON.stringify(data.EncClip, null, 4));
            }
        };

        const buildTree = () => {
            const items = {};

            folders.value.forEach(item => {
                items[item.EncFolder] = { ...item, children: [] };
            });

            folders.value.forEach(item => {
                if (item.FolderRoles && item.FolderRoles.length > 0) {
                    // 여기서 하위 폴더 찾기
                    const topFolder = item.FolderRoles.find(x => x.EncFolder === item.EncFolder && x.EncUser === userInfo.value.EncUser);

                    // 폴더에 하위롤이 있다면 롤 추가
                    if (topFolder) {
                        const parent = items[topFolder.EncTopFolder];
                        if (parent && parent.children && topFolder.EncTopFolder !== sysEnums.zero) {
                            parent.children.push(items[item.EncFolder]);

                            // 여기서 폴더를 삭제 하지 않으면 리스트에 같은 폴더가 2개 보이겠지
                            if (item.Shared) {
                                delete items[topFolder.EncFolder];
                            }
                        }
                    }
                }

                if (item.EncUser === userInfo.value.EncUser && item.EncTopFolder && item.EncTopFolder !== sysEnums.zero) {
                    // 상위 폴더를 찾고 하위 차일드에 폴더 추가
                    const parent = items[item.EncTopFolder];
                    if (parent && parent.children) {
                        parent.children.push(items[item.EncFolder]);
                    }
                }
            });

            const topLevelItems = Object.values(items).filter(item => item.EncTopFolder === sysEnums.zero || (item.Shared && item.EncUser !== userInfo.value.EncUser));
            treeData.value.children = topLevelItems;
        };

        const selectedTree = computed(() => {
            let filterCondition;
            let topLevelItems = [];

            if (selectedFolder.value.Shared) {
                // 포스트선택
                filterCondition = item => item.EncFolder === selectedFolder.value.EncFolder;
            } else {
                // 폴더선택시
                filterCondition = item => item.EncUser === userInfo.value.EncUser && item.EncFolder === selectedFolder.value.EncFolder;
            }

            const filteredChildren = treeData.value.children.filter(filterCondition);

            if (filteredChildren.length > 0) {
                if (filteredChildren[0].children && filteredChildren[0].children.length > 0) {
                    topLevelItems = filteredChildren[0].children;
                } else {
                    topLevelItems = filteredChildren;
                }
            } else {
                topLevelItems = [];
            }

            // console.log('AppVue selectedTree : ' + JSON.stringify(topLevelItems, null, 4));

            return {
                Name: 'WORKSPACE',
                children: topLevelItems,
            };
        });

        const folderList = computed(() => {
            if (!selectedFolder.value) return [];

            if (!Array.isArray(folders.value) || folders.value.length === 0) return [];

            if (widgetInfo.value.tag === sysEnums.PageTags.Search) {
                return filteredFolder.value;
            }

            // folders.value = folders.value.filter(x => x.EncUser === userInfo.value.EncUser || x.FolderRoles.some(role => role.EncUser === userInfo.value.EncUser));

            let filteredFolders = [];

            if (typeof selectedFolder.value.EncFolder === 'string' && selectedFolder.value.EncFolder.length > 10) {
                // 선택한 폴더의 하위 폴더 리스트 반환
                filteredFolders = folders.value.filter(
                    x =>
                        (x.EncUser === userInfo.value.EncUser && x.EncTopFolder === selectedFolder.value.EncFolder) ||
                        x.FolderRoles.some(
                            role => role.EncUser === userInfo.value.EncUser && role.EncFolder === x.EncFolder && role.EncTopFolder === selectedFolder.value.EncFolder,
                        ),
                );
            } else {
                // 모든 최상위 폴더의 리스트 반환
                // filteredFolders = folders.value;
                // filteredFolders = folders.value.filter(x => x.EncTopFolder === sysEnums.zero);
                filteredFolders = folders.value.filter(
                    x =>
                        (x.EncUser === userInfo.value.EncUser && x.EncTopFolder === sysEnums.zero) ||
                        x.FolderRoles.some(role => role.EncUser === userInfo.value.EncUser && role.EncFolder === x.EncFolder && role.EncTopFolder === sysEnums.zero),
                );
            }

            filteredFolders.sort((a, b) => {
                return a.Type - b.Type;
            });

            // 정렬 기준에 따라 정렬
            if (widgetInfo.value.sort > 0) {
                switch (widgetInfo.value.sort) {
                    case sysEnums.SortTypes.Created:
                        // Created 기준으로 정렬
                        filteredFolders.sort((a, b) => {
                            return new Date(b.Created) - new Date(a.Created);
                        });
                        break;
                    case sysEnums.SortTypes.Title:
                        // Title 기준으로 정렬
                        filteredFolders.sort((a, b) => {
                            return a.Name.localeCompare(b.Name, 'ko', { sensitivity: 'base', usage: 'sort' });
                        });
                        break;
                    case sysEnums.SortTypes.NoRead:
                        // 구현해야 할 정렬 로직 추가
                        // 예: 읽지 않은 폴더 목록 정렬
                        break;
                    default:
                        // 기본 정렬은 특별히 추가된 정렬이 없으므로 추가 로직 필요 없음
                        break;
                }
            }

            // console.log(JSON.stringify('--- folderList ---') + filteredFolders.length);
            return filteredFolders;

            // if (!Array.isArray(pages.value) || pages.value.length === 0) return [];

            // if (typeof selectedFolder.value.EncFolder === 'string' && selectedFolder.value.EncFolder.length > 10) {
            //     // 선택한 폴더 하위 리스트 반환
            //     return folders.value.filter(x => x.EncTopFolder === selectedFolder.value.EncFolder)
            // }

            // console.log(JSON.stringify(folders.value))
            // return folders.value.filter(x => x.EncTopFolder === sysEnums.zero)
        });

        const pageList = computed(() => {
            if (!selectedFolder.value) return [];

            if (!Array.isArray(pages.value) || pages.value.length === 0) return [];

            if (widgetInfo.value.tag === sysEnums.PageTags.Search) {
                return filteredPage.value;
            }

            // console.log('pageList : ' + JSON.stringify(pages.value, null, 4));
            // console.log('getTargetList : ' + JSON.stringify(filteredPage.value));
            // console.log(widgetInfo.value.tag + ' / ' + sysEnums.PageTags.Trash + ' / ' + selectedFolder.value.EncFolder)

            // 의사결정 승인전에 안보이게 필터링 내승인 일때 부터 보임
            pages.value.forEach((page, index) => {
                if (page.Tag && page.Tag === sysEnums.PageTags.Decision) {
                    const lastIndexWithDate = page.PageRoles.findLastIndex(role => moment(role.Confirmed).format('YYYY') === '0000');
                    const confirmed = page.PageRoles.some((role, roleIndex) => {
                        return (roleIndex >= lastIndexWithDate && role.EncUser === userInfo.value.EncUser) || page.EncUser === userInfo.value.EncUser;
                    });

                    if (!confirmed && !page.PageRoles) {
                        pages.value.splice(index, 1); // pages.value에서 해당 페이지 제거
                    }

                    // console.log(page.Title + ' / ' + index + ' / ' + lastIndexWithDate + ' / ' + page.EncUser + ' / ' + userInfo.value.EncUser + ' / ' + confirmed);
                }

                if (page.Locked && page.EncUser !== userInfo.value.EncUser) {
                    // console.log(page.Title);
                    pages.value.splice(index, 1); // pages.value에서 나만보기 페이지 제거
                }
            });

            let filteredPages = [];

            // 폴더가 선택 되었을때
            if (typeof selectedFolder.value.EncFolder === 'string' && selectedFolder.value.EncFolder.length > 10) {
                const subFolders = folders.value.filter(x => x.EncTopFolder === selectedFolder.value.EncFolder);
                // console.log(JSON.stringify(subFolders, null, 4));

                filteredPages = pages.value.filter(x => {
                    if (x.FolderRoles != null && x.Trash === false) {
                        return x.EncFolder === selectedFolder.value.EncFolder || subFolders.some(subFolder => subFolder.EncFolder === x.EncFolder);
                    }
                    return false;
                });

                // filteredPages = pages.value.filter(x => {
                //     if (x.FolderRoles != null && x.Trash === false) {
                //         const tagArray = x.FolderRoles.find(
                //             y =>
                //                 (y.EncFolder === selectedFolder.value.EncFolder && y.EncPage !== sysEnums.zero) ||
                //                 subFolders.some(subFolder => subFolder.EncFolder === y.EncFolder),
                //         );
                //         return tagArray !== undefined;
                //     }
                //     return false;
                // });
            } else if (widgetInfo.value.tag === sysEnums.PageTags.Trash) {
                filteredPages = pages.value.filter(x => x.Trash === true);
            } else if (typeof widgetInfo.value.tag === 'number' && widgetInfo.value.tag > 0) {
                filteredPages = pages.value.filter(x => x.Tag > 0 && x.Tag === widgetInfo.value.tag && x.Trash === false);
            } else {
                // 페이지 로드시 또는 로고 클릭시
                filteredPages = pages.value.filter(x => x.Trash === false);
            }

            if (widgetInfo.value.sort > 0) {
                // 정렬 기준에 따라 정렬
                switch (widgetInfo.value.sort) {
                    case sysEnums.SortTypes.Created:
                        filteredPages.sort((a, b) => {
                            return new Date(b.Created) - new Date(a.Created);
                        });
                        break;
                    case sysEnums.SortTypes.Title:
                        filteredPages.sort((a, b) => {
                            return a.Title.localeCompare(b.Title, 'ko', { sensitivity: 'base', usage: 'sort' });
                        });
                        break;
                    case sysEnums.SortTypes.NoRead:
                        // 구현해야 할 정렬 로직 추가
                        // 예: 읽지 않은 목록 정렬
                        break;
                    default:
                        // 기본 정렬은 특별히 추가된 정렬이 없으므로 추가 로직 필요 없음
                        break;
                }
            }

            // console.log(JSON.stringify('--- pageList ---') + filteredPages.length);
            return filteredPages;
        });

        const getFolderPath = (encFolder, children) => {
            for (const child of children) {
                // child.clickEvent = () => handleFolderClick(child.EncFolder);

                if (child.EncFolder === encFolder) {
                    return [child];
                }

                if (child.children && child.children.length > 0) {
                    const path = getFolderPath(encFolder, child.children);
                    if (path.length > 0) {
                        return [child, ...path];
                    }
                }
            }
            return [];
        };

        const handleFolderClick = encFolder => {
            const data = folders.value.find(x => x.EncFolder === encFolder);

            if (data) {
                selectedFolder.value = data;

                filteredPage.value = [];
                widgetInfo.value.tag = sysEnums.PageTags.Default;
                // widgetInfo.value.title = selectedFolder.value.Name;
                // router.push('/page');
                // console.log(`Clicked folder ---: ${encFolder}`);
            }
        };

        const parentFolderName = encFolder => {
            const folderPath = getFolderPath(encFolder, treeData.value.children);
            return folderPath.map(folder => ({
                name: folder.Name,
                encFolder: folder.EncFolder,
                clickEvent: () => handleFolderClick(folder.EncFolder),
            }));
        };

        const pageCount = encFolder => {
            if (typeof encFolder === 'string' && Array.isArray(pages.value) && encFolder.length > 10) {
                const subFolders = folderList.value.filter(x => x.EncTopFolder === encFolder);

                const cnt = pages.value.filter(x => {
                    if (x.FolderRoles != null && x.Trash === false) {
                        return x.EncFolder === encFolder || subFolders.some(subFolder => subFolder.EncFolder === x.EncFolder);
                    }
                    return false;
                });

                return cnt.length;
            }
            return 0;
        };

        const gotoWidget = (data, to, title, place) => {
            widgetInfo.value.target = to;
            widgetInfo.value.place = place;

            if (place === 'start') {
                widgetInfo.value.data = data;
                widgetInfo.value.title = title;

                widgetInfo.value.canvas = true;
                widgetInfo.value.canvasDetail = false;
            } else {
                widgetInfo.value.dataDetail = data;
                widgetInfo.value.titleDetail = title;

                widgetInfo.value.canvas = false;
                widgetInfo.value.canvasDetail = true;
            }
            // console.log(to, title, place);
        };

        const gotoModal = (data, to, title) => {
            if (to === 'UserInfo') {
                const userInfo = userList.value.find(x => x.EncUser === data.EncUser);
                widgetInfo.value.selectedUser = userInfo;

                // console.log(JSON.stringify(widgetInfo.value.selectedUser));

                if (!widgetInfo.value.selectedUser) {
                    return;
                }
            } else if (data && (to === 'FolderCreate' || to === 'FolderMove')) {
                selectedFolder.value = data;
            } else if (data && to === 'FileViewer') {
                widgetInfo.value.data = data;
            } else if (data && (to === 'PageComment' || to === 'FileUpload')) {
                selectedPage.value = data;
            } else if (to === 'SetUserRole') {
                title === '폴더 열람대상 지정하기' ? (selectedFolder.value = data) : (selectedPage.value = data);
            } else if (data) {
                selectedPage.value = data;

                if (!selectedPage.value) {
                    return;
                }
            }

            widgetInfo.value.titleModal = title;
            widgetInfo.value.targetModal = to;
            widgetInfo.value.modal = true;
            widgetInfo.value.selectedFile = null;
            widgetInfo.value.modalSize = 'lg';
        };

        const widgetClose = () => {
            if (widgetInfo.value.target !== 'ClipViewer') {
                selectedPage.value = [];
                selectedClips.value = [];
            }

            if (widgetInfo.value.titleDetail === 'FolderMove') {
                widgetInfo.value.dataDetail = [];
            }

            widgetInfo.value.title = '';
            widgetInfo.value.canvas = false;
            widgetInfo.value.canvasDetail = false;
        };

        const modalClose = () => {
            if (widgetInfo.value.targetModal !== 'PageComment' && widgetInfo.value.targetModal !== 'FileViewer') {
                selectedPage.value = [];
            }

            widgetInfo.value.data = [];
            widgetInfo.value.dataModal = [];
            widgetInfo.value.selectedUser = [];
            widgetInfo.value.selectedFile = null;
            widgetInfo.value.modalSize = 'lg';
            widgetInfo.value.encComment = '';
            widgetInfo.value.modal = false;
        };

        const routeTo = (target, tag) => {
            selectedFolder.value = [];
            filteredPage.value = [];
            widgetInfo.value.tag = tag;
            widgetInfo.value.title = enumTypes.PageTags[tag].name;

            tag === sysEnums.PageTags.User
                ? (widgetInfo.value.activeTab[0] = 1)
                : tag === sysEnums.PageTags.Post
                ? (widgetInfo.value.activeTab[0] = 2)
                : tag === sysEnums.PageTags.Page
                ? (widgetInfo.value.activeTab[0] = 3)
                : tag === sysEnums.PageTags.Noti
                ? (widgetInfo.value.activeTab[0] = 4)
                : tag === sysEnums.PageTags.File
                ? (widgetInfo.value.activeTab[0] = 5)
                : tag === sysEnums.PageTags.History
                ? (widgetInfo.value.activeTab[0] = 6)
                : (widgetInfo.value.activeTab[0] = 3);
            router.push(target);
        };

        const navigateTo = (data, to) => {
            filteredPage.value = [];
            widgetInfo.value.tag = sysEnums.PageTags.Default;
            selectedFolder.value = data;
            widgetInfo.value.title = data.Name;
            store.state.routeTo.to = to;

            //if (to === 5) {
            //    selectedFolder.value = data;
            //}

            //showAlert('Alert!', JSON.stringify(data), 'PageClip', sysEnums.AlertTypes.Info);
        };

        const toApp = (key, data) => {
            if (window.FlutterChannel) {
                const response = {
                    key: key,
                    data: data,
                };
                window.FlutterChannel.postMessage(JSON.stringify(response));
                //showAlert(key, data, "HumaniaPay", sysEnums.AlertTypes.Info);
            }
        };

        // Flutter로부터 알림 받는 함수들
        const initAppChannel = () => {
            window.fromApp = async data => {
                // 액션 타입과 매개변수를 분리 (login과 username:user123,password:pass123 부분)
                //const actionType = data.substring(0, data.indexOf("(")); // login
                //const paramsString = data.substring(data.indexOf("(") + 1, data.indexOf(")")); // username:user123,password:pass123

                // 매개변수를 key:value 형태로 분리
                //const paramsArray = paramsString.split(",").map((param) => param.split("|^|"));

                // key:value를 객체로 변환
                //const params = Object.fromEntries(paramsArray);

                const parsedData = JSON.parse(data); // Flutter에서 받은 JSON 문자열을 파싱
                const actionType = parsedData.actionType;
                const params = parsedData.params;

                widgetInfo.value.title = params.fcmToken;

                if (actionType === 'appLogin') {
                    widgetInfo.value.fcmToken = params.fcmToken;

                    if (userInfo.value.Uid !== params.fcmToken) {
                        setUid(params.fcmToken, params.encUser);
                    }
                }

                if (actionType === 'routeTo') {
                    //router.push('/app');

                    //selectedPage.value = [];
                    //selectedClips.value = [];

                    store.state.routeTo.to = parseInt(params.selectedIndex);
                    widgetClose();
                }

                if (actionType === 'widgetTo') {
                    if (params.selectedIndex === 0) {
                        gotoWidget(null, 'FolderCreate', '새로운 폴더 또는 포스트 만들기', 'end');
                    }

                    if (params.selectedIndex === 1) {
                        gotoWidget(null, 'PageCreate', '새로운 페이지 간편 등록하기', 'start');
                    }

                    if (params.selectedIndex === 2) {
                        gotoWidget(null, 'PageCreate', '새로운 페이지 간편 등록하기', 'start')
                    }

                    // if (params.selectedIndex === sysEnums.NotiTypes.Contract) {
                    //     await getContractInfo(params.encContract)
                    // }

                    // if (params.selectedIndex === sysEnums.NotiTypes.Facility) {
                    //     await getFacilityInfo(params.encFacility);
                    // }
                }

                if (actionType === 'sendChat') {
                    if (store.state.connection) {
                        try {
                            if (params.content) {
                                //alert(userInfo.value.UserName + " / " + params.receiverName);
                                await store.state.connection.invoke('SendMessageToClient', userInfo.value.EncUser, params.receiverName, params.encReceiver, params.content, 0);
                            }
                        } catch (error) {
                            console.error('Error invoking SendMessageToClient:', error);
                        }
                    }
                }

                if (actionType === 'updateChat') {
                    if (store.state.connection) {
                        try {
                            if (params.path) {
                                await store.state.connection.invoke(
                                    'SendMessageNoConnection',
                                    userInfo.value.EncUser,
                                    params.receiverName,
                                    params.encReceiver,
                                    params.path,
                                    sysEnums.FileTypes.Chat,
                                );
                            }
                        } catch (error) {
                            console.error('Error invoking SendMessageToClient:', error);
                        }
                    }
                }

                // if (actionType === "setUser") {
                //     getUserChange();
                // }

                // if (actionType === "setFacility") {
                //     getFacility();
                //     widgetClose();
                // }

                //showAlert('Alert...!', actionType, 'HumaniaPay', sysEnums.AlertTypes.Info);
            };
        };

        provide('$provideList', {
            userList,
            notiList,
            serviceList,
            alarmList,
            folderList,
            workspaceList,
            pageList,
            clipList,
            companyList,
            fileList,
            totalPeriods,
            curPeriods,
            signalReceived,
            filteredFolder,
            filteredPage,
            filteredNoti,
            filteredSignal,
            chatData,
            chatDataGroup,
            treeData,
            selectedTree,
            selectedFolder,
            selectedPage,
            selectedClips,
            parentFolderName,
            pageCount,
            getCompany,
            getUser,
            getUserPage,
            getUserClip,
            getUserFolder,
            setClipSort,
            setClipSortTarget,
            setClipUpdate,
            setFolderUpdate,
            updateFolder,
            updateFolderFix,
            updateFolderShare,
            createRole,
            sendMessage,
            getChatData,
            getChatGroup,
            gotoModal,
            gotoWidget,
            widgetClose,
            modalClose,
            routeTo,
            navigateTo,
            toApp,
        });

        return {
            widgetInfo,
            widgetClose,
            modalClose,
            gotoModal,
        };
    },
};
</script>

<style lang="scss">
@import './assets/css/default.css';
@import './assets/fonts/inter/inter.css';
</style>
