<template>
    <div class="profile-foreground position-relative">
        <div class="profile-wid-bg">
            <img src="@/assets/images/profile-bg.jpg" alt="" class="profile-wid-img" />
        </div>
    </div>

    <BRow>
        <BCol>
            <div class="px-3 py-4 profile-wrapper">
                <div class="d-flex mb-3">
                    <img
                        :src="widgetInfo.companyInfo.Avatar ? $fileUrl + widgetInfo.companyInfo.Avatar : 'assets/images/no-profile.jpg'"
                        :class="['img-thumbnail', { 'opacity-25': !widgetInfo.companyInfo.Status }, widgetInfo.isApp ? 'avatar-xs' : 'avatar-lg']"
                    />
                    <h3 class="text-white ms-3" @click="gotoModal(selectedFolder, 'FolderCreate', '폴더 수정')">{{ widgetInfo.title || widgetInfo.companyInfo.Name }}</h3>
                </div>

                <p class="text-white text-opacity-75">
                    <i class="ri-phone-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{{ widgetInfo.companyInfo.Tel || '' }}
                    <span class="ms-3 text-uppercase">BN:</span> {{ widgetInfo.companyInfo.BusinessNum || '' }}
                </p>

                <div class="hstack text-white-50">
                    <!--<span class="me-2"> <i class="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{{ widgetInfo.companyInfo.Owner || '' }} </span>-->
                    <span>
                        <i class="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{{ widgetInfo.companyInfo.PostalCode || '' }}
                        {{ widgetInfo.companyInfo.Address1 || '' }} {{ widgetInfo.companyInfo.Address2 || '' }}
                    </span>
                </div>

                <!--<div v-if="selectedFolder.EncFolder" class="text-white-50 gap-1 mt-3">
                    <p>
                        <i class="ri-survey-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{{ selectedFolder.Description || '' }}
                        <span>
                            <i class="ri-price-tag-3-line me-1 text-white text-opacity-75 fs-12 align-middle"></i
                            >{{ $moment(selectedFolder.Created).format('YYYY-MM-DD hh:mm') || '' }}
                        </span>
                    </p>
                </div>-->
            </div>
        </BCol>
    </BRow>

    <BRow>
        <BCol>
            <BCard no-body>
                <BCardBody>
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <th class="ps-0" scope="row">Name</th>
                                    <td class="text-muted">{{ widgetInfo.companyInfo.Owner }}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Mobile</th>
                                    <td class="text-muted">+(1) {{ widgetInfo.companyInfo.Mobile }}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">E-mail</th>
                                    <td class="text-muted">{{ widgetInfo.companyInfo.Email }}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row"></th>
                                    <td class="text-muted">{{ $moment(widgetInfo.companyInfo.Created).format('YYYY-MM-DD hh:mm') }} 등록됨</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h5 class="card-title mb-3">About</h5>
                    <p>{{ widgetInfo.companyInfo.Descript }}</p>
                    <BRow>
                        <BCol>
                            <div class="d-flex mt-4">
                                <div class="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div class="avatar-title bg-light rounded-circle fs-16 text-primary">
                                        <i class="ri-user-2-fill"></i>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="mb-1">Designation</p>
                                    <h6 class="text-truncate mb-0">김공선</h6>
                                </div>
                            </div>
                        </BCol>
                        <BCol>
                            <div class="d-flex mt-4">
                                <div class="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div class="avatar-title bg-light rounded-circle fs-16 text-primary">
                                        <i class="ri-global-line"></i>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="mb-1">Website</p>
                                    <BLink href="#" class="fw-semibold">{{ widgetInfo.companyInfo.WebSite }}</BLink>
                                </div>
                            </div>
                        </BCol>
                    </BRow>
                </BCardBody>
            </BCard>

            <BCard no-body>
                <BCardBody>
                    <div class="d-flex align-items-center mb-4">
                        <div class="flex-grow-1">
                            <h5 class="card-title mb-0">Suggestions</h5>
                        </div>
                        <div class="flex-shrink-0">
                            <BDropdown variant="link" right toggle-class="arrow-none p-0" no-caret>
                                <template #button-content><i class="ri-more-2-fill fs-14"></i> </template>
                                <BDropdownItem href="#"> View </BDropdownItem>
                                <BDropdownItem href="#"> Download </BDropdownItem>
                                <BDropdownItem href="#"> Delete </BDropdownItem>
                            </BDropdown>
                        </div>
                    </div>
                    <div>
                        <!--<simplebar data-simplebar style="height: 220px">-->
                        <div v-for="x in userList" :key="x.EncUser" class="d-flex align-items-center py-3">
                            <div class="flex-shrink-0 me-3">
                                <img :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/users/avatar-5.jpg'" alt="" class="img-fit avatar-xs rounded-circle" />
                            </div>
                            <div class="flex-grow-1">
                                <div>
                                    <h5 class="fs-14 mb-1">{{ x.UserName }}</h5>
                                    <p class="fs-13 text-muted mb-0">{{ getUserDepartment(x.Position, true) + ' / ' + getUserDepartment(x.Department, false) }}</p>
                                </div>
                            </div>
                            <div class="flex-shrink-0 ms-2">
                                <BButton type="button" variant="outline-secondary" size="sm">
                                    <i class="ri-user-add-line align-middle"></i>
                                </BButton>
                            </div>
                        </div>
                        <!--</simplebar>-->
                    </div>
                </BCardBody>
            </BCard>

            <BCard no-body>
                <BCardHeader class="align-items-center d-flex">
                    <BCardTitle class="mb-0 me-2">Recent Activity</BCardTitle>
                    <div class="flex-shrink-0 ms-auto"></div>
                </BCardHeader>
                <BCardBody>
                    <BTabs nav-class="text-muted nav-tabs-custom rounded card-header-tabs border-bottom-0 tamp">
                        <BTab title="Today">
                            <div class="profile-timeline">
                                <div class="accordion accordion-flush" id="todayExample">
                                    <div class="accordion-item border-0">
                                        <div class="accordion-header" id="headingOne">
                                            <BLink class="accordion-button p-2 shadow-none" v-b-toggle.collapseOne>
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0">
                                                        <img src="@/assets/images/users/avatar-2.jpg" alt="" class="avatar-xs rounded-circle" />
                                                    </div>
                                                    <div class="flex-grow-1 ms-3">
                                                        <h6 class="fs-14 mb-1">Jacqueline Steve</h6>
                                                        <small class="text-muted">We has changed 2 attributes on 05:16PM</small>
                                                    </div>
                                                </div>
                                            </BLink>
                                        </div>

                                        <BCollapse id="collapseOne" visible>
                                            <div class="accordion-body ms-2 ps-5">
                                                In an awareness campaign, it is vital for people to begin put 2 and 2 together and begin to recognize your cause. Too much or too
                                                little spacing, as in the example below, can make things unpleasant for the reader. The goal is to make your text as comfortable to
                                                read as possible. A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy
                                                with my whole heart.
                                            </div>
                                        </BCollapse>
                                    </div>
                                    <div class="accordion-item border-0">
                                        <div class="accordion-header" id="headingTwo">
                                            <BLink class="accordion-button p-2 shadow-none" v-b-toggle.collapseTwo>
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 avatar-xs">
                                                        <div class="avatar-title bg-light text-success rounded-circle">M</div>
                                                    </div>
                                                    <div class="flex-grow-1 ms-3">
                                                        <h6 class="fs-14 mb-1">Megan Elmore</h6>
                                                        <small class="text-muted">Adding a new event with attachments - 04:45PM</small>
                                                    </div>
                                                </div>
                                            </BLink>
                                        </div>
                                        <BCollapse id="collapseTwo" visible>
                                            <div class="accordion-body ms-2 ps-5">
                                                <BRow class="g-2">
                                                    <BCol cols="auto">
                                                        <div class="d-flex border border-dashed p-2 rounded position-relative">
                                                            <div class="flex-shrink-0">
                                                                <i class="ri-image-2-line fs-17 text-secondary"></i>
                                                            </div>
                                                            <div class="flex-grow-1 ms-2">
                                                                <h6 class="mb-0">
                                                                    <BLink href="javascript:void(0);" class="stretched-link"> Business Template - UI/UX design </BLink>
                                                                </h6>
                                                                <small>685 KB</small>
                                                            </div>
                                                        </div>
                                                    </BCol>
                                                    <BCol cols="auto">
                                                        <div class="d-flex border border-dashed p-2 rounded position-relative">
                                                            <div class="flex-shrink-0">
                                                                <i class="ri-file-zip-line fs-17 text-secondary"></i>
                                                            </div>
                                                            <div class="flex-grow-1 ms-2">
                                                                <h6>
                                                                    <BLink href="javascript:void(0);" class="stretched-link"> Bank Management System - PSD</BLink>
                                                                </h6>
                                                                <small>8.78 MB</small>
                                                            </div>
                                                        </div>
                                                    </BCol>
                                                </BRow>
                                            </div>
                                        </BCollapse>
                                    </div>
                                    <div class="accordion-item border-0">
                                        <div class="accordion-header" id="headingThree">
                                            <BLink class="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapsethree" aria-expanded="false">
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0">
                                                        <img src="@/assets/images/users/avatar-5.jpg" alt="" class="avatar-xs rounded-circle" />
                                                    </div>
                                                    <div class="flex-grow-1 ms-3">
                                                        <h6 class="fs-14 mb-1">New ticket received</h6>
                                                        <small class="text-muted mb-2"
                                                            >User
                                                            <span class="text-secondary">Erica245</span>
                                                            submitted a ticket - 02:33PM</small
                                                        >
                                                    </div>
                                                </div>
                                            </BLink>
                                        </div>
                                    </div>
                                    <div class="accordion-item border-0">
                                        <div class="accordion-header" id="headingFour">
                                            <BLink class="accordion-button p-2 shadow-none" v-b-toggle.collapseFour>
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0 avatar-xs">
                                                        <div class="avatar-title bg-light text-muted rounded-circle">
                                                            <i class="ri-user-3-fill"></i>
                                                        </div>
                                                    </div>
                                                    <div class="flex-grow-1 ms-3">
                                                        <h6 class="fs-14 mb-1">Nancy Martino</h6>
                                                        <small class="text-muted">Commented on 12:57PM</small>
                                                    </div>
                                                </div>
                                            </BLink>
                                        </div>
                                        <BCollapse id="collapseFour" visible>
                                            <div class="accordion-body ms-2 ps-5 fst-italic">
                                                " A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole
                                                heart. Each design is a new, unique piece of art birthed into this world, and while you have the opportunity to be creative and make
                                                your own style choices. "
                                            </div>
                                        </BCollapse>
                                    </div>
                                    <div class="accordion-item border-0">
                                        <div class="accordion-header" id="headingFive">
                                            <BLink class="accordion-button p-2 shadow-none" v-b-toggle.collapseFive>
                                                <div class="d-flex">
                                                    <div class="flex-shrink-0">
                                                        <img src="@/assets/images/users/avatar-7.jpg" alt="" class="avatar-xs rounded-circle" />
                                                    </div>
                                                    <div class="flex-grow-1 ms-3">
                                                        <h6 class="fs-14 mb-1">Lewis Arnold</h6>
                                                        <small class="text-muted">Create new project Building product - 10:05AM</small>
                                                    </div>
                                                </div>
                                            </BLink>
                                        </div>

                                        <BCollapse id="collapseFive" visible>
                                            <div class="accordion-body ms-2 ps-5">
                                                <p class="text-muted mb-2">
                                                    Every team project can have a velzon. Use the velzon to share information with your team to understand and contribute to your
                                                    project.
                                                </p>
                                                <div class="avatar-group">
                                                    <BLink href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover title="Christi">
                                                        <img src="@/assets/images/users/avatar-4.jpg" alt="" class="rounded-circle avatar-xs" />
                                                    </BLink>
                                                    <BLink href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover title="Frank Hook">
                                                        <img src="@/assets/images/users/avatar-3.jpg" alt="" class="rounded-circle avatar-xs" />
                                                    </BLink>
                                                    <BLink href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover title="Ruby">
                                                        <div class="avatar-xs">
                                                            <div class="avatar-title rounded-circle bg-light text-primary">R</div>
                                                        </div>
                                                    </BLink>
                                                    <BLink href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover title="more">
                                                        <div class="avatar-xs">
                                                            <div class="avatar-title rounded-circle">2+</div>
                                                        </div>
                                                    </BLink>
                                                </div>
                                            </div>
                                        </BCollapse>
                                    </div>
                                </div>
                            </div>
                        </BTab>
                    </BTabs>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>
</template>

<script lang="js">
import PageTop from "@/components/pages/PageTop";
import FileUploadSingle from "@/components/files/FileUploadSingle";

import { ref, inject } from 'vue';
import { getUserDepartment, getSignalStatus } from "@/common/comFuncs";
import { apiEnums, sysEnums, enumTypes } from "@/common/sysEnums";

import simplebar from 'simplebar-vue';
import { BRow } from "bootstrap-vue-next";

export default {
    components: {
        PageTop,
        FileUploadSingle,
        simplebar,
    },
    props: {
        Data: Object,
        Title: Array,
    },
    setup(props) {

        const isCompanyRegist = ref(false);
        const fileRef = ref(false);

        const canvasUserType = ref(0);

        const selectedCompany = ref({});
        const selectedData = ref({});
        const selectedList = ref([]);

        const widgetInfo = inject("$widgetInfo");

        const axios = inject("$axios");

        const { userList, getCompany, selectedFolder } = inject("$provideList");

        const updateCompany = (encCompany) => {
            const formData = new FormData();
            formData.append('EncCompany', encCompany);
            formData.append('Seqno', 99);
            axios.post(apiEnums.CloudTicket.updateCompany, formData)
                .then((response) => {
                    if (response.data === '1000') {
                        getCompany();
                    }
                })
                .catch(() => { });
        };

        const setCompany = (data) => {
            selectedCompany.value = data;
        }

        const setData = (data, user) => {
            canvasUserType.value = user;
            isCompanyRegist.value = !isCompanyRegist.value;
            isCompanyRegist.value ? selectedData.value = data : canvasUserType.value = 0;
        }

        return {
            title: "Company",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "CompanyInfo",
                    active: true,
                },
            ],
            props, sysEnums, enumTypes, isCompanyRegist,
            selectedData, selectedList, selectedCompany,
            canvasUserType,
            fileRef, widgetInfo, selectedFolder, userList,
            getUserDepartment, getSignalStatus,
            updateCompany,
            setCompany, setData,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable='true'] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
