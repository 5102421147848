<script>
import { inject, ref, getCurrentInstance } from "vue";
import { apiEnums, sysEnums } from "@/common/sysEnums";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/autoplay";
import 'swiper/css/navigation';

export default {
    emits: ['CloseRegist'],
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        Data: Object,
        UserType: Number,
    },
    setup(props) {
        const companyInfo = ref(props.Data);

        const axios = inject("$axios");
        const store = inject("$store");

        const { emit } = getCurrentInstance();

        const goWidget = (to, dt) => {
            store.dispatch("AC_WIDGET", { target: to, data: dt });
        }

        const createUserConsole = () => {
            axios.post(apiEnums.CloudTicket.createUserConsole)
                .then((response) => {
                    console.log(response);
                    // store.dispatch("AC_FACILITY", response.data);
                    // facilityList.value = response.data;

                    // const selected = facilityList.value.find(x => x.EncFacility === encFacility)

                    // if (selected) {
                    //     store.dispatch("AC_WIDGET", { target: 'FacilityDetail', data: selected });
                    //     showCanvas('#offcanvasWidget');
                    // }

                })
                .catch(() => { });
        }

        return {
            props, emit, sysEnums, companyInfo,
            Autoplay, Navigation,
            goWidget, createUserConsole
        };
    }
};
</script>

<template>

    <!-- <div class="card-header mb-4">
        <button type="button" class="btn-close float-end fs-11" @click="emit('CloseRegist');"></button>
        <h6 class="card-title mb-0 d-inline-block">회사정보</h6>
    </div> -->

    <BRow>
        <BCol xxl="3">
            <BCard no-body>
                <BCardBody>
                    <h5 class="card-title mb-5">Complete Your Profile</h5>
                    <BProgress class="animated-progress custom-progress progress-label">
                        <BProgressBar :value="30" variant="primary">
                            <div class="label">30%</div>
                        </BProgressBar>
                    </BProgress>
                </BCardBody>
            </BCard>

            <BCard no-body>
                <BCardBody>
                    <h5 class="card-title mb-3">Info</h5>
                    <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                            <tbody>
                                <tr>
                                    <th class="ps-0" scope="row">Full Name :</th>
                                    <td class="text-muted">{{ companyInfo.Owner }}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Mobile :</th>
                                    <td class="text-muted">+(1) {{ companyInfo.Mobile }}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">E-mail :</th>
                                    <td class="text-muted">{{ companyInfo.Email }}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Location :</th>
                                    <td class="text-muted">{{ companyInfo.Address1 }}</td>
                                </tr>
                                <tr>
                                    <th class="ps-0" scope="row">Joining Date</th>
                                    <td class="text-muted">{{ $moment(companyInfo.Created) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </BCardBody>
            </BCard>

            <BCard no-body>
                <BCardBody>
                    <div class="d-flex align-items-center mb-4">
                        <div class="flex-grow-1">
                            <h5 class="card-title mb-0">Suggestions</h5>
                        </div>
                        <div class="flex-shrink-0">
                            <BDropdown variant="link" right toggle-class="arrow-none p-0" no-caret>
                                <template #button-content><i class="ri-more-2-fill fs-14"></i>
                                </template>
                                <BDropdownItem href="#">
                                    View
                                </BDropdownItem>
                                <BDropdownItem href="#">
                                    Download
                                </BDropdownItem>
                                <BDropdownItem href="#">
                                    Delete
                                </BDropdownItem>
                            </BDropdown>
                        </div>
                    </div>
                    <div>
                        <div class="d-flex align-items-center py-3">
                            <div class="avatar-xs flex-shrink-0 me-3">
                                <img src="@/assets/images/users/avatar-3.jpg" alt="" class="img-fluid rounded-circle" />
                            </div>
                            <div class="flex-grow-1">
                                <div>
                                    <h5 class="fs-14 mb-1">Esther James</h5>
                                    <p class="fs-13 text-muted mb-0">
                                        Frontend Developer
                                    </p>
                                </div>
                            </div>
                            <div class="flex-shrink-0 ms-2">
                                <BButton type="button" variant="outline-secondary" size="sm">
                                    <i class="ri-user-add-line align-middle"></i>
                                </BButton>
                            </div>
                        </div>
                        <div class="d-flex align-items-center py-3">
                            <div class="avatar-xs flex-shrink-0 me-3">
                                <img src="@/assets/images/users/avatar-4.jpg" alt="" class="img-fluid rounded-circle" />
                            </div>
                            <div class="flex-grow-1">
                                <div>
                                    <h5 class="fs-14 mb-1">Jacqueline Steve</h5>
                                    <p class="fs-13 text-muted mb-0">
                                        UI/UX Designer
                                    </p>
                                </div>
                            </div>
                            <div class="flex-shrink-0 ms-2">
                                <BButton type="button" variant="outline-secondary" size="sm">
                                    <i class="ri-user-add-line align-middle"></i>
                                </BButton>
                            </div>
                        </div>
                        <div class="d-flex align-items-center py-3">
                            <div class="avatar-xs flex-shrink-0 me-3">
                                <img src="@/assets/images/users/avatar-5.jpg" alt="" class="img-fluid rounded-circle" />
                            </div>
                            <div class="flex-grow-1">
                                <div>
                                    <h5 class="fs-14 mb-1">George Whalen</h5>
                                    <p class="fs-13 text-muted mb-0">
                                        Backend Developer
                                    </p>
                                </div>
                            </div>
                            <div class="flex-shrink-0 ms-2">
                                <BButton type="button" variant="outline-secondary" size="sm">
                                    <i class="ri-user-add-line align-middle"></i>
                                </BButton>
                            </div>
                        </div>
                    </div>
                </BCardBody>
            </BCard>
        </BCol>

        <BCol xxl="9">
            <BCard no-body>
                <BCardBody>
                    <h5 class="card-title mb-3">About</h5>
                    <p>
                        Hi I'm Anna Adame, It will be as simple as Occidental;
                        in fact, it will be Occidental. To an English person, it
                        will seem like simplified English, as a skeptical
                        Cambridge friend of mine told me what Occidental is
                        European languages are members of the same family.
                    </p>
                    <p>
                        You always want to make sure that your fonts work well
                        together and try to limit the number of fonts you use to
                        three or less. Experiment and play around with the fonts
                        that you already have in the software you’re working
                        with reputable font websites. This may be the most
                        commonly encountered tip I received from the designers I
                        spoke with. They highly encourage that you use different
                        fonts in one design, but do not over-exaggerate and go
                        overboard.
                    </p>
                    <BRow>
                        <BCol cols="6" md="4">
                            <div class="d-flex mt-4">
                                <div class="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div class="avatar-title bg-light rounded-circle fs-16 text-primary">
                                        <i class="ri-user-2-fill"></i>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="mb-1">Designation :</p>
                                    <h6 class="text-truncate mb-0">
                                        Lead Designer / Developer
                                    </h6>
                                </div>
                            </div>
                        </BCol>
                        <BCol cols="6" md="4">
                            <div class="d-flex mt-4">
                                <div class="flex-shrink-0 avatar-xs align-self-center me-3">
                                    <div class="avatar-title bg-light rounded-circle fs-16 text-primary">
                                        <i class="ri-global-line"></i>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <p class="mb-1">Website :</p>
                                    <BLink href="#" class="fw-semibold">{{
                            companyInfo.WebSite }}</BLink>
                                </div>
                            </div>
                        </BCol>
                    </BRow>
                </BCardBody>
            </BCard>

            <BRow>
                <BCol lg="12">
                    <BCard no-body>
                        <BCardHeader class="align-items-center d-flex">
                            <BCardTitle class="mb-0 me-2">Recent Activity</BCardTitle>
                            <div class="flex-shrink-0 ms-auto">

                            </div>
                        </BCardHeader>
                        <BCardBody>
                            <BTabs nav-class="text-muted nav-tabs-custom rounded card-header-tabs border-bottom-0 tamp">
                                <BTab title="Today">
                                    <div class="profile-timeline">
                                        <div class="accordion accordion-flush" id="todayExample">
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingOne">
                                                    <BLink class="accordion-button p-2 shadow-none" v-b-toggle.collapseOne>
                                                        <div class="d-flex">
                                                            <div class="flex-shrink-0">
                                                                <img src="@/assets/images/users/avatar-2.jpg" alt="" class="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-14 mb-1">
                                                                    Jacqueline Steve
                                                                </h6>
                                                                <small class="text-muted">We
                                                                    has changed 2 attributes
                                                                    on
                                                                    05:16PM</small>
                                                            </div>
                                                        </div>
                                                    </BLink>
                                                </div>

                                                <BCollapse id="collapseOne" visible>
                                                    <div class="accordion-body ms-2 ps-5">
                                                        In an awareness campaign, it is
                                                        vital
                                                        for people to begin put 2 and 2
                                                        together
                                                        and begin to recognize your cause.
                                                        Too
                                                        much or too little spacing, as in
                                                        the
                                                        example below, can make things
                                                        unpleasant for the reader. The goal
                                                        is
                                                        to make your text as comfortable to
                                                        read
                                                        as possible. A wonderful serenity
                                                        has
                                                        taken possession of my entire soul,
                                                        like
                                                        these sweet mornings of spring which
                                                        I
                                                        enjoy with my whole heart.
                                                    </div>
                                                </BCollapse>
                                            </div>
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingTwo">
                                                    <BLink class="accordion-button p-2 shadow-none" v-b-toggle.collapseTwo>
                                                        <div class="d-flex">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-light text-success rounded-circle">
                                                                    M
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-14 mb-1">
                                                                    Megan Elmore
                                                                </h6>
                                                                <small class="text-muted">Adding
                                                                    a new event with
                                                                    attachments -
                                                                    04:45PM</small>
                                                            </div>
                                                        </div>
                                                    </BLink>
                                                </div>
                                                <BCollapse id="collapseTwo" visible>
                                                    <div class="accordion-body ms-2 ps-5">
                                                        <BRow class="g-2">
                                                            <BCol cols="auto">
                                                                <div class="d-flex border border-dashed p-2 rounded position-relative">
                                                                    <div class="flex-shrink-0">
                                                                        <i class="ri-image-2-line fs-17 text-secondary"></i>
                                                                    </div>
                                                                    <div class="flex-grow-1 ms-2">
                                                                        <h6 class="mb-0">
                                                                            <BLink href="javascript:void(0);" class="stretched-link">
                                                                                Business
                                                                                Template
                                                                                - UI/UX
                                                                                design
                                                                            </BLink>
                                                                        </h6>
                                                                        <small>685
                                                                            KB</small>
                                                                    </div>
                                                                </div>
                                                            </BCol>
                                                            <BCol cols="auto">
                                                                <div class="d-flex border border-dashed p-2 rounded position-relative">
                                                                    <div class="flex-shrink-0">
                                                                        <i class="ri-file-zip-line fs-17 text-secondary"></i>
                                                                    </div>
                                                                    <div class="flex-grow-1 ms-2">
                                                                        <h6>
                                                                            <BLink href="javascript:void(0);" class="stretched-link">
                                                                                Bank
                                                                                Management
                                                                                System -
                                                                                PSD</BLink>
                                                                        </h6>
                                                                        <small>8.78
                                                                            MB</small>
                                                                    </div>
                                                                </div>
                                                            </BCol>
                                                        </BRow>
                                                    </div>
                                                </BCollapse>
                                            </div>
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingThree">
                                                    <BLink class="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapsethree" aria-expanded="false">
                                                        <div class="d-flex">
                                                            <div class="flex-shrink-0">
                                                                <img src="@/assets/images/users/avatar-5.jpg" alt="" class="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-14 mb-1">
                                                                    New ticket received
                                                                </h6>
                                                                <small class="text-muted mb-2">User
                                                                    <span class="text-secondary">Erica245</span>
                                                                    submitted a ticket -
                                                                    02:33PM</small>
                                                            </div>
                                                        </div>
                                                    </BLink>
                                                </div>
                                            </div>
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingFour">
                                                    <BLink class="accordion-button p-2 shadow-none" v-b-toggle.collapseFour>
                                                        <div class="d-flex">
                                                            <div class="flex-shrink-0 avatar-xs">
                                                                <div class="avatar-title bg-light text-muted rounded-circle">
                                                                    <i class="ri-user-3-fill"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-14 mb-1">
                                                                    Nancy Martino
                                                                </h6>
                                                                <small class="text-muted">Commented
                                                                    on 12:57PM</small>
                                                            </div>
                                                        </div>
                                                    </BLink>
                                                </div>
                                                <BCollapse id="collapseFour" visible>
                                                    <div class="accordion-body ms-2 ps-5 fst-italic">
                                                        " A wonderful serenity has taken
                                                        possession of my entire soul, like
                                                        these
                                                        sweet mornings of spring which I
                                                        enjoy
                                                        with my whole heart. Each design is
                                                        a
                                                        new, unique piece of art birthed
                                                        into
                                                        this world, and while you have the
                                                        opportunity to be creative and make
                                                        your
                                                        own style choices. "
                                                    </div>
                                                </BCollapse>

                                            </div>
                                            <div class="accordion-item border-0">
                                                <div class="accordion-header" id="headingFive">
                                                    <BLink class="accordion-button p-2 shadow-none" v-b-toggle.collapseFive>
                                                        <div class="d-flex">
                                                            <div class="flex-shrink-0">
                                                                <img src="@/assets/images/users/avatar-7.jpg" alt="" class="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h6 class="fs-14 mb-1">
                                                                    Lewis Arnold
                                                                </h6>
                                                                <small class="text-muted">Create
                                                                    new project Building
                                                                    product -
                                                                    10:05AM</small>
                                                            </div>
                                                        </div>
                                                    </BLink>
                                                </div>

                                                <BCollapse id="collapseFive" visible>
                                                    <div class="accordion-body ms-2 ps-5">
                                                        <p class="text-muted mb-2">
                                                            Every team project can have a
                                                            velzon.
                                                            Use the velzon to share
                                                            information
                                                            with your team to understand and
                                                            contribute to your project.
                                                        </p>
                                                        <div class="avatar-group">
                                                            <BLink href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover title="Christi">
                                                                <img src="@/assets/images/users/avatar-4.jpg" alt="" class="rounded-circle avatar-xs" />
                                                            </BLink>
                                                            <BLink href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover title="Frank Hook">
                                                                <img src="@/assets/images/users/avatar-3.jpg" alt="" class="rounded-circle avatar-xs" />
                                                            </BLink>
                                                            <BLink href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover title="Ruby">
                                                                <div class="avatar-xs">
                                                                    <div class="avatar-title rounded-circle bg-light text-primary">
                                                                        R
                                                                    </div>
                                                                </div>
                                                            </BLink>
                                                            <BLink href="javascript: void(0);" class="avatar-group-item" v-b-tooltip.hover title="more">
                                                                <div class="avatar-xs">
                                                                    <div class="avatar-title rounded-circle">
                                                                        2+
                                                                    </div>
                                                                </div>
                                                            </BLink>
                                                        </div>
                                                    </div>
                                                </BCollapse>
                                            </div>
                                        </div>
                                    </div>
                                </BTab>
                            </BTabs>
                        </BCardBody>
                    </BCard>
                </BCol>
            </BRow>

            <BCard no-body>
                <BCardBody>
                    <h5 class="card-title mb-3">멤버</h5>
                    <div class="swiper project-swiper">
                        <swiper :slides-per-view="3" :space-between="50">
                            <swiper-slide>
                                <div class="swiper-slide">
                                    <BCard no-body class="profile-project-card shadow-none profile-project-primary mb-0">
                                        <BCardBody class="p-4">
                                            <div class="d-flex">
                                                <div class="flex-grow-1 text-muted overflow-hidden">
                                                    <h5 class="fs-14 text-truncate mb-1">
                                                        <BLink href="#" class="text-body">
                                                            ABC Project Customization
                                                        </BLink>
                                                    </h5>
                                                    <p class="text-muted text-truncate mb-0">
                                                        Last Update :
                                                        <span class="fw-semibold text-body">4
                                                            hr Ago</span>
                                                    </p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <BBadge variant="warning-subtle" class="bg-warning-subtle text-warning fs-10">
                                                        Inprogress
                                                    </BBadge>
                                                </div>
                                            </div>
                                            <div class="d-flex mt-4">
                                                <div class="flex-grow-1">
                                                    <div class="d-flex align-items-center gap-2">
                                                        <div>
                                                            <h5 class="fs-12 text-muted mb-0">
                                                                Members :
                                                            </h5>
                                                        </div>
                                                        <div class="avatar-group">
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <img src="@/assets/images/users/avatar-4.jpg" alt="" class="rounded-circle img-fluid" />
                                                                </div>
                                                            </div>
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <img src="@/assets/images/users/avatar-5.jpg" alt="" class="rounded-circle img-fluid" />
                                                                </div>
                                                            </div>
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <div class="avatar-title rounded-circle bg-light text-primary">
                                                                        A
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <img src="@/assets/images/users/avatar-2.jpg" alt="" class="rounded-circle img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </BCardBody>
                                    </BCard>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="swiper-slide">
                                    <BCard no-body class="profile-project-card shadow-none profile-project-success mb-0">
                                        <BCardBody class="p-4">
                                            <div class="d-flex">
                                                <div class="flex-grow-1 text-muted overflow-hidden">
                                                    <h5 class="fs-14 text-truncate mb-1">
                                                        <BLink href="#" class="text-body">
                                                            Client - John</BLink>
                                                    </h5>
                                                    <p class="text-muted text-truncate mb-0">
                                                        Last Update :
                                                        <span class="fw-semibold text-body">1
                                                            hr Ago</span>
                                                    </p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <BBadge variant="success-subtle" class="bg-success-subtle text-success fs-10">
                                                        Completed
                                                    </BBadge>
                                                </div>
                                            </div>
                                            <div class="d-flex mt-4">
                                                <div class="flex-grow-1">
                                                    <div class="d-flex align-items-center gap-2">
                                                        <div>
                                                            <h5 class="fs-12 text-muted mb-0">
                                                                Members :
                                                            </h5>
                                                        </div>
                                                        <div class="avatar-group">
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <img src="@/assets/images/users/avatar-2.jpg" alt="" class="rounded-circle img-fluid" />
                                                                </div>
                                                            </div>
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <div class="avatar-title rounded-circle bg-light text-primary">
                                                                        C
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </BCardBody>
                                    </BCard>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="swiper-slide">
                                    <BCard no-body class="profile-project-card shadow-none profile-project-info mb-0">
                                        <BCardBody class="p-4">
                                            <div class="d-flex">
                                                <div class="flex-grow-1 text-muted overflow-hidden">
                                                    <h5 class="fs-14 text-truncate mb-1">
                                                        <BLink href="#" class="text-body">Brand logo Design</BLink>
                                                    </h5>
                                                    <p class="text-muted text-truncate mb-0">
                                                        Last Update : <span class="fw-semibold text-body">2 hr Ago</span>
                                                    </p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <BBadge variant="warning-subtle" class="bg-warning-subtle text-warning fs-10">
                                                        Inprogress
                                                    </BBadge>
                                                </div>
                                            </div>
                                            <div class="d-flex mt-4">
                                                <div class="flex-grow-1">
                                                    <div class="d-flex align-items-center gap-2">
                                                        <div>
                                                            <h5 class="fs-12 text-muted mb-0">
                                                                Members :
                                                            </h5>
                                                        </div>
                                                        <div class="avatar-group">
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <img src="@/assets/images/users/avatar-5.jpg" alt="" class="rounded-circle img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </BCardBody>
                                    </BCard>
                                </div>
                            </swiper-slide>

                            <swiper-slide>
                                <div class="swiper-slide">
                                    <BCard no-body class="profile-project-card shadow-none profile-project-primary mb-0">
                                        <BCardBody class="p-4">
                                            <div class="d-flex">
                                                <div class="flex-grow-1 text-muted overflow-hidden">
                                                    <h5 class="fs-14 text-truncate mb-1">
                                                        <BLink href="#" class="text-body">Project update</BLink>
                                                    </h5>
                                                    <p class="text-muted text-truncate mb-0">
                                                        Last Update : <span class="fw-semibold text-body">4 hr Ago</span>
                                                    </p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <BBadge variant="success-subtle" class="bg-success-subtle text-success fs-10">
                                                        Completed
                                                    </BBadge>
                                                </div>
                                            </div>

                                            <div class="d-flex mt-4">
                                                <div class="flex-grow-1">
                                                    <div class="d-flex align-items-center gap-2">
                                                        <div>
                                                            <h5 class="fs-12 text-muted mb-0">Members : </h5>
                                                        </div>
                                                        <div class="avatar-group">
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <img src="@/assets/images/users/avatar-4.jpg" alt="" class="rounded-circle img-fluid" />
                                                                </div>
                                                            </div>
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <img src="@/assets/images/users/avatar-5.jpg" alt="" class="rounded-circle img-fluid" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </BCardBody>
                                    </BCard>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="swiper-slide">
                                    <BCard no-body class="profile-project-card shadow-none profile-project-success mb-0">
                                        <BCardBody class="p-4">
                                            <div class="d-flex">
                                                <div class="flex-grow-1 text-muted overflow-hidden">
                                                    <h5 class="fs-14 text-truncate mb-1">
                                                        <BLink href="#" class="text-body">Chat App</BLink>
                                                    </h5>
                                                    <p class="text-muted text-truncate mb-0">
                                                        Last Update : <span class="fw-semibold text-body">1 hr Ago</span>
                                                    </p>
                                                </div>
                                                <div class="flex-shrink-0 ms-2">
                                                    <BBadge variant="warning-subtle" class="bg-warning-subtle text-warning fs-10">
                                                        Inprogress
                                                    </BBadge>
                                                </div>
                                            </div>

                                            <div class="d-flex mt-4">
                                                <div class="flex-grow-1">
                                                    <div class="d-flex align-items-center gap-2">
                                                        <div>
                                                            <h5 class="fs-12 text-muted mb-0">
                                                                Members :
                                                            </h5>
                                                        </div>
                                                        <div class="avatar-group">
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <img src="@/assets/images/users/avatar-4.jpg" alt="" class="rounded-circle img-fluid" />
                                                                </div>
                                                            </div>
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <img src="@/assets/images/users/avatar-5.jpg" alt="" class="rounded-circle img-fluid" />
                                                                </div>
                                                            </div>
                                                            <div class="avatar-group-item">
                                                                <div class="avatar-xs">
                                                                    <div class="avatar-title rounded-circle bg-light text-primary">
                                                                        A
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </BCardBody>
                                    </BCard>
                                </div>
                            </swiper-slide>
                        </swiper>

                    </div>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>

</template>

<style>
.tamp {
    position: absolute;
    top: 17px;
    right: 16px;
}
</style>
