<script>
import { showAlert } from '@/common/comFuncs';
import { apiEnums, sysEnums } from '@/common/sysEnums';
import { computed, inject } from 'vue';

export default {
    props: {
        model: Object,
    },
    setup(props) {
        // const router = inject('$router');
        const axios = inject('$axios');

        const userInfo = inject('$userInfo');
        const widgetInfo = inject('$widgetInfo');

        const { getUserFolder, getUserPage, selectedFolder, filteredPage, sendMessage, pageCount } = inject('$provideList');

        const isFolder = computed(() => {
            // console.log('props.model : ' + JSON.stringify(props.model.Name) + ' / ' + JSON.stringify(props.model.children));
            return props.model.children && props.model.children.length > 0;
        });

        const changeType = () => {
            if (!isFolder.value) {
                // props.model.children = []
                console.log('props.model : ' + JSON.stringify(props.model));
            }
        };

        const navigateTo = data => {
            if (data.Type === sysEnums.PageCategorys.Folder && data.children.length > 0) {
                widgetInfo.value.children = [];
                data.children.forEach(x => {
                    widgetInfo.value.children.push(x.EncFolder);
                });

                //console.log(JSON.stringify(widgetInfo.value.children));
            }

            filteredPage.value = [];
            widgetInfo.value.tag = sysEnums.PageTags.Default;
            selectedFolder.value = data;
            widgetInfo.value.title = data.Name;
            // router.push(target);
        };

        const dragStart = (data, event) => {
            event.dataTransfer.setData('text/plain', JSON.stringify(data));
        };

        const handleDropFolderMove = (data, event) => {
            try {
                event.preventDefault();

                const dataString = event.dataTransfer.getData('text/plain');
                const droppedData = JSON.parse(dataString);

                if (droppedData.EncPage) {
                    const role = droppedData.PageRoles.find(x => x.EncPage === droppedData.EncPage && x.EncUser === droppedData.EncUser);
                    createRole(data.EncFolder, role ? role.EncRole : sysEnums.zero, droppedData.EncPage, droppedData.EncUser);
                } else {
                    updateFolder(data.EncFolder, droppedData.EncFolder);
                }
                // console.log(droppedData.EncPage + ' / ' + JSON.stringify(data) + ' / ' + JSON.stringify(droppedData));
            } catch (error) {
                console.error('Error selectClip : ', error);
            }
        };

        // 폴더 밑에 폴더 지정
        const updateFolder = (encTopFolder, encFolder) => {
            // if (encUser !== userInfo.value.EncUser) {
            //     showAlert('Alert!', '폴더 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
            //     return;
            // }

            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncFolder', encFolder);
            formData.append('EncTopFolder', encTopFolder);
            axios
                .post(apiEnums.CloudTicket.updateFolder, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        sendMessage(null, sysEnums.SignalTypes.Folder);
                        // console.log('handleKeyDown : ' + response.data)
                    }
                })
                .catch(() => {});
        };

        // 페이지를 작업공간 메뉴에 드롭
        const createRole = (encFolder, encRole, encPage, encUser) => {
            if (encUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '페이지 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            const formData = new FormData();
            formData.append('EncFolder', encFolder);
            formData.append('EncPage', encPage);
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncOwner', userInfo.value.EncUser);
            if (encRole) {
                formData.append('EncRole', encRole);
            }
            axios
                .post(apiEnums.CloudTicket.createRole, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        getUserPage();
                        sendMessage(null, sysEnums.SignalTypes.Page);
                    }
                    console.log(response.data);
                })
                .catch(() => {});
        };

        const badgeVariant = computed(() => {
            return props.model.Shared && props.model.EncUser === userInfo.value.EncUser ? 'success' : 'dark';
        });

        const filteredFolderRolesCount = computed(() => {
            // if (props.model.FolderRoles && props.model.FolderRoles.length > 0 && props.model.FolderRoles[0].RoleNum === 678) {
            //     console.log(userInfo.value.EncUser + ' / ' + JSON.stringify(props.model));
            // }

            return props.model.FolderRoles.filter(x => x.EncPage !== sysEnums.zero && x.EncUser === userInfo.value.EncUser && x.EncTopFolder === selectedFolder.value.EncFolder)
                .length;
        });

        return {
            props,
            sysEnums,
            widgetInfo,
            isFolder,
            userInfo,
            selectedFolder,
            badgeVariant,
            filteredFolderRolesCount,
            pageCount,
            changeType,
            navigateTo,
            dragStart,
            handleDropFolderMove,
        };
    },
};
</script>

<template>
    <li class="nav-item no_dot text-muted">
        <span
            v-if="model.Name !== 'WORKSPACE'"
            class="d-flex flex-items-center"
            :class="{ bold: isFolder }"
            @click="navigateTo(model)"
            @dragover.prevent
            @drop="handleDropFolderMove(model, $event)"
            @dblclick="changeType"
        >
            <!--{{ model.EncFolder + ' / ' + model.EncTopFolder + ' / ' + model.Type + ' / ' + model.Name }}-->
            <div
                v-if="isFolder"
                :class="selectedFolder.EncFolder === model.EncFolder ? 'bg-dark text-light w-100 p-1' : 'mb-3'"
                style="cursor: move"
                draggable="true"
                @dragstart="dragStart(model, $event)"
            >
                <i :class="model.Type === sysEnums.PageCategorys.Folder ? 'ri-folder-3-line' : 'ri-space'"></i><span class="fw-bold ps-1" v-text="model.Name"></span>
                <!-- <BBadge v-if="model.FolderRoles.length > 0" :variant="model.Shared && model.EncUser === userInfo.EncUser ? 'success' : 'dark'" class="ms-2">{{
                    model.FolderRoles.filter(x => x.EncUser !== userInfo.value.EncUser && x.EncPage === sysEnums.zero).length
                }}</BBadge> -->

                <BBadge v-if="model.FolderRoles.length > 0" :variant="badgeVariant" class="ms-2"> {{ filteredFolderRolesCount }} </BBadge>

                <BBadge v-if="model.Shared && model.EncUser !== userInfo.EncUser" variant="success" class="ms-1">공유</BBadge>
                <!-- <i v-if="model.Shared && model.EncUser === userInfo.EncUser" class=" ri-send-plane-line ms-1"></i> -->
            </div>

            <div
                v-else
                :class="selectedFolder.EncFolder === model.EncFolder ? 'bg-secondary text-white w-100 p-2' : ''"
                style="cursor: move"
                draggable="true"
                class="menu-dropdown"
                @dragstart="dragStart(model, $event)"
            >
                <span :class="widgetInfo.children.includes(model.EncFolder) ? 'folder folder-expanded' : model.Type === sysEnums.PageCategorys.Folder ? '' : 'd-none'">
                    <i :class="model.Type === sysEnums.PageCategorys.Folder ? 'ri-folder-3-line' : 'ri-space'"></i><span class="ps-1" v-text="model.Name"></span>

                    <BBadge v-if="model.FolderRoles.length > 0" :variant="badgeVariant" class="ms-2">
                        {{ pageCount(model.EncFolder) }}
                    </BBadge>

                    <BBadge v-if="model.Shared && model.EncUser !== userInfo.EncUser" variant="success" class="ms-1">공유</BBadge>
                </span>
            </div>

            <!-- <div v-else>{{ selectedFolder.EncFolder + '\n' + model.EncFolder + '\n' + model.EncTopFolder +'\n'+ filteredFolderRolesCount }}</div> -->
        </span>
        <ul v-if="isFolder">
            <TreeMenu v-for="x in model.children" :key="x" :model="x"></TreeMenu>
        </ul>
    </li>
</template>

<style scoped lang="scss">
.no_dot {
    list-style-type: none !important;
    list-style: none !important;
}

ul {
    list-style-type: disc;
    padding-left: 12px;
    margin-left: 0;
}

ul li {
    padding-left: 0;
    margin-left: 12px;
    line-height: 1.5;
}

ul:nth-child(1) {
    margin-left: -16px;
}

li .folder {
    transform: scaleX(0);
    transform-origin: left;
    overflow: hidden;
    transition: transform 0.5s ease, background-color 0.3s ease, color 0.3s ease;
}

/* 아래 클래스가 적용될 때 폭이 100%로 확대 */
li .folder-expanded {
    transform: scaleX(1);
}
</style>
