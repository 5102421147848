<template>
    <div class="card p-2">

        <div class="card-body">
            <!-- 입력 부분 시작 -->
            <div class="mb-1">
                <p class="text-muted fw-bold">휴대폰번호, 초대자명을 입력하여 주세요</p>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-floating">
                        <input type="text" class="form-control" id="floatingInput1" placeholder="휴대폰번호를 입력하여 주세요" v-model="recipient" />
                        <label for="floatingInput1">휴대폰번호를 -를 제외하고 입력하여 주세요</label>
                    </div>
                </div>
            </div>

            <table class="table table-striped table-hover text-center">
                <thead>
                    <tr class="h-50h bg-light align-middle fw-bold">
                        <th scope="col">번호</th>
                        <th v-for="x in titles" :key="x">{{ x }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="h-50h align-middle hand" v-for="(x, i) in fileList.filter(x => x.EncUser === userInfo.EncUser)" :key="x">
                        <th class="w-10" scope="row">{{ i + 1 }}</th>
                        <td>
                            <!-- <img :src="x.AvatarPath ? $fileUrl + x.AvatarPath : 'assets/images/no-profile.jpg'"
class="rounded-circle-36"
:class="x.Status ? '' : 'opacity-25'" /> -->
                            <!-- {{ getMenuFolder(x.EncFolder).length }} -->
                        </td>
                        <td class="text-start" v-text="x.FileName"></td>
                        <td class="w-10" v-text="(x.FileSize / 1000000).toFixed(1) + 'Mb'"></td>
                        <td class="w-10" v-if="!$store.state.isMobile" v-text="$moment(x.Created).format('YY.MM.DD')"></td>
                        <!-- <td class="w-10">
                            <span class="form-switch">
                                <input class="form-check-input hand" type="checkbox" role="switch" id="flexSwitchCheckDefault" :checked="x.Status" />
                            </span>
                        </td> -->
                    </tr>
                </tbody>
            </table>
            <!-- <div class="row mt-4 mb-4">
                <div class="col">
                    <div class="form-floating">
                        <input type="text" class="form-control" id="floatingInput2" placeholder="고객명을 입력하여 주세요" v-model="receiverName" />
                        <label for="floatingInput2">초대하고자하는 고객명을 입력하여 주세요</label>
                    </div>
                </div>
            </div> -->

            <!-- <div class="mb-5 d-flex justify-content-around hand">
                <span @click="userType = 1" class="text-center"><i class="fs-50" :class="userType === 1 ? 'las la-user text-danger' : 'las la-user'"></i><br>직원</span>
                <span @click="userType = 0" class="text-center"><i class="fs-50" :class="userType === 0 ? 'las la-handshake text-danger' : 'las la-handshake'"></i><br>파트너</span>
            </div> -->

            <!-- <div>
                <div class="mb-4 bg-dark text-light p-4 br-14">
                    <div class="mb-2 fw-bold" v-html="title"></div>
                    <div>[Web발신]</div>
                    <textarea class="w-100 h-180 border-0 bg-dark text-light" v-html="msg" readonly></textarea>
                    <div>https://app.pageclip.ai/6084JRax9S7xQcR55G2ZZw==</div>
                </div>
                <div class="w-100 mt-2 fw-bold" v-html="result"></div>
            </div> -->

        </div>

    </div>

    <div class="col-lg-12 bg-white p-2">
        <div class="row">
            <div class="col-4" data-bs-dismiss="offcanvas">
                <button type="button" class="btn btn-light h-50h w-100">취소</button>
            </div>
            <div class="col-8 ps-0">
                <button class="btn btn-primary h-50h w-100" @click="sendAlimTalk">보내기</button>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import { ref, inject } from "vue";
import { apiEnums } from "@/common/sysEnums";
// import { showAlert } from "@/common/comFuncs";

export default {
    setup() {
        const titles = ref(['', '파일명', '사이즈', '등록일']);

        let result = ref('');
        let recipient = ref('');
        // let receiverName = ref('');

        // 주의 제목길이 40바이트 제한
        // const title = ref('페이지클립 초대안내');

        const filteredUsers = ref([]);

        const axios = inject("$axios");

        const userInfo = inject("$userInfo");

        const { fileList } = inject("$provideList");

        // let msg = computed(() => {
        //     return `${enumTypes.UserTypes[userInfo.value.UserType]}  ${userInfo.value.UserName} 님이\n\n${receiverName.value} 님에게\n${enumTypes.UserTypes[userType.value]} 으로 초대 요청을 하였습니다.\n\n링크된 주소를 클릭하신후 회원가입을 진행해 주시기 바랍니다.\n\n`;
        // });

        const sendAlimTalk = () => {
            const formData = new FormData();
            formData.append('TmpltCode', 'A01a');
            formData.append('CompanyName', '어반클립');
            formData.append('Title', '파일전송');
            formData.append('Recipient', recipient.value);
            formData.append('UserName', '관리자');

            // const Addr2 = props.Data.Address2.trim() === '' ? ' ' : props.Data.Address2;
            // formData.append('Address', props.Data.Address1 + ' ' + Addr2);

            axios.post(apiEnums.BizTalk.sendAlimTalk, formData)
                .then((response) => {
                    // console.log(JSON.stringify(response))
                    result.value = response;
                })
                .catch(() => { });
        };


        return {
            result, userInfo,
            recipient, titles,
            filteredUsers, fileList,
            sendAlimTalk,
        };
    },
};
</script>

<style scoped lang="scss"></style>
