<script>
import { getUserDepartment, showAlert, showConfirm, timesElapsed } from '@/common/comFuncs';
import { apiEnums, enumTypes, sysEnums } from '@/common/sysEnums';
import { inject } from 'vue';

export default {
    setup() {
        const axios = inject('$axios');
        const userInfo = inject('$userInfo');
        const widgetInfo = inject('$widgetInfo');

        const { userList, selectedFolder, selectedPage, gotoModal, getUserFolder, getUserPage, sendMessage, parentFolderName } = inject('$provideList');

        const updatePageFix = () => {
            if (selectedPage.value.EncUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '페이지 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            if (selectedPage.value) {
                const formData = new FormData();
                formData.append('EncPage', selectedPage.value.EncPage);
                formData.append('Fixed', 1);
                axios
                    .post(apiEnums.CloudTicket.updatePage, formData)
                    .then(response => {
                        if (response.data !== '0000') {
                            getUserFolder();
                            getUserPage();
                        }
                    })
                    .catch(() => {});
            } else {
                console.log('updatePageFix error : ' + JSON.stringify(selectedPage.value));
            }
        };

        const updatePageLock = flag => {
            if (selectedPage.value.EncUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '페이지 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            const formData = new FormData();
            formData.append('EncPage', selectedPage.value.EncPage);
            flag ? formData.append('Locked', 1) : formData.append('Shared', 1);
            axios
                .post(apiEnums.CloudTicket.updatePage, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserPage();
                        sendMessage(null, sysEnums.SignalTypes.Folder);
                    }
                })
                .catch(() => {});
        };

        const updatePageTrash = () => {
            showConfirm('', selectedPage.value.Trash ? '페이지를 복원 합니다.' : '페이지를 삭제 합니다.', selectedPage.value.Trash ? '복원하기' : '삭제하기').then(result => {
                if (result) {
                    const formData = new FormData();
                    formData.append('EncUser', userInfo.value.EncUser);
                    formData.append('EncPage', selectedPage.value.EncPage);
                    formData.append('Trash', 1);
                    axios
                        .post(apiEnums.CloudTicket.updatePage, formData)
                        .then(response => {
                            if (response.data !== '0000') {
                                getUserPage();
                                widgetInfo.value.dataModal = [];
                            }
                        })
                        .catch(() => {});
                }
            });
        };

        return {
            sysEnums,
            enumTypes,
            userInfo,
            widgetInfo,
            userList,
            selectedFolder,
            selectedPage,
            parentFolderName,
            timesElapsed,
            gotoModal,
            getUserDepartment,
            updatePageLock,
            updatePageFix,
            updatePageTrash,
        };
    },
};
</script>

<template>
    <div class="bg-primary-subtle">
        <BCardBody class="p-4 mt-4 mb-5">
            <BRow>
                <BCol md>
                    <BRow class="align-items-center">
                        <BCol md="auto">
                            <div class="avatar-md mb-md-0 mb-4">
                                <div class="avatar-title bg-white rounded-circle">
                                    <img
                                        :src="selectedPage.Avatar ? $fileUrl + selectedPage.Avatar : 'assets/images/no-profile.jpg'"
                                        class="avatar-sm"
                                        :class="selectedPage.Status ? '' : 'opacity-25'"
                                    />
                                </div>
                            </div>
                        </BCol>

                        <BCol md>
                            <div class="d-flex align-items-center">
                                <span class="fs-20 fw-semibold">{{ selectedPage.UserName }}</span>
                                <span class="text-muted fs-16 px-3">{{
                                    getUserDepartment(selectedPage.Position, true) + ' / ' + getUserDepartment(selectedPage.Department, false)
                                }}</span>
                                <span class="text-muted">{{ timesElapsed(selectedPage.Updated) }}</span>
                            </div>
                            <div class="d-flex">
                                <!-- <div class="text-muted"><i class="ri-building-line me-1"></i>{{ widgetInfo.companyInfo.BusinessNum || '' }}</div>
                                <div class="vr"></div> -->
                                <div class="hstack gap-3 flex-wrap" @click="gotoModal(selectedPage, 'PageSetDate', '종료일 및 시간변경하기')">
                                    <div class="text-muted">
                                        <i class="ri-building-line me-1"></i> Created : {{ $moment(selectedPage.Created).format('YYYY-MM-DD hh:mm') || '' }}
                                    </div>
                                    <div class="vr"></div>
                                    <div class="text-muted">Due Date : {{ $moment(selectedPage.Expired).format('YYYY-MM-DD') || '' }}</div>
                                    <div class="vr"></div>
                                </div>

                                <i class="ri-price-tag-3-line fs-20 me-2" @click="gotoModal(selectedPage, 'PageTag', '페이지 업무Tag 및 해시태그 변경하기')"></i>
                                <template v-if="selectedPage && selectedPage.Tag">
                                    <span class="px-2">
                                        <BBadge :variant="enumTypes.PageTags[selectedPage.Tag].variant" pill>{{ enumTypes.PageTags[selectedPage.Tag].name }}</BBadge>
                                    </span>
                                </template>
                            </div>
                            <div class="hstack gap-3 flex-wrap" @click="gotoModal(selectedPage, 'PageSetMap', '위치정보')">
                                <div class="text-muted"><i class="ri-map-pin-5-line me-1"></i> {{ selectedPage.Address1 || '' }} {{ selectedPage.Address2 || '' }}</div>
                                <div class="vr"></div>
                            </div>
                            <div class="hstack gap-3 flex-wrap">
                                <div class="text-muted">
                                    <i class="ri-link me-1"></i>
                                    <span class="pe-2" v-for="folder in parentFolderName(selectedFolder.EncFolder)" :key="folder.EncFolder"> {{ folder.name }} > </span>
                                    {{ selectedPage.EncPage ? selectedPage.Title : '' }}
                                </div>
                            </div>
                        </BCol>
                    </BRow>
                </BCol>
                <BCol md="auto" class="mt-md-0 mt-4 pe-4">
                    <div class="d-flex align-items-center">
                        <span class="btn py-0"> <i :class="selectedPage.Fixed ? 'ri-pushpin-2-fill text-primary' : 'ri-pushpin-line'" @click="updatePageFix"></i></span>
                        <span class="btn py-0 px-2">
                            <i :class="selectedPage.Locked ? 'ri-lock-line text-danger' : 'ri-lock-unlock-line'" @click="updatePageLock(true)"></i>
                        </span>
                        <span class="btn py-0">
                            <i :class="selectedPage.Shared ? 'ri-eye-fill text-primary' : 'ri-eye-off-line'" @click="updatePageLock(false)"></i>
                        </span>
                        <BDropdown
                            variant="link"
                            toggle-class="p-1 py-0 fs-15 arrow-none"
                            menu-class="dropdown-menu-end"
                            :offset="{ alignmentAxis: -130, crossAxis: 0, mainAxis: 10 }"
                        >
                            <template #button-content>
                                <i class="ri-more-line"></i>
                            </template>
                            <BDropdownItem @click="gotoModal(x, 'PageMove', '페이지 복사이동하기')"
                                ><i class="ri-pencil-line align-bottom me-2 text-muted"></i>복사이동</BDropdownItem
                            >
                            <BDropdownItem @click="gotoModal(x, 'PageMove', '페이지 복사이동하기')"
                                ><i class="ri-share-forward-line align-bottom me-2 text-muted"></i>공유하기</BDropdownItem
                            >
                            <BDropdownItem><i class="ri-file-pdf-line align-bottom me-2 text-muted"></i>PDF저장</BDropdownItem>
                            <BDropdownItem><i class="ri-printer-line align-bottom me-2 text-muted"></i>인쇄하기</BDropdownItem>
                            <BDropdownItem><i class="ri-bookmark-line align-bottom me-2 text-muted"></i>북마크하기</BDropdownItem>
                            <div class="dropdown-divider"></div>
                            <BDropdownItem @click="gotoModal(null, 'PageCreate', '제목수정')"><i class="ri-text align-bottom me-2 text-muted"></i>제목수정</BDropdownItem>
                            <BDropdownItem @click="updatePageTrash"><i class="ri-delete-bin-4-line align-bottom me-2 text-muted"></i>삭제하기</BDropdownItem>
                        </BDropdown>
                    </div>
                </BCol>
            </BRow>
        </BCardBody>
    </div>
</template>

<style></style>
