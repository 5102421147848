<script>
import { showAlert } from '@/common/comFuncs';
import { apiEnums, enumTypes, sysEnums } from '@/common/sysEnums';
import { inject, onMounted, reactive, ref, watch } from 'vue';

import ClipDefault from '@/components/clips/ClipDefault';
import ClipDraw from '@/components/clips/ClipDraw';
import ClipImage from '@/components/clips/ClipImage';
import ClipTable from '@/components/clips/ClipTable';

import FileUpload from '@/components/files/FileUpload';
// import EditorBottom from "@/components/pages/EditorBottom";

export default {
    setup() {
        const titles = ref(['', '제목', '클립수', '파일수', '권한수', '등록일', '상태']);
        const titles1 = ref(['', '회원명', '아이디', '등록일', '상태']);

        const files = ref([]);
        const clipRefs = ref([]);

        const content = ref('');

        const activeUser = ref('');

        const fileRef = ref(null);

        const selectedIdx = ref(0);

        const pageCategory = ref(null);

        // const encUser = localStorage.getItem('encUser');
        const pageCategorys = reactive(sysEnums.PageCategorys);

        const axios = inject('$axios');

        const signals = inject('$signals');
        const widgetInfo = inject('$widgetInfo');

        const userInfo = inject('$userInfo');

        const { userList, pageList, clipList, selectedPage, selectedClips, setClipSortTarget, setClipUpdate, getUserClip, sendMessage } = inject('$provideList');

        watch(
            () => [...files.value],
            currentValue => {
                return currentValue;
            },
        );

        // 클립 리스트(clipList.value)에 대한 감시 설정
        watch(
            () => clipList.value,
            newValue => {
                const datas = JSON.parse(newValue);
                const idx = selectedClips.value.findIndex(x => x.EncClip === datas.EncClip);
                // console.log('clipList watch PageEditor :', JSON.stringify(selectedClips.value, null, 4));

                if (idx !== -1) {
                    // selectedClips.value[idx] = datas;
                    activeUser.value = selectedClips.value[idx].EncUser;

                    setTimeout(() => {
                        activeUser.value = '';
                    }, 900);
                }
            },
        );

        // selectedClips.value 에서 value 사용시 갱신 안됨
        watch(selectedClips, () => {
            widgetInfo.value.pageWid = document.getElementById('pageBase').offsetWidth;
            selectClip(sysEnums.ClipTypes.Default, selectedClips.value.length - 1);
        });

        onMounted(() => {
            widgetInfo.value.pageWid = document.getElementById('pageBase').offsetWidth;
        });

        const dragStart = (data, event) => {
            event.dataTransfer.setData('text/plain', JSON.stringify(data));
        };

        const handleDropClipMove = (data, event) => {
            event.preventDefault();

            try {
                const dataString = event.dataTransfer.getData('text/plain');
                const droppedData = JSON.parse(dataString);

                if (!data) {
                    if (droppedData.Hided) {
                        setClipUpdate(droppedData, sysEnums.ClipHandles.Status);
                    }
                    return;
                }

                if (droppedData.EncClip) {
                    setClipSortTarget(droppedData, data.EncClip);
                }
                // console.log('PageEditor handleDropClipMove : ' + JSON.stringify(data, droppedData.EncClip));
            } catch (error) {
                console.error('Error handleDropClipMove : ', error);
            }
        };

        const handleInput = data => {
            sendMessage(data, sysEnums.SignalTypes.Clip);
            // console.log('handleInput : ' + JSON.stringify(data) + ' / ' + widgetInfo.value.selectedClip.EncClip);
        };

        const onDragEndCallback = data => {
            // console.log('onDragEndCallback : ' + JSON.stringify(data, null, 4));
            if (data.Xpos < 0) data.Xpos = 0;
            if (data.Ypos < 0) data.Ypos = 0;

            if (data.EncUser) {
                const formData = new FormData();
                formData.append('EncUser', data.EncUser);
                formData.append('EncClip', data.EncClip);
                formData.append('Xpos', parseInt(calculatePositionPercent(data.Xpos)));
                formData.append('Ypos', parseInt(data.Ypos));
                axios
                    .post(apiEnums.CloudTicket.updateClip, formData)
                    .then(response => {
                        if (response.data !== '0000') {
                            // console.log('onDragEndCallback response : ' + JSON.stringify(response.data, null, 4));
                        }
                    })
                    .catch(() => {});
            }
        };

        // 함수: 뷰포트 상대 좌표를 퍼센트 값으로 계산
        const calculatePositionPercent = xpos => {
            const xPercentage = (xpos / widgetInfo.value.pageWid) * 100;
            return xPercentage;
        };

        const onResizeEndCallback = data => {
            // console.log('onResizeEndCallback : ' + JSON.stringify(data, null, 4));
            if (data.EncUser) {
                const formData = new FormData();
                formData.append('EncUser', data.EncUser);
                formData.append('EncClip', data.EncClip);
                formData.append('Wid', parseInt(data.Wid));
                formData.append('Hei', parseInt(data.Hei));
                axios
                    .post(apiEnums.CloudTicket.updateClip, formData)
                    .then(response => {
                        if (response.data !== '0000') {
                            // console.log('onDragEndCallback response : ' + JSON.stringify(response.data, null, 4));
                        }
                    })
                    .catch(() => {});
            }
        };

        const createClip = type => {
            if (!selectedPage.value) {
                showAlert('Alert!', '페이지를 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            if (type === sysEnums.ClipTypes.Table) {
                content.value = `
                <h3>새 테이블</h3>
                <table>
                    <tbody>
                        <tr>
                            <th>Title</th>
                            <th colspan="3">Description</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>`;
            }

            const formData = new FormData();
            formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncPage', selectedPage.value.EncPage);
            formData.append('Content', content.value);
            formData.append('Type', type);
            axios
                .post(apiEnums.CloudTicket.createClip, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserClip();
                    }
                })
                .catch(() => {});
        };

        const selectClip = (type, idx) => {
            try {
                if (selectedClips.value.length > 0) {
                    selectedClips.value.forEach(x => (x.isActive = false));
                    selectedClips.value[idx].isActive = true;

                    selectedIdx.value = idx;

                    widgetInfo.value.selectedClip = selectedClips.value[idx];
                }

                type === sysEnums.ClipTypes.Draw ? (widgetInfo.value.locked = true) : (widgetInfo.value.locked = false);

                // console.log(idx + ' / ' + selectedClips.value.length)
            } catch (error) {
                console.error('Error selectClip : ', error);
            }
        };

        const selectImage = () => {
            fileRef.value.handleSelectImage();
        };

        const getSlicedTitle = content => {
            if (!content) return '';
            const plainText = content.replace(/<\/?[^>]+(>|$)/g, '');
            return plainText.length > 5 ? plainText.slice(0, 5) : plainText;
        };

        const gotoTrash = () => {
            // if (widgetInfo.value.selectedClip.EncClip) {
            //     // console.log(widgetInfo.value.selectedClip)

            //     showConfirm('', '클립을 삭제 합니다.', '삭제하기').then(result => {
            //         if (result) {
            //             setClipUpdate(widgetInfo.value.selectedClip, sysEnums.ClipHandles.Hided);
            //             setClipStatus(widgetInfo.value.selectedClip);
            //         }
            //     });
            // }

            widgetInfo.value.titleModal = '휴지통';
            widgetInfo.value.targetModal = 'PageTrash';
            widgetInfo.value.modal = true;
        };

        const toggleClipImage = data => {
            // setClipUpdate(data, sysEnums.ClipHandles.Hided);
            clipRefs.value[selectedClips.value.length - 1].toggleImageVisibility(data.EncClip);
            selectedIdx.value = selectedClips.value.length - 1;
            console.log('----- ' + selectedIdx.value + ' /' + data.EncClip + ' / ' + data.Hided);
        };

        // const convertNewlinesToBr = (text) => {
        //     return text.replace(/\n/g, '<br>');
        // }

        const editorFunc = target => {
            if (target == 32) clipRefs.value[selectedIdx.value].editor.chain().focus().addRowBefore().run();
            if (target == 33) clipRefs.value[selectedIdx.value].editor.chain().focus().addRowAfter().run();
            if (target == 34) clipRefs.value[selectedIdx.value].editor.chain().focus().deleteRow().run();

            if (target == 36) clipRefs.value[selectedIdx.value].editor.chain().focus().addColumnBefore().run();
            if (target == 37) clipRefs.value[selectedIdx.value].editor.chain().focus().addColumnAfter().run();
            if (target == 38) clipRefs.value[selectedIdx.value].editor.chain().focus().deleteColumn().run();

            if (target == 40) clipRefs.value[selectedIdx.value].editor.chain().focus().mergeCells().run();
            if (target == 41) clipRefs.value[selectedIdx.value].editor.chain().focus().splitCell().run();
            if (target == 42) clipRefs.value[selectedIdx.value].editor.chain().focus().mergeOrSplit().run();

            if (target == 43) clipRefs.value[selectedIdx.value].editor.chain().focus().goToPreviousCell().run();
            if (target == 44) clipRefs.value[selectedIdx.value].editor.chain().focus().goToNextCell().run();
            if (target == 45) clipRefs.value[selectedIdx.value].editor.chain().focus().undo().run();
            if (target == 46) clipRefs.value[selectedIdx.value].editor.chain().focus().redo().run();

            // if (target == 47) clipRefs.value[selectedIdx.value].setClip(sysEnums.ClipHandles.Sort, 0);
            // if (target == 48) clipRefs.value[selectedIdx.value].setClip(sysEnums.ClipHandles.Sort, 1);
            // if (target == 49) clipRefs.value[selectedIdx.value].setClip(sysEnums.ClipHandles.Fix, null);
            // if (target == 50) clipRefs.value[selectedIdx.value].setClip(sysEnums.ClipHandles.Hided);
            // if (target == 43) clipRefs.value[selectedIdx.value].editor.chain().focus().setCellAttribute('backgroundColor', '#FAF594').run();
            // if (target == 44) clipRefs.value[selectedIdx.value].editor.chain().focus().fixTables().run();
            // if (target == 39) clipRefs.value[selectedIdx.value].editor.chain().focus().toggleHeaderColumn().run();
            // if (target == 40) clipRefs.value[selectedIdx.value].editor.chain().focus().toggleHeaderRow().run();
            // if (target == 41) clipRefs.value[selectedIdx.value].editor.chain().focus().toggleHeaderCell().run();

            if (target == 68) clipRefs.value[selectedIdx.value].drawReset();
            if (target == 69) clipRefs.value[selectedIdx.value].setClip(sysEnums.ClipHandles.Hided);

            // console.log(target, selectedIdx.value)
        };

        return {
            widgetInfo,
            clipRefs,
            sysEnums,
            enumTypes,
            fileRef,
            selectedIdx,
            activeUser,
            pageList,
            selectedPage,
            selectedClips,
            files,
            titles,
            titles1,
            content,
            pageCategory,
            pageCategorys,
            signals,
            userList,
            clipList,
            setClipUpdate,
            gotoTrash,
            editorFunc,
            getSlicedTitle,
            selectImage,
            selectClip,
            toggleClipImage,
            createClip,
            dragStart,
            handleDropClipMove,
            handleInput,
            onResizeEndCallback,
            onDragEndCallback,
            calculatePositionPercent,
        };
    },
    components: {
        FileUpload,
        ClipDefault,
        ClipTable,
        ClipDraw,
        ClipImage,
    },
};
</script>

<template>
    <BRow>
        <BCol lg="12">
            <BCard no-body class="border-0">
                <BCardBody>
                    <!-- <div class="mt-2 fs-20">
                        <label class="form-label" for="product-title-input">{{ selectedPage.Title }}</label>
                    </div> -->

                    <!-- <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <span v-for="(x, i) of signals" :key="i">
                                <BBadge v-if="x.Status" variant="primary-subtle" class="bg-primary-subtle text-primary me-1">{{ x.UserName }}</BBadge>
                            </span>
                        </div>
                        <div class="flex-shrink-0">
                            <div class="avatar-group">
                                <span v-for="(x, i) of signals" :key="i" class="avatar-group-item" v-b-tooltip.hover :title="x.UserName">
                                    <img :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'" class="rounded-circle avatar-xs"
                                        :class="x.Status ? '' : 'opacity-25'" />
                                </span>
                            </div>
                        </div>
                    </div> -->

                    <!-- 상위 컴포넌트에서 이미지클립시 생성시 필요                     -->
                    <FileUpload ref="fileRef" :FileType="sysEnums.FileTypes.Clip" :Content="selectedPage.EncPage" :IsBtn="true"></FileUpload>

                    <!-- 클립 -->
                    <div class="w-100" id="pageBase">
                        <BRow>
                            <BCol class="ps-3" lg="11">
                                <template v-for="(clip, i) in selectedClips" :key="i">
                                    <!-- <span class="w-100 h-100 position-absolute" v-if="clip.Fixed" :style="{ 'z-index': selectedIdx === i ? '100' : 100 - i }"
                                        @click="selectClip(sysEnums.ClipTypes.Default, selectedClips.length - 1)"> -->

                                    <span class="w-100 h-100 position-absolute" v-if="clip.Fixed && clip.Status" :style="{ 'z-index': selectedIdx === i ? '100' : 100 - i }">
                                        <ClipDefault
                                            :ref="el => (clipRefs[i] = el)"
                                            v-if="clip.Type === sysEnums.ClipTypes.Default"
                                            :modelValue="clip"
                                            :idx="i"
                                            @update:modelValue="handleInput"
                                        ></ClipDefault>
                                        <ClipDraw
                                            :ref="el => (clipRefs[i] = el)"
                                            v-if="!clip.Hided && clip.Type === sysEnums.ClipTypes.Draw"
                                            :modelValue="clip"
                                            :idx="i"
                                            @update:modelValue="handleInput"
                                        >
                                        </ClipDraw>
                                    </span>

                                    <span
                                        class="position-absolute"
                                        v-else-if="!clip.Hided && clip.Status"
                                        :style="{ 'z-index': selectedIdx === i ? '100' : 100 - i, cursor: clip.Fixed ? '' : 'move' }"
                                        @click="selectClip(clip.Type, i)"
                                    >
                                        <Vue3DraggableResizable
                                            :initW="clip.Wid"
                                            :initH="clip.Hei"
                                            :draggable="!widgetInfo.locked"
                                            :resizable="true"
                                            :parent="false"
                                            v-model:x="clip.Xpos"
                                            v-model:y="clip.Ypos"
                                            v-model:w="clip.Wid"
                                            v-model:h="clip.Hei"
                                            v-model:active="clip.isActive"
                                            @drag-end="onDragEndCallback(clip)"
                                            @resize-end="onResizeEndCallback(clip)"
                                        >
                                            <!-- <ClipDraw v-if="clip.Type === sysEnums.ClipTypes.Draw" :modelValue="clip" :idx="i" @update:modelValue="handleInput"></ClipDraw> -->
                                            <ClipDefault
                                                :ref="el => (clipRefs[i] = el)"
                                                v-if="clip.Type === sysEnums.ClipTypes.Default"
                                                :modelValue="clip"
                                                :idx="i"
                                                @update:modelValue="handleInput"
                                            ></ClipDefault>
                                            <ClipTable
                                                :ref="el => (clipRefs[i] = el)"
                                                v-if="clip.Type === sysEnums.ClipTypes.Table"
                                                :modelValue="clip"
                                                :idx="i"
                                                @update:modelValue="handleInput"
                                            ></ClipTable>
                                            <ClipImage :ref="el => (clipRefs[i] = el)" v-if="clip.Type === sysEnums.ClipTypes.Image" :modelValue="clip" :idx="i"></ClipImage>
                                        </Vue3DraggableResizable>
                                    </span>

                                    <!-- <span v-else>none</span> -->
                                </template>
                            </BCol>

                            <BCol class="pt-4 ps-4" lg="1">
                                <template v-for="(data, i) in selectedClips" :key="i">
                                    <BCard
                                        v-if="data.Status"
                                        no-body
                                        class="mb-2 border"
                                        :class="selectedClips[i].isActive ? 'border-3 border-danger' : ''"
                                        @dragover.prevent
                                        @drop="handleDropClipMove(data, $event)"
                                        @click="selectClip(data.Type, i)"
                                    >
                                        <div class="position-relative">
                                            <!-- <span v-if="data.Hided" class="ps-2 text-primary" @click="setClipUpdate(data, sysEnums.ClipHandles.Hided)"><i class="ri-eye-line"></i></span> -->

                                            <div
                                                class="text-center position-relative"
                                                :class="data.Hided ? 'fs-10 opacity-75' : ''"
                                                draggable="true"
                                                @dragstart="dragStart(data, $event)"
                                                style="cursor: move"
                                            >
                                                <span v-show="data.Type === sysEnums.ClipTypes.Default"
                                                    ><i :class="data.Fixed ? 'ri-fullscreen-line' : 'ri-font-size'"></i>
                                                    <div class="fs-10 text-muted">{{ getSlicedTitle(data.Content) }}</div>
                                                </span>
                                                <span v-show="data.Type === sysEnums.ClipTypes.Table"
                                                    ><i class="ri-layout-grid-line"></i>
                                                    <div class="fs-10 text-muted">{{ getSlicedTitle(data.Content) }}</div>
                                                </span>
                                                <span v-show="data.Type === sysEnums.ClipTypes.Draw"
                                                    ><i class="ri-quill-pen-line"></i>
                                                    <div class="fs-10 text-muted">그리기</div>
                                                </span>
                                                <span v-show="data.Type === sysEnums.ClipTypes.Image">
                                                    <img class="img-fit" :src="data.Content ? $fileUrl + data.Content : 'assets/images/no_image.png'" />
                                                </span>
                                            </div>

                                            <!-- <span
                                                v-if="data.Hided && data.Type === sysEnums.ClipTypes.Image"
                                                class="position-absolute translate-middle top-50 start-50 hand px-1 bg-dark rounded-circle opacity-50"
                                                @click="toggleClipImage(data)"
                                            >
                                                <i class="ri-eye-line text-light"></i>
                                            </span> -->

                                            <span
                                                v-if="data.Hided"
                                                class="position-absolute translate-middle top-50 start-50 hand px-1 bg-dark rounded-circle opacity-50"
                                                @click="setClipUpdate(data, sysEnums.ClipHandles.Hided)"
                                                ><i class="ri-eye-line text-light"></i
                                            ></span>
                                        </div>
                                    </BCard>
                                </template>

                                <BCard no-body class="mb-2 border" @dragover.prevent @drop="handleDropClipMove(null, $event)">
                                    <div class="p-2 text-center hand" @click="gotoTrash">
                                        <span class="position-relative">
                                            <i class="ri-delete-bin-line fs-20"></i>
                                            <BBadge
                                                v-show="selectedClips.filter(x => x.Status === false).length > 0"
                                                variant="danger"
                                                class="position-absolute top-0 start-100 translate-middle"
                                                pill
                                            >
                                                {{ selectedClips.filter(x => x.Status === false).length }}
                                            </BBadge>
                                        </span>
                                    </div>
                                </BCard>
                            </BCol>
                        </BRow>
                    </div>

                    <!-- <EditorBottom></EditorBottom> -->
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>
</template>
