<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <BRow>
            <BCol lg="12">
                <BCard no-body>

                    <BCardBody>

                        <div class="text-end pe-5 mb-2"><span class="pb-2 fs-20 border-bottom">{{ currentTime }}</span></div>

                        <table class="table">
                            <thead>
                                <tr class="h-50h align-middle fw-bold border-bottom">
                                    <th class="w-5">번호</th>
                                    <th class="col">일정</th>
                                    <th class="col">시간</th>
                                    <th class="col">분</th>
                                    <th class="col">초</th>
                                    <th class="w-10 text-center">상태</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="align-middle hand" v-for="x in schedules" :key="x">
                                    <th scope="row">{{ (x.Id) + 1 }}</th>
                                    <td v-text="x.Title"></td>
                                    <td>
                                        <div class="form-floating mb-3">
                                            <select class="form-select" id="floatingSelect1" aria-label="시간 단위" v-model="x.Hour" @change="setSchedule(x)">
                                                <option selected>시간 단위</option>
                                                <option v-for="x in 24" :key="x">{{ x }}</option>
                                            </select>
                                            <label for="floatingSelect1">시간 단위</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-floating mb-3">
                                            <select class="form-select" id="floatingSelect2" aria-label="분 단위" v-model="x.Minute" @change="setSchedule(x)">
                                                <option selected>분 단위</option>
                                                <option v-for="x in 60" :key="x">{{ x }}</option>
                                            </select>
                                            <label for="floatingSelect2">분 단위</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-floating mb-3">
                                            <select class="form-select" id="floatingSelect3" aria-label="초 단위" v-model="x.Second" @change="setSchedule(x)">
                                                <option selected>초 단위</option>
                                                <option v-for="x in 60" :key="x">{{ x }}</option>
                                            </select>
                                            <label for="floatingSelect3">초 단위</label>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <span class="form-switch hand mx-2" @click="toggleSchedule(x)">
                                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault1" :checked="x.Status" disabled />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </BCardBody>
                </BCard>
            </BCol>
        </BRow>

        <BRow>
            <BCol lg="12">
                <BCard no-body>

                    <BCardHeader class="justify-content-between d-flex align-items-center">
                        <BCardTitle>{{ $moment().format('YYYY') }}년 공휴일지정</BCardTitle>
                        <div class="w-25">
                            <div class="form-floating">
                                <input type="number" class="form-control text-center" id="floatingInput1" placeholder="공휴일 날자만 입력" @input="limitInput" v-model="holiday" />
                                <label for="floatingInput1">공휴일 날자만 입력</label>
                            </div>
                        </div>


                    </BCardHeader>

                    <BCardBody>

                        <BRow>

                            <template v-for="x in holidays" :key="x">
                                <BCol lg="3">
                                    <BCard no-body>
                                        <BCardHeader>
                                            <BCardTitle class="mb-0 hand" @click="setHoliday(x.Mon)">{{ x.Mon }}월</BCardTitle>
                                        </BCardHeader>
                                        <BCardBody class="p-4 d-flex align-items-center flex-wrap">

                                            <template v-for="y in x.Days" :key="y">
                                                <BButton variant="danger" class="p-2 mx-2 my-2 rounded-circle-46" pill @click="delHoliday(x.Mon, y)">{{ y }}</BButton>
                                                <!-- <span class="p-2 text-center text-light hand rounded-circle-46 bg-danger" @click="delHoliday(x.Mon, y)">{{ y }}</span> -->
                                            </template>

                                        </BCardBody>
                                    </BCard>
                                </BCol>
                            </template>

                        </BRow>

                    </BCardBody>
                </BCard>
            </BCol>
        </BRow>

    </Layout>
</template>

<script lang="js">
import Layout from "../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { onMounted, inject, ref } from "vue";
import { apiEnums, sysEnums } from "@/common/sysEnums";
import { showAlert, showConfirm } from "@/common/comFuncs";


export default {
    components: {
        Layout,
        PageHeader,
    },
    props: {
        Data: Object,
    },
    setup(props) {
        const schedules = ref({});
        const holidays = ref({});
        const holiday = ref('');

        let currentTime = ref('');

        const axios = inject("$axios");
        const moment = inject("$moment");


        const loadSchedules = async () => {
            // const response = await fetch('https://api-5.cloudticket.co.kr/schedule.json');
            // if (response.ok) {
            //     schedules.value = await response.json();
            //     console.log(JSON.stringify(schedules.value));
            // }

            axios.post(apiEnums.CloudTicket.getSchedule)
                .then((response) => {
                    schedules.value = response.data.Datas;
                    holidays.value = response.data.Holidays;
                    // console.log(JSON.stringify(response.data.Datas))
                })
                .catch(() => { });
        }

        onMounted(() => {
            loadSchedules();

            setInterval(updateTime, 3000);
        });

        const updateTime = () => {
            // 현재 시간을 가져와서 초로 변환
            currentTime.value = moment().format('YYYY-MM-DD hh:mm:ss');
        }

        const setSchedule = (x) => {
            const formData = new FormData();
            formData.append('Id', x.Id);
            formData.append('Hour', x.Hour);
            formData.append('Minute', x.Minute);
            formData.append('Second', x.Second);
            formData.append('Status', x.Status);
            axios.post(apiEnums.CloudTicket.setSchedule, formData)
                .then(() => {
                    // console.log(JSON.stringify(response));
                    loadSchedules();
                })
                .catch(() => { });
        };

        const toggleSchedule = (x) => {
            const formData = new FormData();
            formData.append('Id', x.Id);
            formData.append('Hour', x.Hour);
            formData.append('Minute', x.Minute);
            formData.append('Second', x.Second);
            formData.append('Status', x.Status === 0 ? 1 : 0);
            axios.post(apiEnums.CloudTicket.setSchedule, formData)
                .then(() => {
                    // console.log(JSON.stringify(response));
                    loadSchedules();
                })
                .catch(() => { });
        };

        const setHoliday = (mon) => {
            if (!holiday.value) {
                showAlert('', '공휴일 날자를 입력하세요.', 'HouseMeta', sysEnums.AlertTypes.Info);
            }

            const formData = new FormData();
            formData.append('Mon', (mon - 1));
            formData.append('Day', holiday.value);
            axios.post(apiEnums.CloudTicket.setHoliday, formData)
                .then(() => {
                    loadSchedules();
                })
                .catch(() => { });
        };

        const delHoliday = (mon, day) => {
            showConfirm('', '공휴일을 삭제 합니다.', '삭제하기').then((result) => {
                if (result) {
                    const formData = new FormData();
                    formData.append('Mon', (mon - 1));
                    formData.append('Day', day);
                    axios.post(apiEnums.CloudTicket.setHoliday, formData)
                        .then(() => {
                            loadSchedules();
                        })
                        .catch(() => { });
                }
            });
        };

        const limitInput = () => {
            // 입력값을 1에서 31 사이의 값으로 제한
            if (holiday.value < 1) holiday.value = '';
            if (holiday.value > 31) holiday.value = 31;
        }


        return {
            title: "Schedule",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "ScheduleList",
                    active: true,
                },
            ],
            props, currentTime,
            schedules, holidays, holiday,
            setSchedule, toggleSchedule,
            setHoliday, delHoliday, limitInput
        };
    },
};
</script>

<style scoped lang="scss"></style>
