<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="profile-foreground position-relative mx-n4 mt-n4">
            <div class="profile-wid-bg">
                <img src="@/assets/images/profile-bg.jpg" alt="" class="profile-wid-img" />
            </div>
        </div>

        <PageTop></PageTop>

        <BRow>
            <BCol lg="12">
                <div class="d-flex profile-wrapper" style="position: absolute; right: 13px; top: 26px">
                    <div class="d-flex justify-content-end">
                        <span class="btn btn-success"><i class="ri-edit-box-line align-bottom"></i> 임시</span>
                    </div>
                </div>
                <BTabs nav-class="animation-nav profile-nav gap-2 gap-lg-3 pt-4 text-muted" pills>
                    <BTab class="nav-item pt-4">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-none d-md-inline-block">목록</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <UserComp></UserComp>
                            </BCardBody>
                        </BCard>
                    </BTab>

                    <BTab class="nav-item">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-none d-md-inline-block">테이블 목록</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <UserComp :isTable="true"></UserComp>
                            </BCardBody>
                        </BCard>
                    </BTab>

                    <BTab class="nav-item">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-none d-md-inline-block">temp</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <UserCreate></UserCreate>
                            </BCardBody>
                        </BCard>
                    </BTab>
                </BTabs>
            </BCol>
        </BRow>
    </Layout>
</template>

<script lang="js">
import { ref, inject } from 'vue';
import { sysEnums, enumTypes } from "@/common/sysEnums";
// import { getUserTypeList } from "@/common/comFuncs";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import PageTop from "@/components/pages/PageTop";
import UserComp from "@/components/users/UserComp";
import UserCreate from "@/components/users/UserCreate";

export default {
    components: {
        Layout,
        PageHeader, PageTop,
        UserComp,
        UserCreate
    },
    props: {
        isTable: { type: Boolean, default: false },
        // Data: { type: Object, required: true },
        // Title: { type: Array, required: true },
    },
    setup(props) {
        const titles = ref(['', '회원명', '아이디', '타입', '권한수정', '수정일', '등록일', '상태']);

        // const fileRef = ref(false);

        // const store = inject("$store");
        // const axios = inject("$axios");

        const { userList, getUser } = inject("$provideList");

        // onMounted(() => {
        //     dataReset();
        // });

        // const dataReset = () => {
        //     let obj = {};
        //     userList.value.forEach(item => {
        //         if (!obj[item.LoginId]) {
        //             obj[item.LoginId] = [];
        //         }
        //         obj[item.LoginId].push(item);
        //     });
        //     dataList.value = obj;
        // }

        // const setUserTypeConsole = (encUser, userType) => {
        //     let formData = new FormData();
        //     formData.append('EncUser', encUser);
        //     formData.append('UserType', userType);
        //     axios.post(apiEnums.CloudTicket.setUserTypeConsole, formData)
        //         .then((response) => {
        //             // console.log(JSON.stringify(response))
        //             if (response.data === '0000') {
        //                 // resultMsg.value = '~';
        //             } else {
        //                 getUser();
        //             }
        //         })
        //         .catch(() => { });
        // };

        // const setUserData = (user) => {
        //     fileRef.value.setFileData(user);
        // }

        // const getUser = () => {
        //     axios.post(apiEnums.CloudTicket.getUserConsole)
        //         .then((response) => {
        //             store.dispatch("AC_USER_ALL", response.data);
        //             userList.value = response.data;
        //             // dataReset();
        //             // userList.value.forEach((item) => {
        //             //     item.Selected = false;
        //             // });
        //         })
        //         .catch(() => { });
        // };

        return {
            title: "Users",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "UserList",
                    active: true,
                },
            ],
            titles, sysEnums, enumTypes,
            userList, getUser, props,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable='true'] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
