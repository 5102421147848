<template>
    <!--<Layout>
        <PageHeader :title="title" :items="items" />-->

    <PageTop></PageTop>

    <BRow>
        <BCol lg="12">
            <div>
                <div class="d-flex profile-wrapper" style="position: absolute; right: 13px; top: 26px">
                    <div class="d-flex justify-content-end">
                        <span class="btn btn-success"><i class="ri-edit-box-line align-bottom"></i> Edit</span>
                    </div>
                </div>
                <BTabs nav-class="animation-nav profile-nav gap-2 gap-lg-3 pt-4 text-muted" pills v-model="widgetInfo.activeTab[1]">
                    <BTab class="nav-item pt-4">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-none d-md-inline-block">개요</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <CompanyInfo :Data="selectedCompany" @CloseRegist="widgetInfo.activeTab[1] = 0"></CompanyInfo>
                            </BCardBody>
                        </BCard>
                    </BTab>

                    <BTab class="nav-item pt-4">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-none d-md-inline-block">목록</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <CompanyCard></CompanyCard>
                            </BCardBody>
                        </BCard>
                    </BTab>

                    <BTab class="nav-item pt-4">
                        <template #title>
                            <i class="ri-price-tag-line d-inline-block d-md-none"></i>
                            <span class="d-none d-md-inline-block">등록</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <CompanyRequest :Data="selectedData" @CloseRegist="widgetInfo.activeTab[1] = 0"></CompanyRequest>
                            </BCardBody>
                        </BCard>
                    </BTab>

                    <BTab class="nav-item pt-4">
                        <template #title>
                            <i class="ri-folder-4-line d-inline-block d-md-none"></i>
                            <span class="d-none d-md-inline-block">Documents</span>
                        </template>

                        <BCard no-body>
                            <BCardBody> </BCardBody>
                        </BCard>
                    </BTab>

                    <BTab class="nav-item pt-4">
                        <template #title>
                            <i class="ri-list-unordered d-inline-block d-md-none"></i>
                            <span class="d-none d-md-inline-block">Activities</span>
                        </template>

                        <BCard no-body>
                            <BCardBody>
                                <h5 class="card-title mb-3">Activities...</h5>
                            </BCardBody>
                        </BCard>
                    </BTab>
                </BTabs>
            </div>
        </BCol>
    </BRow>

    <span class="d-none">
        <FileUploadSingle ref="fileRef" :FileType="sysEnums.FileTypes.Company"></FileUploadSingle>
    </span>
    <!--</Layout>-->
</template>

<script lang="js">
//import Layout from "../../layouts/main";
//import PageHeader from "@/components/page-header";

import PageTop from "@/components/pages/PageTop";
import CompanyInfo from "@/components/companys/CompanyInfo";
import CompanyCard from "@/components/companys/CompanyCard";
import CompanyRequest from "@/components/companys/CompanyRequest";
import FileUploadSingle from "@/components/files/FileUploadSingle";

import { ref, inject } from 'vue';
import { apiEnums, sysEnums, enumTypes } from "@/common/sysEnums";

export default {
    components: {
        //Layout,
        //PageHeader,
        PageTop,
        CompanyInfo, CompanyCard,
        CompanyRequest,
        FileUploadSingle,
    },
    props: {
        Data: Object,
        Title: Array,
    },
    setup(props) {
        const titles = ref(['회사명', '주소', '', '타입', '관리자', '파트너', '등록일', '메세지', '상태']);

        const isCompanyRegist = ref(false);
        const fileRef = ref(false);

        const canvasUserType = ref(0);

        const selectedCompany = ref({});
        const selectedData = ref({});
        const selectedList = ref([]);

        const widgetInfo = inject("$widgetInfo");

        const axios = inject("$axios");

        const { companyList, getCompany } = inject("$provideList");

        const updateCompany = (encCompany) => {
            const formData = new FormData();
            formData.append('EncCompany', encCompany);
            formData.append('Seqno', 99);
            axios.post(apiEnums.CloudTicket.updateCompany, formData)
                .then((response) => {
                    if (response.data === '1000') {
                        getCompany();
                    }
                })
                .catch(() => { });
        };

        const setCompany = (data) => {
            selectedCompany.value = data;
        }

        const setData = (data, user) => {
            canvasUserType.value = user;
            isCompanyRegist.value = !isCompanyRegist.value;
            isCompanyRegist.value ? selectedData.value = data : canvasUserType.value = 0;
        }

        return {
            title: "Company",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "CompanyList",
                    active: true,
                },
            ],
            props, sysEnums, enumTypes, isCompanyRegist,
            selectedData, selectedList, selectedCompany,
            titles, companyList, canvasUserType,
            fileRef, widgetInfo,
            updateCompany,
            setCompany, setData,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable='true'] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
