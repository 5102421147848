<script>
import { getUserDepartment, showAlert, showConfirm } from '@/common/comFuncs';
import { apiEnums, sysEnums } from '@/common/sysEnums';
import { computed, inject, ref } from 'vue';

import '@vueform/multiselect/themes/default.css';
import { MoreHorizontalIcon } from '@zhuowenli/vue-feather-icons';

import simplebar from 'simplebar-vue';

export default {
    props: {
        Index: Number,
        Data: Object,
    },
    components: {
        MoreHorizontalIcon,
        simplebar,
    },
    setup(props) {
        const trees = ref({ Name: 'FOLDER', children: [] });
        const subTrees = ref({ Name: 'FOLDER', children: [] });

        const store = inject('$store');
        const axios = inject('$axios');

        const userInfo = inject('$userInfo');
        const widgetInfo = inject('$widgetInfo');

        //const fileRef = ref(null);

        const { treeData, folderList, filteredPage, selectedFolder, getUserFolder, sendMessage, gotoModal, pageCount } = inject('$provideList');

        //onMounted(() => {
        //    filterData();
        //});

        //const filterData = () => {
        //    let filterCondition;

        //    if (props.Data.Shared) {
        //        // 공유시 전체 보이기
        //        filterCondition = item => item.EncFolder === (selectedFolder.value.EncFolder || props.Data.EncFolder);
        //    } else {
        //        // 공유 해제시 내 폴더가 보여야 편집 가능
        //        filterCondition = item => item.EncUser === userInfo.value.EncUser && item.EncFolder === (selectedFolder.value.EncFolder || props.Data.EncFolder);
        //    }

        //    const filteredChildren = treeData.value.children.filter(filterCondition);
        //    trees.value.children = filteredChildren;

        //    if (filteredChildren.length > 0) {
        //        if (filteredChildren[0].children && filteredChildren[0].children.length > 0) {
        //            // 하위 트리가 있을때
        //            subTrees.value.children = filteredChildren[0].children;
        //        } else {
        //            // 하위 트리가 없이 최상위 위치
        //            subTrees.value.children = filteredChildren;
        //        }
        //    } else {
        //        // 하위 트리가 없는경우
        //        subTrees.value.children = [];
        //    }

        //    // console.log('FolderItem filterData No selectedFolder : ' + props.Data.EncFolder + ' / ' + JSON.stringify(trees.value.children, null, 8))
        //    // console.log('FolderItem filterData No selectedFolder : ' + JSON.stringify(filteredChildren[0].children, null, 8))
        //};

        //const countPages = nodes => {
        //    const counts = {
        //        cntFolders: [],
        //        cntPosts: [],
        //        cntPages: [],
        //    };

        //    let cntFolder = 0;
        //    let cntPost = 0;
        //    let cntPage = 0;

        //    const countRoles = node => {
        //        if (node.FolderRoles && node.FolderRoles.length > 0) {
        //            cntPage += pageCount(node.EncFolder);
        //            // cntPage += node.FolderRoles.filter(x => x.Grouped === 3).length;
        //            // console.log(props.Data.EncFolder + ' / ' + JSON.stringify(node, null, 4));
        //        }

        //        if (node.Type === sysEnums.PageCategorys.Folder) {
        //            cntFolder += 1;
        //        }

        //        if (node.Type === sysEnums.PageCategorys.Post) {
        //            cntPost += 1;
        //        }

        //        // 페이지기준 권한시 하위 노드 전체페이지 카운트 / 6.25일 포스트단위 권한적용 변경요청
        //        if (node.children && node.children.length > 0) {
        //            for (let child of node.children) {
        //                countRoles(child);
        //            }
        //        }
        //    };

        //    const processNodes = nodes => {
        //        for (let node of nodes) {
        //            countRoles(node);

        //            if (node.Type === sysEnums.PageCategorys.Folder && node.EncUser === userInfo.value.EncUser) {
        //                cntFolder -= 1;
        //            }

        //            if (node.Type === sysEnums.PageCategorys.Post && node.EncUser === userInfo.value.EncUser) {
        //                cntPost -= 1;
        //            }

        //            // console.log(JSON.stringify(node));

        //            counts.cntFolders.push(cntFolder);
        //            counts.cntPosts.push(cntPost);
        //            counts.cntPages.push(cntPage);

        //            cntFolder = 0;
        //            cntPost = 0;
        //            cntPage = 0;
        //        }
        //    };

        //    if (selectedFolder.value.EncFolder) {
        //        for (let node of nodes) {
        //            processNodes(node.children);
        //        }
        //    } else {
        //        processNodes(nodes);
        //    }

        //    return counts;
        //};

        //const counts = computed(() => {
        //    // console.log(JSON.stringify(trees.value.children));
        //    return countPages(trees.value.children);
        //});

        //const cntFolder = computed(() => {
        //    if (selectedFolder.value.EncFolder) {
        //        return counts.value.cntFolders[props.Index] || 0;
        //    } else {
        //        return counts.value.cntFolders.length ? counts.value.cntFolders.reduce((acc, cnt) => acc + cnt, 0) : 0;
        //    }
        //});

        //const cntPost = computed(() => {
        //    if (selectedFolder.value.EncFolder) {
        //        return counts.value.cntPosts[props.Index] || 0;
        //    } else {
        //        return counts.value.cntPosts.length ? counts.value.cntPosts.reduce((acc, cnt) => acc + cnt, 0) : 0;
        //    }
        //});

        //const cntPage = computed(() => {
        //    if (selectedFolder.value.EncFolder) {
        //        return counts.value.cntPages[props.Index] || 0;
        //    } else {
        //        return counts.value.cntPages.length ? counts.value.cntPages.reduce((acc, cnt) => acc + cnt, 0) : 0;
        //    }
        //});

        const gotoFolder = (data, to, title) => {
            // let encTopFolder = sysEnums.zero;

            // if (selectedFolder.value && selectedFolder.value.EncFolder) {
            //     encTopFolder = selectedFolder.value.EncFolder;
            // }

            selectedFolder.value = data;

            // console.log(JSON.stringify(props.Data));

            if (!selectedFolder.value) {
                return;
            }

            if (props.Data.EncUser === userInfo.value.EncUser) {
                widgetInfo.value.titleModal = title;
                widgetInfo.value.modal = true;
                widgetInfo.value.targetModal = to;
            }
        };

        const navigateTo = data => {
            filteredPage.value = [];
            widgetInfo.value.tag = sysEnums.PageTags.Default;
            selectedFolder.value = data;
            widgetInfo.value.title = data.Name;
            store.state.routeTo.to = 5;

            //showAlert('Alert!', JSON.stringify(data), 'PageClip', sysEnums.AlertTypes.Info);
        };

        const filteredFolderRoles = computed(() => {
            const subFolders = folderList.value.filter(x => x.EncFolder === props.Data.EncFolder);

            const folderRoles = subFolders
                .filter(x => x.FolderRoles && x.FolderRoles.length > 0)
                .map(x => {
                    // console.log(props.Data.EncFolder + ' / ' + JSON.stringify(x.FolderRoles, null, 4));
                    const matchingRoles = x.FolderRoles.filter(y => y.EncFolder === props.Data.EncFolder && y.EncPage === sysEnums.zero);
                    return matchingRoles.length > 0 ? matchingRoles : null;
                })
                .filter(x => x !== null)
                .flat();

            // console.log(props.Data.Name + ' / ' + JSON.stringify(folderRoles, null, 4));

            return folderRoles;
        });

        const setFolderDel = () => {
            if (props.Data.EncUser !== userInfo.value.EncUser) {
                showAlert('Alert!', '폴더 소유주만 가능 합니다', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            showConfirm('', '폴더를 삭제 합니다.', '삭제하기').then(result => {
                if (result) {
                    const formData = new FormData();
                    formData.append('EncUser', userInfo.value.EncUser);
                    formData.append('EncFolder', props.Data.EncFolder);
                    formData.append('Status', 1);
                    axios
                        .post(apiEnums.CloudTicket.updateFolder, formData)
                        .then(response => {
                            if (response.data === '9999') {
                                showAlert('Alert!', '하위 폴더가 있습니다.', 'PageClip', sysEnums.AlertTypes.Info);
                            } else if (response.data !== '0000') {
                                getUserFolder();
                                sendMessage(null, sysEnums.SignalTypes.Folder);
                            }
                        })
                        .catch(() => {});
                }
            });
        };

        return {
            props,
            sysEnums,
            treeData,
            folderList,
            //fileRef,
            trees,
            subTrees,
            filteredFolderRoles,
            //cntFolder,
            //cntPost,
            //cntPage,
            pageCount,
            gotoModal,
            getUserDepartment,
            gotoFolder,
            navigateTo,
            setFolderDel,
        };
    },
};
</script>

<template>
    <div class="p-3 rounded-top placeholder-glow" :class="props.Data.Type === sysEnums.PageCategorys.Folder ? 'bg-secondary' : 'bg-primary'">
        <div class="d-flex align-items-center text-light">
            <img
                :src="props.Data.Avatar ? $fileUrl + props.Data.Avatar : 'assets/images/no-profile.jpg'"
                class="avatar-xs rounded-circle img-fit border border-3 hand"
                @click="gotoModal(props.Data, 'UserInfo', '사용자정보')"
            />

            <span class="ps-3 flex-fill">{{ props.Data.UserName }}</span>

            <span class="align-bottom">{{ getUserDepartment(props.Data.Position, true) + ' / ' + getUserDepartment(props.Data.Department, false) }}</span>

            <!--<span class="d-flex flex-column text-light ps-2">
                <span class="fs-16">{{ props.Data.UserName }}</span>
                <span>{{ getUserDepartment(props.Data.Position, true) + ' / ' + getUserDepartment(props.Data.Department, false) }}</span>
            </span>-->
        </div>
    </div>

    <div v-if="props.Data.Name" class="ps-1 mt-2 hand">
        <i :class="props.Data.Type === sysEnums.PageCategorys.Folder ? 'ri-folder-3-line' : 'ri-space'"></i><span class="ps-2">{{ props.Data.Name }}</span>
    </div>

    <!-- {{ JSON.stringify(props.Data) }} -->

    <div class="mt-1" @click="navigateTo(props.Data)">
        <simplebar class="p-0" data-simplebar style="height: 60px">
            <div class="fs-12 text-muted" v-if="props.Data.Description">{{ props.Data.Description }}</div>
        </simplebar>
    </div>

    <div class="text-muted d-flex justify-content-between mt-2">
        <span class="pt-1 bg-dark text-light text-center avatar-xs rounded-circle">{{ pageCount(props.Data.EncFolder) }}</span>
        <span>{{ $moment(props.Data.Created).format('YYYY.MM.DD hh:mm') }} 에 {{ props.Data.Type === sysEnums.PageCategorys.Folder ? '폴더' : '포스트' }} 만듦</span>
    </div>
</template>
