<template>
    <apexchart v-if="props.Target === enumTypes.ChartTypes.Company" class="apex-charts" height="350" dir="ltr" :series="basicLineChart.series"
        :options="basicLineChart.chartOptions">
    </apexchart>
    <apexchart v-if="props.Target === enumTypes.ChartTypes.Contract" class="apex-charts" height="350" dir="ltr" :series="zoomableChart.series"
        :options="zoomableChart.chartOptions">
    </apexchart>
    <apexchart v-if="props.Target === enumTypes.ChartTypes.File" class="apex-charts" height="350" dir="ltr" :series="lineDatalabelChart.series"
        :options="lineDatalabelChart.chartOptions"></apexchart>
    <apexchart v-if="props.Target === enumTypes.ChartTypes.PayStory" class="apex-charts" height="350" dir="ltr" :series="payStoryChart.series"
        :options="payStoryChart.chartOptions">
    </apexchart>
    <apexchart v-if="props.Target === enumTypes.ChartTypes.Settlement" class="apex-charts" height="350" dir="ltr" :series="lineDatalabelChart.series"
        :options="lineDatalabelChart.chartOptions">
    </apexchart>
</template>

<script lang="js">
import getChartColorsArray from "@/common/getChartColorsArray";

import { onMounted, inject, ref } from "vue";
import { sysEnums, enumTypes } from "@/common/sysEnums";
import { getHouseMetaFee, getLessorSent } from "@/common/comFuncs";


export default {
    props: {
        Target: Number,
    },
    setup(props) {
        const basicLineChart = ref({
            series: [
                {
                    name: "Company",
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                markers: {
                    size: 4,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },
                labels: [],
                colors: getChartColorsArray('["--vz-primary"]'),
                // title: {
                //     text: "Product Trends by Month",
                //     align: "left",
                //     style: {
                //         fontWeight: 500,
                //     },
                // },
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ],
                },
            },
        });

        // Zoomable Timeseries
        const zoomableChart = ref({
            series: [
                {
                    name: "XYZ MOTORS",
                    data: [
                        {
                            x: new Date("2018-02-06").getTime(),
                            y: 155,
                        },
                    ],
                },
            ],
            chartOptions: {
                chart: {
                    type: "area",
                    stacked: false,
                    height: 350,
                    zoom: {
                        type: "x",
                        enabled: true,
                        autoScaleYaxis: true,
                    },
                    toolbar: {
                        autoSelected: "zoom",
                    },
                },
                labels: [],
                colors: getChartColorsArray('["--vz-primary"]'),
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 0,
                },
                title: {
                    text: "Stock Price Movement",
                    align: "left",
                    style: {
                        fontWeight: 500,
                    },
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.5,
                        opacityTo: 0,
                        stops: [0, 90, 100],
                    },
                },
                yaxis: {
                    showAlways: true,
                    labels: {
                        show: true,
                        formatter: function (val) {
                            return (val / 1000000).toFixed(0);
                        },
                    },
                    title: {
                        text: "Price",
                        style: {
                            fontWeight: 500,
                        },
                    },
                },
                xaxis: {
                    type: "datetime",
                },
                tooltip: {
                    shared: false,
                    y: {
                        formatter: function (val) {
                            return (val / 1000000).toFixed(0);
                        },
                    },
                },
            },
        });

        //  Line chart datalabel
        const lineDatalabelChart = ref({
            series: [
                {
                    name: "High",
                    data: [],
                },
                {
                    name: "Low",
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    height: 380,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                labels: [],
                colors: getChartColorsArray('["--vz-primary", "--vz-info"]'),
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    width: [3, 3],
                    curve: "straight",
                },
                grid: {
                    row: {
                        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.2,
                    },
                    borderColor: "#f1f1f1",
                },
                markers: {
                    style: "inverted",
                    size: 6,
                },
                xaxis: {
                    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",],
                    title: {
                        text: "Month",
                    },
                },
                yaxis: {
                    title: {
                        text: "Temperature",
                    },
                    min: 5,
                    max: 40,
                },
                legend: {
                    position: "top",
                    horizontalAlign: "right",
                    floating: true,
                    offsetY: -25,
                    offsetX: -5,
                },
                responsive: [
                    {
                        breakpoint: 600,
                        options: {
                            chart: {
                                toolbar: {
                                    show: false,
                                },
                            },
                            legend: {
                                show: false,
                            },
                        },
                    },
                ],
            },
        });

        const payStoryChart = ref({
            series: [
                {
                    name: "수납금액",
                    type: 'column',
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
                {
                    name: "페이스토리",
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
                {
                    name: "임대인",
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                }
            ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                markers: {
                    size: 4,
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "smooth",
                },
                labels: [],
                xaxis: {
                    categories: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ],
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            val = Math.abs(val)
                            // if (val >= 1000) {
                            //     val = (val / 1000).toFixed(0)
                            // }
                            return val.toLocaleString()
                        }
                    },
                    // min: 4500,
                    // max: 5000
                },
                colors: getChartColorsArray('["--vz-primary", "--vz-primary-rgb, 0.1", "--vz-primary-rgb, 0.50"]'),
                tooltip: {
                    theme: "dark",
                    x: {
                        show: false,
                    },
                    shared: true,
                    y: [{
                        formatter: function (val) {
                            // if (typeof val !== "undefined") {
                            //     return (val).toFixed(0).toLocaleString();
                            // }
                            return val.toLocaleString();
                        },
                    },
                    {
                        formatter: function (val) {
                            return val.toLocaleString();
                        },
                    },
                    {
                        formatter: function (val) {
                            return val.toLocaleString();
                        },
                    },
                    ],
                },
            },
        });

        const files = inject("$files");
        const companys = inject("$companys");

        const moment = inject("$moment");

        const { contractList } = inject("$provideList");

        onMounted(() => {
            try {
                props.Target === enumTypes.ChartTypes.Company ? drawChartCompany() :
                    props.Target === enumTypes.ChartTypes.Sns ? drawChartCompany() :
                        props.Target === enumTypes.ChartTypes.File ? drawChartFile() :
                            props.Target === enumTypes.ChartTypes.PayStory ? drawChartPayStory() :
                                props.Target === enumTypes.ChartTypes.Settlement ? drawChartSettlement() :
                                    drawChartCompany();
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        });

        const fetchDataCompany = async () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const data = companys.value;
                    resolve(data);
                }, 600);
            });
        };

        const fetchDataFile = async () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const data = files.value;
                    resolve(data);
                }, 700);
            });
        };

        const fetchDataContract = async () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                    const data = contractList.value;
                    resolve(data);
                }, 900);
            });
        };

        const drawChartCompany = async () => {
            try {
                const datas = await fetchDataCompany();

                basicLineChart.value.series[0].data = [];
                basicLineChart.value.chartOptions.labels.pop();

                let obj = {};
                datas.forEach(item => {
                    const formattedDate = moment(item.Created).format('YYYY-MM-DD');
                    if (!obj[formattedDate]) {
                        obj[formattedDate] = [];
                    }
                    obj[formattedDate].push(item);
                })

                Object.keys(obj).forEach((key) => {
                    basicLineChart.value.series[0].data.push(obj[key].length);
                    basicLineChart.value.chartOptions.labels.push(key);
                });
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        };

        const drawChartFile = async () => {
            try {
                const datas = await fetchDataFile();

                lineDatalabelChart.value.series[0].data = [];
                lineDatalabelChart.value.chartOptions.labels.pop();

                let obj = {};
                datas.forEach(item => {
                    const formattedDate = moment(item.Created).format('YYYY-MM-DD');
                    if (!obj[formattedDate]) {
                        obj[formattedDate] = [];
                    }
                    obj[formattedDate].push(item);
                })

                Object.keys(obj).forEach((key) => {
                    lineDatalabelChart.value.series[0].data.push(obj[key].length);
                    lineDatalabelChart.value.chartOptions.labels.push(key);
                });
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        };

        const drawChartPayStory = async () => {
            try {
                const datas = await fetchDataContract();

                payStoryChart.value.series[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                payStoryChart.value.series[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                payStoryChart.value.series[2].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                payStoryChart.value.chartOptions.labels.pop();

                let obj = {};
                datas.forEach(item => {
                    const formattedDate = moment(item.StartDate).format('YYYY-MM');
                    if (!obj[formattedDate]) {
                        obj[formattedDate] = [];
                    }
                    obj[formattedDate].push(item);
                })

                Object.keys(obj).forEach((key) => {
                    const index = parseInt(moment(key).format('M')) - 1;

                    const dt1 = obj[key].reduce((acc, cur) => {
                        return acc + (cur.Rent + cur.ManagementFee);
                    }, 0);

                    const dt2 = obj[key].reduce((acc, cur) => {
                        const cardFee = cur.PayMid ? (cur.PayMid.CardFee || sysEnums.cardFee) : sysEnums.cardFee;
                        return acc + getHouseMetaFee(cur.Rent, cur.ManagementFee, cur.FeeDiscountRate, cardFee);
                    }, 0);

                    const dt3 = obj[key].reduce((acc, cur) => {
                        const cardFee = cur.PayMid ? (cur.PayMid.CardFee || sysEnums.cardFee) : sysEnums.cardFee;
                        return acc + getLessorSent(cur.Rent, cur.ManagementFee, cur.FeeDiscountRate, cardFee);
                    }, 0);

                    payStoryChart.value.series[0].data[index] = dt1;
                    payStoryChart.value.series[1].data[index] = dt2;
                    payStoryChart.value.series[2].data[index] = dt3;

                    payStoryChart.value.chartOptions.labels.push(key);
                });
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        };

        const drawChartSettlement = async () => {
            try {
                const datas = await fetchDataContract();

                lineDatalabelChart.value.series[0].data = [];
                lineDatalabelChart.value.chartOptions.labels.pop();

                let obj = {};
                datas.forEach(item => {
                    const formattedDate = moment(item.StartDate).format('YYYY-MM-DD');
                    if (!obj[formattedDate]) {
                        obj[formattedDate] = [];
                    }
                    obj[formattedDate].push(item);
                })

                Object.keys(obj).forEach((key) => {
                    const dt = parseInt((obj[key][0].Rent + obj[key][0].ManagementFee) * sysEnums.DiscoutTypes.Tax);
                    lineDatalabelChart.value.series[0].data.push(dt);
                    lineDatalabelChart.value.chartOptions.labels.push(key);
                });
            } catch (error) {
                console.error('Error during chart drawing:', error);
            }
        };

        return {
            props, enumTypes,
            basicLineChart, zoomableChart, lineDatalabelChart,
            payStoryChart
        };
    },
};
</script>

<style scoped lang="scss"></style>
