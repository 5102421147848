<script>
import { dayLeftPercent, daysLeft } from '@/common/comFuncs';
import { enumTypes, sysEnums } from '@/common/sysEnums';
import { inject, ref } from 'vue';

import EditorTop from '@/components/pages/EditorTop';
import PageComment from '@/components/pages/PageComment';
import PageEditor from '@/components/pages/PageEditor';
import PageFileSimple from '@/components/pages/PageFileSimple';
import PageSetUser from '@/components/pages/PageSetUser';

import ChatGroup from '@/components/notification/ChatGroup';

import simplebar from 'simplebar-vue';
// import UserComp from "@/components/users/UserComp";
// import CompanyMap from "@/components/companys/CompanyMap";

export default {
    setup() {
        const targetPage = ref('');
        const editorRef = ref(null);

        const selectedColor = ref(1);
        const selectedPen = ref(2);

        const widgetInfo = inject('$widgetInfo');
        const pages = inject('$pages');

        const { userList, pageList, treeData, selectedPage, gotoPage, gotoModal, selectedClips, getChatGroup } = inject('$provideList');

        const tableIcons = [
            '',
            'ri-layout-row-line text-info',
            'ri-arrow-up-s-line',
            'ri-arrow-down-s-line',
            'ri-subtract-line',
            'ri-layout-column-line text-info',
            'ri-arrow-left-s-line',
            'ri-arrow-right-s-line',
            'ri-subtract-line',
            'mdi mdi-table-merge-cells text-info',
            'mdi mdi-arrow-expand-horizontal',
            'mdi mdi-align-vertical-distribute',
            'ri-arrow-left-right-line',
            'mdi mdi-arrow-left-bold',
            'mdi mdi-arrow-right-bold',
            'ri-arrow-go-back-line',
            ' ri-arrow-go-forward-line',
        ];

        const tableTxts = [
            '',
            '<br>행',
            '<br>행추가',
            '<br>행추가',
            '<br>행삭제',
            '<br>열',
            '<br>열추가',
            '<br>열추가',
            '<br>열삭제',
            '<br>셀',
            '<br>셀병합',
            '<br>셀분할',
            '<br>셀전환',
            '<br>이전셀',
            '<br>다음샐',
            '<br>Undo',
            '<br>Redo',
        ];

        const drawColors = ['', '#A975FF', '#FB5151', '#FD9170', '#FFCB6B', '#68CEF8', '#80CBC4', '#9DEF8F', '#666666'];

        const getClasses = (x, type) => {
            const classes = [];

            if (type === sysEnums.ClipTypes.Table) {
                if (x < 5) {
                    classes.push('pb-3', 'border-bottom');
                } else if (x > 12) {
                    // 16 - 4 = 12
                    classes.push('pt-3');
                } else {
                    classes.push('py-3', 'border-bottom');
                }
                if (x % 4 !== 0) {
                    classes.push('border-end');
                }
            }

            return classes;
        };

        const selectDrawTool = (pen, idx) => {
            if (pen) {
                editorRef.value.editorFunc(60 + idx);
                selectedPen.value = idx;
                widgetInfo.value.penSize = idx;
            } else {
                editorRef.value.editorFunc(50 + idx);
                selectedColor.value = idx;
                widgetInfo.value.penColor = drawColors[idx];
            }
        };

        const viewChatGroup = () => {
            getChatGroup();
            widgetInfo.value.canvasChat = !widgetInfo.value.canvasChat;
        };

        return {
            sysEnums,
            enumTypes,
            tableIcons,
            tableTxts,
            drawColors,
            selectedClips,
            targetPage,
            widgetInfo,
            selectedPage,
            editorRef,
            userList,
            treeData,
            pages,
            pageList,
            selectedColor,
            selectedPen,
            dayLeftPercent,
            daysLeft,
            gotoPage,
            gotoModal,
            getClasses,
            viewChatGroup,
            selectDrawTool,
        };
    },
    components: {
        simplebar,
        EditorTop,
        PageEditor,
        PageSetUser,
        PageFileSimple,
        PageComment,
        ChatGroup,
    },
};
</script>

<template>
    <BRow>
        <BCol lg="12">
            <BCard no-body class="mt-n4 mx-n4 mb-n5 border-0">
                <EditorTop></EditorTop>
            </BCard>
        </BCol>
    </BRow>

    <BRow>
        <BCol xxl="9">
            <simplebar data-simplebar style="height: 874px; overflow-x: hidden !important">
                <PageEditor ref="editorRef"></PageEditor>
            </simplebar>

            <PageComment></PageComment>

            <!-- <BCard no-body>
                <BCardBody class="p-4">
                    <h6 class="fw-semibold text-uppercase mb-3">Selected Page Description</h6>
                    <p class="text-muted">
                        {{ JSON.stringify(selectedPage) }}
                    </p>
                </BCardBody>
            </BCard> -->
        </BCol>

        <BCol xxl="3">
            <BCard no-body v-if="widgetInfo.selectedClip.Type === sysEnums.ClipTypes.Table && !widgetInfo.clipMenu" class="floating-top-right">
                <BCardHeader class="align-items-center d-flex border-bottom-dashed">
                    <BCardTitle class="mb-0 flex-grow-1 text-uppercase">Table 메뉴</BCardTitle>
                    <i class="ri-arrow-left-down-line" @click="widgetInfo.clipMenu = !widgetInfo.clipMenu"></i>
                </BCardHeader>

                <BCardBody>
                    <BRow class="text-center hand">
                        <BCol v-for="x in 16" :key="x" lg="3" :class="getClasses(x, sysEnums.ClipTypes.Table)" @click="editorRef.editorFunc(30 + x)">
                            <button>
                                <i v-show="tableIcons[x] !== ''" :class="tableIcons[x]"></i>
                            </button>
                            <span v-show="tableTxts[x] !== ''" v-html="tableTxts[x]"></span>
                        </BCol>
                    </BRow>
                </BCardBody>
            </BCard>

            <BCard no-body v-if="widgetInfo.selectedClip.Type === sysEnums.ClipTypes.Draw && !widgetInfo.clipMenu" class="floating-top-right">
                <BCardHeader class="align-items-center d-flex border-bottom-dashed">
                    <BCardTitle class="mb-0 flex-grow-1 text-uppercase">Draw 메뉴</BCardTitle>
                    <i class="ri-arrow-left-down-line" @click="widgetInfo.clipMenu = !widgetInfo.clipMenu"></i>
                </BCardHeader>

                <BCardBody>
                    <BRow class="text-center hand">
                        <BCol v-for="x in 8" :key="x" lg="3" @click="selectDrawTool(false, x)">
                            <div><i v-show="selectedColor === x" class="ri-check-line"></i></div>
                            <button class="p-4 mb-2 br-14-s" :style="{ 'background-color': drawColors[x] }"></button>
                        </BCol>

                        <hr class="text-muted my-4" />

                        <BCol v-for="x in 5" :key="x" lg="2" @click="selectDrawTool(true, x)">
                            <div><i v-show="selectedPen === x" class="ri-check-line"></i></div>
                            <button class="bg-dark mb-2 br-8" :class="'p-' + x"></button>
                        </BCol>

                        <hr class="text-muted my-4" />

                        <BCol lg="6" @click="editorRef.editorFunc(68)">
                            <i class="ri-eraser-line fs-30"></i>
                        </BCol>
                        <BCol lg="6" @click="editorRef.editorFunc(69)">
                            <i class="fs-30" :class="widgetInfo.selectedClip.Hided ? 'ri-eye-line text-muted' : 'ri-eye-off-line text-danger'"></i>
                        </BCol>
                    </BRow>
                </BCardBody>
            </BCard>

            <BCard no-body>
                <BCardHeader class="align-items-center d-flex border-bottom-dashed">
                    <BCardTitle class="mb-0 flex-grow-1 text-uppercase">Clip 만들기</BCardTitle>
                </BCardHeader>

                <BCardBody class="text-center pb-0">
                    <BRow>
                        <BCol lg="3">
                            <BCard no-body class="p-2">
                                <i class="ri-image-fill fs-30" @click="gotoModal(selectedPage, 'FileUpload', '선택 파일 업로드')"></i>
                                <!-- <i class="ri-image-fill fs-30" @click="editorRef.selectImage"></i> -->
                            </BCard>
                        </BCol>

                        <BCol lg="3">
                            <BCard no-body class="p-2">
                                <i class="ri-layout-grid-line fs-30" @click="editorRef.createClip(sysEnums.ClipTypes.Table)"></i>
                            </BCard>
                        </BCol>

                        <BCol lg="3">
                            <BCard no-body class="p-2">
                                <i class="ri-font-size fs-30" @click="editorRef.createClip(sysEnums.ClipTypes.Default)"></i>
                            </BCard>
                        </BCol>

                        <BCol lg="3">
                            <BCard no-body class="p-2">
                                <i class="ri-quill-pen-line fs-30" @click="editorRef.createClip(sysEnums.ClipTypes.Draw)"></i>
                            </BCard>
                        </BCol>
                    </BRow>
                </BCardBody>
            </BCard>

            <BCard no-body>
                <BCardHeader class="align-items-center d-flex border-bottom-dashed">
                    <BCardTitle class="mb-0 flex-grow-1 hand"
                        >Members
                        <BBadge v-if="selectedPage.PageRoles" variant="danger" pill>{{ selectedPage.PageRoles.length }}</BBadge>
                        <i
                            :class="widgetInfo.selectedUser && widgetInfo.selectedUser.EncUser ? 'ri-chat-3-fill text-primary' : 'ri-chat-3-line'"
                            class="ms-2"
                            @click="viewChatGroup"
                        ></i>
                    </BCardTitle>
                    <div class="flex-shrink-0">
                        <BButton type="button" variant="soft-primary" size="sm" @click="gotoModal(selectedPage, 'SetUserRole', '페이지 열람대상 지정하기')"
                            ><i class="ri-share-line me-1 align-bottom"></i> 초대하기</BButton
                        >
                    </div>
                </BCardHeader>

                <BCardBody>
                    <!-- <simplebar data-simplebar style="height: 560px" class="mx-n3 px-3">
                        <div class="vstack gap-3"> -->
                    <PageSetUser></PageSetUser>
                    <!-- </div>
                    </simplebar> -->
                </BCardBody>
            </BCard>

            <PageFileSimple></PageFileSimple>

            <BCard no-body v-if="widgetInfo.selectedClip.Type === sysEnums.ClipTypes.Table && widgetInfo.clipMenu" class="fixed-bottom">
                <BCardBody>
                    <div class="d-flex align-items-center text-center hand">
                        <BCard v-for="x in 16" :key="x" @click="editorRef.editorFunc(30 + x)" class="m-0">
                            <i v-show="tableIcons[x] !== ''" :class="tableIcons[x]"></i>
                            <span v-show="tableTxts[x] !== ''" v-html="tableTxts[x]"></span>
                        </BCard>

                        <span class="flex-fill"></span>
                        <span class="pe-4"><i class="ri-arrow-up-line" @click="widgetInfo.clipMenu = !widgetInfo.clipMenu"></i></span>
                    </div>
                </BCardBody>
            </BCard>

            <BCard no-body v-if="widgetInfo.selectedClip.Type === sysEnums.ClipTypes.Draw && widgetInfo.clipMenu" class="fixed-bottom">
                <BCardBody>
                    <div class="d-flex align-items-center text-center hand">
                        <BCard v-for="x in 8" :key="x" lg="3" @click="selectDrawTool(false, x)" class="m-0">
                            <button class="p-4 mb-2 br-14-s" :style="{ 'background-color': drawColors[x] }">
                                <i v-show="selectedColor === x" class="ri-check-line text-light"></i>
                            </button>
                        </BCard>

                        <i class="ri-more-2-line mx-2"></i>

                        <BCard v-for="x in 5" :key="x" lg="2" @click="selectDrawTool(true, x)" class="m-0">
                            <button class="bg-dark mb-2 br-8" :class="'p-' + x">
                                <i v-show="selectedPen === x" class="ri-check-line text-light"></i>
                            </button>
                        </BCard>

                        <i class="ri-more-2-line mx-2"></i>

                        <BCard lg="6" @click="editorRef.editorFunc(68)" class="m-0">
                            <i class="ri-eraser-line fs-30"></i>
                        </BCard>
                        <BCard lg="6" @click="editorRef.editorFunc(69)" class="m-0">
                            <i class="fs-30" :class="widgetInfo.selectedClip.Hided ? 'ri-eye-line text-muted' : 'ri-eye-off-line text-danger'"></i>
                        </BCard>

                        <span class="flex-fill"></span>
                        <span class="pe-4"><i class="ri-arrow-up-line" @click="widgetInfo.clipMenu = !widgetInfo.clipMenu"></i></span>
                    </div>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>

    <!-- chat offcanvas -->
    <BOffcanvas class="w-75" body-class="border-0 p-0 overflow-x-hidden" v-model="widgetInfo.canvasChat" placement="start" title="Chat">
        <ChatGroup></ChatGroup>
    </BOffcanvas>
</template>

<style>
.floating-top-right {
    position: sticky;
    top: 10px;
    right: 10px;
    z-index: 1000;
    box-shadow: #333 2px 2px 5px;
    background-color: #fff;
}

.floating-top-right-fixed {
    width: 23.3%;
    position: fixed;
    top: 200px;
    right: 40px;
    z-index: 1000;
}
</style>
