<template>
    <BRow v-if="isTable">
        <BCol lg="12">
            <BCard no-body>
                <BCardBody>
                    <table class="table table-striped table-hover text-center">
                        <thead>
                            <tr class="h-50h bg-light align-middle fw-bold">
                                <th scope="col">번호</th>
                                <th v-for="x in titles" :key="x">{{ x }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="h-50h align-middle hand" v-for="(x, i) in userList" :key="i">
                                <th class="w-5" scope="row">{{ i + 1 }}</th>
                                <td>
                                    <img
                                        :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'"
                                        class="avatar-sm rounded-circle"
                                        :class="x.Status ? '' : 'opacity-25'"
                                        @click="fileRef.setFileData(x)"
                                    />
                                </td>
                                <td class="w-10" v-text="x.UserName"></td>
                                <td class="w-10" v-text="x.LoginId"></td>
                                <td class="w-10" v-text="enumTypes.UserTypes[x.UserType]"></td>
                                <td class="w-20">
                                    <span class="pe-4 fs-20">
                                        <i
                                            :class="
                                                x.Status && x.UserType === sysEnums.UserTypes.Agency
                                                    ? 'bi-person-fill text-danger'
                                                    : x.UserType === sysEnums.UserTypes.Agency
                                                    ? 'bi-person fs-14'
                                                    : x.Status && $moment(x.Checked).format('YY') !== '00' && x.UserType === sysEnums.UserTypes.Partner
                                                    ? 'bi-person-fill-gear text-primary'
                                                    : x.Status && $moment(x.Checked).format('YY') === '00' && x.UserType === sysEnums.UserTypes.Partner
                                                    ? 'bi-person-check-fill text-primary'
                                                    : x.UserType === sysEnums.UserTypes.Partner
                                                    ? 'bi-person-check fs-14'
                                                    : x.Status
                                                    ? 'bi-person-fill'
                                                    : 'bi-person fs-14'
                                            "
                                            @click="updateUser(x.EncUser, x.LoginId)"
                                        ></i>
                                    </span>
                                </td>
                                <td class="w-10" v-if="!$store.state.isMobile" v-text="x.Status === true ? $moment(x.Updated).format('YY.MM.DD') : '-'"></td>
                                <td class="w-10" v-if="!$store.state.isMobile" v-text="$moment(x.Created).format('YY.MM.DD')"></td>
                                <td class="w-10">
                                    <div class="form-switch">
                                        <input
                                            class="form-check-input hand"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckDefault"
                                            :checked="x.Status && x.UserType > 0"
                                            @click="updateUser(x.EncUser, x.LoginId)"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </BCardBody>
            </BCard>
        </BCol>
    </BRow>

    <BRow v-else>
        <template v-for="x in userList" :key="x">
            <BCol xxl="3" sm="6">
                <BCard no-body class="profile-project-card shadow-none">
                    <BCardBody class="d-flex">
                        <div class="w-25">
                            <img
                                :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'"
                                alt=""
                                class="avatar-md img-fit"
                                :class="x.Status ? '' : 'opacity-25'"
                                @click="fileRef.setFileData(x)"
                            />
                        </div>

                        <div class="d-flex w-75">
                            <UserUpdate :Data="x"></UserUpdate>
                        </div>
                    </BCardBody>
                </BCard>
            </BCol>
        </template>
    </BRow>

    <span class="d-none">
        <FileUploadSingle ref="fileRef" :FileType="sysEnums.FileTypes.Avatar" @SingleFileUploaded="getUser"></FileUploadSingle>
    </span>
</template>

<script lang="js">
import { ref, inject } from 'vue';
import { apiEnums, sysEnums, enumTypes } from "@/common/sysEnums";
// import { getUserTypeList } from "@/common/comFuncs";

import UserUpdate from "@/components/users/UserUpdate";
import FileUploadSingle from "@/components/files/FileUploadSingle";

export default {
    components: {
        UserUpdate,
        FileUploadSingle,
    },
    props: {
        // isTable: { type: Boolean, default: false },
    },
    setup(props) {
        const titles = ref(['', '회원명', '아이디', '타입', '권한수정', '수정일', '등록일', '상태']);

        const fileRef = ref(null);

        const store = inject("$store");
        const axios = inject("$axios");

        const { userList, getUser } = inject("$provideList");

        const updateUser = (encUser, loginId) => {
            const formData = new FormData();
            formData.append('EncUser', encUser);
            formData.append('Status', 1);
            axios.post(apiEnums.CloudTicket.updateUser, formData)
                .then(async (response) => {
                    if (response.data === '1000') {
                        localStorage.removeItem(sysEnums.UserKey);
                        getUser();

                        try {
                            await store.state.connection.invoke('SendUserReset', loginId, enumTypes.SignalTypes.UserUpdated);
                        } catch (error) {
                            console.error('Error invoking SendUserReset : ', error);
                        }
                    }
                })
                .catch(() => { });
        };

        return {
            sysEnums, enumTypes, titles,
            userList, getUser, fileRef, props,
            updateUser,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable='true'] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
