<script>
import { apiEnums, sysEnums } from '@/common/sysEnums';
import TreeMenu from '@/components/tree-menu.vue';
import { inject } from 'vue';

import { layoutComputed } from '@/state/helpers';

export default {
    components: {
        TreeMenu,
    },
    setup() {
        const axios = inject('$axios');
        const widgetInfo = inject('$widgetInfo');

        const { pageList, treeData, routeTo, getUserFolder, getUserPage, gotoModal } = inject('$provideList');

        // watch(
        //     () => [...folderList.value],
        //     () => {
        //         buildTree();
        //         // console.log('treeData folderList : ' + JSON.stringify(pageList.value, null, 2));
        //     }
        // );

        // watch(
        //     () => [pageList.value],
        //     () => {
        //         buildTree();
        //         console.log('treeData pageList : ' + JSON.stringify(treeData.value, null, 2));
        //     }
        // );

        const countItemsWithTag = tag => {
            if (pageList.value.length > 0) {
                const count = pageList.value.reduce((acc, item) => {
                    if (item.Tag > 0 && item.Tag === tag) {
                        acc += 1;
                    }
                    return acc;
                }, 0); // Initialize the accumulator to 0
                return count;
            }
            return 0;
        };

        const countItemsInTrash = () => {
            if (pageList.value.length > 0) {
                const count = pageList.value.reduce((acc, item) => {
                    if (item.Trash) {
                        acc += 1;
                    }
                    return acc;
                }, 0); // Initialize the accumulator to 0
                return count;
            }
            return '';
        };

        const handleDropFolderMove = event => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            const encRole = droppedData.PageRoles.find(x => x.EncUser === droppedData.EncUser).EncRole;

            const formData = new FormData();
            formData.append('EncFolder', sysEnums.zero);
            formData.append('EncPage', droppedData.EncPage);
            formData.append('EncUser', droppedData.EncUser);
            if (encRole) {
                formData.append('EncRole', encRole);
            }
            axios
                .post(apiEnums.CloudTicket.createRole, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        getUserPage();
                    }
                })
                .catch(() => {});

            // console.log(encFolder + ' / ' + droppedData.EncFolder + ' / ' + JSON.stringify(droppedData));
        };

        const handleDropTrashMove = event => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            const formData = new FormData();
            formData.append('EncPage', droppedData.EncPage);
            formData.append('Trash', 1);
            axios
                .post(apiEnums.CloudTicket.updatePage, formData)
                .then(response => {
                    if (response.data !== '0000') {
                        getUserFolder();
                        getUserPage();
                    }
                })
                .catch(() => {});

            // console.log(encFolder + ' / ' + droppedData.EncFolder + ' / ' + JSON.stringify(droppedData));
        };

        return {
            sysEnums,
            widgetInfo,
            treeData,
            gotoModal,
            routeTo,
            countItemsWithTag,
            countItemsInTrash,
            handleDropFolderMove,
            handleDropTrashMove,
        };
    },
    data() {
        return {
            settings: {
                minScrollbarLength: 60,
            },
            routeFrom: 0,
        };
    },
    computed: {
        ...layoutComputed,
        layoutType: {
            get() {
                return this.$store ? this.$store.state.layout.layoutType : {} || {};
            },
        },
    },

    watch: {
        $route: {
            handler: 'onRoutechange',
            immediate: true,
            deep: true,
        },
    },

    mounted() {
        this.initActiveMenu();
        if (this.rmenu == 'vertical' && this.layoutType == 'twocolumn') {
            document.documentElement.setAttribute('data-layout', 'vertical');
        }

        // document.getElementById('overlay').addEventListener('click', () => {
        //     document.body.classList.remove('vertical-sidebar-enable');
        // });

        // window.addEventListener("resize", () => {
        //     if (this.layoutType == 'twocolumn') {
        //         var windowSize = document.documentElement.clientWidth;
        //         if (windowSize < 767) {
        //             document.documentElement.setAttribute("data-layout", "vertical");
        //             this.rmenu = 'vertical';
        //             localStorage.setItem('rmenu', 'vertical');
        //         } else {
        //             document.documentElement.setAttribute("data-layout", "vertical");
        //             this.rmenu = 'twocolumn';
        //             localStorage.setItem('rmenu', 'twocolumn');
        //             setTimeout(() => {
        //                 this.initActiveMenu();
        //             }, 50);

        //         }
        //     }
        // });

        if (document.querySelectorAll('.navbar-nav .collapse')) {
            // let collapses = document.querySelectorAll(".navbar-nav .collapse");
            //     collapses.forEach((collapse) => {
            //         // Hide sibling collapses on `show.bs.collapse`
            //         collapse.addEventListener("show.bs.collapse", (e) => {
            //             e.stopPropagation();
            //             let closestCollapse = collapse.parentElement.closest(".collapse");
            //             if (closestCollapse) {
            //                 let siblingCollapses =
            //                     closestCollapse.querySelectorAll(".collapse");
            //                 siblingCollapses.forEach((siblingCollapse) => {
            //                     if (siblingCollapse.classList.contains("show")) {
            //                         siblingCollapse.classList.remove("show");
            //                         siblingCollapse.parentElement.firstChild.setAttribute("aria-expanded", "false");
            //                     }
            //                 });
            //             } else {
            //                 let getSiblings = (elem) => {
            //                     // Setup siblings array and get the first sibling
            //                     let siblings = [];
            //                     let sibling = elem.parentNode.firstChild;
            //                     // Loop through each sibling and push to the array
            //                     while (sibling) {
            //                         if (sibling.nodeType === 1 && sibling !== elem) {
            //                             siblings.push(sibling);
            //                         }
            //                         sibling = sibling.nextSibling;
            //                     }
            //                     return siblings;
            //                 };
            //                 let siblings = getSiblings(collapse.parentElement);
            //                 siblings.forEach((item) => {
            //                     if (item.childNodes.length > 2) {
            //                         item.firstElementChild.setAttribute("aria-expanded", "false");
            //                         item.firstElementChild.classList.remove("active");
            //                     }
            //                     let ids = item.querySelectorAll("*[id]");
            //                     ids.forEach((item1) => {
            //                         item1.classList.remove("show");
            //                         item1.parentElement.firstChild.setAttribute("aria-expanded", "false");
            //                         item1.parentElement.firstChild.classList.remove("active");
            //                         if (item1.childNodes.length > 2) {
            //                             let val = item1.querySelectorAll("ul li a");
            //                             val.forEach((subitem) => {
            //                                 if (subitem.hasAttribute("aria-expanded"))
            //                                     subitem.setAttribute("aria-expanded", "false");
            //                             });
            //                         }
            //                     });
            //                 });
            //             }
            //         });
            //         // Hide nested collapses on `hide.bs.collapse`
            //         collapse.addEventListener("hide.bs.collapse", (e) => {
            //             e.stopPropagation();
            //             let childCollapses = collapse.querySelectorAll(".collapse");
            //             childCollapses.forEach((childCollapse) => {
            //                 let childCollapseInstance = childCollapse;
            //                 childCollapseInstance.classList.remove("show");
            //                 childCollapseInstance.parentElement.firstChild.setAttribute("aria-expanded", "false");
            //             });
            //         });
            //     });
        }
    },

    methods: {
        onRoutechange(ele) {
            this.initActiveMenu(ele.path);
            if (document.getElementsByClassName('mm-active').length > 0) {
                const currentPosition = document.getElementsByClassName('mm-active')[0].offsetTop;
                if (currentPosition > 500) if (this.$refs.isSimplebar) this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
            }
        },

        initActiveMenu() {
            const pathName = window.location.pathname;
            const ul = document.getElementById('navbar-nav');
            if (ul) {
                const items = Array.from(ul.querySelectorAll('a.nav-link'));
                let activeItems = items.filter(x => x.classList.contains('active'));
                this.removeActivation(activeItems);
                let matchingMenuItem = items.find(x => {
                    return x.getAttribute('href') === pathName;
                });
                if (matchingMenuItem) {
                    this.activateParentDropdown(matchingMenuItem);
                } else {
                    var id = pathName.replace('/', '');
                    if (id) document.body.classList.add('twocolumn-panel');
                    this.activateIconSidebarActive(pathName);
                }
            }
        },

        updateMenu(e, event) {
            document.body.classList.remove('twocolumn-panel');
            const ul = document.getElementById('navbar-nav');
            if (ul) {
                const items = Array.from(ul.querySelectorAll('.show'));
                items.forEach(item => {
                    item.classList.remove('show');
                });
            }
            const icons = document.getElementById('two-column-menu');
            if (icons) {
                const activeIcons = Array.from(icons.querySelectorAll('.nav-icon.active'));
                activeIcons.forEach(item => {
                    item.classList.remove('active');
                });
            }
            document.getElementById(e).classList.add('show');
            event.target.classList.add('active');
            this.activateIconSidebarActive('#' + e);
        },

        removeActivation(items) {
            items.forEach(item => {
                if (item.classList.contains('menu-link')) {
                    if (!item.classList.contains('active')) {
                        item.setAttribute('aria-expanded', false);
                    }
                    item.nextElementSibling.classList.remove('show');
                }
                if (item.classList.contains('nav-link')) {
                    if (item.nextElementSibling) {
                        item.nextElementSibling.classList.remove('show');
                    }
                    item.setAttribute('aria-expanded', false);
                }
                item.classList.remove('active');
            });
        },

        activateParentDropdown(item) {
            // navbar-nav menu add active
            item.classList.add('active');

            let parentCollapseDiv = item.closest('.collapse.menu-dropdown');

            if (parentCollapseDiv) {
                // Ensure other menu-dropdowns are closed
                document.querySelectorAll('.collapse.menu-dropdown').forEach(menuDropdown => {
                    if (menuDropdown !== parentCollapseDiv) {
                        menuDropdown.classList.remove('show');
                    }
                });

                // Open the current menu-dropdown
                parentCollapseDiv.classList.add('show');
                parentCollapseDiv.parentElement.children[0].classList.add('active');
                parentCollapseDiv.parentElement.children[0].setAttribute('aria-expanded', 'true');

                if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
                    if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown').previousElementSibling) {
                        if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown').previousElementSibling.parentElement.closest('.collapse.menu-dropdown')) {
                            const grandparent = parentCollapseDiv.parentElement
                                .closest('.collapse.menu-dropdown')
                                .previousElementSibling.parentElement.closest('.collapse.menu-dropdown');

                            this.activateIconSidebarActive('#' + grandparent.getAttribute('id'));

                            grandparent.classList.add('show');
                        }
                    }

                    this.activateIconSidebarActive('#' + parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown').getAttribute('id'));

                    parentCollapseDiv.parentElement.closest('.collapse').classList.add('show');

                    if (parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling)
                        parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling.classList.add('active');

                    return false;
                }

                this.activateIconSidebarActive('#' + parentCollapseDiv.getAttribute('id'));
                return false;
            }

            return false;
        },

        // activateParentDropdown(item) {
        //     // navbar-nav menu add active
        //     item.classList.add('active');
        //     let parentCollapseDiv = item.closest('.collapse.menu-dropdown');
        //     if (parentCollapseDiv) {
        //         // to set aria expand true remaining
        //         parentCollapseDiv.classList.add('show');
        //         parentCollapseDiv.parentElement.children[0].classList.add('active');
        //         parentCollapseDiv.parentElement.children[0].setAttribute('aria-expanded', 'true');
        //         if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
        //             if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown').previousElementSibling) {
        //                 if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown').previousElementSibling.parentElement.closest('.collapse.menu-dropdown')) {
        //                     const grandparent = parentCollapseDiv.parentElement
        //                         .closest('.collapse.menu-dropdown')
        //                         .previousElementSibling.parentElement.closest('.collapse.menu-dropdown');
        //                     this.activateIconSidebarActive('#' + grandparent.getAttribute('id'));
        //                     grandparent.classList.add('show');
        //                 }
        //             }
        //             this.activateIconSidebarActive('#' + parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown').getAttribute('id'));

        //             parentCollapseDiv.parentElement.closest('.collapse').classList.add('show');
        //             if (parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling)
        //                 parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling.classList.add('active');
        //             return false;
        //         }
        //         this.activateIconSidebarActive('#' + parentCollapseDiv.getAttribute('id'));
        //         return false;
        //     }
        //     return false;
        // },

        activateIconSidebarActive(id) {
            var menu = document.querySelector("#two-column-menu .simplebar-content-wrapper a[href='" + id + "'].nav-icon");
            if (menu !== null) {
                menu.classList.add('active');
            }
        },

        // routeTo(target, seq, pos) {
        //     this.$store.dispatch("AC_ROUTE", { to: target, from: this.routeFrom, seqno: seq, savedPosition: pos });
        //     this.routeFrom = target;
        // },

        navigateTo(target) {
            this.$router.push(target);
        },
    },
};
</script>

<template>
    <BContainer fluid>
        <div id="two-column-menu"></div>

        <template v-if="layoutType === 'vertical' || layoutType === 'semibox'">
            <ul class="navbar-nav h-100 hand" id="navbar-nav">
                <li class="menu-title">
                    <span data-key="t-menu">{{ $t('t-menu') }}</span>
                    <span data-key="t-menu"></span>
                </li>

                <div class="collapse menu-dropdown show" id="sidebarDashboards">
                    <li class="nav-item">
                        <span @click="navigateTo('/company')" class="nav-link" data-key="t-company"> <i class="ri-building-2-line pe-2"></i>{{ $t('t-company') }} </span>
                    </li>
                    <li class="nav-item">
                        <span @click="routeTo('/page', sysEnums.PageTags.User)" class="nav-link" data-key="t-user"> <i class="ri-group-line pe-2"></i>{{ $t('t-user') }} </span>
                    </li>
                    <li class="nav-item">
                        <span
                            @click="routeTo('/page', sysEnums.PageTags.Noti)"
                            class="nav-link"
                            :class="widgetInfo.tag === sysEnums.PageTags.Noti ? 'bg-secondary text-light' : ''"
                            data-key="t-notification"
                        >
                            <i class="ri-mail-send-line pe-2"></i>{{ $t('t-notification') }}
                            <BBadge class="ms-2" variant="secondary">{{ countItemsWithTag(sysEnums.PageTags.Noti) }}</BBadge>
                        </span>
                    </li>
                    <li class="nav-item">
                        <span
                            @click="routeTo('/page', sysEnums.PageTags.Decision)"
                            class="nav-link"
                            :class="widgetInfo.tag === sysEnums.PageTags.Decision ? 'bg-secondary text-light' : ''"
                            data-key="t-decision"
                        >
                            <i class="ri-quill-pen-line pe-2"></i>{{ $t('t-decision') }}
                            <BBadge class="ms-2" variant="secondary">{{ countItemsWithTag(sysEnums.PageTags.Decision) }}</BBadge>
                        </span>
                    </li>
                    <li class="nav-item">
                        <span
                            @click="routeTo('/page', sysEnums.PageTags.Cooperation)"
                            class="nav-link"
                            :class="widgetInfo.tag === sysEnums.PageTags.Cooperation ? 'bg-secondary text-light' : ''"
                            data-key="t-cooperation"
                        >
                            <i class="ri-task-line pe-2"></i>{{ $t('t-cooperation') }}
                            <BBadge class="ms-2" variant="secondary">{{ countItemsWithTag(sysEnums.PageTags.Cooperation) }}</BBadge>
                        </span>
                    </li>
                    <li class="nav-item">
                        <span
                            @click="routeTo('/page', sysEnums.PageTags.Safety)"
                            class="nav-link"
                            :class="widgetInfo.tag === sysEnums.PageTags.Safety ? 'bg-secondary text-light' : ''"
                            data-key="t-safety"
                        >
                            <i class="ri-spam-line pe-2"></i>{{ $t('t-safety') }}
                            <BBadge class="ms-2" variant="secondary">{{ countItemsWithTag(sysEnums.PageTags.Safety) }}</BBadge>
                        </span>
                    </li>
                    <li class="nav-item">
                        <span @click="routeTo('/page', sysEnums.PageTags.File)" class="nav-link" data-key="t-file"> <i class="ri-attachment-2 pe-2"></i>{{ $t('t-file') }} </span>
                    </li>
                    <li class="nav-item">
                        <span @click="navigateTo('/company')" class="nav-link" data-key="t-chat"> <i class="ri-message-2-line pe-2"></i>{{ $t('t-chat') }} </span>
                    </li>
                    <li class="nav-item">
                        <span @click="navigateTo('/company')" class="nav-link" data-key="t-video"> <i class="ri-live-line pe-2"></i>{{ $t('t-video') }} </span>
                    </li>
                    <li class="nav-item">
                        <span @click="navigateTo('/company')" class="nav-link" data-key="t-storage"> <i class="ri-briefcase-line pe-2"></i>{{ $t('t-storage') }} </span>
                    </li>
                    <li class="nav-item" @dragover.prevent @drop="handleDropTrashMove($event)">
                        <span @click="gotoModal(null, 'PageTrash', '휴지통')" class="nav-link" data-key="t-trash">
                            <i class="ri-delete-bin-line pe-2"></i>{{ $t('t-trash') }}
                            <BBadge class="ms-2" variant="danger">{{ countItemsInTrash() }}</BBadge>
                        </span>
                    </li>

                    <li class="menu-title mt-4 mb-3" @dragover.prevent @drop="handleDropFolderMove($event)">
                        <span data-key="t-workspace">{{ $t('t-workspace') }}</span>
                        <div class="btn btn-primary btn-icon btn-add fs-14 p-1" @click="gotoModal(null, 'FolderCreate', '폴더 만들기')">+</div>
                    </li>

                    <TreeMenu :model="treeData"></TreeMenu>

                    <li class="menu-title mt-4">
                        <i class="ri-more-fill"></i>
                        <span data-key="t-archive">{{ $t('t-archive') }}</span>
                    </li>
                    <li class="nav-item mb-4"></li>

                    <li class="menu-title mt-4">
                        <i class="ri-more-fill"></i>
                        <span data-key="t-settings">{{ $t('t-settings') }}</span>
                    </li>
                    <li class="nav-item mb-5">
                        <!-- <a class="nav-link menu-link" href="#sidebarDashboards" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarDashboards">
                            <span data-key="t-dashboards"> {{ $t("t-dashboards") }}</span>
                        </a> -->
                        <div class="collapse menu-dropdown show" id="sidebarDashboards">
                            <ul class="navbar-nav hand">
                                <li class="nav-item">
                                    <span @click="navigateTo('/tesla')" class="nav-link custom-abc" data-key="t-myinfo">
                                        <i class="ri-user-line pe-2"></i>{{ $t('t-myinfo') }}
                                    </span>
                                </li>
                                <li class="nav-item">
                                    <span @click="navigateTo('/facility')" class="nav-link" data-key="t-history"> <i class="ri-history-line pe-2"></i>{{ $t('t-history') }} </span>
                                </li>
                                <li class="nav-item">
                                    <span @click="navigateTo('/notification')" class="nav-link" data-key="t-status">
                                        <i class="ri-bar-chart-2-line pe-2"></i>{{ $t('t-status') }}
                                    </span>
                                </li>
                                <li class="nav-item">
                                    <span @click="navigateTo('/main-calendar')" class="nav-link" data-key="t-preference">
                                        <i class="ri-settings-3-line pe-2"></i>{{ $t('t-preference') }}
                                    </span>
                                </li>

                                <li class="nav-item">
                                    <router-link to="/biztalk" class="nav-link" data-key="t-biztalk"> <i class="ri-bar-chart-2-line pe-2"></i>{{ $t('t-biztalk') }} </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </div>
            </ul>

            <ul class="navbar-nav h-100 hand d-none" id="navbar-nav-secondary">
                <!-- <div class="collapse menu-dropdown" > -->

                <li class="nav-item" @click="navigateTo('/company')">
                    <span class="nav-link"><i class="ri-building-2-line"></i></span>
                </li>

                <li class="nav-item" @click="routeTo('/page', sysEnums.PageTags.User)">
                    <span class="nav-link"><i class="ri-group-line"></i></span>
                </li>

                <li class="nav-item" @click="routeTo('/page', sysEnums.PageTags.Noti)">
                    <span class="nav-link"><i class="ri-mail-send-line"></i></span>
                </li>

                <li class="nav-item" @click="routeTo('/page', sysEnums.PageTags.Decision)">
                    <span class="nav-link"><i class="ri-quill-pen-line"></i></span>
                </li>

                <li class="nav-item" @click="routeTo('/page', sysEnums.PageTags.Cooperation)">
                    <span class="nav-link"><i class="ri-task-line"></i></span>
                </li>

                <li class="nav-item" @click="routeTo('/page', sysEnums.PageTags.Safety)">
                    <span class="nav-link"><i class="ri-spam-line"></i></span>
                </li>

                <li class="nav-item" @click="routeTo('/page', sysEnums.PageTags.File)">
                    <span class="nav-link"><i class="ri-attachment-2"></i></span>
                </li>

                <li class="nav-item" @click="navigateTo('/company')">
                    <span class="nav-link"><i class="ri-message-2-line"></i></span>
                </li>

                <li class="nav-item" @click="navigateTo('/company')">
                    <span class="nav-link"><i class="ri-live-2"></i></span>
                </li>

                <li class="nav-item" @click="navigateTo('/company')">
                    <span class="nav-link"><i class="ri-briefcase-line"></i></span>
                </li>

                <li class="nav-item" @click="routeTo('/page', sysEnums.PageTags.Trash)">
                    <span class="nav-link"><i class="ri-delete-bin-line"></i></span>
                </li>

                <li class="nav-item" @click="routeTo('/page', sysEnums.PageTags.Trash)">
                    <span class="nav-link">
                        <BBadge variant="danger">{{ countItemsInTrash() }}</BBadge>
                    </span>
                </li>

                <li class="nav-item" @click="gotoModal(null, 'FolderCreate', '폴더 만들기')">
                    <span class="nav-link">
                        <div class="btn btn-primary btn-icon btn-add fs-14">+</div>
                    </span>
                </li>

                <!-- 확장메뉴 -->
                <li class="nav-item mt-2">
                    <a class="nav-link menu-link border-bottom" href="#sidebarFolders" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarFolders">
                        <i class="ri-folder-3-line"></i>
                        <span data-key="t-workspace"> {{ $t('t-workspace') }}</span>
                    </a>
                    <div class="collapse menu-dropdown" id="sidebarFolders">
                        <ul class="nav nav-sm flex-column pe-4">
                            <TreeMenu :model="treeData"></TreeMenu>
                        </ul>
                    </div>
                </li>

                <li class="menu-title mt-4">
                    <i class="ri-more-fill"></i>
                    <span class="nav-link">...</span>
                </li>

                <li class="nav-item">
                    <span @click="navigateTo('/tesla')" class="nav-link custom-abc" data-key="t-myinfo">
                        <i class="ri-user-line"></i>
                    </span>
                </li>
                <li class="nav-item">
                    <span @click="navigateTo('/facility')" class="nav-link" data-key="t-history"> <i class="ri-history-line"></i></span>
                </li>
                <li class="nav-item">
                    <span @click="navigateTo('/notification')" class="nav-link" data-key="t-status">
                        <i class="ri-bar-chart-2-line"></i>
                    </span>
                </li>
                <li class="nav-item">
                    <span @click="navigateTo('/main-calendar')" class="nav-link" data-key="t-preference">
                        <i class="ri-settings-3-line"></i>
                    </span>
                </li>

                <li class="nav-item">
                    <router-link to="/biztalk" class="nav-link" data-key="t-biztalk"> <i class="ri-bar-chart-2-line pe-2"></i></router-link>
                </li>
            </ul>
        </template>
    </BContainer>
</template>

<style scoped lang="scss">
.navbar-menu .navbar-nav .nav-link {
    padding: 0.35rem 0.5rem;
    margin-left: 12px;
}

.navbar-menu .navbar-nav .nav-sm .nav-link:before {
    content: none;
    padding-left: 0px;
    margin-left: 0px;
}

#navbar-nav {
    // list-style-type: square !important;
    list-style: none;
    padding-left: 0px;
    margin-left: 0px;
}

.nav-sm .nav-item .nav-link {
    padding: 0.5rem 0.5rem;
    color: var(--vz-vertical-menu-sub-item-color);
    white-space: none;
    position: static !important;
    font-size: 0.875rem;
    font-family: 'Open Sans', sans-serif;
}

.btn-add {
    width: 26px;
    height: 26px;
    text-align: justify;
}
</style>
