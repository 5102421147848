<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <BRow>
            <BCol lg="12">
                <BCard no-body>

                    <!-- <BCardHeader class="justify-content-between d-flex align-items-center">
                        <BCardTitle></BCardTitle>
                    </BCardHeader> -->

                    <BCardBody>

                        <table class="table table-striped table-hover text-center">
                            <thead>
                                <tr class="h-50h bg-light align-middle fw-bold">
                                    <th scope="col">번호</th>
                                    <th v-for="x in titles" :key="x">{{ x }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="h-50h align-middle hand" v-for="(x, i) in userList" :key="x">
                                    <th class="w-5" scope="row">{{ i + 1 }}</th>
                                    <td>
                                        <span>
                                            <img :src="x.AvatarPath ? $fileUrl + x.AvatarPath : 'assets/images/no-profile.jpg'" class="rounded-circle-36"
                                                :class="x.Status ? '' : 'opacity-25'" />
                                        </span>
                                    </td>
                                    <td class="w-10" v-text="x.UserName"></td>
                                    <td class="w-10" v-text="x.LoginId"></td>
                                    <td class="w-10" v-text="enumTypes.UserTypes[x.UserType]"></td>
                                    <td class="w-20">
                                        <span class="pe-4 fs-20">
                                            <i :class="x.Status && x.UserType === sysEnums.UserTypes.Agency ? 'bi-person-fill text-danger' :
            x.UserType === sysEnums.UserTypes.Agency ? 'bi-person fs-14' :
                x.Status && $moment(x.Checked).format('YY') !== '00' && x.UserType === sysEnums.UserTypes.Partner ? 'bi-person-fill-gear text-primary' :
                    x.Status && $moment(x.Checked).format('YY') === '00' && x.UserType === sysEnums.UserTypes.Partner ? 'bi-person-check-fill text-primary' :
                        x.UserType === sysEnums.UserTypes.Partner ? 'bi-person-check fs-14' :
                            x.Status ? 'bi-person-fill' : 'bi-person fs-14'" @click="setUserStatus(x.EncUser, x.LoginId)"></i>
                                        </span>
                                    </td>
                                    <td class="w-10" v-if="!$store.state.isMobile" v-text="x.Status === true ? $moment(x.Updated).format('YY.MM.DD') : '-'"></td>
                                    <td class="w-10" v-if="!$store.state.isMobile" v-text="$moment(x.Created).format('YY.MM.DD')"></td>
                                    <td class="w-10">
                                        <div class="form-switch">
                                            <input class="form-check-input hand" type="checkbox" role="switch" id="flexSwitchCheckDefault" :checked="x.Status && x.UserType > 0"
                                                @click="setUserStatus(x.EncUser, x.LoginId)" />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </BCardBody>
                </BCard>
            </BCol>
        </BRow>

    </Layout>
</template>

<script lang="js">
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { ref, inject } from 'vue';
import { apiEnums, sysEnums, enumTypes } from "@/common/sysEnums";
// import { getUserTypeList } from "@/common/comFuncs";

export default {
    components: {
        Layout,
        PageHeader,
    },
    // props: {
    //     Data: { type: Object, required: true },
    //     Title: { type: Array, required: true },
    // },
    setup() {
        const titles = ref(['', '회원명', '아이디', '타입', '권한수정', '수정일', '등록일', '상태']);

        const store = inject("$store");
        const axios = inject("$axios");

        // const chatSignals = inject('$chatSignals');

        const { userList } = inject("$provideList");

        // onMounted(() => {
        //     dataReset();
        // });

        // const dataReset = () => {
        //     let obj = {};
        //     userList.value.forEach(item => {
        //         if (!obj[item.LoginId]) {
        //             obj[item.LoginId] = [];
        //         }
        //         obj[item.LoginId].push(item);
        //     });
        //     dataList.value = obj;
        // }

        // const setUserTypeConsole = (encUser, userType) => {
        //     let formData = new FormData();
        //     formData.append('EncUser', encUser);
        //     formData.append('UserType', userType);
        //     axios.post(apiEnums.CloudTicket.setUserTypeConsole, formData)
        //         .then((response) => {
        //             // console.log(JSON.stringify(response))
        //             if (response.data === '0000') {
        //                 // resultMsg.value = '~';
        //             } else {
        //                 getUser();
        //             }
        //         })
        //         .catch(() => { });
        // };

        const setUserStatus = (encUser, loginId) => {
            let formData = new FormData();
            formData.append('EncUser', encUser);
            axios.post(apiEnums.CloudTicket.setUserStatus, formData)
                .then(async (response) => {
                    if (response.data === '1000') {
                        getUser();

                        try {
                            await store.state.connection.invoke('SendUserReset', loginId, enumTypes.SignalTypes.UserUpdated);
                        } catch (error) {
                            console.error('Error invoking SendUserReset : ', error);
                        }
                    }
                })
                .catch(() => { });
        };

        const getUser = () => {
            axios.post(apiEnums.CloudTicket.getUserConsole)
                .then((response) => {
                    store.dispatch("AC_USER_ALL", response.data);
                    userList.value = response.data;
                    // dataReset();
                    // userList.value.forEach((item) => {
                    //     item.Selected = false;
                    // });
                })
                .catch(() => { });
        };

        return {
            title: "Users",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "UserList",
                    active: true,
                },
            ],
            titles, sysEnums, enumTypes,
            userList,
            setUserStatus,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable="true"] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
