<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <BRow>
            <BCol cols="12">
                <div class="justify-content-between d-flex align-items-center mt-3 mb-4">
                    <h5 class="mb-0 pb-1 text-decoration-underline">결제관리</h5>
                </div>
                <BRow>
                    <BCol xxl="4" lg="6">
                        <BCard no-body class="card-animate">
                            <BCardBody>
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="fw-medium text-muted mb-0">수납금액</p>
                                        <h2 class="mt-4 ff-secondary fw-semibold">
                                            {{ (totalIncome * sysEnums.DiscoutTypes.Tax).toLocaleString() }}원
                                        </h2>
                                        <p class="mb-0 text-muted">
                                        </p>
                                    </div>
                                    <div>
                                        <div class="avatar-sm flex-shrink-0">
                                            <span class="avatar-title bg-primary-subtle rounded-circle fs-2">
                                                <span class="text-primary bi-person"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </BCardBody>
                        </BCard>
                    </BCol>
                    <BCol xxl="4" lg="6">
                        <BCard no-body class="card-animate">
                            <BCardBody>
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="fw-medium text-muted mb-0">페이스토리 차감액</p>
                                        <h2 class="mt-4 ff-secondary fw-semibold">
                                            {{ totalPayStoryFee.toLocaleString() }}원
                                        </h2>
                                        <p class="mb-0 text-muted">
                                        </p>
                                    </div>
                                    <div>
                                        <div class="avatar-sm flex-shrink-0">
                                            <span class="avatar-title bg-primary-subtle rounded-circle fs-2">
                                                <span class="text-primary bi-person"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </BCardBody>
                        </BCard>
                    </BCol>
                    <BCol xxl="4" lg="6">
                        <BCard no-body class="card-animate">
                            <BCardBody>
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <p class="fw-medium text-muted mb-0">임대인 입금액</p>
                                        <h2 class="mt-4 ff-secondary fw-semibold">
                                            {{ totalLessorSent.toLocaleString() }}원
                                        </h2>
                                        <p class="mb-0 text-muted">
                                        </p>
                                    </div>
                                    <div>
                                        <div class="avatar-sm flex-shrink-0">
                                            <span class="avatar-title bg-primary-subtle rounded-circle fs-2">
                                                <span class="text-primary bi-person"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </BCardBody>
                        </BCard>
                    </BCol>
                </BRow>
            </BCol>
        </BRow>

        <BRow>
            <BCol lg="12">
                <BCard no-body>
                    <BCardHeader class="justify-content-between d-flex align-items-center">
                        <BCardTitle>PayStory</BCardTitle>
                    </BCardHeader>
                    <BCardBody>
                        <ChartLine :Target="enumTypes.ChartTypes.PayStory"></ChartLine>
                    </BCardBody>
                </BCard>
            </BCol>
        </BRow>

        <BCard no-body class="card-animate">
            <BCardHeader class="border-0 align-items-center d-flex">
                <BCardTitle class="mb-0 flex-grow-1">{{ contractList.length }}건
                </BCardTitle>

                <div class="d-flex align-items-center me-5">
                    <BFormSelect v-model="selectedYy" class="form-select me-2" @change="handleMonthChange">
                        <BFormSelectOption :value="null">년선택</BFormSelectOption>
                        <BFormSelectOption v-for="yy in years" :key="yy" :value="yy">{{ yy }}</BFormSelectOption>
                    </BFormSelect>
                    <BFormSelect v-model="selectedMm" class="form-select" @change="handleMonthChange">
                        <BFormSelectOption :value="null">월선택</BFormSelectOption>
                        <BFormSelectOption v-for="mm in 12" :key="mm" :value="mm">{{ mm }}</BFormSelectOption>
                    </BFormSelect>
                </div>

                <div class="hstack gap-1">
                    <BButton type="button" :variant="paySelect === 0 ? 'soft-primary' : 'soft-secondary'" size="sm" @click="selectStatus(0)">ALL</BButton>
                    <BButton type="button" :variant="paySelect === 1 ? 'soft-primary' : 'soft-secondary'" size="sm" @click="selectStatus(1)">완납</BButton>
                    <BButton type="button" :variant="paySelect === 2 ? 'soft-primary' : 'soft-secondary'" size="sm" @click="selectStatus(2)">미납</BButton>
                </div>
            </BCardHeader>
            <BCardBody>
                <BRow>
                    <BCol cols="12">
                        <div class="px-2 bg-white">
                            <table class="table table-striped table-hover text-center">
                                <thead>
                                    <tr class="h-50h bg-light align-middle fw-bold">
                                        <th scope="col">번호</th>
                                        <th v-for="x in titles" :key="x">{{ x }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(x, i) in contractList" :key="x">
                                        <tr class="h-50h align-middle hand" @click="setWidgetData">
                                            <th class="w-5" scope="row">{{ i + 1 }}</th>
                                            <td class="text-start" v-text="x.Address1"></td>
                                            <td class="w-5" v-text="x.LessorName"></td>
                                            <td class="w-5" v-text="x.PartnerName"></td>
                                            <td class="w-5" v-text="x.LesseeName"></td>
                                            <td class="w-5" v-text="x.PaymentDay"></td>
                                            <td class="w-5" v-text="x.PayDos.length + ' / ' + getCotractPeriod(x.StartDate, x.EndDate)"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="x.PayDos ? $moment(x.PayDos.Created).format('YY.MM.DD') : '-'"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="((x.Rent + x.ManagementFee) * sysEnums.DiscoutTypes.Tax).toLocaleString()"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="x.FeeDiscountRate + '%'"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="(1 - (x.FeeDiscountRate)) + '%'"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="x.PayMid ? (x.PayMid.CardFee || sysEnums.cardFee) + '%' : '-'"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile"
                                                v-text="x.PayMid ? ((x.PayMid.CardFee || sysEnums.cardFee) + (1 - (x.FeeDiscountRate))) + '%' : '-'"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile"
                                                v-text="x.PayMid && x.PayMid.CardFee ? getHouseMetaFee(x.Rent, x.ManagementFee, x.FeeDiscountRate, x.PayMid.CardFee).toLocaleString() : '-'">
                                            </td>
                                            <td class="w-5" v-if="!$store.state.isMobile"
                                                v-text="x.PayMid && x.PayMid.CardFee ? getLessorSent(x.Rent, x.ManagementFee, x.FeeDiscountRate, x.PayMid.CardFee).toLocaleString() : '-'">
                                            </td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="x.PayDos.length >= getStatus(x.StartDate) ? '완납' : '미납'"></td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </BCol>
                </BRow>
            </BCardBody>
        </BCard>

    </Layout>
</template>

<script lang="js">
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { onMounted, computed, ref, inject, reactive } from 'vue';
import { apiEnums, sysEnums, enumTypes } from "@/common/sysEnums";
import { getCotractPeriod, getServiceFee, getPayStoryFee, getPartnerFee, getHouseMetaFee, getLessorSent } from "@/common/comFuncs";
import ChartLine from "@/components/charts/ChartLine";

export default {
    components: {
        Layout,
        PageHeader,
        ChartLine,
    },
    props: {
        Data: Object,
        Title: Array,
    },
    setup(props) {
        const titles = ref(['시설', '임대인', '파트너', '임차인', '결제일', '회차', '결제일시', '수납금액', '할인률', '적용률', '카드수수료', '전체요율', 'PS차감', '임대인입금', '상태']);

        const paySelect = ref(0);
        const selectedYy = ref('');
        const selectedMm = ref('');

        const years = reactive(['2023', '2024', '2025', '2026', '2027', '2028', '2029']);

        const store = inject("$store");
        const axios = inject("$axios");
        const moment = inject("$moment");

        const contracts = inject('$contracts');

        const { contractList } = inject("$provideList");

        onMounted(() => {
            contractList.value = contracts.value;
        });

        const totalIncome = computed(() => {
            return contractList.value.reduce((acc, cur) => {
                return acc + (cur.Rent + cur.ManagementFee);
            }, 0);
        });

        // totalPayStoryFee와 totalLessorSent를 computed 속성으로 처리
        const totalPayStoryFee = computed(() => {
            return contractList.value.reduce((acc, cur) => {
                const cardFee = cur.PayMid ? (cur.PayMid.CardFee || sysEnums.cardFee) : sysEnums.cardFee;
                return acc + getHouseMetaFee(cur.Rent, cur.ManagementFee, cur.FeeDiscountRate, cardFee);
            }, 0);
        });

        const totalLessorSent = computed(() => {
            return contractList.value.reduce((acc, cur) => {
                const cardFee = cur.PayMid ? (cur.PayMid.CardFee || sysEnums.cardFee) : sysEnums.cardFee;
                return acc + getLessorSent(cur.Rent, cur.ManagementFee, cur.FeeDiscountRate, cardFee);
            }, 0);
        });

        const getStatus = (startDate) => {
            // 계약일 날자를 기준으로 기간 계산, 결제일은 무시
            const day = moment(startDate).format('DD');
            const endDate = moment().day(day);
            const period = getCotractPeriod(startDate, endDate)

            return period;
        }

        const setWidgetData = () => {
            // let title = '';
            // let target = '';
            // $store.dispatch("AC_WIDGET", { title: title, target: target, data: props.Data });
        }

        const setContractStatus = (encContract) => {
            const formData = new FormData();
            formData.append('EncContract', encContract);
            axios.post(apiEnums.CloudTicket.setContractStatus, formData)
                .then((response) => {
                    if (response.data === '1000') {
                        getContract();
                    }
                })
                .catch(() => { });
        };

        const getContract = () => {
            axios.post(apiEnums.CloudTicket.getContractConsole)
                .then((response) => {
                    const datas = response.data;
                    store.dispatch("AC_CONTRACT", datas);
                    contractList.value = datas;
                    // console.log(JSON.stringify(response.data))
                })
                .catch(() => { });
        };

        const selectStatus = (select) => {
            paySelect.value = select;
            select === 1 ? contractList.value = contracts.value.filter(x => x.PayDos.length >= getStatus(x.StartDate)) :
                select === 2 ? contractList.value = contracts.value.filter(x => x.PayDos.length < getStatus(x.StartDate)) : contractList.value = contracts.value;
        }

        const handleMonthChange = () => {
            if (!selectedYy.value) selectedYy.value = moment().format('YYYY');

            if (!selectedMm.value) {
                contractList.value = contracts.value;
            } else {
                // 등록일 기준 필터링
                // contractList.value = contracts.value.filter(x => {
                //     const createdDt = moment(x.Created);
                //     return createdDt.year() === parseInt(selectedYy.value) &&
                //         createdDt.month() + 1 === parseInt(selectedMm.value); // month는 0부터 시작하므로 +1
                // });

                // 선택된 년도와 월에 해당하는 데이터만 필터링.
                contractList.value = contracts.value.filter(x => {
                    const startDt = moment(x.StartDate);
                    const endDt = moment(x.EndDate);
                    const selectedDt = moment(`${selectedYy.value}-${selectedMm.value}-01`, 'YYYY-MM-DD');

                    return selectedDt.isBetween(startDt, endDt, 'month', '[]');
                });
            }
        }

        return {
            title: "PayStory",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "PayStoryList",
                    active: true,
                },
            ],
            props, totalIncome, totalPayStoryFee, totalLessorSent,
            titles, contractList, sysEnums, enumTypes, paySelect,
            years, selectedYy, selectedMm,
            getCotractPeriod, getServiceFee, getPayStoryFee, getPartnerFee, getHouseMetaFee, getLessorSent,
            setWidgetData, setContractStatus,
            selectStatus, getStatus, handleMonthChange,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable="true"] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
