<script>
import { sysEnums } from '@/common/sysEnums';
import { computed, inject } from 'vue';

export default {
    props: {
        model: Object,
    },
    setup(props) {
        const router = inject('$router');

        const widgetInfo = inject('$widgetInfo');

        const { selectedFolder, selectedPage, filteredPage, pageCount } = inject('$provideList');

        const isFolder = computed(() => {
            return props.model.children && props.model.children.length;
        });

        // const changeType = () => {
        //     if (!isFolder.value) {
        //         // props.model.children = []
        //         console.log('props.model : ' + JSON.stringify(props.model))
        //     }
        // }

        const navigateTo = (target, data) => {
            filteredPage.value = [];
            widgetInfo.value.tag = sysEnums.PageTags.Default;
            selectedFolder.value = data;
            widgetInfo.value.title = data.Name;
            router.push(target);
        };

        return {
            props,
            sysEnums,
            widgetInfo,
            isFolder,
            selectedFolder,
            selectedPage,
            pageCount,
            navigateTo,
        };
    },
};
</script>

<template>
    <!--페이지탭에 보이는 폴더-->
    <div v-if="model.Name !== 'FOLDER'" class="d-flex flex-items-center" :class="{ bold: isFolder }" @click="navigateTo('/page', model)">
        <span class="flex-grow-1">
            <span v-if="isFolder" style="cursor: move" draggable="true" @dragstart="dragStart(model, $event)">
                <i :class="model.Type === sysEnums.PageCategorys.Folder ? 'ri-folder-3-line' : 'ri-space'"></i> <span class="fw-bold pe-2" v-text="model.Name"></span>
                <BBadge v-if="model.FolderRoles.length > 0" variant="dark">{{ model.FolderRoles.length }}</BBadge>
            </span>
            <span v-else class="" style="cursor: move" draggable="true" @dragstart="dragStart(model, $event)">
                <i :class="model.Type === sysEnums.PageCategorys.Folder ? 'ri-folder-3-line' : 'ri-space'"></i> <span v-text="model.Name"></span>
                <BBadge v-if="model.FolderRoles.length > 0" class="ms-2" variant="primary">{{
                    model.Type === sysEnums.PageCategorys.Post ? pageCount(model.EncFolder) : model.FolderRoles.length
                }}</BBadge>
            </span>
        </span>

        <span class="flex-grow-0 text-muted pt-2">{{ $moment(model.Created).format('YY.MM.DD') }}</span>
    </div>

    <!--<div v-if="model.Name !== 'FOLDER'" class="text-muted pb-2 mb-2 border-bottom">{{ model.Description }}</div>-->

    <span v-if="isFolder">
        <AppTreeFolder v-for="x in model.children" :key="x" :model="x"></AppTreeFolder>
    </span>
</template>

<style scoped></style>
