const chat = {
    namespaced: true,
    state: {
        chatList: {},
        chatData: {},
        chatSignal: [],
        chatSelect: [],
    },
    getters: {
        // 채팅 가능 리스트
        GE_CHAT: (state) => state.chatList,
        // 채팅 했던 리스트
        GE_CHAT_DATA: state => state.chatData,
        // 채팅 현재 접속 리스트
        GE_SIGNAL: state => state.chatSignal,
        // 채팅중인 데이터
        GE_CHAT_SELECT: state => state.chatSelect,
    },
    mutations: {
        MU_CHAT: (state, payload) => { state.chatList = payload },
        MU_CHAT_DATA: (state, payload) => { state.chatData = payload },

        MU_SIGNAL: (state, payload) => { state.chatSignal = payload },
        MU_SIGNAL_ADD: (state, chatItem) => { state.chatSignal.push(chatItem) },
        MU_SIGNAL_DEL: (state, chatIndex) => { state.chatSignal.splice(chatIndex, 1) },
        MU_SIGNAL_UP: (state, payload) => {
            state.chatSignal[payload.index].Status = payload.data.status,
                state.chatSignal[payload.index].Updated = payload.data.updated
        },
        MU_CHAT_SELECT: (state, payload) => { state.chatSelect = payload },
        MU_CHAT_ADD: (state, chatItem) => { state.chatSelect.push(chatItem) },
        MU_CHAT_DEL: (state, chatIndex) => { state.chatSelect.splice(chatIndex, 1) },
    },
    actions: {
        AC_CHAT: ({ commit }, payload) => { commit("MU_CHAT", payload) },
        AC_CHAT_DATA: ({ commit }, payload) => { commit("MU_CHAT_DATA", payload) },

        AC_SIGNAL: ({ commit }, payload) => { commit("MU_SIGNAL", payload) },
        AC_SIGNAL_ADD: ({ commit }, chatItem) => { commit("MU_SIGNAL_ADD", chatItem) },
        AC_SIGNAL_DEL: ({ commit }, chatIndex) => { commit("MU_SIGNAL_DEL", chatIndex) },

        AC_CHAT_SELECT: ({ commit }, payload) => { commit("MU_CHAT_SELECT", payload) },
        AC_CHAT_ADD: ({ commit }, chatItem) => { commit("MU_CHAT_ADD", chatItem) },
        AC_CHAT_DEL: ({ commit }, chatIndex) => { commit("MU_CHAT_DEL", chatIndex) },

        // async AC_SEARCH({ commit }, payload) {
        //     await new Promise((resolve) => setTimeout(resolve, 300));
        //     commit('MU_SEARCH', payload);
        // },    
    },
};

module.exports = chat;
