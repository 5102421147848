<script>
import { sysEnums } from '@/common/sysEnums';
import { inject } from 'vue';

export default {
    setup() {
        const widgetInfo = inject('$widgetInfo');

        const { userList, selectedFolder, selectedClip, gotoModal, setFolderUpdate } = inject('$provideList');

        return {
            sysEnums,
            widgetInfo,
            userList,
            selectedFolder,
            selectedClip,
            gotoModal,
            setFolderUpdate,
        };
    },
};
</script>

<template>
    <div class="profile-foreground position-relative mx-n4 mt-n4">
        <div class="profile-wid-bg">
            <img src="@/assets/images/profile-bg.jpg" alt="" class="profile-wid-img" />
        </div>
    </div>

    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
        <BRow class="g-4">
            <BCol cols="auto" :class="['', { 'd-none': widgetInfo.isApp }]">
                <img
                    v-if="selectedFolder && selectedFolder.FolderAvatar"
                    :src="$fileUrl + selectedFolder.FolderAvatar || 'assets/images/no-profile.jpg'"
                    :class="['avatar-lg img-thumbnail', { 'opacity-25': !selectedFolder.Status }]"
                />

                <img
                    v-else-if="selectedClip && selectedClip.Avatar"
                    :src="$fileUrl + selectedClip.Avatar || 'assets/images/no-profile.jpg'"
                    :class="['avatar-lg img-thumbnail rounded-circle', { 'opacity-25': !selectedClip.Status }]"
                />

                <img
                    v-else
                    :src="widgetInfo.companyInfo.Avatar ? $fileUrl + widgetInfo.companyInfo.Avatar : 'assets/images/no-profile.jpg'"
                    :class="['img-thumbnail', { 'opacity-25': !widgetInfo.companyInfo.Status }, widgetInfo.isApp ? 'avatar-xs' : 'avatar-lg']"
                />
            </BCol>

            <BCol cols="12" lg="auto" v-if="widgetInfo.tag === sysEnums.PageTags.Search">
                <div class="p-3 br-8 text-light border border-2 border-light">
                    <div><span class="text-warning pe-2">~</span>기간 검색 (예, ~20240301 : 2024년 3월 1일 자료)</div>
                    <div><span class="text-warning pe-2">!</span>장소 검색 (예, !강남구 : 주소가 강남구내의 모든 자료)</div>
                    <div><span class="text-warning pe-2">#</span>본문 검색 (#휴가 : 본문에 ‘휴가’가 포함된 자료)</div>
                    <div><span class="text-warning pe-2">*</span>파일 검색 (*작업사진 : 파일명에 ‘작업사진’이 포함된 모든 파일)</div>
                    <div><span class="me-4">사용예시</span><span class="text-warning">!강남구 ~2024 #휴가</span></div>
                    <!-- <div><span class="text-warning pe-1">@</span>이름 검색 (예, @홍길동 : ‘홍길동’인 등록한 모든 자료)</div> -->
                </div>
            </BCol>

            <BCol v-else>
                <div class="p-2">
                    <h3 class="text-white mb-2" @click="gotoModal(selectedFolder, 'FolderCreate', '폴더 수정')">{{ widgetInfo.title || widgetInfo.companyInfo.Name }}</h3>
                    <p class="text-white text-opacity-75">
                        <i class="ri-phone-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{{ widgetInfo.companyInfo.Tel || '' }}
                        <span class="ms-3 text-uppercase">BN:</span> {{ widgetInfo.companyInfo.BusinessNum || '' }}
                    </p>

                    <div v-if="selectedFolder.EncFolder" class="hstack text-white-50 gap-1">
                        <span class="me-2"> <i class="ri-survey-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{{ selectedFolder.Description || '' }} </span>
                        <span>
                            <i class="ri-price-tag-3-line me-1 text-white text-opacity-75 fs-16 align-middle"></i
                            >{{ $moment(selectedFolder.Created).format('YYYY-MM-DD hh:mm') || '' }}
                        </span>
                    </div>
                    <div v-else class="hstack text-white-50 gap-1">
                        <span class="me-2"> <i class="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{{ widgetInfo.companyInfo.Owner || '' }} </span>
                        <span>
                            <i class="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{{ widgetInfo.companyInfo.PostalCode || '' }}
                            {{ widgetInfo.companyInfo.Address1 || '' }} {{ widgetInfo.companyInfo.Address2 || '' }}
                        </span>
                    </div>
                </div>
            </BCol>

            <BCol cols="12" lg="auto" order-lg="0" class="order-last">
                <BRow class="text text-white-50 text-center">
                    <BCol lg="6" cols="4">
                        <div class="p-2" v-if="selectedFolder.Type === sysEnums.PageCategorys.Folder || selectedFolder.Type === sysEnums.PageCategorys.Post">
                            <i
                                :class="selectedFolder.Fixed ? 'ri-pushpin-2-fill text-primary' : 'ri-pushpin-line'"
                                class="text-light me-3"
                                @click="setFolderUpdate(selectedFolder, sysEnums.ClipHandles.Fixed)"
                            ></i>
                            <i
                                :class="selectedFolder.Shared ? 'ri-eye-line' : 'ri-eye-off-line'"
                                class="text-light me-3"
                                @click="setFolderUpdate(selectedFolder, sysEnums.ClipHandles.Shared)"
                                v-if="selectedFolder.Type === sysEnums.PageCategorys.Post"
                            ></i>
                        </div>
                    </BCol>
                    <!-- <BCol lg="6" cols="4">
                        <div class="p-2">
                            <h4 class="text-white mb-1">{{ userList.length }}</h4>
                            <p class="fs-14 mb-0">직원</p>
                        </div>
                    </BCol>
                    <BCol lg="6" cols="4">
                        <div class="p-2">
                            <h4 class="text-white mb-1">1</h4>
                            <p class="fs-14 mb-0">기타</p>
                        </div>
                    </BCol> -->
                </BRow>
            </BCol>
        </BRow>
    </div>
</template>

<style></style>
