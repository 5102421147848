<template>
    <div v-show="widgetInfo.selectedFile">
        <div class="d-flex align-items-center">
            <h5>
                {{ fileName }}
            </h5>
        </div>

        <!-- <input type="file" @change="onFileChange" :accept="acceptedFileTypes" /> -->
        <div v-if="isPdf && widgetInfo.selectedFile">
            <iframe :src="$fileUrl + widgetInfo.selectedFile" width="100%" height="620px"></iframe>
        </div>

        <div v-if="isExcel && excelData.length">
            <table>
                <thead>
                    <tr>
                        <th v-for="header in excelHeaders" :key="header">{{ header }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in excelData" :key="index">
                        <td v-for="(cell, i) in row" :key="i">{{ cell }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- DOCX 처리 파트 추가 -->
        <div v-if="isDocx && widgetInfo.selectedFile">
            <simplebar class="p-0" data-simplebar style="height: 620px">
                <div v-html="widgetInfo.selectedFile"></div>
            </simplebar>
        </div>
    </div>
</template>

<script lang="js">
import { sysEnums } from "@/common/sysEnums";
import { getCurrentInstance, inject, onMounted, ref, watch } from "vue";

import simplebar from 'simplebar-vue';

import mammoth from 'mammoth';
import * as XLSX from 'xlsx';

export default {
    props: {

    },
    components: {
        simplebar
    },
    setup(props) {
        const fileName = ref(null);
        const excelHeaders = ref([]);
        const excelData = ref([]);

        const isPdf = ref(false);
        const isExcel = ref(false);
        const isDocx = ref(false); // DOCX 파일인지 여부

        const { proxy } = getCurrentInstance(); // Get the component instance
        const fileUrl = proxy.$fileUrl;

        const widgetInfo = inject("$widgetInfo");

        const { selectedPage } = inject("$provideList");

        watch(() => widgetInfo.value.data, (newValue, oldValue) => {
            if (newValue && newValue !== oldValue) {
                try {
                    // 정해진 구조를 갖는지를 먼저 확인
                    if (newValue.FileName) {
                        acceptedFileTypes(newValue);
                        // console.log(JSON.stringify(newValue)); // 데이터 확인
                    }
                } catch (error) {
                    console.error("Error processing data:", error);
                }
            }
        });

        onMounted(() => {
            if (widgetInfo.value.data.FileName) {
                acceptedFileTypes(widgetInfo.value.data);
            }
        });

        const acceptedFileTypes = (file) => {
            fileName.value = file.FileName;
            const fileExtension = fileName.value.split('.').pop().toLowerCase();

            isPdf.value = fileExtension === 'pdf';
            isExcel.value = ['xlsx', 'xls'].includes(fileExtension);
            isDocx.value = fileExtension === 'docx';

            onFileChange(file);
        }

        const onFileChange = async (file) => {
            widgetInfo.value.modalSize = 'xl';

            try {
                if (isPdf.value) {
                    // console.log('PDF File:', file);
                    widgetInfo.value.selectedFile = file.FilePath;
                } else if (isExcel.value) {
                    const response = await fetch(fileUrl + file.FilePath);

                    if (!response.ok) throw new Error('Failed to fetch Excel file');

                    const data = new Uint8Array(await response.arrayBuffer());
                    const workbook = XLSX.read(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    excelHeaders.value = jsonData[0];
                    excelData.value = jsonData.slice(1);
                } else if (isDocx.value) {
                    const response = await fetch(fileUrl + file.FilePath);

                    if (!response.ok) throw new Error('Failed to fetch DOCX file');

                    const arrayBuffer = await response.arrayBuffer();
                    const result = await mammoth.convertToHtml({ arrayBuffer });
                    widgetInfo.value.selectedFile = result.value;
                } else {
                    alert(`Unsupported file type: ${fileName.value}`);
                }
            } catch (error) {
                console.error('Error processing file:', error);
            }
        }


        return {
            props, sysEnums, fileName,
            selectedPage,
            widgetInfo,
            isPdf, isExcel, isDocx, excelHeaders, excelData,
            acceptedFileTypes,
        };
    },
};
</script>

<style scoped lang="scss"></style>
