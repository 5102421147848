<template>
    <div v-if="editor">
        <bubble-menu class="bubble-menu px-3 mt-4" :editor="editor" :tippy-options="tippyOptions" :style="floatingMenuStyle1">
            <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }"><i class="ri-bold"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }"><i class="ri-italic"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }"><i class="ri-strikethrough"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }"><i class="ri-underline"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                <i class="ri-heading"></i><i class="ri-number-1"></i></button
            ><i class="ri-more-2-line"></i>
            <!-- <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                <i class="ri-heading"></i><i class="ri-number-2"></i></button
            ><i class="ri-more-2-line"></i> -->
            <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
                <i class="ri-heading"></i><i class="ri-number-3"></i></button
            ><i class="ri-more-2-line"></i>
            <!-- <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
                <i class="ri-heading"></i><i class="ri-number-4"></i></button
            ><i class="ri-more-2-line"></i> -->
            <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
                <i class="ri-heading"></i><i class="ri-number-5"></i></button
            ><i class="ri-more-2-line"></i>
            <!-- <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
                <i class="ri-heading"></i><i class="ri-number-6"></i></button
            ><i class="ri-more-2-line"></i> -->
            <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }"><i class="ri-list-unordered"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }"><i class="ri-list-ordered"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().setHorizontalRule().run()" :class="{ 'is-active': editor.isActive('horizontalrule') }"><i class="ri-separator"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }"><i class="ri-indent-increase"></i></button>
            <i class="ri-more-2-line"></i>

            <button @click="editor.chain().focus().setColor('#A975FF').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #a975ff">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().setColor('#FB5151').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #fb5151">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().setColor('#FD9170').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #fd9170">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().setColor('#FFCB6B').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #ffcb6b">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().setColor('#68CEF8').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #68cef8">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().setColor('#80CBC4').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #80cbc4">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().unsetColor().run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <i class="ri-eraser-line"></i>
            </button>

            <!-- <button @click="editor.chain().focus().setHardBreak().run()" :class="{ 'is-active': editor.isActive('hardbreak') }">hardbreak</button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().undo().run()" :disabled="!editor.can().chain().focus().undo().run()" :class="{ 'is-active': editor.isActive('undo') }">undo</button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().redo().run()" :disabled="!editor.can().chain().focus().redo().run()" :class="{ 'is-active': editor.isActive('redo') }">redo</button> -->
        </bubble-menu>

        <floating-menu class="floating-menu px-3 mt-5 hand" :editor="editor" :tippy-options="tippyOptions" :style="floatingMenuStyle2">
            <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }"><i class="ri-bold"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }"><i class="ri-italic"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }"><i class="ri-strikethrough"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }"><i class="ri-underline"></i></button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
                <i class="ri-heading"></i><i class="ri-number-1"></i></button
            ><i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
                <i class="ri-heading"></i><i class="ri-number-2"></i></button
            ><i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
                <i class="ri-heading"></i><i class="ri-number-3"></i></button
            ><i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
                <i class="ri-heading"></i><i class="ri-number-4"></i></button
            ><i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
                <i class="ri-heading"></i><i class="ri-number-5"></i></button
            ><i class="ri-more-2-line"></i>
            <!-- <button @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
                <i class="ri-heading"></i><i class="ri-number-6"></i></button
            ><i class="ri-more-2-line"></i> -->
            <button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }" aria-expanded="true">
                <i class="ri-list-unordered"></i></button
            ><i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }" aria-expanded="true">
                <i class="ri-list-ordered"></i></button
            ><i class="ri-more-2-line"></i>
            <!-- <button @click="editor.chain().focus().setHorizontalRule().run()" :class="{ 'is-active': editor.isActive('horizontalrule') }" aria-expanded="true">
                <i class="ri-separator"></i></button
            ><i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }" aria-expanded="true">
                <i class="ri-indent-increase"></i>
            </button>
            <i class="ri-more-2-line"></i> -->

            <button @click="editor.chain().focus().setColor('#A975FF').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #a975ff">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().setColor('#FB5151').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #fb5151">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().setColor('#FD9170').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #fd9170">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().setColor('#FFCB6B').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #ffcb6b">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i>
            <button @click="editor.chain().focus().setColor('#68CEF8').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #68cef8">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i>
            <!-- <button @click="editor.chain().focus().setColor('#80CBC4').run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <span class="px-2" style="background-color: #80cbc4">&nbsp;</span>
            </button>
            <i class="ri-more-2-line"></i> -->
            <button @click="editor.chain().focus().unsetColor().run()" :class="{ 'is-active': editor.isActive('textStyle') }" aria-expanded="true">
                <i class="ri-eraser-line"></i>
            </button>
        </floating-menu>
    </div>

    <!-- <div class="d-flex align-items-center position-absolute top-0 end-0">
        <span v-for="x in hideImgs" :key="x" class="pe-2"
            ><img v-if="x.attrs.class" class="avatar-xs hand" :class="x.attrs.class ? '' : 'opacity-50'" :src="x.attrs.src" @click="toggleImageVisibility(x.attrs.id)"
        /></span>
    </div> -->

    <div id="tippy-1" data-tippy-root class="p-2" @dragover.prevent @drop="handleDropFileUpload" @mouseleave="handleUpdate">
        <editor-content :editor="editor" />

        <!-- <span v-for="x in imagePositions" :key="x.id" class="position-absolute start-0" :style="{ top: x.top + 'px', zIndex: 200 }">
            <img class="avatar-xs hand" :src="x.src" @click="toggleImageVisibility(x.id)" />
        </span> -->

        <span v-show="props.modelValue.isActive && !props.modelValue.Fixed" class="position-absolute top-0 end-0 p-2">
            <!-- <span class="p-2 bg-dark text-light rounded-circle">
                    <i :class="props.modelValue.Fixed ? 'ri-pushpin-line' : 'ri-pushpin-2-line'" @click="setClip(sysEnums.ClipHandles.Fix)"></i>
                </span>

                <i class="ri-sort-asc ms-3" @click="setClip(sysEnums.ClipHandles.SortUp)"></i>
                <i class="ri-sort-desc ms-3" @click="setClip(sysEnums.ClipHandles.SortDown)"></i> -->

            <span class="p-2 ms-2 bg-dark text-light rounded-circle">
                <i class="ri-eye-off-line" @click="setClip(sysEnums.ClipHandles.Hided)"></i>
            </span>

            <span class="p-2 ms-2 bg-danger text-light rounded-circle">
                <i class="ri-delete-bin-line" @click="setClip(sysEnums.ClipHandles.Del)"></i>
            </span>
        </span>

        <span v-show="props.modelValue.isActive" class="position-absolute bottom-0 end-0 p-2">
            <BBadge variant="primary">{{ $moment(props.modelValue.Created).format('YY-MM-DD hh:mm') }}</BBadge>
        </span>
    </div>

    <!-- {{ parsedData }} -->

    <span class="d-none">
        <FileUpload ref="fileRef" :FileType="sysEnums.FileTypes.Clip" :Content="props.modelValue.EncPage" :IsBtn="true"></FileUpload>
    </span>

    <span v-show="isUpload" :style="{ top: `${yPos}px` }" class="spinner-border p-4 position-absolute start-50" role="status">
        <!-- <span class="visually-hidden">Loading...</span> -->
        <span>UpLoading...</span>
    </span>
</template>

<script>
import { apiEnums, sysEnums } from '@/common/sysEnums';
import { getCurrentInstance, inject, onBeforeUnmount, onMounted, ref, watch } from 'vue';

import FileUpload from '@/components/files/FileUpload';

import { Color } from '@tiptap/extension-color';
import { Link } from '@tiptap/extension-link';
// import Image from '@tiptap/extension-image';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { BubbleMenu, EditorContent, FloatingMenu, useEditor } from '@tiptap/vue-3';
import ImageResize from 'tiptap-extension-resize-image';

// import tippy from 'tippy.js';
// tippy.css 추가 되지않으면 커서에 화살표마크 표시안됨 삭제금지
import 'tippy.js/dist/tippy.css';

export default {
    emits: ['update:modelValue'],
    components: {
        EditorContent,
        BubbleMenu,
        FloatingMenu,
        FileUpload,
    },
    props: {
        // modelValue: { type: String, default: '' },
        modelValue: Object,
        idx: Number,
    },
    setup(props) {
        const fileRef = ref(null);
        const yPos = ref(0);
        const isDrag = ref(false);
        const isUpload = ref(false);
        const clipContent = ref('');

        // const hideImgs = ref([]);

        // const style1 = ref('p-5');

        const axios = inject('$axios');

        const { proxy } = getCurrentInstance(); // Get the component instance
        const fileUrl = proxy.$fileUrl;

        const widgetInfo = inject('$widgetInfo');

        const { getUserClip, setClipFix, setClipSort, setClipUpdate } = inject('$provideList');

        const { emit } = getCurrentInstance();

        const tippyOptions = {
            // placement: 'top', // 'top', 'bottom', 'left', 'right' 등으로 위치 설정
            // offset: [200, -100], // [수평 오프셋, 수직 오프셋]으로 위치 조정 (좌측으로 20px, 아래로 10px)
            duration: 100, // 나타나고 사라질 때의 애니메이션 지속 시간
            interactive: true, // 팝업 내 상호작용 허용
            arrow: true, // 팝업에 화살표 표시
            theme: 'custom', // 팝업 테마
        };

        const floatingMenuStyle1 = `width: ${widgetInfo.value.pageWid - widgetInfo.value.pageWid / 4}px`;
        const floatingMenuStyle2 = `width: ${widgetInfo.value.pageWid - widgetInfo.value.pageWid / 6}px`;

        // watch(widgetInfo.value, newVal => {
        //     if (newVal.selectedClip && newVal.selectedClip.Type === sysEnums.ClipTypes.Image) {
        //         toggleImageVisibility(newVal.selectedClip.EncClip);
        //         console.log('000 : ' + JSON.stringify(widgetInfo.value.selectedClip.Hided));
        //     }
        // });

        // 다른사용자가 본문 내용 변경시 signal 데이터 반영
        watch(
            () => props.modelValue.Content,
            newVal => {
                editor.value.commands.setContent(newVal, false);
                // editor.value.commands.focus('end');
                // console.log('props.modelValue.Content : ' + props.modelValue.isActive);
            },
        );

        // const CustomNode = Node.create({
        //     name: 'customNode',

        //     group: 'block',

        //     content: 'inline*',

        //     parseHTML() {
        //         return [
        //             {
        //                 tag: 'div[data-type="custom-node"]',
        //             },
        //         ];
        //     },

        //     renderHTML({ HTMLAttributes }) {
        //         return ['div', { 'data-type': 'custom-node', ...HTMLAttributes }, 0];
        //     },

        //     addAttributes() {
        //         return {
        //             id: {
        //                 default: null,
        //             },
        //             style: {
        //                 default: null,
        //             },
        //             user: {
        //                 default: null,
        //             },
        //         };
        //     },
        // });

        const editor = useEditor({
            extensions: [StarterKit, TextStyle, Underline, Color, ImageResize, Link.configure({ openOnClick: false })],
            editorProps: {
                attributes: {
                    class: props.modelValue.Fixed ? 'w-100 vh-100' : '',
                },
                handleDOMEvents: {
                    click: (view, event) => {
                        const { doc } = view.state;
                        const pos = view.posAtCoords({ left: event.clientX, top: event.clientY });

                        if (!pos) return false;

                        const { nodeAfter } = doc.resolve(pos.pos);

                        if (nodeAfter) {
                            const linkMark = nodeAfter.marks.find(mark => mark.type.name === 'link');
                            if (linkMark) {
                                event.preventDefault();
                                showInIframe(linkMark.attrs.href);
                                return true;
                            }
                        }
                        return false;
                    },
                },
            },
            content: props.modelValue.Content,
            onUpdate: () => {
                emit('update:modelValue', editor.value.getHTML());
                // console.log('onUpdate : ' + editor.value.getHTML());
            },
        });

        // const parsedData = computed(() => {
        //     return editor.value && editor.value.getHTML();
        // });

        onMounted(() => {
            // clipContent에 상태값을 저장한다 변경시만 자동업데이트가 반영되게하려면 필수
            clipContent.value = editor.value.getHTML();
            // getHidedImage(editor.value.getJSON());

            // if (editor.value) {
            //     // Tippy.js를 수동으로 초기화.
            //     tippy('.bubble-menu', {
            //         placement: 'top',
            //         offset: [0, 10],
            //     });
            // }
            // editor.value.commands.focus('end')
            // console.log('Modified JSON Data:', JSON.stringify(props.modelValue, null, 4));
        });

        onBeforeUnmount(() => {
            if (editor.value) {
                editor.value.destroy();
            }
        });

        const showInIframe = url => {
            if (url) {
                window.open(url, '_blank');

                // 캔버스로 보기 크로스 오리진 문제 발생
                // widgetInfo.value.selectedUrl = url;
                // widgetInfo.value.title = '링크경로 보기';
                // widgetInfo.value.target = 'ClipViewer';
                // widgetInfo.value.place = 'end';
                // widgetInfo.value.canvas = true;

                // 모달로 보내기
                // gotoModal(null, 'ClipViewer', '링크경로 보기');
            }
        };

        // const getHidedImage = node => {
        //     try {
        //         if (node.content) {
        //             hideImgs.value = [];
        //             for (const contentNode of node.content) {
        //                 if (contentNode.type === 'image' && contentNode.attrs.id) {
        //                     // console.log('getHidedImage : ' + contentNode.attrs.class);
        //                     hideImgs.value.push(contentNode);
        //                 }
        //             }
        //         }
        //     } catch (error) {
        //         console.error('extractImagePaths Error parsing data : ', error);
        //     }
        // };

        const handleUpdate = async () => {
            if (props.modelValue.isActive && clipContent.value !== editor.value.getHTML()) {
                // console.log('Modified JSON Data handleUpdate :', JSON.stringify(editor.value.getHTML(), null, 4));

                await extractImagePaths(editor.value.getJSON());

                if (clipContent.value !== editor.value.getHTML()) {
                    const formData = new FormData();
                    formData.append('EncUser', props.modelValue.EncUser);
                    formData.append('EncClip', props.modelValue.EncClip);
                    formData.append('Content', editor.value.getHTML());
                    axios
                        .post(apiEnums.CloudTicket.updateClip, formData)
                        .then(response => {
                            if (response.data !== '0000') {
                                getUserClip();

                                clipContent.value = editor.value.getHTML();
                                // getHidedImage(editor.value.getJSON());
                                // console.log('handleMouseLeave : ' + JSON.stringify(response.data, null, 4))
                            }
                        })
                        .catch(() => {});
                }
            }
            // isDrag.value = false;
        };

        const setClip = flag => {
            if (flag === sysEnums.ClipHandles.Fix) {
                setClipFix(props.modelValue);
            }

            if (flag === sysEnums.ClipHandles.SortUp || flag === sysEnums.ClipHandles.SortDown) {
                setClipSort(props.modelValue, flag);
            }

            if (flag === sysEnums.ClipHandles.Hided) {
                setClipUpdate(props.modelValue, flag);
            }
        };

        // 윈도우 탐색기에서 이미지 드래그시 올리기
        // const handleDropFileUpload = event => {
        //     event.preventDefault();

        //     try {
        //         const dataString = event.dataTransfer.getData('text/plain');
        //         const droppedData = JSON.parse(dataString);

        //         // if (droppedData && droppedData.EncClip) {
        //         //     toggleImageVisibility(droppedData.EncClip);
        //         // }

        //         if (!droppedData && !droppedData.EncClip && event.dataTransfer.files) {
        //             isDrag.value = true;
        //             const file = event.dataTransfer.files[0];
        //             fileRef.value.uploadClipImage(file);
        //         }
        //     } catch (error) {
        //         console.log('handleDropFileUpload error : ', error);
        //     }
        // };

        const handleDropFileUpload = event => {
            event.preventDefault();

            try {
                // 미리 만들어둔 이벤트캐치 메서드
                // if (event.dataTransfer.files && event.dataTransfer.files.name) {
                //     isDrag.value = true;
                //     const file = event.dataTransfer.files[0];
                //     fileRef.value.uploadClipImage(file);
                // }
                // console.log('handleDropFileUpload success : ', JSON.stringify(event, null, 4));
            } catch (error) {
                console.log('handleDropFileUpload error : ', error);
            }
        };

        const createImageClip = async (url, wid, hei, ypos) => {
            if (props.modelValue.EncUser) {
                const formData = new FormData();
                formData.append('EncUser', props.modelValue.EncUser);
                formData.append('EncPage', props.modelValue.EncPage);
                formData.append('Wid', wid || 500);
                formData.append('Hei', hei || 500);
                formData.append('Ypos', ypos || 0);
                formData.append('Content', url);

                try {
                    const response = await axios.post(apiEnums.CloudTicket.createImageClip, formData);
                    if (response.data !== '0000') {
                        // getUserPage();
                        // getUserClip();
                        isDrag.value = false;
                        isUpload.value = false;

                        return response.data;
                    }
                } catch (error) {
                    console.error('createImageClip error:', error);
                    throw error; // 예외 처리
                }
            }
        };

        const extractImagePaths = async node => {
            // console.log('extractImagePaths start :', JSON.stringify(node, null, 4));
            try {
                if (node.content) {
                    // 실제크기로 전달하면 내용이 길어질 경우 너무 하단에 위치해서 처음에는 기본값으로 저장하고 필요시 주석제거
                    const editorRect = document.querySelector('#tippy-1').getBoundingClientRect();

                    for (const contentNode of node.content) {
                        if (contentNode.type === 'image' && !contentNode.attrs.id) {
                            const imagePath = contentNode.attrs.src;
                            const imgElement = document.querySelector(`img[src="${imagePath}"]`);
                            if (imgElement && !imagePath.includes(fileUrl)) {
                                // 실제크기로 전달하면 내용이 길어질 경우 너무 하단에 위치해서 처음에는 기본값으로 저장하고 필요시 주석제거
                                // const width = imgElement.clientWidth;
                                // const height = imgElement.clientHeight;
                                const rect = imgElement.getBoundingClientRect();
                                const ypos = parseInt(rect.top - editorRect.top, 10);
                                yPos.value = ypos;
                                // console.log('imgElement info :', imagePath, width, height, ypos);

                                isUpload.value = true;

                                // 서버에 이미지 업로드하고 새로운 경로를 받아옴
                                // const newImg = await createImageClip(imagePath, width, height, ypos);
                                const newImg = await createImageClip(imagePath, 500, 500, 0);

                                if (newImg) {
                                    // 이미지 경로를 새로운 경로로 변경
                                    contentNode.attrs.class = '';
                                    contentNode.attrs.id = newImg.EncClip;
                                    contentNode.attrs.src = fileUrl + newImg.Content;
                                    // contentNode.attrs.style = `position: relative; width: ${width}px; height: ${height}px; top: ${ypos}px; left:0px;`;

                                    // editor.value.commands.setContent(node, false);
                                    // console.log('newImg : ' + imgElement.id + ' / ' + imgElement.style);

                                    // p 태그를 추가하기
                                    // const wrapperNode = {
                                    //     type: 'customNode',
                                    //     attrs: {
                                    //         id: 'img-' + newImg.EncClip,
                                    //         user: newImg.EncUser,
                                    //         style: `padding: 10px; background-color:#ccc`,
                                    //     },
                                    //     content: [
                                    //         {
                                    //             type: 'text',
                                    //             text: `Image: ${fileUrl + newImg.Content + '/' + newImg.EncUser}`,
                                    //         },
                                    //     ],
                                    // };

                                    // editor.value.commands.insertContent(wrapperNode, false);

                                    // 기존 이미지 노드를 p 태그로 감싼 새로운 노드로 교체
                                    // const contentIndex = node.content.indexOf(contentNode);
                                    // if (contentIndex !== -1) {
                                    //     node.content.splice(contentIndex, 1, wrapperNode);
                                    // editor.value.commands.setContent(contentNode, false);
                                    // }

                                    // console.log('Updated node:', JSON.stringify(wrapperNode, null, 4));
                                }
                            }

                            // 에디터에 이미지 태그 삭제시
                            // node.content.splice(node.content.indexOf(contentNode), 1);
                            // editor.value.commands.setContent(node, false);

                            // console.log('Image Path image : ', imagePath);
                            // console.log('Draged JSON Data Image Path doc image :', JSON.stringify(contentNode, null, 4));
                        }

                        // 새로운 텍스트 노드 처리 로직 추가
                        if (contentNode.type === 'text' && contentNode.text) {
                            // URL 패턴으로 매칭
                            const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

                            // 이미 <a> 태그가 포함되어 있는지 확인
                            const anchorTagPattern = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/i;

                            if (!anchorTagPattern.test(contentNode.text) && urlPattern.test(contentNode.text)) {
                                // <a> 태그가 없고 URL이 매칭될 때만 변환
                                contentNode.text = contentNode.text.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');
                            }
                        }

                        // if (contentNode.type === 'codeBlock') {
                        //     for (const innerNode of contentNode.content) {
                        //         if (innerNode.type === 'text' && innerNode.text.includes('<img src=')) {
                        //             // const imagePath = innerNode.text.match(/<img src="(.*?)"/)[1];
                        //             node.content.splice(node.content.indexOf(contentNode), 1);
                        //             editor.value.commands.setContent(node, false);
                        //         }
                        //     }
                        // }

                        // if (contentNode.type === 'table') {
                        //     console.log('extractImagePaths table :', JSON.stringify(node, null, 4));
                        //     node.content.splice(node.content.indexOf(contentNode), 1);
                        //     editor.value.commands.setContent(node, false);
                        // }

                        // if (node.type === 'doc' && node.content) {
                        //     for (const contentNode of node.content) {
                        //         if ((contentNode.type === 'codeBlock' || contentNode.type === 'pre') && contentNode.content) {
                        //             for (const innerNode of contentNode.content) {
                        //                 if (innerNode.type === 'text' && innerNode.text.includes('<img src=')) {
                        //                     const imagePath = innerNode.text.match(/<img src="(.*?)"/)[1];
                        //                     console.log('Image Path:', imagePath);

                        //                     // If the inner node is a text node and contains an image tag, remove the codeBlock or pre node
                        //                     node.content.splice(node.content.indexOf(contentNode), 1);
                        //                     break;
                        //                 }
                        //             }
                        //         }

                        //         if (contentNode.content) {
                        //             // Check if the contentNode has content to iterate over
                        //             for (const innerNode of contentNode.content) {
                        //                 if (innerNode.type === 'image' && innerNode.text.includes('<img src=')) {
                        //                     const imagePath = innerNode.text.match(/<img src="(.*?)"/)[1];
                        //                     console.log('Image Path:', imagePath);

                        //                     node.content.splice(node.content.indexOf(contentNode), 1);
                        //                     break;
                        //                 }
                        //             }
                        //         }
                        //     }
                        // }

                        // 자식 노드가 있는 경우 재귀 호출
                        if (contentNode.content) {
                            await extractImagePaths(contentNode);
                        }
                    }
                }
            } catch (error) {
                console.error('extractImagePaths Error parsing data : ', error);
            }
        };

        const toggleImageVisibility = encClip => {
            if (editor.value) {
                const { state, view } = editor.value;
                const { tr } = state;

                state.doc.descendants((node, pos) => {
                    // console.log(node.attrs.id, encClip);
                    if (node.type.name === 'image' && node.attrs.id === encClip) {
                        const newAttrs = {
                            ...node.attrs,
                            class: node.attrs.class === '' ? 'd-none' : '',
                        };

                        tr.setNodeMarkup(pos, undefined, newAttrs);
                    }
                });

                view.dispatch(tr);
            }
        };

        // 단일 이미지의 y 좌표 계산 함수
        // const calculateImagePosition = imageNode => {
        //     return new Promise(resolve => {
        //         const imagePath = imageNode.attrs.src;
        //         const imgElement = document.querySelector(`img[src="${imagePath}"]`);

        //         if (imgElement) {
        //             // 이미지 로드 이벤트에 콜백 추가
        //             imgElement.addEventListener('load', () => {
        //                 const editorRect = document.querySelector('#tippy-1').getBoundingClientRect();
        //                 const rect = imgElement.getBoundingClientRect();
        //                 const ypos = rect.top - editorRect.top + window.scrollY;
        //                 console.log('> ' + ypos + ' / ' + (rect.top + window.scrollY));
        //                 resolve(ypos);
        //             });

        //             // 이미 이미지가 로드된 경우
        //             if (imgElement.complete) {
        //                 const editorRect = document.querySelector('#tippy-1').getBoundingClientRect();
        //                 const rect = imgElement.getBoundingClientRect();
        //                 const ypos = rect.top - editorRect.top + window.scrollY;
        //                 console.log('>> ' + ypos + ' / ' + (rect.top + window.scrollY));
        //                 resolve(ypos);
        //             }
        //         } else {
        //             resolve(0); // 이미지가 없는 경우 기본 값
        //         }
        //     });
        // };

        // const imagePositions = computed(() => {
        //     return hideImgs.value.map(imageNode => ({
        //         id: imageNode.attrs.id,
        //         src: imageNode.attrs.src,
        //         top: imageNode.attrs.position || 0,
        //         // top: calculateImagePosition(imageNode),
        //     }));
        // });

        return {
            props,
            sysEnums,
            widgetInfo,
            editor,
            fileRef,
            isDrag,
            isUpload,
            yPos,
            // hideImgs,
            tippyOptions,
            floatingMenuStyle1,
            floatingMenuStyle2,
            setClip,
            handleUpdate,
            handleDropFileUpload,
            toggleImageVisibility,
        };
    },
};
</script>

<style lang="scss">
#tippy-1 p {
    font-size: 17px;
}

#tippy-1 a {
    cursor: pointer;
}

i {
    font-size: 20px;
    cursor: pointer;
}

.ri-more-2-line {
    color: #ccc;
}

.tippy-box[data-theme~='custom'] {
    background-color: transparent; /* 배경을 투명으로 설정 */
    box-shadow: none; /* 그림자 제거 */
    border: none; /* 테두리 제거 */
    padding: 0; /* 패딩 제거 */
}

.floating-menu {
    position: absolute;
    top: 20px;
    left: 20px;
}

.my-custom-class {
    width: 100%;
    height: auto;
}
</style>
