<script>
import { sysEnums } from '@/common/sysEnums';
import { computed, inject } from 'vue';

export default {
    props: {
        model: Object,
    },
    setup(props) {
        const router = inject('$router');
        //const axios = inject('$axios');

        //const userInfo = inject('$userInfo');
        const widgetInfo = inject('$widgetInfo');

        const { selectedFolder, selectedPage, filteredPage, pageCount, updateFolder, createRole } = inject('$provideList');

        const isFolder = computed(() => {
            return props.model.children && props.model.children.length;
        });

        // const changeType = () => {
        //     if (!isFolder.value) {
        //         // props.model.children = []
        //         console.log('props.model : ' + JSON.stringify(props.model))
        //     }
        // }

        const navigateTo = (target, data) => {
            filteredPage.value = [];
            widgetInfo.value.tag = sysEnums.PageTags.Default;
            selectedFolder.value = data;
            widgetInfo.value.title = data.Name;
            router.push(target);
        };

        const dragStart = (data, event) => {
            event.dataTransfer.setData('text/plain', JSON.stringify(data));
        };

        const handleDropFolderMove = (data, event) => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            const role = droppedData.PageRoles.find(x => x.EncPage === droppedData.EncPage && x.EncUser === droppedData.EncUser);

            // updateFolder createRole 모두 상위와 같은 동작 정리 필요
            droppedData.EncPage ? createRole(data.EncFolder, role ? role.EncRole : sysEnums.zero, droppedData.EncPage) : updateFolder(droppedData.EncFolder, data.EncFolder);
            // console.log(droppedData.EncFolder + ' / ' + JSON.stringify(droppedData));
        };

        //const updateFolder = (encFolder, encTopFolder) => {
        //    const formData = new FormData();
        //    formData.append('EncUser', userInfo.value.EncUser);
        //    formData.append('EncFolder', encFolder);
        //    formData.append('EncTopFolder', encTopFolder);
        //    axios
        //        .post(apiEnums.CloudTicket.updateFolder, formData)
        //        .then(response => {
        //            if (response.data !== '0000') {
        //                getUserFolder();
        //                // console.log('updateFolder : ' + response.data)
        //            }
        //        })
        //        .catch(() => {});
        //};

        // 페이지를 작업공간 메뉴에 드롭
        //const createRole = (encFolder, encRole, encPage) => {
        //    const formData = new FormData();
        //    formData.append('EncFolder', encFolder);
        //    formData.append('EncPage', encPage);
        //    formData.append('EncUser', userInfo.value.EncUser);
        //    formData.append('EncOwner', userInfo.value.EncUser);
        //    if (encRole) {
        //        formData.append('EncRole', encRole);
        //    }
        //    axios
        //        .post(apiEnums.CloudTicket.createRole, formData)
        //        .then(response => {
        //            if (response.data !== '0000') {
        //                getUserFolder();
        //                getUserPage();
        //            }
        //        })
        //        .catch(() => {});
        //};

        return {
            props,
            sysEnums,
            widgetInfo,
            isFolder,
            selectedFolder,
            selectedPage,
            pageCount,
            navigateTo,
            dragStart,
            handleDropFolderMove,
        };
    },
};
</script>

<template>
    <!--페이지탭에 보이는 폴더-->
    <div
        v-if="model.Name !== 'FOLDER'"
        class="d-flex flex-items-center pb-1 mb-1 border-bottom border-bottom-dotted"
        :class="{ bold: isFolder }"
        @click="navigateTo('/page', model)"
        @dragover.prevent
        @drop="handleDropFolderMove(model, $event)"
    >
        <span class="flex-grow-1">
            <span v-if="isFolder" style="cursor: move" draggable="true" @dragstart="dragStart(model, $event)">
                <!-- <i class="ri-folder-5-line"></i><span class="fw-bold ps-2 me-2" v-text="model.Name"></span> -->
                <i :class="model.Type === sysEnums.PageCategorys.Folder ? 'ri-folder-3-line' : 'ri-space'"></i> <span class="fw-bold pe-2" v-text="model.Name"></span>
                <BBadge v-if="model.FolderRoles.length > 0" variant="dark">{{ model.FolderRoles.length }}</BBadge>
            </span>
            <span v-else class="" style="cursor: move" draggable="true" @dragstart="dragStart(model, $event)">
                <i :class="model.Type === sysEnums.PageCategorys.Folder ? 'ri-folder-3-line' : 'ri-space'"></i> <span v-text="model.Name"></span>
                <BBadge v-if="model.FolderRoles.length > 0" class="ms-2" variant="primary">{{
                    model.Type === sysEnums.PageCategorys.Post ? pageCount(model.EncFolder) : model.FolderRoles.length
                }}</BBadge>
            </span>
        </span>

        <span class="flex-grow-0 text-muted pt-2">{{ $moment(model.Created).format('YY.MM.DD') }}</span>
    </div>

    <!--<div v-if="model.Name !== 'FOLDER'" class="text-muted pb-2 mb-2 border-bottom">{{ model.Description }}</div>-->

    <span v-if="isFolder">
        <TreeFolder v-for="x in model.children" :key="x" :model="x"></TreeFolder>
    </span>
</template>

<style scoped></style>
