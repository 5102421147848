/* eslint-disable no-console */
// registerServiceWorker.js 는 웹에서 서비스워커 설정하는 역활
// 이파일이 서비스워커가 아니고 웹앱에서 서비스워커 등록을 처리한다 

import { register } from 'register-service-worker'

if ('serviceWorker' in navigator) {
    register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered() {
            console.log('Service worker has been registered.')
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound() {
            console.log('New content is downloading.');

            navigator.serviceWorker.ready.then((registration) => {
                // 예시: 페이지 내에서 새로 고침 유도
                if (registration.active) {
                    registration.active.postMessage({ type: 'CLEAR_CACHE' });
                }
            });
        },
        updated() {
            console.log('New content is available please refresh.');

            // 서비스워커 메서드 호출
            // navigator.serviceWorker.ready.then((registration) => {
            //     // 예시: 페이지 내에서 새로 고침 유도
            //     if (registration.active) {
            //         // registration.waiting.postMessage({ type: 'CLIENT_RELOAD' });
            //         registration.active.postMessage({ type: 'CLEAR_CACHE' });
            //     }
            // });


            // 서비스워커 자동 갱신시 필요
            // Display a prompt to the user to reload the page
            // if (confirm('A new version of the app is available. Do you want to refresh the page?')) {
            //     // Send a message to the waiting service worker to skip waiting
            //     registration.waiting.postMessage({ type: 'CLIENT_RELOAD' });
            // }
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
