<template>
    <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div class="bg-overlay"></div>

        <div class="auth-page-content overflow-hidden pt-lg-5">
            <BContainer>
                <BRow>
                    <BCol lg="12">
                        <BCard no-body class="overflow-hidden border-0 m-0">
                            <BRow class="justify-content-center g-0">
                                <BCol lg="6">
                                    <div class="p-lg-5 p-4 auth-one-bg h-100">
                                        <div class="bg-overlay"></div>
                                        <div class="position-relative h-100 d-flex flex-column">
                                            <div v-if="!widgetInfo.isApp" class="mb-4">
                                                <router-link to="/" class="d-block">
                                                    <!-- <img src="@/assets/images/logo-light.png" alt="" height="18"> -->
                                                    <img src="assets/images/pageclip/symbol_100.png" alt="" height="36" />
                                                    <img src="assets/images/pageclip/pageclip_400.png" alt="" height="28" />
                                                </router-link>
                                            </div>
                                            <div class="mt-auto">
                                                <div class="mb-3">
                                                    <i class="ri-double-quotes-l display-4 text-success"></i>
                                                </div>

                                                <div id="qoutescarouselIndicators" class="carousel slide" data-bs-ride="carousel">
                                                    <Swiper
                                                        class="text-center text-white pb-5"
                                                        :autoplay="{ delay: 3000, disableOnInteraction: false }"
                                                        :loop="true"
                                                        :modules="[Autoplay, Navigation, Pagination]"
                                                        :pagination="{ clickable: true, el: '.swiper-pagination' }"
                                                    >
                                                        <swiper-slide>
                                                            <div class="active">
                                                                <p class="fs-15 fst-italic">" Great! Clean code, clean design, easy for customization. Thanks very much! "</p>
                                                            </div>
                                                        </swiper-slide>
                                                        <swiper-slide>
                                                            <div>
                                                                <p class="fs-15 fst-italic">" The theme is really great with an amazing customer support."</p>
                                                            </div>
                                                        </swiper-slide>
                                                        <swiper-slide>
                                                            <div>
                                                                <p class="fs-15 fst-italic">" Great! Clean code, clean design, easy for customization. Thanks very much!"</p>
                                                            </div>
                                                        </swiper-slide>
                                                        <div class="swiper-pagination"></div>
                                                    </Swiper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </BCol>

                                <BCol lg="6">
                                    <div class="p-lg-5 p-4">
                                        <!--{{ $route.path }}-->
                                        <h5 class="text-primary">PageClip 서비스 가입하기</h5>

                                        <div class="mt-4">
                                            <div v-if="curPage === 1" :data-aos="widgetInfo.isApp ? '' : 'flip-left'">
                                                <div class="form-floating mb-3">
                                                    <input
                                                        type="text"
                                                        class="form-control text-center fs-20"
                                                        id="floatingInput1"
                                                        placeholder="휴대폰번호를 입력하여 주시기 바랍니다."
                                                        v-model="recipient"
                                                        @input="handlePhoneNumber"
                                                    />
                                                    <label for="floatingInput1" class="fw-bold ms-2 mt-1">휴대폰번호</label>
                                                </div>

                                                <div class="form-floating mb-3">
                                                    <input
                                                        type="text"
                                                        class="form-control text-center fs-20"
                                                        id="floatingInput2"
                                                        placeholder="고객님의 이름을 입력하여 주시기 바랍니다."
                                                        v-model="userName"
                                                    />
                                                    <label for="floatingInput2" class="fw-bold ms-2 mt-1">고객명</label>
                                                </div>

                                                <!-- <div class="form-floating text-center">
                                                    <select class="form-select" id="floatingSelect1" aria-label="회사선택" v-model="company">
                                                        <option class="text-center" selected>회사선택</option>
                                                        <option class="text-center" v-for="x in companyList" :key="x">{{ x.Name }}</option>
                                                    </select>
                                                    <label for="floatingSelect1">회사선택</label>
                                                </div> -->

                                                <!-- <div class="mb-3">
                                                    <label class="form-label" for="password-input">Password</label>
                                                    <div class="position-relative auth-pass-inputgroup">
                                                        <input type="password" class="form-control pe-5 password-input" onpaste="return false" placeholder="Enter password"
                                                            id="password-input" aria-describedby="passwordInput" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required>
                                                        <BButton variant="link" class="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                            id="password-addon"><i class="ri-eye-fill align-middle"></i></BButton>
                                                        <div class="invalid-feedback">
                                                            Please enter password
                                                        </div>
                                                    </div>
                                                </div> -->

                                                <!-- <div class="mb-4">
                                                    <p class="mb-0 fs-12 text-muted fst-italic">By registering you agree to the Velzon
                                                        <BLink href="#" class="text-primary text-decoration-underline fst-normal fw-medium">Terms of Use</BLink>
                                                    </p>
                                                </div>
                                                <div id="password-contain" class="p-3 bg-light mb-2 rounded">
                                                    <h5 class="fs-13 fw-semibold">Password must contain:</h5>
                                                    <p id="pass-length" class="invalid fs-12 mb-2">Minimum <b>8 characters</b></p>
                                                    <p id="pass-lower" class="invalid fs-12 mb-2">At <b>lowercase</b> letter (a-z)</p>
                                                    <p id="pass-upper" class="invalid fs-12 mb-2">At least <b>uppercase</b> letter (A-Z)</p>
                                                    <p id="pass-number" class="invalid fs-12 mb-0">A least <b>number</b> (0-9)</p>
                                                </div> -->

                                                <div class="mt-5">
                                                    <div v-show="recipient && recipient.length < 12" class="p-2 h-50h text-center fw-bold fs-20 text-dark" v-text="phoneNum"></div>
                                                    <button class="btn btn-success h-50h w-100" type="button" @click="checkUserInfo" :disabled="recipient && recipient.length < 11">
                                                        다음
                                                    </button>
                                                </div>

                                                <div v-if="!widgetInfo.isApp" class="mt-4 text-center">
                                                    <div class="signin-other-title">
                                                        <h5 class="fs-13 mb-4 title text-muted">Create account with</h5>
                                                    </div>

                                                    <div>
                                                        <BButton type="button" variant="primary" class="btn-icon"><i class="ri-facebook-fill fs-16"></i></BButton>
                                                        <BButton type="button" variant="danger" class="btn-icon ms-1"><i class="ri-google-fill fs-16"></i></BButton>
                                                        <BButton type="button" variant="dark" class="btn-icon ms-1"><i class="ri-github-fill fs-16"></i></BButton>
                                                        <!-- <BButton type="button" variant="info" class="btn-icon ms-1"><i class="ri-twitter-fill fs-16"></i></BButton> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="curPage === 2" :data-aos="widgetInfo.isApp ? '' : 'flip-right'">
                                                <div class="mb-4">
                                                    <div class="avatar-lg mx-auto">
                                                        <div class="avatar-title bg-light text-primary display-5 rounded-circle">
                                                            <i class="ri-mail-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-muted text-center mx-lg-3">
                                                    <h4 class="">인증번호 확인</h4>
                                                    <p>
                                                        4자리 인증번호를 확인하여 주세요.<br />
                                                        <span class="fw-semibold" v-text="phoneNum"></span>
                                                        <span class="mx-2" v-text="' '"></span>
                                                        <span class="fw-semibold" v-text="userName"></span>
                                                    </p>
                                                </div>

                                                <div class="mt-4">
                                                    <div class="row position-relative">
                                                        <div v-for="(i, n) in 4" :key="i" class="col-3">
                                                            <div class="mb-3 form-control form-control-lg bg-light border-light text-center" v-text="digits[n]"></div>
                                                        </div>

                                                        <input
                                                            class="px-4 h-50h w-100 position-absolute top-0 left-0 opacity-0"
                                                            type="text"
                                                            maxlength="4"
                                                            v-model="validNumber"
                                                            @input="handleValidNumber($event)"
                                                        />
                                                    </div>

                                                    <div class="mt-3">
                                                        <button type="button" class="btn btn-success h-50h w-100" @click="confirmValidNumber">인증하기</button>
                                                    </div>
                                                </div>

                                                <div class="mt-5 text-center">
                                                    <p class="mb-0">인증번호를 받지못하셨습니까?</p>
                                                    <p href="#" class="fw-semibold text-primary text-decoration-underline hand" @click="curPage = 1">인증번호 다시받기</p>
                                                </div>
                                            </div>

                                            <div v-if="curPage === 3" :data-aos="widgetInfo.isApp ? '' : 'flip-left'">
                                                <div class="avatar-lg mx-auto mt-2">
                                                    <div class="avatar-title bg-light text-success display-3 rounded-circle">
                                                        <i class="ri-checkbox-circle-fill"></i>
                                                    </div>
                                                </div>
                                                <div class="mt-4 pt-2 text-center">
                                                    <h4>약관동의!</h4>
                                                    <p class="text-muted mx-4">PageClip 서비스를 이용하기 위해서는<br />아래의 약관에 동의하셔야 합니다</p>
                                                </div>

                                                <div class="mt-4 mb-4 text-start">
                                                    <p
                                                        class="hand"
                                                        :class="isAllSelected() ? 'fst-italic border-bottom' : 'fs-12 bg-danger badge rounded-pill'"
                                                        @click="checkPolicyAll"
                                                    >
                                                        전체동의
                                                    </p>
                                                    <template v-for="(i, n) in 7" :key="i">
                                                        <div v-if="checkedPolicy[userType][n]" class="mb-2 fs-12 text-muted d-flex">
                                                            <div class="form-check-inline flex-fill">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="checkbox"
                                                                    :id="'formCheck' + n"
                                                                    @click="checkPolicy(n)"
                                                                    :checked="selectedPolicy[n]"
                                                                />
                                                                <label class="form-check-label ps-2" :for="'formCheck' + n">{{ policyTitle[n] }}</label>
                                                            </div>
                                                            <span class="text-primary text-decoration-underline fst-normal fw-medium hand" @click="viewPolicy(i)">약관보기</span>
                                                        </div>
                                                    </template>
                                                </div>

                                                <div class="mt-5">
                                                    <button type="button" class="btn btn-success h-50h w-100" @click="checkToken">PageClip 가입하기</button>
                                                    <button type="button" class="btn h-50h w-100 mt-2" @click="curPage = 2">back</button>
                                                </div>
                                            </div>

                                            <div class="my-5 fw-bold fs-20 text-center text-danger" v-text="confirmMsg"></div>
                                        </div>

                                        <span v-if="!widgetInfo.isApp">
                                            <div class="mt-5 text-center">
                                                <p class="mb-0">
                                                    이미 PageClip을 이용하고 있습니다
                                                    <router-link to="/auth/signin-cover" class="fw-semibold text-primary text-decoration-underline"> 로그인</router-link>
                                                </p>
                                            </div>

                                            <div class="mt-4 text-center">
                                                <p class="mb-0">--- UID ---</p>
                                                <!-- <p>{{ confirmNum }}</p> -->
                                                <p class="fs-10">{{ fcmToken }}</p>

                                                <span v-if="widgetInfo.loaded" class="spinner-border text-primary avatar-sm text-center" role="status"></span>
                                            </div>
                                        </span>
                                    </div>
                                </BCol>
                            </BRow>
                        </BCard>
                    </BCol>
                </BRow>
            </BContainer>
        </div>

        <footer class="footer">
            <BContainer>
                <BRow>
                    <BCol lg="12">
                        <div class="text-center">
                            <p class="mb-0">&copy; {{ new Date().getFullYear() }} PageClip. Crafted with <i class="mdi mdi-heart text-danger"></i> by UrbanClip</p>
                        </div>
                    </BCol>
                </BRow>
            </BContainer>
        </footer>
    </div>

    <!-- 회원약관보기용 Modal -->
    <BModal v-model="modalShow" hide-footer title="약관보기" class="v-modal-custom" size="xl">
        <div v-html="modalBody"></div>
        <div class="modal-footer v-modal-footer">
            <BButton type="button" variant="primary" class="btn btn-link link-success fw-medium" @click="modalShow = false">Close</BButton>
        </div>
    </BModal>
</template>

<script>
import { getFcmToken, setUserToStorage, showAlert, isApp } from '@/common/comFuncs';
import { apiEnums, sysEnums } from '@/common/sysEnums';
import { userPolicy } from '@/common/userPolicy';
import { inject, onMounted, reactive, ref } from 'vue';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

// import 'aos/dist/aos.css';
// import AOS from "aos";

import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup(props) {
        const pages = 3;
        let curPage = ref(1);

        const isIOS = ref(false);
        const modalShow = ref(false);

        const userName = ref('');
        const email = ref('');
        const userType = ref(1);
        const phoneNum = ref('');
        const confirmNum = ref('');

        let modalBody = ref('');

        let fcmToken = ref('');
        let isToken = ref(false);
        let showPwaGuide = ref(false);
        let isPwa = ref(false);
        let digits = ref([]);
        let validNumber = ref('');
        let recipient = ref('');
        let confirmMsg = ref('');

        const policyTitle = ref([
            '개인정보 보호정책 동의',
            '서비스 이용약관 동의',
            '회원 이용약관 (임대인·임차인) 동의하기',
            '협업 계약서 동의하기',
            '개인정보 처리방침 동의하기',
            '개인정보 수집·이용 동의서',
            '개인정보 제3자 제공 동의서',
        ]);

        let selectedPolicy = ref([false, false, false, false, false, false]);

        const checkedPolicy = ref([
            [false, false, false, false, false, false],
            [true, true, false, false, false, false],
            [true, true, false, true, true, true],
            [true, true, false, true, true, true],
        ]);

        const browserInfo = reactive({
            name: '',
            code: '',
            platform: '',
            userAgent: '',
            version: '',
        });

        const widgetInfo = inject('$widgetInfo');

        const store = inject('$store');
        const axios = inject('$axios');
        const router = inject('$router');

        // const { companyList } = inject("$provideList");

        onMounted(() => {
            const pageClipUser = localStorage.getItem(sysEnums.UserKey);

            if (pageClipUser) {
                // 이미 기입한 회원 홈화면으로 이동
                isApp() ? router.push('/app') : router.push('/');
            }

            checkBrowser();

            // setTimeout(() => {
            //     AOS.init({ easing: "ease-out-back", duration: 1e3 });
            // }, 200);
        });

        const checkBrowser = () => {
            browserInfo.name = navigator.appName;
            browserInfo.code = navigator.appCodeName;
            browserInfo.platform = navigator.platform;
            browserInfo.userAgent = navigator.userAgent;
            browserInfo.version = navigator.appVersion;

            const isDeviceIOS = /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;
            isIOS.value = isDeviceIOS;

            const isChromeBrowser = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
            const isStandalone = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;

            // Safari 크롬 브라우저에서 현재 페이지가 홈 화면에 추가되었는지 확인
            if (isStandalone) {
                isPwa.value = true;
                // console.log('현재 브라우저에서 웹 앱이 홈 화면에 추가되었습니다.');
            }

            // 크롬 브라우저에서 현재 페이지가 홈 화면에 추가되었는지 확인
            if (isChromeBrowser) {
                isPwa.value = true;
                // console.log('현재 크롬 브라우저에서 웹 앱이 홈 화면에 추가되었습니다.');
            }
        };

        const createUser = () => {
            const formData = new FormData();
            formData.append('Recipient', recipient.value);
            formData.append('UserName', userName.value);
            formData.append('Email', email.value);
            formData.append('UserType', userType.value);
            formData.append('Platform', browserInfo.platform);
            formData.append('UserAgent', browserInfo.userAgent);
            formData.append('Uid', fcmToken.value);
            axios
                .post(apiEnums.CloudTicket.createUser, formData)
                .then(response => {
                    const datas = response.data;
                    if (datas.result === '1000' || datas.result === '3000') {
                        setUser(datas, true);
                    } else if (datas.result === '2000') {
                        showAlert('', '재가입시에는 최초 등록된<br>연락처와 성명이 일치 하여야 합니다', 'HouseMeta', sysEnums.AlertTypes.Error);
                    } else {
                        showAlert('', '회원가입 중 오류 가 발생 하였습니다!<br>관리자에 문의 바랍니다', datas, sysEnums.AlertTypes.Error);
                    }
                })
                .catch(() => {});
        };

        const handlePhoneNumber = event => {
            let replacedInput = event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,4})(\d{0,4})/);
            phoneNum.value = !replacedInput[2] ? replacedInput[1] : '(' + replacedInput[1] + ') ' + replacedInput[2] + (replacedInput[3] ? '-' + replacedInput[3] : '');
            recipient.value = !replacedInput[2] ? replacedInput[1] : replacedInput[1] + replacedInput[2] + replacedInput[3];
            // console.log('handlePhoneNumber : ' + phoneNum.value + ' / ' + recipient.value)
        };

        const handleValidNumber = event => {
            const inputValue = event.target.value;
            const index = inputValue.length;
            digits.value = validNumber.value.split('');
            // digits.value = [...validNumber.value];

            if (index === 4) {
                confirmValidNumber();
                // checkNotification();
            }
        };

        const confirmValidNumber = async () => {
            // curPage.value = 3;

            const validNum = digits.value.join('');

            if (fcmToken.value === '') {
                widgetInfo.value.loaded = false;
                showAlert('', '알림설정을 확인 하시기 바랍니다.', '', sysEnums.AlertTypes.Error);
            } else if (confirmMsg.value === '' && confirmNum.value === validNumber.value) {
                const datas = await checkExistUser();

                // if (datas === '0000' && isPwa.value) {
                //     isPwa.value = false;
                //     curPage.value = 1;
                // }

                if (datas === '0000') {
                    curPage.value = 3;
                } else {
                    setUserToStorage(datas, true);
                    store.dispatch('AC_USER', datas);
                    isApp() ? router.push('/app') : router.push('/');
                    window.location.reload();
                }
            } else if (validNum.length == 4 && confirmNum.value !== validNumber.value) {
                confirmMsg.value = '인증번호를 확인 하세요.';
                showAlert('', '4자리 인증번호를 확인 하신 후<br><br>정확히 입력해 주세요.', '', sysEnums.AlertTypes.Error);
            } else {
                confirmMsg.value = '';
            }
        };

        const checkExistUser = async () => {
            try {
                const formData = new FormData();
                formData.append('Recipient', recipient.value);
                formData.append('UserName', userName.value);
                formData.append('Uid', fcmToken.value);

                const response = await axios.post(apiEnums.CloudTicket.checkExistUser, formData);

                return response.data;
            } catch (error) {
                console.error(error);
                return null;
            }
        };

        const checkUserInfo = async () => {
            // if (!email.value && !isPwa.value) {
            //     showAlert('', '메일 주소를 입력하세요.', '', sysEnums.AlertTypes.Error);
            //     return;
            // }

            // if (!isValidEmail(email.value) && !isPwa.value) {
            //     showAlert('', '유효한 이메일 주소를 입력하세요.', '', sysEnums.AlertTypes.Error);
            //     return;
            // }

            // if (userType.value == 0 && !isPwa.value) {
            //     showAlert('', '회원타입을 선택 하세요.', '', sysEnums.AlertTypes.Error);
            //     return;
            // }

            if (widgetInfo.value.isApp) {
                getCofirmNumber();

                await sendFcm();

                return;
            }

            if (isPwa.value) {
                widgetInfo.value.loaded = true;
                await checkNotification();
            }

            // const isDev = process.env.NODE_ENV === 'development' ? true : false;
            // if (isDev) {
            //     confirmNum.value = '1234';
            //     curPage.value = 2;
            // } else {
            //     sendMMS();
            // }
        };

        // const isValidEmail = (email) => {
        //     // 이메일 유효성 검사 정규식
        //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //     return emailRegex.test(email);
        // };

        let retryCount = 0;
        const maxRetries = 2;

        const checkNotification = async () => {
            try {
                Notification.requestPermission().then(async permission => {
                    if (permission == 'granted') {
                        isToken.value = true;

                        fcmToken.value = await getFcmToken();

                        if (fcmToken.value === '0000' && retryCount < maxRetries) {
                            retryCount++;
                            console.log(`Retrying... Attempt ${retryCount}`);
                            await checkNotification(); // 재시도
                            return;
                        }

                        isToken.value = false;
                        widgetInfo.value.loaded = false;

                        getCofirmNumber();

                        await sendFcm();
                    } else {
                        fcmToken.value = '';
                        widgetInfo.value.loaded = false;
                        showAlert('', '알림설정 차단 중 입니다.', '', sysEnums.AlertTypes.Error);
                    }
                });
            } catch (error) {
                showAlert('', error, 'PageClip', sysEnums.AlertTypes.Info);
            }
        };

        // const checkNotification = async () => {
        //     try {
        //         const permission = await Notification.requestPermission();

        //         if (permission === 'granted') {
        //             isToken.value = true;

        //             fcmToken.value = await getFcmToken();

        //             isToken.value = false;
        //             widgetInfo.value.loaded = false;

        //             await sendFcm();
        //         } else {
        //             showAlert('', '알림설정 차단 중 입니다.', '', sysEnums.AlertTypes.Error);
        //             fcmToken.value = '';
        //         }
        //     } catch (error) {
        //         showAlert('', error, 'HouseMeta', sysEnums.AlertTypes.Info);
        //     }
        // };

        const checkToken = async () => {
            // 약관 확인 했는지 체크
            if (!isAllSelected()) {
                showAlert('', '약관 동의가 필요 합니다.', '', sysEnums.AlertTypes.Error);
                return;
            }

            // if (!fcmToken.value) {
            //     showAlert('', '푸시요청 수락이 필요 합니다.', '', sysEnums.AlertTypes.Error);
            //     return;
            // }

            // showPwaGuide.value = true;

            try {
                createUser();
            } catch (error) {
                showAlert('', error, 'PageClip', sysEnums.AlertTypes.Info);
            }
        };

        // const sendMMS = () => {
        //     const params = new URLSearchParams();
        //     params.append('recipient', recipient.value);
        //     axios.post(apiEnums.BizTalk.sendMms, null, { params })
        //         .then((response) => {
        //             // bizToken.value = JSON.stringify(response);
        //             if (response.data === "0000") {
        //                 // 메세지 전송오류 로직 추가
        //             } else {
        //                 confirmNum.value = response.data
        //                 curPage.value = 2
        //             }
        //         })
        //         .catch(() => { });
        // }

        const getCofirmNumber = () => {
            const params = new URLSearchParams();
            params.append('recipient', recipient.value);
            axios
                .post(apiEnums.BizTalk.getCofirmNumber, null, { params })
                .then(response => {
                    // bizToken.value = JSON.stringify(response);
                    if (response.data === '0000') {
                        // 메세지 전송오류 로직 추가
                    } else {
                        const datas = String(response.data);
                        for (let i = 0; i < datas.length; i++) {
                            digits.value[i] = datas[i];
                        }

                        confirmNum.value = datas;
                        validNumber.value = datas;

                        curPage.value = 2;
                    }
                })
                .catch(() => {});
        };

        const sendFcm = async () => {
            const formData = new FormData();
            formData.append('NotiType', sysEnums.NotiTypes.User);
            formData.append('Title', 'PageClip');
            formData.append('Content', '인증번호가 발송되었습니다');
            formData.append('UserName', userName.value);
            formData.append('FcmToken', fcmToken.value);
            axios
                .post(apiEnums.CloudTicket.sendFcm, formData)
                .then(() => {
                    // bizToken.value = JSON.stringify(response);
                    // if (response.data === "0000") {
                    //     // 메세지 전송오류 로직 추가
                    // }
                })
                .catch(() => {});
        };

        const setUser = (datas, flag) => {
            const user = setUserToStorage(datas, flag);

            if (showPwaGuide.value) {
                store.dispatch('AC_USER', datas);
                return;
            }

            if (user.EncUser) {
                store.dispatch('AC_USER', datas);
                widgetInfo.value.isApp ? router.push('/app') : router.push('/');
                // window.location.reload();
            }
        };

        const checkPolicy = index => {
            selectedPolicy.value[index] = !selectedPolicy.value[index];
            // console.log(selectedPolicy.value + ' / ' + index);
        };

        const checkPolicyAll = () => {
            // selectedPolicy.value = selectedPolicy.value.map(item => !item);
            isAllSelected() ? selectedPolicy.value.fill(false) : (selectedPolicy.value = [...checkedPolicy.value[userType.value]]); // 새로운 배열을 할당하여 복사
        };

        const isAllSelected = () => {
            return selectedPolicy.value.every((item, index) => item === checkedPolicy.value[userType.value][index]);
        };

        const viewPolicy = index => {
            modalShow.value = true;
            switch (index) {
                case 1:
                    modalBody.value = userPolicy.policy1;
                    break;
                case 2:
                    modalBody.value = userPolicy.policy2;
                    break;
                case 3:
                    modalBody.value = userPolicy.policy3;
                    break;
                case 4:
                    modalBody.value = userPolicy.policy4;
                    break;
                case 5:
                    modalBody.value = userPolicy.policy5;
                    break;
                case 6:
                    modalBody.value = userPolicy.policy6;
                    break;
                case 7:
                    modalBody.value = userPolicy.policy7;
                    break;
                default:
                    modalBody.value = '';
            }
        };

        return {
            props,
            pages,
            curPage,
            isPwa,
            modalBody,
            modalShow,
            userName,
            phoneNum,
            recipient,
            validNumber,
            sysEnums,
            showPwaGuide,
            confirmNum,
            confirmMsg,
            email,
            userType,
            isToken,
            fcmToken,
            widgetInfo,
            browserInfo,
            isIOS,
            digits,
            policyTitle,
            selectedPolicy,
            checkedPolicy,
            handlePhoneNumber,
            handleValidNumber,
            checkUserInfo,
            confirmValidNumber,
            isAllSelected,
            checkPolicyAll,
            viewPolicy,
            checkPolicy,
            checkToken,
        };
    },
    data() {
        return {
            Autoplay: Autoplay,
            Pagination: Pagination,
            Navigation: Navigation,
            pagination: {
                clickable: true,
                el: '.swiper-pagination',
                renderBullet: function (index, className) {
                    return '<span class=' + className + '>' + (index + 1) + '</span>';
                },
            },
        };
    },
    // methods: {
    // },
    // mounted() {
    //     var password = document.getElementById("password-input");
    //     password.onchange = this.validatePassword;
    //     document.querySelectorAll("form .auth-pass-inputgroup")
    //         .forEach(function (item) {
    //             item.querySelectorAll(".password-addon").forEach(function (subitem) {
    //                 subitem.addEventListener("click", function () {
    //                     var passwordInput = item.querySelector(".password-input");
    //                     if (passwordInput.type === "password") {
    //                         passwordInput.type = "text";
    //                     } else {
    //                         passwordInput.type = "password";
    //                     }
    //                 });
    //             });
    //         });

    //     var myInput = document.getElementById("password-input");
    //     var letter = document.getElementById("pass-lower");
    //     var capital = document.getElementById("pass-upper");
    //     var number = document.getElementById("pass-number");
    //     var length = document.getElementById("pass-length");

    //     // When the user clicks on the password field, show the message box
    //     myInput.onfocus = function () {
    //         document.getElementById("password-contain").style.display = "block";
    //     };

    //     // When the user clicks outside of the password field, hide the password-contain box
    //     myInput.onblur = function () {
    //         document.getElementById("password-contain").style.display = "none";
    //     };

    //     // When the user starts to type something inside the password field
    //     myInput.onkeyup = function () {
    //         // Validate lowercase letters
    //         var lowerCaseLetters = /[a-z]/g;
    //         if (myInput.value.match(lowerCaseLetters)) {
    //             letter.classList.remove("invalid");
    //             letter.classList.add("valid");
    //         } else {
    //             letter.classList.remove("valid");
    //             letter.classList.add("invalid");
    //         }

    //         // Validate capital letters
    //         var upperCaseLetters = /[A-Z]/g;
    //         if (myInput.value.match(upperCaseLetters)) {
    //             capital.classList.remove("invalid");
    //             capital.classList.add("valid");
    //         } else {
    //             capital.classList.remove("valid");
    //             capital.classList.add("invalid");
    //         }

    //         // Validate numbers
    //         var numbers = /[0-9]/g;
    //         if (myInput.value.match(numbers)) {
    //             number.classList.remove("invalid");
    //             number.classList.add("valid");
    //         } else {
    //             number.classList.remove("valid");
    //             number.classList.add("invalid");
    //         }

    //         // Validate length
    //         if (myInput.value.length >= 8) {
    //             length.classList.remove("invalid");
    //             length.classList.add("valid");
    //         } else {
    //             length.classList.remove("valid");
    //             length.classList.add("invalid");
    //         }
    //     };
    // },
};
</script>
