<template>

    <BCard no-body>
        <BCardHeader class="align-items-center d-flex py-3">
            <BCardTitle class="mb-0 flex-grow-1">{{ selectedPage.Title }}</BCardTitle>
        </BCardHeader>

        <BCardBody>

            <BRow>
                <BCol cols="12">

                    <!-- 우편번호용 Modal -->
                    <div v-show="showPost">
                        <div class="d-flex mt-4">
                            <h1 class="fs-5 flex-grow-1" id="staticBackdropLabel">우편번호검색</h1>
                            <button type="button" class="btn-close" aria-label="Close" @click="searchPostalCode"></button>
                        </div>
                        <div class="modal-body mt-2">
                            <KakaoPost ref="postRef" v-on:SetAddress="setAddress"></KakaoPost>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary rounded-pill" @click="searchPostalCode">닫기</button>
                        </div>
                    </div>

                    <div v-show="!showPost" class="mt-4">
                        <div class="d-flex align-items-center mb-2">
                            <div class="form-floating w-50 pe-3">
                                <input type="number" class="form-control" id="floatingInput-1" placeholder="우편번호" v-model="postalCode" readonly />
                                <label for="floatingInput-1">우편번호</label>
                            </div>
                            <button type="button" class="w-50 h-50h btn btn-primary rounded-pill" @click="searchPostalCode">우편번호검색</button>
                        </div>

                        <div class="mb-2">
                            <div class="form-floating w-100">
                                <input type="text" class="form-control" id="floatingInput-2" placeholder="주소" v-model="addr1" readonly />
                                <label for="floatingInput-2">주소</label>
                            </div>
                        </div>
                        <div class="mb-2">
                            <div class="form-floating w-100">
                                <input type="text" class="form-control" id="floatingInput-3" placeholder="상세주소" v-on:input="handleInput" v-model="addr2" />
                                <label for="floatingInput-3">상세주소</label>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 text-center text-muted">
                        <span class="me-3">{{ selectedPage.Address1 || '' }}</span>
                        <span>{{ selectedPage.Address2 || '' }}</span>
                    </div>

                    <div class="mt-5 text-end">
                        <BButton variant="primary" @click="updatePage"><i class="ri-arrow-up-line"></i> 장소지정하기</BButton>
                    </div>

                </BCol>
            </BRow>

        </BCardBody>
    </BCard>

</template>


<script lang="js">
import { ref, inject } from "vue";
import { apiEnums, sysEnums } from "@/common/sysEnums";
import { showAlert } from "@/common/comFuncs";

import KakaoPost from "@/components/kakao/KakaoPost";

export default {
    components: {
        KakaoPost
    },
    props: {
        Content: String,
        FileType: Number,
        IsBtn: Boolean,
    },
    setup(props) {
        const titles = ref(['', '제목', '설명', '등록일', '상태']);

        const showPost = ref(false);
        const postRef = ref(null);

        let addr1 = ref('');
        let addr2 = ref('');
        let postalCode = ref('');
        let lat = ref(null);
        let lng = ref(null);

        const axios = inject("$axios");

        const userInfo = inject('$userInfo');

        const { filteredFolder, selectedPage } = inject("$provideList");

        const searchPostalCode = () => {
            showPost.value = !showPost.value;
            if (showPost.value) {
                postRef.value.viewDaumPostcode();
            }
        }

        const setAddress = (x) => {
            postalCode.value = x.code;
            addr1.value = x.addr1;
            addr2.value = x.addr2;
            lat.value = x.coords.lat;
            lng.value = x.coords.lng;
            // console.log(lat.value , lng.value)
        }

        const handleInput = (event) => {
            addr2.value = event.target.value;
            // console.log(addr1.value, addr2.value)
        };

        const handleDropFolderMove = (encFolder, event) => {
            event.preventDefault();

            const dataString = event.dataTransfer.getData('text/plain');
            const droppedData = JSON.parse(dataString);

            // updateFolder(droppedData.EncFolder, encFolder)
            console.log(encFolder + ' / ' + droppedData.EncFolder)

            // if (droppedData.EncWorkspace) {
            //     updateWorkspace(droppedData.EncWorkspace, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncWorkspace)
            // } else {
            //     updateFolder(droppedData.EncFolder, encFolder)
            //     console.log(encFolder + ' / ' + droppedData.EncFolder)
            // }
        }

        const updatePage = () => {
            if (!selectedPage.value) {
                showAlert('Alert!', '페이지를 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            if (!addr1.value) {
                showAlert('Alert!', '주소를 선택하세요', 'PageClip', sysEnums.AlertTypes.Info);
                return;
            }

            const formData = new FormData();
            // formData.append('EncUser', userInfo.value.EncUser);
            formData.append('EncPage', selectedPage.value.EncPage);
            formData.append('Addr1', addr1.value);
            formData.append('Addr2', addr2.value);
            formData.append('Lat', lat.value);
            formData.append('Lng', lng.value);
            axios.post(apiEnums.CloudTicket.updatePage, formData)
                .then((response) => {
                    if (response.data !== '0000') {
                        console.log('updatePage success');
                    }
                })
                .catch(() => { });
        }


        return {
            props, userInfo, showPost, postRef,
            titles, filteredFolder,
            addr1, addr2, postalCode, selectedPage,
            setAddress, searchPostalCode,
            handleDropFolderMove, handleInput,
            updatePage
        };
    },
};
</script>

<style scoped lang="scss"></style>