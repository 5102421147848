<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <BCard no-body class="card-animate">
            <BCardHeader class="border-0 align-items-center d-flex">
                <BCardTitle class="mb-0 flex-grow-1">{{ contractList.length }}건
                </BCardTitle>
            </BCardHeader>
            <BCardBody>
                <BRow>
                    <BCol cols="12">
                        <div class="px-2 bg-white">
                            <table class="table table-striped table-hover text-center">
                                <thead>
                                    <tr class="h-50h bg-light align-middle fw-bold">
                                        <th scope="col">번호</th>
                                        <th v-for="x in titles" :key="x">{{ x }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(x, i) in contractList" :key="x">
                                        <tr class="h-50h align-middle hand">
                                            <th class="w-5" scope="row">{{ i + 1 }}</th>
                                            <td class="text-start" v-text="x.Address1"></td>
                                            <td class="w-5" v-text="x.LessorName"></td>
                                            <td class="w-5" v-text="x.PartnerName"></td>
                                            <td class="w-5" v-text="x.LesseeName"></td>
                                            <td class="w-10" v-if="!$store.state.isMobile" v-text="x.PayMid ? x.PayMid.Mid : '-'"></td>
                                            <td class="w-10" v-if="!$store.state.isMobile" v-text="x.PayBid ? x.PayBid.FnNm : '-'"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="x.PayMid && x.PayMid.BankCode ? payEnums.bankCodes[x.PayMid.BankCode] : '-'"></td>
                                            <td class="w-5" v-if="!$store.state.isMobile" v-text="x.Confirmed ? '승인' : '대기'"></td>
                                            <td class="w-10" v-text="$moment(x.Updated).format('YYYY-MM-DD hh:mm')"></td>
                                            <td class="w-10" v-text="$moment(x.Created).format('YYYY-MM-DD hh:mm')"></td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </BCol>
                </BRow>
            </BCardBody>
        </BCard>

        <BRow>
            <BCol lg="12">
                <BCard no-body>
                    <BCardHeader class="justify-content-between d-flex align-items-center">
                        <BCardTitle>PayStory</BCardTitle>
                    </BCardHeader>
                    <BCardBody>
                        <ChartBar :Target="enumTypes.ChartTypes.Payment"></ChartBar>
                    </BCardBody>
                </BCard>
            </BCol>
        </BRow>

        <b-row>
            <b-col xl="12">

                <!-- <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-center pb-2 mt-2 border-bottom">
                            <div class="flex-grow-1">
                                <h5 class="card-title mb-0 fw-bold">등록 계약</h5>
                            </div>
                            <div class="flex-shrink-0">
                                <span class="fw-bold text-primary pe-3" v-text="resultMsg"></span>
                                <span class="form-switch hand mx-2" @click="getDataFilter(1)">
                                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault1" :checked="selected1" disabled />
                                </span>
                                <span class="badge bg-light text-primary fs-12 hand"><i class="ri-add-fill align-bottom me-1"></i> {{
                                    contractList.length + ' / ' + contracts.length }} </span>
                            </div>
                        </div>
                        <span v-for="x in contractList" :key="x">
                            <div class="d-flex align-items-center p-2 border-bottom hand" :class="x.EncContract === selectedContract ? 'fw-bold text-danger' : ''"
                                @click="selectedContract = x.EncContract">
                                <span class="w-50" v-text="x.Address1"></span>
                                <span class="w-25" v-text="x.PartnerName"></span>
                                <span class="w-25" v-text="x.LessorName"></span>
                                <span class="w-25" v-text="x.Rent.toLocaleString()"></span>
                                <span class="w-25 text-end" v-text="$moment(x.Created).format('YY-MM-DD hh:mm')"></span>
                                <div class="avatar-xs d-block flex-shrink-0 ms-3">
                                    <span v-if="x.PayBill" class="avatar-title rounded-circle fs-16 bg-dark" @click="doBill(x)">
                                        <i class="bx bx-credit-card"></i>
                                    </span>
                                    <span v-else class="avatar-title rounded-circle fs-16 bg-success" @click="registBill(x)">
                                        <i class="bi-credit-card-2-front"></i>
                                    </span>
                                </div>
                            </div>
                        </span>
                    </div>
                </div> -->

                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-center pb-2 mt-2 border-bottom">
                            <div class="flex-grow-1">
                                <h5 class="card-title mb-0 fw-bold">결재 내역</h5>
                            </div>
                            <div class="flex-shrink-0">
                                <span class="fw-bold text-primary pe-3" v-text="resultMsg"></span>
                                <span class="form-switch hand mx-2" @click="getDataFilter(3)">
                                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault3" :checked="selected3" disabled />
                                </span>
                                <span class="badge bg-light text-primary fs-12 hand"><i class="ri-add-fill align-bottom me-1"></i> {{
            paydoList.length + ' / ' + paydos.length }} </span>
                            </div>
                        </div>
                        <span v-for="(x, i) in paydoList" :key="x">
                            <div class="d-flex align-items-center p-2 border-bottom hand">
                                <span class="w-5">{{ i + 1 }}</span>
                                <span class="w-30 text-start" v-text="x.Address1"></span>
                                <span class="w-5" v-text="x.CpCd"></span>
                                <span class="w-20" v-text="x.CardNo"></span>
                                <span class="w-10" v-text="x.Amt.toLocaleString()"></span>
                                <span class="w-10" v-text="x.LesseeName"></span>
                                <span class="w-10" v-text="x.Status ? '정상처리' : '취소완료'"></span>
                                <span class="w-10" v-text="$moment(x.Created).format('YY-MM-DD hh:mm')"></span>
                                <div class="w-10 text-end avatar-xs d-block flex-shrink-0 ms-3">
                                    <span v-if="x.Status" class="avatar-title rounded-circle fs-16 bg-dark" @click="cancelBill(x)">
                                        <i class="bi-trash2"></i>
                                    </span>
                                    <span v-else class="avatar-title rounded-circle fs-16 opacity-25">
                                        <i class="bi-trash2-fill"></i>
                                    </span>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>

            </b-col>
        </b-row>

    </Layout>
</template>

<script lang="js">
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { onMounted, ref, inject } from "vue";
import { apiEnums, enumTypes, payEnums } from "@/common/sysEnums";
import ChartBar from "@/components/charts/ChartBar";

export default {
    components: {
        Layout, PageHeader,
        ChartBar
    },
    setup() {
        const titles = ref(['시설', '임대인', '파트너', '임차인', '입금키', '결제키', '파트너계좌', '상태', '수정일', '등록일']);

        let selectedContract = ref('');

        const resultMsg = ref('');

        let selectedOption = ref('01');
        let selected1 = ref(false);
        let selected2 = ref(false);
        let selected3 = ref(false);
        let selected4 = ref(false);

        const mids = ref({});

        const store = inject("$store");
        const axios = inject("$axios");
        const moment = inject("$moment");

        const contracts = inject("$contracts");
        const paydos = inject("$paydos");
        const bills = inject("$bills");

        const { contractList, paydoList, billList } = inject("$provideList");

        onMounted(() => {
            // console.log(JSON.stringify(paydoList.value));
            getBill();
            getPayDo();

            billList.value = bills.value.filter(x => x.Status === true);
            paydoList.value = paydos.value.filter(x => x.Status === true);
            contractList.value = contracts.value.filter(x => x.PayBill !== null);

            mids.value = contracts.value.filter(x => x.PayMid && x.PayMid.EncMid !== null);
        });

        const getDataFilter = (section) => {
            switch (section) {
                case 1:
                    selected1.value = !selected1.value;
                    selected1.value === true ? (contractList.value = contracts.value) : (contractList.value = contracts.value.filter(x => x.PayBill !== null));
                    break
                case 2:
                    selected2.value = !selected2.value;
                    selected2.value === true ? (billList.value = bills.value) : (billList.value = bills.value.filter(x => x.Status === true));
                    break
                case 3:
                    selected3.value = !selected3.value;
                    selected3.value === true ? (paydoList.value = paydos.value) : (paydoList.value = paydos.value.filter(x => x.Status === true));
                    break
                // no default
            }
        };

        const deleteBill = (x) => {
            const formData = new FormData();
            formData.append('EncLessor', x.EncLessor);
            formData.append('EncLessee', x.EncLessee);
            formData.append('EncContract', x.EncContract);
            axios.post(apiEnums.PayStory.deleteBill, formData)
                .then((response) => {
                    resultMsg.value = '빌키 삭제 : ' + response.data;
                    getBill();
                    getPayDo();
                })
                .catch(() => { });
        };

        const doBill = (x) => {
            const formData = new FormData();
            formData.append('EncLessor', x.EncLessor);
            formData.append('EncLessee', x.EncLessee);
            formData.append('EncContract', x.EncContract);
            formData.append('GoodsNm', x.Address1);
            formData.append('GoodsAmt', "1000");
            formData.append('QuotaMon', "00");
            formData.append('OrdNm', x.LesseeName);
            formData.append('OrdNo', moment().format('YYYYMMDDhhmmss'));
            formData.append('OrdTel', x.LesseeId);
            axios.post(apiEnums.PayStory.doBill, formData)
                .then((response) => {
                    resultMsg.value = response.data;
                    getPayDo();
                })
                .catch(() => { });
        };

        const cancelBill = (x) => {
            const formData = new FormData();
            formData.append('EncLessor', x.EncLessor);
            formData.append('EncLessee', x.EncLessee);
            formData.append('EncContract', x.EncContract);
            formData.append('EncPayDo', x.EncPayDo);
            formData.append('PartCanFlg', "0");
            axios.post(apiEnums.PayStory.cancelBill, formData)
                .then((response) => {
                    resultMsg.value = response.data;
                    getPayDo();
                })
                .catch(() => { });
        };

        const inquiryBill = (x) => {
            const formData = new FormData();
            formData.append('EncLessor', x.EncLessor);
            formData.append('EncLessee', x.EncLessee);
            formData.append('EncContract', x.EncContract);
            formData.append('EncPayDo', x.EncPayDo);
            axios.post(apiEnums.PayStory.inquiryBill, formData)
                .then((response) => {
                    console.log(JSON.stringify(response.data))
                })
                .catch(() => { });
        };

        // const selectPayDo = (x) => {
        //     encLessor.value = x.EncLessor;
        //     encLessee.value = x.EncLessee;
        //     encContract.value = x.EncContract;
        //     encPayDo.value = x.EncPayDo;
        //     console.log(JSON.stringify(x));
        // }

        // const getContract = () => {
        //     axios.post(apiEnums.CloudTicket.getContractConsole)
        //         .then((response) => {
        //             store.dispatch("AC_CONTRACT", response.data);
        //             contractList.value = response.data;
        //         })
        //         .catch(() => { });
        // };

        const getPayDo = () => {
            axios.post(apiEnums.PayStory.getPayDo)
                .then((response) => {
                    store.dispatch("AC_PAYDO", response.data);
                    paydoList.value = response.data;
                })
                .catch(() => { });
        };

        const getBill = () => {
            axios.post(apiEnums.PayStory.getBill)
                .then((response) => {
                    store.dispatch("AC_BILL", response.data);
                    billList.value = response.data;
                })
                .catch(() => { });
        };


        return {
            title: "Payment",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "PayStory",
                    active: true,
                },
            ],
            contracts, selectedContract,
            mids, bills, paydos, paydoList, billList, contractList,
            selectedOption, titles, payEnums, enumTypes,
            resultMsg, selected1, selected2, selected3, selected4,
            doBill, cancelBill, deleteBill, inquiryBill,
            getDataFilter
        };
    },
};
</script>

<style scoped lang="scss"></style>
