<script>
import { getUserDepartment } from '@/common/comFuncs';
import { inject, ref } from 'vue';
// import { PlusCircleIcon } from '@zhuowenli/vue-feather-icons';
// import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import CKEditor from '@ckeditor/ckeditor5-vue';

import { emails } from '@/common/data';
import animationData from '@/components/widgets/gsqxdxog.json';
import Lottie from '@/components/widgets/lottie.vue';
// import simplebar from 'simplebar-vue';
export default {
    setup() {
        const chatShow = ref(false);
        const detailShow = ref(false);

        const widgetInfo = inject('$widgetInfo');

        const { pageList, selectedPage } = inject('$provideList');

        const viewDetail = data => {
            if (selectedPage.value.EncPage === data.EncPage) {
                detailShow.value = !detailShow.value;
            } else {
                selectedPage.value = data;
                detailShow.value = true;
            }
        };

        return {
            widgetInfo,
            chatShow,
            detailShow,
            pageList,
            selectedPage,
            getUserDepartment,
            viewDetail,
        };
    },
    data() {
        return {
            defaultOptions: {
                animationData: animationData,
            },
            emailsdata: emails,
            mailChatDetailElm: false,
            removeModal: false,
            value: ['support@themesbrand.com'],
            editor: ClassicEditor,
            editorData: '',
        };
    },
    components: {
        // ckeditor: CKEditor.component,
        lottie: Lottie,
        // PlusCircleIcon,
        // Multiselect,
        // simplebar
    },
    methods: {
        // scrollToBottom(id) {
        //     setTimeout(function () {
        //         var simpleBar = (document.getElementById(id).querySelector("#chat-conversation .simplebar-content-wrapper")) ?
        //             document.getElementById(id).querySelector("#chat-conversation .simplebar-content-wrapper") : '';
        //         var offsetHeight = document.getElementsByClassName("chat-conversation-list")[0] ?
        //             document.getElementById(id).getElementsByClassName("chat-conversation-list")[0].scrollHeight - window.innerHeight + 750 : 0;
        //         if (offsetHeight)
        //             simpleBar.scrollTo({
        //                 top: offsetHeight,
        //                 behavior: "smooth"
        //             });
        //     }, 100);
        // },

        // changeUserScroll() {
        //     var currentChatId = "users-chat";
        //     this.scrollToBottom(currentChatId);
        // },
        toggleFavourite(ele) {
            ele.target.closest('.favourite-btn').classList.toggle('active');
        },
    },
    // mounted() {
    //     setTimeout(() => {
    //         document.getElementById('elmLoader').style.display = 'none';
    //         document.getElementById('mail-list-data').style.display = 'block';
    //     }, 1000);
    // },
};
</script>

<template>
    <div class="border p-2">
        <BRow>
            <BCol :xxl="detailShow ? '8' : '12'">
                <div class="message-list-content mx-n4 px-4 message-list-scroll" id="emailsdatas" data-simplebar>
                    <div v-if="!widgetInfo.loaded" id="elmLoader">
                        <div class="spinner-border text-primary avatar-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <ul class="message-list">
                        <div v-for="(x, index) of pageList" :key="index">
                            <li :class="{ unread: x.Fixed == false }">
                                <div class="col-mail col-mail-1">
                                    <!-- <BButton
                                        type="button"
                                        variant="transparent"
                                        class="btn avatar-xs mt-n1 p-0 favourite-btn fs-15"
                                        :class="{ active: x.Shared == true }"
                                        @click="toggleFavourite"
                                    >
                                        <i class="ri-star-fill"></i>
                                    </BButton> -->
                                    <span class="fs-20 text-muted ps-2 pe-4">{{ index + 1 }}</span>
                                    <span @click="viewDetail(x)" class="title-name hand">{{
                                        x.UserName + ' / ' + getUserDepartment(x.Position, true) + ' / ' + getUserDepartment(x.Department, false)
                                    }}</span>
                                </div>
                                <div class="col-mail col-mail-2" @click="viewDetail(x)">
                                    <span class="subject hand">
                                        <span class="subject-title">{{ x.Title }}</span> – <span class="teaser">{{ x.Address1 }}</span>
                                    </span>
                                    <div class="date">{{ $moment(x.Updated).format('MMM DD') }}</div>
                                </div>
                            </li>
                        </div>
                        <!-- <li class="bg-transparent text-center">
                            <BButton type="button" pill variant="info-subtle" size="sm" class="btn-rounded px-2"><i class="mdi mdi-spin mdi-loading me-2"></i>Load More </BButton>
                        </li> -->
                    </ul>
                </div>
            </BCol>

            <BCol v-show="detailShow" xxl="4">
                <div class="border">
                    <div class="p-4 d-flex flex-column h-100">
                        <div class="pb-4 border-bottom border-bottom-dashed">
                            <BRow>
                                <BCol>
                                    <BButton type="button" variant="soft-danger" size="sm" class="btn-icon fs-16 close-btn-email" @click="detailShow = false">
                                        <i class="ri-close-fill align-bottom"></i>
                                    </BButton>
                                </BCol>
                                <div class="col-auto">
                                    <div class="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link">
                                        <!-- <BButton type="button" variant="ghost-secondary" size="sm" class="btn-icon fs-16 favourite-btn active">
                                            <i class="ri-star-fill align-bottom"></i>
                                        </BButton> -->
                                        <BButton variant="ghost-secondary" size="sm" class="btn-icon fs-16">
                                            <i class="ri-printer-fill align-bottom"></i>
                                        </BButton>
                                        <BButton type="button" variant="ghost-secondary" class="btn-icon btn-sm fs-16 remove-mail" @click="removeModal = !removeModal">
                                            <i class="ri-delete-bin-5-fill align-bottom"></i>
                                        </BButton>
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <i class="ri-more-2-fill align-bottom"></i>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-end">
                                                <BLink class="dropdown-item">Mark as Unread</BLink>
                                                <BLink class="dropdown-item">Mark as Important</BLink>
                                                <BLink class="dropdown-item">Add to Tasks</BLink>
                                                <BLink class="dropdown-item">Add Star</BLink>
                                                <BLink class="dropdown-item">Mute</BLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </BRow>
                        </div>

                        <div class="mx-n4 px-4 email-detail-content-scroll" data-simplebar>
                            <div class="d-flex align-items-center mt-4 mb-3">
                                <span class="pe-3"><h5 class="fw-bold email-subject-title">Page Roles</h5></span>
                                <BBadge v-if="selectedPage.PageRoles" variant="danger" pill>{{ selectedPage.PageRoles.length }}</BBadge>
                            </div>

                            <div class="accordion accordion-flush">
                                <template v-for="x in selectedPage.PageRoles" :key="x">
                                    <div class="accordion-item border-dashed">
                                        <div class="accordion-header">
                                            <BLink role="button" class="btn w-100 text-start px-0 bg-transparent shadow-none collapsed" v-b-toggle.email-collapseOne>
                                                <div class="d-flex align-items-center text-muted">
                                                    <div class="flex-shrink-0 me-3" @click="chatShow = true">
                                                        <img :src="x.Avatar ? $fileUrl + x.Avatar : 'assets/images/no-profile.jpg'" class="img-fluid avatar-xs rounded-circle" />
                                                    </div>
                                                    <div class="flex-grow-1 overflow-hidden">
                                                        <h5 class="fs-14 text-truncate mb-0">{{ x.UserName }}</h5>
                                                    </div>
                                                    <div class="flex-shrink-0 align-self-start">
                                                        <div class="text-muted fs-12">{{ $moment(x.Updated).format('DD MMM YYYY, hh:mm A') }}</div>
                                                    </div>
                                                </div>
                                            </BLink>
                                        </div>

                                        <BCollapse id="email-collapseOne">
                                            <div class="accordion-body text-body px-0">
                                                <div>
                                                    <p>Hi,</p>
                                                    <p>
                                                        Praesent dui ex, dapibus eget mauris ut, finibus vestibulum enim. Quisque arcu leo, facilisis in fringilla id, luctus in
                                                        tortor.
                                                    </p>
                                                    <p>
                                                        Sed elementum turpis eu lorem interdum, sed porttitor eros commodo. Nam eu venenatis tortor, id lacinia diam. Sed aliquam in
                                                        dui et porta. Sed bibendum orci non tincidunt ultrices.
                                                    </p>
                                                    <p>Sincerly,</p>

                                                    <div class="d-flex gap-3">
                                                        <div class="border rounded avatar-xl h-auto">
                                                            <img src="@/assets/images/small/img-2.jpg" alt="" class="img-fluid rouned-top" />
                                                            <div class="py-2 text-center">
                                                                <BLink class="d-block fw-semibold">Download</BLink>
                                                            </div>
                                                        </div>
                                                        <div class="border rounded avatar-xl h-auto">
                                                            <img src="@/assets/images/small/img-6.jpg" alt="" class="img-fluid rouned-top" />
                                                            <div class="py-2 text-center">
                                                                <BLink class="d-block fw-semibold">Download</BLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </BCollapse>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- <div class="mt-auto">
                            <div>
                                <label for="exampleFormControlTextarea1" class="form-label">Reply :</label>
                                <textarea
                                    class="form-control border-bottom-0 rounded-top rounded-0 border"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    placeholder="Enter message"
                                ></textarea>
                                <div class="bg-light px-2 py-1 rouned-bottom border">
                                    <BRow>
                                        <BCol>
                                            <BButton type="button" variant="light" size="sm" class="py-0 fs-15" data-bs-toggle="tooltip" data-bs-placement="top" title="Bold"
                                                ><i class="ri-bold align-bottom"></i
                                            ></BButton>
                                            <BButton type="button" variant="light" size="sm" class="py-0 fs-15" data-bs-toggle="tooltip" data-bs-placement="top" title="Italic">
                                                <i class="ri-italic align-bottom"></i>
                                            </BButton>
                                            <BButton type="button" variant="light" size="sm" class="py-0 fs-15" data-bs-toggle="tooltip" data-bs-placement="top" title="Link"
                                                ><i class="ri-link align-bottom"></i
                                            ></BButton>
                                            <BButton type="button" variant="light" size="sm" class="py-0 fs-15" data-bs-toggle="tooltip" data-bs-placement="top" title="Image">
                                                <i class="ri-image-2-line align-bottom"></i>
                                            </BButton>
                                        </BCol>
                                        <div class="col-auto">
                                            <BButton type="button" variant="success" size="sm"><i class="ri-send-plane-2-fill align-bottom"></i></BButton>
                                            <button
                                                type="button"
                                                class="btn btn-sm btn-success dropdown-toggle dropdown-toggle-split"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                <span class="visually-hidden">Toggle Dropdown</span>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <BLink class="dropdown-item"><i class="ri-timer-line text-muted me-1 align-bottom"></i> Schedule Send</BLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </BRow>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </BCol>
        </BRow>
    </div>

    <Vue3DraggableResizable class="border-0" :initW="600" :initH="600" :draggable="true" :resizable="false" :parent="false" v-model:active="chatShow">
        <div v-if="chatShow" class="position-absolute bottom-0 end-0 p-5">
            <BCard no-body class="mb-0">
                <BCardHeader class="align-items-center d-flex bg-primary text-white-50">
                    <div class="flex-grow-1">
                        <h5 class="fs-13 text-white mb-1 profile-username">Julian Rosa</h5>
                        <p class="mb-0 fs-12 lh-1">Active</p>
                    </div>
                    <div class="flex-shrink-0">
                        <!-- <div class="mt-n3 me-n3"> -->
                        <BButton type="button" variant="link" class="text-white fs-16 text-decoration-none" @click="chatShow = false"><i class="ri-close-fill"></i></BButton>
                        <!-- </div> -->
                    </div>
                </BCardHeader>

                <BCardBody class="p-0">
                    <div id="users-chat123">
                        <div class="chat-conversation p-3" id="chat-conversation" data-simplebar="init" style="width: 300px; height: 350px">
                            <div class="simplebar-wrapper" style="margin: -16px">
                                <div class="simplebar-height-auto-observer-wrapper">
                                    <div class="simplebar-height-auto-observer"></div>
                                </div>
                                <div class="simplebar-mask">
                                    <div class="simplebar-offset" style="right: 0px; bottom: 0px">
                                        <div
                                            class="simplebar-content-wrapper"
                                            tabindex="0"
                                            role="region"
                                            aria-label="scrollable content"
                                            style="height: 100%; overflow: hidden scroll"
                                        >
                                            <div class="simplebar-content" style="padding: 16px">
                                                <ul class="list-unstyled chat-conversation-list chat-sm" id="users-conversation">
                                                    <li class="chat-list left">
                                                        <div class="conversation-list">
                                                            <div class="chat-avatar">
                                                                <img src="@/assets/images/users/avatar-4.jpg" alt="" />
                                                            </div>
                                                            <div class="user-chat-content">
                                                                <div class="ctext-wrap">
                                                                    <div class="ctext-wrap-content">
                                                                        <p class="mb-0 ctext-content">Good morning 😊</p>
                                                                    </div>
                                                                    <BDropdown
                                                                        variant="link"
                                                                        class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                                                        toggle-class=" arrow-none"
                                                                        menu-class="dropdown-menu"
                                                                        aria-haspopup="true"
                                                                    >
                                                                        <template #button-content> <i class="ri-more-2-fill align-bottom"></i> </template>
                                                                        <BDropdownItem><i class="ri-reply-line me-2 text-muted align-bottom"></i> Reply</BDropdownItem>
                                                                        <BDropdownItem><i class="ri-file-copy-line me-2 text-muted align-bottom"></i> Copy</BDropdownItem>
                                                                        <BDropdownItem><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i> Delete</BDropdownItem>
                                                                    </BDropdown>
                                                                </div>
                                                                <div class="conversation-name">
                                                                    <small class="text-muted time">09:07 am</small>
                                                                    <span class="text-success check-message-icon"><i class="ri-check-double-line align-bottom"></i></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li class="chat-list right">
                                                        <div class="conversation-list">
                                                            <div class="user-chat-content">
                                                                <div class="ctext-wrap">
                                                                    <div class="ctext-wrap-content">
                                                                        <p class="mb-0 ctext-content">Good morning, How are you? What about our next meeting?</p>
                                                                    </div>
                                                                    <BDropdown
                                                                        variant="link"
                                                                        class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                                                        toggle-class=" arrow-none"
                                                                        menu-class="dropdown-menu"
                                                                        aria-haspopup="true"
                                                                    >
                                                                        <template #button-content> <i class="ri-more-2-fill align-bottom"></i> </template>
                                                                        <BDropdownItem><i class="ri-reply-line me-2 text-muted align-bottom"></i> Reply</BDropdownItem>
                                                                        <BDropdownItem><i class="ri-file-copy-line me-2 text-muted align-bottom"></i> Copy</BDropdownItem>
                                                                        <BDropdownItem><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i> Delete</BDropdownItem>
                                                                    </BDropdown>
                                                                </div>
                                                                <div class="conversation-name">
                                                                    <small class="text-muted time">09:08 am</small>
                                                                    <span class="text-success check-message-icon"><i class="ri-check-double-line align-bottom"></i></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li class="chat-list right">
                                                        <div class="conversation-list">
                                                            <div class="user-chat-content">
                                                                <div class="ctext-wrap">
                                                                    <div class="ctext-wrap-content">
                                                                        <p class="mb-0 ctext-content">Wow that's great</p>
                                                                    </div>
                                                                    <BDropdown
                                                                        variant="link"
                                                                        class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                                                        toggle-class=" arrow-none"
                                                                        menu-class="dropdown-menu"
                                                                        aria-haspopup="true"
                                                                    >
                                                                        <template #button-content> <i class="ri-more-2-fill align-bottom"></i> </template>
                                                                        <BDropdownItem><i class="ri-reply-line me-2 text-muted align-bottom"></i> Reply</BDropdownItem>
                                                                        <BDropdownItem><i class="ri-file-copy-line me-2 text-muted align-bottom"></i> Copy</BDropdownItem>
                                                                        <BDropdownItem><i class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i> Delete</BDropdownItem>
                                                                    </BDropdown>
                                                                </div>
                                                                <div class="conversation-name">
                                                                    <small class="text-muted time">09:12 am</small>
                                                                    <span class="text-success check-message-icon"><i class="ri-check-double-line align-bottom"></i></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="simplebar-placeholder" style="width: auto; height: 479px"></div>
                            </div>
                            <div class="simplebar-track simplebar-horizontal" style="visibility: hidden">
                                <div class="simplebar-scrollbar" style="width: 0px; display: none"></div>
                            </div>
                            <div class="simplebar-track simplebar-vertical" style="visibility: visible">
                                <div class="simplebar-scrollbar" style="height: 130px; display: block; transform: translate3d(0px, 120px, 0px)"></div>
                            </div>
                        </div>
                    </div>

                    <div class="border-top border-top-dashed">
                        <BRow class="g-2 mx-3 mt-2 mb-3">
                            <BCol>
                                <div class="position-relative">
                                    <input type="text" class="form-control border-light bg-light" placeholder="Enter Message..." />
                                </div>
                            </BCol>
                            <div class="col-auto">
                                <BButton type="submit" variant="info"><i class="mdi mdi-send"></i></BButton>
                            </div>
                        </BRow>
                    </div>
                </BCardBody>
            </BCard>
        </div>
    </Vue3DraggableResizable>

    <!-- remove modal  -->
    <BModal v-model="removeModal" id="removeItemModal" hide-footer header-class="p-3 bg-light" class="v-modal-custom" centered no-close-on-backdrop>
        <div class="mt-2 text-center">
            <lottie colors="primary:#f7b84b,secondary:#f06548" trigger="loop" :options="defaultOptions" :height="100" :width="100" />

            <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you Sure ?</h4>
                <p class="text-muted mx-4 mb-0">Are you Sure You want to Remove this Record ?</p>
            </div>
        </div>
        <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
            <BButton type="button" variant="light" class="w-sm" id="btn-close" @click="removeModal = false">Close </BButton>
            <BButton type="button" variant="danger" class="w-sm" id="delete-record">Yes, Delete It!</BButton>
        </div>
    </BModal>
</template>
