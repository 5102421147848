const login = {
    namespaced: true,
    state: {
        kakaoApi: {
            callback: 'https://app.pageclip.ai/login/kakao',
            clientId: 'c989490c9707b775b6b00339083a7112',
            secret: '',
            states: '',
        },
        naverApi: {
            callback: 'https://app.pageclip.ai/login/naver',
            clientId: 'mbfS_rURhVTG04XBxFGu',
            secret: '',
            states: '',
        },
        googleApi: {
            callback: 'https://app.pageclip.ai/login/google',
            clientId: '555866032065-fjf8p9klubri64c35il2t05hh6atsfn9.apps.googleusercontent.com',
            secret: '',
            states: '',
        },
        firebaseConfig: {
            apiKey: "AIzaSyAPYcbAO6JDluBxFTCEcplXPbSxrAia-6M",
            authDomain: "pageclip-cloudticket.firebaseapp.com",
            projectId: "pageclip-cloudticket",
            storageBucket: "pageclip-cloudticket.appspot.com",
            messagingSenderId: "415380284798",
            appId: "1:415380284798:web:445c4a7b3d65796eec7f2f",
            measurementId: "G-FF24WKQXPG"
        },
        apiToken: 'jddwpmakxiendlxjjat3m0qxu#.nnjnjxss!by$mksda',
        kakaoAppKey: '30cd673bd9d390b1655e28f2894bbc96',
        firebaseVapidKey: 'BNKXQhM2jz8V4WhtbSAOcup5nckJuOGHsCFYCFmWluzS9uzFXqj3fQQLJdPVWb2sH8Aa9mmq9e6QyNd3mK36aNM',
        fcmToken: ''
    },
    getters: {
        GE_KAKAO: (state) => state.kakaoApi,
        GE_NAVER: (state) => state.naverApi,
        GE_GOOGLE: (state) => state.googleApi,
        GE_AIPTOKEN: (state) => state.apiToken,
        GE_KAKAOAPPKEY: (state) => state.kakaoAppKey,
        GE_FCMTOKEN: (state) => state.fcmToken,
    },
    mutations: {
        MU_APITOKEN: (state, payload) => { state.apiToken = payload },
        MU_FCMTOKEN: (state, payload) => { state.fcmToken = payload },
    },
    actions: {
        AC_APITOKEN: ({ commit }, payload) => { commit("MU_APITOKEN", payload) },
        AC_FCMTOKEN: ({ commit }, payload) => { commit("MU_FCMTOKEN", payload) },
    },
};

module.exports = login;
