<template>

    <div v-if="edit" class="flex-grow-1 text-muted overflow-hidden">
        <input type="text" class="form-control mb-2 text-center" v-model="owner" placeholder="대표명">
        <input type="text" class="form-control mb-2 text-center" v-model="tel" placeholder="연락처">
        <input type="text" class="form-control mb-2 text-center" v-model="name" placeholder="회사명">

        <span class="d-flex flex-wrap my-3 hand">
            <span v-for="x in companyTypes" :key="x">
                <BBadge :variant="x === props.Data.Type ? 'danger' : 'secondary'" class="m-1" @click="updateCompanyType(x)">{{ enumTypes.CompanyTypes[x].name }}</BBadge>
            </span>
            <div class="form-switch">
                <input class="form-check-input hand" type="checkbox" role="switch" id="flexSwitchCheckDefault" :checked="props.Data.Status" @click="updateCompany(true)" />
            </div>
            <!-- <div v-if="!$store.state.isMobile" @click="sendAlimTalk" class="mt-1"><i class="ri-mail-send-line fs-20"></i></div> -->
        </span>

        <div class="row mt-2 mb-4">
            <div class="col-6">
                <BButton class="w-100 p-0" variant="primary" @click="updateCompany(false)"><i class="ri-edit-line fs-20"></i></BButton>
            </div>
            <div class="col-6">
                <BButton class="w-100 p-0" @click="edit = false"><i class="ri-close-line fs-20"></i></BButton>
            </div>
        </div>

    </div>
    <div v-else class="text-muted overflow-hidden hand" @click="setCompany">
        <div class="mb-2">{{ props.Data.Owner }} ({{ props.Data.Tel }})</div>
        <BBadge :variant="props.Data.Type > 0 ? 'danger' : 'primary'" class="mb-2">{{ enumTypes.CompanyTypes[props.Data.Type].name }}</BBadge>
        <!-- <div>{{ props.Data.Email }}</div> -->
    </div>

</template>

<script lang="js">
import { ref, inject } from 'vue';
import { apiEnums, sysEnums, enumTypes } from "@/common/sysEnums";

export default {
    components: {
    },
    props: {
        Data: Object,
    },
    setup(props) {
        const name = ref('');
        const tel = ref('');
        const owner = ref('');
        const edit = ref(false);

        const companyTypes = sysEnums.CompanyTypes;

        const isCompanyRegist = ref(false);

        const canvasUserType = ref(0);

        // const selectedCompany = ref({});
        const selectedData = ref({});
        const selectedList = ref([]);

        const widgetInfo = inject("$widgetInfo");

        const axios = inject("$axios");

        const { companyList, getCompany } = inject("$provideList");

        const updateCompany = (flag) => {
            const formData = new FormData();
            formData.append('EncCompany', props.Data.EncCompany);
            if (flag) {
                formData.append('Status', 1);
            } else {
                formData.append('Owner', owner.value);
                formData.append('Name', name.value);
                formData.append('Tel', tel.value);
            }

            axios.post(apiEnums.CloudTicket.updateCompany, formData)
                .then((response) => {
                    if (response.data === '1000') {
                        getCompany();
                    }
                })
                .catch(() => { });
        };

        const updateCompanyType = (type) => {
            const formData = new FormData();
            formData.append('EncCompany', props.Data.EncCompany);
            formData.append('Type', type);
            axios.post(apiEnums.CloudTicket.updateCompany, formData)
                .then(async (response) => {
                    if (response.data !== '0000') {
                        getCompany();

                        // try {
                        //     await store.state.connection.invoke('SendUserReset', props.Data.LoginId, enumTypes.SignalTypes.UserUpdated);
                        // } catch (error) {
                        //     console.error('Error invoking SendUserReset : ', error);
                        // }
                    }
                })
                .catch(() => { });
        };

        const sendAlimTalk = () => {
            const formData = new FormData();
            formData.append('TmpltCode', 'C01a');
            formData.append('EncCompany', props.Data.EncCompany);
            formData.append('UserType', '임대인');
            formData.append('Recipient', props.Data.LessorId);
            formData.append('UserName', props.Data.LessorName);

            const Addr2 = props.Data.Address2.trim() === '' ? ' ' : props.Data.Address2;

            console.log(JSON.stringify(props.Data, null, 4));

            formData.append('Address', props.Data.Address1 + ' ' + Addr2);
            axios.post(apiEnums.BizTalk.sendAlimTalk, formData)
                .then((response) => {
                    console.log(JSON.stringify(response))
                })
                .catch(() => { });
        };

        const setCompany = () => {
            edit.value = true;

            name.value = props.Data.Name;
            tel.value = props.Data.Tel;
            owner.value = props.Data.Owner;
            // selectedCompany.value = props.Data;
            widgetInfo.value.companyInfo = props.Data;
        }

        const setData = (data, user) => {
            canvasUserType.value = user;
            isCompanyRegist.value = !isCompanyRegist.value;
            isCompanyRegist.value ? selectedData.value = data : canvasUserType.value = 0;
        }

        return {
            title: "Company",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },
                {
                    text: "CompanyList",
                    active: true,
                },
            ],
            props, sysEnums, enumTypes, isCompanyRegist,
            selectedData, selectedList,
            companyList, canvasUserType, widgetInfo,
            name, tel, owner, edit, companyTypes,
            updateCompany, updateCompanyType,
            sendAlimTalk, setCompany, setData,
        };
    },
};
</script>

<style scoped lang="scss">
table {
    tbody {
        tr[draggable="true"] {
            cursor: move;

            &.hover {
                margin-top: 49px;
                opacity: 0.2;
            }
        }
    }
}
</style>
